import React, { FC, Fragment } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { Dialog, Transition } from '@headlessui/react';
import { FlexRow, Text } from '@core'

interface ButtonProps {
  className?: string;
  title: string;
  onClose: any;
  description: string;
  isOpen: boolean;
  toastKind: string;
  onClickSubmit?: any;
  onChange?: any;
  onChange2?: any;
  onSubmit?: any;
  submitBtnText?: string;
  placeholder?: string;
  placeholder2?: string;
  type: string;
  value: string;
  value2: string;
  emoji: any;
  emoji2: any;
  clear: any;
  loading?: any;
}

export const ToastInputDouble: FC<ButtonProps> = ({
  title, description, onClose, onChange, onChange2, onSubmit,
  onClickSubmit, isOpen, toastKind, submitBtnText, placeholder,
  placeholder2, type, value, value2, emoji, emoji2,
  clear, loading, children
}) => {
  let color = 'green-100';

  let icon = 'fa-check';
  if (toastKind === 'success') {
    color = 'green-100';
    icon = 'fa-check';
  } else if (toastKind === 'error') {
    color = 'red-100';
    icon = 'fa-times';
  } else if (toastKind === 'warning') {
    color = 'yellow-100';
    icon = 'fa-exclamation';
  } else if (toastKind === 'info') {
    color = 'primary-100';
    icon = 'fa-exclamation';
  }

  const [isShow, setIsShow] = React.useState(false);
  const [isShow2, setIsShow2] = React.useState(false);

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-40 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="phone:w-full medium:w-3/4 tabletWide:w-1/2 phone:w-full h-screen text-center">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-50 px-5 pt-5
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <div
                className={`flex flex-col justify-around p-4 mb-4 text-gray-500 w-full
            bg-white border-4 border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins bg-white`}
                id="toast-success"
                role="alert"
              >
              <FlexRow className='w-full'>
                <div
                  className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white bg-${color} rounded-lg mr-1`}
                >
                  <i className={`fas ${icon}`} />
                </div>
                <div>
                  <div className={`ml-3 font-semibold text-base text-${color}`}>
                    {title.toUpperCase()}
                  </div>
                  <div className="ml-3 text-sm font-normal text-primary-100">{description}</div>
                </div>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
                </FlexRow>

              <div className='laptop:h-96 overflow-y-auto px-2'>
                <form  className='w-full mt-4' onSubmit={onSubmit}>
                  <Text className='my-2 text-primary-100 font-semibold'>
                   Marketing Asset Title
                  </Text>
                  <div className='w-full relative'>
                    <input
                      className='border-2 border-primary-100 w-full focus:outline-none rounded-lg mb-2 pr-10'
                      onChange={onChange}
                      placeholder={placeholder}
                      required
                      type={type}
                      value={value}
                    />
                    <span className='absolute top-3 right-2 cursor-pointer hidden' onClick={() => setIsShow(!isShow)} title='Click to use emoji picker'>
                      &#128522;
                    </span>
                    {
                      isShow?
                      <div className='absolute top-0 right-0 p-2 z-50 rounded-lg shadow-xl bg-grey-500 flex flex-col justify-end items-end'>
                        <i className='fas fa-times text-secondary-200 border border-grey-400 rounded-lg py-1 px-2 mr-2 my-2 cursor-pointer' onClick={() => setIsShow(!isShow)} title='Close window'/>
                        <EmojiPicker onEmojiClick={emoji} />
                      </div>
                      :
                      <></>
                    }
                  </div>
                  <Text className='my-2 text-primary-100 font-semibold'>
                    Marketing Asset Content
                  </Text>
                  <div className=''>
                    <textarea
                      className='font-poppins phone:text-sm tablet:text-base scrollbar-hide focus:outline-none rounded-lg block w-full border border-primary-100 h-40 pb-14 resize-none'
                      maxLength={1000}
                      onChange={onChange2}
                      placeholder={placeholder2}
                      required
                      rows={4}
                      value={value2}
                    />
                    {
                      isShow2?
                      <div className='absolute top-0 right-0 p-2 z-50 rounded-lg shadow-xl bg-grey-500 flex flex-col justify-end items-end'>
                        <i className='fas fa-times text-secondary-200 border border-grey-400 rounded-lg py-1 px-2 mr-2 my-2 cursor-pointer' onClick={() => setIsShow2(!isShow2)} title='Close window'/>
                        <EmojiPicker onEmojiClick={emoji2} />
                      </div>
                      :
                      <></>
                    }
                  </div>
                  <div className='w-full hidden'>
                    <FlexRow className='w-full justify-end p-2'>
                      {
                        value2 !== ''?
                        <Text className={`${value2.length > 1000 ? 'text-red-400' : 'text-green-100'}`}>
                          {value2?.length}/1000
                        </Text>
                        :
                        <Text className='text-secondary-200'>
                          0/1000
                        </Text>
                      }
                      <span className='text-secondary-200 font-semibold hover:underline cursor-pointer mx-4' onClick={clear} title='Clear this field'>
                        CLEAR
                      </span>
                      <span className='cursor-pointer' onClick={() => setIsShow2(!isShow2)} title='Click to use emoji picker'>
                        &#128522;
                      </span>
                    </FlexRow>
                  </div>
                  <FlexRow className='w-full justify-end mt-2'>
                  {
                    value2 !== ''?
                    <Text className={`${value2.length > 1000 ? 'text-red-400' : 'text-green-100'}`}>
                      {value2?.length}/1000
                    </Text>
                    :
                    <Text className='text-secondary-200'>
                      0/1000
                    </Text>
                  }
                  </FlexRow>
                  <div className='w-full'>
                    {children}
                  </div>
                  <button className='bg-primary-100 rounded-lg py-2 px-4 ml-auto mt-4 text-white block' type='submit'>
                    {loading? <i className="fad fa-spinner-third animate-spin text-2xl" /> : submitBtnText}
                  </button>
                  <button className='bg-primary-100 rounded-lg py-2 px-4 ml-auto mt-4 text-white block hidden' onClick={onClickSubmit}>
                    {submitBtnText}
                  </button>
                </form>
              </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};