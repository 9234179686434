import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import { FlexRow, Button, Text, Icons, ToastAssets } from '@core';
import { SHARE } from '@config';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  selectedProd: any;
}

export const AssetModal: FC<ModalProps> = ({ isOpen, onClose, selectedProd }) => {

  const [download, setDownload] = React.useState(false);
  const [url, setUrl] = React.useState<any>('');
  const [copied, setCopied] = React.useState<any>(false);
  const refLink = selectedProd?.affiliate_link;

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2] = React.useState('info');
  const [toastTitle2] = React.useState('Action');
  const [toastDesc2] = React.useState('Create a personalized marketing asset.');

  React.useEffect(() => {
    if (refLink) {
      setUrl(`${SHARE}${refLink}`);
    }
    setCopied(false);
  }, [refLink]);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied]);

  const handleDownloadImage = async () => {
    setDownload(true);
    const element: any = document.getElementById('qrcode');
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    link.href = data;
    link.download = 'QRCODE.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownload(false);
  };

  return (
    <>
    <ToastAssets
          description={toastDesc2}
          isOpen={isToastOpen2}
          onClose={() => setToastOpen2(false)}
          product={selectedProd}
          title={toastTitle2}
          toastKind={toastKind2}
          url={url}
    />
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
        onClose={() => onClose()}
      >
        <div className="h-full text-center flex justify-end">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
            >
              <FlexRow
                className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
              >
                <Dialog.Title as="h3">REFERRAL LINK</Dialog.Title>
                <i
                  className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                  onClick={() => onClose()}
                />
              </FlexRow>
              <div className="px-8 h-full w-full py-8 flex flex-col items-center overflow-y-auto overflow-x-hidden">
                <Text className="font-bold text-lg mb-3 text-blue-100 select-none flex items-center">
                  {url}
                  {
                    copied?
                    <div className='relative hidden'
                         onClick={() => {
                         setCopied(true);
                         navigator.clipboard.writeText(`${url}`);
                         }}
                         title='Click to copy link again'
                    >
                      <span className='absolute -top-full text-xs bg-grey-500 p-1 rounded hidden'>COPIED!</span>
                      <i className='fas fa-copy ml-4 text-green-100 cursor-pointer'/>
                    </div>
                    :
                    <i className='fas fa-copy ml-4 text-secondary-200 cursor-pointer hidden'
                       onClick={() => {
                         setCopied(true);
                         navigator.clipboard.writeText(`${url}`);
                       }}
                       title='Click to copy link'
                    />
                  }
                  
                </Text>
                <QRCode
                  bgColor="#ffffff"
                  fgColor="#223141"
                  id="qrcode"
                  imageSettings={{
                    src: Icons.xffiliateQrcode,
                    height: 80,
                    width: 80,
                  }}
                  includeMargin={false}
                  level="H"
                  renderAs="canvas"
                  size={280}
                  value={`${url}`}
                />
                <Text className="font-poppins font-normal text-base text-grey-100  mt-6 mb-3">
                  This scannable code is a shortcut to your referral link.
                </Text>
                <Text className="font-poppins font-normal text-base text-grey-100  mb-5">
                  Place the code anywhere people can scan it with their phones: brochures, event
                  posters, invoices... The ideas are limitless.
                </Text>

              </div>
              <hr />
              <div className="flex flex-col  mx-8 my-5">
                <FlexRow className='w-full justify-between'>
                  <Button
                    className='w-4/5 justify-center items-center mb-5 bg-blue-100'
                    onClick={() => {
                      onClose()
                      setToastOpen2(true)
                    }}
                  >
                    SHARE MY LINK
                  </Button>
                  <Button
                    className={`relative w-1/5 justify-center items-center mb-5 phone:mr-0 phone:py-3 laptop:py-4 ${
                      copied ? 'bg-green-100' : 'bg-blue-100'
                    }`}
                    onClick={() => {
                      setCopied(true);
                      navigator.clipboard.writeText(`${url}`);
                    }}
                  >
                    <i className='fas fa-copy text-lg text-white' />
                    <span className={`absolute -top-3/4 text-xs text-primary-100 bg-grey-500 p-2 border border-secondary-200 rounded-md ${copied? '' : 'hidden'}`}>COPIED!</span>
                  </Button>
                </FlexRow>
                <Button
                  className="w-full justify-center items-center bg-blue-100 phone:mr-0"
                  isLoading={download}
                  onClick={handleDownloadImage}
                >
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
    </>
  );
};