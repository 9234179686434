import React, { FC } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import validator from 'validator';
import { batch, useSelector } from 'react-redux';
import { useCookie } from '@hooks';
import { API } from '@config';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import {
  Icons,
  H2Custom,
  Info,
  Div,
  Flex,
  FlexRow,
  InputCustom,
  Checkbox,
  PasswordInputCustom,
  ButtonCustom,
  Text,
  Toast,
  ToastBanner,
} from '@core';

export const AffiliateSignUp: FC<any> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { search } = useLocation();

  const newDate: any = new Date();
  const userNum: number = newDate.getTime();

  const query = new URLSearchParams(search);
  const merchantId = query.get('merchant');
  const merchantStore = query.get('merchantStore');

  const [cookie, updateCookie] = useCookie('token', '0');
  const [userId, updateUserId] = useCookie('userId', '0');
  const [email, setEmail] = React.useState('');
  const username: any = `user${userNum}`;
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [validEmail, setValidEmail] = React.useState('');
  const [validPassword, setValiPassword] = React.useState('');
  const [validConfirmPassword, setValisetConfirmPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [agreement, setAgreement] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');
  const [isToastOpenB, setToastOpenB] = React.useState(false);

  const [details, setDetails] = React.useState<any>([]);
  const [imageSource, setImageSource] = React.useState();

  const [terms, setTerms] = React.useState('');
  const [policy, setPolicy] = React.useState('');
  const [colorsFont, setColorsFont] = React.useState('#073763');
  const [colorsButton, setColorsButton] = React.useState('#073763');

  const [companyName, setCompanyName] = React.useState('Xffiliate');

  const onSubmit = () => {
    const isEmail = validator.isEmail(email);
    const isPassword = validator.isStrongPassword(password, {
      minLength: 6,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    const isPasswordSame = password === confirmPassword;
    setValidEmail(email === '' ? 'Email is required' : `${!isEmail ? 'Invalid email' : ''}`);
    setValiPassword(
      password === '' ? 'Password is required' : `${!isPassword ? 'Invalid password' : ''}`,
    );
    setValisetConfirmPassword(
      confirmPassword === ''
        ? 'Password is required'
        : `${password !== confirmPassword ? `Password don't match` : ''}`,
    );

    if (email !== '' && password !== '' && confirmPassword !== '') {
      if (isEmail && isPassword && isPasswordSame && agreement) {
        batch(async () => {
          setLoading(true);
          const data: any = await dispatch(
            usersActions.newUser({
              email,
              username,
              password,
              password_confirmation: confirmPassword,
              merchant_link: merchantId
            }),
          );
          if (data?.payload?.status === 200) {
            setToastTitle('Account Created');
            setToastDesc('Redirecting to onboarding.');
            setToastKind('success');
            setToastOpen(true);
            setLoading(false);
            const login: any = await dispatch(usersActions.login({ email, password }));
            updateCookie(`${login?.payload?.access_token}`, 24);
            updateUserId(`${login?.payload?.userInfo?.id}`, 24);
            await dispatch(usersActions.profile());
            setTimeout(() => {
              setToastOpen(false);
              navigate(`/affiliate/onboard/aboutyou?id=${data?.payload?.userId}&merchant=${merchantId}`);
            }, 2000);
          } else {
            setToastTitle('Invalid Input');
            setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
            setToastKind('error');
            setToastOpen(true);
            setLoading(false);
          }
        });
      }else if(!agreement){
        setToastKind('error');
        setToastTitle('INVALID');
        setToastDesc('Please check the agreements');
        setToastOpen(true);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
      fetch(`${API}/merchant-available-product/${merchantId}`)
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          if(data.data.length > 0){
            setImageSource(data.company_info.company_icon)
            setCompanyName(data.company_info.name)
            const tempKey = localStorage.getItem('signup')
            setDetails(data.data)
            if(data.files_info !== null){
              setTerms(data.files_info.TermsOfService)
              setPolicy(data.files_info.privacyPolicy)
            }if(data.custom_info !== null){
              setColorsFont(data.custom_info.font_colors)
              setColorsButton(data.custom_info.button_colors)
            }
            if(tempKey === null){
              localStorage.setItem('signup', 'processed');
              setToastOpenB(false)
            } 
          }else{
            navigate(`/affiliate/invite/${merchantId}`)
          }
        }else{
          navigate(`/affiliate/invite/${merchantId}`)
        }
      })
  }, [])

  React.useEffect(() => {
    if(user?.user_info?.role === 'affiliate'){
      navigate(`/affiliate/merchant_catalogs?merchant=${merchantId}&merchantStore=${merchantStore}&user=affiliate`)
    }
  })

  return (
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 overflow-y-auto h-full">
      <div className='hidden'>Unused {cookie}{userId}</div>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <ToastBanner
        BannerSubTitle='Affiliate marketing tailored for Filipino businesses. Run data-driven, scalable and effective referral marketing campaigns that drive real results.'
        BannerTitle='BECOME AN AFFILIATE PARTNER'
        description='Hi there, Welcome to Xffiliate!'
        details={details}
        ImageAlt='Logo'
        ImageSource={imageSource}
        isOpen={isToastOpenB}
        onClose={() => setToastOpenB(false)}
        queryInfo={merchantStore || 'Merchants'}
        style={colorsButton}
        title='GREETINGS'
        toastKind='info'
      />
      
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className={`w-12 h-12 mr-3 select-none ${imageSource? 'rounded-full' : ''}`} src={imageSource || Icons.xffiliate} />
        <div className="mr-auto text-3xl font-semibold select-none font-robot font-regular" style={{color: colorsFont}}>
          {companyName || 'Xffiliate'}
        </div>
      </Div>
      <hr className="text-secondary-300" />
      <Div
        className="flex flex-col items-center my-12 tabletWide:my-0 tabletWide:justify-center tabletWide:h-full w-full"
      >
        <H2Custom style={colorsFont}>Sign Up</H2Custom>
        <Info className="text-secondary-100 mt-2">
          Already have an account?{' '}
          <Link className="underline text-primary-100" to={`/affiliate/onboard/login?merchant=${merchantId}&merchantStore=${merchantStore}`}>
            Login
          </Link>
        </Info>
        <Div className="phone:w-full medium:w-3/4 tabletWide:w-3/5 laptop:w-2/5 mt-10">
          <form>

          <InputCustom
              containerClass='w-full surfaceDuo:mr-2'
              errorLabel={validEmail}
              label="Email address *"
              name="email"
              onChange={setEmail}
              placeholder="Enter your email"
              style={colorsFont}
              type="email"
              validation={validEmail !== ''}
              value={email}
            />
          <FlexRow className='w-full phone:flex-col surfaceDuo:flex-row'>
            <PasswordInputCustom
              containerClass='w-full surfaceDuo:mr-2'
              label="Password *"
              name="password"
              onChange={setPassword}
              placeholder="Enter your Password"
              style={colorsFont}
              validation={validPassword !== ''}
              value={password}
            />
            <PasswordInputCustom
              containerClass='w-full surfaceDuo:ml-2'
              label="Confirm Password *"
              name="confirm-password"
              onChange={setConfirmPassword}
              placeholder="Enter your Password"
              style={colorsFont}
              validation={validConfirmPassword !== ''}
              value={confirmPassword}
            />
          </FlexRow>
            

            <Div
              className="flex tablet:flex-row ml-10 tablet:items-center mb-5 font-poppins 
            text-base phone:text-sm tablet:text-base phone:flex-col phone:items-start"
            >
              <ul className="list-disc">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 6 characters</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 1 capital letter</Text>
                </li>
              </ul>
              <ul className="list-disc tablet:ml-20 phone:ml-0">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 1 number</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${
                          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                            ? 'text-green-100'
                            : 'text-red-100'
                        }`
                  }
                >
                  <Text className="mb-1">At least 1 special character</Text>
                </li>
              </ul>
            </Div>

            <ButtonCustom className="w-full py-5 justify-center" isLoading={loading} onClick={onSubmit} style={colorsButton}>
              Sign Up Now
            </ButtonCustom>

            <FlexRow className='items-start mt-8'>
              <Checkbox
                checked={agreement}
                className=''
                containerClass="mb-auto"
                id="agreements"
                label=""
                onClick={() => setAgreement(!agreement)}
              />
              <Text className="text-base text-left">
                By creating an account, you agree to {merchantStore || 'Xffiliate'}’s{' '}
                <Link className="font-robot font-regular underline text-primary-100 cursor-pointer" target='_blank' to={`/files?path=${terms}&merchantStore=${companyName}&merchant=${merchantId}`}>
                  Terms of Service
                </Link>{' '}
                and {merchantStore || 'Xffiliate'}’s{' '}
                <Link className="font-robot font-regular underline text-primary-100 cursor-pointer" target='_blank' to={`/files?path=${policy}&merchantStore=${companyName}&merchant=${merchantId}`}>
                  Privacy Policy
                </Link>
              </Text>
            </FlexRow>

          </form>
        </Div>
      </Div>
    </Flex>
  );
};