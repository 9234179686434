import React, { FC } from 'react';
import { batch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectUser } from '@redux';
import { Flex, FlexRow, Text, ToastPermissions } from '@core';
import { NavBarSuperAdmin, CreateAdminModal, UpdateAdminModal, AdminData, PaginationV2 } from '@components';
import { getSuperAdmins, getAdminPermissions } from '@services';
import { adminWhiteList } from '@constants';

export const AdminManagement: FC<any> = () => {
	
	const test: any = 'Manage Users';
	const user = useSelector(selectUser);
	const navigate = useNavigate();

	const { search } = useLocation();
	const query = new URLSearchParams(search)
	const actionQuery = query.get('action');
	const adminID = query.get('admin');

	const [createUsers , setCreateUsers] = React.useState(false);
	const [readUsers , setReadUsers] = React.useState(false);
	const [updateUsers , setUpdateUsers] = React.useState(false);
	const [removeUsers , setRemoveUsers] = React.useState(false);
	const [permitted, setPermitted] = React.useState(false);
	const [createAdminOpen, setCreateAdminOpen] = React.useState(false);
	const [updateAdminOpen, setUpdateAdminOpen] = React.useState(false);
	const [isToastOpen, setToastOpen] = React.useState(false);

	const [adminsList, setAdminsList] = React.useState<any>([]);

	const [currentPage, setCurrentPage] = React.useState(1);
	const [articlesPerPage] = React.useState(5);
	const indexOfLastArticle = currentPage * articlesPerPage;
	const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

	const currentAccounts = adminsList.slice(indexOfFirstArticle, indexOfLastArticle);

	const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

	React.useEffect(() => {
	  batch(async () => {
	    const useradmin = await getAdminPermissions(user.user_info.id)
	    if(useradmin.data.status === 1){
	      setCreateUsers(useradmin?.data?.permissions_info?.user_create === 1)
	      setReadUsers(useradmin?.data?.permissions_info?.user_view === 1)
	      setRemoveUsers(useradmin?.data?.permissions_info?.user_archive === 1)
	      setUpdateUsers(useradmin?.data?.permissions_info?.user_update === 1)
	      setPermitted(useradmin?.data?.permissions_info.permission_write === 1)  
	    }
	  })
	}, [user])

	React.useEffect(() => {
		batch(async () => {
			const admins = await getSuperAdmins();
			if(admins.data.status === 1){
				setAdminsList(admins?.data?.user_info)	
			}else{
				setAdminsList([])
			}
		})
	}, [test])

	React.useEffect(() => {
		if(actionQuery === 'updated'){
			batch(async () => {
				const admins = await getSuperAdmins();
				if(admins.data.status === 1){
					setAdminsList(admins?.data?.user_info)	
					navigate('/admin/manage_users')
					setCreateAdminOpen(false)
					setUpdateAdminOpen(false)
					setToastOpen(false)
				}else{
					setAdminsList([])
					navigate('/admin/manage_users')
					setCreateAdminOpen(false)
					setUpdateAdminOpen(false)
					setToastOpen(false)
				}
			})
		}else if(actionQuery === 'toUpdate'){
			setUpdateAdminOpen(true)
		}
	})

return(
	<>
	<ToastPermissions
	  descriptionP='View Permissions'
	  id={227}
	  isOpenP={isToastOpen}
	  onClose={() => setToastOpen(false)}
	  permitted={permitted}
	  titleP='INFO'
	  toastKindP='info'
	/>
	<NavBarSuperAdmin/>
	<CreateAdminModal
		isOpen={createAdminOpen}
		onClose={() => setCreateAdminOpen(false)}
	/>
	<UpdateAdminModal
		id={adminID}
		isOpen={updateAdminOpen}
		onClose={() => setUpdateAdminOpen(false)} 
	/>
	<Flex className='mt-24 overflow-y-scroll scrollbar-hide phone:px-4 tablet:px-8 laptop:px-20'>
		
		<Text className="font-poppins font-semibold text-primary-100 text-2xl phone:mt-2 tablet:mt-8 mb-2">
		  {test}
		</Text>
		<hr className="text-grey-400 mb-4" />

		<FlexRow className='justify-between mt-4 phone:flex-col surfaceDuo:flex-row'>
		  <FlexRow className='phone:mr-auto surfaceDuo:mr-3'>
		    <Text className='font-semibold mr-3 text-primary-100'>All Users</Text>
		    <Text>{adminsList.length} Total &#8226; {adminsList.length} Active</Text>
		  </FlexRow>

		  <FlexRow className='phone:mx-0 phone:flex-col phone:mr-auto surfaceDuo:mr-3 surfaceDuo:flex-row surfaceDuo:ml-3'>
		    <form className='flex flex-row items-center phone:mt-4 surfaceDuo:mt-0 hidden'>
		      <input className='rounded-lg border-none w-32 outline-none' placeholder='Search' type='search' />
		      <i className="rounded fas fa-search text-base py-2 px-3 mx-2 hover:bg-grey-100" />
		    </form>

		    {
		    	createUsers?
		    	<button className='bg-primary-100 p-3 text-white hover:bg-blue-400 phone:mt-4 surfaceDuo:mt-0' onClick={() => setCreateAdminOpen(true)}>
		    	  Add Admin <i className="fas fa-plus text-white mx-3" />
		    	</button>
		    	:
		    	<button className='bg-secondary-200 p-3 text-white cursor-not-allowed phone:mt-4 surfaceDuo:mt-0'>
		    	  Add Admin <i className="fas fa-plus text-white mx-3" />
		    	</button>
		    }
		    
		  </FlexRow>
		</FlexRow>

		<FlexRow className='justify-between mt-8 hidden tabletWide:flex'>
		  <Text className='w-1/4 text-secondary-200 text-left font-semibold'>
		    Name
		  </Text>
		  <Text className='w-1/4 text-secondary-200 font-semibold'>
		    Email Address
		  </Text>
		  <Text className='w-1/4 text-secondary-200 font-semibold'>
		    Contact Number
		  </Text>
		  <Text className='w-1/4 text-secondary-200 font-semibold'>
		    Actions
		  </Text>
		</FlexRow>
		<Text className='text-secondary-200 text-center font-semibold mt-8 tabletWide:hidden'>
			List of Admins
		</Text>
		<hr className="text-grey-400 mt-2 mb-4" />

		{
			adminsList.length > 0 ?
			<>
			<div className='w-full'>
			{
				adminsList.map((admin:any) => {
					if(adminWhiteList.includes(admin.email) && currentPage === 1){
						return (<AdminData
							contact={admin.contact}
							email={admin.email}
							id={admin.user_id}
							key={admin.user_id}
							name={`${admin.firstname} ${admin.lastname}`}
							permitted={permitted}
							readUsers={readUsers}
							removeUsers={removeUsers}
							updateUsers={updateUsers}
						/>)
					}
					return <></>
				})
			}
			</div>
			<div className='w-full'>
			{
				currentAccounts.map((admin:any) => {
					if(!adminWhiteList.includes(admin.email)){
						return (<AdminData
							contact={admin.contact}
							email={admin.email}
							id={admin.user_id}
							key={admin.user_id}
							name={`${admin.firstname} ${admin.lastname}`}
							permitted={permitted}
							readUsers={readUsers}
							removeUsers={removeUsers}
							updateUsers={updateUsers}
						/>)
					}
					return <></>
				})
			}
			</div>
			</>
			:
			<FlexRow className='w-full px-10'>
			  <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
			    You have no admins registered yet.
			  </Text>
			</FlexRow>
		}

		<div className='w-full mt-12'>
		<PaginationV2
		  currentPage={currentPage}
		  itemsPerPage={articlesPerPage}
		  paginate={paginate}
		  totalItems={adminsList.length}
		/>
		</div>

	</Flex>
	</>
	)
};