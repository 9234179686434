import React from 'react';
import { batch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import visa from '../../assets/icons/visa-logo.png';
import bancnet from '../../assets/icons/bancnet-big.png';
import masterCard from '../../assets/icons/mastercard-logo.png';
import xendit from '../../assets/icons/xendit-big.png';
import { AffiliateLogin } from '../Authentication';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import {
  Flex,
  FlexRow,
  Icons,
  UserDropdownV2,
  Text,
  Div,
  Button,
  Checkbox,
  Toast,
  Radio,
} from '@core';
import { pricingPro, pricingPlus } from '@constants';
import { getWixCheckoutUrl, subscription2 } from '@services';
import { useSubscription } from '@hooks';

export const Subscription = () => {
  const dispatch = useAppDispatch();
  const userSubscription = useSubscription();
  const user = useSelector(selectUser);
  const [agreement, setAgreement] = React.useState(false);
  const [choosePlan, setChoosePlan] = React.useState('PRO PLAN');

  const [description, setDescription] = React.useState('');
  const [amount, setAmount] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');
  const [wixCheckoutUrl, setWixCheckoutUrl] = React.useState();
  const [isWixUser, setIsWixUser] = React.useState<boolean>(false);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
      const userInfo = await dispatch(usersActions.profile());
      const isWixUserTemp = userInfo.payload?.data.is_wix_user;
      setIsWixUser(isWixUserTemp);
      if (isWixUserTemp) {
        const data: any = await getWixCheckoutUrl();
        const checkoutUrl = data?.data?.checkoutUrl;
        setWixCheckoutUrl(checkoutUrl);
      }
    });
  }, [dispatch, batch, usersActions]);

  React.useEffect(() => {
    if (choosePlan === 'PRO PLAN') {
      setDescription('Xffiliate Pro Plan Subscription');
      setAmount(999);
    } else if (choosePlan === 'PLUS PLAN') {
      setDescription('Xffiliate Plus Plan Subscription');
      setAmount(495);
    }
  }, [choosePlan]);

  const onPay = async () => {
    setLoading(true);
    if (!user?.email_confirmed) {
      setToastKind('error');
      setToastTitle('Email not verified');
      setToastDesc('Please verify email to subscribe');
      setToastOpen(true);
      setLoading(false);
    } else if (agreement === false) {
      setToastKind('error');
      setToastTitle('INVALID');
      setToastDesc('Please check the agreements');
      setToastOpen(true);
      setLoading(false);
    } else {
      const res = await subscription2({ description, amount });

      if (res.data.status === 1) {
        setLoading(false);
        window.open(`/merchant/payment?invoice=${res.data.data.last_created_invoice_url}`);
      }
    }
  };

  return (
    <>
      {console.log(wixCheckoutUrl, 'check me checkout url')}
      {user?.user_info?.role === 'merchant' ? (
        <Flex className="laptop:px-20 tablet:px-10 phone:px-5">
          <Toast
            description={toastDesc}
            isOpen={isToastOpen}
            onClose={() => setToastOpen(false)}
            title={toastTitle}
            toastKind={toastKind}
          />
          <FlexRow className="py-7">
            <Link className="mr-auto flex flex-row items-center" to="/merchant/dashboard">
              <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
              <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none phone:hidden tablet:block">
                Xffiliate
              </Text>
              <Text className="italic text-grey-100 pl-1 font-light self-end pb-2 phone:hidden tablet:block">
                Merchant
              </Text>
            </Link>
            <UserDropdownV2 info={user.user_info} isWhite noHidden user={user} />
          </FlexRow>
          <hr className="text-secondary-300" />

          <Flex className="laptop:px-56 tabletWide:px-20 tablet:px-10 pt-10 pb-20 overflow-y-scroll scrollbar-hide">
            <FlexRow className="w-full mt-10 phone:flex-col tabletWide:flex-row">
              {choosePlan === 'PRO PLAN' ? (
                <>
                  <Flex className="medium:px-10 tabletWide:px-0">
                    <Text
                      className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-left mb-5"
                    >
                      Why you should upgrade to
                      <span className="text-orange-100"> Xffiliate PRO</span>!
                    </Text>
                    <Text className="text-grey-100 text-lg mb-10">What's Included:</Text>
                    {pricingPro.map((value) => (
                      <Div className="text-grey-100 flex items-center text-base mb-2" key={value}>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        {value}
                      </Div>
                    ))}
                  </Flex>
                </>
              ) : (
                <>
                  <Flex className="medium:px-10 tabletWide:px-0">
                    <Text
                      className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-left mb-5"
                    >
                      Why you should upgrade to
                      <span className="text-orange-100"> Xffiliate PLUS</span>!
                    </Text>
                    <Text className="text-grey-100 text-lg mb-10">What's Included:</Text>
                    {pricingPlus.map((value) => (
                      <Div className="text-grey-100 flex items-center text-base mb-2" key={value}>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        {value}
                      </Div>
                    ))}
                  </Flex>
                </>
              )}

              <Div className="h-full border border-grey-100 mx-14" />

              <Div className="h-full phone:pt-10 tabletWide:pt-0">
                {/* Hide Subscription Plan Select */}
                <Text className="font-medium text-secondary-100 text-xl mb-2 hidden">
                  {`${userSubscription.status === 'PLUS PLAN' ? 'Your Plan' : 'Your Plan'}`}
                </Text>

                {/* Hide Subscription Plan Select */}
                <Div className="mx-3 hidden">
                  <div className={`${userSubscription.status === 'PLUS PLAN' ? 'hidden' : ''}`}>
                    <Radio
                      isChecked={choosePlan === 'PLUS PLAN'}
                      label="PLUS PLAN"
                      name="plusPlan"
                      onClick={() => setChoosePlan('PLUS PLAN')}
                      optionId="subscriptionPlan"
                    />
                  </div>
                  <Radio
                    isChecked={choosePlan === 'PRO PLAN'}
                    label="PRO PLAN"
                    name="plusPlan"
                    onClick={() => setChoosePlan('PRO PLAN')}
                    optionId="subscriptionPlan"
                  />
                </Div>

                {choosePlan === 'PRO PLAN' ? (
                  <>
                    <FlexRow className="mb-5 justify-between">
                      <Text className="text-2xl font-bold text-primary-100 mr-auto w-auto">
                        PRO Plan
                      </Text>
                      <Text className="text-orange-100 font-bold">60% off</Text>
                    </FlexRow>
                    {!isWixUser ? (
                      <Text className="font-medium text-secondary-100 text-xl mt-5">
                        Billed Now:
                        <span className="text-blue-100 mx-2">
                          <span className="text-xs line-through">PHP 2495</span> PHP 999
                        </span>
                      </Text>
                    ) : (
                      <Text className="font-medium text-secondary-100 text-xl mt-5">
                        Billed Now:
                        <span className="text-blue-100 mx-2">
                          <span className="text-xs line-through">$49</span> $29
                        </span>
                      </Text>
                    )}
                    <Text className="text-grey-100 mt-3">
                      You will be charged
                      {!isWixUser ? (
                        <span className="text-secondary-100 font-semibold"> PHP 999</span>
                      ) : (
                        <span className="text-secondary-100 font-semibold"> $29</span>
                      )}{' '}
                      monthly until you cancel your subscription.
                    </Text>
                    <Div className="flex flex-row items-start mt-6 mb-4">
                      <Checkbox
                        checked={agreement}
                        className="mt-2"
                        containerClass=""
                        id="agreements"
                        label=""
                        onClick={() => setAgreement(!agreement)}
                      />
                      <Text>
                        I have read and agree to Xffiliate's
                        <a
                          className="text-blue-100 mx-1 font-semibold hover:underline"
                          href="https://xffiliate.com/terms-of-service/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Terms of Service,
                        </a>
                        <a
                          className="text-blue-100 mr-1 font-semibold hover:underline"
                          href="https://xffiliate.com/privacy-policy/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Privacy Policy.
                        </a>
                      </Text>
                    </Div>
                    {!isWixUser && (
                      <>
                        <FlexRow className="w-full justify-center mt-5">
                          <img
                            alt="Bank Icon"
                            className="mx-2"
                            src={visa}
                            style={{ width: '68px', height: '68px' }}
                          />
                          <img
                            alt="Bank Icon"
                            className="mx-2"
                            src={bancnet}
                            style={{ width: '100px', height: '20px' }}
                          />
                          <img
                            alt="Bank Icon"
                            className="mx-2"
                            src={masterCard}
                            style={{ width: '68px', height: '40px' }}
                          />
                        </FlexRow>
                        <img
                          alt="Bank Icon"
                          className="mx-auto mt-3 block"
                          src={xendit}
                          style={{ width: '158px', height: '43px' }}
                        />
                      </>
                    )}
                    {isWixUser ? (
                      <button
                        className="text-white bg-primary-100 hover:bg-opacity-80 focus:ring-2 focus:ring-secondary-300 
                  font-poppins font-regular rounded-lg px-5 py-4 text-center
                   inline-flex items-center laptop:text-lg phone:text-sm tablet:text-base mr-2 w-full justify-center mt-5"
                        disabled={loading}
                        onClick={() => {
                          if (agreement) {
                            window.open(wixCheckoutUrl);
                          } else {
                            setToastKind('error');
                            setToastTitle('INVALID');
                            setToastDesc('Please check the agreements');
                            setToastOpen(true);
                            setTimeout(() => setToastOpen(false), 3000);
                          }
                        }}
                      >
                        Pay now
                      </button>
                    ) : (
                      <Button
                        className="w-full justify-center mt-5"
                        isLoading={loading}
                        onClick={onPay}
                      >
                        Pay now
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <FlexRow className="mb-5">
                      <Text className="text-2xl font-bold text-primary-100 mr-auto w-auto">
                        PLUS Plan
                      </Text>
                      <Text className="text-lg font-semibold text-secondary-100 w-auto text-center">
                        <span className="text-blue-100 mx-2">PHP 495</span>
                        per month
                      </Text>
                    </FlexRow>
                    <Text className="font-medium text-secondary-100 text-xl mt-5">
                      Billed Now:
                      <span className="text-blue-100 mx-2">PHP 495</span>
                    </Text>
                    <Text className="text-grey-100 mt-3">
                      You will be charged
                      <span className="text-secondary-100 font-semibold"> PHP 495</span> monthly
                      until you cancel your subscription.
                    </Text>
                    <Div className="flex flex-row items-start mt-6 mb-4">
                      <Checkbox
                        checked={agreement}
                        className="mt-2"
                        containerClass=""
                        id="agreements"
                        label=""
                        onClick={() => setAgreement(!agreement)}
                      />
                      <Text>
                        I have read and agree to Xffiliate's
                        <a
                          className="text-blue-100 mx-1 font-semibold hover:underline"
                          href="https://xffiliate.com/terms-of-service/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Terms of Service,
                        </a>
                        <a
                          className="text-blue-100 mr-1 font-semibold hover:underline"
                          href="https://xffiliate.com/privacy-policy/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Privacy Policy.
                        </a>
                      </Text>
                    </Div>
                    <FlexRow className="w-full justify-center mt-5">
                      <img
                        alt="Bank Icon"
                        className="mx-2"
                        src={visa}
                        style={{ width: '68px', height: '68px' }}
                      />
                      <img
                        alt="Bank Icon"
                        className="mx-2"
                        src={bancnet}
                        style={{ width: '100px', height: '20px' }}
                      />
                      <img
                        alt="Bank Icon"
                        className="mx-2"
                        src={masterCard}
                        style={{ width: '68px', height: '40px' }}
                      />
                    </FlexRow>
                    <img
                      alt="Bank Icon"
                      className="mx-auto mt-3 block"
                      src={xendit}
                      style={{ width: '158px', height: '43px' }}
                    />
                    <Button
                      className="w-full justify-center mt-5"
                      isLoading={loading}
                      onClick={onPay}
                    >
                      Pay now
                    </Button>
                  </>
                )}
              </Div>
            </FlexRow>
          </Flex>
        </Flex>
      ) : (
        <AffiliateLogin />
      )}
    </>
  );
};
