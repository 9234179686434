import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

export const deletePartner = async (id: number) => {
  const token = getCookie('token');

  return axios({
    method: 'DELETE',
    url: `${API}/remove-affiliate/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const deleteApplication = async (id: number) => {
  const token = getCookie('token');

  return axios({
    method: 'DELETE',
    url: `${API}/remove-application/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};