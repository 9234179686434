import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { Flex, Text, FlexRow, Div } from '@core';
import { AdminRoleList, CreateInviteAdmin } from '@components';
import { useSubscription, getCookie } from '@hooks';
import { API } from '@config';

export const AdminRole: FC<any> = () => {
  const user = useSelector(selectUser);
  const subscription = useSubscription();
  const navigate = useNavigate();
  const token = getCookie('token');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const [adminList, setAdminList] = React.useState([]);

  const [createInviteOpen, setCreateInviteOpen] = React.useState(false);

  React.useEffect(() => {
    if (actionQuery === 'updated') {
      navigate('/merchant/settings');
      fetch(`${API}/get-merchant-users`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 1) {
            setAdminList(data.data);
            setCreateInviteOpen(false);
          } else {
            setAdminList([]);
            setCreateInviteOpen(false);
          }
        });
    }
  }, [actionQuery]);

  React.useEffect(() => {
    fetch(`${API}/get-merchant-users`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          setAdminList(data.data);
        } else {
          setAdminList([]);
        }
      });
  }, [token]);

  return (
    <Flex className="desktop:pl-16 phone:pt-0 pb-16 laptop:pr-20 phone:px-5">
      <CreateInviteAdmin isOpen={createInviteOpen} onClose={() => setCreateInviteOpen(false)} />

      <Text className="font-poppins font-semibold text-primary-100 text-xl phone:mt-3 tablet:mt-8 mb-2">
        Admin Role Assignment
      </Text>
      <hr className="text-grey-400" />

      <FlexRow className="justify-between mt-4 phone:flex-col surfaceDuo:flex-row w-full">
        <FlexRow className="w-full phone:flex-col surfaceDuo:w-auto mx-3 surfaceDuo:flex-row mt-4">
          <Text className="mr-auto">
            {adminList.length} Total &#8226;
            {subscription.status === 'FREE PLAN' ? ' 1 Allowable' : ' Unlimited Allowable'}
          </Text>
        </FlexRow>

        <FlexRow className="medium:mx-3 phone:flex-col surfaceDuo:flex-row mt-4 w-full surfaceDuo:w-auto">
          {subscription.status === 'FREE PLAN' && adminList.length >= 1 ? (
            <button className="bg-secondary-200 p-3 text-white phone:w-full surfaceDuo:w-auto mt-4 surfaceDuo:mt-0 rounded cursor-not-allowed">
              Add User <i className="fas fa-plus text-white mx-3" />
            </button>
          ) : (
            <button
              className="bg-primary-100 p-3 text-white hover:bg-blue-400 phone:w-full surfaceDuo:w-auto mt-4 surfaceDuo:mt-0 rounded"
              onClick={() => setCreateInviteOpen(true)}
            >
              Add User <i className="fas fa-plus text-white mx-3" />
            </button>
          )}
        </FlexRow>
      </FlexRow>

      <FlexRow className="phone:hidden medium:flex tablet:hidden tabletWide:flex justify-around mt-12">
        <Text className="w-2/5 text-lg text-secondary-200 text-center font-semibold">Name</Text>
        <Text className="w-1/5 text-lg text-secondary-200 text-center font-semibold">Role</Text>
        <Text className="w-2/5 text-lg text-secondary-200 text-center font-semibold">Actions</Text>
      </FlexRow>
      <hr className="text-grey-400 mt-2 mb-4 phone:hidden medium:flex tablet:hidden tabletWide:flex" />

      {adminList.length === 0 ? (
        <Div className="mt-4">
          <AdminRoleList
            adminEmail={`${user.user_info.email}`}
            adminName={`${user.user_info.firstname} ${user.user_info.lastname}`}
            adminRole="Owner"
            id="0"
            userId="0"
          />
          <FlexRow className="w-full">
            <Text className="text-secondary-200 bg-grey-500 py-10 text-center w-full">
              You have no admins registered.
            </Text>
          </FlexRow>
        </Div>
      ) : (
        <Div>
          <AdminRoleList
            adminEmail={`${user.user_info.email}`}
            adminName={`${user.user_info.firstname} ${user.user_info.lastname}`}
            adminRole="Owner"
            id="0"
            userId="0"
          />
          {adminList.map((admin: any, index: number) => (
            <AdminRoleList
              adminEmail={admin.email}
              adminName={admin.name}
              adminRole={admin.role}
              id={admin.id}
              key={index}
              userId={admin.user_id}
            />
          ))}
        </Div>
      )}
    </Flex>
  );
};
