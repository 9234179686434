import React, { FC } from 'react';
import { Div, Flex, FlexRow, Text, Icons } from '@core';

export const Billing: FC<any> = () => (
  <Flex
    className="laptop:pl-16 laptop:pt-9 phone:pt-0 pb-16 laptop:pr-20 phone:px-5 
  overflow-y-auto"
  >
    <Text className="font-poppins font-semibold text-xl mt-8 mb-3">Billing</Text>
    <hr className="text-grey-400 mb-10" />
    <FlexRow
      className="mb-10 phone:flex-col phone:items-start laptop:flex-row 
        laptop:items-center "
    >
      <FlexRow className="phone:w-full laptop:w-auto">
        <Text className="w-36 laptop:mr-36 phone:mr-auto text-base text-secondary-100 font-medium">
          Billing period
        </Text>
        <Text
          className="text-base text-blue-100 font-medium laptop:ml-auto phone:ml-0
        phone:block laptop:hidden"
        >
          Change
        </Text>
      </FlexRow>
      <Text
        className="text-base text-secondary-200 font-light phone:mt-2 phone:mb-3
      laptop:mt-0 laptop:mb-0"
      >
        Monthly
      </Text>
      <Text
        className="text-base text-blue-100 font-medium laptop:ml-auto phone:ml-0 
      phone:hidden laptop:block"
      >
        Change
      </Text>
    </FlexRow>
    <hr className="text-grey-400 mb-10" />
    <FlexRow
      className="mb-10 phone:flex-col phone:items-start laptop:flex-row 
        laptop:items-center "
    >
      <FlexRow className="phone:w-full laptop:w-auto">
        <Text className="w-36 laptop:mr-36 phone:mr-auto text-base text-secondary-100 font-medium">
          Billing period
        </Text>
        <Text
          className="text-base text-blue-100 font-medium laptop:ml-auto phone:ml-0
        phone:block laptop:hidden"
        >
          Change
        </Text>
      </FlexRow>
      <FlexRow
        className="phone:w-full laptop:w-auto phone:mt-4 phone:mb-3
      laptop:mt-0 laptop:mb-0"
      >
        <img alt="Master Card" src={Icons.masterCard} />
        <Div className="ml-5 w-full">
          <Text className="text-base text-secondary-100 font-medium">
            Mastercard ending in 4567
          </Text>
          <Text className="text-base text-secondary-200 font-light">Expiry 06/24</Text>
        </Div>
      </FlexRow>
      <Text
        className="text-base text-blue-100 font-medium laptop:ml-auto phone:ml-0 
      phone:hidden laptop:block"
      >
        Change
      </Text>
    </FlexRow>
    <Text className="font-poppins font-semibold text-xl mt-8 mb-3">Billing History</Text>
    <hr className="text-grey-400 mb-10" />
    <Flex className="mb-10">
      <FlexRow>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-medium
        phone:mr-auto phone:w-auto"
        >
          Date
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-medium
        phone:mr-auto phone:w-auto"
        >
          Invoice Number
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-0 mb-auto text-base text-secondary-200 font-medium
        phone:mr-0 phone:w-auto"
        >
          Amount
        </Text>
      </FlexRow>
      <hr className="text-grey-400 my-4" />
      <FlexRow>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-light
        phone:mr-auto phone:w-auto"
        >
          October 28, 2020
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-light
        phone:mr-auto text-left phone:w-auto"
        >
          XF - 856
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-0 mb-auto text-base text-secondary-200 font-light
        phone:mr-0 phone:w-auto"
        >
          PHP 2,500
        </Text>
      </FlexRow>
      <hr className="text-grey-400 my-4" />
      <FlexRow>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-light
        phone:mr-auto phone:w-auto"
        >
          October 28, 2020
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-light
        phone:mr-auto phone:w-auto"
        >
          XF - 856
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-0 mb-auto text-base text-secondary-200 font-light
        phone:mr-0 phone:w-auto"
        >
          PHP 2,500
        </Text>
      </FlexRow>
      <hr className="text-grey-400 my-4" />
      <FlexRow>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-light
        phone:mr-auto phone:w-auto"
        >
          October 28, 2020
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-36 mb-auto text-base text-secondary-200 font-light
        phone:mr-auto phone:w-auto"
        >
          XF - 856
        </Text>
        <Text
          className="laptop:w-36 laptop:mr-0 mb-auto text-base text-secondary-200 font-light
        phone:mr-0 phone:w-auto"
        >
          PHP 2,500
        </Text>
      </FlexRow>
    </Flex>
  </Flex>
);
