import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import {
  productsActions,
  selectProducts,
  selectPartners,
  useAppDispatch,
  partnersActions,
} from '@redux';
import { FlexRow, Button, DropdownSelect, Text, Toast } from '@core';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const CreateLinkModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const products: any = useSelector(selectProducts);
  const partners: any = useSelector(selectPartners);
  const [selectedProduct, setSelectedProduct]: any = React.useState('');
  const [selectedPartner, setSelectedPartner]: any = React.useState('');
  const [productList, setProductList] = React.useState([]);
  const [partnerList, setPartnerList] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  React.useEffect(() => {
    batch(async () => {
      await dispatch(productsActions.productList());
      await dispatch(partnersActions.partnersList());
      const filterProducts: any = [];
      for (let i = 0; i < products.length; i++) {
        filterProducts.push({
          label: products[i].productCode,
          value: products[i].id,
          title: products[i].name,
          price: products[i].price,
          commission: `${products[i].commission}%`,
        });
        if (i === products.length - 1) {
          setProductList(filterProducts);
        }
      }
      const filterPartners: any = [];
      for (let i = 0; i < partners.length; i++) {
        filterPartners.push({
          label: `${partners[i].firstname} ${partners[i].lastname}`,
          value: partners[i].user_id,
          title: `${partners[i].firstname} ${partners[i].lastname}`,
          email: partners[i].email,
          number: partners[i].contact,
        });
        if (i === products.length - 1) {
          console.log('filterPartners', filterPartners);
          setPartnerList(filterPartners);
        }
      }
    });
  }, [dispatch, batch, productsActions, partnersActions, isOpen]);

  const onSubmit = () => {
    if (selectedPartner === '' && selectedProduct === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          partnersActions.newPartner({
            user_id: selectedPartner?.value,
            product_id: selectedProduct?.value,
            status: '1',
            role: 'affiliate',
          }),
        );
        if (data?.payload?.status === 200) {
          if (data?.payload?.data?.message === 'Affiliate was already added') {
            setToastTitle('Already Added');
            setToastDesc(`Affiliate was already added!`);
            setToastKind('error');
            setToastOpen(true);
            setLoading(false);
          } else {
            setToastTitle('Link Created');
            setToastDesc(`Link has been created.`);
            setToastKind('success');
            setToastOpen(true);
            setLoading(false);
            setTimeout(() => {
              onClose(false);
              navigate(
                `/merchant/dashboard?action=qrcode&referral_link=${data?.payload?.data?.referral_link}`,
              );
            }, 2000);
          }
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            navigate('/merchant/dashboard');
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
          align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
            text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">CREATE LINK</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
            cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate('/merchant/dashboard');
                    }}
                  />
                </FlexRow>
                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide">
                  <Text className="font-roboto font-bold text-lg text-grey-100 ml-2">
                    Product Information
                  </Text>
                  <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                    Select the product ID you want to add.
                  </Text>
                  <DropdownSelect
                    className="text-secondary-100 border border-secondary-100 py-5"
                    color="white"
                    current={selectedProduct?.label || ''}
                    items={productList}
                    onSelect={setSelectedProduct}
                    placeholder="Select a product"
                    width="w-full"
                  />
                  <Text className="font-roboto font-bold text-lg text-primary-100 ml-2 mt-3">
                    {selectedProduct?.title || ''}
                  </Text>
                  <Text className="font-roboto font-semibold text-lg text-secondary-100 ml-2">
                    {selectedProduct?.price || ''}
                  </Text>
                  <Text className="font-roboto font-semibold text-lg text-secondary-100 ml-2">
                    {selectedProduct?.commission || ''}
                  </Text>
                  <Text className="font-roboto font-bold text-lg text-grey-100 ml-2 mt-5">
                    Affiliate Information
                  </Text>
                  <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                    Select the affiliate you want to add.
                  </Text>
                  <DropdownSelect
                    className="text-secondary-100 border border-secondary-100 py-5 z-0"
                    color="white"
                    current={selectedPartner?.label || ''}
                    items={partnerList}
                    onSelect={setSelectedPartner}
                    placeholder="Select a partner"
                    width="w-full"
                  />
                  <Text className="font-roboto font-bold text-lg text-primary-100 ml-2 mt-3">
                    {selectedPartner?.title || ''}
                  </Text>
                  <Text className="font-roboto font-semibold text-lg text-secondary-100 ml-2">
                    {selectedPartner?.email || ''}
                  </Text>
                  <Text className="font-roboto font-semibold text-lg text-secondary-100 ml-2">
                    {selectedPartner?.number || ''}
                  </Text>
                  <FlexRow
                    className="px-5 py-6 bg-blue-300 font-roboto
              text-blue-100 rounded-lg mt-5"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      Be sure to complete all fields to create a link.
                    </Text>
                  </FlexRow>
                </div>
                <hr />
                <div className=" flex  mx-8 my-5">
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    CREATE
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
