import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { H1, H2, Info, Div, Flex, FlexCenter, Button, Text } from '@core';

export const Google: FC<any> = () => (
  <Flex className="px-20">
    <Div />
    <H1 className="py-8 text-primary-100">Xffiliate</H1>
    <hr className="text-secondary-300" />
    <FlexCenter>
      <Div className="w-4/12 mb-20">
        <H2 className="text-primary-100 text-center mb-3">Log in and start sharing</H2>
        <Info className="text-secondary-100 mt-2 text-center mb-10">
          You’re about to create a <b>new Xffiliate account</b> linked to your Google account.
        </Info>
        <Link to="/new/signup">
          <Button className="w-full py-5 justify-center">Create new Xffiliate account</Button>
        </Link>
        <Link to="/">
          <Button className="w-full py-5 justify-center bg-grey-100">Cancel</Button>
        </Link>
        <Text className="text-base text-center mt-5">
          <Link className="underline text-primary-100" to="/">
            <b>Wait!</b> I already have a Xffiliate account
          </Link>
        </Text>
      </Div>
    </FlexCenter>
  </Flex>
);
