import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

interface UPDATEAFFILIATE {
  id: number;
  link?: any;
  commission: string;
}

interface ASSET {
  id?: number | null;
  title?: string | null;
  content?: string | null;
  hashtag?: string | null;
  status?: string | null;
  hidden?: string | null; 
}

export const updateAffiliate = async (body: UPDATEAFFILIATE) => {
  const token = getCookie('token');

  return axios({
    method: 'PUT',
    url: `${API}/update-affiliate/${body.id}`,
    data: JSON.stringify({
      link: body.link,
      commission: body.commission,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const updateAffiliate2 = async (body: UPDATEAFFILIATE) => {
  const token = getCookie('token');

  return axios({
    method: 'PUT',
    url: `${API}/update-affiliate/${body.id}`,
    data: JSON.stringify({
      commission: body.commission,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const updateAsset = async (body: ASSET) => {
  const token = getCookie('token');

  return axios({
    method: 'PUT',
    url: `${API}/update-asset/${body.id}`,
    data: JSON.stringify({
      title: body?.title,
      content: body?.content,
      hashtag: body?.title,
      status: body?.status,
      hidden: body?.hidden,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};