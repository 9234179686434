import React, {FC} from 'react';
import { batch } from 'react-redux';
import { Div, Text, FlexRow, Button } from '@core';

interface DataProps {
	title: string;
	content: string;
	key: number;
	nohide: boolean;
}

export const AffiliateAssetData: FC<DataProps> = ({
	title,
	content,
	key,
	nohide,
}) => {

	const [isViewOpen, setIsViewOpen] = React.useState(false);
	const [isRemove, setIsRemove] = React.useState(false);
	const [copied, setCopied] = React.useState<any>(false);
	const [lines, setLines] = React.useState(0);

	React.useEffect(() => {
		batch(async() => {
			const numberOfLineBreaks: number = await (content.match(/\n/g)||[]).length;
			setLines(numberOfLineBreaks)
		})
	}, [isViewOpen])

	React.useEffect(() => {
		if(nohide){
			setIsRemove(false)
		}
	}, [nohide])

return(
	<>
	<div className={`mb-6 px-2 py-4 bg-grey-500 ${isRemove? 'hidden' : ''}`} key={key}>
		
		{
			isViewOpen?
			<Div>
				<Text className='text-lg text-secondary-200 my-2 flex flex-row items-center'>
					{title}
				</Text>
				<textarea
				  className='font-poppins text-secondary-200 phone:text-sm tablet:text-base focus:outline-none focus:border-none outline-none border-none bg-grey-500 rounded-lg block w-full px-0 overflow-y-auto resize-none'
				  disabled
				  rows={content.length > 100? 4 : lines+1}
				  value={content}
				/>
				<div className='cursor-pointer text-secondary-200 mt-2' onClick={() => setIsViewOpen(false)}>[Hide]</div>
			</Div>
			:
			<Div>
				<Text className='text-lg text-secondary-200 my-2 flex flex-row items-center'>
					{title}
				</Text>
				<Text className='text-secondary-200'>
					{content.substring(0, 50)}
					<span className={`mx-2 cursor-pointer ${content.length > 50 || lines > 0 ? '' : 'hidden'}`} onClick={() => setIsViewOpen(true)}>[More]</span>
				</Text>
			</Div>
		}

		<FlexRow className='w-full justify-start mt-4'>
			<Button className='phone:py-2 phone:px-0 phone:w-1/2 laptop:w-40 bg-white border border-secondary-200 phone:text-secondary-200 flex items-center justify-center' onClick={() => setIsRemove(true)}>
				Hide
			</Button>
			<Button
				className={`phone:py-2 phone:px-0 phone:w-1/2 laptop:w-40 flex items-center justify-center ${copied? 'phone:bg-green-100' : 'phone:bg-primary-100'}`}
				onClick={() => {
				  setCopied(true);
				  navigator.clipboard.writeText(`${content}`);
				}}
			>
				{copied ? 'COPIED' : 'COPY'}
			</Button>
		</FlexRow>

	</div>
	</>
)
}	