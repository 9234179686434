import React, { FC } from 'react';
import { ResponsiveBar } from '@nivo/bar';

const data = [
  {
    date: 'Jan 4',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 5',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 6',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 7',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 8',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 9',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 10',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 11',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 12',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 13',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 14',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 15',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 16',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 17',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 18',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 19',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 20',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 21',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 22',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 23',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 24',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 25',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 26',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
  {
    date: 'Jan 27',
    clicks: Math.floor(Math.random() * 100),
    clicksColor: '#073763',
  },
];

const values = ['Jan 8', 'Jan 13', 'Jan 18', 'Jan 23'];

const theme = {
  axis: {
    domain: {
      line: {
        stroke: '#777777',
        strokeWidth: 1,
      },
    },
  },
};

export const BarGraph: FC<any> = () => (
  <ResponsiveBar
    axisBottom={{
      tickSize: 15,
      tickPadding: 3,
      tickRotation: 0,
      tickValues: 'every month',
      format: (value) => {
        if (values.includes(value)) {
          return value;
        }
        return ' ';
      },
    }}
    axisLeft={{
      tickSize: 0,
      tickPadding: 0,
      tickRotation: 0,
      legend: 'Total Links Created',
      legendPosition: 'middle',
      legendOffset: -20,
      tickValues: [],
    }}
    axisRight={null}
    axisTop={null}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    borderWidth={1}
    colors={['#073763', '#3173AF']}
    data={data}
    // enableGridY={false}
    enableLabel={false}
    indexBy="date"
    indexScale={{ type: 'band', round: true }}
    keys={['clicks']}
    margin={{ top: 50, right: 0, bottom: 30, left: 60 }}
    maxValue={100}
    padding={0.1}
    role="application"
    theme={theme}
    valueScale={{ type: 'linear' }}
  />
);
