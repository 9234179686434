import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { batch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FlexRow, Text } from '@core';
import { usersActions, useAppDispatch, selectUser } from '@redux';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const SupremeAdminInformationModal: FC<ModalProps> = ({ isOpen, onClose }) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, [dispatch, batch, usersActions, isOpen]);

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
        onClose={() => {
          onClose();
          navigate(`/admin/dashboard`);
        }}
      >
        <div className="h-full text-center flex justify-end">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
            >
              <FlexRow
                className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
              >
                <Dialog.Title as="h3">ACCOUNT</Dialog.Title>
                <i
                  className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                  onClick={() => {
                    onClose();
                    navigate(`/admin/dashboard`);
                  }}
                />
              </FlexRow>
              <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide">
                <Text className="font-poppins font-semibold text-xl text-blue-100">
                    Account Information
                </Text>
                <Text className="font-poppins font-semibold text-lg text-secondary-100 mt-5">
                  Hello {`${user.user_info.firstname} ${user.user_info.lastname}!`}
                </Text>
                <Text className="font-poppins font-normal text-base text-grey-100 mt-3">
                  Admin Role:<b className='ml-2'>{user.user_info?.role}</b>
                </Text>
                <Text className="font-poppins font-normal text-base text-grey-100 mt-3">
                  Your workspace was created on
                </Text>
                <Text className="font-poppins font-bold text-base text-grey-100 mt-1 mb-5">
                  {new Date(user.user_info?.created_at).toString().substring(0, 15)}
                </Text>
                
              </div>

            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};