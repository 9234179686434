import React from 'react';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { linksActions, useAppDispatch } from '@redux';
import { Flex, Div, Icons, Text } from '@core';
// import { MAIN } from '@config';

export const Clicked = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    batch(async () => {
      await dispatch(linksActions.ipAddress()).then(async (item: any) => {
        dispatch(
          linksActions.linkClicked({
            url: `${id}`,
            ip_address: item?.payload?.data?.IPv4,
          }),
        ).then((items) => (window.location.href = items?.payload?.data?.link));
      });
    });
  }, []);

  return (
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
      <Helmet>
        {/* <meta charSet="utf-8" />
        <meta content={Images.logo} property="og:image" />
        <meta content="image/png" property="og:image:type" />
        <meta content="website" property="og:type" />
        <meta content={MAIN} property="og:url" />
        <meta content="XFFILIATE - Affiliate Marketing" property="og:title" />
        <meta
          content="Affiliate marketing tailored for Filipino businesses."
          property="og:description"
        />
        <meta content="Affiliate marketing tailored for Filipino businesses." name="description" />
        <meta content="English" name="language" /> */}
      </Helmet>
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300 w-full" />
      <Flex className="items-center laptop:pt-32 phone:pt-20 tablet:w-500px phone:w-full">
        <Text className="font-semibold text-secondary-100 text-2xl mb-5">Almost there!</Text>
        <Text
          className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-10"
        >
          Please wait while the <span className="text-orange-100"> website</span> loads.
        </Text>
        <Text className="mb-10 text-center">
          This may take up to 30 seconds. If you encounter a error please reload the page. Thank
          you!
        </Text>
      </Flex>
    </Flex>
  );
};
