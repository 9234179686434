import React, { FC } from 'react';
import { Div, Text } from '@core';

interface DetailProps {
  affiliateName: string;
  referenceNum: string;
  individualAmount: number;
  status: string;
  fees: any;
  bank: string;
}

export const PaymentDetails: FC<DetailProps> = ({
  affiliateName,
  referenceNum,
  individualAmount,
  status,
  fees,
  bank,
}) => (
  <>
    <Div className='phone:flex phone:flex-row items-center justify-around'>

      <Div className='phone:flex phone:flex-col phone:w-1/2 tablet:hidden'>
        <Text
          className=" text-base text-secondary-200 font-light  w-full text-left"
        >
          Affiliate Name:
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light  w-full text-left"
        >
          Reference No:
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light  w-full text-left"
        >
          Deposited to:
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light  w-full text-left"
        >
          Net Amount:
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light  w-full text-left"
        >
          Fees:
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light  w-full text-left"
        >
          Status:
        </Text>
      </Div>

      <Div className='phone:flex phone:flex-col phone:w-1/2 tablet:flex-row tablet:w-full justify-around py-2' >
        <Text
          className=" text-base text-secondary-200 font-light w-full tablet:w-1/6 text-left tablet:text-center"
        >
          {affiliateName}
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light w-full tablet:w-1/6 text-left tablet:text-center"
        >
          {referenceNum}
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light w-full tablet:w-1/6 text-left tablet:text-center"
        >
          {bank}
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light w-full tablet:w-1/6 text-left tablet:text-center"
        >
          &#8369; {individualAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light w-full tablet:w-1/6 text-left tablet:text-center"
        >
          &#8369; {fees.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
        <Text
          className=" text-base text-secondary-200 font-light w-full tablet:w-1/6 text-left tablet:text-center"
        >
          {status}
        </Text>
      </Div>

    </Div>
    <hr className="text-grey-400 mb-4" />
  </>
);