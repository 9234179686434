import React, {FC} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import { Div, FlexRow, Text, Flex, Button, H2, ToastBanner, FilterDropdownV2, Icons } from '@core';
import { NavBarAffiliate, CatalogMerchant, Pagination } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';
import { MainCategoriesSelect } from '@constants';

export const MerchantCatalogs: FC<any> = () => {

	const { search } = useLocation();
	const token = getCookie('token');
	const navigate = useNavigate();
	const query = new URLSearchParams(search);
	const merchantId = query.get('merchant');
	const user = query.get('user');

	const [isToastOpenB, setToastOpenB] = React.useState(false);
	const [isShow, setIsShow] = React.useState(false);

	const [keyword, setKeyword] = React.useState('');
	const [isLoading, setLoading] = React.useState(false);
	const [iconLoaded, setIconLoaded] = React.useState(false);
	const [onCategoryTab, setOnCategoryTab] = React.useState(false);

	const [catalogsList, setCatalogsList] = React.useState<any>([]);
	const [mainList, setMainList] = React.useState<any>([]);
	const affiliates: any[] =  [];
	const resultList: any[] = [];
	const [affiliateCount, setAffiliateCount] = React.useState(0);

	const [imageSource, setImageSource] = React.useState(Icons.xffiliate2);
	const [merchantStore, setMerchantStore] = React.useState('Merchant');

	const [category, setCategory] = React.useState('All Categories');
  	const [subcategory, setSubCategory] = React.useState({
    		label: 'All Categories',
    		value: 'All Categories',
  		});

	const [currentPage, setCurrentPage] = React.useState(1);
	const [articlesPerPage] = React.useState(20);
	const indexOfLastArticle = currentPage * articlesPerPage;
	const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

	const currentCatalogs = catalogsList.slice(indexOfFirstArticle, indexOfLastArticle);

	const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

	const onProducts = () => {
		setCategory('All Categories')
		setSubCategory({
		   label: 'All Categories',
		   value: 'All Categories',
		 });
	}

	const onCategory = () => {
		setSubCategory({
		   label: 'All Categories',
		   value: 'All Categories',
		 });
	}

	const Catalogs = () => {
		setLoading(true)
		setIsShow(false)
		fetch(`${API}/merchant-available-product/${merchantId}`)
		.then(res => res.json())
		.then(data => {
		  if(data.status === 1){
		  	setMainList(data.data)
		  	setCatalogsList(data.data)
		  	setImageSource(data.company_info.company_icon)
		  	setMerchantStore(data.company_info.name)
		  	setLoading(false)
		  	setIsShow(true)
		  	data.data.forEach((item:any) => {
		  		affiliates.push(item.available_affiliate)
		  		const initialValue = 0;
		  		setAffiliateCount(
		  		  affiliates.reduce(
		  		    (previousValue, currentValue) => previousValue + currentValue,
		  		    initialValue
		  		  )
		  		)
		  	})
		  }
		})
	}

	const Explore = () => {
		navigate('/affiliate/catalogs')
	}

	const onSearch = (e:any) => {
		e.preventDefault();
		if(/[a-zA-Z]/.test(keyword)){
			resultList.splice(0,resultList.length)
			setIsShow(false)
			batch(async() => {
				await mainList.map((item:any) => {
				  if(item.name.toLowerCase().includes(keyword)){
				    resultList.push(item)
				    setCatalogsList(resultList)
				    setCurrentPage(1)
				  } else{
				    setCurrentPage(1)
				  }
				  return 1;
				})
				if(resultList.length === 0){
				  setIsShow(true)
				  setCatalogsList([])
				} else {
				  setIsShow(true)
				}
			})
		}
	}

	const onSearchClick = () => {
		if(/[a-zA-Z]/.test(keyword)){
			resultList.splice(0,resultList.length)
			setIsShow(false)
			batch(async() => {
				await mainList.map((item:any) => {
				  if(item.name.toLowerCase().includes(keyword)){
				    resultList.push(item)
				    setCatalogsList(resultList)
				    setCurrentPage(1)
				  } else{
				    setCurrentPage(1)
				  }
				  return 1;
				})
				if(resultList.length === 0){
				  setIsShow(true)
				  setCatalogsList([])
				} else {
				  setIsShow(true)
				}
			})
		}
	}

	React.useEffect(() => {
		setCatalogsList(mainList)	
	}, [keyword])

	React.useEffect(() => {
	  setIsShow(false)
	  fetch(`${API}/merchant-available-product/${merchantId}`)
	    .then(res => res.json())
	    .then(data => {
	      if(data.status === 1){
	      	setMainList(data.data)
	      	setCatalogsList(data.data)
	      	setImageSource(data.company_info.company_icon)
	      	setMerchantStore(data.company_info.name)
	      	setIsShow(true)
	      	data.data.forEach((item:any) => {
	      		affiliates.push(item.available_affiliate)
	      		const initialValue = 0;
	      		setAffiliateCount(
	      		  affiliates.reduce(
	      		    (previousValue, currentValue) => previousValue + currentValue,
	      		    initialValue
	      		  )
	      		)
	      	})
	      }
	    })
	}, [])

	React.useEffect(() => {
		if(user === 'affiliate'){
			setToastOpenB(false)
		}else{
			setToastOpenB(false)
		}
	}, [token])

	React.useEffect(() => {
		if(category !== 'All Categories'){
			if(category !== 'All Categories' && subcategory.label !== 'All Categories'){
					setIsShow(false)
					batch(async() => {
						resultList.splice(0,resultList.length)
						await mainList.map((item:any) => {
							  if(item.sub_category){
							  	if(item.sub_category.includes(subcategory.label)){
							  	  resultList.push(item)
							  	  setCatalogsList(resultList)
							  	  setCurrentPage(1)
							  	} else{
							  	  setCurrentPage(1)
							  	}
							  }
							  return 1;
							})
							if(resultList.length === 0){
							  setIsShow(true)
							  setCatalogsList([])
							} else {
							  setIsShow(true)
							}
					})
			}else{
				setIsShow(false)
				batch(async() => {
					resultList.splice(0,resultList.length)
					await mainList.map((item:any) => {
						  if(item.category){
						  	if(item.category.includes(category)){
						  	  resultList.push(item)
						  	  setCatalogsList(resultList)
						  	  setCurrentPage(1)
						  	} else{
						  	  setCurrentPage(1)
						  	}
						  }
						  return 1;
						})
						if(resultList.length === 0){
						  setIsShow(true)
						  setCatalogsList([])
						} else {
						  setIsShow(true)
						}
				})
			}
		}else{
			setCatalogsList(mainList)
		}
	}, [category, subcategory])

return(
	<>
	<ToastBanner
        BannerSubTitle='Affiliate marketing tailored for Filipino businesses. Run data-driven, scalable and effective referral marketing campaigns that drive real results.'
        BannerTitle='BECOME AN AFFILIATE PARTNER'
        description='Hi there, Welcome to Xffiliate!'
        details={catalogsList}
        ImageAlt='Logo'
        isOpen={isToastOpenB}
        onClose={() => setToastOpenB(false)}
        queryInfo={merchantStore || 'Merchants'}
        title='GREETINGS'
        toastKind='info'
  />
	<NavBarAffiliate />
	<Flex className='overflow-y-auto mt-24'>
		<FlexRow className='phone:w-full flex-col surfaceDuo:flex-row medium:w-10/12 mt-8 px-4 mx-auto'>
			<FlexRow className='flex-row w-full items-center'>
				<img
					alt='merchant'
					className='w-20 h-20 object-cover rounded-full shadow-xl phone:mr-4 surfaceDuo:mr-10'
					onError={() => setIconLoaded(true)}
					src={
					  iconLoaded
					    ? Icons.xffiliate2
					    : `${imageSource}`
					} 
				/>
				<div className='flex flex-col'>
					<Text className='surfaceDuo:text-xl font-semibold text-primary-100'>
						{merchantStore}
					</Text>
					<FlexRow className='mt-2'>
						<Text className='text-sm text-secondary-200'>
							<b className='mr-2'>{mainList.length}</b>
							Products
						</Text>
						<Text className='text-sm text-secondary-200 hidden'>
							<b className='mx-2'>{affiliateCount}</b>
							Affiliates
						</Text>
					</FlexRow>
				</div>
			</FlexRow>

			<button className='text-center text-white rounded-lg font-semibold py-2 phone:mt-8 surfaceDuo:mt-0 phone:w-full surfaceDuo:w-64 bg-primary-100 hover:bg-blue-100' onClick={Explore}>
				EXPLORE MERCHANTS
			</button>
		</FlexRow>

		<FlexRow className='mt-10 mx-auto px-4 phone:w-full phone:flex-col medium:w-10/12 medium:flex-row medium:items-center'>
			<H2
			  className="font-poppins font-bold text-primary-100 phone:mr-auto 
			phone:mb-3 medium:mb-0 w-full"
			>
			  MERCHANT PRODUCTS
			</H2>

			<FlexRow className='phone:w-full medium:w-auto justify-between'>
			<div className='px-5 py-2 rounded-full border border-primary-100 flex flex-row items-center phone:w-40 surfaceDuo:w-auto'>
				<i className="fas fa-search mx-2 p-2 rounded-full cursor-pointer bg-grey-500 hover:bg-grey-400" onClick={onSearchClick} title='Click icon or press enter to search'/>
				<form className='w-full' onSubmit={(e:any) => onSearch(e)}>
					<input
						className='placeholder-grey-700 outline-none border-none w-full'
						onChange={(e:any) => setKeyword(e.target.value)}
						placeholder='Search'
						value={keyword}
					/>
				</form>
			</div>
			 {category === 'All Categories' ? (
               <FilterDropdownV2
                 color="white"
                 current={category || ''}
                 items={MainCategoriesSelect}
                 onSelect={setCategory}
                 onSelectSub={setSubCategory}
                 placeholder="Select a product"
               />
             ) : (
               <>
                 <div
                   className={`inline-flex items-center justify-center px-5 border-0
            py-3 font-poppins text-base font-regular text-primary-100
            hover:text-red-100 cursor-pointer `}
                   onClick={() => {
                   	 setOnCategoryTab(false)
                     setCategory('All Categories')
                     setSubCategory({
                        label: 'All Categories',
                        value: 'All Categories',
                      });
                     }
                   }
                 >
                   Reset
                   <i className="fas fa-filter text-2xl ml-1" />
                 </div>
               </>
             )}
			</FlexRow>
		</FlexRow>

		{
		  category !== 'All Categories' && ( subcategory.label === 'All Categories' || onCategoryTab ) ?
		  <FlexRow className='phone:flex-col phone:w-full tablet:w-10/12 tablet:flex-row tablet:px-4 mt-8 mx-auto'>
		    <div className='font-robot font-regular text-primary-100 text-lg hidden tablet:block cursor-pointer' onClick={onProducts}>
		      Products
		    </div>
		    <i className="fas fa-angle-right ml-2 desktop:ml-4 hidden tablet:block"/>
		    <i className="fas fa-angle-right mr-2 desktop:mr-4 hidden tablet:block"/>
		    <Text className='text-primary-100 text-lg'>
		      {category}
		    </Text>
		  </FlexRow>
		  :
		  <></>
		}

		{
		  subcategory.label !== 'All Categories' && !onCategoryTab ?
		  <FlexRow className='phone:flex-col phone:w-full tablet:w-10/12 tablet:flex-row tablet:px-4 mt-8 mx-auto'>
		    <div className='font-robot font-regular text-primary-100 text-lg hidden tablet:block cursor-pointer' onClick={onProducts}>
		      Products
		    </div>
		    <i className="fas fa-angle-right ml-2 desktop:ml-4 hidden tablet:block"/>
		    <i className="fas fa-angle-right mr-2 desktop:mr-4 hidden tablet:block"/>
		    <div
		      className='font-robot font-regular text-primary-100 text-lg cursor-pointer'
		      onClick={onCategory}>
		      {category}
		    </div>
		    <i className="fas fa-angle-right ml-2 desktop:ml-4 hidden tablet:block"/>
		    <i className="fas fa-angle-right mr-2 desktop:mr-4 hidden tablet:block"/>
		    <Text className='text-primary-100 text-lg'>
		      {subcategory.label}
		    </Text>
		  </FlexRow>
		  :
		  <></>
		}

		{
			catalogsList.length > 0 && isShow?
			<>
			  <Div
			    className="grid desktop:grid-cols-4 laptop:grid-cols-4 medium:grid-cols-2 tablet:grid-cols-2 tabletWide:grid-cols-3 phone:grid-cols-1 py-10 gap-y-7 tabletWide:w-11/12 laptop:w-10/12 mx-auto"
			  >
			    {currentCatalogs
			      ?.slice(0)
			      ?.reverse()
			      ?.map((item: any) => (
			        <CatalogMerchant
			          category={item?.category}
			          commision={item?.commission?.replace('%', '')}
			          id={item?.id}
			          image={item?.image}
			          key={item?.id}
			          link={item?.link}
			          remaining={item?.available_affiliate || 0}
			          status={item?.status}
			          title={item?.name}
			        />
			      ))}
			  </Div>
			  <div className="flex justify-center">
			    <Pagination
			      currentPage={currentPage}
			      itemsPerPage={articlesPerPage}
			      paginate={paginate}
			      totalItems={catalogsList.length}
			    />
			  </div>
			</>
			:
			<Div className="w-full h-full flex flex-col items-center justify-center">
			{
				isShow?
				<>
				<Text className="font-semibold text-secondary-100 text-2xl text-center mb-5">Ooops..</Text>
				<Text
				  className="font-poppins font-bold surfaceDuo:text-4xl text-primary-100 text-center mb-10"
				>
				  No available <span className="text-orange-100"> products</span>.
				</Text>
				<Text className="mb-10 text-center">
				  If you encounter an error please reload the page. Thank you!
				</Text>
				<Button isLoading={isLoading} onClick={Catalogs}>
				  Reload
				</Button>
				</>
			    :
			    <>
			    <Text className="font-semibold text-secondary-100 text-2xl mb-5">Please wait</Text>
              	<Text className="font-poppins font-bold surfaceDuo:text-4xl text-primary-100 text-center mb-10">
                	<i className="fad fa-spinner-third animate-spin surfaceDuo:text-4xl mr-3" />
                	Loading <span className="text-orange-100"> products</span>
              	</Text>
			    </>
			}
			</Div>
		}
		  
	</Flex>
	</>
)
}