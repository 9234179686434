import React, { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { batch } from 'react-redux';
import { FlexRow, Text, Icons, Flex, Checkbox, Toast } from '@core';
import { getAdminPermissions, createPermissions, updatePermissions } from '@services';

interface ButtonProps {
  className?: string;
  titleP: string;
  onClose: any;
  descriptionP: string;
  isOpenP: boolean;
  toastKindP: string;
  id: number;
  permitted: boolean;
}

export const ToastPermissions: FC<ButtonProps> = ({
  titleP, descriptionP, onClose, isOpenP, toastKindP, id, permitted
}) => {
  let color = 'green-100';

  let icon = 'fa-check';
  if (toastKindP === 'success') {
    color = 'green-100';
    icon = 'fa-check';
  } else if (toastKindP === 'error') {
    color = 'red-100';
    icon = 'fa-times';
  } else if (toastKindP === 'warning') {
    color = 'yellow-100';
    icon = 'fa-exclamation';
  } else if (toastKindP === 'info') {
    color = 'primary-100';
    icon = 'fa-exclamation';
  }

  const navigate = useNavigate();

  const [readTransactions , setReadTrans] = React.useState(false);
  const [writeTransactions , setWriteTrans] = React.useState(false);

  const [readDisbursements , setReadDisbursements] = React.useState(false);
  const [writeDisburse , setWriteDisburse] = React.useState(false);

  const [readAccounts , setReadAccounts] = React.useState(false);
  const [writeAccounts , setWriteAccounts] = React.useState(false);

  const [readUsers , setReadUsers] = React.useState(false);
  const [updateUsers , setUpdateUsers] = React.useState(false);
  const [createUsers , setCreateUsers] = React.useState(false);
  const [removeUsers , setRemoveUsers] = React.useState(false);

  const [supreme, setSupreme] = React.useState(false);
  const [desc, setDesc] = React.useState('This admin has no permissions set yet.');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  React.useEffect(() => {
    batch(async () => {
      
      const permission = await getAdminPermissions(id);

      if(permission.data.status === 1){
         
        setSupreme(permission?.data?.permissions_info?.permission_write === 1)

        setReadTrans(permission?.data?.permissions_info?.transaction_view === 1)
        setWriteTrans(permission?.data?.permissions_info?.transaction_write === 1)

        setReadDisbursements(permission?.data?.permissions_info?.disbursement_view === 1)
        setWriteDisburse(permission?.data?.permissions_info?.disbursement_write === 1)

        setReadAccounts(permission?.data?.permissions_info?.account_view === 1)
        setWriteAccounts(permission?.data?.permissions_info?.account_write === 1)

        setReadUsers(permission?.data?.permissions_info?.user_view === 1)
        setCreateUsers(permission?.data?.permissions_info?.user_create === 1)
        setRemoveUsers(permission?.data?.permissions_info?.user_archive === 1)
        setUpdateUsers(permission?.data?.permissions_info?.user_update === 1)

        setDesc('')

      }else{
        setDesc('This admin has no permissions set yet.')
      }
    })
  }, [isOpenP])

  React.useEffect(() => {
    if(supreme){
      setReadTrans(true)
      setWriteTrans(true)
      setReadDisbursements(true)
      setWriteDisburse(true)
      setReadAccounts(true)
      setWriteAccounts(true)
      setReadUsers(true)
      setCreateUsers(true)
      setRemoveUsers(true)
      setUpdateUsers(true)
    }
  }, [supreme])

  const createPermission = async () => {
    setLoading(true)
    batch(async() => {
      const create = await createPermissions({
        user_id: id,
        user_create: createUsers? 1 : 0,
        user_view: readUsers? 1 : 0,
        user_archive:removeUsers? 1 : 0,
        user_update: updateUsers? 1 : 0,
        transaction_view: readTransactions? 1 : 0,
        transaction_write: writeTransactions? 1 : 0,
        disbursement_view: readDisbursements? 1 : 0,
        disbursement_write: writeDisburse? 1 : 0,
        account_view: readAccounts? 1 : 0,
        account_write: writeAccounts? 1 : 0,
        permission_write: supreme? 1 : 0,
      })
      console.log(create)
      if(create.data.status === 1){
        setLoading(false)
        setToastTitle('SUCCESS');
        setToastDesc('Permissions created.');
        setToastKind('success');
        setToastOpen(true);
        navigate('/admin/manage_users?action=updated')
      }else{
        setLoading(false)
        setToastTitle('Error');
        setToastDesc( create?.data?.message || 'Something went wrong. Try again.');
        setToastKind('error');
        setToastOpen(true);
      }
    })
  }

  const updatePermission = async () => {
    setLoading(true)
    batch(async() => {
      const update = await updatePermissions({
        user_id: id,
        user_create: createUsers? 1 : 0,
        user_view: readUsers? 1 : 0,
        user_archive:removeUsers? 1 : 0,
        user_update: updateUsers? 1 : 0,
        transaction_view: readTransactions? 1 : 0,
        transaction_write: writeTransactions? 1 : 0,
        disbursement_view: readDisbursements? 1 : 0,
        disbursement_write: writeDisburse? 1 : 0,
        account_view: readAccounts? 1 : 0,
        account_write: writeAccounts? 1 : 0,
        permission_write: supreme? 1 : 0,
      })
      if(update.status === 200){
        setLoading(false)
        setToastTitle('SUCCESS');
        setToastDesc('Permissions updated.');
        setToastKind('success');
        setToastOpen(true);
        navigate('/admin/manage_users?action=updated')
        navigate('/admin/manage_users?action=updated')
      }else{
        setLoading(false)
        setToastTitle('Error');
        setToastDesc('Something went wrong. Try again.');
        setToastKind('error');
        setToastOpen(true);
      }
    })
  }

  return (
    <>
    <Toast
          description={toastDesc}
          isOpen={isToastOpen}
          onClose={() => setToastOpen(false)}
          title={toastTitle}
          toastKind={toastKind}
        />
    <Transition appear as={Fragment} show={isOpenP}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-40 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="phone:w-full medium:w-3/4 tabletWide:w-3/5 phone:w-full h-screen text-center">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-50 px-5 pt-5
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <div
                className={`flex flex-col justify-start p-4 mb-4 text-gray-500 w-full
            bg-white border border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins bg-white phone:h-full medium:h-3/4`}
                id="toast-success"
                role="alert"
              >
              <FlexRow className='w-full'>
                <div
                  className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white bg-${color} rounded-lg mr-1`}
                >
                  <i className={`fas ${icon} hidden`} />
                  <img alt='logo' className='object-cover' src={Icons.xffiliate} />
                </div>
                <div>
                  <div className={`ml-3 font-semibold phone:text-base surfaceDuo:text-2xl text-${color}`}>
                    {titleP.toUpperCase()}
                  </div>
                  <div className="ml-3 phone:text-xs surfaceDuo:text-sm font-normal text-primary-100">{descriptionP}</div>
                </div>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
              </FlexRow>
              <hr className='text-grey-400 my-3' />

              <div className='h-full w-full overflow-y-scroll scrollbar-hide'>

                <Text className='text-xl text-primary-100 my-4 font-semibold'>
                  Permissions and Controls
                </Text>

                <Text className='mb-2 mt-4 text-red-400'>
                  {desc}
                </Text>

                <FlexRow className='phone:flex-col medium:flex-row'>
                  <Flex className='px-2 hover:bg-grey-500'>
                    <Text className='my-2'>
                      Transactions Permissions
                    </Text>
                    
                      <Checkbox
                        checked={readTransactions}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2"
                        id='transaction_view'
                        label='Read-only Transactions'
                        onClick={() => setReadTrans(!readTransactions)}
                      />
                      <Checkbox
                        checked={writeTransactions}
                        className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                        containerClass="my-2"
                        id='transaction_view'
                        label='Update Transactions'
                        onClick={() => setWriteTrans(false)}
                      />
                    
                  </Flex>
                  <Flex className='px-2 hover:bg-grey-500'>
                    <Text className='my-2'>
                      Disbursements Permissions
                    </Text>
                    
                      <Checkbox
                        checked={readDisbursements}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2"
                        id='disbursement_view'
                        label='Read-only Disbursements'
                        onClick={() => setReadDisbursements(!readDisbursements)}
                      />
                      <Checkbox
                        checked={writeDisburse}
                        className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                        containerClass="my-2"
                        id='disbursement_view'
                        label='Update Disbursements'
                        onClick={() => setWriteDisburse(false)}
                      />

                  </Flex>
                </FlexRow>

                <FlexRow className='phone:flex-col medium:flex-row mt-4'>
                  <Flex className='px-2 hover:bg-grey-500'>
                    <Text className='my-2'>
                      Users Permissions
                    </Text>
                   
                      <Checkbox
                        checked={readUsers}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2"
                        id='user_view'
                        label='View Users'
                        onClick={() => setReadUsers(!readUsers)}
                      />
                      <Checkbox
                        checked={createUsers}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2"
                        id='user_create'
                        label='Create Users'
                        onClick={() => setCreateUsers(!createUsers)}
                      />
                      <Checkbox
                        checked={updateUsers}
                        className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                        containerClass="my-2"
                        id='user_update'
                        label='Update Users'
                        onClick={() => setUpdateUsers(false)}
                      />
                      <Checkbox
                        checked={removeUsers}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2"
                        id='user_archive'
                        label='Remove Users'
                        onClick={() => setRemoveUsers(!removeUsers)}
                      />
                    
                  </Flex>
                  <Flex className='px-2 mb-auto hover:bg-grey-500'>
                    <Text className='my-2'>
                      Accounts Permissions
                    </Text>
                    
                      <Checkbox
                        checked={readAccounts}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2"
                        id='account_view'
                        label='Read-only Accounts'
                        onClick={() => setReadAccounts(!readAccounts)}
                      />
                      <Checkbox
                        checked={writeAccounts}
                        className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                        containerClass="my-2"
                        id='account_write'
                        label='Update Accounts'
                        onClick={() => setWriteAccounts(false)}
                      />
                   
                  </Flex>
                </FlexRow>

                {
                  permitted?
                  <>
                  <FlexRow className='mt-4'>
                    <Flex className='px-2 hover:bg-grey-500'>
                      <Text className='my-2'>
                        Other Permissions
                      </Text>
                      <Checkbox
                        checked={supreme}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2"
                        id='permission_write'
                        label='All Access'
                        onClick={() => setSupreme(!supreme)}
                      />
                    </Flex>
                  </FlexRow>
                  {
                    desc === '' ?
                    <FlexRow className='mt-4'>
                      <Text className='text-secondary-200 font-semibold mr-4'>
                        Update changes:
                      </Text>
                      <button className='text-xs text-white px-3 py-2 rounded bg-primary-100 hover:bg-blue-100 cursor-pointer' onClick={updatePermission}>
                        { 
                          loading?
                          <i className="fad fa-spinner-third animate-spin text" />
                          :
                          'UPDATE'
                        }
                      </button>
                    </FlexRow>
                    :
                    <FlexRow className='mt-4'>
                      <Text className='text-secondary-200 font-semibold mr-4'>
                        Update changes:
                      </Text>
                      <button className='text-xs text-white px-3 py-2 rounded bg-primary-100 hover:bg-blue-100 cursor-pointer' onClick={createPermission}>
                        { 
                          loading ?
                          <i className="fad fa-spinner-third animate-spin text" />
                          :
                          'SET PERMISSIONS'
                        }
                      </button>
                    </FlexRow>
                  }
                  </>
                  :
                  <>
                  <FlexRow className='mt-4'>
                    <Flex className='px-2 hover:bg-grey-500 cursor-not-allowed'>
                      <Text className='my-2'>
                        Other Permissions
                      </Text>
                      <Checkbox
                        checked={supreme}
                        className='tabletWide:text-sm text-secondary-200'
                        containerClass="my-2 cursor-not-allowed"
                        id='permission_write'
                        label='All Access'
                      />
                    </Flex>
                  </FlexRow>
                  <FlexRow className='mt-4 cursor-not-allowed'>
                    <Text className='text-secondary-200 font-semibold mr-4'>
                      You are not permitted to update users.
                    </Text>
                    <button className='text-xs text-white px-3 py-2 rounded bg-secondary-300 cursor-not-allowed'>
                      UPDATE
                    </button>
                  </FlexRow>
                  </>
                }

              </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
    </>
  );
};