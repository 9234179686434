import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

export const getAllMerchant = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-all-users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAnalytics = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/analytics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAnalyticsAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/admin-analytics/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getFailedDisbursements = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-failed-disbursements`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getFrequency = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-disbursement-frequency`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getFilesData = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-terms-and-conditions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getColorsData = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-custom-page`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getDisbursements = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/commission-report`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getOverallConversions = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-conversions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getConversionRate = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/conversion-rate-graph`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getTopPartners = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/top-performing-affiliates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getTopProducts = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/dashboard-analytics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAllAssets = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-merchant-assets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateAssets = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliate-assets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getSingleAssetById = async (id:number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-merchant-asset/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateAssetById = async (id:number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliate-asset/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAllAssetByProduct = async (id:number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/merchant-product-asset/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateAssetByProduct = async (id:number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/affiliate-product-assets/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAssetByAuth = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-assets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAssetById = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-asset/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAssetByProduct = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/product-asset/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};