import React, { FC } from 'react';
import { Tab } from '@headlessui/react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import { AccountDashboard } from './AccountDashboard';
import { TransactionDashboard } from './TransactionDashboard';
import { PendingDashboard } from './PendingDashboard';
import { ListDashboard } from './ListDashboard';
import { FullLogsDashboard } from './FullLogsDashboard';
import { UsersDashboard } from './UsersDashboard';
import { selectUser } from '@redux';
import { Flex, FlexRow } from '@core';
import { NavBarSuperAdmin, SupremeAdminInformationModal } from '@components';
import { useWindowSize } from '@hooks';
import { classNames } from '@helpers';
import { getAdminPermissions } from '@services';

export const AdminHomePage: FC<any> = () => {

	const navigate = useNavigate();
	const user = useSelector(selectUser);

	const { search } = useLocation();
	const query = new URLSearchParams(search)
	const actionQuery = query.get('action');
	const tabQuery = query.get('tab');

	const [accountInfoOpen, setAccountInfoOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const [readAccounts , setReadAccounts] = React.useState(false);
	const [writeAccounts , setWriteAccounts] = React.useState(false);

	const [readTransactions , setReadTrans] = React.useState(false);
  	const [writeTransactions , setWriteTrans] = React.useState(false);

  	const [readDisbursements , setReadDisbursements] = React.useState(false);
  	const [writeDisbursements , setWriteDisbursements] = React.useState(false);

	const [tabIndex, setTabIndex] = React.useState(0);
	const tabs = ['Users', 'Transactions'];

	// const tabs = ['Account', 'Transactions', 'Pending Disbursements'];

	const window = useWindowSize();

	function checkTabMobile(tab: string) {
	  let icon: string = '';
	  switch (tab) {
	    case tabs[0]:
	      icon = 'fas fa-users';
	      break;
	    case tabs[1]:
	      icon = 'fas fa-money-check';
	      break;

	    // case tabs[2]:
	      // icon = 'fas fa-hand-holding-usd';
	      // break;

	    default:
	      break;
	  }
	  return <i className={classNames(icon, 'fa-2x')} />;
	}

	function checkTabDesktop(tab: string) {
	  switch (tab) {
	    case tabs[0]:
	      return tabs[0];
	    case tabs[1]:
	      return `Transactions`;

	    // case tabs[2]:
	      // return `Pending Disbursements`;

	    default:
	      return '';
	  }
	}

	React.useEffect(() => {
		if(actionQuery === 'transactions'){
			setTabIndex(1)
			navigate('/admin/dashboard')
		} else if (actionQuery === 'account') {
      		setAccountInfoOpen(true);
    	}

    	// else if(actionQuery === 'pending'){
			// setTabIndex(2)
			// navigate('/admin/dashboard')
		// }

	}, [actionQuery])

	React.useEffect(() => {
	  batch(async () => {
	  	setLoading(true)
	    const useradmin = await getAdminPermissions(user.user_info.id)
	    if(useradmin.data.status === 1){
	      setReadAccounts(useradmin?.data?.permissions_info?.account_view === 1)
	      setWriteAccounts(useradmin?.data?.permissions_info?.account_write === 1)
	      setReadTrans(useradmin?.data?.permissions_info?.transaction_view === 1)
          setWriteTrans(useradmin?.data?.permissions_info?.transaction_write === 1)
          setReadDisbursements(useradmin?.data?.permissions_info?.disbursement_view === 1)
          setWriteDisbursements(useradmin?.data?.permissions_info?.disbursement_write === 1)
	      setLoading(false)
	    }else{
	      setLoading(false)
	    }
	  })
	}, [user])

return(
	<>
	<SupremeAdminInformationModal
		isOpen={accountInfoOpen}
		onClose={() => setAccountInfoOpen(false)}
	/>
	<NavBarSuperAdmin/>
	<Flex className='mt-24 overflow-y-auto'>

	<FlexRow className="mt-8 phone:px-4 tablet:px-8">
	  <Tab.Group defaultIndex={tabIndex}>
	    <Flex>
	      {
	      	tabQuery?
	      	<FlexRow className='phone:w-full flex justify-start'>
	      	  <Link className='font-semibold text-primary-100' to='/admin/dashboard'>
	      	      <i className="fas fa-angle-left"/>
	      	      <i className="fas fa-angle-left mr-3"/>
	      	      Back to Dashboard
	      	  </Link>
	      	</FlexRow>
	      	:
	      	<>
	      	<Tab.List className="tabletWide:space-x-8">
	      	  {tabs.map((tab) => (
	      	    <Tab
	      	      className={({ selected }) =>
	      	        classNames(
	      	          'py-3 font-semibold phone:w-1/2 tabletWide:w-auto',
	      	          selected
	      	            ? 'text-primary-100 text-xl border-b-4 border-blue-400'
	      	            : 'text-grey-700 text-xl  hover:text-grey-200',
	      	        )
	      	      }
	      	      key={tab}
	      	    >
	      	      {window.width > 800 ? checkTabDesktop(tab) : checkTabMobile(tab)}
	      	    </Tab>
	      	  ))}
	      	</Tab.List>
	      	<hr className="text-grey-1200" />
	      	</>
	      }
	      <Tab.Panels className="pt-9">
	        <Tab.Panel>
	          <UsersDashboard
	          	loading={loading}
	          	readAccounts={readAccounts}
	          	writeAccounts={writeAccounts}
	          />
	        </Tab.Panel>
	        <Tab.Panel>
	          <FullLogsDashboard
	          	loading={loading}
	          	readTransactions={readTransactions}
	          	writeTransactions={writeTransactions}
	          />
	        </Tab.Panel>
	        <Tab.Panel>
	          <TransactionDashboard
	          	loading={loading}
	          	readTransactions={readTransactions}
	          	writeTransactions={writeTransactions}
	          />
	        </Tab.Panel>
	        <Tab.Panel>
	          <PendingDashboard
	          	loading={loading}
	          	readDisbursements={readDisbursements}
	          	writeDisbursements={writeDisbursements}
	          />
	        </Tab.Panel>
	        <Tab.Panel>
	          <AccountDashboard
	          	loading={loading}
	          	readAccounts={readAccounts}
	          	writeAccounts={writeAccounts}
	          />
	        </Tab.Panel>
	        <Tab.Panel>
	          <ListDashboard
	          	loading={loading}
	          	readAccounts={readAccounts}
	          	writeAccounts={writeAccounts}
	          />
	        </Tab.Panel>
	      </Tab.Panels>
	    </Flex>
	  </Tab.Group>
	</FlexRow>
		
	</Flex>
	</>
	)
};