import React, { FC } from 'react';
import { FlexRow, Div, Text, Flex } from '@core';

interface ProductItemProps {
  partners?: any;
  selectedPartner?: any;
  setSelected?: any;
  setSelectedTab?: any;
  onSelect?: any;
}

export const PartnerItem: FC<ProductItemProps> = ({
  partners,
  selectedPartner,
  setSelected,
  setSelectedTab,
  onSelect,
}) => (
  <div
    className="group cursor-pointer hover:bg-white"
    key={partners?.user_id}
    onClick={() => {
      setSelectedTab('partner');
      setSelected(partners);
      onSelect();
    }}
  >
    <li
      className="relative pr-4 hover:bg-coolGray-100 border-b-2 
                      border-grey-400 "
    >
      <FlexRow>
        <Div
          className={`h-28 w-2 mr-4 ${
            partners?.user_id === selectedPartner?.user_id ? 'bg-blue-200' : 'bg-white'
          } `}
        />
        <Flex className="py-5">
          <FlexRow className='justify-between'>
            <Div className='w-2/3'>
              {
                partners.firstname === 'none' ?
                <>
                    <Text className="font-poppins text-sm text-grey-100">{partners.company_email}</Text>
                    <h3
                      className="font-poppins text-base font-semibold leading-5 mb-1 
                  text-secondary-100 "
                    >
                      {partners.company_name}
                    </h3>
                    <Text className="font-poppins font-medium text-sm text-grey-100 ">
                      {partners.contact_number}
                    </Text>
                </>
                :
                <>
                    <Text className="font-poppins text-sm text-grey-100">{partners.email}</Text>
                    <h3
                      className="font-poppins text-base font-semibold leading-5 mb-1 
                  text-secondary-100 "
                    >
                      {partners.firstname} {partners.lastname}
                    </h3>
                    <Text className="font-poppins font-medium text-sm text-grey-100 ">
                      {partners.contact}
                    </Text>
                </>
              }
            </Div>

            <Text
              className={`ml-auto font-poppins text-lg rounded-full 
              text-primary-100`}
            >
              {partners?.total_conversion}
              <i className="fas fa-repeat ml-1" />
            </Text>
          </FlexRow>
        </Flex>
      </FlexRow>
    </li>
  </div>
);