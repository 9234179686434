import React, { FC } from 'react';

interface H1Props {
  className?: string;
  props?: any;
}

export const H1: FC<H1Props> = ({ className, children, props }) => (
  <h1
    className={`font-lato font-black phone:text-xl tablet:text-2xl laptop:text-3xl 
    desktop:text-4xl ${className}`}
    {...props}
  >
    {children}
  </h1>
);
