import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import { useAppDispatch, usersActions, catalogsActions, selectUser } from '@redux';
import { NavBarAffiliate, CatalogItem, AccountInformationModal } from '@components';
import { Div, Flex, FlexRow, H2, FilterDropdownV2, Text, Button } from '@core';
import { useCookie } from '@hooks';
import { MainCategoriesSelect } from '@src/constants';

export const Catalogs: FC<any> = () => {

  const user: any = useSelector(selectUser);
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [catalogsList, setcatalogsList] = React.useState<any>([]);
  const [catalogsLoad, setCatalogsLoad] = React.useState(true);
  const [onCategoryTab, setOnCategoryTab] = React.useState(false);
  const [token, updateToken] = useCookie('token', '0');
  const [accountInfoOpen, setAccountInfoOpen] = React.useState(false);
  const [isSearchOpen, setSearchOpen] = React.useState(false);

  const [keyword, setKeyword] = React.useState('');
  const [category, setCategory] = React.useState('All Categories');
  const [subcategory, setSubCategory] = React.useState({
    label: 'All Categories',
    value: 'All Categories',
  });

  const [current, setCurrent] = React.useState(0);
  const [next, setNext] = React.useState(false);
  const [prev, setPrev] = React.useState(false);

  React.useEffect(() => {
    if(!user?.email_confirmed){
      setTimeout(() => {
        navigate('/affiliate/dashboard?action=unverified')
      })
    }
  })

  const onSearchClick = () => {
    if (keyword !== '') {
      setCurrent(0)
      setSearchOpen(true)
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(
          catalogsActions.catalogListSearch({
            page: 1,
            search: keyword,
          }),
        ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }

          setcatalogsList(value?.payload?.data?.data);
        })
        setTimeout(() => {
            setCatalogsLoad(false)
        }, 2000)
      });
    }
  }

  const onSearch = (e:any) => {
    e.preventDefault();
    if (keyword !== '') {
      setCurrent(0)
      setSearchOpen(true)
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(
          catalogsActions.catalogListSearch({
            page: 1,
            search: keyword,
          }),
        ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }
          
          setcatalogsList(value?.payload?.data?.data);
        })
        setTimeout(() => {
            setCatalogsLoad(false)
        }, 2000)
      });
    }
  }

  const onCategory = async () => {
    setKeyword('')
    setCurrent(0)
    setCatalogsLoad(true)
    setOnCategoryTab(true)
    batch(async () => {
      await dispatch(
        catalogsActions.catalogListFilterd({
          page: 1,
          filter: category,
        }),
      ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }

          setcatalogsList(value?.payload?.data?.data);
      })
      setTimeout(() => {
          setCatalogsLoad(false)
      }, 2000)
    });
  }

  const onProducts = () => {
    setKeyword('')
    setCurrent(0)
    batch(async () => {
      setCatalogsLoad(true)
      await dispatch(catalogsActions.catalogListPagination(0)).then((value) => {
        const NextValue = value?.payload?.data?.pagination?.next;
        const PrevValue = value?.payload?.data?.pagination?.prev;
        if (NextValue) {
          setNext(true);
        } else {
          setNext(false);
        }
        if (PrevValue) {
          setPrev(true);
        } else {
          setPrev(false);
        }

        setcatalogsList(value?.payload?.data?.data);
        setTimeout(() => {
            setCategory('All Categories')
            setSubCategory({
               label: 'All Categories',
               value: 'All Categories',
             });
            setCatalogsLoad(false)
          }, 2000)
      });
    });
  }

  React.useEffect(() => {
    if(keyword === ''){
      setSearchOpen(false)
      onProducts();
    }
  }, [keyword])

  React.useEffect(() => {
    batch(async () => {
      setCatalogsLoad(true)
      await dispatch(catalogsActions.catalogListPagination(current)).then((value) => {
        const NextValue = value?.payload?.data?.pagination?.next;
        const PrevValue = value?.payload?.data?.pagination?.prev;
        if (NextValue) {
          setNext(true);
        } else {
          setNext(false);
        }
        if (PrevValue) {
          setPrev(true);
        } else {
          setPrev(false);
        }

        setcatalogsList(value?.payload?.data?.data);
        setTimeout(() => {
            setCatalogsLoad(false)
          }, 2000)
      });
    });
  }, []);

  React.useEffect(() => {
    if(catalogsList && keyword === '' && category === 'All Categories' && subcategory.label === 'All Categories'){
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(catalogsActions.catalogListPagination(current)).then((value) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }

          setcatalogsList(value?.payload?.data?.data);
          setTimeout(() => {
            setCatalogsLoad(false)
          }, 2000)
        });
      });
    }else if(catalogsList && keyword !== ''){
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(
          catalogsActions.catalogListSearch({
            page: current+1,
            search: keyword,
          }),
        ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }
          
          setcatalogsList(value?.payload?.data?.data);
        })
        setTimeout(() => {
            setCatalogsLoad(false)
        }, 2000)
      });
    }else if(catalogsList && category !== 'All Categories' && subcategory.label === 'All Categories'){
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(
          catalogsActions.catalogListFilterd({
            page: current+1,
            filter: category,
          }),
        ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }
          
          setcatalogsList(value?.payload?.data?.data);
        })
        setTimeout(() => {
            setCatalogsLoad(false)
        }, 2000)
      });
    }else if(catalogsList && category !== 'All Categories' && subcategory.label !== 'All Categories'){
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(
          catalogsActions.catalogListFilteredSub({
            page: current+1,
            filter: subcategory.label,
          }),
        ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }
          
          setcatalogsList(value?.payload?.data?.data);
        })
        setTimeout(() => {
            setCatalogsLoad(false)
        }, 2000)
      });
    }
  }, [current]);

  React.useEffect(() => {
    setCurrent(0)
    if (category !== 'All Categories') {
      setKeyword('')
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(
          catalogsActions.catalogListFilterd({
            page: 1,
            filter: category,
          }),
        ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }
          
          setcatalogsList(value?.payload?.data?.data);
        })
        setTimeout(() => {
            setCatalogsLoad(false)
        }, 2000)
      });
    } else {
      setOnCategoryTab(false)
    }
  }, [category]);

  React.useEffect(() => {
    setCurrent(0)
    if (subcategory.label !== 'All Categories') {
      setKeyword('')
      setCatalogsLoad(true)
      batch(async () => {
        await dispatch(
          catalogsActions.catalogListFilteredSub({
            page: 1,
            filter: subcategory.label,
          }),
        ).then((value:any) => {
          const NextValue = value?.payload?.data?.pagination?.next;
          const PrevValue = value?.payload?.data?.pagination?.prev;
          if (NextValue) {
            setNext(true);
          } else {
            setNext(false);
          }
          if (PrevValue) {
            setPrev(true);
          } else {
            setPrev(false);
          }
          
          setcatalogsList(value?.payload?.data?.data);
        })
        setTimeout(() => {
            setCatalogsLoad(false)
        }, 2000)
      });
    } else {
      setOnCategoryTab(false)
    }
  }, [subcategory]);

  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  React.useEffect(() => {
    if (actionQuery === 'account') {
      setAccountInfoOpen(true);
    } else if (actionQuery === 'logout') {
      batch(async () => {
        await dispatch(usersActions.logout());
        updateToken('', 1);
        navigate(`/`);
      });
    }
  }, [actionQuery]);

  return (
    <>
    <NavBarAffiliate />
    <AccountInformationModal isOpen={accountInfoOpen} onClose={() => setAccountInfoOpen(false)} />
    <Div className="h-full mt-24 overflow-y-auto overflow-x-hidden">
      <div className='hidden'>UNUSED {token}</div>
      <Flex className="pt-8 pb-20 desktop:px-56 laptop:px-42 tablet:px-12 phone:px-8">
        <FlexRow
          className="tablet:justify-center tablet:items-center tablet:flex-row
         phone:flex-col"
        >
          <H2
            className="font-poppins font-bold text-primary-100 phone:mr-auto 
          phone:mb-3 tablet:mb-0 w-full"
          >
            MERCHANT PRODUCTS
          </H2>
          <FlexRow className="w-full">
            <div
              className={`border bg-white text-primary-100 font-poppins text-base px-5
             rounded-full tablet:ml-auto flex flex-row phone:w-full tablet:w-80 
             items-center overflow-hidden`}
            >
              <i className="fas fa-search p-2 rounded-full cursor-pointer bg-grey-500 hover:bg-grey-400" onClick={onSearchClick} title='Click icon or press enter to search'/>
              <form onSubmit={(e:any) => onSearch(e)}>
              <input
                className={`bg-white text-primary-100 laptop:text-base
              tablet:py-3 ml-3 w-full outline-none border-none block phone:py-3`}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Search..."
                value={keyword}
              />
              </form>
              {keyword === '' ? (
                <></>
              ) : (
                <i
                  className="fas fa-times text-xl hover:text-red-100 cursor-pointer"
                  onClick={() => setKeyword('')}
                />
              )}
            </div>
             {category === 'All Categories' ? (
               <FilterDropdownV2
                 color="white"
                 current={category || ''}
                 items={MainCategoriesSelect}
                 onSelect={setCategory}
                 onSelectSub={setSubCategory}
                 placeholder="Select a product"
               />
             ) : (
               <>
                 <div
                   className={`inline-flex items-center justify-center px-5 border-0
            py-3 font-poppins text-base font-regular text-primary-100
            hover:text-red-100 cursor-pointer `}
                   onClick={() => {
                     onProducts();
                     setCategory('All Categories')
                     setSubCategory({
                        label: 'All Categories',
                        value: 'All Categories',
                      });
                     }
                   }
                 >
                   Reset
                   <i className="fas fa-filter text-2xl ml-1" />
                 </div>
               </>
             )}
          </FlexRow>
        </FlexRow>

        {
          keyword === '' && category !== 'All Categories' && ( subcategory.label === 'All Categories' || onCategoryTab ) ?
          <FlexRow className='phone:flex-col tablet:flex-row tablet:px-4 mt-8'>
            <div className='font-robot font-regular text-primary-100 text-lg hidden tablet:block cursor-pointer' onClick={onProducts}>
              Products
            </div>
            <i className="fas fa-angle-right ml-2 desktop:ml-4 hidden tablet:block"/>
            <i className="fas fa-angle-right mr-2 desktop:mr-4 hidden tablet:block"/>
            <Text className='text-primary-100 text-lg'>
              {category}
            </Text>
          </FlexRow>
          :
          <></>
        }

        {
          keyword === '' && subcategory.label !== 'All Categories' && !onCategoryTab ?
          <FlexRow className='phone:flex-col tablet:flex-row tablet:px-4 mt-8'>
            <div className='font-robot font-regular text-primary-100 text-lg hidden tablet:block cursor-pointer' onClick={onProducts}>
              Products
            </div>
            <i className="fas fa-angle-right ml-2 desktop:ml-4 hidden tablet:block"/>
            <i className="fas fa-angle-right mr-2 desktop:mr-4 hidden tablet:block"/>
            <div
              className='font-robot font-regular text-primary-100 text-lg cursor-pointer'
              onClick={onCategory}>
              {category}
            </div>
            <i className="fas fa-angle-right ml-2 desktop:ml-4 hidden tablet:block"/>
            <i className="fas fa-angle-right mr-2 desktop:mr-4 hidden tablet:block"/>
            <Text className='text-primary-100 text-lg'>
              {subcategory.label}
            </Text>
          </FlexRow>
          :
          <></>
        }

        {
          keyword !== '' && isSearchOpen?
          <Div className='my-4 laptop:ml-8'>
            <Text className='text-primary-100 laptop:text-lg'>
              Showing results for "{keyword}".
            </Text>
          </Div>
          :
          <></>
        }

        {catalogsList?.length !== 0 && catalogsList !== null && catalogsList !== 'undefined' && !catalogsLoad ? (
          <>
            <Div
              className="grid desktop:grid-cols-4 laptop:grid-cols-4 medium:grid-cols-2 tablet:grid-cols-2 
          tabletWide:grid-cols-3 phone:grid-cols-1 py-10 gap-y-7 "
            >
              {catalogsList
                ?.slice(0)
                ?.reverse()
                ?.map((item: any) => (
                  <CatalogItem
                    category={item?.category}
                    commision={item?.commission?.replace('%', '')}
                    id={item?.id}
                    image={item?.image}
                    key={item?.id}
                    link={item?.link}
                    merchantSub={item?.merchant_sub}
                    remaining={item?.available_affiliate || 0}
                    status={item?.status}
                    title={item?.name}
                  />
                ))}
            </Div>
            {/* Pagginations */}
            <FlexRow className='pb-10 mt-10 w-full justify-center space-x-2'>
              {prev === true && current !== 0 ? (
                <>
                  {' '}
                  <i
                    className="fas fa-chevron-left text-2xl text-primary-100 
                    hover:text-blue-100 cursor-pointer"
                    onClick={() => setCurrent(current - 1)}
                  />
                  <div
                    className="font-semibold py-2 px-4 rounded-lg text-primary-100 
                    cursor-pointer"
                    onClick={() => setCurrent(current - 1)}
                  >
                    {current}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div
                className="font-semibold py-2 px-4 rounded-lg bg-primary-100 
                text-white cursor-pointer"
              >
                {current + 1}
              </div>
              {next === true ? (
                <>
                  <div
                    className="font-semibold py-2 px-4 rounded-lg text-primary-100 
                    cursor-pointer"
                    onClick={() => setCurrent(current + 1)}
                  >
                    {current + 2}
                  </div>

                  <i
                    className="fas fa-chevron-right text-2xl text-primary-100 
                    hover:text-blue-100 cursor-pointer"
                    onClick={() => setCurrent(current + 1)}
                  />
                </>
              ) : (
                <></>
              )}
            </FlexRow>
          </>
        ) : (
          <>
          {
            !catalogsLoad ?
            <Flex className="w-full items-center">
            <Flex className="items-center justify-center surfaceDuo:w-500px">
              <Text className="font-semibold text-secondary-100 text-2xl mb-5">Ooops..</Text>
              <Text
                className="font-poppins font-bold surfaceDuo:text-4xl text-primary-100 text-center mb-10"
              >
                No available <span className="text-orange-100"> products</span>.
              </Text>
              <Text className="mb-10 text-center">
                If you encounter an error please reload the page. Thank you!
              </Text>
              <Button isLoading={catalogsLoad} onClick={onProducts}>
                Reload
              </Button>

              {
                current !== 0?
                <FlexRow className="pb-10 mt-10 w-full justify-center space-x-2">
                  {prev === true && current !== 0 ? (
                    <>
                      {' '}
                      <i
                        className="fas fa-chevron-left text-2xl text-primary-100 
                        hover:text-blue-100 cursor-pointer"
                        onClick={() => setCurrent(current - 1)}
                      />
                      <div
                        className="font-semibold py-2 px-4 rounded-lg text-primary-100 
                        cursor-pointer"
                        onClick={() => setCurrent(current - 1)}
                      >
                        {current}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    className="font-semibold py-2 px-4 rounded-lg bg-primary-100 
                    text-white cursor-pointer"
                  >
                    {current + 1}
                  </div>
                  {next === true ? (
                    <>
                      <div
                        className="font-semibold py-2 px-4 rounded-lg text-primary-100 
                        cursor-pointer"
                        onClick={() => setCurrent(current + 1)}
                      >
                        {current + 2}
                      </div>

                      <i
                        className="fas fa-chevron-right text-2xl text-primary-100 
                        hover:text-blue-100 cursor-pointer"
                        onClick={() => setCurrent(current + 1)}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </FlexRow>
                :
                <></>
              }
            </Flex>
            </Flex>
            :
            <Flex className="w-full items-center">
            <Flex className="items-center justify-center surfaceDuo:w-500px">
              <Text className="font-semibold text-secondary-100 text-2xl mb-5">Please wait</Text>
              <Text
                className="font-poppins font-bold surfaceDuo:text-4xl text-primary-100
        text-center mb-10"
              >
                <i className="fad fa-spinner-third animate-spin surfaceDuo:text-4xl mr-3" />
                Loading <span className="text-orange-100"> products</span>
              </Text>
            </Flex>
          </Flex>
          }
          </>
        )}
      </Flex>
    </Div>
    </>
  );
};