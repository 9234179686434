import moment from 'moment';

export const data = [
  {
    id: 'Revenue',
    data: [
      { x: '2022-01-09', y: 2109 },
      { x: '2022-02-09', y: 2365 },
      { x: '2022-03-09', y: 7568 },
      { x: '2022-04-09', y: 2345 },
      { x: '2022-05-09', y: 2093 },
      { x: '2022-07-09', y: 9162 },
      { x: '2023-01-09', y: 1579 },
    ],
  },
  {
    id: 'Commissions',
    data: [
      { x: '2022-02-09', y: 1309 },
      { x: '2022-04-09', y: 2165 },
      { x: '2022-06-09', y: 4368 },
      { x: '2022-07-09', y: 7345 },
      { x: '2022-09-09', y: 8093 },
      { x: '2022-10-09', y: 9162 },
    ],
  },
];

export const data2 = [
  {
    id: 'Clicks',
    data: [
      { x: '2022-01-09', y: 2109 },
      { x: '2022-03-09', y: 2865 },
      { x: '2022-04-09', y: 3568 },
      { x: '2022-06-09', y: 1345 },
      { x: '2022-07-09', y: 2093 },
      { x: '2022-08-09', y: 5162 },
      { x: '2022-09-09', y: 7579 },
    ],
  },
  {
    id: 'Conversions',
    data: [
      { x: '2022-01-09', y: 1309 },
      { x: '2022-03-09', y: 2165 },
      { x: '2022-04-09', y: 7368 },
      { x: '2022-05-09', y: 4345 },
      { x: '2022-11-09', y: 6093 },
      { x: '2022-12-09', y: 8162 },
    ],
  },
];

function randomize() {
  return Math.floor(Math.random() * 5000 + 1);
}

const start = new Date(2021, 0, 1);
const end = new Date(2022, 0, 1);

// eslint-disable-next-line no-unused-vars
function randomDate() {
  const rand = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  return moment(rand).format('YYYY-dd-mm');
}

export const data3 = [
  {
    id: 1,
    name: 'Product Name',
    data: [
      { x: '8/14/2022', y: randomize() },
      { x: '8/15/2022', y: randomize() },
      { x: '8/16/2022', y: randomize() },
      { x: '8/17/2022', y: randomize() },
      { x: '8/18/2022', y: randomize() },
      { x: '8/19/2022', y: randomize() },
    ],
  },
];

export const tempApplicants = [
  {
    firstname: 'Job',
    email: 'job1@xtendly.com',
    contact: '09123456789',
    created_at: '2022-03-12T01:43:49.000000Z',
    lastname: 'Cuta',
    social_link: 'facebook.com',
  },
  {
    firstname: 'Mob',
    email: 'job2@xtendly.com',
    contact: '09123456789',
    created_at: '2022-03-17T01:43:49.000000Z',
    lastname: 'Cuta',
    social_link: 'facebook.com',
  },
  {
    firstname: 'Zob',
    email: 'job3@xtendly.com',
    contact: '09123456789',
    created_at: '2022-03-19T01:43:49.000000Z',
    lastname: 'Cuta',
    social_link: 'facebook.com',
  },
  {
    firstname: 'Hob',
    email: 'job4@xtendly.com',
    contact: '09123456789',
    created_at: '2022-03-18T01:43:49.000000Z',
    lastname: 'Cuta',
    social_link: 'facebook.com',
  },
];

export const affiliatesD = [
  {
    image: 'default',
    name: 'Job Cuta',
    company: 'Xtendly',
    revenue: '420',
    clicks: '100',
    conversions: '120',
  },
  {
    image: 'default',
    name: 'Jerico Navarro',
    company: 'Prosperna',
    revenue: '345',
    clicks: '7467',
    conversions: '232',
  },
  {
    image: 'default',
    name: 'AG Nieve',
    company: 'Prosperna',
    revenue: '34545',
    clicks: '67',
    conversions: '232',
  },
  {
    image: 'default',
    name: 'Bob Cuta',
    company: 'Xtendly',
    revenue: '421230',
    clicks: '100',
    conversions: '120',
  },
  {
    image: 'default',
    name: 'Ferico Navarro',
    company: 'Prosperna',
    revenue: '345',
    clicks: '6211',
    conversions: '2362',
  },
  {
    image: 'default',
    name: 'BG Nieve',
    company: 'Prosperna',
    revenue: '345',
    clicks: '6677',
    conversions: '232',
  },
  {
    image: 'default',
    name: 'VG Nieve',
    company: 'Prosperna',
    revenue: '34545',
    clicks: '67',
    conversions: '232',
  },
  {
    image: 'default',
    name: 'Nob Cuta',
    company: 'Xtendly',
    revenue: '421230',
    clicks: '100',
    conversions: '120',
  },
  {
    image: 'default',
    name: 'Derico Navarro',
    company: 'Prosperna',
    revenue: '345',
    clicks: '6211',
    conversions: '2362',
  },
  {
    image: 'default',
    name: 'XG Nieve',
    company: 'Prosperna',
    revenue: '345',
    clicks: '6677',
    conversions: '232',
  },
  {
    image: 'default',
    name: 'CG Nieve',
    company: 'Prosperna',
    revenue: '34545',
    clicks: '67',
    conversions: '232',
  },
  {
    image: 'default',
    name: 'Aob Cuta',
    company: 'Xtendly',
    revenue: '421230',
    clicks: '100',
    conversions: '120',
  },
  {
    image: 'default',
    name: 'Qerico Navarro',
    company: 'Prosperna',
    revenue: '345',
    clicks: '6211',
    conversions: '2362',
  },
  {
    image: 'default',
    name: 'EG Nieve',
    company: 'Prosperna',
    revenue: '345',
    clicks: '6677',
    conversions: '232',
  },
];

export const radarDev = {
  products: ['product1', 'product2', 'product3', 'product4', 'product5'],
  data: [
    {
      stat: 'Clicks',
      product1: 99,
      product2: 48,
      product4: 17,
      product5: 35,
      product3: 59,
    },
    {
      stat: 'Conversions',
      product1: 39,
      product2: 64,
      product3: 95,
      product4: 9,
      product5: 11,
    },
    {
      stat: 'Earnings',
      product1: 34,
      product2: 56,
      product3: 77,
      product4: 12,
      product5: 15,
    },
  ],
};

export const radarDevPartner = {
  partners: ['Fro Oliva', 'Riza Gonzales', 'Theresa Matt', 'Patricai Teodoro', 'Neil Yanto'],
  data: [
    {
      stat: 'Clicks',
      ['Fro Oliva']: 21,
      ['Riza Gonzales']: 48,
      ['Theresa Matt']: 59,
      ['Patricai Teodoro']: 17,
      ['Neil Yanto']: 35,
    },
    {
      stat: 'Conversions',
      ['Fro Oliva']: 21,
      ['Riza Gonzales']: 48,
      ['Theresa Matt']: 59,
      ['Patricai Teodoro']: 17,
      ['Neil Yanto']: 35,
    },
    {
      stat: 'Earnings',
      ['Fro Oliva']: 21,
      ['Riza Gonzales']: 48,
      ['Theresa Matt']: 59,
      ['Patricai Teodoro']: 17,
      ['Neil Yanto']: 35,
    },
  ],
};

export const radarTest = [
  {
    stat: 'Clicks',
    product1: 99,
    product2: 48,
    product3: 59,
    product4: 17,
    product5: 35,
  },
  {
    stat: 'Conversions',
    product1: 39,
    product2: 64,
    product3: 95,
    product4: 9,
    product5: 11,
  },
  {
    stat: 'Earnings',
    product1: 34,
    product2: 56,
    product3: 77,
    product4: 12,
    product5: 15,
  },
];

export const radarEmpty = [
  {
    stat: 'Clicks',
    product: 0,
  },
  {
    stat: 'Conversions',
    product: 0,
  },
  {
    stat: 'Earnings',
    product: 0,
  },
];

export const radarKey = ['product']

export const tempDisbursement = [
  {
    account_name: "John Doe",
    account_number: "9887537645345",
    affiliate_name: "John Doe",
    affiliate_email: "john@mail.com",
    bank_name: "Bank1",
    earned: 999,
    status: "NOT PAID",
    affiliate_id: "119",
  }
]