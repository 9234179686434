import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

export const updatePartnerStatus = async (
  body: {
    id: string;
    status: string;
    code: string;
    token?: string;
  }
) => {
  const token = getCookie('token');
  return axios
    .put(`${API}/update-applicant/${body.id}`, {"status": body.status, "code": body.code}, {
      headers: {
        Authorization: `Bearer ${token || body.token}`,
      },
    })
    .then((response) => response);
};

export const updatePartnerStatusAdmin = async (
  body: {
    id: string;
    status: string;
    code: string;
    token?: string;
  }
) => {
  const token = getCookie('token');
  return axios
    .put(`${API}/update-applicant-admin/${body.id}`, {"status": body.status, "code": body.code}, {
      headers: {
        Authorization: `Bearer ${token || body.token}`,
      },
    })
    .then((response) => response);
};