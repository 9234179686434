import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllProducts,
  getProduct,
  getProductFiltered,
  getProductFilteredSub,
  getProductSearch,
  getProductPaggination,
} from '@services';

interface STATE {
  loading: boolean;
  data: [];
}

interface FILTERED {
  page: number;
  filter: string;
}

interface SEARCH {
  page: number;
  search: string;
}

export const catalogsInitialState: STATE = {
  loading: false,
  data: [],
};

const catalogList = createAsyncThunk('catalogs/catalogList', async () => {
  const data: any = await getAllProducts();
  const catalogs = Object.values(data.data.data);
  return {
    data: catalogs,
  };
});

const catalogListFilterd = createAsyncThunk(
  'catalogs/catalogListFilterd',
  async (body: FILTERED) => {
    const data: any = await getProductFiltered(body);
    return data;
  },
);

const catalogListFilteredSub = createAsyncThunk(
  'catalogs/catalogListFilterdSub',
  async (body: FILTERED) => {
    const data: any = await getProductFilteredSub(body);
    return data;
  },
);

const catalogListSearch = createAsyncThunk('catalogs/catalogListSearch', async (body: SEARCH) => {
  const data: any = await getProductSearch(body);
  return data;
});

const catalogListPagination = createAsyncThunk(
  'catalogs/catalogListPagination',
  async (page: number) => {
    const data: any = await getProductPaggination(page);
    return data;
  },
);

const catalogDetails = createAsyncThunk('catalogs/catalogDetails', async (id: string) => {
  const data: any = await getProduct(id);
  return data;
});

const { actions, reducer } = createSlice({
  name: 'catalogs',
  initialState: catalogsInitialState,
  reducers: {},
  extraReducers: {
    [catalogList.pending.type]: (state) => {
      state.loading = true;
    },
    [catalogList.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
    [catalogListFilterd.pending.type]: (state) => {
      state.loading = true;
    },
    [catalogListFilteredSub.pending.type]: (state) => {
      state.loading = true;
    },
    [catalogListFilterd.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
    [catalogListFilteredSub.fulfilled.type]: (
      state,
       { payload: { data } }: PayloadAction<STATE>
      ) => {
      state.data = data;
      state.loading = false;
    },
    [catalogListSearch.pending.type]: (state) => {
      state.loading = true;
    },
    [catalogListSearch.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
  },
});

export const catalogsActions = {
  ...actions,
  catalogList,
  catalogDetails,
  catalogListFilterd,
  catalogListFilteredSub,
  catalogListSearch,
  catalogListPagination,
};

export const catalogsReducer = reducer;