import React, {FC} from 'react';
import { Div, Text, FlexRow } from '@core';

interface DataProps {
	accEmail: string;
	accCompany: string;
	accName: string;
	count: string;
	date: string;
	key: any;
	readAccounts: boolean;
	writeAccounts: boolean;
	loading: boolean;
	subscription: string;
}

export const ListData: FC<DataProps> = ({
	accEmail,
	accCompany,
	accName,
	count,
	date,
	key,
	readAccounts,
	loading,
	writeAccounts,
	subscription,
}) => (
<>
{
	readAccounts || writeAccounts ?

<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2'>
	<Div className='phone:flex phone:flex-row laptop:hidden' key={key}>
	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Merchant Name:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Email:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {accName}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {accEmail}
	      </Text>
	  </Div>
	</Div>

	<details className='laptop:hidden'>
	  <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100'>
	    Details 
	    <i className='fas fa-chevron-down ml-2' />
	  </summary>

	  <Div className='phone:flex phone:flex-row laptop:hidden'>
	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          Store Name:
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          Subscription:
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          Listing Date:
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          Affiliate Count:
	        </Text>
	    </Div>

	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {accCompany}
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {subscription.toUpperCase()}
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {date}
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {count}
	        </Text>
	    </Div>
	  </Div>

	</details>

	<FlexRow className='w-full justify-around phone:hidden laptop:flex'>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {date}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center truncate"
		>
		  {accEmail}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {accName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {accCompany}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {subscription.toUpperCase()}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {count}
		</Text>
	</FlexRow>
	
</div>
:
<>
<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2 cursor-not-allowed'>
	{
		loading?
		<Text className='my-4 text-center text-secondary-200'>
		  Loading data ...
		  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
		</Text>
		:
		<Text className='my-4 text-center text-secondary-200'>
			Record cannot be viewed.
		</Text>
	}
</div>
</>
}
</>
)