import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import { Popover } from '@headlessui/react';
import { PartnerList } from './PartnerList';
import { useAppDispatch, partnersActions } from '@redux';
import { Div, Flex, FlexCenter, FlexRow, Text, ToastInput, Toast, ToastV4, ToastContainer, ActionDropdown } from '@src/core';
import { Pagination } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';
import { ActionsSelect2 } from '@constants';

export const AffiliatePartnersV2: FC<any> = ({
  prodID, product, user, onIndex, affiliateCount }) => {

  const dispatch = useAppDispatch();
  const token = getCookie('token');
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const [selection, setSelection] = React.useState(true);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<any>([]);
  const [indexDeny, setIndexDeny] = React.useState(0);
  const [startDeny, setStartDeny] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState<any>();
  const [actionSelected, setActionSelected] = React.useState('');

  const [isShow, setIsShow] = React.useState(true);
  const [isToastOpen5, setToastOpen5] = React.useState(false);
  const [toastKind5, setToastKind5] = React.useState('success');
  const [toastTitle5, setToastTitle5] = React.useState('');
  const [toastDesc5, setToastDesc5] = React.useState('');

  const [isToastOpen4, setToastOpen4] = React.useState(false);
  const [toastKind4, setToastKind4] = React.useState('success');
  const [toastTitle4, setToastTitle4] = React.useState('');
  const [toastDesc4, setToastDesc4] = React.useState('');

  const [isToastOpen3, setToastOpen3] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [list, setList] = React.useState<any[]>([])
  const [mainList, setMainList] = React.useState<any[]>([])
  const resultList: any[] = [];
  const emptyList: any[] = [];
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [searchOpen, setSearchOpen] = React.useState(true);
  const [resultDesc, setResultDesc] = React.useState('');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentList = list.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const onRefresh = () => {
    setSearchKeyword('')
    setSearchOpen(true)
    setIsShow(false)
    fetch(`${API}/get-affiliates/${prodID}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => res.json())
    .then(data => {
      if(data.status === 1){
        setList(data?.data)
        setMainList(data?.data)
      }
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    })
  }

  React.useEffect(() => {
    if(actionQuery === 'affiliate' && onIndex === 1){
      if(user?.user_info?.role === 'merchant'){
        navigate('/merchant/dashboard')
      }else{
        navigate(`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}`)
      }
      fetch(`${API}/get-affiliates/${prodID}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setList(data?.data)
          setMainList(data?.data)
        }
      })
    }
  }, [actionQuery])

  React.useEffect(() => {
    if(onIndex === 1){
      setIsShow(false)
      fetch(`${API}/get-affiliates/${prodID}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setList(data?.data)
          setMainList(data?.data)
          setTimeout(() => {
            setIsShow(true)
          }, 2000)
        }
      })
    }
  }, [prodID, affiliateCount])

  React.useEffect(() => {
    setIsShow(false)
    setTimeout(() => {
      setIsShow(true)
    }, 750)
  }, [currentPage])

  React.useEffect(() => {
    setList(mainList)
  }, [searchKeyword])

  React.useEffect(() => {
    if(selectedAll){
      setIsChecked(true)
    }else{
      setIsChecked(null)
    }
  }, [selectedAll])

  /* Loop function for Deny */
  React.useEffect(() => {
    const interval = setInterval(() => {
      if(selectedData.length === 0 && startDeny){
        setToastOpen4(false)
        setStartDeny(false)
        setToastTitle('ERROR');
        setToastDesc('Please select an applicant to proceed.');
        setToastKind('error');
        setToastOpen(true);
      }else if(indexDeny !== selectedData.length && startDeny){
        batch(async () => {
          const res: any = await dispatch(
            partnersActions.removePartner(selectedData[indexDeny].affiliate_id));

          if (res?.payload?.data?.status === 1) {
            setIndexDeny(indexDeny+1)
          }else{
            setIndexDeny(indexDeny+1)
          }
          
        });
      }else if(indexDeny === selectedData.length && startDeny){
        setIndexDeny(0)
        setSelectedAll(false)
        setToastOpen3(false)
        setStartDeny(false)
        setToastTitle('Applicants Removed');
        setToastDesc('Applicants are successfully removed.');
        setToastKind('success');
        setSelectedData([])
        setToastOpen(true);
        setTimeout(() => {
          onRefresh();
        }, 1500)
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [startDeny, indexDeny])

  const onClick = () => {
      setSearchKeyword('')
      fetch(`${API}/get-affiliates/${prodID}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setList(data?.data)
          setMainList(data?.data)
        }
      })
      setToastOpen5(true)
      setToastTitle5('ACTION')
      setToastKind5('info')
      setToastDesc5('Please enter a name to search partner.')
  }

  const onSearch = async () => {
    if(/[a-zA-Z]/.test(searchKeyword)){
      setList(mainList)
      setSearchOpen(false)
      await mainList.map((item:any) => {
        const fullName = item?.firstname.concat(" ", item?.lastname);
        if(item.firstname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.lastname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.firstname === 'none'){
            if(item.company_name.toLowerCase().includes(searchKeyword.toLowerCase())){
              resultList.push(item)
              setList(resultList)
              setToastOpen5(false)
              setToastKind5('info')
              setCurrentPage(1)
            }
        } else if(fullName.toLowerCase().includes(searchKeyword.toLowerCase())){
            resultList.push(item)
            setList(resultList)
            setToastOpen5(false)
            setToastKind5('info')
            setCurrentPage(1)
        } else{
          setToastDesc5('No matches found.')
          setToastTitle5('ERROR')
          setTimeout(() => {
            setToastKind5('error')
          }, 500)
        }
        return 1;
      })
      if(resultList.length === 0){
        setList(emptyList)
        setResultDesc(`You've searched for "${searchKeyword}". (0) result(s) found.`)
      } else {
        setResultDesc(`You've searched for "${searchKeyword}". (${resultList.length}) result(s) found.`)
      }
    }
  }

  const onSubmit = async (e:any) => {
    e.preventDefault()
    if(/[a-zA-Z]/.test(searchKeyword)){
      setList(mainList)
      setSearchOpen(false)
      await mainList.map((item:any) => {
        const fullName = item?.firstname.concat(" ", item?.lastname);
        if(item.firstname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.lastname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.firstname === 'none'){
            if(item.company_name.toLowerCase().includes(searchKeyword.toLowerCase())){
              resultList.push(item)
              setList(resultList)
              setToastOpen5(false)
              setToastKind5('info')
              setCurrentPage(1)
            }
        } else if(fullName.toLowerCase().includes(searchKeyword.toLowerCase())){
            resultList.push(item)
            setList(resultList)
            setToastOpen5(false)
            setToastKind5('info')
            setCurrentPage(1)
        } else{
          setToastDesc5('No matches found.')
          setToastTitle5('ERROR')
          setTimeout(() => {
            setToastKind5('error')
          }, 500)
        }
        return 1;
      })
      if(resultList.length === 0){
        setList(emptyList)
        setResultDesc(`You've searched for "${searchKeyword}". (0) result(s) found.`)
      } else {
        setResultDesc(`You've searched for "${searchKeyword}". (${resultList.length}) result(s) found.`)
      }
    }
  }

  const onSort = (e:any) => {
    if(e === 'ascending'){
      setIsShow(false)
      setList(mainList.sort((a, b) => {
      if(a.firstname === 'none' && b.firstname !== 'none'){
        if(a.company_name < b.firstname) { return -1; }
        if(a.company_name > b.firstname) { return 1; }
      }else if(a.firstname !== 'none' && b.firstname === 'none'){
        if(a.firstname < b.company_name) { return -1; }
        if(a.firstname > b.company_name) { return 1; }
      }else if(a.firstname === 'none' && b.firstname === 'none'){
        if(a.company_name < b.company_name) { return -1; }
        if(a.company_name > b.company_name) { return 1; }
      }else{
        if(a.firstname < b.firstname) { return -1; }
        if(a.firstname > b.firstname) { return 1; }
      }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'descending'){
      setIsShow(false)
      setList(mainList.sort((a, b) => {
      if(a.firstname === 'none' && b.firstname !== 'none'){
        if(a.company_name > b.firstname) { return -1; }
        if(a.company_name < b.firstname) { return 1; }
      }else if(a.firstname !== 'none' && b.firstname === 'none'){
        if(a.firstname > b.company_name) { return -1; }
        if(a.firstname < b.company_name) { return 1; }
      }else if(a.firstname === 'none' && b.firstname === 'none'){
        if(a.company_name > b.company_name) { return -1; }
        if(a.company_name < b.company_name) { return 1; }
      }else{
        if(a.firstname > b.firstname) { return -1; }
        if(a.firstname < b.firstname) { return 1; }
      }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'newest'){
      setIsShow(false)
      setList(mainList.sort((a, b) => {
      if(a.firstname > b.firstname) { return -1; }
      if(a.firstname < b.firstname) { return 1; }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'oldest'){
      setIsShow(false)
      setList(mainList.sort((a, b) => {
      if(a.firstname > b.firstname) { return -1; }
      if(a.firstname < b.firstname) { return 1; }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'commissions'){
      setIsShow(false)
      setList(mainList.sort((a, b) => b.earnings - a.earnings))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'clicks'){
      setIsShow(false)
      setList(mainList.sort((a, b) => b.total_cliked - a.total_cliked ))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'conversions'){
      setIsShow(false)
      setList(mainList.sort((a, b) => b.conversion - a.conversion))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === ''){
      onRefresh()
    }
  }

  const Select = (data: any) => {
    setActionSelected('')
    if(!selectedData.includes(data)){
      selectedData.push(data)
      setSelectedData(selectedData)
    }else{
      setSelectedData(selectedData.filter((value: number) => value !== data))
    }
  }

  const Remove = async () => {
    if(selectedData.length > 0){
      setSelection(false)
      setToastDesc4(`Are you sure you want to completely remove all ${selectedData.length} affiliate(s) selected?`);
      setToastTitle4('Action');
      setToastKind4('info');
      setToastOpen4(true);
      setSelection(true)
    }
  }

  const onRemove = async () => {
    setToastOpen4(false);
    setToastOpen3(true);
    setTimeout(() => {
      setStartDeny(true)
    }, 1500)
  };

  const SelectAll = async () => {
    setActionSelected('')
    setIsShow(false)
    if(selectedData.length === mainList.length || selectedAll){
      setSelectedAll(false)
      setSelectedData([])
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    }else{
      setSelectedAll(true)
      setSelectedData(mainList)
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    }
  }

  React.useEffect(() => {
    if (actionSelected === 'Remove Selected'){
      Remove()
    }
  }, [actionSelected])

  return (
    <Flex>
    <Toast
          description={toastDesc}
          isOpen={isToastOpen}
          onClose={() => setToastOpen(false)}
          title={toastTitle}
          toastKind={toastKind}
        />
    <ToastV4
          btnText='Cancel'    
          description={toastDesc4}
          isOpen={isToastOpen4}
          link=''
          onClick={onRemove}
          onClick2={() => {
            setActionSelected('')
            setToastOpen4(false)
          }}
          onClose={() => {
            setActionSelected('')
            setToastOpen4(false)
          }}
          submitBtnText='Remove'
          title={toastTitle4}
          toastKind={toastKind4}
        />
    <ToastInput
          description={toastDesc5}
          isOpen={isToastOpen5}
          onChange={(e:any) => setSearchKeyword(e.target.value)}
          onClickSubmit={onSearch}
          onClose={() => setToastOpen5(false)}
          onSubmit={onSubmit}
          placeholder='Search'
          submitBtnText='Search'
          title={toastTitle5}
          toastKind={toastKind5}
          type='search'
          value={searchKeyword}
        />
    <ToastContainer
          description='Affiliate Applications'
          isOpen={isToastOpen3}
          onClose={() => setToastOpen3(false)}
          title='ACTION'
          toastKind='info'
    >
      <Div>
        <Text className='text-secondary-200 py-1 mt-2'>
          <b className='mr-1'>REMINDER:</b>
          Please refrain from reloading this page as we complete this action.
        </Text>
      </Div>
      <Div className={`${startDeny? '' : 'hidden'}`}>
        {
          indexDeny === 0?
          <Text className='text-secondary-200'>
            Loading data ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
          :
          <>
          <Text className='text-secondary-200'>
            Processing applications ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
          <FlexRow className='pr-4 py-2 w-full justify-between'>
            <div
              className='rounded-full background-play h-2.5'
              style={{
                width: `${indexDeny === 0? 0 : ((indexDeny/selectedData.length)*100).toFixed(0)}%`
              }}
            />
            <Text className='ml-4'>
              {indexDeny === 0? 0 : ((indexDeny/selectedData.length)*100).toFixed(0)}%
            </Text>
          </FlexRow>
          </>
        }
      </Div>
    </ToastContainer>

      <Flex className="gap-4">
        {
          searchOpen?
          <>
          </>
          :
          <div>
              <p className='phone:text-center surfaceDuo:text-left mb-2'>{resultDesc}</p>
              <span className='block text-center surfaceDuo:text-left hover:underline text-blue-100 cursor-pointer' onClick={onRefresh}>Clear Searches</span>
          </div>
        }
        <FlexRow className="justify-between">
          <FlexRow className='gap-4 phone:items-start phone:mx-5 surfaceDuo:items-center surfaceDuo:mx-0 surfaceDuo:justify-between w-full flex phone:flex-col surfaceDuo:flex-row'>

                <ActionDropdown
                  className='phone:text-white phone:py-2'
                  classNameBody='border border-grey-400 rounded-lg z-10 phone:left-0'
                  color="white"
                  containerClass='bg-primary-100 hover:bg-blue-100 phone:rounded-lg phone:hidden laptop:block'
                  current={actionSelected}
                  items={ActionsSelect2}
                  onSelect={setActionSelected}
                  placeholder="Select an action"
                />

                <div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
                  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
                      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
                  >
                      <form onSubmit={onSubmit}>
                      <input className="placeholder-grey-700 laptop:text-base
                          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
                          onChange={(e:any) => {
                            setSearchKeyword(e.target.value)
                          }}
                          placeholder="Search"
                          value={searchKeyword}
                      />
                      <button className='hidden' type='submit'>Search</button>
                      </form>
                  </div>
                  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearch}/>
                </div>

                <button className='hidden tabletWide:flex laptop:ml-auto w-52 flex-row items-center justify-start text-primary-100 hover:text-white py-2 px-8 rounded-full border-2 border-primary-100 bg-white hover:bg-primary-100 shadow-xl' onClick={onClick}>
                  <i className="fas fa-search text-base mr-2" />Search 
                </button>
              
              <div className='flex flex-row items-center phone:w-full surfaceDuo:w-auto'>
              <label className='hidden medium:flex mx-3' htmlFor='sort'>Sort by:</label>
              <select className='rounded-lg phone:w-full surfaceDuo:w-56 shadow-xl border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
                  <option value="">ALL</option>
                  <option value="ascending">A - Z (Name)</option>
                  <option value="descending">Z - A (Name)</option>
                  <option value="conversions">Conversions</option>
                  <option value="commissions">Commissions</option>
                  <option value="clicks">Clicks</option>
              </select>
              </div>
          </FlexRow>
        </FlexRow>

        {/* Substitute Component */}
        <Div className='phone:px-5 surfaceDuo:px-0 surfaceDuo:mt-4'>
          <ActionDropdown
            className='phone:text-white phone:py-2'
            classNameBody='border border-grey-400 rounded-lg z-10 phone:left-0'
            color="white"
            containerClass='bg-primary-100 hover:bg-blue-100 phone:rounded-lg laptop:hidden w-full surfaceDuo:w-40'
            current={actionSelected}
            items={ActionsSelect2}
            onSelect={setActionSelected}
            placeholder="Select an action"
          />
        </Div>

        {/* Hide Component */}
        <FlexRow className='items-center w-full mt-8 hidden'>
          <button
            className='text-blue-100 font-semibold hover:underline'
            onClick={() => setSelection(!selection)}
          >
            {selection? 'Disable selection' : 'Enable Selection'}
          </button>
          <Popover className="relative inline mx-2">
                <Popover.Button className='inline border border-primary-100 bg-white hover:bg-primary-100 text-primary-100 hover:text-white rounded-full px-2'>
                  <i className='fas fa-question text-xs' />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 -right-8 mt-4">
                  <Div className='bg-white border border-primary-100 phone:w-48 surfaceDuo:w-60 rounded-lg py-4 px-2'>
                    <Text className='text-sm text-secondary-200 text-left'>
                      Select entries to execute a specific action on chosen items.
                    </Text>
                    <Text className='mt-4 text-sm text-secondary-200'>
                      ** Click anywhere on screen to close. **
                    </Text>
                  </Div>
                </Popover.Panel>
          </Popover>
        </FlexRow>

        <Div className='w-full tabletWide:hidden'>
          <Text className="font-poppins font-semibold text-secondary-200 my-2 text-center text-lg">
            List of Affiliates
          </Text>
          <hr className="text-grey-400" />
        </Div>

        {
          selection?
          <FlexRow className='items-center mt-2 phone:flex tabletWide:hidden'>
            <input
              checked={isChecked}
              className={`mr-2 phone:w-3 phone:h-3 laptop:w-4 laptop:h-4 rounded border border-secondary-100 
                    focus:ring-3 focus:ring-primary-200 text-primary-100
                    cursor-pointer
                    ${selection ? '' : 'hidden'}
                    `}
              onClick={SelectAll}
              title={selectedData.length === mainList.length
                      ? 'Click to Unselect All'
                      : 'Click to Select All'
                    }
              type='checkbox'
            />
            <Text className='ml-2 text-blue-100 font-semibold'>
              {selectedData.length === mainList.length
                ? 'Unselect All'
                : 'Select All'
              }
            </Text>
          </FlexRow>
          :
          <></>
        }
        
        <FlexRow className="w-full font-medium text-grey-1000 pt-0 tablet:pt-6 phone:hidden tabletWide:flex pb-2 border-b border-grey-400">
          <input
            checked={isChecked}
            className={`mr-2 phone:w-3 phone:h-3 laptop:w-4 laptop:h-4 rounded border border-secondary-100 
                  focus:ring-3 focus:ring-primary-200 text-primary-100
                  cursor-pointer
                  ${selection ? '' : 'hidden'}
                  `}
            onClick={SelectAll}
            title={selectedData.length === mainList.length
                    ? 'Click to Unselect All'
                    : 'Click to Select All'
                  }
            type='checkbox'
          />
          <Text className="w-4/12 text-left laptop:w-3/12">Name</Text>
          <Text className="w-2/12 text-center">Social</Text>
          <Text className="w-2/12 text-center laptop:w-3/12">Statistics</Text>
          <Text className="w-2/12 text-center">Status</Text>
          <Text className="w-2/12 text-center">Actions</Text>
        </FlexRow>

        {(currentList.length !== 0 && isShow) ? (
          currentList.map((affiliate: any, index: number) => (
          <PartnerList
            affiliateId={affiliate.affiliate_id}
            clicks={affiliate.total_cliked}
            companyLink={affiliate.company_link}
            companyName={affiliate.company_name}
            conversion={affiliate.conversion}
            currentPage={currentPage}
            data={affiliate}
            dateApproved={affiliate.date_approved}
            earnings={affiliate.earnings}
            firstName={affiliate.firstname}
            key={index}
            lastName={affiliate.lastname}
            product={product}
            Select={() => Select(affiliate)}
            selectedAll={selectedAll}
            selectedData={selectedData}
            selection={selection}
            socialLink={affiliate.social_link}
            user={user}
          />
          ))
        ) : (
          <FlexCenter className="col-span-2 py-4">
          {
            !isShow?
            <Text>
              Loading data ...
              <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
            </Text>
            :
            <>
            <Text>Currently no affiliates at this time.</Text>
            <div className='text-sm text-blue-100 hover:underline cursor-pointer' onClick={onRefresh}>
              Click to reload
              <i className="fas fa-repeat text-2xl mx-3" />
            </div>
            </>
          }
          </FlexCenter>
        )}

        <div className="flex justify-center">
        <Pagination
          currentPage={currentPage}
          itemsPerPage={articlesPerPage}
          paginate={paginate}
          totalItems={list.length}
        />
        </div> 
        
      </Flex>
    </Flex>
  );
};