/* eslint-disable react/jsx-sort-props */
/* eslint-disable no-unused-vars */
import React, { FC, useState } from "react";
import { Pie, ResponsivePie } from "@nivo/pie";
import { Bar } from "@nivo/bar";
import ReactCountryFlag from "react-country-flag";
import { Div, Flex, FlexCenter, FlexRow, Text } from "@src/core";
import { classNames } from "@src/helpers";

type DemographicsProps = {
    product : any,
}

const piedata = [
    {
        "id": "m",
        "label": "Male",
        "value": 492,
    },
    {
        "id": "f",
        "label": "Female",
        "value": 152,
    },
    {
        "id": "o",
        "label": "Other",
        "value": 12,
    },
]

const bardata = [
    {
        "age": "18-25",
        "male": 65,
        "female": 52,
        "other": 10
      },
      {
        "age": "25-35",
        "male": 60,
        "female": 176,
        "other": 2
      },
      {
        "age": "36-55",
        "male": 191,
        "female": 88,
        "other": 1
      },
      {
        "age": "56+",
        "male": 1,
        "female": 0,
        "other": 0
      },
].reverse()

const locdata = [
    {
        "country": "PH",
        "city": "Manila",
        "value": 26
    },
    {
        "country": "PH",
        "city": "Baguio",
        "value": 22
    },
    {
        "country": "PH",
        "city": "Cebu",
        "value": 19
    },
    {
        "country": "PH",
        "city": "Makati",
        "value": 10
    },
    {
        "country": "PH",
        "city": "Metro Manila",
        "value": 13
    },
    {
        "country": "UA",
        "city": "Kyiv",
        "value": 7
    },
    {
        "country": "US",
        "city": "New York",
        "value": 10
    }
]

const socdata = [
    {
        "media": "Twitter",
        "value": 34,
    },
    {
        "media": "Facebook", 
        "value": 20,
    },
    {
        "media": "Direct", "value": 15,
    },
    {
        "media": "Instagram", "value": 11,
    },
    {
        "media": "Youtube", "value":  9,
    },
    {
        "media": "Email", "value":  7,
    },
    {
        "media": "Other", "value": 4
    }
]

export const Demographics: FC<DemographicsProps> = (product) => {
    const [locs, setLocs] = useState(locdata);
    const [socials, setSocials] = useState(socdata);

    const theme = {
        "fontFamily": "Poppins",
        "fontSize": 14,
        "textColor": "#333333",
        "legends": {
            "text": {
                "fill": "#333333"
            }
        },
        "axis": {
            "ticks": {
                "line": {
                    "strokeWidth": 0
                }
            }
        }
    }

    useState(() => {
        let sortedArray:any = locs.sort((a, b) => b.value - a.value)
        setLocs(sortedArray)
        sortedArray = socdata.sort((a, b) => b.value - a.value)
        setSocials(sortedArray)
    })

    const checkChannels = (channel: string) => {
        switch (channel) {
            case "Twitter":
                return "fab fa-twitter"
            case "Facebook":
                return "fab fa-facebook"
            case "Direct":
                return "fas fa-link"
            case "Instagram":
                return "fab fa-instagram"
            case "Youtube":
                return "fab fa-youtube"
            case "Email":
                return "fas fa-envelope"
            case "other":
                return "fas fa-globe"
            default:
                return "fas fa-globe"
        }
    }

    return <Flex className="grid grid-cols-3 gap-4">
        <Div className="col-span-2">
            <Div className="my-2">
                <Text className="pb-4 text-lg font-semibold text-primary-100">Demographics</Text>
                <Div className="bg-grey-900 rounded-2xl p-8 grid grid-cols-3 gap-4">
                    <Div className="col-span-1">
                        <Text className="pb-4 text-lg">Gender</Text>
                        <Pie
                            activeInnerRadiusOffset={8}
                            colors={["#0C61B0", "#FF6C3E", "#C9D5D9"]}
                            data={piedata}
                            enableArcLabels={false}
                            enableArcLinkLabels={false}
                            height={200}
                            innerRadius={0.7}
                            padAngle={0.7}
                            cornerRadius={3}
                            width={200}
                        />
                    </Div>
                    <Div className="col-span-2">
                        <Text className="pb-4 text-lg ">Age Group</Text>
                        <Bar 
                            borderRadius={4}
                            colors={["#0C61B0", "#FF6C3E", "#C9D5D9"]}
                            data={bardata} 
                            enableGridY={false}
                            enableLabel={false}
                            height={200} 
                            indexBy="age"
                            keys={[
                                'male',
                                'female',
                                'other',
                            ]}
                            layers={['grid', 'bars', 'axes', 'markers', 'legends', 'annotations']}
                            layout="horizontal" 
                            legends={[{
                                anchor: 'top-right',
                                dataFrom: 'keys',
                                direction: 'row',
                                itemHeight: 10,
                                itemWidth: 80
                            }]}
                            margin={{
                                left: 52
                            }}
                            padding={0.4}
                            theme={theme}
                            width={500}
                        />
                    </Div>
                </Div>
            </Div>
            <Div className="my-2">
                <Text className="pb-4 text-lg font-semibold text-primary-100">Location</Text>
                <Div className="bg-grey-900 rounded-2xl p-8 gap-4">
                    {
                        locs.map((place) => (
                            <FlexRow key={place.country} className="flex flex-row justify-between py-2">
                                <FlexRow>
                                    <ReactCountryFlag countryCode={place.country} svg />
                                    <Text className="text-primary-100 font-medium px-3">{place.city}</Text>
                                    <Text className="text-grey-1200 text-sm font-light">{place.country}</Text>
                                </FlexRow>
                                <Text className="font-medium text-primary-600">{place.value}%</Text>
                            </FlexRow>
                        ))
                    }
                </Div>
            </Div>
        </Div>
        <Div className="col-span-1 ">
            <Div className="my-2">
                <Text className="pb-4 text-lg font-semibold text-primary-100">Device</Text>    
                <Div className="bg-grey-900 rounded-2xl p-8 flex flex-col justify-items-center items-center">
                    <Div className="pb-4 text-lg font-semibold text-white">&nbsp;</Div>
                    <Pie
                        arcLabelsSkipAngle={20}
                        arcLabelsTextColor='#FFFFFF'
                        colors={["#1184F0", "#FF6C3E", "#073763"]}
                        data={piedata}
                        enableArcLabels
                        enableArcLinkLabels={false}
                        height={200}
                        margin={{bottom: 30}}
                        legends={[{
                            anchor: 'bottom',
                            direction: 'row',
                            itemHeight: 10,
                            itemWidth: 65,
                            translateY: 20,
                            symbolShape: 'circle',
                            symbolSize: 8,
                        }]}
                        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends',]}
                        width={200}
                    />
                </Div>
            </Div>
            <Div className="my-2">
                <Text className="pb-4 text-lg font-semibold text-primary-100">Channels</Text>   
                <Div className="bg-grey-900 rounded-2xl p-8 gap-4 ">
                    {
                        socials.map((item) => (
                            <FlexRow key={item.media} className="py-2 justify-between">
                                <FlexRow className="">
                                    <i className={classNames("mr-4 text-primary-100", checkChannels(item.media))} />
                                    <Text className="px-4">{item.media}</Text>
                                </FlexRow>
                                <Text className="font-medium text-primary-600">{item.value}%</Text>
                            </FlexRow>
                        ))
                    }
                </Div>
            </Div>
        </Div>
    </Flex>
}