import React, { FC } from 'react';
import { Tab } from '@headlessui/react';
import { batch, useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { FlexCenter, FlexRow, Flex, Div, Text, Toast, ToastInputDouble, Button, Icons } from '@core';
import { MarketingAssetsRequests, AssetData2 } from '@components';
import { classNames } from '@helpers';
import { useWindowSize, getCookie } from '@hooks';
import { API } from '@config';
import { getAssetByProduct } from '@services';

export const MarketingAssets: FC<any> = ({selectedProd, setAssets} : any) => {

	const user = useSelector(selectUser);
	const token = getCookie('token');
	const window = useWindowSize();

	const tabs2 = ['Create', 'Affiliate Requests'];
	const [editTitle, setTitle] = React.useState('');
	const [editContent, setContent] = React.useState('');
	const [assetList, setAssetList] = React.useState<any>([]);

	const [imageLoaded, setImageLoaded] = React.useState(false);
	const [isShow, setIsShow] = React.useState(false);
	const [createView, setCreateView] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('Account Created');
	const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

	const [isToastOpen2, setToastOpen2] = React.useState(false);
	const [toastKind2] = React.useState('info');
	const [toastTitle2] = React.useState('Action');
	const [toastDesc2] = React.useState('Create a personalized marketing asset.');

	React.useEffect(() => {
		setIsShow(false)
		batch(async() => {
			const assets = await getAssetByProduct(selectedProd.id);
			if(assets.data.status === 1){
				const createdAssets: any[] = [];
				assets.data.data.forEach((list: any) => {
					if(list.asset_status === 'Created' || list.asset_status === 'Approved'){
						createdAssets.push(list)
					}
				})
				setAssetList(createdAssets)
				setIsShow(true)
			}else{
				setAssetList([])
				setIsShow(true)
			}
		})
	}, [selectedProd])

	const onRefresh = () => {
		setIsShow(false)
		batch(async() => {
			const assets = await getAssetByProduct(selectedProd.id);
			if(assets.data.status === 1){
				const createdAssets: any[] = [];
				assets.data.data.forEach((list: any) => {
					if(list.asset_status === 'Created' || list.asset_status === 'Approved'){
						createdAssets.push(list)
					}
				})
				setAssetList(createdAssets)
				setIsShow(true)
			}else{
				setAssetList([])
				setIsShow(true)
			}
		})
	}


	const onClickSubmit = async () => {
		fetch(`${API}/create-asset/${selectedProd.id}`,{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			 	title: editTitle,
			 	content: editContent,
			 	hashtag: editTitle,
			})
		})
		.then(res => res.json())
		.then(data => {
			if(data.status === 1){
				setToastOpen2(false)
				setToastKind('success');
				setToastTitle('SUCCESS');
				setToastDesc('You have successfully created a new asset content');
				setToastOpen(true);
				setCreateView(false);
				setTitle('');
				setContent('');
				setTimeout(() => {
					setToastOpen(false);
				}, 3000)
			}else{
				setToastKind('error');
				setToastTitle('ERROR');
				setToastDesc(data.message || 'Something went wrong');
				setToastOpen(true);
				setTimeout(() => {
					setToastOpen(false);
				}, 3000)
			}
		})
	}

	const onSubmit = (e: any) => {
		e.preventDefault()
		setLoading(true)
		fetch(`${API}/create-asset/${selectedProd.id}`,{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			 	title: editTitle,
			 	content: editContent,
			 	hashtag: editTitle,
			})
		})
		.then(res => res.json())
		.then(data => {
			if(data.status === 1){
				setToastOpen2(false)
				setToastKind('success');
				setToastTitle('SUCCESS');
				setToastDesc('You have successfully created a new asset content');
				setToastOpen(true);
				setCreateView(false);
				setLoading(false);
				onRefresh();
				setTitle('');
				setContent('');
				setTimeout(() => {
					setToastOpen(false);
				}, 3000)
			}else{
				setToastKind('error');
				setToastTitle('ERROR');
				setToastDesc(data.message || 'Something went wrong');
				setToastOpen(true);
				setLoading(false);
				setTimeout(() => {
					setToastOpen(false);
				}, 3000)
			}
		})
	}

	const onToast = async (type:any, message:any) => {
		if(type === 'Reject'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('You have rejected the requested item.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'Approve'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('You have successfully approved the requested item.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'Remove'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('Asset successfully removed from the list.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'onSubmit'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('You have successfully updated the requested item.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'Error'){
			setToastKind('error');
			setToastTitle('ERROR');
			setToastDesc(message || 'Something went wrong.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}
	}

	function checkTabMobile2(tab: string) {
	  switch (tab) {
	    case tabs2[0]:
	      return <img alt='Create' className='w-6 h-6 mx-auto' src={Icons.create} />;
	    case tabs2[1]:
	      return <img alt='Requests' className='w-8 h-6 mx-auto' src={Icons.request} />;
	    default:
	      return '';
	  }
	}

	function checkTabDesktop2(tab: string) {
	  switch (tab) {
	    case tabs2[0]:
	      return tabs2[0];
	    case tabs2[1]:
	      return 'Affiliate Requests';
	    default:
	      return '';
	  }
	}
	
return(
	<>
	  {' '}
	  <Toast
	    description={toastDesc}
	    isOpen={isToastOpen}
	    onClose={() => setToastOpen(false)}
	    title={toastTitle}
	    toastKind={toastKind}
	  />
	  <ToastInputDouble
	  		clear={() => setContent('')}
	        description={toastDesc2}
	        emoji={(e:any) => setTitle(editTitle.concat(e.emoji))}
	        emoji2={(e:any) => setContent(editContent.concat(e.emoji))}
	        isOpen={isToastOpen2}
	        loading={loading}
	        onChange={(e:any) => setTitle(e.target.value)}
	        onChange2={(e:any) => setContent(e.target.value)}
	        onClickSubmit={onClickSubmit}
	        onClose={() => setToastOpen2(false)}
	        onSubmit={onSubmit}
	        placeholder='Title'
	        placeholder2='Content'
	        submitBtnText='CREATE'
	        title={toastTitle2}
	        toastKind={toastKind2}
	        type='text'
	        value={editTitle}
	        value2={editContent}
	  />
	  	
	  <FlexRow className="justify-start mb-10">
	    <button
	      className="text-grey-100 hover:text-grey-200"
	      onClick={() => setAssets(false)}
	      type="button"
	    >
	      <Text className="items-center flex laptop:text-lg phone:base mr-3">
	        <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to Product Details
	      </Text>
	    </button>
	  </FlexRow>
	  <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100">
	    Marketing Asset Management
	  </Text>

	  <FlexRow className="pt-8" id="Tabs">
	    <Tab.Group>
	      <Flex>
	        <Tab.List className="tabletWide:space-x-4">
	          {tabs2.map((tab) => (
	            <Tab
	              className={({ selected }) =>
	                classNames(
	                  'py-3 font-semibold phone:w-1/2 tabletWide:w-auto',
	                  selected
	                    ? 'text-primary-100 border-b-4 border-blue-400'
	                    : 'text-grey-700  hover:text-grey-200',
	                )
	              }
	              key={tab}
	            >
	              {window.width > 800 ? checkTabDesktop2(tab) : checkTabMobile2(tab)}
	            </Tab>
	          ))}
	        </Tab.List>
	        <hr className="text-grey-1200" />
	        <Tab.Panels className="pt-9">
	          <Tab.Panel>
	          	<Text className='my-4 font-semibold text-primary-100 text-xl tablet:hidden'>
	          		Create Marketing Asset
	          	</Text>
	            <FlexRow className="mt-5 w-full tablet:flex-row phone:flex-col">
	              <Div className="tablet:w-400px h-400px phone:w-full rounded-3xl">
	                <img
	                  alt="images"
	                  className="object-contain h-full w-full rounded-3xl"
	                  onError={() => setImageLoaded(true)}
	                  src={imageLoaded ? 'https://i.imgur.com/CGDGnf6.png' : selectedProd?.image}
	                />
	              </Div>
	              <Div
	                className="flex flex-col phone:mt-10 w-full tablet:w-1/2 phone:ml-0 
	                      tablet:ml-10 tablet:mt-0 desktop:w-3/5"
	              >
	                <Text
	                  className="laptop:text-3xl phone:text-xl font-semibold
	                        rounded-lg text-primary-100 my-4"
	                >
	                  {selectedProd?.name}
	                </Text>
	                <Text
	                  className="text-xl font-semibold
	                        rounded-lg text-primary-100 my-4"
	                >
	                  Product Description
	                </Text>
	                <Text
	                  className="laptop:text-base phone:text-sm
	                        rounded-lg text-secondary-100"
	                >
	                  {selectedProd?.description}
	                </Text>

	                {
	                	createView?
	                	<form className='mt-8' onSubmit={(e:any) => onSubmit(e)}>
	                		<Text className='text-secondary-100 font-semibold'>
	                			Marketing Asset Title
	                		</Text>
	                		<input
	                			className='w-full my-2 rounded-lg'
	                			onChange={(e:any) => setTitle(e.target.value)}
	                			placeholder='Enter title here'
	                			required
	                			type='text'
	                			value={editTitle}
	                		/>
	                		<Text className='text-secondary-200 mb-4'>
	                			Eg. Facebook Marketing, Twitter, etc.
	                		</Text>
	                		<Text className='text-secondary-100 font-semibold'>
	                			Marketing Asset Content
	                		</Text>
	                		<textarea
	                		  className='scrollbar-hide rounded-lg block w-full h-40 my-2 resize-none'
	                		  maxLength={1000}
	                		  onChange={(e:any) => setContent(e.target.value)}
	                		  placeholder='Enter content here'
	                		  required
	                		  rows={4}
	                		  value={editContent}
	                		/>
	                		<FlexRow className='justify-end'>
	                			{
	                			  editContent !== ''?
	                			  <Text className={`${editContent.length > 1000 ? 'text-red-400' : 'text-green-100'}`}>
	                			    {editContent?.length}/1000
	                			  </Text>
	                			  :
	                			  <Text className='text-secondary-200'>
	                			    0/1000
	                			  </Text>
	                			}
	                		</FlexRow>

	                		<FlexRow className='justify-end mt-4'>
	                			<Button className='phone:bg-white phone:border phone:border-secondary-200 phone:text-primary-100 phone:px-4 phone:py-2' onClick={() => setCreateView(false)}>
	                				Cancel
	                			</Button>
	                			<Button className='phone:py-2 phone:px-4 phone:mr-0' isLoading={loading} type='submit'>
	                				Create
	                			</Button>
	                		</FlexRow>
	                
	                	</form>
	                	:
	                	<></>
	                }

	              </Div>

	            </FlexRow>

	            <button className='w-full rounded-lg font-semibold bg-grey-600 hover:bg-grey-400 border border-grey-400 py-4 mt-8 tabletWide:hidden text-blue-100' onClick={() => setCreateView(true)}>
	            		<i className='fas fa-plus mr-4 text-blue-100' />
	            		CREATE
	            </button>
	            <button className='w-full rounded-lg font-semibold bg-grey-600 hover:bg-grey-400 border border-grey-400 py-4 mt-8 phone:hidden tabletWide:block text-blue-100' onClick={() => setToastOpen2(true)}>
	            		<i className='fas fa-plus mr-4 text-blue-100' />
	            		CREATE
	            </button>

	            <Text className="font-poppins font-bold text-xl text-primary-100 mt-12 mb-8">
	              Created Marketing Assets
	            </Text>

	            {
	            	assetList.length > 0 && isShow ?
	            	assetList.map((list: any, index: number) => (
	            		<AssetData2
	            			affiliateName={list.affiliate_name || user?.merchant_data?.company[0]?.name}
	            			content={list.content}
	            			date={list.created_at}
	            			id={list.asset_id}
	            			key={index}
	            			onRefresh={onRefresh}
	            			onToast={onToast}
	            			status={list.asset_status || list.asset_id.toString()}
	            			title={list.title}
	            		/>
	            	))
	            	:
	            	<FlexCenter className="py-4">
	            	{
	            	  !isShow?
	            	  <Text>
	            	    Loading data ...
	            	    <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	            	  </Text>
	            	  :
	            	  <>
	            	  <Text>No marketing asset at this time.</Text>
	            	  <div className='text-sm text-blue-100 hover:underline cursor-pointer hidden' onClick={onRefresh}>
	            	    Click to reload
	            	    <i className="fas fa-repeat mx-3" />
	            	  </div>
	            	  </>
	            	}
	            	</FlexCenter>
	            }


	          </Tab.Panel>
	          <Tab.Panel>
	          	<Text className='my-4 font-semibold text-primary-100 text-xl tablet:hidden'>
	          		Affiliate Requests
	          	</Text>
	            <MarketingAssetsRequests selectedProd={selectedProd} />
	          </Tab.Panel>
	        </Tab.Panels>
	      </Flex>
	    </Tab.Group>
	  </FlexRow>
	</>
)
}