import React, {FC} from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, FlexRow } from '@core';

interface DataProps {
	refNum: string;
	status: string;
	merchantName: string;
	reason: string;
	affiliateName: string;
	date: string;
	key: any;
	amount: number;
	loading: boolean;
	readDisbursements: boolean;
	writeDisbursements: boolean;
}

export const DisbursementData: FC<DataProps> = ({
	refNum,
	status,
	merchantName,
	affiliateName,
	reason,
	date,
	key,
	amount,
	loading,
	readDisbursements,
	writeDisbursements,
}) => (
<>
{
readDisbursements || writeDisbursements ?

<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2'>
	<Div className='phone:flex phone:flex-row tabletWide:hidden' key={key}>
	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Affiliate Name:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Status:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {affiliateName}
	      </Text>
	      <Text
	        className="w-52 text-left"
	      >
	        <button className='bg-red-100 text-white text-xs py-1 px-3 rounded-full'>
	        	{status}
	        </button>
	      </Text>
	  </Div>
	</Div>

	<details className='tabletWide:hidden'>
	  <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100'>
	    Details 
	    <i className='fas fa-chevron-down ml-2' />
	  </summary>

	  <Div className='phone:flex phone:flex-row laptop:hidden'>
	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Reference No.
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Amount:
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Disburse Date:
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Merchant Name:
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Reason:
	        </Text>
	    </Div>

	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {refNum}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {date}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {merchantName}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {reason}
	        </Text>
	    </Div>
	  </Div>
	  <FlexRow className='w-full justify-start border-t border-grey-400 pt-2 mt-2'>
	  	<Text className='mr-4 text-secondary-200 inline'>
	  		Actions:
	  	</Text>
	  	<Text
	  	  className="text-center inline mr-4"
	  	>
	  	  <button className='bg-red-100 text-white text-xs py-1 px-3 rounded-full'>
	  	  	{status}
	  	  </button>
	  	</Text>
	  	<Text
	  		className="text-center inline mr-4"
	  		>
	  		  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions/failed?ref=${refNum}&affName=${affiliateName}&merchant=${merchantName}&date=${date}&amount=${amount}&reason=${reason}`}>
	  		  	VIEW
	  		  </Link>
	  	</Text>
	  </FlexRow>

	</details>

	<Div className='w-full phone:hidden tabletWide:block laptop:hidden'>
	<details title='Click to view Actions'>
	<summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100'>
	<FlexRow className='w-full justify-around'>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {date}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {refNum}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {merchantName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {affiliateName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {reason}
		</Text>
	</FlexRow>
	
	</summary>

	<FlexRow className='w-full justify-end border-t border-grey-400 pt-2'>
		<Text className='mr-4 font-semibold text-blue-100 inline'>
			Actions:
		</Text>
		<Text
		  className="text-center inline mr-4"
		>
		  <button className='bg-red-100 text-white text-xs py-1 px-3 rounded-full'>
		  	{status}
		  </button>
		</Text>
		<Text
	  		 className="text-center inline mr-4"
	  	>
	  		  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions/failed?ref=${refNum}&affName=${affiliateName}&merchant=${merchantName}&date=${date}&amount=${amount}&reason=${reason}`}>
	  		  	VIEW
	  		  </Link>
	  	</Text>
	</FlexRow>
	</details>
	
	</Div>

	<FlexRow className='w-full justify-around phone:hidden laptop:flex'>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {date}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {refNum}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {merchantName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {affiliateName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {reason}
		</Text>
		<Text
		  className="w-52 text-center"
		>
		  <button className='bg-red-100 text-white text-xs py-1 px-3 rounded-full'>
		  	{status}
		  </button>
		</Text>
		<Text
		  className="w-52 text-center"
		>
		  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions/failed?ref=${refNum}&affName=${affiliateName}&merchant=${merchantName}&date=${date}&amount=${amount}&reason=${reason}`}>
		  	VIEW
		  </Link>
		</Text>
		
	</FlexRow>
	
</div>
:
<>
<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2 cursor-not-allowed'>
	{
		loading?
		<Text className='my-4 text-center text-secondary-200'>
		  Loading data ...
		  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
		</Text>
		:
		<Text className='my-4 text-center text-secondary-200'>
			Record cannot be viewed.
		</Text>
	}
</div>
</>
}
</>
	)