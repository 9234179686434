/* eslint-disable no-unused-vars */
import React, { FC, Fragment } from 'react';
import { batch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import bell from '../assets/icons/notifIcons/bell.svg';
import { NotificationCard } from './NotifCard';
import { FlexCenter, FlexRow, Text } from '@src/core';
import { notifsActions, selectNotifs, selectUser, useAppDispatch } from '@src/redux';
import { classNames } from '@src/helpers';
import { useWindowSize } from '@src/hooks';
import { enableNotifications } from '@src/services/user/pusher';

export const NotificationBell: FC<any> = ({ userLink, notifLink, notifLink2 }) => {
  const dispatch = useAppDispatch();
  const [read, setRead] = React.useState(0);
  const [newData, setNewdata] = React.useState<any>([]);
  const notifs = useSelector(selectNotifs);
  const user = useSelector(selectUser);
  const screen = useWindowSize();
  const userType = user.affiliate_info === null;

  React.useEffect(() => {
    batch(async () => {
      if(user.user_info.role === 'merchant' || user.user_info.role === 'affiliate'){
        await dispatch(notifsActions.notifData());
      }else if(user.user_info.role === 'Manager' || user.user_info.role === 'Editor'){
        await dispatch(notifsActions.notifAdminData(user?.user_info?.merchant_id));
      }
    });
    enableNotifications(user, dispatch);
  }, []);

  React.useEffect(() => {
    const rdata = notifs.data.filter((notif: any) => notif.mark_as_read === null);
    setRead(rdata.length);

    const newList: any[] = notifs.data.filter((notif: any) => notif.merchant_name !== null);
    setNewdata(newList);
  }, [notifs]);

  const markRead = () => {
    batch(async () => {
      await dispatch(notifsActions.markNotifRead(''));
      if(user.user_info.role === 'merchant' || user.user_info.role === 'affiliate'){
        await dispatch(notifsActions.notifData());
      }else if(user.user_info.role === 'Manager' || user.user_info.role === 'Editor'){
        await dispatch(notifsActions.notifAdminData(user?.user_info?.merchant_id));
      }
    });
  };

  return (
    <div className="flex justify-end phone:pr-0 mobile:pr-2 tabletWide:pr-5">
      <Menu as="div" className="relative inline-block">
        <div>
          <Menu.Button className="flex flex-row">
            {screen.width >= 1000 ? (
              <div className='flex flex-row' onClick={markRead}>
                <img alt="bell" height={25} src={bell} title='Notifications' width={25} />
                <Text
                  className={classNames(
                    read === 0 ? 'bg-transparent' : 'bg-orange-100',
                    ' text-white rounded-full w-5 h-5 text-xs transform -translate-y-1 -translate-x-3 flex justify-center items-center',
                  )}
                >
                  {read === 0 ? <>&nbsp;</> : read}
                </Text>
              </div>
            ) : (
              <>
                <a href={userLink}>
                  <img alt="bell" height={25} src={bell} title='Notifications' width={25} />
                </a>
                <Text
                  className={classNames(
                    read === 0 ? 'hidden' : '',
                    'bg-orange-100 text-white rounded-full w-5 h-5 text-xs transform -translate-y-1 -translate-x-3 justify-center items-center',
                  )}
                >
                  {read}
                </Text>
              </>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="phone:hidden tablet:flex absolute flex-col divide-y divide-grey-400 bg-white z-50 mt-2 shadow shadow-xl w-96 rounded-md right-0">
            <Menu.Item>
              <FlexRow className="justify-between px-4 py-3">
                <Text className="text-primary-400 font-medium">Notifications</Text>
                <button
                  className="text-blue-400 text-sm hover:bg-grey-600 px-2 rounded-lg"
                  onClick={markRead}
                >
                  Mark all as read
                </button>
              </FlexRow>
            </Menu.Item>
            <Menu.Item>
            <div className={notifs.data.length > 0 ? 'h-96 overflow-y-scroll' : ''}>
            {notifs.data !== [] ? (
              [...newData]
                .reverse()
                .filter((item, index) => index < 5)
                .map((notif, i) => (
                    <NotificationCard
                      affiliateCompany={`${notif.affiliate_name === 'none, none' ? notif.affiliate_company_name : ''}`}
                      affiliateName={notif.affiliate_name}
                      category={notif.category}
                      createdAt={notif.created_at}
                      key={i}
                      merchant={userType}
                      merchantName={notif.merchant_name}
                      notifLink={notifLink}
                      notifLink2={notifLink2}
                      productName={notif.product_name}
                      read={notif.mark_as_read}
                      role={notif.affiliate_category}
                    />
                ))
            ) : (
              <FlexCenter className="py-8 text-sm">Currently no notifications.</FlexCenter>
            )}
            </div>
            </Menu.Item>
            <Menu.Item>
              <FlexCenter className="justify-between px-4 py-3">
                <Link
                  className="text-blue-400 font-medium px-2 rounded-lg hover:bg-grey-600"
                  to={userLink}
                >
                  See all Notifications
                </Link>
              </FlexCenter>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};