import React from 'react';
import { Flex, Div, Icons, Text } from '@core';

export const Wait = () => (
  <Flex className="phone:px-5 justify-center items-center">
    <Div className="flex flex-row items-center">
      <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate || 'https://github.com/XtendlyORG/xffiliate-frontend/blob/development/src/assets/icons/xffiliate-logo.png?raw=true'} />
      <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
        Xffiliate
      </Text>
    </Div>
    <Text className='text-center mt-4'>
      Loading...
      <i className="fad fa-spinner-third animate-spin ml-3" />
    </Text>
  </Flex>
);