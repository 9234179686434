import React, {FC} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text, FlexRow, ToastPermissions, ToastV4, Toast } from '@core';
import { API } from '@config';
import { getCookie } from '@hooks';

interface DataProps {
	email: string;
	name: string;
	contact: string;
	id: number;
	readUsers: boolean;
	updateUsers: boolean;
	removeUsers: boolean;
	permitted: boolean;
}

export const AdminData: FC<DataProps> = ({
	email,
	name,
	contact,
	id,
	readUsers,
	updateUsers,
	removeUsers,
	permitted,
}) => {

	const token = getCookie('token');
	const navigate = useNavigate();
	const { search } = useLocation();
	const query = new URLSearchParams(search)
	const actionQuery = query.get('action');

	const [isToastOpenPermission, setToastOpenPermission] = React.useState(false);

	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('');
	const [toastDesc, setToastDesc] = React.useState('');

	const [isToastOpen2, setToastOpen2] = React.useState(false);
	const [toastKind2, setToastKind2] = React.useState('success');
	const [toastTitle2, setToastTitle2] = React.useState('');
	const [toastDesc2, setToastDesc2] = React.useState('');

	React.useEffect(() => {
		if(actionQuery === 'updated'){
			setToastOpenPermission(false)
			setToastOpen(false)
		}
	})

	const Remove = async () => {
	  setToastTitle2('Action');
	  setToastDesc2(`Are you sure you want to remove ${name} as admin and delete all credentials?`);
	  setToastKind2('info');
	  setToastOpen2(true);
	}

	const onRemove = async () => {
	setToastOpen2(false);
	fetch(`${API}/remove-superadmin/${id}`, {
	  	method: 'DELETE',
	  	headers:	{
          	Authorization: `Bearer ${token}`
	  	}
	  })
	  .then(res => res.json())
      .then(data => {
      	if(data.status === 1){
      		setToastTitle('Success');
      		setToastDesc('Admin has been deleted from the list.');
      		setToastKind('success');
      		setToastOpen(true);
      		setTimeout(() => {
      			navigate('/admin/manage_users?action=updated')
      		}, 1000)
      	}else{
      		setToastTitle('Error');
      		setToastDesc( data?.message || 'Something went wrong. Try again.');
      		setToastKind('error');
      		setToastOpen(true);
      	}
      })
	}

return(
<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2 flex flex-row tabletWide:items-center' key={id}>

	<ToastPermissions
	  descriptionP='View Permissions'
	  id={id}
	  isOpenP={isToastOpenPermission}
	  onClose={() => setToastOpenPermission(false)}
	  permitted={permitted}
	  titleP='INFO'
	  toastKindP='info'
	/>
	<ToastV4
		  btnText='Cancel'		
	      description={toastDesc2}
	      isOpen={isToastOpen2}
	      link=''
	      onClick={onRemove}
	      onClick2={() => setToastOpen2(false)}
	      onClose={() => setToastOpen2(false)}
	      submitBtnText='Remove'
	      title={toastTitle2}
	      toastKind={toastKind2}
	    />
	<Toast
	      description={toastDesc}
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	      title={toastTitle}
	      toastKind={toastKind}
	    />

	<input className='mr-4 tabletWide:mr-0 hidden' type='checkbox' />

	<FlexRow className='flex-col tabletWide:hidden phone:w-1/3 surfaceDuo:w-1/2'>
		<Text
		  className="text-base text-secondary-200 font-light w-full text-left"
		>
		  Name:
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-full text-left"
		>
		  Email:
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-full text-left"
		>
		  Contact:
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-full text-left"
		>
		  Actions:
		</Text>
	</FlexRow>

	<FlexRow className='phone:flex-col phone:w-2/3 tabletWide:flex-row tabletWide:w-full tabletWide:flex tabletWide:justify-between'>
		<Text className='w-1/4 text-secondary-200 text-left flex flex-row items-center hidden tabletWide:flex'>
			<div className='phone:hidden tabletWide:flex items-center justify-center w-10 h-10 rounded-full bg-grey-400 mr-4'>
				{name.substring(0, 1).toUpperCase()}
			</div>
		  {name}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light phone:w-full tabletWide:w-1/4 text-left tabletWide:hidden truncate"
		>
		  {name}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light phone:w-full tabletWide:w-1/4 text-left truncate"
		>
		  {email}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light phone:w-full tabletWide:w-1/4 text-left"
		>
		  {contact}
		</Text>
		{
			readUsers || updateUsers?
			<>
			<Text className='phone:w-full tabletWide:w-1/4 text-blue-100 text-left flex flex-row justify-start'>
			  	<span className='text-blue-100 hover:underline cursor-pointer font-semibold mr-2 phone:hidden surfaceDuo:inline' onClick={() => setToastOpenPermission(true)}>
			  		View Permissions
			  	</span>
			  	<FlexRow>
			  	{
			  		updateUsers?
			  		<i className="fas fa-edit mx-2 cursor-pointer hidden" onClick={() => navigate(`/admin/manage_users?action=toUpdate&admin=${id}`)}/>
			  		:
			  		<i className="fas fa-edit mx-2 cursor-not-allowed hidden"/>
			  	}
			  	{
			  		removeUsers?
			  		<i className="fas fa-trash-alt ml-2 cursor-pointer" onClick={Remove}/>
			  		:
			  		<i className="fas fa-trash-alt ml-2 cursor-not-allowed"/>
			  	} 
			    </FlexRow>
			    <span className='text-blue-100 hover:underline cursor-pointer font-bold surfaceDuo:hidden ml-2' onClick={() => setToastOpenPermission(true)}>
			    	&#8942;&#8942;
			    </span>
			</Text>
			</>
			:
			<Text className='phone:w-full tabletWide:w-1/4 text-grey-400 text-left flex flex-row justify-start cursor-not-allowed'>
			  	NOT ALLOWED
			</Text>
		}
	</FlexRow>
	
</div>
)
};