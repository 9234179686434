import React, { FC } from 'react';
import { batch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { Text, Icons, H2, Info, Div, Flex, FlexRow, Input, Button, Toast } from '@core';
import { Stage } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';

export const AffiliateEditAboutYou: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const token = getCookie('token');

  const query = new URLSearchParams(search);
  const userId = query.get('id');
  const merchantId = query.get('merchant');

  const [firstname, setFirstName] = React.useState(user?.user_info?.firstname);
  const [lastname, setLastName] = React.useState(user?.user_info?.lastname);
  const [contactNumber, setContactNumber] = React.useState(user?.user_info?.contact);

  const [isCompany, setIsCompany] = React.useState(false);
  const [companyName, setCompanyName] = React.useState('');
  const [companyLink, setCompanyLink] = React.useState('');
  const [companyNumber, setCompanyNumber] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  React.useEffect(() => {
    batch(async() => {
      await dispatch(usersActions.profile());
    })
  }, [])

  React.useEffect(() => {
    if(user?.affiliate_company_info === null){
      setIsCompany(false)
      setFirstName(user?.user_info?.firstname)
      setLastName(user?.user_info?.lastname)
      setContactNumber(user?.user_info?.contact)
    }else{
      setIsCompany(true)
      setCompanyName(user?.affiliate_company_info?.company_name || '')
      setCompanyLink(user?.affiliate_company_info?.company_link || '')
      setCompanyNumber(user?.affiliate_company_info?.contact_number || '')
    }
  }, [user])

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if(isCompany){
      if(parseInt(companyNumber) < 0 || regExp.test(companyNumber)){
        setCompanyNumber('')
      }
    }else if (!isCompany){
      if(parseInt(contactNumber) < 0 || regExp.test(contactNumber)){
        setContactNumber('')
      }
    }
  }, [contactNumber, companyNumber])

  const onSubmit = () => {
    if (firstname === '' || lastname === '' || contactNumber === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newInfo({
            firstname,
            lastname,
            contact: user?.user_info?.contact,
            role: user?.user_info?.role,
            subscription: user?.user_info?.subscription,
          }),
        );

        if (data?.payload?.status === 200) {
          setToastTitle('Account Update');
          setToastDesc('Account details updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          await dispatch(usersActions.profile());
          setTimeout(() => {
            navigate(`/affiliate/onboard/setup?id=${userId}&merchant=${merchantId}&role=affiliate`)
          }, 1000)
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
          }, 2000)
        }
      });
    }
  };

  const onUpdate = () => {
    if (companyName === '' || companyLink === '' || companyNumber === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      setLoading(true)
      fetch(`${API}/update-affiliate-company`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_name: companyName,
          company_link: companyLink,
          contact_number: companyNumber,
          company_email: user?.user_info?.email
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data?.status === 1) {
          setToastTitle('Account Update');
          setToastDesc('Account details updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          batch(async () => {
            await dispatch(usersActions.profile());
            setTimeout(() => {
              navigate(`/affiliate/onboard/setup?id=${userId}&merchant=${merchantId}&role=affiliate`)
            }, 1000)
          })
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
          }, 2000)
        }
      })
    }
  };

  return (
    <Flex className="laptop:px-12 phone:px-2 overflow-y-scroll items-center">
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="phone:px-5 tablet:px-10 laptop:px-20 w-full">
        <Div className="mr-auto flex flex-row items-center py-8">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
        </Div>
        <hr className="text-secondary-300 laptop:mb-20 phone:mb-10" />
      </Div>
      <Div className="phone:px-4 pb-10 phone:w-full tablet:w-3/5 laptop:w-9/12">
        <FlexRow className="h-full phone:flex-col laptop:flex-row">
          <Flex className="mr-10 phone:mr-0 phone:items-center laptop:items-start">
            
              <>
                <H2 className="text-primary-100 phone:text-center laptop:text-left">
                  Your Links Are Everything
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center laptop:text-left tablet:pr-10"
                >
                  Links carry your brand and connect you audience in ways that no other medium can.
                  They’re kind of a big deal.
                </Info>
                <Info
                  className="text-secondary-100 mt-4 pr-10 phone:pr-0  
            phone:text-center laptop:text-left tablet:pr-10"
                >
                  You’re about to start creating powerful links. Easier-to-manage links. Branded
                  links. Xffiliate links.
                </Info>
              </>
            
          </Flex>
          <Flex className="phone:mt-10">
            <FlexRow
              className="laptop:mb-7 phone:mb-2 phone:flex-col phone:space-y-4
            tablet:flex-row items-center tablet:space-y-0 w-full phone:text-center phone:justify-center laptop:justify-start"
            >
              <Stage isCurrent label="About you" stageNumber="1" />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Select Products"
                stageNumber="2"
              />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:flex" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Complete"
                stageNumber="3"
              />
            </FlexRow>

            <Info className="text-primary-100 mb-4 mt-5">Join Xffiliate as...</Info>

            <Text className='font-semibold mx-3 text-2xl text-primary-100'>Affiliate Partner</Text>
            
            {
              isCompany?
              <form>
                
                  <Div className="mt-8">
                    <Input
                      label="Company Name *"
                      name="companyName"
                      onChange={setCompanyName}
                      placeholder="Enter your first name"
                      type="text"
                      value={companyName}
                    />
                    <Input
                      inputmode="numeric"
                      label="Company Contact Number *"
                      maxlength={11}
                      name="companyNumber"
                      onChange={setCompanyNumber}
                      pattern="[0-9]*"
                      placeholder="Enter your contact number"
                      type="text"
                      value={companyNumber}
                    />
                    <Input
                      label="Company Link *"
                      name="companylink"
                      onChange={setCompanyLink}
                      placeholder="eg: FB, Instagram, Twitter"
                      type="text"
                      value={companyLink}
                    />
                  </Div>

                <FlexRow>
                  <Button
                    className="mt-5 px-14 ml-auto phone:w-full phone:justify-center"
                    isLoading={loading}
                    onClick={onUpdate}
                  >
                    Update
                  </Button>
                </FlexRow>

              </form>
              :
              <form>
                
                  <Div className="mt-8">
                    <Input
                      label="First name *"
                      name="first-name"
                      onChange={setFirstName}
                      placeholder="Enter your first name"
                      type="text"
                      value={firstname}
                    />
                    <Input
                      label="Last name *"
                      name="last-name"
                      onChange={setLastName}
                      placeholder="Enter your last name"
                      type="text"
                      value={lastname}
                    />
                    <Input
                      inputmode="numeric"
                      label="Contact Number *"
                      maxlength={11}
                      name="contact-number"
                      onChange={setContactNumber}
                      pattern="[0-9]*"
                      placeholder="Enter your contact number"
                      type="text"
                      value={contactNumber}
                    />
                  </Div>

                <FlexRow>
                  <Button
                    className="mt-5 px-14 ml-auto phone:w-full phone:justify-center"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    Update
                  </Button>
                </FlexRow>

              </form>
            }

            <Link className='mt-5 items-center text-primary-100 hover:underline' to={`/affiliate/onboard/setup?id=${userId}&merchant=${merchantId}&role=affiliate`}>
              Skip to Next Step
              <i className="fas fa-angle-right ml-2"/>
              <i className="fas fa-angle-right"/>
            </Link>

          </Flex>
        </FlexRow>
      </Div>
    </Flex>
  );
};