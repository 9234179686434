import React, { FC } from 'react';
import validator from 'validator';
import { batch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usersActions, useAppDispatch } from '@redux';
import {
  Text,
  Icons,
  H2,
  Info,
  Div,
  Flex,
  FlexRow,
  Input,
  Radio,
  Button,
  Toast,
  ToastV4,
} from '@core';
import { Stage } from '@components';

export const AboutYou: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(search);
  const userId = query.get('id');
  const user = query.get('user');

  const [joinAs, setJoinAs] = React.useState(user === 'affiliate' ? 'affiliate' : 'merchant');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');
  const [socialLink, setSocialLink] = React.useState('');
  const [storeName, setStoreName] = React.useState('');
  const [storeLink, setStoreLink] = React.useState('');
  const [isCompany] = React.useState(false);
  const [isWixUser, setIsWixUser] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [isToastOpen4, setToastOpen4] = React.useState(false);
  const [toastKind4] = React.useState('info');
  const [toastTitle4] = React.useState('ACTION');
  const [toastDesc4, setToastDesc4] = React.useState(
    'Hi! You have chosen to join Xffiliate as a MERCHANT. Please Submit to continue.',
  );

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if (parseInt(contactNumber) < 0 || regExp.test(contactNumber)) {
      setContactNumber('');
    }
  }, [contactNumber]);

  React.useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = await dispatch(usersActions.profile());
      const checkIfWixUser = userInfo?.payload?.data?.is_wix_user;
      setIsWixUser(checkIfWixUser);
      if (checkIfWixUser) {
        setJoinAs('merchant');
      }
    };
    getUserInfo();
  }, [user]);

  const Affiliate = () => {
    setJoinAs('affiliate');
    setToastDesc4(
      'Hi! You have chosen to join Xffiliate as an AFFILIATE. Please Submit to continue.',
    );
  };

  const Merchant = () => {
    setJoinAs('merchant');
    setToastDesc4(
      'Hi! You have chosen to join Xffiliate as a MERCHANT. Please Submit to continue.',
    );
  };

  const onSubmit = async () => {
    setToastOpen4(false);
    const batching = async () => {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newRole({
            is_company: isCompany,
            user_id: userId || '0',
            firstname: firstName,
            lastname: lastName,
            contact: contactNumber,
            role: joinAs,
            social_link: socialLink,
            store_name: storeName,
            store_link: storeLink,
          }),
        );
        if (data?.payload?.status !== 500 || data?.payload?.status === 200) {
          setToastTitle('Successfully Added');
          setToastDesc('Redirecting to Setup page.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
            if (joinAs === 'merchant') {
              navigate(`/onboard/setup?id=${userId}&role=${joinAs}`);
            } else {
              navigate(`/onboard/complete?id=${userId}&role=${joinAs}`);
            }
          }, 2000);
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    };
    if (joinAs === 'merchant') {
      if (
        firstName === '' ||
        lastName === '' ||
        storeName === '' ||
        contactNumber === '' ||
        storeLink === ''
      ) {
        setToastTitle('Invalid Input');
        setToastDesc('Please complete all the fields.');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
      } else if (
        !validator.isURL(storeLink, {
          protocols: ['http', 'https'],
          require_host: true,
          require_protocol: true,
        })
      ) {
        setToastTitle('Invalid Input');
        setToastDesc('Please enter a valid store link.');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
      } else {
        await batching();
      }
    } else if (firstName === '' || lastName === '' || socialLink === '' || contactNumber === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if (
      !validator.isURL(socialLink, {
        protocols: ['http', 'https'],
        require_host: true,
        require_protocol: true,
      })
    ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please enter a valid social link.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      await batching();
    }
  };

  return (
    <Flex className="laptop:px-12 phone:px-2 overflow-y-scroll items-center">
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <ToastV4
        btnText="Cancel"
        description={toastDesc4}
        isOpen={isToastOpen4}
        link=""
        onClick={onSubmit}
        onClick2={() => setToastOpen4(false)}
        onClose={() => setToastOpen4(false)}
        submitBtnText="Submit"
        title={toastTitle4}
        toastKind={toastKind4}
      />
      <Div className="phone:px-5 tablet:px-10 laptop:px-20 w-full">
        <Div className="mr-auto flex flex-row items-center py-8">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
        </Div>
        <hr className="text-secondary-300 laptop:mb-20 phone:mb-10" />
      </Div>
      <Div className="phone:px-4 pb-10 phone:w-full tablet:w-6/12 laptop:w-9/12">
        <FlexRow className="h-full phone:flex-col laptop:flex-row">
          <Flex className="mr-10 phone:mr-0 phone:items-center tablet:items-start">
            {joinAs === 'merchant' ? (
              <>
                <H2 className="text-primary-100 phone:text-center tablet:text-left">
                  Monetize Your Network
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Grow sales by creating and enabling your affiliate network to earn commissions by
                  sharing your store and product links.
                </Info>
              </>
            ) : (
              <>
                <H2 className="text-primary-100 phone:text-center tablet:text-left">
                  Your Links Are Everything
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Links carry your brand and connect you audience in ways that no other medium can.
                  They’re kind of a big deal.
                </Info>
                <Info
                  className="text-secondary-100 mt-4 pr-10 phone:pr-0  
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  You’re about to start creating powerful links. Easier-to-manage links. Branded
                  links. Xffiliate links.
                </Info>
              </>
            )}
          </Flex>
          <Flex className="phone:mt-10">
            <FlexRow
              className="items-center laptop:mb-7 phone:mb-2 phone:flex-col phone:items-start phone:space-y-4
            tablet:flex-row tablet:items-center tablet:space-y-0"
            >
              <Stage isCurrent label="About you" stageNumber="1" />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Setup"
                stageNumber="2"
              />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:flex" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Complete"
                stageNumber="3"
              />
            </FlexRow>
            <form>
              {user === 'affiliate' ? (
                <></>
              ) : !isWixUser ? (
                <>
                  <Info className="text-primary-100 mb-4 mt-5">Join Xffiliate as...</Info>
                  <Radio
                    isChecked={joinAs === 'merchant'}
                    label="A Merchant"
                    name="merchant"
                    onClick={Merchant}
                    optionId="join-as"
                  />
                  <Radio
                    isChecked={joinAs === 'affiliate'}
                    label="An Affiliate Partner"
                    name="affiliate"
                    onClick={Affiliate}
                    optionId="join-as"
                  />
                </>
              ) : (
                <Info className="text-primary-100 mb-4 mt-5">
                  As a user that came from wix platform, you are automatically added as a{' '}
                  <b>MERCHANT</b>.
                </Info>
              )}

              {joinAs === 'merchant' ? (
                <Div className="mt-8">
                  <Input
                    label="First name *"
                    name="first-name"
                    onChange={setFirstName}
                    placeholder="Enter your first name"
                    type="text"
                    value={firstName}
                  />
                  <Input
                    label="Last name *"
                    name="last-name"
                    onChange={setLastName}
                    placeholder="Enter your last name"
                    type="text"
                    value={lastName}
                  />
                  <Input
                    inputmode="numeric"
                    label="Contact Number *"
                    maxlength={11}
                    name="contact-number"
                    onChange={setContactNumber}
                    pattern="[0-9]*"
                    placeholder="Enter your contact number"
                    type="text"
                    value={contactNumber}
                  />
                  <Input
                    label="Store name *"
                    name="store-name"
                    onChange={setStoreName}
                    placeholder="Enter your store name"
                    type="text"
                    value={storeName}
                  />
                  <Input
                    label="Store link *"
                    name="store-link"
                    onChange={setStoreLink}
                    placeholder="eg: Facebook Page, Website"
                    type="text"
                    value={storeLink}
                  />
                </Div>
              ) : (
                <Div className="mt-8">
                  <Input
                    label="First name *"
                    name="first-name"
                    onChange={setFirstName}
                    placeholder="Enter your first name"
                    type="text"
                    value={firstName}
                  />
                  <Input
                    label="Last name *"
                    name="last-name"
                    onChange={setLastName}
                    placeholder="Enter your last name"
                    type="text"
                    value={lastName}
                  />
                  <Input
                    inputmode="numeric"
                    label="Contact Number *"
                    maxlength={11}
                    name="contact-number"
                    onChange={setContactNumber}
                    pattern="[0-9]*"
                    placeholder="Enter your contact number"
                    type="text"
                    value={contactNumber}
                  />
                  <Input
                    label="Social Link *"
                    name="social-link"
                    onChange={setSocialLink}
                    placeholder="eg: FB, Instagram, Twitter"
                    type="text"
                    value={socialLink}
                  />
                  {/* <Select
                    label="Mode of Payment *"
                    name="mode-payment"
                    onChange={setPayment}
                    options={PaymentMethods}
                    placeholder="Select your payment method"
                    value={payment}
                  /> */}
                </Div>
              )}

              <FlexRow>
                <Button
                  className="mt-5 px-14 ml-auto phone:w-full phone:justify-center"
                  isLoading={loading}
                  onClick={() => setToastOpen4(true)}
                >
                  Next
                </Button>
              </FlexRow>
            </form>
          </Flex>
        </FlexRow>
      </Div>
    </Flex>
  );
};
