import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addPartner,
  addPartnerAdmin,
  getPartners,
  getPartnersAdmin,
  getApplicants,
  getAffiliatedProducts,
  getAffiliatedProductsAdmin,
  updatePartnerStatus,
  updatePartnerStatusAdmin,
  getPartnerAffiliates,
  deletePartner,
  getAffiliateList,
  getProductAnalytics,
  getAffiliatePage,
  searchAffiliatePage,
  deleteApplication,
} from '@services';

interface PARTNER {
  user_id: string;
  product_id: string;
  status: string;
  role: string;
}

interface GRAPH {
  status: number,
  data: {
    graph1: {
      revenue: {
        date_created: string,
        clicked: number
      }[],
      commission: {
        date_created: string,
        clicked: number
      }[]
    },
    graph2: {
      clicks: {
        date_created: string,
        clicked: number
      }[],
      conversion: {
        date_created: string,
        clicked: number
      }[]
    }
  }
}

interface AFFILIATEPAGE {
  prodID: string, 
  page: number,
  sortBy: string, 
  sortType: string
}

interface AFFILIATESEARCH {
  prodID: string, 
  search: string,
  sortBy: string, 
  sortType: string
}

interface STATE {
  loading: boolean;
  data: [];

  // States of application list
  applicationListLoading: boolean;
  applicationList: [];

  // States of per product affiliate list
  productAffiliateListLoading: boolean;
  productAffiliateList: [];

  // States of per porduct affiliate page
  productAffiliatePageLoading: boolean;
  productAffiliatePage: [];

  // States of per porduct affiliate page
  productAffiliateSearchLoading: boolean;
  productAffiliateSearch: [];

  // States of graphs per product
  productGraphsLoading:boolean;
  productGraphs: GRAPH;
}

// Initial States
export const partnersInitialState: STATE = {
  loading: false,
  data: [],
  applicationListLoading: false,
  applicationList: [],
  productAffiliateListLoading: false,
  productAffiliateList: [],
  productAffiliatePageLoading: false,
  productAffiliatePage: [],
  productAffiliateSearchLoading: false,
  productAffiliateSearch: [],
  productGraphsLoading: false,
  productGraphs: {
    status: 0,
    data: {
      graph1: {
        revenue: [{
          date_created: '',
          clicked: 0
        }],
        commission: [{
          date_created: '',
          clicked: 0
        }]
      },
      graph2: {
        clicks: [{
          date_created: '',
          clicked: 0
        }],
        conversion: [{
          date_created: '',
          clicked: 0
        }]
      }
    }
  },
};

const partnersList = createAsyncThunk('partners/partnersList', async () => {
  const data: any = await getPartners();
  const partners = Object.values(data.data.data);
  const filtered = partners?.filter(
    (v: any, i: any, a: any) => a.findIndex((t: any) => t.user_id === v.user_id) === i,
  );

  return {
    data: filtered || [],
  };
});

const partnersListAdmin = createAsyncThunk('partners/partnersListAdmin', async (id: number) => {
  const data: any = await getPartnersAdmin(id);
  const partners = Object.values(data.data.data);
  const filtered = partners?.filter(
    (v: any, i: any, a: any) => a.findIndex((t: any) => t.user_id === v.user_id) === i,
  );

  return {
    data: filtered || [],
  };
});

const newPartner = createAsyncThunk('partners/newPartner', async (body: PARTNER) => {
  const { data, status }: any = await addPartner(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newPartnerAdmin = createAsyncThunk('partners/newPartnerAdmin', async (body: PARTNER) => {
  const { data, status }: any = await addPartnerAdmin(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newStatus = createAsyncThunk(
  'partners/newStatus',
  async (body: { id: string; status: string; code: string; token?: string }) => {
    const { data, status }: any = await updatePartnerStatus(body);
    if (data.errors) {
      console.log('app error', data.errors, 'status', status);
      return {
        status: 422,
        userId: 0,
        message: data.errors[Object.keys(data.errors)[0]],
        error: true,
      };
    }
    if (status === 200) {
      return {
        status: 200,
        message: data.message,
        error: false,
        data,
      };
    }
    return {
      status: 500,
      userId: 0,
      message: 'Something went wrong.',
      error: true,
    };
  },
);

const newStatusAdmin = createAsyncThunk(
  'partners/newStatusAdmin',
  async (body: { id: string; status: string; code: string; token?: string }) => {
    const { data, status }: any = await updatePartnerStatusAdmin(body);
    if (data.errors) {
      console.log('app error', data.errors, 'status', status);
      return {
        status: 422,
        userId: 0,
        message: data.errors[Object.keys(data.errors)[0]],
        error: true,
      };
    }
    if (status === 200) {
      return {
        status: 200,
        message: data.message,
        error: false,
        data,
      };
    }
    return {
      status: 500,
      userId: 0,
      message: 'Something went wrong.',
      error: true,
    };
  },
);

const removePartner = createAsyncThunk('partners/removePartner', async (affiliateId: number) => {
  const { data, status }: any = await deletePartner(affiliateId);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const removeApplication = createAsyncThunk('partners/removePartner', async (affiliateId: number) => {
  const { data, status }: any = await deleteApplication(affiliateId);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const affiliatedProducts = createAsyncThunk(
  'partners/affiliatedProducts',
  async (userId: string) => {
    const data: any = await getAffiliatedProducts(userId);
    return data;
  },
);

const affiliatedProductsAdmin = createAsyncThunk(
  'partners/affiliatedProductsAdmin',
  async (userId: string) => {
    const data: any = await getAffiliatedProductsAdmin(userId);
    return data;
  },
);

const partnerAffiliates = createAsyncThunk('partners/partnerAffiliates', async () => {
  const data: any = await getPartnerAffiliates();
  return data;
});

// Application List
const applicationList = createAsyncThunk(
  'partners/applicationList', 
  async (productId: string) => {
    const data: any = await getApplicants(productId);
    // console.log('application list data', data)
    return data;
  }
);

// All Affiliates per product
const productAffiliateList = createAsyncThunk(
  'partners/productAffiliateList',
  async (prodID: string) => {
    const data: any = await getAffiliateList(prodID);
    return data;
  },
);

// Pagination Affilates per product
const productAffiliatePage = createAsyncThunk(
  'partners/productAffiliatePage',
  async (params: AFFILIATEPAGE) => {
    const data: any = await getAffiliatePage(
      params.prodID, params.page, params.sortBy, params.sortType
    );
    return data;
  }
)

// Pagination Affilates per product
const productAffiliateSearch = createAsyncThunk(
  'partners/productAffiliateSearch',
  async (params: AFFILIATESEARCH) => {
    const data: any = await searchAffiliatePage(
      params.prodID, params.search, params.sortBy, params.sortType
    );
    return data;
  }
)

// Graphs per product
const productGraphData = createAsyncThunk(
  '/product-analytics',
  async (prodID: string) => {
  const data:any = await getProductAnalytics(prodID);
  return data
});

const { actions, reducer } = createSlice({
  name: 'partners',
  initialState: partnersInitialState,
  reducers: {},
  extraReducers: {
    [partnersList.pending.type]: (state) => {
      state.loading = true;
    },
    [partnersList.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
    [partnersListAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [partnersListAdmin.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
    [applicationList.pending.type]: (state) => {
      state.applicationListLoading = true;
    },
    [applicationList.fulfilled.type]: (state, { payload }) => {
      state.applicationList = payload?.data?.data;
      state.applicationListLoading = false;
    },
    [productAffiliateList.pending.type]: (state) => {
      state.productAffiliateListLoading = true;
    },
    [productAffiliateList.fulfilled.type]: (state, { payload }) => {
      state.productAffiliateList = payload?.data?.data;
      state.productAffiliateListLoading = false;
    },
    [productAffiliatePage.pending.type]: (state) => {
      state.productAffiliatePageLoading = true;
    },
    [productAffiliatePage.fulfilled.type]: (state, { payload }) => {
      state.productAffiliatePage = payload?.data?.data;
      state.productAffiliatePageLoading = false;
    },
    [productAffiliateSearch.pending.type]: (state) => {
      state.productAffiliateSearchLoading = true;
    },
    [productAffiliateSearch.fulfilled.type]: (state, { payload }) => {
      state.productAffiliateSearch = payload?.data?.data;
      state.productAffiliateSearchLoading = false;
    },
    [productGraphData.pending.type]: (state) => {
      state.productGraphsLoading = true;
    },
    [productGraphData.fulfilled.type]: (state, { payload }) => {
      state.productGraphs = payload.data || [];
      state.productGraphsLoading = false;
    }
  },
});

export const partnersActions = {
  ...actions,
  newPartner,
  newPartnerAdmin,
  partnersList,
  partnersListAdmin,
  applicationList,
  affiliatedProducts,
  affiliatedProductsAdmin,
  newStatus,
  newStatusAdmin,
  partnerAffiliates,
  removePartner,
  productAffiliateList,
  productAffiliatePage,
  productAffiliateSearch,
  productGraphData,
  removeApplication,
};

export const partnersReducer = reducer;