import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface ButtonProps {
  onClose: any;
  isOpen: boolean;
}

export const ToastCustom: FC<ButtonProps> = ({
  onClose, isOpen, children
}) => (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-60 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="w-full h-full">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-60 phone:p-4 tablet:p-8 laptop:p-20
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <button
                aria-label="Close"
                className="text-blue-300 hover:text-white bg-grey-400 px-2 flex justify-center items-center rounded-lg fixed top-4 right-4 z-70"
                data-collapse-toggle="toast-success"
                onClick={onClose}
                type="button"
              >
                <span>Close</span>
                <i className="fas fa-times text-3xl ml-2" />
              </button>
              <div
                className='flex flex-col w-full h-full'
                id="toast-success"
                role="alert"
              >
                {children}

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
);