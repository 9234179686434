import { combineReducers } from 'redux';
import {
  usersReducer,
  productsReducer,
  partnersReducer,
  linksReducer,
  catalogsReducer,
  merchantsReducer,
  notifsReducer,
} from './slices';

export default combineReducers({
  user: usersReducer,
  products: productsReducer,
  partners: partnersReducer,
  links: linksReducer,
  catalogs: catalogsReducer,
  merchants: merchantsReducer,
  notifications: notifsReducer,
});
