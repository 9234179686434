import React, { FC } from 'react';
import { Text, FlexRow } from '@core';

interface StageProps {
  isCurrent: boolean;
  stageNumber: string;
  label: string;
  className?: string;
}

export const Stage: FC<StageProps> = ({ isCurrent, stageNumber, label, className }) => (
  <FlexRow className={className}>
    <Text
      className={`font-poppins ${isCurrent ? 'bg-primary-100' : 'bg-grey-300'} text-white w-10 
      h-10 rounded-full flex justify-center items-center text-base`}
    >
      {stageNumber}
    </Text>
    <Text
      className={`font-poppins ${isCurrent ? 'text-primary-100' : 'text-grey-300'} ml-2 
      rounded-full flex justify-center items-center text-base`}
    >
      {label}
    </Text>
  </FlexRow>
);
