import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import { Div, Text } from '@core';

interface PaymentProps {
  paymentType: string;
  paymentDate: string;
  referenceNum: string;
  amount: number;
  id?: string;
  user: any;
  status: string;
}

export const PaymentData: FC<PaymentProps> = ({
  paymentType,
  paymentDate,
  referenceNum,
  amount,
  id,
  user,
  status,
}) => (
  <>
  <Div className='phone:flex phone:flex-row items-center justify-around'>

      <Div className='phone:flex phone:flex-col phone:w-1/2 medium:hidden tablet:flex tabletWide:hidden'>
          <Text className='hidden' key={id}>{id}</Text>
          <Text
            className=" text-base text-secondary-200 font-light w-full text-left"
          >
            Type:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Date:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Reference No.
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Amount:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Status:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Action:
          </Text>
      </Div>

      <Div className='phone:flex phone:flex-col phone:w-1/2 medium:flex-row medium:w-full tablet:w-1/2 tablet:flex-col tabletWide:w-full tabletWide:flex-row cursor-pointer hover:bg-grey-500 py-2' >
                <Text
                  className=" text-base text-secondary-200 font-light w-full medium:w-1/6 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/6"
                >
                  {paymentType}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light w-full medium:w-1/6 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/6"
                >
                  {paymentDate.substring(0, 10)}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light  w-full medium:w-1/6 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/6"
                >
                  {referenceNum}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light  w-full medium:w-1/6 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/6"
                >
                   {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light  w-full medium:w-1/6 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/6"
                >
                   {status}
                </Text>
                <div
                  className="flex medium:justify-center tablet:justify-start tabletWide:justify-center items-center text-base text-blue-100 font-light  w-full medium:w-1/6 tablet:w-1/2 tabletWide:w-1/6"
                >
                  <Link
                    className='border-b-2 border-white hover:border-blue-100 font-semibold text-sm'
                    to={user?.user_info?.role === 'merchant' ? `/merchant/transaction_details?reference=${referenceNum}` : `/merchant/${user?.user_info?.role.toLowerCase()}/transaction_details?reference=${referenceNum}`}
                  >
                      View
                  </Link>
                </div>
            </Div>

  </Div>
  <hr className="text-grey-400 mb-4" />
  </>
  );