export const Editor = [
  'Add, Edit, Remove Products',
  'Add, Edit, Remove Partners',
];

export const Owner = [
  'Add, Edit, Remove Products',
  'Add, Edit, Remove Partners',
  'Manage Admins and Roles',
  'Manage Affiliate Categories',
  'Release Disbursements',
  'Update User Settings'
];

export const Manager = [
  'Add, Edit, Remove Products',
  'Add, Edit, Remove Partners',
  'Manage Admins and Roles',
  'Manage Affiliate Categories',
  'Release Disbursements',
  'Update User Settings'
];

export const NoData = [
  'No data found.'
];

export const FrequencyOptions = [
  {
    label: 'Weekly',
    name: 'weekly',
    cutOff: 'Reminders will be sent every Wednesday 8:00PM PHT.',
    cutoffTime: '20:00',
    cutoffDay: '3',
    details: '(Disbursements processed every Friday at 8:00PM PHT)',
    disburseTime: '00:00',
    disburseDay: '5'
  },
  {
    label: 'Bi-Monthly',
    name: 'bi-monthly',
    cutOff: 'Reminders will be sent every 1st and 16th day of the month at 8:00PM PHT.',
    cutoffTime: '20:00',
    cutoffDay: '1',
    details: '(Disbursements processed every 3rd and the 18th day of the month at 8:00PM PHT)',
    disburseTime: '00:00',
    disburseDay: '3'
  },
  {
    label: 'Monthly',
    name: 'monthly',
    cutOff: 'Reminders will be sent every 1st day of the month at 8:00PM PHT.',
    cutoffTime: '20:00',
    cutoffDay: '3',
    details: '(Disbursements processed every 3rd day of the succeeding month at 8:00PM PHT)',
    disburseTime: '00:00',
    disburseDay: '3'
  },
]

export const AdminRoles = [
  'Manager',
  'Editor'
]

export const AccountUserTypes = [
  'User',
  'Admin'
]

export const AffiliateCategories = [
  'Dealer',
  'Sub-Dealer',
  'Reseller',
  'Distributor'
]

export const AffiliateCategory = [
  '-- CUSTOM --',
  'Dealer',
  'Sub-Dealer',
  'Reseller',
  'Distributor',
]

export const OptionsFree = [{label: 'Partner as Regular Affiliate', value: 'product'}];

export const OptionsPlans = [{label: 'Partner as Categorized Affiliate', value: 'category'}, {label: 'Partner as Regular Affiliate', value: 'product'}];

export const OptionsDefault = [{label: 'Choose',value: 'Default'}];

export const Days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const Months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const MonthsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const FrequencyDataDefault = {
  frequency: 'None',
  date_cutoff: '0',
  time_cutoff: '00:00',
  date_disburse: '0',
  time_disburse: '00:00',
}

export const ColorsList = [
  {
    name: 'Skobeloff Green',
    hex: '#007476',
  },
  {
    name: 'Teal',
    hex: '#008489',
  },
  {
    name: 'Cadet Blue',
    hex: '#48A3A7',
  },
  {
    name: 'Shelduck Blue',
    hex: '#93C2C4',
  },
  {
    name: 'Cool Mint',
    hex: '#CCE2E4',
  },
  {
    name: 'Medium Electric Blue',
    hex: '#00527C',
  },
  {
    name: 'Lapis Lazuli',
    hex: '#00609C',
  },
  {
    name: 'Bice Blue',
    hex: '#006DB2',
  },
  {
    name: 'Celestial Blue',
    hex: '#4E97D1',
  },
  {
    name: 'Aero',
    hex: '#7BB4E3',
  },
  {
    name: 'Mango Tango',
    hex: '#EE7811',
  },
  {
    name: 'Cadmium Orange',
    hex: '#F0862D',
  },
  {
    name: 'Cadmium Orange',
    hex: '#F29449',
  },
  {
    name: 'Sandy Brown',
    hex: '#F39C5B',
  },
  {
    name: 'Middle Yellow Red',
    hex: '#F5AA75',
  },
];

export const ColorsPairs = [
  {
    font: '#5D1D88',
    button: '#FFB700',
  },
    {
    font: '#F04DA1',
    button: '#005EAB',
  },
    {
    font: '#FF7F47',
    button: '#FF0072',
  },
    {
    font: '#0171CE',
    button: '#FCB61A',
  },
    {
    font: '#87575C',
    button: '#D1DFBB',
  },
    {
    font: '#000000',
    button: '#FCB61A',
  },
    {
    font: '#E02122',
    button: '#FFD8E1',
  },
    {
    font: '#171718',
    button: '#C0FF2D',
  },
    {
    font: '#8A5F36',
    button: '#8A5F36',
  },
  {
    font: '#7978c9',
    button: '#a2a1e8',
  },
]

export const EmptyGraphs = {

  dailyConversion: [],
  dailyRevenue: [],
  dailyCommission: [],
  dailyClicks: [],
}

export const adminWhiteList = [
  'dennis@xtendly.com',
]