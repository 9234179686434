import React, {FC} from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { Div, Text, FlexRow } from '@core';
import { formatDate2, formatTime } from '@helpers';

interface DataProps {
	accEmail: string;
	accName: string;
	lastLogin: any;
	date: string;
	key: any;
	readAccounts: boolean;
	writeAccounts: boolean;
	loading: boolean;
	userType: any;
	id: number;
}

export const UserData: FC<DataProps> = ({
	accEmail,
	accName,
	lastLogin,
	date,
	key,
	readAccounts,
	writeAccounts,
	loading,
	userType,
	id,
}) => {

	const checkUser = (type: any) => {
		switch (type) {
			case 'affiliate':
				return 'AFFILIATE';
			case 'merchant':
				return 'MERCHANT';
			case 'Manager':
				return 'MANAGER';
			case 'Editor':
				return 'EDITOR';
			case 'Super-Admin':
				return 'ADMIN';
			default:
				return null;
		}
	}

return(
<>
{
	readAccounts || writeAccounts ?

<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2'>
	<Div className='phone:flex phone:flex-row tabletWide:hidden' key={key}>
	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Name:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Email:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {accName}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {accEmail}
	      </Text>
	  </Div>
	</Div>

	<details className='tabletWide:hidden'>
	  <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100'>
	    Details 
	    <i className='fas fa-chevron-down ml-2' />
	  </summary>

	  <Div className='phone:flex phone:flex-row tabletWide:hidden'>
	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          ID:
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          User Type:
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          Registered:
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          Last Login:
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left mt-4"
	        >
	          Action:
	        </Text>
	    </Div>

	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {id}
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {
	          	checkUser(userType)
	          	? checkUser(userType)
	          	: <div className='text-primary-100 cursor-pointer parentToolTip flex flex-row items-center'>
			  		NO DATA
			  		<i className="fas fa-question text-xs ml-4 px-1 rounded-full bg-blue-100 text-white border-4 border-blue-100" />
			  		<div className='childTooltip bg-white border border-grey-400 rounded-lg px-2 py-4 text-sm -bottom-12 -left-12 z-20'>
			  			User is at onboarding status.
			  		</div>
		  	  	  </div>
	          }
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {formatDate2(date)}
	        </Text>
	        <Text
	          className="text-base text-secondary-100 font-light w-full text-left"
	        >
	          {
	          	lastLogin === '-- --'
	          	? '-- --'
	          	: `${formatDate2(lastLogin.substring(0, 10))} at ${formatTime(lastLogin.substring(10, 18))}`
	          }
	        </Text>
	        <Link
	          className="text-base text-blue-100 font-semibold w-full text-left mt-4"
	          to={`/admin/dashboard?tab=user&id=${id}&login=${lastLogin}&type=${checkUser(userType)}`}
	        >
	          VIEW
	        </Link>
	    </Div>
	  </Div>

	</details>

	<FlexRow className='w-full justify-around phone:hidden tabletWide:flex'>
		<Text
		  className="text-base text-secondary-200 font-light w-14"
		>
		  {id}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/5"
		>
		  {accName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/5 hidden laptop:block"
		>
		  {accEmail}
		</Text>
		<Popover className="relative phone:w-full tabletWide:w-1/12 laptop:hidden">
			<Popover.Button className='flex flex-row w-full justify-start'>
				<Text className='text-blue-100'>
					<i className='fas fa-external-link text-blue-100 text-xs mr-2' />
					Email
				</Text>
			</Popover.Button>
			<Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
				<Text className='text-sm text-secondary-200'>
					Email Address:
				</Text>
				<hr className='text-grey-400' />
				<Text className='text-sm text-secondary-200 hover:text-blue-100 hover:underline break-words mt-4'>          	
		          	{accEmail}
				</Text>
			</Popover.Panel>
		</Popover>
		<div
		  className="font-robot font-regular text-base text-secondary-200 font-light w-1/10"
		>
		  {
		  	checkUser(userType)
		  	? checkUser(userType)
		  	: <div className='text-primary-100 cursor-pointer parentToolTip flex flex-row items-center'>
		  		NO DATA
		  		<i className="fas fa-question text-xs ml-4 px-1 rounded-full bg-blue-100 text-white border-4 border-blue-100" />
		  		<div className='childTooltip bg-white border border-grey-400 rounded-lg px-2 py-4 text-sm -bottom-20'>
		  			User is at onboarding status.
		  		</div>
		  	  </div>
		  }
		</div>
		<Text
		  className="text-base text-secondary-200 font-light w-1/10"
		>
		  {formatDate2(date)}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/5"
		>
		  {
		  	lastLogin === '-- --'
		  	? '-- --'
		  	: `${formatDate2(lastLogin.substring(0, 10))} at ${formatTime(lastLogin.substring(10, 19))}`
		  }
		</Text>
		<Link
		  className="text-base text-blue-100 text-center font-semibold hover:underline w-1/12"
		  to={`/admin/dashboard?tab=user&id=${id}&login=${lastLogin}&type=${checkUser(userType)}`}
		>
		  View
		</Link>
	</FlexRow>
	
</div>
:
<>
<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2 cursor-not-allowed'>
	{
		loading?
		<Text className='my-4 text-center text-secondary-200'>
		  Loading data ...
		  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
		</Text>
		:
		<Text className='my-4 text-center text-secondary-200'>
			Record cannot be viewed.
		</Text>
	}
</div>
</>
}
</>
)
}