import React, {FC} from 'react';
import { Popover } from '@headlessui/react'
import { BankIcons, Div, FlexRow, Text, Toast, ToastV4, Flex, Radio, ToastInput } from '@core';
import { getCookie } from '@hooks';
import { API } from '@config';

interface BankProps {
	id?: string;
	bank: string;
	accName: string;
	code: any;
	accNum: string;
	defaultBank: string;
}

export const BankData: FC<BankProps> = ({
	id,
	bank,
	code,
	accName,
	accNum,
	defaultBank,
}) => {
	
	const token = getCookie('token');
	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('');
	const [toastDesc, setToastDesc] = React.useState('');

	const [isToastOpen2, setToastOpen2] = React.useState(false);
	const [toastKind2, setToastKind2] = React.useState('success');
	const [toastTitle2, setToastTitle2] = React.useState('');
	const [toastDesc2, setToastDesc2] = React.useState('');

	const [isToastOpen4, setToastOpen4] = React.useState(false);
	const [toastKind4, setToastKind4] = React.useState('success');
	const [toastTitle4, setToastTitle4] = React.useState('');
	const [toastDesc4, setToastDesc4] = React.useState('');

	const [isToastOpenInput, setToastOpenInput] = React.useState(false);
	const [toastKindInput, setToastKindInput] = React.useState('success');
	const [toastTitleInput, setToastTitleInput] = React.useState('');
	const [toastDescInput, setToastDescInput] = React.useState('');

	const [edit, setEdit] = React.useState(false);
	const [editAccName, setEditAccName] = React.useState(accName);
	const [editAccNum, setEditAccNum] = React.useState(accNum);
	const [editDefault, setEditDefault] = React.useState(defaultBank);
	const [hashedNum, setHashedNum] = React.useState('');
	const [newAccNum, setnewAccNum] = React.useState('');
	const hidden = ('******');

	const [verificationCode, setVerificationCode] = React.useState('');
	const [oneTimeCode, setOneTimeCode] = React.useState('');
	const OTP: any[] = [];

	React.useEffect(() => {
		if(accNum.length > 0){
		setnewAccNum(accNum.slice(-4));
		setHashedNum(hidden.concat(newAccNum))
		}
	})
	const Cancel = async () => {
		setEdit(false)
	}

	const Done = async () => {
	  setToastTitle4('Action');
	  setToastDesc4(`Are you sure you want to update the information for the method ${bank}?`);
	  setToastKind4('info');
	  setToastOpen4(true);
	}

	const onDone = async (e:any) => {
	  e.preventDefault()
	  if(verificationCode === oneTimeCode){
	  	  setEdit(false)
	  	  setToastOpen4(false);
	  	  fetch(`${API}/update-payment-method/${id}`, {
	  	  	method: 'POST',
	  	  	headers:	{
	  	  		'Content-Type': 'application/json',
	            	Authorization: `Bearer ${token}`
	  	  	},
	  	  	body: JSON.stringify({
	              bank_code: code,
	              account_name: editAccName,
	              account_number: editAccNum,
	              priority: editDefault
	          })
	  	  })
	  	  .then(res => res.json())
	        .then(data => {
	        	if(data.status === 1){
	        		setToastOpenInput(false)
	        		setToastTitle('Success');
	        		setToastDesc('Bank details has been updated successfully.');
	        		setToastKind('success');
	        		setToastOpen(true);
	        		setTimeout(() => {
	        			window.location.reload();
	        		}, 1000)
	        	}else{
	        		setToastTitle('Error');
	        		setToastDesc( data?.message || 'Something went wrong. Try again.');
	        		setToastKind('error');
	        		setToastOpen(true);
	        	}
	        })
	  }else{
	  	setToastTitle('Error');
	  	setToastDesc('Verification code is incorrect. Please try again');
	  	setToastKind('error');
	  	setToastOpen(true);
	  }
	}

	const onVerify = async () => {
		setToastOpen4(false)
	  	OTP.shift()
	    await OTP.push(Math.random().toString().substring(3, 9))
	    setVerificationCode('')
	    fetch(`${API}/payment-method-verification`,{
	      method: 'POST',
	      headers: {
	        Authorization: `Bearer ${token}`,
	        'Content-Type': 'application/json'
	      },
	      body: JSON.stringify({
	            bank_code: code,
	            account_name: accName,
	            action: 'edit',
	            code: OTP[0]
	        })
	    })
	    .then(res => res.json())
	    .then(data => {
	      if(data.status === 1){
	        setToastTitle('Info');
	        setToastDesc('Please check the code that has been sent to your registered email.');
	        setToastKind('warning');
	        setToastOpen(true);
	        setToastTitleInput('ACTION');
	        setToastDescInput('Enter the code sent to your email for verification.');
	        setToastKindInput('info');
	        setToastOpenInput(true);
	        setOneTimeCode(OTP[0])
	      }else{
	        setToastTitle('ERROR');
	        setToastDesc('Something went wrong. Try again.');
	        setToastKind('error');
	        setToastOpen(true);
	      }
	    })
	}

	const onRemove = async () => {
	setToastOpen2(false);
	fetch(`${API}/delete-payment-method/${id}`, {
	  	method: 'DELETE',
	  	headers:	{
          	Authorization: `Bearer ${token}`
	  	}
	  })
	  .then(res => res.json())
      .then(data => {
      	if(data.status === 1){
      		setToastTitle('Success');
      		setToastDesc('Bank method has been removed.');
      		setToastKind('success');
      		setToastOpen(true);
      		setTimeout(() => {
      			window.location.reload();
      		}, 1000)
      	}else{
      		setToastTitle('Error');
      		setToastDesc( data?.message || 'Something went wrong. Try again.');
      		setToastKind('error');
      		setToastOpen(true);
      	}
      })
	}

	const Edit = async () => {
	  setEdit(true);
	}

	const Remove = async () => {
	  setToastTitle2('Action');
	  setToastDesc2(`Are you sure you want to remove the bank ${bank}?`);
	  setToastKind2('info');
	  setToastOpen2(true);
	}

	return(
	<>

	<Toast
	      description={toastDesc}
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	      title={toastTitle}
	      toastKind={toastKind}
	    />
	<ToastInput
	      description={toastDescInput}
	      isOpen={isToastOpenInput}
	      onChange={(e:any) => setVerificationCode(e.target.value)}
	      onClose={() => setToastOpenInput(false)}
	      onSubmit={(e:any) => onDone(e)}
	      placeholder='Enter code'
	      submitBtnText='Verify'
	      title={toastTitleInput}
	      toastKind={toastKindInput}
	      type='text'
	      value={verificationCode}
	    />
	<ToastV4
		  btnText='Cancel'		
	      description={toastDesc2}
	      isOpen={isToastOpen2}
	      link=''
	      onClick={onRemove}
	      onClick2={() => setToastOpen2(false)}
	      onClose={() => setToastOpen2(false)}
	      submitBtnText='Remove'
	      title={toastTitle2}
	      toastKind={toastKind2}
	    />

	<ToastV4
		  btnText='Cancel'		
	      description={toastDesc4}
	      isOpen={isToastOpen4}
	      link=''
	      onClick={onVerify}
	      onClick2={() => setToastOpen4(false)}
	      onClose={() => setToastOpen4(false)}
	      submitBtnText='Update'
	      title={toastTitle4}
	      toastKind={toastKind4}
	    />

	<FlexRow
	  className="w-full parentToolTip">

	  <div className="bg-white w-64 shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-60 phone:top-full phone:left-0 tabletWide:-top-1/3 tabletWide:left-1/3 laptop:left-1/4 z-50" id='childTooltip' >
	  	<p className='font-semibold text-sm'>
	  		{bank}
	  	</p>
	  	<hr className='mt-1 mb-2 text-grey-400'/>
	  	{
	  		defaultBank === 'default' ?
	  		<p className='text-xs'>
	  			Bank accounts or e-Wallets that your commissions will be sent to.
	  		</p>
	  		:
	  		<></>
	  	}
	  	{
	  		defaultBank === 'secondary' ?
	  		<p className='text-xs'>
	  			Bank accounts or e-Wallets that your commissions will be sent to
	  			 when default bank is not available.
	  		</p>
	  		:
	  		<></>
	  	}
	  </div>

	  <div className='mb-auto' style={{width: '70px', height: '40px'}}>
	  <img alt="Bank Icon" className='object-cover rounded' src={BankIcons[code] || BankIcons.masterCard} />
	  </div>
	    <Div className="hover:bg-grey-500 ml-5 w-full phone:block mobile:flex mobile:flex-row mobile:justify-between">
	      <Div>
	      <Text className='text-left text-primary-100 hover:underline font-semibold'>
	      	{bank}
	      </Text>
	      <Popover className="relative hidden">
	            <Popover.Button>
	            	<div className='text-left text-primary-100 hover:underline font-semibold '>
	            	{bank}
	            	</div>
	            </Popover.Button>

	            <Popover.Panel className="absolute z-10 medium:left-1/4">
	              <div className="bg-white w-64 shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-60">
	                <p className='font-semibold text-sm'>
	                	{bank}
	                </p>
	                <hr className='mt-1 mb-2 text-grey-400'/>
	                {
	                	defaultBank === 'default' ?
	                	<p className='text-xs'>
	                		Bank accounts or e-Wallets that your disbursements will be debited from.
	                	</p>
	                	:
	                	<></>
	                }
	                {
	                	defaultBank === 'secondary' ?
	                	<p className='text-xs'>
	                		Bank accounts or e-Wallets that your disbursements will be 
	                		debited from when default bank is not available.
	                	</p>
	                	:
	                	<></>
	                }
	              </div>
	            </Popover.Panel>
	          </Popover>
	      {
	      	edit?
	      	<>
	      		<Flex className='flex-col'>
	      		<input className='border-blue-100 border-2 px-2 my-1 w-52' onChange={(e: any) => setEditAccName(e.target.value)} value={editAccName}/>
	      		<input className='border-blue-100 border-2 px-2 my-1 w-52' maxLength={20} onChange={(e: any) => setEditAccNum(e.target.value)} value={editAccNum}/>
				<Text className={`${editAccNum.length > 19 ? 'text-red-400 text-sm py-2' : 'hidden'}`}>
					Account number: Max length is 20.
				</Text>
	     
	      			<Radio
	      			  isChecked={editDefault === 'default'}
	      			  label="Primary Bank"
	      			  name="primary"
	      			  onClick={() => setEditDefault('default')}
	      			  optionId="default-bank"
	      			/>
	      			<div className={`${editDefault === 'secondary' ? '' : 'hidden'}`}>
	      			<Radio
	      			  isChecked={editDefault === 'secondary'}
	      			  label="Secondary Bank"
	      			  name="secondary"
	      			  onClick={() => setEditDefault('secondary')}
	      			  optionId="default-bank"
	      			/>
	      			</div>
	      		
	      		<Div className="phone:flex phone:flex-col medium:flex-row">
	      		    <span className='text-sm text-secondary-200 font-light my-2 mx-1'>Click here to Update:</span>
	      		    <span className="text-sm text-blue-100 font-medium my-2 mx-1 cursor-pointer hover:underline" onClick={Done}>Update</span>
	      		    <span className="text-sm text-grey-200 font-medium my-2 mx-1 cursor-pointer hover:underline" onClick={Cancel}>Cancel</span>
	      		</Div>
	      		</Flex>
	      	</>
	      	:
	      	<>
	      		<Text className="text-base text-secondary-200 font-light">{accName}</Text>
	      		<Text className="text-base text-secondary-200 font-light">{hashedNum}</Text>
	      		{
	      			defaultBank === 'default'?
	      			<>
	      				<Text className="text-red-400 font-medium font-semibold ">Primary Bank</Text>
	      				<Text className="text-sm text-secondary-200 font-light">
	      				    Default Bank
	      				    <span className="text-blue-100 font-medium mx-3 cursor-pointer border-b-2 border-white hover:border-b-2 hover:border-blue-100"  onClick={Edit}>Edit</span>
	      				</Text>
	      			</>
	      			:
	      			<>
	      				<Text className="text-red-400 font-medium font-semibold">Secondary Bank</Text>
	      				<Text className="text-sm text-secondary-200 font-light">
	      				    Set as Default
	      				    <span className="text-blue-100 font-medium mx-3 cursor-pointer hover:underline"  onClick={Edit}>Edit</span>
	      				</Text>
	      			</>
	      		}
	      	</>
	      }
	      
	      

	      </Div>
	      <Div>
	      <div className="text-red-400 font-medium text-sm cursor-pointer hover:underline" onClick={Remove}>Remove</div>
	      </Div>
	    </Div>
	</FlexRow> 
	<hr className="text-grey-400 mb-10 mt-3" />
	</>
	)
}