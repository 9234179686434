import React, { FC } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { Div, Text, FlexRow } from '@core';
import { NavBarSuperAdmin, PaymentDetails } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';

export const TransactionAdmin: FC<any> = () => {

  const token = getCookie('token');
  const user = useSelector(selectUser);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const transRef: any = query.get('action');
  const plan: any = query.get('plan');
  const paid: any = query.get('paid');
  const dateSub: any = query.get('date');
  const merchant: any = query.get('merchant');

  const [date, setDate] = React.useState<any>('');
  const [amount, setAmount] = React.useState<any>(0);
  const [refNum, setRefNum] = React.useState<any>(0);
  const [count, setCount] = React.useState<any>(0);
  const [tax, setTax] = React.useState<any>(0);
  const [transFee, setTransFee] = React.useState<any>(0);
  const [commission, setCommission] = React.useState<any>(0);

  const [transaction, setTransaction] = React.useState<any[]>([]);
  const [fees, setFees] = React.useState(0);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true)
    fetch(`${API}/get-transaction-details/${transRef}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.json())
    .then( data => {
      if(data !== null || data !== 'undefined'){
        const newDate: any = new Date(data.data.created_at)
        setAmount(parseFloat(data.data.total_amount) + 
        parseFloat(data.data.total_transaction_fee) + 
        parseFloat(data.data.tax_fee))
        
        setTransFee(parseFloat(data.data.total_transaction_fee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        setTax(parseFloat(data.data.tax_fee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        setRefNum(data.data.reference_id)
        setCommission(parseFloat(data.data.total_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        setCount(data.items.length)
        setDate(newDate.toString().substring(0, 25))
        setTransaction(data.items)

        const individualTax = (data.data.tax_fee)/(data.items.length);
        const individualFee = (data.data.total_transaction_fee)/(data.items.length);
        setFees(individualTax+individualFee);

        setTimeout(() => {
          setLoading(false)
        }, 2000)
      } 
    })
    .catch( err => {
      if(err){
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }
    })
  }, [])

  return(
  <>

  {
    user?.user_info?.role === 'Super-Admin' ?
  <>
  <NavBarSuperAdmin/>
  <Div className='flex pt-28 px-2 phone:flex-col tabletWide:pt-36 tabletWide:flex-row w-full h-full'>

    <Div className='phone:w-full phone:px-2 surfaceDuo:px-4 tabletWide:w-64 flex justify-start tabletWide:justify-center mb-3'>
      <Link className='font-semibold text-primary-100' to='/admin/dashboard?action=transactions'>
          <i className="fas fa-angle-left"/>
          <i className="fas fa-angle-left mr-3"/>
          Back to Settings
      </Link>
    </Div>

  {
    plan !== 'Comission disbursement'?
    
    <Div
      className="overflow-y-scroll scrollbar-hide phone:px-2 surfaceDuo:px-4 tabletWide:px-8 flex flex-col w-full"
    >
      <Text className="font-poppins font-semibold text-xl mb-3 text-primary-100">
        Transaction Details
      </Text>
      <hr className="text-grey-400 mb-10" />

      <Text className='text-secondary-200'>
        Reference ID: <b className='text-primary-100 ml-2'>{transRef}</b>
      </Text>
      <Text className='text-secondary-200 my-4'>
        Subscription payment made on: <b className='text-primary-100'>{dateSub || '2022-01-01'}</b>
      </Text>

      <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
        <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
          Merchant:
        </Text>
        <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
          {merchant || 'Merchant Name'}
        </Text>
      </FlexRow>
      <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
        <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
          Subscription Plan:
        </Text>
        <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
          {plan || 'No plan'}
        </Text>
      </FlexRow>
      <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
        <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
          Total Amount Paid:
        </Text>
        <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
          &#8369; {(parseFloat(paid)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.00}
        </Text>
      </FlexRow>
    
    </Div>
    :
    <Div
      className="overflow-y-scroll scrollbar-hide phone:px-2 surfaceDuo:px-4 tabletWide:px-8 flex flex-col w-full"
    >
      <Text className="font-poppins font-semibold text-xl mb-3 text-primary-100">
        Transaction Details
      </Text>
      <hr className="text-grey-400 mb-10" />

      {
        transaction.length > 0?
        <>
        <Text className='text-secondary-200'>
          Reference ID: <b className='text-primary-100 ml-2'>{refNum}</b>
        </Text>
        <Text className='text-secondary-200 my-4'>
          A record of your disbursements made on: <b className='text-primary-100'>{date}</b>
        </Text>
        </>
        :
        <>
        <Text className='text-secondary-200'>
          Reference ID: <b className='text-primary-100 ml-2'>{transRef}</b>
        </Text>
        <Text className='text-secondary-200 my-4'>
          A record of your disbursements made on: <b className='text-primary-100'>{dateSub}</b>
        </Text>
        </>
      }

      <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
        <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
          Total Amount of Commission:
        </Text>
        <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
          &#8369; {commission}
        </Text>
      </FlexRow>
      <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
        <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
          Convenience Fees:
        </Text>
        <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
          &#8369; {(parseFloat(tax)+parseFloat(transFee)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
      </FlexRow>
      <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
        <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
          Total Transaction:
        </Text>
        <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
          &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
      </FlexRow>
      <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
        <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
          Total Affiliates Paid:
        </Text>
        <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
          {
            count > 1
            ? `${count} Affiliate(s)`
            : `${count} Affiliate`
          }
        </Text>
      </FlexRow>

      {
        transaction.length === 0 && !loading ?
        <Text className='text-red-100 my-4'>
          <b className='mr-2'>ERROR:</b>
          The transaction {transRef} does not exist.
        </Text>
        :
        <></>
      }

      <FlexRow className='w-full items-center justify-between mb-4 mt-8'>
        <Text className="font-poppins font-semibold text-xl text-primary-100">
          Payment History
        </Text>
        <Div className='hidden'>
        <i className="fas fa-download text-primary-100 mx-2"/>
        <Link className='hidden medium:inline text-primary-100 font-bold' download target='_blank' to='/report.pdf'>
          Download
        </Link>
        </Div>
      </FlexRow>
      <hr className="text-grey-400 mb-10" />

          <Div className='hidden tablet:flex tablet:flex-row'>
            <Text
              className=" text-base text-secondary-200 font-semibold  w-full tablet:w-1/6 text-left tablet:text-center"
            >
              Affiliate Name
            </Text>
            <Text
              className=" text-base text-secondary-200 font-semibold  w-full tablet:w-1/6 text-left tablet:text-center"
            >
              Reference ID
            </Text>
            <Text
              className=" text-base text-secondary-200 font-semibold  w-full tablet:w-1/6 text-left tablet:text-center"
            >
              Deposited to
            </Text>
            <Text
              className=" text-base text-secondary-200 font-semibold w-full tablet:w-1/6 text-left tablet:text-center"
            >
              Net Amount
            </Text>
            <Text
              className=" text-base text-secondary-200 font-semibold w-full tablet:w-1/6 text-left tablet:text-center"
            >
              Fees
            </Text>
            <Text
              className=" text-base text-secondary-200 font-semibold w-full tablet:w-1/6 text-left tablet:text-center"
            >
              Status
            </Text>

          </Div>
      <hr className="hidden tablet:block text-grey-400 my-4" />

      {
        transaction.length > 0 && !loading ?
        <Div className='w-full'>
        {
          transaction.map((list: any) =>(
            <PaymentDetails
              affiliateName={list?.affiliate_name}
              bank={list?.deposit_to}
              fees={fees}
              individualAmount={list?.amount}
              key={list?.id}
              referenceNum={refNum}
              status={list?.status}
            />
            ))
        }
        </Div>
        :
        <>
        {
          loading?
          <FlexRow className="w-full justify-center py-4">
            <Text>
              Loading data ...
              <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
            </Text>
          </FlexRow>
          :
          <FlexRow className="w-full justify-center py-8 my-4 bg-grey-500">
            <Text>
              No transaction data found.
            </Text>
          </FlexRow>
        }
        </>
      }
    
    </Div>
  }

  </Div>
  </>
  :
  <Navigate to='/' />

  }

  </>
)};