export const pricingStarter = [
  '0% transaction fees',
  'Up to 10 referral partners',
  'Real-time reporting',
  'Item/Category based comissions',
  'Automation (Zapier, Webhooks, Emails, SMS)',
];

export const pricingPro = [
  'Everything in the Free Plan',
  'Unlimited Admin Users',
  'Priority Email Response',
  'Custom Domain',
  'Unlimited Commission Configurations',
  'Xffiliate Wallet (Beta)',
  'Automated Disbursement',
  'Electronic Commission Disbursement: PHP 15 per recipient'
];

export const pricingEnterprise = [
  'Everything in Pro',
  'Unlimited referral partners',
  '0% transaction fees',
  'Premium Support',
  'Single Sign On (SSO)',
];

export const pricingPlus = [
  'Everything in the Free Plan',
  'Up to 10 Products',
  'Unlimited Affiliate Partners',
  '2 Admin Users',
  'Custom Domain',
  'Real Time Reporting',
  'Electronic Commission Disbursement: PHP 18 per recipient'
]