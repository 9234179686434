import React, { FC } from 'react';
import { batch } from 'react-redux';
import { Popover } from '@headlessui/react';
import { Text, FlexRow, Div, ToastInputMultiple } from '@core';
import { PaginationV2, TransactionData, OverviewAdmin } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';

export const TransactionDashboard: FC<any> = ({
	loading, readTransactions, writeTransactions  
}) => {

	const token = getCookie('token');

	const [isShow, setIsShow] = React.useState(true);
	const [isToastOpen5, setToastOpen5] = React.useState(false);
	const [toastKind5, setToastKind5] = React.useState('success');
	const [toastTitle5, setToastTitle5] = React.useState('');
	const [toastDesc5, setToastDesc5] = React.useState('');

	const [selected, setSelected] = React.useState('name');
	const [list, setList] = React.useState<any[]>([])
	const [mainList, setMainList] = React.useState<any[]>([])
	const completeList:any[] = [];
	const pendingList:any[] = [];
	const resultList: any[] = [];
	const emptyList: any[] = [];

	const [search, setSearch] = React.useState('')
	const [searchRef, setSearchRef] = React.useState('')
	const [searchDate, setSearchDate] = React.useState('')

	const [searchOpen, setSearchOpen] = React.useState(true);
	const [resultDesc, setResultDesc] = React.useState('');

	const [balance, setBalance] = React.useState(0);
	const [commission, setCommission] = React.useState(0);
	const [net, setNet] = React.useState(0);

	const [currentPage, setCurrentPage] = React.useState(1);
	const [articlesPerPage, setArticlesPerPage] = React.useState(5);
	const [pageItems, setPageItems] = React.useState(5);
	const indexOfLastArticle = currentPage * articlesPerPage;
	const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

	const [pageDesc, setPageDesc] = React.useState('');

	const currentAccounts = list.slice(indexOfFirstArticle, indexOfLastArticle);

	const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

	React.useEffect(() => {
		if(pageItems < 0 || pageItems > list.length){
			setArticlesPerPage(5)
			setPageDesc('Page number must not exceed total records count or less than zero.')
		}else if (pageItems > 0 && pageItems <= list.length){
			setArticlesPerPage(pageItems)
		}
	}, [pageItems])

	React.useEffect(() => {
		if(pageItems > 0 && pageItems <= list.length){
			setPageDesc('')
		}
	})

	React.useEffect(() => {
		setIsShow(false)
	  fetch(`${API}/get-merchant-transaction`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	  		setList(data?.transactions)
	  		setMainList(data?.transactions)
	  		setNet(data?.net_balance)
	  		setBalance(data?.xendit.balance)
	  		setCommission(data?.disbursement_commission)
	  		setIsShow(true)
	  })
	}, [token])

	React.useEffect(() => {
	  setList(mainList)
	}, [search])

	const onClick = () => {
	    setSearch('')
	    fetch(`${API}/get-merchant-transaction`, {
	      method: 'GET',
	      headers: {
	        Authorization: `Bearer ${token}`,
	      },
	    })
	    .then(res => res.json())
	    .then(data => {
	  		setList(data?.transactions)
	  		setMainList(data?.transactions)
	  		setNet(data?.net_balance)
	  		setBalance(data?.xendit.balance)
	  		setCommission(data?.disbursement_commission)
	    })
	    setToastOpen5(true)
	    setToastTitle5('ACTION')
	    setToastKind5('info')
	    setToastDesc5('')
	}

	const onSearch = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(search)){
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.merchant_name.toLowerCase().includes(search)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${search}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${search}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmit = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(search)){
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.merchant_name.toLowerCase().includes(e.target.value || search || e)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${search}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${search}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmitRef = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(searchRef)){
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.reference_id.includes(e.target.value || searchRef || e)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchRef}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchRef}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmitDate = async (e:any) => {
	  e.preventDefault()
	  setList(mainList)
	  setSearchOpen(false)
	  await list.map((item:any) => {
	    if(item.date.toString()
	    	.substring(0, 10)
	    	.includes(e.target.value || searchDate.toString() || e))
	    {
	      resultList.push(item)
	      setList(resultList)
	      setToastOpen5(false)
	      setToastKind5('info')
	      setCurrentPage(1)
	    }else{
	      setToastDesc5('No matches found your search.')
	      setToastTitle5('ERROR')
	      setTimeout(() => {
	        setToastKind5('error')
	      }, 500)
	    }
	    return 1;
	  })
	  if(resultList.length === 0){
	    setList(emptyList)
	    setResultDesc(`You've searched for "${searchDate}" keyword. (0) result(s) found.`)
	  } else {
	    setResultDesc(`You've searched for "${searchDate}" keyword. (${resultList.length}) result(s) found.`)
	  }
	}

	const onSearchRef = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(searchRef)){
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.reference_id.includes(searchRef)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchRef}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchRef}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSearchDate = async (e:any) => {
	  e.preventDefault()
	  setList(mainList)
	  setSearchOpen(false)
	  await list.map((item:any) => {
	    if(item.date.toString().substring(0, 10).includes(searchDate.toString())){
	      resultList.push(item)
	      setList(resultList)
	      setToastOpen5(false)
	      setToastKind5('info')
	      setCurrentPage(1)
	    }else{
	      setToastDesc5('No matches found your search.')
	      setToastTitle5('ERROR')
	      setTimeout(() => {
	        setToastKind5('error')
	      }, 500)
	    }
	    return 1;
	  })
	  if(resultList.length === 0){
	    setList(emptyList)
	    setResultDesc(`You've searched for "${searchDate}" keyword. (0) result(s) found.`)
	  } else {
	    setResultDesc(`You've searched for "${searchDate}" keyword. (${resultList.length}) result(s) found.`)
	  }
	}

	const onRefresh = () => {
	  setSearch('')
	  setSearchOpen(true)
	  fetch(`${API}/get-merchant-transaction`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data.transactions.length > 0){
	  		setList(data?.transactions)
	  		setMainList(data?.transactions)
	  		setNet(data?.net_balance)
	  		setBalance(data?.xendit.balance)
	  		setCommission(data?.disbursement_commission)
	  	}
	  })
	}

	const onSort = (e:any) => {
	  if(e === 'name'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.merchant_name < b.merchant_name) { return -1; }
	    if(a.merchant_name > b.merchant_name) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'reference'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.reference_id < b.reference_id) { return -1; }
	    if(a.reference_id > b.reference_id) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'status'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.status < b.status) { return -1; }
	    if(a.status > b.status) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateNew'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date);
	    const newDateB: any = new Date(b.date);
	    return newDateB - newDateA
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateOld'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date);
	    const newDateB: any = new Date(b.date);
	    return newDateA - newDateB
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if(e === 'complete'){
	  			batch(async () => {
	  				await completeList.splice(0, completeList.length)
	  				setIsShow(false)
	  				await mainList.map((listB:any) => {
	  				if(listB.status === 'COMPLETED'){
	  				  completeList.push(listB)
	  				}
	  				return 1;
	  				})
	  				setList(completeList)
	  				setTimeout(() => {
	  				  setIsShow(true)
	  				}, 1500)
	  			})
    } else if(e === 'pending'){
	  			batch(async () => {
	  				await pendingList.splice(0, pendingList.length)
	  				setIsShow(false)
	  				await mainList.map((listB:any) => {
	  				if(listB.status === 'PENDING'){
	  				  pendingList.push(listB)
	  				}
	  				return 1;
	  				})
	  				setList(pendingList)
	  				setTimeout(() => {
	  				  setIsShow(true)
	  				}, 1500)
	  			})
    } else if (e === 'amountHigh'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => b.total_amount - a.total_amount))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'amountLow'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => a.total_amount - b.total_amount))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'bankName'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.payment_channel < b.payment_channel) { return -1; }
	    if(a.payment_channel > b.payment_channel) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'bankType'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.payment_method < b.payment_method) { return -1; }
	    if(a.payment_method > b.payment_method) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  }
	}

	const onReload = () => {
	  setIsShow(false)
	  fetch(`${API}/get-merchant-transaction`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data.transactions.length > 0){
	  		setList(data?.transactions)
	  		setMainList(data?.transactions)
	  		setNet(data?.net_balance)
	  		setBalance(data?.xendit.balance)
	  		setCommission(data?.disbursement_commission)
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}
	
return( 
	<>
	<ToastInputMultiple
	      description={toastDesc5}
	      isOpen={isToastOpen5}
	      onChange={(e:any) => setSearch(e.target.value.toLowerCase())}
	      onChangeDate={(e:any) => setSearchDate(e.target.value)}
	      onChangeRef={(e:any) => setSearchRef(e.target.value)}
	      onClickSubmit={onSearch}
	      onClickSubmitDate={onSearchDate}
	      onClickSubmitRef={onSearchRef}
	      onClose={() => setToastOpen5(false)}
	      onReset={onClick}
	      onSubmit={onSubmit}
	      onSubmitDate={onSubmitDate}
	      onSubmitRef={onSubmitRef}
	      title={toastTitle5}
	      toastKind={toastKind5}
	      value={search}
	      valueDate={searchDate}
	      valueRef={searchRef}
	    />
	<Text className="font-poppins font-semibold text-2xl text-primary-100 my-4">
        Transactions
    </Text>
    <hr className="text-grey-400 mb-10" />

    <FlexRow
      className="laptop:flex-row phone:flex-col laptop:space-x-5 phone:space-y-5 
        laptop:space-y-0 phone:space-x-0 mb-8"
    >
      <OverviewAdmin icon="envelope-open-dollar" label="Total Balance" value={`P ${balance.toFixed(2).toString()}`} />
      <OverviewAdmin icon="money-check" label="Total Disbursement Commission" value={`P ${commission.toFixed(2).toString()}`} />
      <OverviewAdmin icon="coins" label="Total Net Balance" value={`P ${net.toFixed(2).toString()}`} />
    </FlexRow>

    {
      searchOpen?
      <>
      </>
      :
      <div>
          <p className='phone:text-center surfaceDuo:text-left mb-2'>{resultDesc}</p>
          <span className='block text-center surfaceDuo:text-left hover:underline text-blue-100 cursor-pointer mb-4' onClick={onRefresh}>Clear Searches</span>
      </div>
    }

    <div className='flex tabletWide:hidden flex-row items-center phone:w-full surfaceDuo:w-auto my-4'>
    <label className='flex phone:ml-4 surfaceDuo:ml-0 mr-4' htmlFor='sort'>Search by:</label>
    <select className='w-56 border border-grey-400 text-secondary-200' name="sort" onChange={(e:any) => setSelected(e.target.value) }>
        <option disabled selected value="">-- SELECT --</option>
        <option value="name">Merchant Name</option>
        <option value='reference'>Reference ID</option>
        <option value='date'>Disbursement Date</option>
    </select>
    </div>

    <FlexRow className="justify-between">
      <FlexRow className='gap-4 phone:items-start phone:mx-5 surfaceDuo:items-center surfaceDuo:mx-0 surfaceDuo:justify-between w-full flex phone:flex-col surfaceDuo:flex-row'>
      		{
      			selected === 'name' ?
      			<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
      			  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
      			      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
      			  >
      			      <form onSubmit={onSubmit}>
      			      <input className="placeholder-grey-700 laptop:text-base
      			          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
      			          onChange={(e:any) => {
      			            setSearch(e.target.value)
      			          }}
      			          placeholder="Search"
      			          value={search.toLowerCase()}
      			      />
      			      <button className='hidden' type='submit'>Search</button>
      			      </form>
      			  </div>
      			  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearch}/>
      			</div>
      			:
      			<></>
      		}

      		{
      			selected === 'reference' ?
      			<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
      			  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
      			      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
      			  >
      			      <form onSubmit={onSubmitRef}>
      			      <input className="placeholder-grey-700 laptop:text-base
      			          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
      			          onChange={(e:any) => {
      			            setSearchRef(e.target.value)
      			          }}
      			          placeholder="Search"
      			          value={searchRef}
      			      />
      			      <button className='hidden' type='submit'>Search</button>
      			      </form>
      			  </div>
      			  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchRef}/>
      			</div>
      			:
      			<></>
      		}

      		{
      			selected === 'date' ?
      			<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
      			  <div className="border border-primary-100 text-grey-700 font-poppins text-base flex flex-row items-center overflow-hidden phone:w-full surfaceDuo:w-52 py-1 tablet:w-80 tablet:py-0"
      			  >
      			      <form className='w-full' onSubmit={onSubmitDate}>
      			      <input className="placeholder-grey-700 laptop:text-base
      			          tablet:py-3.5 w-full focus:border-none focus:outline-none outline-none border-none block phone:text-sm phone:py-2"
      			          onChange={(e:any) => {
      			            setSearchDate(e.target.value)
      			          }}
      			          type='date'
      			          value={searchDate}
      			      />
      			      <button className='hidden' type='submit'>Search</button>
      			      </form>
      			  </div>
      			  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchDate}/>
      			</div>
      			:
      			<></>
      		}

            <button className='hidden tabletWide:flex w-52 flex-row items-center justify-start text-primary-100 hover:text-white py-2 px-8 rounded-full border-2 border-primary-100 bg-white hover:bg-primary-100' onClick={onClick}>
              <i className="fas fa-search text-base mr-2" />Search 
            </button>
          
          <div className='flex flex-row items-center phone:w-full surfaceDuo:w-auto'>
          <label className='hidden medium:flex mx-3' htmlFor='sort'>Sort by:</label>
          <select className='rounded-lg phone:w-full surfaceDuo:w-56 border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
              <option disabled selected value="">SORT</option>
              <option value="name">A - Z (by Name)</option>
              <option value='reference'>A - Z (by Reference ID)</option>
              <option value="amountHigh">Highest Amount</option>
              <option value="amountLow">Lowest Amount</option>
              <option value="dateNew">Newest to Oldest</option>
              <option value="dateOld">Oldest to Newest</option>
              <option value="status">Status</option>
              <option value="complete">Completed</option>
              <option value="pending">Pending</option>
          </select>
          </div>
          
          {/* <Div className="phone:w-32 tablet:w-40">
              <DropdownSelect
                  className="text-secondary-100 border border-primary-100"
                  color="white"
                  current=""
                  items={[]}
                  onSelect={() => {}}
                  placeholder='Sort By'
                  width="w-full"
              />
          </Div> */}
      </FlexRow>
      {/* <Button className='cursor-not-allowed bg-primary-400' disabled>
          Export to CSV
      </Button> */}
    </FlexRow>

    <Text className='tabletWide:hidden mt-8 pb-2 text-primary-100 font-semibold border-b border-grey-400 text-center'>
    	List of Transactions
    </Text>

    <FlexRow className='w-full justify-around mt-4 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('dateNew')}>
    		Disburse Date
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('reference')}>
    		Reference No.
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('name')}>
    		Merchant Name
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('amountHigh')}>
    		Amount
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('bankName')}>
    		Bank Name
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('bankType')}>
    		Bank Type
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center phone:hidden laptop:block' onClick={() => onSort('status')}>
    		Status
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center phone:hidden laptop:block' onClick={() => onSort('status')}>
    		Actions
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    </FlexRow>

    <Div className='w-full pt-4'>
    	<Div className={`w-full ${articlesPerPage > 10 ? 'h-96 overflow-y-scroll' : ''}`}>
	    {
	    	currentAccounts.length > 0 && isShow && list.length > 0 ?
	    	<>
	    	{
	    	  currentAccounts.map((listA: any, index: number) =>(
	    	    <TransactionData
	    	      accName={listA.merchant_name}
	    	      amount={parseFloat(listA.total_amount)}
	    	      bankName={listA.payment_channel}
	    	      bankType={listA.payment_method}
	    	      date={listA.date.toString().substring(0, 10)}
	    	      id={listA.id}
	    	      key={index}
	    	      loading={loading}
	    	      readTransactions={readTransactions}
	    	      refNum={listA.reference_id}
	    	      status={listA.status}
	    	      writeTransactions={writeTransactions}
	    	    />
	    	    ))
	    	}
	    	</>
	    	:
	    	<></>
	    }
	    {
	    	currentAccounts.length === 0 && isShow && list.length > 0 ?
	    	<Div className='bg-grey-400 py-4 my-2'>
	    	<Text className='text-center w-full'>
	    		Page is empty.
	    	</Text>
	    	<Text className='text-xs text-center w-full mt-4'>
	    		Go to Page 1
	    	</Text>
	    	</Div>
	    	:
	    	<></>
	    }
	    {
	    	currentAccounts.length === 0 && isShow && list.length === 0?
	    	<Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	    	  No data to be displayed.
	    	  <span className='block text-blue-100 hover:underline font-semibold cursor-pointer' onClick={onReload}>
	    	    Click to reload.
	    	    <i className="fas fa-repeat ml-2" />
	    	  </span>
	    	</Text>
	    	:
	    	<></>
	    }
	    {
	      !isShow?
	      <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	      	Loading data ...
	      	<i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	      </Text>
	      :
	      <></>
	    }
	    </Div>

	  <FlexRow className='flex-col hidden tabletWide:flex laptop:hidden mt-8'>
	  	<Text className='mr-auto'>
	  		Legend:
	  	</Text>
	  	<Text className='text-secondary-200 mr-auto ml-10'>
	  		<i className='fas fa-edit text-green-100 cursor-pointer mr-2' />
	  		Status: COMPLETED
	  	</Text>
	  	<Text className='text-secondary-200 mr-auto ml-10'>
	  		<i className='fas fa-edit text-red-100 cursor-pointer mr-2' />
	  		Status: PENDING
	  	</Text>
	  </FlexRow>

    <Div className="w-full justify-center mt-14">

    	<div
    	  className='font-robot font-regular text-blue-100 my-4 hover:underline cursor-pointer'
    	  onClick={onReload}>
    	  Reset records.
    	</div>

      <Text className='text-secondary-200 my-4'>
      	Total records found: <b className='ml-4 mr-2'>{list.length}</b> entries
      </Text>

      <Text className='flex flex-row items-center text-secondary-200 my-4'>
      	<Popover className="relative my-4">
      	      <Popover.Button>
      	            <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
      	      </Popover.Button>
      	      <Popover.Panel className="absolute z-10 left-0">
      	        <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
      	          <p className='font-semibold text-sm'>
      	            Items per Page
      	          </p>
      	          <hr className='text-primary-100 my-1'/>
      	          <p className='text-sm mt-4'>
      	            Input a number or press
      	            <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            to change number of entries per page.
      	          </p>
      	          <p className='mt-4 text-sm text-secondary-200'>
      	            ** Click anywhere on screen to close. **
      	          </p>
      	        </div>
      	      </Popover.Panel>
      	</Popover>
      	Items per Page:
      	<input
      		className='mx-2 w-10 px-2'
      		max={50}
          min={0}
      		onChange={(e:any) => setPageItems(e.target.value)}
      		type='number'
      		value={pageItems} />
      	<button className='hidden flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white'>
      		Go
      	</button>
      </Text>

      <Text className='text-red-400'>
      	{pageDesc}
      </Text>

      <PaginationV2
        currentPage={currentPage}
        itemsPerPage={articlesPerPage}
        paginate={paginate}
        totalItems={list.length}
      />
    </Div>  
    </Div>
 
    </>
  )
}; 