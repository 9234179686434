import React, { FC } from 'react';
import { batch } from 'react-redux';
import { usersActions, useAppDispatch } from '@redux';
import { Div, Flex, FlexRow, Text, Input, Button, Toast } from '@core';
import { changeInfoSuperAdmin } from '@services';

export const AccountSettings: FC<any> = ({ user }) => {
  const dispatch = useAppDispatch();

  const [firstname, setFirstname] = React.useState(user?.user_info?.firstname);
  const [lastname, setLastname] = React.useState(user?.user_info?.lastname);
  const [contactNum, setContactNum] = React.useState(user?.user_info?.contact);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.'); 

  const onSubmit = () => {
    if (firstname === '' || lastname === '' || contactNum === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await changeInfoSuperAdmin({
            firstname,
            lastname,
            contact: contactNum,
            role: user?.user_info?.role,
            subscription: user?.user_info?.subscription,
          })
        if (data?.data?.status === 1) {
          setToastTitle('Account Update');
          setToastDesc(data?.data?.message || 'Account details updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          await dispatch(usersActions.profile());
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.data?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    }
  };

  return (
    <Flex
      className="desktop:pl-16 laptop:pt-9 phone:pt-0 pb-16 laptop:pr-20 phone:px-5 
  overflow-y-auto scrollbar-hide pb-72"
    >
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Text className="font-poppins font-semibold text-xl phone:mt-3 tablet:mt-8 mb-3">
        Profile
      </Text>
      <hr className="text-grey-400 mb-10" />
      <FlexRow
        className="mb-10 phone:flex-col phone:items-start laptop:flex-row 
      laptop:items-center"
      >
        <Text
          className="laptop:w-36 laptop:mr-36 laptop:mb-auto phone:mb-5 text-base 
      text-grey-100 font-medium"
        >
          About You
        </Text>
      
          <Div className="w-full">
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full"
              label="First Name"
              lableSize="text-sm"
              onChange={setFirstname}
              type="text"
              value={firstname}
            />
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full"
              label="Last Name"
              lableSize="text-sm"
              onChange={setLastname}
              type="text"
              value={lastname}
            />
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full "
              label="Contact Number"
              lableSize="text-sm"
              maxlength={11}
              onChange={setContactNum}
              pattern="[0-9]*"
              type="number"
              value={contactNum}
            />
            {firstname == user?.user_info?.firstname &&
            lastname == user?.user_info?.lastname &&
            contactNum == user?.user_info?.contact ? (
              <Button className="w-56 justify-center cursor-not-allowed bg-grey-100" disabled>
                Update Details
              </Button>
            ) : (
              <Button className={`w-56 justify-center `} isLoading={loading} onClick={onSubmit}>
                Update Details
              </Button>
            )}
          </Div>

      </FlexRow>
      
    </Flex>
  );
};