import React, { FC } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useSelector } from 'react-redux';
import { Text, FlexRow, Flex, FlexCenter, Div } from '@core';
import { selectOverallGraphData } from '@redux';
import { getCookie, useWindowSize } from '@hooks';
import { API } from '@config';
import { EmptyGraphs } from '@constants';

interface ChartProps {
  chart?: any;
  value?: any;
  initDate?: any;
  finalDate?: any;
  date?: any;
  onDate?: any;
}

export const Charts: FC<ChartProps> = ({
  chart, value, initDate, finalDate, date, onDate
}) => {

	const dimensions = useWindowSize();
	const token = getCookie('token');
	const graphs = useSelector(selectOverallGraphData);
	const [isShow, setIsShow] = React.useState(false);

	const [clicksgraph, setClicksGraphs] = React.useState<any>(EmptyGraphs);
	const [commissiongraph, setCommissionGraphs] = React.useState<any>(EmptyGraphs);
	const [conversiongraph, setConversionGraphs] = React.useState<any>(EmptyGraphs);
	const [revenuegraph, setRevenueGraphs] = React.useState<any>(EmptyGraphs);

	const theme = {
	  textColor: '#9C9C9C',
	  fontFamily: 'Poppins',
	  fontSize: 14,
	  legends: {
	    title: {
	      text: {
	        fontSize: 14,
	        fill: '#333333',
	      },
	    },
	    text: {
	      fontSize: 14,
	      fill: '#333333',
	    },
	    ticks: {
	      line: {},
	      text: {
	        fontSize: 14,
	        fill: '#333333',
	      },
	    },
	  },
	};

	const commonProperties = {
	  height: 350,
	  margin: { top: 50, right: 50, bottom: 75, left: 50 },
	};

	const clickdates: any[] = [];

	function convertGraphData(data: any[]) {
	  const convData: any = [];
	  data.map((entry) => {
	    clickdates.push(entry.date_created)
	    return convData.push({
	      x: entry.date_created,
	      y: entry[Object.keys(entry)[1]],
	    });
	  });
	  return convData;
	}

	function convertGraphDataA(data: any[]) {
	  const convData: any = [];
	  data.map((entry) => {
	    clickdates.push(entry.date_created)
	    return convData.push({
	      x: entry.date_created,
	      y: entry.daily_conversions,
	    });
	  });
	  return convData;
	}

	React.useEffect(() => {
		if(value === '0' && initDate === ''){
			setIsShow(false)
			setClicksGraphs(convertGraphData(graphs.overallGraphsData.data.graph2.clicks))
			setCommissionGraphs(convertGraphData(graphs.overallGraphsData.data.graph1.commission))
			setConversionGraphs(convertGraphData(graphs.overallGraphsData.data.graph2.conversion))
			setRevenueGraphs(convertGraphData(graphs.overallGraphsData.data.graph1.revenue))
			setTimeout(() => {
				setIsShow(true)
			}, 1000)
		}else if(parseFloat(value) > 0){
			setIsShow(false)
			fetch(`${API}/chart-analytics/${value}`,{
				method: 'GET',
				headers: {Authorization: `Bearer ${token}`}
			})
			.then(res => res.json())
			.then(data => {
				if(data.status === 1){
					setClicksGraphs(convertGraphData(data.data.dailyClicks))
					setCommissionGraphs(convertGraphData(data.data.dailyCommission))
					setConversionGraphs(convertGraphDataA(data.data.dailyConversion))
					setRevenueGraphs(convertGraphData(data.data.dailyRevenue))
					setTimeout(() => {
						setIsShow(true)
					}, 1500)
				}
			})
		}
	}, [value, chart, initDate])

	React.useEffect(() => {
		if(initDate !== '' && finalDate !== '' && value === '0'){
			setIsShow(false)
			fetch(`${API}/chart-analytics-dated/${initDate}/${finalDate}`,{
				method: 'GET',
				headers: {Authorization: `Bearer ${token}`}
			})
			.then(res => res.json())
			.then(data => {
				if(data.status === 1){
					setClicksGraphs(convertGraphData(data.data.dailyClicks))
					setCommissionGraphs(convertGraphData(data.data.dailyCommission))
					setConversionGraphs(convertGraphDataA(data.data.dailyConversion))
					setRevenueGraphs(convertGraphData(data.data.dailyRevenue))
					setTimeout(() => {
						setIsShow(true)
					}, 1500)
				}
			})
		}
	}, [onDate, initDate, finalDate])

return(
	<>
	<div className='hidden'>{date}</div>
	<div className={`${chart === 'Clicks' ? '' : 'hidden'} h-96`}>
	  <Text>Total Clicks</Text>
	  {
	  	clicksgraph.length > 0 && isShow?
	  	<ResponsiveLine
	  	           axisBottom={{
	  	            format: '%b %d',
	  	            tickPadding: 5,
	  	            tickRotation: 67,
	  	            tickValues: dimensions.width > 1000 ? 12 : 5,
	  	           }}
	  	           axisLeft={{
	  	             tickValues: 5,
	  	             tickPadding: 10,
	  	             format: (text) => Number(text),
	  	           }}
	  	           {...commonProperties}
	  	           colors='#FF6C3E'
	  	           curve="monotoneX"
	  	           data={[
	  	               {
	  	                   id: 'Clicks',
	  	                   data: clicksgraph,
	  	               },
	  	           ]}
	  	           enableArea
	  	           enableGridX={false}
	  	           enableGridY={false}
	  	           enableSlices="x"
	  	           legends={[
	  	             {
	  	               anchor: 'top-left',
	  	               direction: 'row',
	  	               justify: false,
	  	               translateY: -40,
	  	               itemDirection: 'left-to-right',
	  	               itemWidth: 140,
	  	               itemHeight: 20,
	  	               symbolSize: 12,
	  	               symbolShape: 'square',
	  	               effects: [
	  	                 {
	  	                   on: 'hover',
	  	                   style: {
	  	                     itemBackground: 'rgba(0, 0, 0, .03)',
	  	                     itemOpacity: 1,
	  	                   },
	  	                 },
	  	               ],
	  	               toggleSerie: false,
	  	             },
	  	           ]}
	  	           sliceTooltip={
	  	             // eslint-disable-next-line react/no-unstable-nested-components
	  	             ({ slice }) => (
	  	               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
	  	                 {slice.points.map((point, i) => (
	  	                   <Div key={point.id}>
	  	                     <FlexCenter className="text-xs font-medium">
	  	                       {i === 0 ? point.data.xFormatted : ''}
	  	                     </FlexCenter>
	  	                     <Div className="flex flex-row pt-1">
	  	                       <Flex className="text-grey-700 text-sm">
	  	                         Clicks
	  	                       </Flex>
	  	                       <Flex className="text-right text-sm font-medium">
	  	                         {point.data.y.toLocaleString()}
	  	                       </Flex>
	  	                     </Div>
	  	                   </Div>
	  	                 ))}
	  	               </Div>
	  	             )
	  	           }
	  	           theme={theme}
	  	           xFormat="time:%m/%d/%Y"
	  	           xScale={{
	  	               type: 'time',
	  	               format: '%m/%d/%Y',
	  	           }}
	  	           yScale={{
	  	             type: 'linear',
	  	           }}
	  	       />
	  	:
	  	<>
	  	{
	  		!isShow ?
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  			  Loading data ...
	  			  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  		:
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  				No data recorded at this time.
	  			</Text>
	  			<Text className='text-center text-xs text-secondary-200 mt-2'>
	  				This appears when there is no data or no timeline selected.
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  	}
	  	</>
	  } 
	</div>

	<div className={`${chart === 'Conversions' ? 'h-96' : 'hidden'}`}>
	  <Text>Total Conversions</Text>
	  {
	  	conversiongraph.length > 0 && isShow?
	  	<ResponsiveLine
	  	           axisBottom={{
	  	            format: '%b %d',
	  	            tickPadding: 5,
	  	            tickRotation: 67,
	  	            tickValues: dimensions.width > 1000 ? 12 : 5,
	  	           }}
	  	           axisLeft={{
	  	             tickValues: 5,
	  	             tickPadding: 10,
	  	             format: (text) => Number(text),
	  	           }}
	  	           {...commonProperties}
	  	           colors='magenta'
	  	           curve="monotoneX"
	  	           data={[
	  	               {
	  	                   id: 'Conversions',
	  	                   data: conversiongraph,
	  	               },
	  	           ]}
	  	           enableArea
	  	           enableGridX={false}
	  	           enableGridY={false}
	  	           enableSlices="x"
	  	           legends={[
	  	             {
	  	               anchor: 'top-left',
	  	               direction: 'row',
	  	               justify: false,
	  	               translateY: -40,
	  	               itemDirection: 'left-to-right',
	  	               itemWidth: 140,
	  	               itemHeight: 20,
	  	               symbolSize: 12,
	  	               symbolShape: 'square',
	  	               effects: [
	  	                 {
	  	                   on: 'hover',
	  	                   style: {
	  	                     itemBackground: 'rgba(0, 0, 0, .03)',
	  	                     itemOpacity: 1,
	  	                   },
	  	                 },
	  	               ],
	  	               toggleSerie: false,
	  	             },
	  	           ]}
	  	           sliceTooltip={
	  	             // eslint-disable-next-line react/no-unstable-nested-components
	  	             ({ slice }) => (
	  	               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
	  	                 {slice.points.map((point, i) => (
	  	                   <Div key={point.id}>
	  	                     <FlexCenter className="text-xs font-medium">
	  	                       {i === 0 ? point.data.xFormatted : ''}
	  	                     </FlexCenter>
	  	                     <Div className="flex flex-row pt-1">
	  	                       <Flex className="text-grey-700 text-sm">
	  	                         Conversions
	  	                       </Flex>
	  	                       <Flex className="text-right text-sm font-medium">
	  	                         {point.data.y.toLocaleString()}
	  	                       </Flex>
	  	                     </Div>
	  	                   </Div>
	  	                 ))}
	  	               </Div>
	  	             )
	  	           }
	  	           theme={theme}
	  	           xFormat="time:%m/%d/%Y"
	  	           xScale={{
	  	               type: 'time',
	  	               format: '%m/%d/%Y',
	  	           }}
	  	           yScale={{
	  	             type: 'linear',
	  	           }}
	  	       />
	  	:
	  	<>
	  	{
	  		!isShow ?
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  			  Loading data ...
	  			  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  		:
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  				No data recorded at this time.
	  			</Text>
	  			<Text className='text-center text-xs text-secondary-200 mt-2'>
	  				This appears when there is no data or no timeline selected.
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  	}
	  	</>
	  }
	</div>

	<div className={`${chart === 'Revenues' ? 'h-96' : 'hidden'}`}>
	  <Text>Total Revenue</Text>
	  {
	  	revenuegraph.length > 0 ?
	  	<ResponsiveLine
	  	           axisBottom={{
	  	            format: '%b %d',
	  	            tickPadding: 5,
	  	            tickRotation: 67,
	  	            tickValues: dimensions.width > 1000 ? 12 : 5,
	  	           }}
	  	           axisLeft={{
	  	             tickValues: 5,
	  	             tickPadding: 10,
	  	             format: (text) => Number(text),
	  	           }}
	  	           {...commonProperties}
	  	           colors='#42B963'
	  	           curve="monotoneX"
	  	           data={[
	  	               {
	  	                   id: 'Revenue',
	  	                   data: revenuegraph,
	  	               },
	  	           ]}
	  	           enableArea
	  	           enableGridX={false}
	  	           enableGridY={false}
	  	           enableSlices="x"
	  	           legends={[
	  	             {
	  	               anchor: 'top-left',
	  	               direction: 'row',
	  	               justify: false,
	  	               translateY: -40,
	  	               itemDirection: 'left-to-right',
	  	               itemWidth: 140,
	  	               itemHeight: 20,
	  	               symbolSize: 12,
	  	               symbolShape: 'square',
	  	               effects: [
	  	                 {
	  	                   on: 'hover',
	  	                   style: {
	  	                     itemBackground: 'rgba(0, 0, 0, .03)',
	  	                     itemOpacity: 1,
	  	                   },
	  	                 },
	  	               ],
	  	               toggleSerie: false,
	  	             },
	  	           ]}
	  	           sliceTooltip={
	  	             // eslint-disable-next-line react/no-unstable-nested-components
	  	             ({ slice }) => (
	  	               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
	  	                 {slice.points.map((point, i) => (
	  	                   <Div key={point.id}>
	  	                     <FlexCenter className="text-xs font-medium">
	  	                       {i === 0 ? point.data.xFormatted : ''}
	  	                     </FlexCenter>
	  	                     <Div className="flex flex-row pt-1">
	  	                       <Flex className="text-grey-700 text-sm">
	  	                         Revenue
	  	                       </Flex>
	  	                       <Flex className="text-right text-sm font-medium">
	  	                         {point.data.y.toLocaleString()}
	  	                       </Flex>
	  	                     </Div>
	  	                   </Div>
	  	                 ))}
	  	               </Div>
	  	             )
	  	           }
	  	           theme={theme}
	  	           xFormat="time:%m/%d/%Y"
	  	           xScale={{
	  	               type: 'time',
	  	               format: '%m/%d/%Y',
	  	           }}
	  	           yScale={{
	  	             type: 'linear',
	  	           }}
	  	       />
	  	:
	  	<>
	  	{
	  		!isShow ?
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  			  Loading data ...
	  			  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  		:
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  				No data recorded at this time.
	  			</Text>
	  			<Text className='text-center text-xs text-secondary-200 mt-2'>
	  				This appears when there is no data or no timeline selected.
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  	}
	  	</>
	  }
	</div>

	<div className={`${chart === 'Commissions' ? 'h-96' : 'hidden'}`}>
	  <Text>Total Commissions</Text>
	  {
	  	commissiongraph.length > 0 ?
	  	<ResponsiveLine
	  	           axisBottom={{
	  	            format: '%b %d',
	  	            tickPadding: 5,
	  	            tickRotation: 67,
	  	            tickValues: dimensions.width > 1000 ? 12 : 5,
	  	           }}
	  	           axisLeft={{
	  	             tickValues: 5,
	  	             tickPadding: 10,
	  	             format: (text) => Number(text),
	  	           }}
	  	           {...commonProperties}
	  	           colors='#0C61B0'
	  	           curve="monotoneX"
	  	           data={[
	  	               {
	  	                   id: 'Commissions',
	  	                   data: commissiongraph,
	  	               },
	  	           ]}
	  	           enableArea
	  	           enableGridX={false}
	  	           enableGridY={false}
	  	           enableSlices="x"
	  	           legends={[
	  	             {
	  	               anchor: 'top-left',
	  	               direction: 'row',
	  	               justify: false,
	  	               translateY: -40,
	  	               itemDirection: 'left-to-right',
	  	               itemWidth: 140,
	  	               itemHeight: 20,
	  	               symbolSize: 12,
	  	               symbolShape: 'square',
	  	               effects: [
	  	                 {
	  	                   on: 'hover',
	  	                   style: {
	  	                     itemBackground: 'rgba(0, 0, 0, .03)',
	  	                     itemOpacity: 1,
	  	                   },
	  	                 },
	  	               ],
	  	               toggleSerie: false,
	  	             },
	  	           ]}
	  	           sliceTooltip={
	  	             // eslint-disable-next-line react/no-unstable-nested-components
	  	             ({ slice }) => (
	  	               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
	  	                 {slice.points.map((point, i) => (
	  	                   <Div key={point.id}>
	  	                     <FlexCenter className="text-xs font-medium">
	  	                       {i === 0 ? point.data.xFormatted : ''}
	  	                     </FlexCenter>
	  	                     <Div className="flex flex-row pt-1">
	  	                       <Flex className="text-grey-700 text-sm">
	  	                         Commissions
	  	                       </Flex>
	  	                       <Flex className="text-right text-sm font-medium">
	  	                         {point.data.y.toLocaleString()}
	  	                       </Flex>
	  	                     </Div>
	  	                   </Div>
	  	                 ))}
	  	               </Div>
	  	             )
	  	           }
	  	           theme={theme}
	  	           xFormat="time:%m/%d/%Y"
	  	           xScale={{
	  	               type: 'time',
	  	               format: '%m/%d/%Y',
	  	           }}
	  	           yScale={{
	  	             type: 'linear',
	  	           }}
	  	       />
	  	:
	  	<>
	  	{
	  		!isShow ?
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  			  Loading data ...
	  			  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  		:
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  				No data recorded at this time.
	  			</Text>
	  			<Text className='text-center text-xs text-secondary-200 mt-2'>
	  				This appears when there is no data or no timeline selected.
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  	}
	  	</>
	  }
	</div>

	<div className={`${chart === 'Clicks-Conversions' ? '' : 'hidden'} h-96`}>
	  <Text>Compare Clicks and Conversions</Text>
	  {
	  	(clicksgraph.length > 0 || conversiongraph.length > 0) && isShow?
	  	<ResponsiveLine
	  	           axisBottom={{
	  	            format: '%b %d',
	  	            tickPadding: 5,
	  	            tickRotation: 67,
	  	            tickValues: dimensions.width > 1000 ? 12 : 5,
	  	           }}
	  	           axisLeft={{
	  	             tickValues: 5,
	  	             tickPadding: 10,
	  	             format: (text) => Number(text),
	  	           }}
	  	           {...commonProperties}
	  	           colors={['#FF6C3E', '#3173AF']}
	  	           curve="monotoneX"
	  	           data={[
	  	               {
	  	                   id: 'Clicks',
	  	                   data: clicksgraph,
	  	               },
	  	               {
	  	                   id: 'Conversions',
	  	                   data: conversiongraph,
	  	               },
	  	           ]}
	  	           enableArea
	  	           enableGridX={false}
	  	           enableGridY={false}
	  	           enableSlices="x"
	  	           legends={[
	  	             {
	  	               anchor: 'top-left',
	  	               direction: 'row',
	  	               justify: false,
	  	               translateY: -40,
	  	               itemDirection: 'left-to-right',
	  	               itemWidth: 140,
	  	               itemHeight: 20,
	  	               symbolSize: 12,
	  	               symbolShape: 'square',
	  	               effects: [
	  	                 {
	  	                   on: 'hover',
	  	                   style: {
	  	                     itemBackground: 'rgba(0, 0, 0, .03)',
	  	                     itemOpacity: 1,
	  	                   },
	  	                 },
	  	               ],
	  	               toggleSerie: false,
	  	             },
	  	           ]}
	  	           sliceTooltip={
	  	             // eslint-disable-next-line react/no-unstable-nested-components
	  	             ({ slice }) => (
	  	               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
	  	                 {slice.points.map((point, i) => (
	  	                   <Div key={point.id}>
	  	                     <FlexCenter className="text-xs font-medium">
	  	                       {i === 0 ? point.data.xFormatted : ''}
	  	                     </FlexCenter>
	  	                     <Div className="flex flex-row pt-1">
	  	                       <Flex className="text-grey-700 text-sm">
	  	                         {point.serieId === 'Clicks' ? 'Clicks' : 'Conversions'}
	  	                       </Flex>
	  	                       <Flex className="text-right text-sm font-medium">
	  	                         {point.data.y.toLocaleString()}
	  	                       </Flex>
	  	                     </Div>
	  	                   </Div>
	  	                 ))}
	  	               </Div>
	  	             )
	  	           }
	  	           theme={theme}
	  	           xFormat="time:%m/%d/%Y"
	  	           xScale={{
	  	               type: 'time',
	  	               format: '%m/%d/%Y',
	  	           }}
	  	           yScale={{
	  	             type: 'linear',
	  	           }}
	  	       />
	  	:
	  	<>
	  	{
	  		!isShow ?
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  			  Loading data ...
	  			  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  		:
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  				No data recorded at this time.
	  			</Text>
	  			<Text className='text-center text-xs text-secondary-200 mt-2'>
	  				This appears when there is no data or no timeline selected.
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  	}
	  	</>
	  } 
	</div>

	<div className={`${chart === 'Revenues-Commissions' ? '' : 'hidden'} h-96`}>
	  <Text>Compare Revenue and Commissions</Text>
	  {
	  	(revenuegraph.length > 0 || commissiongraph.length > 0) && isShow?
	  	<ResponsiveLine
	  	           axisBottom={{
	  	            format: '%b %d',
	  	            tickPadding: 5,
	  	            tickRotation: 67,
	  	            tickValues: dimensions.width > 1000 ? 12 : 5,
	  	           }}
	  	           axisLeft={{
	  	             tickValues: 5,
	  	             tickPadding: 10,
	  	             format: (text) => Number(text),
	  	           }}
	  	           {...commonProperties}
	  	           colors={['#42B963', '#0C61B0']}
	  	           curve="monotoneX"
	  	           data={[
	  	               {
	  	                   id: 'Revenue',
	  	                   data: revenuegraph,
	  	               },
	  	               {
	  	                   id: 'Commissions',
	  	                   data: commissiongraph,
	  	               },
	  	           ]}
	  	           enableArea
	  	           enableGridX={false}
	  	           enableGridY={false}
	  	           enableSlices="x"
	  	           legends={[
	  	             {
	  	               anchor: 'top-left',
	  	               direction: 'row',
	  	               justify: false,
	  	               translateY: -40,
	  	               itemDirection: 'left-to-right',
	  	               itemWidth: 140,
	  	               itemHeight: 20,
	  	               symbolSize: 12,
	  	               symbolShape: 'square',
	  	               effects: [
	  	                 {
	  	                   on: 'hover',
	  	                   style: {
	  	                     itemBackground: 'rgba(0, 0, 0, .03)',
	  	                     itemOpacity: 1,
	  	                   },
	  	                 },
	  	               ],
	  	               toggleSerie: false,
	  	             },
	  	           ]}
	  	           sliceTooltip={
	  	             // eslint-disable-next-line react/no-unstable-nested-components
	  	             ({ slice }) => (
	  	               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
	  	                 {slice.points.map((point, i) => (
	  	                   <Div key={point.id}>
	  	                     <FlexCenter className="text-xs font-medium">
	  	                       {i === 0 ? point.data.xFormatted : ''}
	  	                     </FlexCenter>
	  	                     <Div className="flex flex-row pt-1">
	  	                       <Flex className="text-grey-700 text-sm">
	  	                         {point.serieId === 'Commissions' ? 'Commissions' : 'Revenue'}
	  	                       </Flex>
	  	                       <Flex className="text-right text-sm font-medium">
	  	                         {point.data.y.toLocaleString()}
	  	                       </Flex>
	  	                     </Div>
	  	                   </Div>
	  	                 ))}
	  	               </Div>
	  	             )
	  	           }
	  	           theme={theme}
	  	           xFormat="time:%m/%d/%Y"
	  	           xScale={{
	  	               type: 'time',
	  	               format: '%m/%d/%Y',
	  	           }}
	  	           yScale={{
	  	             type: 'linear',
	  	           }}
	  	       />
	  	:
	  	<>
	  	{
	  		!isShow ?
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  			  Loading data ...
	  			  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  		:
	  		<>
	  		<FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  			<Text className='text-center'>
	  				No data recorded at this time.
	  			</Text>
	  			<Text className='text-center text-xs text-secondary-200 mt-2'>
	  				This appears when there is no data or no timeline selected.
	  			</Text>
	  			<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  				<Text>10 -</Text>
	  				<Text>8 -</Text>
	  				<Text>6 -</Text>
	  				<Text>4 -</Text>
	  				<Text>2 -</Text>
	  				<Text>0 -</Text>
	  			</Flex>
	  		</FlexRow>
	  		</>
	  	}
	  	</>
	  } 
	</div>

	<div className={`${chart === '' ? '' : 'hidden'} h-96`}>
	  <Text>Charts Overview</Text>
	  <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
	  	<Text className='text-center'>
	  	  Loading data ...
	  	  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	  	</Text>
	  	<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
	  		<Text>10 -</Text>
	  		<Text>8 -</Text>
	  		<Text>6 -</Text>
	  		<Text>4 -</Text>
	  		<Text>2 -</Text>
	  		<Text>0 -</Text>
	  	</Flex>
	  </FlexRow>
	</div>
	</>
	)
}