import React, { FC } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import useFontFaceObserver from 'use-font-face-observer';
import {
  Login,
  Google,
  Signup,
  Complete,
  Setup,
  AboutYou,
  Dashboard,
  DashboardAffiliate,
  Affiliates,
  Links,
  Settings,
  Catalogs,
  CatalogDetails,
  Subscription,
  Payment,
  Congrats,
  Wait,
  Notfound,
  Clicked,
  Logout,
  EmailMerchant,
  EmailAffiliate,
  EmailError,
  RequestReset,
  ChangePassword,
  AffiliateApplications,
  AffiliateAboutYou,
  AffiliateEditAboutYou,
  AffiliatesSetup,
  AffiliateSignUp,
  AffiliateComplete,
  ReferralLanding,
  AffiliateOnLogin,
  MerchantCatalogs,
  Failed,
  MethodSetup,
  AddMethodComplete,
  AdminSignup,
  DashboardAdmin,
  NotificationsAdmin,
  SettingsAdmin,
  ViewImages,
  ConversionReports,
  ConversionReportsAdmin,
  Reports,
  ReportsAdmin,
  Files,
  TransactionDetails,
  AdminHomePage,
  AdminManagement,
  AdminSettings,
  NotificationsSA,
  TransactionAdmin,
  TransactionFailed,
  DisbursementSuccess,
  DisbursementFailed,
  DashboardEditor,
  LinkError,
  Maintenance,
  CommissionDetails,
} from '@pages';
import { Notifications } from './pages/Dashboard/Notifications';
import { selectUser, selectUserLoading, usersActions, useAppDispatch } from '@redux';
import { Flex, Toast } from '@core';
import { useCookie } from '@hooks';

const App: FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const User = useSelector(selectUser);
  const userLoading = useSelector(selectUserLoading);
  const isFontListLoaded = useFontFaceObserver([
    { family: `Poppins` },
    { family: `Roboto` },
    { family: `Lato` },
  ]);
  const [token] = useCookie('token', '0');

  // ----- Session Timer -----

  let timeout: NodeJS.Timeout | null = null;
  const [timer, setTimer] = React.useState(5);
  const [isToastOpen, setToastOpen] = React.useState(false);

  // ----- Developer Mode -----

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('session');

  function resizeWindow() {
    if (window.outerHeight - window.innerHeight > 100) {
      console.log(
        `Inspector mode by
        ${
          User?.user_info?.role === 'Super-Admin' || actionQuery === 'debugger'
            ? 'Admin enabled'
            : 'non-admin. Please request assistance to Customer Support at https://xffiliate.com/contact-us/'
        }`,
      );
    }
  }
  resizeWindow();
  window.addEventListener('resize', resizeWindow);

  // ----- Comment Code for Maintenance -----

  // const navigate = useNavigate();

  // React.useEffect(() => {
  //  navigate('/maintenance')
  // }, [])

  React.useEffect(() => {
    if (actionQuery === 'debugger') {
      setTimer(0.25);
      console.log('debugger mode');
    } else if (User?.user_info?.role === 'merchant' || User?.user_info?.role === 'affiliate') {
      setTimer(20);
    } else if (
      User?.user_info?.role === 'Super-Admin' ||
      User?.user_info?.role === 'Manager' ||
      User?.user_info?.role === 'Editor'
    ) {
      setTimer(5);
    } else {
      setTimer(5);
    }
  }, [User, token, actionQuery]);

  React.useEffect(() => {
    batch(async () => {
      if (token !== '0') {
        if (
          window.location.pathname === '/merchant/admin_notifications' ||
          window.location.pathname === '/merchant/admin_dashboard/manager' ||
          window.location.pathname === '/merchant/admin_dashboard/editor' ||
          window.location.pathname === '/merchant/admin_dashboard/editor/settings' ||
          window.location.pathname === '/merchant/admin_dashboard/manager/settings' ||
          window.location.pathname === '/merchant/admin/reports' ||
          window.location.pathname === '/merchant/admin/reports/conversion'
        ) {
          await dispatch(usersActions.profileAdmin());
        } else {
          await dispatch(usersActions.profile());
        }
      }
    });
  }, [token]);

  React.useEffect(() => {
    batch(async () => {
      if (
        window.location.pathname === '/merchant/admin_notifications' ||
        window.location.pathname === '/merchant/admin_dashboard/manager' ||
        window.location.pathname === '/merchant/admin_dashboard/editor' ||
        window.location.pathname === '/merchant/admin_dashboard/editor/settings' ||
        window.location.pathname === '/merchant/admin_dashboard/manager/settings' ||
        window.location.pathname === '/merchant/admin/reports' ||
        window.location.pathname === '/merchant/admin/reports/conversion'
      ) {
        await dispatch(usersActions.profileAdmin());
      } else {
        await dispatch(usersActions.profile());
      }
    });
  }, []);

  // ----- Code for Session Timer -----
  const onRestart = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (User?.user_info?.role !== '') {
        navigate('/user/logout?session=timeout');
        setToastOpen(false);
      }
    }, 1000 * 60 * timer);
  };
  const onMousemove = () => {
    onRestart();
  };
  window.addEventListener('mousemove', onMousemove);

  return (
    <Flex className="font-poppins overflow-hidden">
      <Toast
        description="You have been logged out due to inactivity"
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title="SESSION TIMEOUT"
        toastKind="warning"
      />

      {isFontListLoaded ? (
        <Routes>
          {/* Authentication Pages */}
          <Route element={<Login />} path="/" />
          <Route element={<Signup />} path="/new/signup" />
          <Route element={<Google />} path="/new/google" />
          <Route element={<AdminSignup />} path="/register-admin/:id" />
          {/* OnBoarding Pages */}
          <Route element={<Complete />} path="/onboard/complete" />
          <Route element={<Setup />} path="/onboard/setup" />
          <Route element={<AboutYou />} path="/onboard/aboutyou" />

          <Route element={<ReferralLanding />} path="/affiliate/invite/:id" />
          <Route element={<AffiliateSignUp />} path="/affiliate/onboard/signup" />
          <Route element={<AffiliateOnLogin />} path="/affiliate/onboard/login" />
          <Route element={<AffiliateAboutYou />} path="/affiliate/onboard/aboutyou" />
          <Route element={<AffiliateEditAboutYou />} path="/affiliate/onboard/edit/aboutyou" />
          <Route element={<AffiliatesSetup />} path="/affiliate/onboard/setup" />
          <Route element={<AffiliateComplete />} path="/affiliate/onboard/complete" />

          {/* Add Payment Method Pages */}
          <Route element={<MethodSetup />} path="/payment_method_setup" />
          <Route element={<AddMethodComplete />} path="/payment_method_setup/complete" />

          {/* Confirmation Email */}
          <Route element={<EmailMerchant />} path="/merchant/email" />
          <Route element={<EmailAffiliate />} path="/affiliate/email" />
          <Route element={<EmailError />} path="/error/email" />

          {/* Forgot Password */}
          <Route element={<RequestReset />} path="/reset/password" />
          <Route element={<ChangePassword />} path="/forgot/password" />

          {/* Approve and Denied Applications */}
          <Route element={<AffiliateApplications />} path="/merchant/application" />

          <Route element={<Subscription />} path="/merchant/subscription" />

          {/* Super Admin Routes */}
          {User?.user_info?.role === 'Super-Admin' ? (
            <>
              <Route element={<AdminHomePage />} path="/admin/dashboard" />
              <Route element={<AdminManagement />} path="/admin/manage_users" />
              <Route element={<AdminSettings />} path="/admin/settings" />
              <Route element={<NotificationsSA />} path="/admin/notifications" />
              <Route element={<TransactionAdmin />} path="/admin/transactions" />
              <Route element={<TransactionFailed />} path="/admin/transactions/failed" />
            </>
          ) : (
            <></>
          )}

          {/* Shared Routes */}
          <Route element={<Payment />} path="/merchant/payment" />
          <Route element={<DisbursementSuccess />} path="/payment/disbursement/success" />
          <Route element={<DisbursementFailed />} path="/payment/disbursement/failed" />
          <Route element={<ReportsAdmin />} path="/merchant/admin/reports" />
          <Route element={<ConversionReportsAdmin />} path="/merchant/admin/reports/conversion" />

          {/* Merchant Protected Routes */}
          {User?.user_info?.role === 'merchant' ? (
            <>
              {/* Merchant Pages */}
              <Route element={<Dashboard />} path="/merchant/dashboard" />
              <Route element={<Affiliates />} path="/merchant/affiliates" />
              <Route element={<Links />} path="/merchant/links" />
              <Route element={<Settings />} path="/merchant/settings" />

              {/* Payment Pages */}

              <Route element={<Congrats />} path="/payment/success" />
              <Route element={<Failed />} path="/payment/failed" />

              <Route element={<Notifications />} path="/merchant/notifications" />
              <Route element={<Reports />} path="/merchant/reports" />
              <Route element={<ConversionReports />} path="/merchant/reports/conversion" />
              <Route element={<TransactionDetails />} path="/merchant/transaction_details" />
            </>
          ) : (
            <></>
          )}

          {/* Affiliate Protected Routes */}
          {User?.user_info?.role === 'affiliate' ? (
            <>
              {/* Affiliate Pages */}
              <Route element={<Catalogs />} path="/affiliate/catalogs" />
              <Route element={<MerchantCatalogs />} path="/affiliate/merchant_catalogs" />
              <Route element={<CatalogDetails />} path="/affiliate/catalog/details" />
              <Route element={<DashboardAffiliate />} path="/affiliate/dashboard" />
              <Route element={<Settings />} path="/affiliate/settings" />
              <Route element={<Notifications />} path="/affiliate/notifications" />
              <Route element={<CommissionDetails />} path="/affiliate/commission_details" />
            </>
          ) : (
            <></>
          )}

          {/* Admin Protected Routes */}
          {User?.user_info?.role === 'Manager' ? (
            <>
              {/* Merchant Pages */}
              <Route element={<DashboardAdmin />} path="/merchant/admin_dashboard/manager" />
              <Route element={<NotificationsAdmin />} path="/merchant/admin_notifications" />
              <Route
                element={<TransactionDetails />}
                path="/merchant/manager/transaction_details"
              />
              <Route
                element={<SettingsAdmin />}
                path="/merchant/admin_dashboard/manager/settings"
              />

              {/* 
              <Route
                element={<TransactionDetails />}
                path="/merchant/manager/transaction_details"
              />
              */}
            </>
          ) : (
            <></>
          )}
          {User?.user_info?.role === 'Editor' ? (
            <>
              {/* Merchant Pages */}
              <Route element={<DashboardEditor />} path="/merchant/admin_dashboard/editor" />
              <Route element={<NotificationsAdmin />} path="/merchant/admin_notifications" />
              <Route element={<TransactionDetails />} path="/merchant/editor/transaction_details" />
              <Route element={<SettingsAdmin />} path="/merchant/admin_dashboard/editor/settings" />
            </>
          ) : (
            <></>
          )}

          {/* 404 */}
          {User?.user_info?.role === '' && !userLoading ? (
            <Route element={<Notfound />} path="*" />
          ) : (
            <></>
          )}

          {userLoading ? <Route element={<Wait />} path="*" /> : <></>}

          <Route element={<Notfound />} path="*" />
          <Route element={<Clicked />} path="/:id" />
          <Route element={<Logout />} path="/user/logout" />
          <Route element={<Notfound />} path="/undefined" />

          <Route element={<ViewImages />} path="/full_image" />
          <Route element={<Files />} path="/files" />
          <Route element={<LinkError />} path="/link_error" />
          <Route element={<Maintenance />} path="/maintenance" />
        </Routes>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default App;
