import React, { FC } from 'react';

interface InputProps {
  className?: string;
  props?: any;
  placeholder?: string;
  label: string;
  containerClass?: string;
  onChange?: any;
  value?: string;
  name?: string;
  options: string[];
}

export const SelectV2: FC<InputProps> = ({
  className,
  placeholder,
  label,
  props,
  containerClass,
  onChange,
  value,
  name,
  options,
}) => (
  <div
    {...props}
    className={`px-5 py-4 border border-secondary-100 rounded-lg mb-4 ${containerClass}`}
  >
    <p className="font-poppins font-bold text-sm text-secondary-100 laptop:text-sm phone:text-xs mb-1">
      {label}
    </p>
    <select
      className={`outline-0 outline-none w-full border-0 font-poppins font-light 
       text-secondary-100 p-0 focus:outline-none border-transparent 
       focus:border-transparent focus:ring-0 laptop:text-sm phone:text-xs ${className}`}
      id={name}
      name={name}
      {...props}
      onChange={(event) => onChange(event.target.value)}
      value={value}
    >
      <option disabled selected value="">
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);
