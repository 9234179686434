import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { Div, Text } from '@core';

interface DataProps {
	date: string;
	referenceNum: string;
	bank: string;
	amount: number;
	externalId: number;
}

export const HistoryData: FC<DataProps> = ({
	date,
	referenceNum,
	bank,
	amount,
	externalId
}) => (
	<>
		<Div className='phone:flex phone:flex-row tablet:block'>

			<Div className='phone:flex phone:flex-col phone:w-1/2 tablet:hidden'>
				<Text
					className=" text-base text-secondary-200 font-light w-full text-left"
				>
					Date:
				</Text>
				<Text
					className=" text-base text-secondary-200 font-light w-full text-left"
				>
					Reference No.
				</Text>
				<Text
					className=" text-base text-secondary-200 font-light w-full text-left"
				>
					Net Amount:
				</Text>
				<Text
					className=" text-base text-secondary-200 font-light w-full text-left"
				>
					Deposited to:
				</Text>
				<Text
					className=" text-base text-secondary-200 font-light w-full text-left"
				>
					Action:
				</Text>
			</Div>

			<Div className='phone:flex phone:flex-col phone:w-1/2 tablet:flex-row tablet:w-full' >
				<Text
					className=" text-base text-secondary-200 font-light w-full tablet:w-1/5 text-left"
				>
					{date}
				</Text>
				<Popover className="relative w-full tablet:w-1/5 flex flex-row">
					<Popover.Button className=''>
						<Text className='text-blue-100'>
							View Ref. ID
						</Text>
					</Popover.Button>
					<Popover.Panel className="absolute z-10 right-0 top-full mt-2 text-secondary-200 border-2 border-primary-100 bg-white px-2 rounded w-64 py-4">
						<Text className='text-sm text-secondary-200'>
							Reference ID:
						</Text>
						<hr className='text-grey-400' />
						<Text className='text-sm text-secondary-200 break-words mt-4'>
							{referenceNum}
						</Text>
					</Popover.Panel>
				</Popover>
				<Text
					className=" text-base text-secondary-200 font-light w-full tablet:w-1/5 text-left"
				>
					&#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</Text>
				<Text
					className=" text-base text-secondary-200 font-light w-full tablet:w-1/5 text-left"
				>
					{bank}
				</Text>
				<Link
					className=" text-base text-blue-100 font-semibold w-full hover:underline tablet:w-1/5 text-left tablet:text-center"
					to={`/affiliate/commission_details?reference=${referenceNum}&value=${amount}&external=${externalId}`}
				>
					View
				</Link>
			</Div>


		</Div>

		<hr className="text-grey-400 my-4" />
	</>
)