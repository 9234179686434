import click from "@assets/icons/clicks.png";
import mastercard from '@assets/icons/mastercard-logo.png';
import visaLogo from '@assets/icons/visa.png';
import visa from '@assets/icons/visa-logo.png';
import location from '@assets/icons/location.png';
import reports from '@assets/icons/reports.png';
import window from '@assets/icons/window.png';
import masterCard from '@assets/icons/masterCard.png';
import gcash from '@assets/icons/gcash-logo.png';
import gcashSm from '@assets/icons/gcash-sm.png';
import paymaya from '@assets/icons/paymaya-logo.png';
import xffiliate from '@assets/icons/xffiliate-logo.png';
import xffiliate2 from '@assets/icons/xffiliate-logo2.png';
import xffiliateQrcode from '@assets/icons/xffiliate-qrcode.png';
import banner from '@assets/icons/banner.png';
import upgrade from '@assets/icons/upgrade.png';
import maintenance from '@assets/icons/maintenance.png';
import approve from '@assets/icons/approve.png';
import denied from '@assets/icons/denied.png';
import pending from '@assets/icons/pending.png';
import create from '@assets/icons/create.png';
import request from '@assets/icons/request.png';

export const Icons: any = {
  click,
  location,
  reports,
  window,
  masterCard,
  mastercard,
  gcash,
  gcashSm,
  paymaya,
  visa,
  banner,
  visaLogo,
  xffiliate,
  xffiliate2,
  xffiliateQrcode,
  upgrade,
  maintenance,
  approve,
  denied,
  pending,
  create,
  request,
};
