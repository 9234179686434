import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../type';

const merchant = (state: RootState) => state.merchants;

export const selectmerchantLoading = createSelector(merchant, (state) => state.loading);

export const selectmerchant = createSelector(merchant, (state) => state.data ?? []);

export const selectMerchantAnalyticsProducts = createSelector(
  merchant,
  (state) => state.analyticsProducts,
);

export const selectMerchantAnalyticsPartners = createSelector(
  merchant,
  (state) => state.analyticsPartners,
);

export const selectAllmerchantAndMetadata = createSelector(
  selectmerchant,
  selectmerchantLoading,
  selectMerchantAnalyticsProducts,
  selectMerchantAnalyticsPartners,
  (data = [], loading = false) => ({ data, loading }),
);
