import React, {FC} from 'react';
import { Popover } from '@headlessui/react';
import { Div, Text } from '@core';
import { useWindowSize } from '@src/hooks';

interface AffiliateProps {
	companyDesc: string;
	companyIcon: string;
	companyName: string;
	categoryCommission: string;
	categoryName: any;
	merchantFName: string;
	merchantLName: string;
}

export const AffiliateCategoryList: FC<AffiliateProps> = ({
	companyDesc,
	companyIcon,
	companyName,
	categoryCommission,
	categoryName,
	merchantFName,
	merchantLName,
}) => {

	const dimensions: any = useWindowSize();
	const [imageLoaded, setImageLoaded] = React.useState(false);

return(
	<>
	<div className='mx-auto py-2 px-1 rounded w-full hover:bg-grey-500'>
	
	<Div className='phone:flex phone:flex-row medium:items-center tablet:items-start' key={companyIcon}>

		<Div className='phone:flex phone:flex-col phone:w-1/2 phone:justify-start medium:hidden tablet:flex tabletWide:hidden'>
		    <Text
		      className='text-base text-secondary-200 font-light w-full text-left mb-2'
		    >
		      Affiliate Category:
		    </Text>
		    <Text
		      className='text-base text-secondary-200 font-light w-full text-left mb-2'
		    >
		      Affiliate Commission:
		    </Text>
		    <Text
		      className='text-base text-secondary-200 font-light w-full text-left mb-2'
		    >
		      Merchant Data:
		    </Text>
		</Div>

		<Div className='phone:flex phone:flex-col phone:w-1/2 medium:flex-row medium:w-full medium:items-center tablet:flex-col tablet:items-start tablet:w-1/2 tabletWide:flex-row tabletWide:items-center tabletWide:w-full justify-between' >
				<Text
				  className=" text-base text-secondary-200 font-light w-full medium:w-1/3 tablet:w-full tabletWide:w-1/3 text-left medium:text-center tablet:text-left tabletWide:text-center mb-2"
				>
				  {categoryName !== null ? categoryName.toUpperCase() : 'NONE'}
				</Text>
		    	<Text
		    	  className=" text-base text-secondary-200 font-light w-full medium:w-1/3 tablet:w-full tabletWide:w-1/3 text-left medium:text-center tablet:text-left tabletWide:text-center mb-2"
		    	>
		    	  {parseFloat(categoryCommission).toFixed(2)}%
		    	</Text>
		    	<Div
		    	  className="font-robot font-regular text-base text-secondary-200 font-light w-full medium:w-1/3 tablet:w-full tabletWide:w-1/3 text-left medium:text-center tablet:text-left tabletWide:text-center mb-2"
		    	>
		    	   <Popover className="relative w-full flex flex-row medium:justify-center tablet:justify-start tabletWide:justify-center mx-auto">
		    	        <Popover.Button className=''>
		    	            <Text className='text-blue-100 hover:underline font-semibold flex flex-row truncate w-full'>
		    	              {companyName.length > 12 && dimensions.width < 1500 ? `${companyName.substring(0,10)}...` : companyName}
		    	              <i className="fas fa-question text-sm ml-4 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
		    	            </Text>
		    	        </Popover.Button>
		    	        <Popover.Panel className="absolute z-10 right-0 top-full mt-2 text-secondary-200 border-2 border-primary-100 bg-white px-2 rounded w-64 py-4">
		    	        	<Text className='text-sm text-primary-100 text-left'>
		    	        		Merchant Name:
		    	        	</Text>
		    	        	<Text className='text-sm text-primary-100 text-left'>
		    	        		{merchantFName} {merchantLName}
		    	        	</Text>
		    	        	<hr className='text-grey-400 mt-2' />
		    	        	<img
		    	        	  alt="Comapny Logo"
		    	        	  className="w-12 h-12 object-contain my-2 rounded-full"
		    	        	  onError={() => setImageLoaded(true)}
		    	        	  src={
		    	        	    imageLoaded
		    	        	      ? 'https://i.imgur.com/CGDGnf6.png'
		    	        	      : companyIcon
		    	        	  }
		    	        	/>
		    	        	<Text className='text-sm text-primary-100 break-words flex flex-row items-center'>
		    	        		Company: {companyName}
		    	        	</Text>
		    	        	<Text className='text-sm text-primary-100 my-2 text-left'>
		    	        		Description:
		    	        	</Text>
		    	        	<Text className='text-sm text-primary-100 my-2 text-left'>
		    	        		{companyDesc}
		    	        	</Text>
		    	        </Popover.Panel>
		    	  </Popover>
		    	</Div>  
		</Div>

	</Div>

	</div>
	<hr className="text-grey-400 mt-2 mb-4 w-full mx-auto" />
	</>
)
}