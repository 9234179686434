import React from 'react';
import Iframe from 'react-iframe';
import { Link, useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { useAppDispatch, usersActions } from '@redux';
import { Flex, FlexRow, Icons, Text } from '@core';
import { API, SHARE } from '@config';

export const Files = () => {

  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const file = query.get('path');
  const company = query.get('merchantStore');
  const merchantId = query.get('merchant');
  const [imageSource, setImageSource] = React.useState();
  const [colorsFont, setColorsFont] = React.useState('#073763');

  const [isUserEmpty, setUser] = React.useState(false);

  React.useEffect(() => {
      fetch(`${API}/merchant-available-product/${merchantId}`)
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setImageSource(data.company_info.company_icon)
          if(data.custom_info !== null){
              setColorsFont(data.custom_info.font_colors)
          }
        }
      })
  }, [])

  React.useEffect(() => {
    batch(async () => {
      const data:any = await dispatch(usersActions.profileAdmin())
      if(data.payload === undefined || data.payload === null){
        await dispatch(usersActions.profile())
        .then( data2 => {
          if(data2.payload === undefined || data2.payload === null){
            setUser(true)
          }
        })
      }
    })
  }, [])

  return (
  <Flex className="laptop:px-20 tablet:px-10 phone:px-0">
    <FlexRow className="py-7 phone:px-5">
      <Link className="mr-auto flex flex-row items-center" to="/">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none rounded-full" src={imageSource || Icons.xffiliate} />
        <div className="mr-auto text-3xl font-semibold select-none phone:hidden tablet:block" style={{color: colorsFont}}>
          {company || 'Xffiliate'}
        </div>
        <Text className="italic text-grey-100 pl-1 font-light self-end pb-2 phone:hidden tablet:hidden">
          Merchant
        </Text>
      </Link>
    </FlexRow>
    <hr className="text-secondary-300" />

    {
      isUserEmpty?
      <a className='phone:ml-5 font-semibold text-primary-100 mt-3 py-2 hover:underline' href={`${SHARE}affiliate/onboard/signup/merchant/${merchantId}/merchantStore/${company}`}>
          <i className="fas fa-angle-left"/>
          <i className="fas fa-angle-left mr-3"/>
          Back to Registration
      </a>
      :
      <></>
    }
    
    {
      file !== 'https://xffiliate-api.xtendly.com/text_storage/' && file !== 'undefined' && file !== null ?
      <Flex className="overflow-y-scroll scrollbar-hide my-4 py-2 border-b">
        <Iframe className="w-full h-full" url={file || ''} />
      </Flex>
      :
      <Flex className="overflow-y-scroll scrollbar-hide items-center justify-center my-4 py-2 border-b">
        <Text className="italic">
          Merchant has no files uploaded yet.
        </Text>
      </Flex>
    }
  </Flex>
  );
};