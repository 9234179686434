import axios from 'axios';
import { API } from '@config';

interface CLICKED {
  url: string;
  ip_address: string;
}

export const addClicked = async (body: CLICKED) =>
  axios.post(`${API}/add-clicked`, body).then((response) => response);
