import React, { FC } from 'react';

interface ButtonIconProps {
  className?: string;
  props?: any;
  icon: string;
  onClick?: Function;
}

export const ButtonIcon: FC<ButtonIconProps> = ({ className, icon, children, props, onClick }) => (
  <button
    className={`text-white bg-primary-100 hover:bg-opacity-80 focus:ring-2 focus:ring-secondary-300 
    font-poppins font-regular rounded-lg text-lg px-5 py-4 laptop:px-3 desktop:px-5 text-center
     laptop:text-lg phone:text-sm tablet:text-base
     inline-flex items-center mr-2 ${className}`}
    type="button"
    {...props}
    onClick={onClick}
  >
    <i className={`${icon} inline desktop:text-2xl phone:xl mr-3`} />
    {children}
  </button>
);
