import React, { FC } from 'react';
import { batch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Popover } from '@headlessui/react';
import { Text, FlexRow, Div, ToastInputMultiple } from '@core';
import { PaginationV2, FullLogsData, OverviewAdmin } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';

export const FullLogsDashboard: FC<any> = ({ loading, readTransactions, writeTransactions  }) => {

	const token = getCookie('token');

	const [isShow, setIsShow] = React.useState(true);
	const [isToastOpen5, setToastOpen5] = React.useState(false);
	const [toastKind5, setToastKind5] = React.useState('success');
	const [toastTitle5, setToastTitle5] = React.useState('');
	const [toastDesc5, setToastDesc5] = React.useState('');

	const [selected, setSelected] = React.useState('name');
	const [list, setList] = React.useState<any[]>([])
	const [mainList, setMainList] = React.useState<any[]>([])
	const disbursementList:any[] = [];
	const subscriptionList:any[] = [];
	const statusList:any[] = [];
	const resultList: any[] = [];
	const emptyList: any[] = [];

	const [search, setSearch] = React.useState('')
	const [searchRef, setSearchRef] = React.useState('')
	const [searchDate, setSearchDate] = React.useState('')

	const [searchOpen, setSearchOpen] = React.useState(true);
	const [resultDesc, setResultDesc] = React.useState('');

	const [balance, setBalance] = React.useState(0);
	const [commission, setCommission] = React.useState(0);
	const [net, setNet] = React.useState(0);

	const [currentPage, setCurrentPage] = React.useState(1);
	const [articlesPerPage, setArticlesPerPage] = React.useState(5);
	const [pageItems, setPageItems] = React.useState(5);
	const indexOfLastArticle = currentPage * articlesPerPage;
	const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

	const [pageDesc, setPageDesc] = React.useState('');

	const currentAccounts = list.slice(indexOfFirstArticle, indexOfLastArticle);

	const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

	const newDate = new Date();
	const fileName = newDate.getTime();

	const [csvData, setCsvData] = React.useState([]);

	const headers: any = [
  	{ label: "Transaction Date", key: "date" },
  	{ label: "Reference No.", key: "reference_id" },
  	{ label: "Store Name", key: "store_name" },
  	{ label: "Amount", key: "paid_amount" },
  	{ label: "Bank Name", key: "payment_channel" },
  	{ label: "Type", key: "action" },
  	{ label: "Status", key: "status" }
	];

	React.useEffect(() => {
		if(pageItems < 0 || pageItems > list.length){
			setArticlesPerPage(5)
			setPageDesc('Items per page must not exceed total records count or less than zero.')
		}else if (pageItems > 0 && pageItems <= list.length){
			setArticlesPerPage(pageItems)
		}
	}, [pageItems])

	React.useEffect(() => {
		if(pageItems > 0 && pageItems <= list.length){
			setPageDesc('')
		}
	})

	React.useEffect(() => {
		setToastDesc5('')
		setToastTitle5('ACTION')
		setTimeout(() => {
		  setToastKind5('info')
		}, 500)
	}, [search, searchRef, searchDate])

	React.useEffect(() => {
		setIsShow(false)
	  fetch(`${API}/get-merchant-payments`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	  	if(data.data.length > 0){
	  		setCsvData(data?.data)
	  		setList(data?.data)
	  		setMainList(data?.data)
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}, [token])

	React.useEffect(() => {
	  fetch(`${API}/get-merchant-transaction`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	  	if(data){
	  		setNet(data?.net_balance)
	  		setBalance(data?.xendit.balance)
	  		setCommission(data?.disbursement_commission)
	  	}
	  })
	}, [token])

	const onClick = () => {
	    setSearch('')
	    fetch(`${API}/get-merchant-payments`, {
	      method: 'GET',
	      headers: {
	        Authorization: `Bearer ${token}`,
	      },
	    })
	    .then(res => res.json())
	    .then(data => {
	      if(data.data.length > 0){
	      setCsvData(data?.data)
	  		setList(data?.data)
	  		setMainList(data?.data)
	  	}
	    })
	    setToastOpen5(true)
	    setToastTitle5('ACTION')
	    setToastKind5('info')
	    setToastDesc5('')
	}

	const onSearch = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(search)){
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.store_name.toLowerCase().includes(search.toLowerCase())){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${search}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${search}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmit = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(search)){
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.store_name.toLowerCase().includes(search.toLowerCase())){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${search}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${search}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmitRef = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(searchRef)){
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.reference_id.includes(searchRef)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchRef}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchRef}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmitDate = async (e:any) => {
	  e.preventDefault()
	  setList(mainList)
	  setSearchOpen(false)
	  await mainList.map((item:any) => {
	    if(item.date.toString()
	    	.substring(0, 10)
	    	.includes(e.target.value || searchDate.toString() || e))
	    {
	      resultList.push(item)
	      setList(resultList)
	      setToastOpen5(false)
	      setToastKind5('info')
	      setCurrentPage(1)
	    }else{
	      setToastDesc5('No matches found your search.')
	      setToastTitle5('ERROR')
	      setTimeout(() => {
	        setToastKind5('error')
	      }, 500)
	    }
	    return 1;
	  })
	  if(resultList.length === 0){
	    setList(emptyList)
	    setResultDesc(`You've searched for "${searchDate}" keyword. (0) result(s) found.`)
	  } else {
	    setResultDesc(`You've searched for "${searchDate}" keyword. (${resultList.length}) result(s) found.`)
	  }
	}

	const onSearchRef = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(searchRef)){
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.reference_id.includes(searchRef)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchRef}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchRef}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSearchDate = async (e:any) => {
	  e.preventDefault()
	  setList(mainList)
	  setSearchOpen(false)
	  await mainList.map((item:any) => {
	    if(item.date.toString().substring(0, 10).includes(searchDate.toString())){
	      resultList.push(item)
	      setList(resultList)
	      setToastOpen5(false)
	      setToastKind5('info')
	      setCurrentPage(1)
	    }else{
	      setToastDesc5('No matches found your search.')
	      setToastTitle5('ERROR')
	      setTimeout(() => {
	        setToastKind5('error')
	      }, 500)
	    }
	    return 1;
	  })
	  if(resultList.length === 0){
	    setList(emptyList)
	    setResultDesc(`You've searched for "${searchDate}" keyword. (0) result(s) found.`)
	  } else {
	    setResultDesc(`You've searched for "${searchDate}" keyword. (${resultList.length}) result(s) found.`)
	  }
	}

	const onRefresh = () => {
	  setSearch('')
	  setSearchOpen(true)
	  fetch(`${API}/get-merchant-payments`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data.data.length > 0){
	    	setCsvData(data?.data)
	  		setList(data?.data)
	  		setMainList(data?.data)
	  	}
	  })
	}

	const onSort = (e:any) => {
		setCurrentPage(1)
	  if(e === 'name'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.store_name.toLowerCase() < b.store_name.toLowerCase()) { return -1; }
	    if(a.store_name.toLowerCase() > b.store_name.toLowerCase()) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if(e === 'nameReverse'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.store_name.toLowerCase() > b.store_name.toLowerCase()) { return -1; }
	    if(a.store_name.toLowerCase() < b.store_name.toLowerCase()) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'reference'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.reference_id < b.reference_id) { return -1; }
	    if(a.reference_id > b.reference_id) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateNew'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date);
	    const newDateB: any = new Date(b.date);
	    return newDateB - newDateA
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateOld'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date);
	    const newDateB: any = new Date(b.date);
	    return newDateA - newDateB
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'amountHigh'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => b.paid_amount - a.paid_amount))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'amountLow'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => a.paid_amount - b.paid_amount))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'bankName'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.payment_channel < b.payment_channel) { return -1; }
	    if(a.payment_channel > b.payment_channel) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'type'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.action < b.action) { return -1; }
	    if(a.action > b.action) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if(e === 'disbursement'){
	  			batch(async () => {
	  				await disbursementList.splice(0, disbursementList.length)
	  				setIsShow(false)
	  				await mainList.map((listB:any) => {
	  				if(listB.action === 'Comission disbursement'){
	  				  disbursementList.push(listB)
	  				}
	  				return 1;
	  				})
	  				setList(disbursementList)
	  				setTimeout(() => {
	  				  setIsShow(true)
	  				}, 1500)
	  			})
    } else if(e === 'subscription'){
	  			batch(async () => {
	  				await subscriptionList.splice(0, subscriptionList.length)
	  				setIsShow(false)
	  				await mainList.map((listB:any) => {
	  				if(listB.action.toLowerCase().includes('plan')){
	  				  subscriptionList.push(listB)
	  				}
	  				return 1;
	  				})
	  				setList(subscriptionList)
	  				setTimeout(() => {
	  				  setIsShow(true)
	  				}, 1500)
	  			})
    } else if(e === 'pending'){
	  			batch(async () => {
	  				await statusList.splice(0, statusList.length)
	  				setIsShow(false)
	  				await mainList.map((listB:any) => {
	  				if(listB.status.toLowerCase() === 'pending'){
	  				  statusList.push(listB)
	  				}
	  				return 1;
	  				})
	  				setList(statusList)
	  				setTimeout(() => {
	  				  setIsShow(true)
	  				}, 1500)
	  			})
    } else if(e === 'paid'){
	  			batch(async () => {
	  				await statusList.splice(0, statusList.length)
	  				setIsShow(false)
	  				await mainList.map((listB:any) => {
	  				if(listB.status.toLowerCase() === 'paid'){
	  				  statusList.push(listB)
	  				}
	  				return 1;
	  				})
	  				setList(statusList)
	  				setTimeout(() => {
	  				  setIsShow(true)
	  				}, 1500)
	  			})
    } else if (e === ''){
	    setIsShow(false)
	    setList(mainList)
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  }
	}

	const onReload = () => {
	  setIsShow(false)
	  fetch(`${API}/get-merchant-payments`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data.data.length > 0){
	    	setCsvData(data?.data)
	  		setList(data?.data)
	  		setMainList(data?.data)
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}
	
return( 
	<>
	<ToastInputMultiple
	      description={toastDesc5}
	      isOpen={isToastOpen5}
	      onChange={(e:any) => setSearch(e.target.value)}
	      onChangeDate={(e:any) => setSearchDate(e.target.value)}
	      onChangeRef={(e:any) => setSearchRef(e.target.value)}
	      onClickSubmit={onSearch}
	      onClickSubmitDate={onSearchDate}
	      onClickSubmitRef={onSearchRef}
	      onClose={() => setToastOpen5(false)}
	      onReset={onClick}
	      onSubmit={onSubmit}
	      onSubmitDate={onSubmitDate}
	      onSubmitRef={onSubmitRef}
	      title={toastTitle5}
	      toastKind={toastKind5}
	      value={search}
	      valueDate={searchDate}
	      valueRef={searchRef}
	    />
	<Text className="font-poppins font-semibold text-2xl text-primary-100 my-4">
        Transactions
    </Text>
    <hr className="text-grey-400 mb-10" />

    <FlexRow
      className="tabletWide:flex-row phone:flex-col tabletWide:space-x-5 phone:space-y-5 
        tabletWide:space-y-0 phone:space-x-0 mb-8"
    >
      <OverviewAdmin icon="envelope-open-dollar" label="Running Balance" value={<>&#8369; {balance.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>} />
      <OverviewAdmin icon="money-check" label="Pending Commission" value={<>&#8369; {commission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>} />

      {/*
      	<OverviewAdmin icon="coins" label="Total Net Balance"
      	value={<>&#8369;
      	{net.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>} />
      */}

      <span className='hidden'>{net} Unused</span>

    </FlexRow>

    {
      searchOpen?
      <>
      </>
      :
      <div>
          <p className='phone:text-center surfaceDuo:text-left mb-2'>{resultDesc}</p>
          <span className='block text-center surfaceDuo:text-left hover:underline text-blue-100 cursor-pointer mb-4' onClick={onRefresh}>Clear Searches</span>
      </div>
    }

    <div className='flex tabletWide:hidden flex-row items-center phone:w-full surfaceDuo:w-auto my-4'>
    <label className='flex phone:ml-4 surfaceDuo:ml-0 mr-4' htmlFor='sort'>Search by:</label>
    <select className='w-56 border border-grey-400 text-secondary-200' name="sort" onChange={(e:any) => setSelected(e.target.value) }>
        <option value="name">Store Name</option>
        <option value='reference'>Reference ID</option>
        <option value='date'>Transaction Date</option>
    </select>
    </div>

    <FlexRow className="justify-between">
      <FlexRow className='gap-4 phone:items-start phone:mx-5 tabletWide:items-center tabletWide:mx-0 tabletWide:justify-between w-full flex phone:flex-col tabletWide:flex-row'>
      		{
      			selected === 'name' ?
      			<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
      			  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
      			      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
      			  >
      			      <form onSubmit={onSubmit}>
      			      <input className="placeholder-grey-700 laptop:text-base
      			          tablet:py-3.5 w-full outline-none border-none block phone:text-sm phone:py-2"
      			          onChange={(e:any) => {
      			            setSearch(e.target.value)
      			          }}
      			          placeholder="Search"
      			          value={search}
      			      />
      			      <button className='hidden' type='submit'>Search</button>
      			      </form>
      			  </div>
      			  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearch}/>
      			</div>
      			:
      			<></>
      		}

      		{
      			selected === 'reference' ?
      			<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
      			  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
      			      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
      			  >
      			      <form onSubmit={onSubmitRef}>
      			      <input className="placeholder-grey-700 laptop:text-base
      			          tablet:py-3.5 w-full outline-none border-none block phone:text-sm phone:py-2"
      			          onChange={(e:any) => {
      			            setSearchRef(e.target.value)
      			          }}
      			          placeholder="Search"
      			          value={searchRef}
      			      />
      			      <button className='hidden' type='submit'>Search</button>
      			      </form>
      			  </div>
      			  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchRef}/>
      			</div>
      			:
      			<></>
      		}

      		{
      			selected === 'date' ?
      			<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
      			  <div className="border border-primary-100 text-grey-700 font-poppins text-base flex flex-row items-center overflow-hidden phone:w-full surfaceDuo:w-52 py-1 tablet:w-80 tablet:py-0"
      			  >
      			      <form className='w-full' onSubmit={onSubmitDate}>
      			      <input className="placeholder-grey-700 laptop:text-base
      			          tablet:py-3.5 w-full focus:border-none focus:outline-none outline-none border-none block phone:text-sm phone:py-2"
      			          onChange={(e:any) => {
      			            setSearchDate(e.target.value)
      			          }}
      			          type='date'
      			          value={searchDate}
      			      />
      			      <button className='hidden' type='submit'>Search</button>
      			      </form>
      			  </div>
      			  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchDate}/>
      			</div>
      			:
      			<></>
      		}

            <button className='hidden tabletWide:flex w-52 flex-row items-center justify-start text-primary-100 hover:text-white py-2 px-8 rounded-full border-2 border-primary-100 bg-white hover:bg-primary-100' onClick={onClick}>
              <i className="fas fa-search text-base mr-2" />Search 
            </button>
          
          <FlexRow className='phone:flex-col phone:w-full phone:items-start surfaceDuo:w-auto tablet:flex-row tablet:justify-start tablet:w-auto'>
	          <FlexRow className='phone:flex-col phone:items-start surfaceDuo:flex-row surfaceDuo:items-center phone:w-full surfaceDuo:w-auto surfaceDuo:my-2 tablet:my-0'>
	          <label className='mr-3 my-2 surfaceDuo:my-0 surfaceDuo:w-28 tablet:w-14' htmlFor='sort'>Sort by:</label>
	          <select className='rounded-lg phone:w-full surfaceDuo:w-56 border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
	              <option value="">Default</option>
	              <option value="name">A - Z (Store Name)</option>
	              <option value="nameReverse">Z - A (Store Name)</option>
	              <option value="amountHigh">Highest Amount</option>
	              <option value="amountLow">Lowest Amount</option>
	              <option value="dateNew">Newest to Oldest</option>
	              <option value="dateOld">Oldest to Newest</option>
	              <option value="pending">Status (Pending)</option>
	              <option value="paid">Status (Paid)</option>
	          </select>
	          </FlexRow>

	          <FlexRow className='phone:flex-col phone:items-start surfaceDuo:flex-row surfaceDuo:items-center phone:w-full surfaceDuo:w-auto surfaceDuo:my-2 tablet:my-0'>
	          <label className='mr-3 my-2 surfaceDuo:my-0 surfaceDuo:w-28 tablet:w-32 tablet:ml-4' htmlFor='sort'>Filter by Type:</label>
	          <select className='rounded-lg phone:w-full surfaceDuo:w-56 border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
	              <option value="">All Transaction</option>
	              <option value="disbursement">Disbursement</option>
	              <option value="subscription">Subscription</option>
	          </select>
	          </FlexRow>
          </FlexRow>

      </FlexRow>
    </FlexRow>

    <Text className='tabletWide:hidden mt-8 pb-2 text-primary-100 font-semibold border-b border-grey-400 text-center'>
    	List of Transactions
    </Text>

    <FlexRow className='w-full justify-start mt-4 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
    	<div className='text-primary-100 font-medium py-2 w-1/7'>
    		<span className=' phone:hidden laptop:inline'>Transaction</span> Date
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/7'>
    		<span className='laptop:hidden'>Ref.</span><span className='phone:hidden laptop:inline'>Reference</span> ID
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/7'>
    		Store Name
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/7'>
    		Amount
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/7'>
    		Bank Name
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/7'>
    		Type
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/13 text-center'>
    		Status
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/12 text-center'>
    		Actions
    		<i className="fas fa-chevron-down text-primary-100 mx-2 phone:hidden laptop" />
    	</div>
    </FlexRow>

    <Div className='w-full pt-4'>
    	<Div className={`w-full ${articlesPerPage > 10 ? 'h-96 overflow-y-scroll' : ''}`}>
	    {
	    	currentAccounts.length > 0 && isShow && list.length > 0 ?
	    	<>
	    	{
	    	  currentAccounts.map((listA: any, index: number) =>(
	    	    <FullLogsData
	    	      accName={listA.store_name}
	    	      amount={parseFloat(listA.paid_amount)}
	    	      bankName={listA.payment_channel}
	    	      date={listA.date}
	    	      id={listA.transaction_id}
	    	      key={index}
	    	      loading={loading}
	    	      readTransactions={readTransactions}
	    	      refNum={listA.reference_id}
	    	      status={listA.status}
	    	      type={listA.action}
	    	      writeTransactions={writeTransactions}
	    	    />
	    	    ))
	    	}
	    	</>
	    	:
	    	<></>
	    }
	    {
	    	currentAccounts.length === 0 && isShow && list.length > 0 ?
	    	<Div className='bg-grey-400 py-4 my-2'>
	    	<Text className='text-center w-full'>
	    		Page is empty.
	    	</Text>
	    	<Text className='text-xs text-center w-full mt-4'>
	    		Go to Page 1
	    	</Text>
	    	</Div>
	    	:
	    	<></>
	    }
	    {
	    	currentAccounts.length === 0 && isShow && list.length === 0?
	    	<Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	    	  No data to be displayed.
	    	  <span className='block text-blue-100 hover:underline font-semibold cursor-pointer' onClick={onReload}>
	    	    Click to reload.
	    	    <i className="fas fa-repeat ml-2" />
	    	  </span>
	    	</Text>
	    	:
	    	<></>
	    }
	    {
	      !isShow?
	      <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	      	Loading data ...
	      	<i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	      </Text>
	      :
	      <></>
	    }
	    </Div>

	  <FlexRow className='flex-col hidden mt-8'>
	  	<Text className='mr-auto'>
	  		Legend:
	  	</Text>
	  	<Text className='text-secondary-200 mr-auto ml-10'>
	  		<i className='fas fa-edit text-green-100 cursor-pointer mr-2' />
	  		Status: COMPLETED
	  	</Text>
	  	<Text className='text-secondary-200 mr-auto ml-10'>
	  		<i className='fas fa-edit text-red-100 cursor-pointer mr-2' />
	  		Status: PENDING
	  	</Text>
	  </FlexRow>

    <Div className="w-full justify-center mt-14">

    	{
    		csvData.length > 0?
    		<FlexRow className='w-full justify-start'>
    		<CSVLink data={csvData} filename={`transaction-${fileName}.csv`} headers={headers}>
    			<div className='font-semibold text-primary-100 hover:underline'>
    				<i className="fas fa-download text-blue-100 mr-2"/>
    				Export to CSV
    			</div>
    		</CSVLink>
    		</FlexRow>
    		:
    		<FlexRow className='w-full justify-start'>
    		<div className='font-semibold text-primary-100 hover:underline cursor-not-allowed'>
    			<i className="fas fa-download text-blue-100 mr-2"/>
    			Export to CSV
    		</div>
    		</FlexRow>
    	}

    	<div
    	  className='font-robot font-regular text-blue-100 my-4 hover:underline cursor-pointer'
    	  onClick={onReload}>
    	  Refresh records
    	</div>

      <Text className='text-secondary-200 my-4'>
      	Total records found: <b className='ml-4 mr-2'>{list.length}</b> entries
      </Text>

      <Text className='flex flex-row items-center text-secondary-200 my-4'>
      	<Popover className="relative my-4">
      	      <Popover.Button>
      	            <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
      	      </Popover.Button>
      	      <Popover.Panel className="absolute z-10 left-0">
      	        <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
      	          <p className='font-semibold text-sm'>
      	            Items per Page
      	          </p>
      	          <hr className='text-primary-100 my-1'/>
      	          <p className='text-sm mt-4'>
      	            Input a number or press
      	            <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            to change number of entries per page.
      	          </p>
      	          <p className='mt-4 text-sm text-secondary-200'>
      	            ** Click anywhere on screen to close. **
      	          </p>
      	        </div>
      	      </Popover.Panel>
      	</Popover>
      	Items per Page:
      	<input
      		className='mx-2 w-10 px-2'
      		max={50}
          min={0}
      		onChange={(e:any) => setPageItems(e.target.value)}
      		type='number'
      		value={pageItems} />
      	<button className='hidden flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white'>
      		Go
      	</button>
      </Text>

      {
      	list.length > 5 ?
      	<Text className='text-red-400'>
      		{pageDesc}
      	</Text>
      	:
      	<></>
      }

      <PaginationV2
        currentPage={currentPage}
        itemsPerPage={articlesPerPage}
        paginate={paginate}
        totalItems={list.length}
      />
    </Div>  
    </Div>
 
    </>
  )
};