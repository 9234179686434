import React, { FC } from 'react';

interface InfoProps {
  className?: string;
  props?: any;
}

export const Info: FC<InfoProps> = ({ className, children, props }) => (
  <h1
    className={`font-roboto font-regular phone:text-lg tablet:text-lg laptop:text-lg 
    desktop:text-lg ${className}`}
    {...props}
  >
    {children}
  </h1>
);
