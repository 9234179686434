import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import {
  Flex,
  Text,
  FlexRow,
  FilterDropdown,
  Div,
  Button,
  Toast,
  ToastV4,
  ToastContainer,
  ActionDropdown,
} from '@core';
import { Pagination, PaginationV2, AffiliateTagList, CreatePartnerModalV2 } from '@components';
import { getCookie } from '@hooks';
import { API } from '@config';
import { CategoriesTagging, ActionsSelect3 } from '@src/constants';

export const AffiliateTagging: FC<any> = () => {
  const token = getCookie('token');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');
  const navigate = useNavigate();

  const [selection, setSelection] = React.useState(true);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<any>([]);
  const [options, setOptions] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [indexNum, setIndex] = React.useState(0);
  const [start, setStart] = React.useState(false);
  const [categoryCommission, setCategoryCommission] = React.useState('0');
  const [categoryId, setCategoryId] = React.useState(0);
  const [actionSelected, setActionSelected] = React.useState('');

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastDesc2, setToastDesc2] = React.useState('');
  const [isToastOpen3, setToastOpen3] = React.useState(false);

  const [createPartnerOpen, setCreatePartnerOpen] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [resultDesc, setResultDesc] = React.useState('');
  const [isShow, setIsShow] = React.useState(true);

  const [partnerList, setPartnerList] = React.useState<any>([]);
  const [sortList, setSortList] = React.useState<any>([]);
  const dataList: any[] = [];
  const nullList: any[] = [];
  const resultList: any[] = [];
  const emptyList: any[] = [];

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage, setArticlesPerPage] = React.useState(5);
  const [pageItems, setPageItems] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newPartners = sortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const onRefresh = () => {
    setIndex(0);
    setSelectedData([]);
    setIsShow(false);
    fetch(`${API}/get-all-affiliate-category`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setPartnerList(data.data);
        setSortList(data.data);
        setTimeout(() => {
          setIsShow(true);
        }, 1500);
      });
  };

  React.useEffect(() => {
    setIsShow(false);
    setTimeout(() => {
      setIsShow(true);
    }, 750);
  }, [currentPage]);

  React.useEffect(() => {
    if (pageItems < 0 || pageItems > sortList.length) {
      setArticlesPerPage(5);
    } else if (pageItems > 0 && pageItems < sortList.length) {
      setArticlesPerPage(pageItems);
    }
  }, [pageItems]);

  const [category, setCategory] = React.useState({
    label: 'Default',
    value: 'Default',
  });

  React.useEffect(() => {
    fetch(`${API}/get-all-role-commission`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          setOptions(data.data);
        }
      });
  }, []);

  React.useEffect(() => {
    if (actionQuery === 'updated') {
      navigate('/merchant/settings');
      fetch(`${API}/get-all-affiliate-category`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setPartnerList(data.data);
          setSortList(data.data);
        });
    }
  }, [actionQuery]);

  React.useEffect(() => {
    if (category.value === 'Default') {
      fetch(`${API}/get-all-affiliate-category`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setPartnerList(data.data);
          setSortList(data.data);
        });
    }
  }, [token, category]);

  React.useEffect(() => {
    if (selectedCategory === 'none') {
      setCategoryId(0);
      setCategoryCommission('0');
    } else {
      options.map((categoryA: any) => {
        if (categoryA.role_name === selectedCategory) {
          setCategoryId(categoryA.id);
          setCategoryCommission(categoryA.commission);
        }
        return 1;
      });
    }
  }, [selectedCategory]);

  React.useEffect(() => {
    if (searchKeyword === '') {
      setSortList(partnerList);
      setResultDesc('');
    }
  }, [searchKeyword]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (selectedData.length === 0 && start) {
        setToastOpen3(false);
        setStart(false);
        setToastTitle('ERROR');
        setToastDesc('Please select an applicant to proceed.');
        setToastKind('error');
        setToastOpen(true);
      } else if (indexNum !== selectedData.length && start) {
        fetch(`${API}/update-affiliate-category/${selectedData[indexNum].user_id}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            category_id: categoryId,
            commission: categoryCommission,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === 1) {
              setIndex(indexNum + 1);
            } else {
              setIndex(indexNum + 1);
              setToastDesc(data?.message || 'Something went wrong. Please try again.');
              setToastOpen(true);
              setToastTitle('ERROR');
              setToastKind('error');
            }
          });
      } else if (indexNum === selectedData.length && start) {
        setIndex(0);
        setSelectedAll(false);
        setToastOpen3(false);
        setStart(false);
        setToastTitle('Applicants Added');
        setToastDesc('Affiliates are successfully updated.');
        setToastKind('success');
        setSelectedData([]);
        setToastOpen(true);
        setTimeout(() => {
          onRefresh();
        }, 1500);
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [start, indexNum]);

  React.useEffect(() => {
    const onSort = async () => {
      if (category.value === 'By Role') {
        await dataList.splice(0, dataList.length);
        setIsShow(false);
        await partnerList.map((partner: any) => {
          if (partner.category !== null) {
            dataList.push(partner);
          }
          return 1;
        });
        await setSortList(
          dataList.sort((a: any, b: any) => {
            if (a.category < b.category) {
              return -1;
            }
            if (a.category > b.category) {
              return 1;
            }
            return 0;
          }),
        );
        setTimeout(() => {
          setIsShow(true);
        }, 1500);
      } else if (category.value === 'By Commissions') {
        setIsShow(false);
        await setSortList(partnerList.sort((a: any, b: any) => b.commission - a.commission));
        setTimeout(() => {
          setIsShow(true);
        }, 1500);
      } else if (category.value === 'Non-Categorized') {
        await nullList.splice(0, nullList.length);
        setIsShow(false);
        await partnerList.map((partner: any) => {
          if (partner.category === null) {
            nullList.push(partner);
          }
          return 1;
        });
        setSortList(nullList);
        setTimeout(() => {
          setIsShow(true);
        }, 1500);
      } else if (category.value === 'Default') {
        setIsShow(false);
        setSortList(partnerList);
        setTimeout(() => {
          setIsShow(true);
        }, 1500);
      }
    };
    onSort().catch(console.error);
  }, [category]);

  const onSearch = async (e: any) => {
    e.preventDefault();
    if (/[a-zA-Z]/.test(searchKeyword)) {
      setIsShow(false);
      await sortList.map((item: any) => {
        const fullName = item?.firstname.concat(' ', item?.lastname);
        if (item.firstname.toLowerCase().includes(searchKeyword.toLowerCase())) {
          resultList.push(item);
          setSortList(resultList);
          setCurrentPage(1);
        } else if (item.lastname.toLowerCase().includes(searchKeyword.toLowerCase())) {
          resultList.push(item);
          setSortList(resultList);
          setCurrentPage(1);
        } else if (item.firstname === 'none') {
          if (item.company_name.toLowerCase().includes(searchKeyword.toLowerCase())) {
            resultList.push(item);
            setSortList(resultList);
            setCurrentPage(1);
          }
        } else if (fullName.toLowerCase().includes(searchKeyword.toLowerCase())) {
          resultList.push(item);
          setSortList(resultList);
          setCurrentPage(1);
        } else {
          setCurrentPage(1);
        }
        return 1;
      });
      if (resultList.length === 0) {
        setIsShow(true);
        setSortList(emptyList);
        setResultDesc(`You've searched for "${searchKeyword}" keyword. (0) result(s) found.`);
      } else {
        setIsShow(true);
        setResultDesc(
          `You've searched for "${searchKeyword}" keyword. (${resultList.length}) result(s) found.`,
        );
      }
    }
  };

  const onLoadData = () => {
    setResultDesc('');
    setIsShow(false);
    setSortList(partnerList);
    setTimeout(() => {
      setIsShow(true);
    }, 1500);
  };

  const Update = async () => {
    if (selectedData.length > 0 && selectedCategory !== '') {
      setSelection(false);
      setToastDesc2(
        `Are you sure you want to update all ${selectedData.length} affiliate(s) selected?`,
      );
      setToastOpen2(true);
      setSelection(true);
    } else if (selectedCategory === '') {
      setToastTitle('ERROR');
      setToastDesc('Please select a category.');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000);
    } else if (selectedData.length === 0) {
      setToastTitle('ERROR');
      setToastDesc('Please select affiliates.');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000);
    }
  };

  const onUpdate = async () => {
    setToastOpen2(false);
    setToastOpen3(true);
    setTimeout(() => {
      setStart(true);
    }, 1500);
  };

  const SelectAll = async () => {
    setIsShow(false);
    if (selectedData.length === partnerList.length) {
      setSelectedAll(false);
      setSelectedData([]);
      setTimeout(() => {
        setIsShow(true);
      }, 1500);
    } else {
      setSelectedAll(true);
      setSelectedData(partnerList);
      setTimeout(() => {
        setIsShow(true);
      }, 1500);
    }
  };

  const Select = (data: any) => {
    if (!selectedData.includes(data)) {
      selectedData.push(data);
      setSelectedData(selectedData);
    } else {
      setSelectedData(selectedData.filter((value: number) => value !== data));
    }
  };

  const ClearSelection = async () => {
    setIsShow(false);
    setSelectedData([]);
    setTimeout(() => {
      setIsShow(true);
    }, 1500);
  };

  const Selection = async () => {
    setIsShow(false);
    await setSelection(!selection);
    setTimeout(() => {
      setIsShow(true);
    }, 1000);
  };

  React.useEffect(() => {
    if (actionSelected === 'Update Selected') {
      Update();
      setActionSelected('');
    } else if (actionSelected === 'Clear Selected') {
      ClearSelection();
      setActionSelected('');
    } else if (actionSelected === 'Select/Unselect') {
      setSelection(!selection);
      setActionSelected('');
    }
  }, [actionSelected]);

  return (
    <Flex className="desktop:pl-16 phone:pt-0 pb-16 laptop:pr-20 phone:px-5">
      <CreatePartnerModalV2
        isOpen={createPartnerOpen}
        onClose={() => setCreatePartnerOpen(false)}
      />
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <ToastV4
        btnText="Cancel"
        description={toastDesc2}
        isOpen={isToastOpen2}
        link=""
        onClick={onUpdate}
        onClick2={() => {
          ClearSelection();
          setToastOpen2(false);
        }}
        onClose={() => {
          ClearSelection();
          setToastOpen2(false);
        }}
        submitBtnText="Update All"
        title="ACTION"
        toastKind="info"
      />
      <ToastContainer
        description="Update Category"
        isOpen={isToastOpen3}
        onClose={() => setToastOpen3(false)}
        title="ACTION"
        toastKind="info"
      >
        <Div>
          <Text className="text-secondary-200 phone:text-sm surfaceDuo:text-base py-1 mt-2">
            <b className="mr-1">REMINDER:</b>
            Please refrain from reloading this page as we complete this action.
          </Text>
        </Div>
        <Div className={`${start ? '' : 'hidden'}`}>
          {indexNum === 0 ? (
            <Text className="text-secondary-200 phone:text-sm surfaceDuo:text-base">
              Loading data ...
              <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
            </Text>
          ) : (
            <>
              <Text className="text-secondary-200 phone:text-sm surfaceDuo:text-base">
                Processing applications ...
                <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
              </Text>
              <FlexRow className="pr-4 py-2 w-full justify-between">
                <div
                  className="rounded-full background-play h-2.5"
                  style={{
                    width: `${
                      indexNum === 0 ? 0 : ((indexNum / selectedData.length) * 100).toFixed(0)
                    }%`,
                  }}
                />
                <Text className="phone:ml-2 surfaceDuo:ml-4 phone:text-sm surfaceDuo:text-base">
                  {indexNum === 0 ? 0 : ((indexNum / selectedData.length) * 100).toFixed(0)}%
                </Text>
              </FlexRow>
            </>
          )}
        </Div>
      </ToastContainer>

      <Text className="font-poppins font-semibold text-primary-100 text-xl phone:mt-3 tablet:mt-8 mb-2">
        Affiliate Category Tagging
      </Text>
      <hr className="text-grey-400" />

      <FlexRow className="justify-between mt-4 phone:flex-col tablet:items-start tabletWide:items-center tabletWide:flex-row w-full">
        <FlexRow className="w-full mx-3 phone:flex-col surfaceDuo:flex-row justify-start tabletWide:w-auto mt-4">
          <Text className="text-lg font-semibold mr-10 text-primary-100">All Affiliates</Text>
          <Text className="mr-auto medium:ml-4">
            {partnerList.length} Total &#8226; {partnerList.length} Active
          </Text>
        </FlexRow>

        <FlexRow className="medium:mx-3 phone:flex-col surfaceDuo:flex-row mt-4 w-full tabletWide:w-auto">
          <FlexRow className="justify-between w-full tablet:w-auto">
            <form
              className="relative flex flex-row items-center phone:w-full surfaceDuo:w-auto border-2 border-primary-100 rounded-lg"
              onSubmit={(e: any) => onSearch(e)}
            >
              <input
                className="relative inputForm rounded-lg phone:w-full surfaceDuo:w-44 border-none outline-none"
                onChange={(e: any) => setSearchKeyword(e.target.value)}
                placeholder="Search"
                type="search"
                value={searchKeyword}
              />
              <button
                className="absolute -right-1 z-10 bg-primary-100 border-2 border-primary-100 rounded-lg"
                type="submit"
              >
                <i className="rounded fas fa-search text-white text-base py-2 px-3" />
              </button>
            </form>

            {category.label === 'Default' ? (
              <FilterDropdown
                classNameBody="border-4 border-primary-100 rounded-lg z-10"
                color="white"
                current={category?.label || ''}
                items={CategoriesTagging}
                onSelect={setCategory}
                placeholder="Select a product"
              />
            ) : (
              <>
                <div
                  className={`inline-flex items-center justify-center px-5 border-0
        py-3 font-poppins text-base font-regular text-primary-100
        hover:text-red-100 cursor-pointer `}
                  onClick={() =>
                    setCategory({
                      label: 'Default',
                      value: 'Default',
                    })
                  }
                >
                  Reset
                  <i className="fas fa-filter text-2xl ml-1" />
                </div>
              </>
            )}
          </FlexRow>

          <div className="hidden tabletWide:flex border-r-2 border-secondary-200 ml-2 mr-4 h-14" />
          <button
            className="ml-auto bg-primary-100 p-3 text-white hover:bg-blue-400 phone:w-full mt-4 surfaceDuo:mt-0 rounded-lg medium:w-36 laptop:w-44"
            onClick={() => setCreatePartnerOpen(true)}
          >
            Add Affiliate <i className="fas fa-plus text-white mx-3 medium:hidden laptop:inline" />
          </button>
        </FlexRow>
      </FlexRow>

      <Text className="text-secondary-200 my-4">{resultDesc}</Text>

      <FlexRow className="items-center w-full tablet:ml-4 mb-4 hidden">
        <button
          className="text-white text-lg font-regular bg-primary-100 hover:bg-blue-100 rounded-lg py-2 px-3"
          onClick={Selection}
        >
          <i className="fas fa-bars phone:mr-4 surfaceDuo:mr-2" />
          {selection ? 'Disable selection' : 'Enable Selection'}
        </button>
        <Popover className="relative inline mx-2 hidden">
          <Popover.Button className="inline border border-primary-100 bg-white hover:bg-primary-100 text-primary-100 hover:text-white rounded-full px-2">
            <i className="fas fa-question text-xs" />
          </Popover.Button>
          <Popover.Panel className="absolute z-10 -right-10 mt-4">
            <Div className="bg-white border border-primary-100 phone:w-48 surfaceDuo:w-60 rounded-lg py-4 px-2">
              <Text className="text-sm text-secondary-200 text-left">
                Select entries to execute a specific action on chosen items.
              </Text>
              <Text className="mt-4 text-sm text-secondary-200">
                ** Click anywhere on screen to close. **
              </Text>
            </Div>
          </Popover.Panel>
        </Popover>
      </FlexRow>

      <Div className="tablet:ml-4 mb-4 hidden">
        <ActionDropdown
          className="phone:text-white phone:py-3"
          classNameBody="border border-grey-400 rounded-lg z-10 phone:left-0"
          color="white"
          containerClass="bg-primary-100 hover:bg-blue-100 phone:rounded-lg surfaceDuo:w-40"
          current={actionSelected}
          items={ActionsSelect3}
          onSelect={setActionSelected}
          placeholder="Select an action"
        />
      </Div>

      {selection ? (
        <>
          <Div className="flex flex-row items-center phone:w-full surfaceDuo:w-auto tablet:ml-4">
            <label className="hidden surfaceDuo:flex mr-3" htmlFor="sort">
              Set Selected as:
            </label>
            <select
              className="rounded-lg phone:w-full surfaceDuo:w-56 border-2 border-primary-100"
              name="sort"
              onChange={(e: any) => setSelectedCategory(e.target.value)}
              value={selectedCategory}
            >
              <option value="">Category</option>
              {options.map((option: any) => (
                <option
                  key={option.role_name}
                  style={{ backgroundColor: option.color }}
                  value={option.role_name}
                >
                  {option.role_name.toUpperCase()}
                </option>
              ))}
              <option value="none">NONE</option>
            </select>
          </Div>

          <FlexRow className="phone:flex-col surfaceDuo:flex-row items-center w-full mt-4 tablet:ml-4">
            <Button
              className="phone:px-3 phone:py-3 phone:text-base phone:w-full phone:my-1 surfaceDuo:my-0 surfaceDuo:w-auto"
              onClick={Update}
            >
              <span className="mx-auto">Update Selected</span>
            </Button>
            <Button className="phone:px-3 phone:py-2 phone:text-secondary-200 phone:text-base phone:bg-grey-500 phone:w-full border border-secondary-100 phone:my-1 surfaceDuo:my-0 surfaceDuo:w-auto hidden">
              <span className="mx-auto">Clear Selection</span>
            </Button>
          </FlexRow>
        </>
      ) : (
        <></>
      )}

      <Div className="mb-4 w-full medium:hidden tablet:block tabletWide:hidden">
        <Text className="font-poppins font-semibold text-secondary-200 my-2 text-center text-lg">
          List of Affiliates
        </Text>
        <hr className="text-grey-400" />
      </Div>

      {selection ? (
        <FlexRow className="items-center mb-4 ml-1 medium:hidden tablet:flex tabletWide:hidden">
          <input
            checked={selectedAll}
            className={`mr-2 w-5 h-5 rounded border border-secondary-100 
                focus:ring-3 focus:ring-primary-200 text-primary-100
                cursor-pointer
                ${selection ? '' : 'hidden'}
                `}
            onClick={SelectAll}
            readOnly
            title={
              selectedData.length === partnerList.length
                ? 'Click to Unselect All'
                : 'Click to Select All'
            }
            type="checkbox"
          />
          <Text className="ml-2 text-blue-100 font-semibold">
            {selectedData.length === partnerList.length ? 'Unselect All' : 'Select All'}
          </Text>
        </FlexRow>
      ) : (
        <></>
      )}

      <div className="w-full mx-auto">
        <FlexRow className="pl-6 mt-8 justify-around phone:hidden medium:flex tablet:hidden tabletWide:flex relative">
          <input
            checked={selectedAll}
            className={`mr-2 w-5 h-5 rounded border border-secondary-100 
              focus:ring-3 focus:ring-primary-200 text-primary-100
              cursor-pointer absolute left-1 top-auto bottom-auto
              ${selection ? '' : 'hidden'}
              `}
            onClick={SelectAll}
            readOnly
            title={
              selectedData.length === partnerList.length
                ? 'Click to Unselect All'
                : 'Click to Select All'
            }
            type="checkbox"
          />
          <Text className="w-1/4 text-secondary-200 text-center font-semibold">Name</Text>
          <Text className="w-1/4 text-secondary-200 text-center font-semibold">Category</Text>
          <Text className="w-1/4 text-secondary-200 text-center font-semibold">Commission</Text>
          <Text className="w-1/4 text-secondary-200 text-center font-semibold">Social Link</Text>
        </FlexRow>
        <hr className="text-grey-400 mt-2 mb-4 font-semibold phone:hidden medium:flex tablet:hidden tabletWide:flex" />

        {sortList.length > 0 && isShow ? (
          <>
            <Div
              className={`w-full
          ${articlesPerPage > 10 ? `${selection ? '' : 'h-96 overflow-y-scroll'}` : ''}`}
            >
              {newPartners.map((partner: any, index: number) => (
                <AffiliateTagList
                  affiliateCategory={partner.category !== null ? partner.category : 'NONE'}
                  affiliateEmail={
                    partner.firstname === 'none' ? partner.company_email : partner.email
                  }
                  affiliateLink={
                    partner.firstname === 'none' ? partner.company_link : partner.social_link
                  }
                  affiliateName={
                    partner.firstname === 'none'
                      ? partner.company_name
                      : `${partner.firstname} ${partner.lastname}`
                  }
                  categoryColor={partner.color === null ? '#F5F6F7' : partner.color}
                  commission={partner.commission !== null ? parseFloat(partner.commission) : 0}
                  currentPage={currentPage}
                  id={partner.user_id}
                  key={index}
                  partner={partner}
                  Select={() => Select(partner)}
                  selectedAll={selectedAll}
                  selectedData={selectedData}
                  selection={selection}
                />
              ))}
              {newPartners.length === 0 ? (
                <Div className="bg-grey-400 py-4 my-2">
                  <Text className="text-center w-full">Page is empty.</Text>
                  <Text className="text-xs text-center w-full mt-4">Go to Page 1</Text>
                </Div>
              ) : (
                <></>
              )}
            </Div>
          </>
        ) : (
          <FlexRow className="w-full">
            {sortList.length > 0 && !isShow ? (
              <Text className="text-secondary-200 bg-grey-500 py-10 text-center w-full">
                Loading data ...
                <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
              </Text>
            ) : (
              <Text className="text-secondary-200 bg-grey-500 py-10 text-center w-full">
                No data to be displayed.
                <span
                  className="block text-blue-100 hover:underline font-semibold cursor-pointer"
                  onClick={onLoadData}
                >
                  Click to reload.
                  <i className="fas fa-repeat ml-2" />
                </span>
              </Text>
            )}
          </FlexRow>
        )}

        {partnerList.length > 5 ? (
          <Div className="w-full justify-center mt-14">
            <div
              className="font-robot font-regular text-blue-100 my-4 hover:underline cursor-pointer"
              onClick={() => {
                setCategory({
                  label: 'Default',
                  value: 'Default',
                });
                setResultDesc('');
              }}
            >
              Reset records.
            </div>

            <Text className="text-secondary-200 my-4">
              Total records found: <b className="ml-4 mr-2">{sortList.length}</b> entries
            </Text>

            <Div className="flex flex-row items-center text-secondary-200 font-robot font-regular my-4">
              <Popover className="relative my-4">
                <Popover.Button>
                  <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
                </Popover.Button>
                <Popover.Panel className="absolute z-10 left-0">
                  <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
                    <p className="font-semibold text-sm">Items per Page</p>
                    <hr className="text-primary-100 my-1" />
                    <p className="text-sm mt-4">
                      Input a number or press
                      <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
                      <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
                      to change number of entries per page.
                    </p>
                    <p className="mt-4 text-sm text-secondary-200">
                      ** Click anywhere on screen to close. **
                    </p>
                  </div>
                </Popover.Panel>
              </Popover>
              Items per Page:
              <input
                className="mx-2 w-10 px-2"
                onChange={(e: any) => setPageItems(e.target.value)}
                type="number"
                value={pageItems}
              />
              <button className="flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white">
                Go
              </button>
            </Div>

            <PaginationV2
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={sortList.length}
            />
          </Div>
        ) : (
          <div className="w-full flex justify-center my-4">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={sortList.length}
            />
          </div>
        )}
      </div>
    </Flex>
  );
};
