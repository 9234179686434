import React, { FC, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector, batch } from 'react-redux';
import { PartnersAdmin } from './PartnersAdmin';
import { ProductsAdmin } from './ProductsAdmin';
import { PartnerDetails } from './PartnerDetails';
import { ProductDetails } from './ProductDetails';
import {
  selectProducts,
  selectPartners,
  selectlinks,
  productsActions,
  merchantsActions,
  partnersActions,
  useAppDispatch,
  usersActions,
  selectUser,
  selectProductsLoading,
} from '@redux';
import { useCookie } from '@hooks';
import { FlexRow, Div, Flex, Text, H2, Toast } from '@core';
import {
  NavBarAdmin,
  CreateLinkModal,
  QrCodeModal,
  CreateProductAdminModal,
  CreatePartnerAdminModal,
  AdminInformationModal,
  AnalyticsAdmin,
  AlertMessage,
} from '@components';
import { MenuTabs } from '@constants';
import { getTopProducts, getTopPartners } from '@services';

export const DashboardEditor: FC<any> = () => {

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const user: any = useSelector(selectUser);
  const products: any = useSelector(selectProducts);
  const productLoading: any = useSelector(selectProductsLoading);
  const partners: any = useSelector(selectPartners);
  const links: any = useSelector(selectlinks);
  const [isOpen, setIsOpen] = React.useState(false);

  const [iconLoaded, setIconLoaded] = React.useState(false);
  const [token, updateToken] = useCookie('token', '0');
  const [selectedTab, setSelectedTab] = React.useState('Overview');
  const [selectedPartner, setSelectedPartner] = React.useState<any>('');
  const [selectedProduct, setSelectedProduct] = React.useState<any>('');
  const [selectedProd, setSelected] = React.useState<any>('');

  const [totalAffiliates, setTotalAffiliates] = React.useState(0);
  const [totalClicks, setTotalClicks] = React.useState(0);
  const [totalConversion, setTotalConversion] = React.useState(0);

  const [totalRevenue, setTotalRevenue] = React.useState(0);
  const [pendingRevenue, setPendingRevenue] = React.useState(0);
  const [netRevenue, setNetRevenue] = React.useState(0);
  const [totalCommission, setTotalCommission] = React.useState(0);
  const [pendingCommission, setPendingCommission] = React.useState(0);
  const [paidCommission, setPaidCommission] = React.useState(0);

  const [createLinkOpen, setCreateLinkOpen] = React.useState(false);
  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);
  const [createProdOpen, setCreateProdOpen] = React.useState(false);
  const [createPartnerOpen, setCreatePartnerOpen] = React.useState(false);
  const [accountInfoOpen, setAccountInfoOpen] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const [productData, setProductData] = React.useState([]);
  const [productKeys, setProductKeys] = React.useState([]);
  const [productPie, setProductPie] = React.useState([]);

  const [partnerData, setPartnerData] = React.useState([]);
  const [partnerKeys, setPartnerKeys] = React.useState([]);
  const [partnerPie, setPartnerPie] = React.useState([]);

  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');
  const partnerId = query.get('partner');
  const productId = query.get('product');
  const notification = query.get('notification');
  const type = query.get('type');
  const newitem = query.get('item');

  React.useEffect(() => {
    if (actionQuery === 'createlink') {
      setCreateLinkOpen(true);
    } else if (actionQuery === 'qrcode') {
      setQrCodeOpen(true);
    } else if (actionQuery === 'product') {
      // create product limit
      if(user){
        if(!user.email_confirmed){
          setToastTitle('Email not verified');
          setToastDesc('Please verify email to proceed');
          setToastKind('error');
          setToastOpen(true);
        } else {
          setCreateProdOpen(true);
        }
      }
    } else if (actionQuery === 'partner') {
      // create partner limit
      if(user){
        if(!user.email_confirmed){
          setToastTitle('Email not verified');
          setToastDesc('Please verify email to proceed');
          setToastKind('error');
          setToastOpen(true);
        } else {
          setCreatePartnerOpen(true);
        }
      }
    } else if (actionQuery === 'account') {
      setAccountInfoOpen(true);
    } else if (actionQuery === 'logout') {
      batch(async () => {
        updateToken('', 1);
        await dispatch(usersActions.logout());
        setTimeout(() => {
          navigate(`/`);
        }, 2000);
      });
    } else if (actionQuery === 'details_partner') {
      setSelectedPartner(partnerId)
      setSelectedTab('details_partner')
      setTimeout(() => {
        navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=details_partner`)
      }, 3000)
    } else if (actionQuery === 'details_product') {
      setSelectedProduct(productId)
      setSelectedTab('details_product')
      setTimeout(() => {
        navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=details_product`)
      }, 3000)
    } else if (actionQuery === 'partners') {
      setSelectedTab('Partners')
    } else if (actionQuery === 'products') {
      setSelectedTab('Products')
    } else if (actionQuery === 'overview') {
      setSelectedTab('Overview')
    } else if (actionQuery === 'notif') {
      setSelectedTab('')
      if(!productLoading){
        batch(async() => {
          products.map((product: any) => {
            if(product.name === notification){
              setSelectedProduct(productId)
              setSelected(product)
              if(type === 'apply'){
                navigate(`/merchant/admin_dashboard/editor?action=details_product&product=${product.id}&notif=application`)
              }else{
                navigate(`/merchant/admin_dashboard/editor?action=details_product&product=${product.id}`)
              }
            }
            return 1;
          })
        })
      }
    } else if (actionQuery === 'newitem') {
      setSelectedTab('')
      if(!productLoading){
        batch(async() => {
          products.map((product: any) => {
            if(product.name === newitem){
              setSelectedProduct(productId)
              setSelected(product)
              navigate(`/merchant/admin_dashboard/editor?action=details_product&product=${product.id}&notif=newassets`)
            }
            return 1;
          })
        })
      }
    }
  }, [actionQuery]);

  // closing toast, and clearing actionQuery
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if(event){
      navigate('/merchant/admin_dashboard/editor');
      setToastOpen(false);
    }
  }

  React.useEffect(() => {
      setTotalRevenue(user?.revenue_and_commissions?.total_revenue);
      setNetRevenue(user?.revenue_and_commissions?.net_revenue);
      setPendingRevenue(user?.revenue_and_commissions?.pending_revenue);
      setTotalCommission(user?.revenue_and_commissions?.total_commission);
      setPaidCommission(user?.revenue_and_commissions?.paid_commission);
      setPendingCommission(user?.revenue_and_commissions?.not_paid_commission);
      setTotalClicks(user?.merchant_data?.total_clicks)
      setTotalAffiliates(user?.merchant_data?.total_affiliate)
      setTotalConversion(user?.merchant_data?.total_conversions)
  }, []);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(productsActions.productListAdmin(user?.user_info?.merchant_id));
      await dispatch(partnersActions.partnersListAdmin(user?.user_info?.merchant_id));
      await dispatch(merchantsActions.merchantAnalyticsAdmin(user?.user_info?.merchant_id));

      const topProduct = await getTopProducts();
      if(topProduct.data.status === 1){
        setProductData(topProduct.data.radar.product_data)
        setProductKeys(topProduct.data.radar.product_list)
        setProductPie(topProduct.data.pie.product_data)
      }

      const topPartner = await getTopPartners();
      if(topPartner.data.status === 1){
        setPartnerData(topPartner.data.partner_data)
        setPartnerKeys(topPartner.data.partner_list)
        setPartnerPie(topPartner.data.partner_data2)
      }
    });
  }, []);

  return (
    <Div className="h-full">
      <NavBarAdmin />
      <CreateLinkModal isOpen={createLinkOpen} onClose={() => setCreateLinkOpen(false)} />
      <QrCodeModal isOpen={qrCodeOpen} onClose={() => setQrCodeOpen(false)} />
      <CreateProductAdminModal isOpen={createProdOpen} onClose={() => setCreateProdOpen(false)} />
      <CreatePartnerAdminModal
        isOpen={createPartnerOpen}
        onClose={() => setCreatePartnerOpen(false)}
      />
      <AdminInformationModal isOpen={accountInfoOpen} onClose={() => setAccountInfoOpen(false)} />
      <Flex className="h-full w-full pt-24">
      <span className='hidden'>Unused Variables {token}{links}</span>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={handleClose}
        title={toastTitle}
        toastKind={toastKind}
      />
        {user?.email_confirmed ? (
          <></>
        ) : (
          <AlertMessage
            btnText="Go to Settings"
            linkTo="/merchant/admin_dashboard/editor/settings?action=verify"
            message={`Your email address ${user?.user_info?.email} is currently not verified. Please confirm your email address.`}
          />
        )}

        <FlexRow className="h-full w-full overflow-hidden">
          <Flex className='surfaceDuo:pt-4 pb-14 w-fit laptop:px-20 phone:px-4 tablet:px-10 overflow-y-scroll flex'>

          <FlexRow className='w-full py-1 items-center surfaceDuo:hidden border-b-2 border-grey-400 mb-4'>
            <H2 className="font-poppins font-semibold mb-2 phone:mt-4 text-secondary-200">Menu</H2>
            <div className='ml-auto w-10 h-10 rounded-lg bg-grey-500 border-2 border-grey-400 flex items-center justify-center tablet:hidden' onClick={() => setIsOpen(!isOpen)}>
              <i className="fas fa-bars text-secondary-200" />
            </div>
          </FlexRow>

          <Transition appear as={Fragment} show={isOpen}>
            <Dialog
              as="div"
              className="fixed h-full mt-20 w-64 inset-0 z-10 overflow-hide tablet:hidden"
              onClose={() => 'noop'}
            >
              <div className="h-full text-center flex justify-start">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease duration-700 transform"
                  enterFrom="opacity-0 -translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition ease duration-1000 transform"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-full"
                >
                  <div
                    className="w-full overflow-y-scroll scrollbar-hide text-left z-20 py-5 items-center
                    align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
                  >
                    <div className='ml-auto mr-4 w-6 h-6 rounded-lg border-2 border-grey-400 bg-grey-500 flex items-center justify-center tablet:hidden mt-2' onClick={() => setIsOpen(!isOpen)}>
                      <i className="fas fa-times text-secondary-200" />
                    </div>
                    {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
                    {MenuTabs[0].links.map((value) => (
                      <div
                        className={`pl-4 w-full py-2 tex-base font-medium hover:text-blue-100
                         ${
                          selectedTab === value.tab
                            ? 'border-r-4 text-secondary-200 font-bold'
                            : 'text-primary-100'
                        }`}
                        key={value.name}
                        onClick={() => {
                          setSelectedTab(value.tab)
                          navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=${value.tab.toLowerCase()}`)
                        }}
                      >
                        <i className={`${value.icon} w-10`} />
                        <span className="font-poppins">{value.name}</span>
                      </div>
                    ))}
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>

          <FlexRow className='phone:justify-between surfaceDuo:justify-start w-full mb-8'>
            <img
              alt={`${user?.merchant_data?.info?.store_name}`}
              className='w-20 h-20 object-cover rounded-full shadow-xl border-4 border-grey-400 mr-10'
              onError={() => setIconLoaded(true)}
              src={
                iconLoaded
                  ? 'https://i.imgur.com/CGDGnf6.png'
                  : user.merchant_data.company[0].image
              } 
            />
            <Div>
              <Text className="font-poppins tablet:text-xl phone:text-base text-primary-100">
                Merchant: <b>{user?.merchant_data?.info?.store_name}</b>
              </Text>
              <FlexRow className='mt-2'>
                <Text className='text-sm text-secondary-200'>
                  <b className='mx-2'>{products.length}</b>
                  Products
                </Text>
                <Text className='text-sm text-secondary-200'>
                  <b className='mx-2'>{partners.length}</b>
                  Partners
                </Text>
              </FlexRow>
            </Div>
          </FlexRow>

          <FlexRow className='w-full pt-2 mb-8 border-b-2 border-primary-100 phone:hidden surfaceDuo:flex'>
            <div className={`hover:bg-blue-300 py-2 mr-8 border-b-4 font-poppins font-semibold text-2xl cursor-pointer ${selectedTab === 'Overview' ? 'border-blue-100' : 'border-none text-primary-100'}`} onClick={() => {
                setSelectedTab('Overview')
                navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=overview`)
              }}>
              OVERVIEW
            </div>
            <div className={`hover:bg-blue-300 py-2 mr-8 border-b-4 font-poppins font-semibold text-2xl cursor-pointer ${selectedTab === 'Products' ? 'border-blue-100' : 'border-none text-primary-100'}`} onClick={() => {
                setSelectedTab('Products')
                navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=products`)
              }}>
              PRODUCTS
            </div>
            <div className={`hover:bg-blue-300 py-2 mr-8 border-b-4 font-poppins font-semibold text-2xl cursor-pointer ${selectedTab === 'Partners' ? 'border-blue-100' : 'border-none text-primary-100'}`} onClick={() => {
                setSelectedTab('Partners')
                navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=partners`)
              }}>
              PARTNERS
            </div>
        </FlexRow>

          {
            selectedTab === 'Overview' ?
            <Flex className='w-full h-full'>
              <AnalyticsAdmin
                affiliates={totalAffiliates}
                clicks={totalClicks}
                conversions={totalConversion}
                netRevenue={netRevenue}
                paidCommission={paidCommission}
                partnerData={partnerData}
                partnerKeys={partnerKeys}
                partnerPie={partnerPie}
                pendingCommission={pendingCommission}
                pendingRevenue={pendingRevenue}
                productData={productData}
                productKeys={productKeys}
                productPie={productPie}
                totalCommission={totalCommission}
                totalRevenue={totalRevenue}
                user={user}
              />
            </Flex>
            :
            <></>
          }
          {
            selectedTab === 'Products' ?
            <Div className='w-full h-full'>
              <Text className="font-poppins font-semibold tablet:text-2xl phone:text-xl text-primary-100 pb-4 hidden">
                {user?.merchant_data?.info?.store_name} Products
              </Text>
              <hr className="text-grey-400 mb-8 hidden" />
              <ProductsAdmin />
            </Div>
            :
            <></>
          }
          {
            selectedTab === 'Partners' ?
            <Div className='w-full h-full'>
              <Text className="font-poppins font-semibold tablet:text-xl phone:text-xl text-primary-100 pb-4 hidden">
                {user?.merchant_data?.info?.store_name} Partners
              </Text>
              <hr className="text-grey-400 mb-8 hidden" />
              <PartnersAdmin />
            </Div>
            :
            <></>
          }
          {
            selectedTab === 'details_partner' ?
            <Div className='w-full h-full'>
              <PartnerDetails partner={selectedPartner}/>
            </Div>
            :
            <></>
          }
          {
            selectedTab === 'details_product' ?
            <Div className='w-full h-full'>
              <ProductDetails product={selectedProduct} setSelected={selectedProd}/>
            </Div>
            :
            <></>
          }
          
          </Flex>
        </FlexRow>
      </Flex>
    </Div>
  );
};