import React, { FC } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { useSelector, batch } from 'react-redux';
import { Product } from './Products';
import { Partners2 } from './Partners';
import { MobileModal } from './MobileModal';
import {
  selectProducts,
  selectPartners,
  selectlinks,
  productsActions,
  merchantsActions,
  partnersActions,
  useAppDispatch,
  usersActions,
  selectUser,
  selectProductsLoading,
} from '@redux';
import { classNames } from '@helpers';
import { useCookie } from '@hooks';
import { FlexRow, Div, Flex, Button, Text, Toast } from '@core';
import {
  NavBarAdmin,
  CreateLinkModal,
  QrCodeAdminModal,
  CreateProductAdminModal,
  CreatePartnerAdminModal,
  AdminInformationModal,
  ProductItem,
  PartnerItem,
  Analytics,
  AlertMessage,
} from '@components';
import { getTopProducts, getTopPartners } from '@services';

export const DashboardAdmin: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const user: any = useSelector(selectUser);
  const products: any = useSelector(selectProducts);
  const productLoading: any = useSelector(selectProductsLoading);
  const partners: any = useSelector(selectPartners);
  const links: any = useSelector(selectlinks);

  const [token, updateToken] = useCookie('token', '0');

  const [Products, setProducts] = React.useState<any>('');
  const [Partners, setPartners] = React.useState<any>('');

  const [selectedProd, setSelected] = React.useState('');
  const [selectedPartner, setPartner] = React.useState('');

  const [totalAffiliates, setTotalAffiliates] = React.useState(0);
  const [totalClicks, setTotalClicks] = React.useState(0);
  const [totalConversion, setTotalConversion] = React.useState(0);

  const [totalRevenue, setTotalRevenue] = React.useState(0);
  const [pendingRevenue, setPendingRevenue] = React.useState(0);
  const [netRevenue, setNetRevenue] = React.useState(0);
  const [totalCommission, setTotalCommission] = React.useState(0);
  const [pendingCommission, setPendingCommission] = React.useState(0);
  const [paidCommission, setPaidCommission] = React.useState(0);

  const [createLinkOpen, setCreateLinkOpen] = React.useState(false);
  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);
  const [createProdOpen, setCreateProdOpen] = React.useState(false);
  const [createPartnerOpen, setCreatePartnerOpen] = React.useState(false);
  const [accountInfoOpen, setAccountInfoOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState('product');
  const [openModal, setOpenModal] = React.useState(false);
  const [openOverview, setOpenOverview] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const [productData, setProductData] = React.useState([]);
  const [productKeys, setProductKeys] = React.useState([]);
  const [productPie, setProductPie] = React.useState([]);

  const [partnerData, setPartnerData] = React.useState([]);
  const [partnerKeys, setPartnerKeys] = React.useState([]);
  const [partnerPie, setPartnerPie] = React.useState([]);

  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');
  const notification = query.get('notification');
  const type = query.get('type');
  const newitem = query.get('item');

  React.useEffect(() => {
    if (actionQuery === 'createlink') {
      setCreateLinkOpen(true);
    } else if (actionQuery === 'qrcode') {
      setQrCodeOpen(true);
    } else if (actionQuery === 'product') {
      // create product limit
      if (user) {
        if (!user.email_confirmed) {
          setToastTitle('Email not verified');
          setToastDesc('Please verify email to proceed');
          setToastKind('error');
          setToastOpen(true);
        } else {
          setCreateProdOpen(true);
        }
      }
    } else if (actionQuery === 'partner') {
      // create partner limit
      if (user) {
        if (!user.email_confirmed) {
          setToastTitle('Email not verified');
          setToastDesc('Please verify email to proceed');
          setToastKind('error');
          setToastOpen(true);
        } else {
          setCreatePartnerOpen(true);
        }
      }
    } else if (actionQuery === 'account') {
      setAccountInfoOpen(true);
    } else if (actionQuery === 'logout') {
      batch(async () => {
        updateToken('', 1);
        await dispatch(usersActions.logout());
        setTimeout(() => {
          navigate(`/`);
        }, 2000);
      });
    } else if (actionQuery === 'notif') {
      setPartner('');
      setSelectedTab('product');
      if (!productLoading && Products.length > 0) {
        batch(async () => {
          setProducts(products);
          Products.map((product: any) => {
            if (product.name === notification) {
              setSelected(product);
              setOpenModal(true);
              if (type === 'apply') {
                navigate('/merchant/admin_dashboard/manager?action=application');
              } else {
                navigate('/merchant/admin_dashboard/manager');
              }
            }
            return 1;
          });
        });
      }
    } else if (actionQuery === 'newitem') {
      setPartner('');
      setSelectedTab('product');
      if (!productLoading && Products.length > 0) {
        batch(async () => {
          setProducts(products);
          Products.map((product: any) => {
            if (product.name === newitem) {
              setSelected(product);
              setOpenModal(true);
              navigate('/merchant/admin_dashboard/manager?action=newassets');
            }
            return 1;
          });
        });
      }
    }
  }, [actionQuery]);

  React.useEffect(() => {
    if (actionQuery === 'notif') {
      if (!productLoading && Products.length > 0) {
        batch(async () => {
          setProducts(products);
          Products.map((product: any) => {
            if (product.name === notification) {
              setSelected(product);
              setOpenModal(true);
              navigate('/merchant/admin_dashboard/manager?action=application');
            } else {
              navigate('/merchant/admin_dashboard/manager');
            }
            return 1;
          });
        });
      }
    }
  }, [Products]);

  // closing toast, and clearing actionQuery
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if (event) {
      navigate('/merchant/admin_dashboard/manager');
      setToastOpen(false);
    }
  };

  // Reset States
  React.useEffect(() => {
    setSelected('');
    setPartner('');

    setProducts('');
    setPartners('');
  }, []);

  // Add Datas
  React.useEffect(() => {
    setProducts(products);
  }, [products]);
  React.useEffect(() => {
    setPartners(partners);
  }, [partners]);

  React.useEffect(() => {
    setTotalRevenue(user?.revenue_and_commissions?.total_revenue);
    setNetRevenue(user?.revenue_and_commissions?.net_revenue);
    setPendingRevenue(user?.revenue_and_commissions?.pending_revenue);
    setTotalCommission(user?.revenue_and_commissions?.total_commission);
    setPaidCommission(user?.revenue_and_commissions?.paid_commission);
    setPendingCommission(user?.revenue_and_commissions?.not_paid_commission);
    setTotalClicks(user?.merchant_data?.total_clicks);
    setTotalAffiliates(user?.merchant_data?.total_affiliate);
    setTotalConversion(user?.merchant_data?.total_conversions);
  }, []);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(productsActions.productListAdmin(user?.user_info?.merchant_id));
      await dispatch(partnersActions.partnersListAdmin(user?.user_info?.merchant_id));
      await dispatch(merchantsActions.merchantAnalyticsAdmin(user?.user_info?.merchant_id));

      const topProduct = await getTopProducts();
      if (topProduct.data.status === 1) {
        setProductData(topProduct.data.radar.product_data);
        setProductKeys(topProduct.data.radar.product_list);
        setProductPie(topProduct.data.pie.product_data);
      }

      const topPartner = await getTopPartners();
      if (topPartner.data.status === 1) {
        setPartnerData(topPartner.data.partner_data);
        setPartnerKeys(topPartner.data.partner_list);
        setPartnerPie(topPartner.data.partner_data2);
      }
    });
  }, []);

  const selectedItem = (item: String, ItemX: JSX.Element) => (
    <>
      {item !== '' ? (
        ItemX
      ) : (
        <Analytics
          affiliates={totalAffiliates}
          clicks={totalClicks}
          conversions={totalConversion}
          netRevenue={netRevenue}
          paidCommission={paidCommission}
          partnerData={partnerData}
          partnerKeys={partnerKeys}
          partnerPie={partnerPie}
          pendingCommission={pendingCommission}
          pendingRevenue={pendingRevenue}
          productData={productData}
          productKeys={productKeys}
          productPie={productPie}
          totalCommission={totalCommission}
          totalRevenue={totalRevenue}
          user={user}
        />
      )}
    </>
  );

  return (
    <Div className="h-full">
      <NavBarAdmin />
      <CreateLinkModal isOpen={createLinkOpen} onClose={() => setCreateLinkOpen(false)} />
      <QrCodeAdminModal isOpen={qrCodeOpen} onClose={() => setQrCodeOpen(false)} />
      <CreateProductAdminModal isOpen={createProdOpen} onClose={() => setCreateProdOpen(false)} />
      <CreatePartnerAdminModal
        isOpen={createPartnerOpen}
        onClose={() => setCreatePartnerOpen(false)}
      />
      <AdminInformationModal isOpen={accountInfoOpen} onClose={() => setAccountInfoOpen(false)} />
      <Flex className="h-full w-full pt-24">
        <span className="hidden">
          Unused Variables {token}
          {links}
        </span>
        <Toast
          description={toastDesc}
          isOpen={isToastOpen}
          onClose={handleClose}
          title={toastTitle}
          toastKind={toastKind}
        />
        {user?.email_confirmed ? (
          <></>
        ) : (
          <AlertMessage
            btnText="Go to Settings"
            linkTo="/merchant/admin_dashboard/manager/settings?action=verify"
            message={`Your email address ${user?.user_info?.email} is currently not verified. Please confirm your email address.`}
          />
        )}

        <FlexRow className="h-full w-full relative overflow-hidden">
          <Div className=" border-t-2 border-grey-300 w-full absolute top-0" />
          <div
            className="bottom-10 z-10 right-6 p-5 bg-primary-100 rounded-full font-semibold text-white
          phone:fixed tabletWide:hidden"
            onClick={() => setOpenOverview(true)}
          >
            Overview
          </div>
          <Div
            className="h-full tabletWide:w-700px phone:w-full flex flex-col bg-grey-500 pb-5 
        overflow-y-scroll scrollbar-hide"
          >
            <Tab.Group>
              <Div className="bg-blue-100">
                <Tab.List className="flex space-x-1 bg-primary-200">
                  <div className="w-full" onClick={() => setSelectedTab('product')}>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          `w-full py-6 text-sm leading-5 font-medium text-blue-700 `,
                          `focus:outline-none`,
                          selected
                            ? 'bg-orange-200 text-white shadow'
                            : 'text-white hover:bg-white/[0.12] hover:text-blue-100',
                        )
                      }
                    >
                      PRODUCTS
                    </Tab>
                  </div>
                  <div className="w-full" onClick={() => setSelectedTab('partner')}>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          `w-full py-6 text-sm leading-5 font-medium text-blue-700`,
                          `focus:outline-none`,
                          selected
                            ? 'bg-orange-200 text-white shadow'
                            : 'text-white hover:bg-white/[0.12] hover:text-blue-100',
                        )
                      }
                    >
                      PARTNERS
                    </Tab>
                  </div>
                </Tab.List>
              </Div>
              <Tab.Panels>
                <Tab.Panel>
                  <ul>
                    {Products.length === 0 ? (
                      <Div className="px-6 flex items-center flex-col w-full">
                        {user?.email_confirmed ? (
                          <Link
                            className="w-full"
                            to={`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}?action=product`}
                          >
                            <Button className="w-full justify-center bg-blue-100 mt-4">
                              CREATE PRODUCT
                            </Button>
                          </Link>
                        ) : (
                          <Link
                            className="w-full"
                            to="/merchant/admin_dashboard/manager?action=product"
                          >
                            <Button className="w-full justify-center bg-blue-100 mt-4">
                              CREATE PRODUCT
                            </Button>
                          </Link>
                        )}

                        <Text className="mt-5">You don't have any products yet.</Text>
                        <Text>Create your first product now.</Text>
                      </Div>
                    ) : (
                      Products?.map((product: any) => (
                        <ProductItem
                          available
                          key={product?.id}
                          onSelect={() => setOpenModal(true)}
                          products={product}
                          selectedProd={selectedProd}
                          setSelected={setSelected}
                        />
                      ))
                    )}
                  </ul>
                </Tab.Panel>
                <Tab.Panel>
                  <ul>
                    {Partners.length === 0 ? (
                      <Div className="px-6 flex items-center flex-col w-full">
                        {user?.email_confirmed ? (
                          <Link
                            className="w-full"
                            to="/merchant/admin_dashboard/manager?action=partner"
                          >
                            <Button className="w-full justify-center bg-blue-100 mt-4">
                              CREATE PARTNER
                            </Button>
                          </Link>
                        ) : (
                          <Link
                            className="w-full"
                            to="/merchant/admin_dashboard/manager?action=partner"
                          >
                            <Button className="w-full justify-center bg-blue-100 mt-4">
                              CREATE PARTNER
                            </Button>
                          </Link>
                        )}
                        <Text className="mt-5">You don't have any partner yet.</Text>
                        <Text>Create your first partner now.</Text>
                      </Div>
                    ) : (
                      Partners.map((partner: any) => (
                        <PartnerItem
                          key={partner?.id}
                          onSelect={() => setOpenModal(true)}
                          partners={partner}
                          selectedPartner={selectedPartner}
                          setSelected={setPartner}
                          setSelectedTab={setSelectedTab}
                        />
                      ))
                    )}
                  </ul>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Div>
          <Flex className="py-14 w-fit laptop:px-20 phone:px-10 overflow-y-scroll phone:hidden tabletWide:flex">
            {selectedTab === 'product' ? (
              selectedItem(
                selectedProd,
                <Product selectedProd={selectedProd} setSelected={setSelected} />,
              )
            ) : (
              <></>
            )}
            {selectedTab === 'partner' ? (
              selectedItem(
                selectedPartner,
                <Partners2
                  selectedPartner={selectedPartner}
                  setPartner={setPartner}
                  setSelected={setSelected}
                />,
              )
            ) : (
              <></>
            )}
          </Flex>
          <MobileModal isOpen={openModal} onClose={() => setOpenModal(false)} title={selectedTab}>
            {selectedTab === 'product' ? (
              selectedItem(
                selectedProd,
                <Product selectedProd={selectedProd} setSelected={setSelected} />,
              )
            ) : (
              <></>
            )}
            {selectedTab === 'partner' ? (
              selectedItem(
                selectedPartner,
                <Partners2
                  selectedPartner={selectedPartner}
                  setPartner={setPartner}
                  setSelected={setSelected}
                />,
              )
            ) : (
              <></>
            )}
          </MobileModal>
          <MobileModal
            isOpen={openOverview}
            onClose={() => setOpenOverview(false)}
            title="OVERVIEW"
          >
            <Analytics
              affiliates={totalAffiliates}
              clicks={totalClicks}
              conversions={totalConversion}
              netRevenue={netRevenue}
              paidCommission={paidCommission}
              partnerData={partnerData}
              partnerKeys={partnerKeys}
              partnerPie={partnerPie}
              pendingCommission={pendingCommission}
              pendingRevenue={pendingRevenue}
              productData={productData}
              productKeys={productKeys}
              productPie={productPie}
              totalCommission={totalCommission}
              totalRevenue={totalRevenue}
              user={user}
            />
          </MobileModal>
        </FlexRow>
      </Flex>
    </Div>
  );
};
