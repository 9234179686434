import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { Stage, CatalogItemV2, Pagination } from '@components';
import { Div, Flex, FlexRow, H2, Text, Icons, Button, ButtonV2 } from '@core';
import { API } from '@config';

export const AffiliatesSetup: FC<any> = () => {

  const navigate = useNavigate()
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const merchantId = query.get('merchant');
  const userId = query.get('id');
  const apply = query.get('applied');

  const [catalogsList, setcatalogsList] = React.useState([]);
  const [applied, setApplied] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(20);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const currentCatalogs = catalogsList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);
  const Submit = () => navigate('/affiliate/onboard/complete');
  const Back = () => navigate(`/affiliate/onboard/edit/aboutyou?id=${userId}&merchant=${merchantId}`)

  React.useEffect(() => {
    if((merchantId !== null || merchantId !== 'undefined') && window.location.pathname === '/affiliate/onboard/setup'){
      fetch(`${API}/merchant-available-product/${merchantId}`)
      .then(res => res.json())
      .then(data => {
        setcatalogsList(data.data)
      })
    }
  }, [merchantId])

  React.useEffect(() => {
    if(localStorage.getItem('applied')){
      setApplied(true)
    }
  }, [apply])

  return (
    <Flex className="px-12 pb-10 phone:px-2 overflow-y-scroll items-center">
      <Div className="phone:px-5 tablet:px-10 laptop:px-20 w-full">
        <Div className="mr-auto flex flex-row items-center py-8">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
        </Div>
        <hr className="text-secondary-300 laptop:mb-20 phone:mb-10" />
      </Div>
      
      <Div className="phone:px-4 phone:w-full tablet:w-3/4 laptop:w-9/12">
        <FlexRow className="phone:flex-col laptop:flex-row w-full">
          <Flex className="mr-10 phone:mr-0 phone:items-center laptop:items-start">
    
              <>
                <H2 className="text-primary-100 phone:text-center tablet:text-left">
                  Choose Your Affiliate Products
                </H2>
                <Text
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Select which products you want to promote across different platform
                </Text>
                <Text
                  className="text-secondary-100 mt-4 pr-10 phone:pr-0  
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Be ready to bang the drum and earn!
                </Text>
                <H2
                  className="font-poppins phone:hidden laptop:block font-bold text-primary-100 phone:text-center 
                phone:mb-3 tabletWide:text-left tablet:mb-0 w-full mt-8"
                >
                  MERCHANT PRODUCTS
                </H2>
              </>
    
          </Flex>

            <FlexRow
              className="mb-7  phone:mt-6 laptop:mt-0 phone:flex-col phone:space-y-4
            tablet:flex-row items-center tablet:space-y-0 w-full phone:justify-center laptop:justify-start"
            >
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent
                label="About you"
                stageNumber="1"
              />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage isCurrent label="Select Products" stageNumber="2" />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Complete"
                stageNumber="3"
              />
            </FlexRow>

        </FlexRow>
      </Div>

      <FlexRow
        className="tablet:justify-center tablet:items-center tablet:flex-row
       phone:flex-col"
      >
        <H2
          className="font-poppins font-bold text-primary-100 phone:text-center 
        phone:mb-3 phone:block laptop:hidden tabletWide:text-left tablet:mb-0 w-full mx-5 mt-8"
        >
          MERCHANT PRODUCTS
        </H2>
      </FlexRow>

      <Div className='phone:w-full laptop:w-9/12'>
      {catalogsList?.length !== 0 && merchantId !== null && merchantId !== 'undefined' ? (
        <>
          <Div
            className="grid medium:grid-cols-2 phone:grid-cols-1 tabletWide:grid-cols-3 laptop:grid-cols-4 py-10 gap-y-7"
          >
            {currentCatalogs
              ?.slice(0)
              ?.reverse()
              ?.map((item: any) => (
                <CatalogItemV2
                  category={item?.category}
                  commision={item?.commission?.replace('%', '')}
                  id={item?.id}
                  image={item?.image}
                  key={item?.id}
                  link={item?.link}
                  remaining={item?.available_affiliate || 0}
                  status={item?.status}
                  title={item?.name}
                />
              ))}
          </Div>
          {/* Pagginations */}
          <div className="flex justify-center">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={catalogsList.length}
            />
          </div>
        </>
      ) : (
        <Flex className="w-full items-center">
          <Flex className="items-center pt-32 w-500px">
            <Text className="font-semibold text-secondary-100 text-2xl mb-5">Ooops..</Text>
            <Text
              className="font-poppins font-bold text-4xl text-primary-100
      text-center mb-10"
            >
              No available <span className="text-orange-100"> products</span>.
            </Text>
            <Text className="mb-10 text-center">
              If you encounter a error please reload the page. Thank you!
            </Text>
          </Flex>
        </Flex>
      )}

      <FlexRow className='phone:justify-center medium:justify-end tablet:pr-10 tabletWide:pr-20 laptop:pr-0 desktop:pr-10'> 
      <ButtonV2
        className="bg-grey-500 hover:bg-grey-100 mt-10 mb-20 phone:w-full phone:justify-center surfaceDuo:w-32"
        onClick={Back}
      >
        Back
      </ButtonV2>
      
      {
        applied?
        <Button
          className=" mt-10 mb-20 phone:w-full phone:justify-center surfaceDuo:w-32"
          onClick={Submit}
        >
          Submit
        </Button>
        :
        <Button
          className=" mt-10 mb-20 phone:w-full phone:justify-center phone:bg-secondary-200 surfaceDuo:w-32 cursor-not-allowed"
        >
          Submit
        </Button>
      }

      </FlexRow>

      </Div>

    </Flex>
  );
};