import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Flex, Text, Div, FlexRow, ToastV4, Icons } from '@core';

interface CatalogItemProps {
  title: string;
  commision: any;
  remaining: any;
  category: string;
  id: string;
  image: string;
  status: any;
  link: string;
}

export const CatalogMerchant: FC<CatalogItemProps> = ({
  title,
  commision,
  remaining,
  id,
  image,
  category,
  status,
  link,
}) => {

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const merchantId = query.get('merchant');
  const merchantStore = query.get('merchantStore');

  const [isLoaded, setLoaded] = React.useState(false);
  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2, setToastKind2] = React.useState('success');
  const [toastTitle2, setToastTitle2] = React.useState('');
  const [toastDesc2, setToastDesc2] = React.useState('');

  const onSubmit = () => {
    setToastOpen2(true);
    setToastKind2('info');
    setToastTitle2('INFO');
    setToastDesc2(`The product ${title} is not available at this moment.`)
  }

  return (
  <>
    <ToastV4
          btnText='Cancel'
          description={toastDesc2}
          isOpen={isToastOpen2}
          link={link}
          onClick={() => setToastOpen2(false)}
          onClick2={() => setToastOpen2(false)}
          onClose={() => setToastOpen2(false)}
          submitBtnText='OK'
          title={toastTitle2}
          toastKind={toastKind2}
        />
    <Link
      className="rounded-3xl w-72 justify-self-center bg-primary-100 shadow-lg cursor-pointer 
    border-4 border-white hover:border-4 hover:border-orange-100 duration-500 flex flex-col justify-center overflow-hidden"
      to={`${
        (!status && status !== 2 && status !== '2')
          ? `/affiliate/catalog/details?productid=${id}&merchant=${merchantId}&merchantStore=${merchantStore}`
          : `/affiliate/merchant_catalogs?merchant=${merchantId}&merchantStore=${merchantStore}`
        }`}
    >
    <div onClick={onSubmit}>
      <Flex>
        <Div className="bg-primary-300 h-52">
          <img
            alt="Pic"
            className={`w-full h-full ${title === 'Prosperna myChat' ? 'object-contain' : 'object-cover'}`}
            onError={() => setLoaded(true)}
            src={isLoaded ? Icons.xffiliate2 : image}
          />
        </Div>
        <Div className="px-5 pt-4 pb-2 h-36 flex flex-col">
          <Text className="text-base text-blue-100">{category}</Text>
          <Text className="font-bold h-full text-lg text-white">{title}</Text>
          <FlexRow className="h-full">
            <Text className="font-semibold text-white mr-auto ">
              Earn <span className="text-2xl text-orange-100 font-bold">{commision}%</span>
            </Text>
            {
              status?
              <Text className="font-semibold text-red-400">Limit Reached</Text>
              :
              <Text className="text-white">{remaining} Affiliates</Text>
            }
          </FlexRow>
        </Div>
      </Flex>
    </div>
    </Link>
  </>
  );
};