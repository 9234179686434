import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

export const getProducts = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-product`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProductsAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-admin-products/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProduct = async (id: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-single-product/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAllProducts = async () =>
  axios.get(`${API}/get-all-products`).then((response) => response);

export const getProductPaggination = async (page: number) =>
  axios.get(`${API}/product-pagination/${page}`).then((response) => response);

export const getProductImage = async (id: any) =>
  axios.get(`${API}/share-product/${id}`).then((response) => response);

export const getProductAffiliates = async (id: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliates/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProductApplicants = async (id: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-applicants/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProductOfMerchantFromAdmin = async (id: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/admin-get-merchant-products-by-merchant-id/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProductOfAffiliateFromAdmin = async (id: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/admin-get-affiliate-products-by-id/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};