import React, { FC } from 'react';
import { FlexRow } from '@core';

interface ButtonProps {
  containerClass?: string;
  props?: any;
  onClick?: any;
  disabled?: boolean;
  checked: boolean;
  labelClass?: string;
  toggleClass?: string;
}

export const ToggleButton: FC<ButtonProps> = ({
  containerClass,
  children,
  props,
  onClick,
  disabled,
  checked,
  labelClass,
  toggleClass,
}) => (
  <FlexRow className={`${containerClass} hover:bg-grey-500 px-2 py-2 laptop:px-4 rounded-lg`} {...props}>
    <div className={`${labelClass} text-sm surfaceDuo:text-base`}>
      {children}
    </div>
    <div className={`${toggleClass} ${disabled? 'cursor-not-allowed' : ''} text-sm surfaceDuo:text-base`}>
      <label className="switch">
        <input checked={checked} disabled={disabled} onClick={onClick} type="checkbox" />
        <span className="slider round my-auto" />
      </label>
    </div>
  </FlexRow>
);