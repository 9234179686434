interface APIGRAPH {
  status: number;
  data: {
    graph1: {
      revenue: {
        date_created: string;
        clicked: number;
      }[];
      commission: {
        date_created: string;
        clicked: number;
      }[];
    };
    graph2: {
      clicks: {
        date_created: string;
        clicked: number;
      }[];
      conversion: {
        date_created: string;
        clicked: number;
      }[];
    };
  };
}

const dates: any[] = [];

function convertGraphData(data: any[]) {
  const convData: any = [];
  data.map((entry) => {
    dates.push(entry.date_created)
    return convData.push({
      x: entry.date_created,
      y: entry[Object.keys(entry)[1]],
    });
  });
  return convData;
}

export const convertedGraph1 = (graphData: APIGRAPH) => {
  if (graphData.status !== 0) {
    if (
      graphData.data.graph1.commission.length !== 0 ||
      graphData.data.graph1.revenue.length !== 0
    ) {
      return [
        {
          id: 'Revenue',
          data: convertGraphData(graphData.data.graph1.revenue),
        },
        {
          id: 'Commission',
          data: convertGraphData(graphData.data.graph1.commission),
        },
      ];
    }
    return [];
  }
  return [];
};

export const convertedGraph2 = (graphData: APIGRAPH) => {
  if (graphData.status !== 0) {
    if (
      graphData.data.graph2.clicks.length !== 0 ||
      graphData.data.graph2.conversion.length !== 0
    ) {
      return [
        {
          id: 'Clicks',
          data: convertGraphData(graphData.data.graph2.clicks),
        },
        {
          id: 'Conversions',
          data: convertGraphData(graphData.data.graph2.conversion),
        },
      ];
    }
    return [];
  }
  return [];
};