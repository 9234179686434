import React, { FC } from 'react';

interface ButtonProps {
  className?: string;
  props?: any;
  onClick?: Function;
  type?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
  className,
  children,
  props,
  onClick,
  type,
  isLoading,
  disabled,
}) => (
  <button
    className={` text-white bg-primary-100 hover:bg-opacity-80 focus:ring-2 focus:ring-secondary-300 
    font-poppins font-regular rounded-lg px-5 py-4 text-center
     inline-flex items-center laptop:text-lg phone:text-sm tablet:text-base mr-2 ${className}`}
    disabled={disabled || false}
    type={type || 'button'}
    {...props}
    onClick={onClick}
  >
    {isLoading ? <i className="fad fa-spinner-third animate-spin text-2xl" /> : children}
  </button>
);
