import React,{ FC } from 'react';
import { useSelector, batch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Pie } from "@nivo/pie";
import { ResponsiveLine } from '@nivo/line';
import { MobileModal } from './MobileModal';
import { selectProducts, selectUser, productsActions, useAppDispatch } from '@redux';
import { Div, FlexRow, FlexCenter, Flex, Button, Text, Select, SelectV3 } from '@core';
import { NavBarAdmin, ProductItemV2, ConversionItem } from '@components'
import { useWindowSize } from '@hooks';
import { getOverallConversions, getConversionRate } from '@services';

export const ConversionReportsAdmin: FC<any> = () => {
	
	const user = useSelector(selectUser);
	const dispatch = useAppDispatch();
	const products = useSelector(selectProducts);
	const dimensions = useWindowSize();

	const [productList, setProductList] = React.useState(products);

	const [selectedProd, setSelected] = React.useState({name: 'Product'});
	const [totalClicks, setTotalClicks] = React.useState(0);
	const [totalConversion, setTotalConversion] = React.useState(0);
	const [totalLeads, setTotalLeads] = React.useState(0);
	const [totalServiceConversion, setTotalServiceConversion] = React.useState(0);
	const [conversionRate, setConversionRate] = React.useState(0);
	const [conversionServiceRate, setConversionServiceRate] = React.useState(0);
	const [selectedChart, setSelectedChart] = React.useState<any>('');

	const [openModal, setOpenModal] = React.useState(false);
	const [openOverview, setOpenOverview] = React.useState(false);
	const [isShow, setIsShow] = React.useState(false);

	const [conversionRateService, setConversionRateService] = React.useState<any>([]);
	const [conversionRateProduct, setConversionRateProduct] = React.useState<any>([]);

	const conversiontheme = {
	  background: '#F5F6F7',
	  textColor: '#9C9C9C',
	  fontFamily: 'Poppins',
	  fontSize: 14,
	  legends: {
	    title: {
	      text: {
	        fontSize: 14,
	        fill: '#333333',
	      },
	    },
	    text: {
	      fontSize: 14,
	      fill: '#333333',
	    },
	    ticks: {
	      line: {},
	      text: {
	        fontSize: 14,
	        fill: '#333333',
	      },
	    },
	  },
	};

	const serviceData = [
	    {
	        "id": "Leads",
	        "label": "Leads",
	        "value": totalLeads,
	    },
	    {
	        "id": "Sales",
	        "label": "Sales",
	        "value": totalServiceConversion,
	    },
	]

	const productData = [
	    {
	        "id": "Clicks",
	        "label": "Clicks",
	        "value": totalClicks,
	    },
	    {
	        "id": "Sales",
	        "label": "Sales",
	        "value": totalConversion,
	    },
	]

	const commonProperties = {
	  height: 400,
	  margin: { top: 50, right: 50, bottom: 75, left: 50 },
	};

	React.useEffect(() => {
	  setIsShow(false)
	  batch(async () => {
	    const convRate: any = await getConversionRate();
	    if(convRate){
	      setConversionRateProduct(convRate.data.product_rate)
	      setConversionRateService(convRate.data.service_rate)
	      if(convRate.data.product_rate.length > 0 && convRate.data.service_rate.length > 0){
	      	setSelectedChart('')
	      }else if(convRate.data.product_rate.length === 0 && convRate.data.service_rate.length > 0){
	      	setSelectedChart('Service')
	      }else if(convRate.data.product_rate.length > 0 && convRate.data.service_rate.length === 0){
	      	setSelectedChart('Product')
	      }else{
	      	setSelectedChart('')
	      }
	    }
	    const convData: any = await getOverallConversions();
	    if(convData){
	      setTotalClicks(convData.data.products.product_clicks);
	      setTotalConversion(convData.data.products.product_conversion);
	      setTotalLeads(convData.data.services.service_leads);
	      setTotalServiceConversion(convData.data.services.service_conversion);
	    }
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1000)
	  });
	}, []);

	React.useEffect(() => {
		setTimeout(() => {
			setConversionRate((totalConversion/totalClicks)*100)
			setConversionServiceRate((totalServiceConversion/totalLeads)*100)
		}, 3000)
	})

	React.useEffect(() => {
		batch(async () => {
		  const data:any = await dispatch(
		  						productsActions.productListAdmin(user?.user_info?.merchant_id)
		  					);
		  setProductList(data?.payload?.data)
		});
	}, []);

return(
	<>
	<NavBarAdmin />
	<FlexRow className='w-full h-full pt-24'>
		<div
		  className="bottom-10 z-10 right-6 p-5 bg-primary-100 rounded-full font-semibold text-white
		phone:fixed tablet:hidden"
		  onClick={() => setOpenOverview(true)}
		>
		  Overview
		</div>

		<Div className='phone:w-full tablet:block tablet:w-auto h-full border-r border-grey-400'>
			<Div className='flex flex-row justify-center items-center bg-primary-100 h-20 text-center text-white font-poppins font-bold tabletWide:px-32 laptop:px-44'>
				PRODUCTS
			</Div>
			<Div className='overflow-y-scroll scrollbar-hide h-full pb-96'>
			<ul>
			{productList.length === 0 ? (
			  <Div className="px-6 flex items-center flex-col w-full">
			    <Link className="w-full" to="/merchant/dashboard?action=product">
			      <Button className="w-full justify-center bg-blue-100 mt-4">
			        CREATE PRODUCT
			      </Button>
			    </Link>
			    <Text className="mt-5">You don't have any products yet.</Text>
			    <Text>Create your first product now.</Text>
			  </Div>
			) : (
			  productList?.map((product: any) => (
			      <ProductItemV2
			        available
			        key={product?.id}
			        onSelect={() => setOpenModal(true)}
			        products={product}
			        selectedProd={selectedProd}
			        setSelected={setSelected}
			      />
			    )
			  )
			)}
			</ul>
			</Div>
		</Div>

		{
			selectedProd.name === 'Product' ?
			<Div className='phone:hidden tablet:block laptop:px-8 w-full h-full overflow-y-scroll'>

				<Link
				  className="text-grey-100 hover:text-grey-200 phone:mt-4 tablet:mt-8 mb-4 ml-8 block"
				  to={`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}`}
				>
				  <Text className="items-center flex laptop:text-lg phone:base mr-3">
				    <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to Dashboard
				  </Text>
				</Link>
				
				<Text className="font-poppins font-bold text-2xl text-primary-100 phone:mt-4 tablet:mt-8 mb-4 ml-8">
				 	SALES DATA
				</Text>
				<hr className="text-grey-400 mb-10 ml-8 mr-8 hidden" />

				<FlexRow className='px-8 w-full phone:flex-col tabletWide:flex-row'>
					<Div className='w-full laptop:w-500px tabletWide:w-1/2 tabletWide:mr-2 bg-grey-500 rounded-2xl px-2 pt-2 pb-6 my-2'>
					<Text className='text-secondary-200 my-4 tabletWide:text-center laptop:text-left'>
						Conversion Data for Service
					</Text>	
					<FlexRow className='justify-around w-full tabletWide:flex-col laptop:flex-row'>
						<Div className='relative text-center'>
						{
							totalServiceConversion === 0 && totalLeads === 0 ?
							<div className='h-24 w-24 border-grey-400 border-8 rounded-full' />
							:
							<Pie
							    activeInnerRadiusOffset={8}
							    colors={["#0C61B0", "#FF6C3E"]}
							    cornerRadius={3}
							    data={serviceData}
							    enableArcLabels={false}
							    enableArcLinkLabels={false}
							    height={100}
							    innerRadius={0.7}
							    padAngle={0.7}
							    width={100}
							/>
						}
						<Text className='absolute top-1/3 text-lg text-primary-100 font-bold text-center w-full'>
							{conversionServiceRate > 0 ? Math.round(conversionServiceRate) : 0.00}%
						</Text>
						</Div>
						<FlexRow className='items-center justify-between mt-4'>
						  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-24">
						    {totalLeads}
						    <span className='text-secondary-200 text-sm font-normal'>LEADS</span>
						  </Text>
						  <div className='h-12 border-l-2 border-grey-400' />
						  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-28">
						    {totalServiceConversion}
						    <span className='text-secondary-200 text-sm font-normal'>SALES</span>
						  </Text>
						</FlexRow>
					</FlexRow>
					</Div>
					<Div className='w-full laptop:w-500px tabletWide:w-1/2 tabletWide:ml-2 bg-grey-500 rounded-2xl px-2 pt-2 pb-6 my-2'>
					<Text className='text-secondary-200 my-4 tabletWide:text-center laptop:text-left'>
						Conversion Data for Product
					</Text>	
					<FlexRow className='justify-around w-full tabletWide:flex-col laptop:flex-row'>
						<Div className='relative text-center'>
						{
							totalConversion === 0 && totalClicks === 0 ?
							<div className='h-24 w-24 border-grey-400 border-8 rounded-full' />
							:
							<Pie
							    activeInnerRadiusOffset={8}
							    colors={["#0C61B0", "#FF6C3E"]}
							    cornerRadius={3}
							    data={productData}
							    enableArcLabels={false}
							    enableArcLinkLabels={false}
							    height={100}
							    innerRadius={0.7}
							    padAngle={0.7}
							    width={100}
							/>
						}
						<Text className='absolute top-1/3 text-lg text-primary-100 font-bold text-center w-full'>
							{conversionRate > 0 ? Math.round(conversionRate) : 0.00}%
						</Text>
						</Div>
						<FlexRow className='items-center justify-between mt-4'>
						  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-24">
						    {totalClicks}
						    <span className='text-secondary-200 text-sm font-normal'>CLICKS</span>
						  </Text>
						  <div className='h-12 border-l-2 border-grey-400' />
						  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-28">
						    {totalConversion}
						    <span className='text-secondary-200 text-sm font-normal'>SALES</span>
						  </Text>
						</FlexRow>
					</FlexRow>
					</Div>
				</FlexRow>

				<Div className='ml-8 mb-60'>

				<Text className="font-poppins font-bold text-2xl text-primary-100 phone:mt-4 tablet:mt-8 mb-4">
				 	CONVERSION RATE
				</Text>

				<FlexRow>
				  <Text className='text-primary-100 font-semibold mr-4 phone:ml-4 tabletWide:ml-0'>
				    Chart:
				  </Text>
				  {
				  	conversionRateProduct.length > 0 && conversionRateService.length > 0 ?
				  	<SelectV3
				  	  className='mr-12 cursor-pointer'
				  	  label=""
				  	  labelHead1="Single Charts"
				  	  labelHead2="Compare Charts"
				  	  name="selectedMethod"
				  	  onChange={setSelectedChart}
				  	  options={['Product', 'Service']}
				  	  options2={['-- SHOW ALL --']}
				  	  placeholder="Choose..."
				  	  value={selectedChart}
				  	/>
				  	:
				  	<Select
				  	  className='phone:w-60'
				  	  label=""
				  	  name="selectedMethod"
				  	  onChange={setSelectedChart}
				  	  options={['Product', 'Service']}
				  	  placeholder="Choose..."
				  	  value={selectedChart}
				  	/>
				  }
				</FlexRow>

				<div className={`w-full h-80 my-8 ${selectedChart === 'Product'? '' : 'hidden'}`}>
					<Text className='mb-4'>Rate by Product</Text>
				{
					conversionRateProduct.length > 0 && isShow?
					<ResponsiveLine
					           axisBottom={{
					            format: '%b %d',
					            tickPadding: 5,
					            tickRotation: 67,
					            tickValues: dimensions.width > 1000 ? 12 : 5,
					           }}
					           axisLeft={{
					             tickValues: 5,
					             tickPadding: 10,
					             format: (text) => Number(text),
					           }}
					           {...commonProperties}
					           colors={['#FF733F']}
					           curve="monotoneX"
					           data={[
					               {
					                   id: '% by Product',
					                   data: conversionRateProduct,
					               },
					           ]}
					           enableArea
					           enableGridX={false}
					           enableGridY={false}
					           enableSlices="x"
					           legends={[
					             {
					               anchor: 'top-left',
					               direction: 'row',
					               justify: false,
					               translateY: -40,
					               itemDirection: 'left-to-right',
					               itemWidth: 140,
					               itemHeight: 20,
					               symbolSize: 12,
					               symbolShape: 'square',
					               effects: [
					                 {
					                   on: 'hover',
					                   style: {
					                     itemBackground: 'rgba(0, 0, 0, .03)',
					                     itemOpacity: 1,
					                   },
					                 },
					               ],
					               toggleSerie: false,
					             },
					           ]}
					           sliceTooltip={
					             // eslint-disable-next-line react/no-unstable-nested-components
					             ({ slice }) => (
					               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
					                 {slice.points.map((point, i) => (
					                   <Div key={point.id}>
					                     <FlexCenter className="text-xs font-medium">
					                       {i === 0 ? point.data.xFormatted : ''}
					                     </FlexCenter>
					                     <Div className="flex flex-row pt-1">
					                       <Flex className="text-grey-700 text-sm">
					                         Rate at
					                       </Flex>
					                       <Flex className="text-right text-sm font-medium">
					                         {point.data.y.toLocaleString()}%
					                       </Flex>
					                     </Div>
					                   </Div>
					                 ))}
					               </Div>
					             )
					           }
					           theme={conversiontheme}
					           xFormat="time:%m/%d/%Y"
					           xScale={{
					               type: 'time',
					               format: '%m/%d/%Y',
					           }}
					           yScale={{
					             type: 'linear',
					           }}
					       />
				    :
				    <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
				    	<span className='text-center text-sm tablet:text-base'>No data recorded at this time.</span>
				    	<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
				    		<Text>10 -</Text>
				    		<Text>8 -</Text>
				    		<Text>6 -</Text>
				    		<Text>4 -</Text>
				    		<Text>2 -</Text>
				    		<Text>0 -</Text>
				    	</Flex>
				    </FlexRow>
				}
				</div>

				<div className={`w-full h-80 my-8 ${selectedChart === 'Service'? '' : 'hidden'}`}>
					<Text className='mb-4'>Rate by Service</Text>
				{
					conversionRateService.length > 0 && isShow?
					<ResponsiveLine
					           axisBottom={{
					            format: '%b %d',
					            tickPadding: 5,
					            tickRotation: 67,
					            tickValues: dimensions.width > 1000 ? 12 : 5,
					           }}
					           axisLeft={{
					             tickValues: 5,
					             tickPadding: 10,
					             format: (text) => Number(text),
					           }}
					           {...commonProperties}
					           colors={['#073763']}
					           curve="monotoneX"
					           data={[
					               {
					                   id: '% by Service',
					                   data: conversionRateService,
					               },
					           ]}
					           enableArea
					           enableGridX={false}
					           enableGridY={false}
					           enableSlices="x"
					           legends={[
					             {
					               anchor: 'top-left',
					               direction: 'row',
					               justify: false,
					               translateY: -40,
					               itemDirection: 'left-to-right',
					               itemWidth: 140,
					               itemHeight: 20,
					               symbolSize: 12,
					               symbolShape: 'square',
					               effects: [
					                 {
					                   on: 'hover',
					                   style: {
					                     itemBackground: 'rgba(0, 0, 0, .03)',
					                     itemOpacity: 1,
					                   },
					                 },
					               ],
					               toggleSerie: false,
					             },
					           ]}
					           sliceTooltip={
					             // eslint-disable-next-line react/no-unstable-nested-components
					             ({ slice }) => (
					               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
					                 {slice.points.map((point, i) => (
					                   <Div key={point.id}>
					                     <FlexCenter className="text-xs font-medium">
					                       {i === 0 ? point.data.xFormatted : ''}
					                     </FlexCenter>
					                     <Div className="flex flex-row pt-1">
					                       <Flex className="text-grey-700 text-sm">
					                         Rate at
					                       </Flex>
					                       <Flex className="text-right text-sm font-medium">
					                         {point.data.y.toLocaleString()}%
					                       </Flex>
					                     </Div>
					                   </Div>
					                 ))}
					               </Div>
					             )
					           }
					           theme={conversiontheme}
					           xFormat="time:%m/%d/%Y"
					           xScale={{
					               type: 'time',
					               format: '%m/%d/%Y',
					           }}
					           yScale={{
					             type: 'linear',
					           }}
					       />
				    :
				    <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
				    	<span className='text-center text-sm tablet:text-base'>No data recorded at this time.</span>
				    	<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
				    		<Text>10 -</Text>
				    		<Text>8 -</Text>
				    		<Text>6 -</Text>
				    		<Text>4 -</Text>
				    		<Text>2 -</Text>
				    		<Text>0 -</Text>
				    	</Flex>
				    </FlexRow>
				}
				</div>

				<div className={`w-full h-80 my-8 ${selectedChart === '' || selectedChart === '-- SHOW ALL --'? '' : 'hidden'}`}>
				{
					( conversionRateProduct.length > 0 && conversionRateService.length > 0 ) && isShow?
					<ResponsiveLine
					           axisBottom={{
					            format: '%b %d',
					            tickPadding: 5,
					            tickRotation: 67,
					            tickValues: dimensions.width > 1000 ? 12 : 5,
					           }}
					           axisLeft={{
					             tickValues: 5,
					             tickPadding: 10,
					             format: (text) => Number(text),
					           }}
					           {...commonProperties}
					           colors={['#073763','#FF733F']}
					           curve="monotoneX"
					           data={[
					           	   {
					                   id: '% by Service',
					                   data: conversionRateService,
					               },
					               {
					                   id: '% by Product',
					                   data: conversionRateProduct,
					               },
					           ]}
					           enableArea
					           enableGridX={false}
					           enableGridY={false}
					           enableSlices="x"
					           legends={[
					             {
					               anchor: 'top-left',
					               direction: 'row',
					               justify: false,
					               translateY: -40,
					               itemDirection: 'left-to-right',
					               itemWidth: 140,
					               itemHeight: 20,
					               symbolSize: 12,
					               symbolShape: 'square',
					               effects: [
					                 {
					                   on: 'hover',
					                   style: {
					                     itemBackground: 'rgba(0, 0, 0, .03)',
					                     itemOpacity: 1,
					                   },
					                 },
					               ],
					               toggleSerie: false,
					             },
					           ]}
					           sliceTooltip={
					             // eslint-disable-next-line react/no-unstable-nested-components
					             ({ slice }) => (
					               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
					                 {slice.points.map((point, i) => (
					                   <Div key={point.id}>
					                     <FlexCenter className="text-xs font-medium">
					                       {i === 0 ? point.data.xFormatted : ''}
					                     </FlexCenter>
					                     <Div className="flex flex-row pt-1">
					                       <Flex className="text-grey-700 text-sm">
					                         Rate at
					                       </Flex>
					                       <Flex className="text-right text-sm font-medium">
					                         {point.data.y.toLocaleString()}%
					                       </Flex>
					                     </Div>
					                   </Div>
					                 ))}
					               </Div>
					             )
					           }
					           theme={conversiontheme}
					           xFormat="time:%m/%d/%Y"
					           xScale={{
					               type: 'time',
					               format: '%m/%d/%Y',
					           }}
					           yScale={{
					             type: 'linear',
					           }}
					       />
				    :
				    <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
				    	<span className='text-center text-sm tablet:text-base'>No data recorded at this time.</span>
				    	<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
				    		<Text>10 -</Text>
				    		<Text>8 -</Text>
				    		<Text>6 -</Text>
				    		<Text>4 -</Text>
				    		<Text>2 -</Text>
				    		<Text>0 -</Text>
				    	</Flex>
				    </FlexRow>
				}
				</div>

				</Div>

			</Div>
			:
			<Div className='phone:hidden tablet:block laptop:px-8 w-full h-full overflow-y-scroll'>
				<ConversionItem product={selectedProd} setSelected={setSelected} />
			</Div>
		}

		<MobileModal isOpen={openModal} onClose={() => setOpenModal(false)} title='PRODUCTS'>
		  {selectedProd.name !== 'Product' ?
		    <Div className='phone:block tablet:hidden w-full h-full overflow-y-scroll'>
		    	<ConversionItem product={selectedProd} setSelected={setSelected} />
		    </Div>
		 	: 
		    <></>
		  }
		</MobileModal>
		<MobileModal
		  isOpen={openOverview}
		  onClose={() => setOpenOverview(false)}
		  title="OVERVIEW"
		>
		  <Div className='phone:block tablet:hidden w-full h-full overflow-y-scroll scrollbar-hide'>
		  	
		  	<Link
		  	  className="text-grey-100 hover:text-grey-200 phone:mt-4 tablet:mt-8 mb-4 block"
		  	  to={`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}`}
		  	>
		  	  <Text className="items-center flex laptop:text-lg phone:base mr-3">
		  	    <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to Dashboard
		  	  </Text>
		  	</Link>

		  	<Text className="font-poppins font-bold text-2xl phone:mt-4 tablet:mt-8 mb-4">
		  	  SALES DATA
		  	</Text>
		  	<hr className="text-grey-400 mb-10 hidden" />

		  	<FlexRow className='w-full phone:flex-col laptop:flex-row items-start justify-start'>
		  		<Div className='w-full laptop:w-500px laptop:w-1/3 laptop:mr-2 bg-grey-500 rounded-2xl p-2 my-2'>
		  		<Text className='text-secondary-200 my-4'>
		  			Conversion Data for Service
		  		</Text>	
		  		<FlexRow className='justify-around w-full flex-col surfaceDuo:flex-row'>
		  			<Div className='relative text-center'>
		  			{
		  				totalServiceConversion === 0 && totalLeads === 0 ?
		  				<div className='h-24 w-24 border-grey-400 border-8 rounded-full' />
		  				:
		  				<Pie
		  				    activeInnerRadiusOffset={8}
		  				    colors={["#0C61B0", "#FF6C3E"]}
		  				    cornerRadius={3}
		  				    data={serviceData}
		  				    enableArcLabels={false}
		  				    enableArcLinkLabels={false}
		  				    height={100}
		  				    innerRadius={0.7}
		  				    padAngle={0.7}
		  				    width={100}
		  				/>
		  			}
		  			<Text className='absolute top-1/3 text-primary-100 font-bold text-center w-full'>
		  				{conversionServiceRate > 0 ? Math.round(conversionServiceRate) : 0.00}%
		  			</Text>
		  			</Div>
		  			<FlexRow className='items-center justify-between mt-4'>
		  			  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-32">
		  			    {totalLeads}
		  			    <span className='text-secondary-200 text-sm font-normal'>LEADS</span>
		  			  </Text>
		  			  <div className='h-12 border-l-2 border-grey-400' />
		  			  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-32">
		  			    {totalServiceConversion}
		  			    <span className='text-secondary-200 text-sm font-normal'>SALES</span>
		  			  </Text>
		  			</FlexRow>
		  		</FlexRow>
		  		</Div>
		  		<Div className='w-full laptop:w-500px laptop:w-1/3 laptop:mx-2 bg-grey-500 rounded-2xl p-2 my-2'>
		  		<Text className='text-secondary-200 my-4'>
		  			Conversion Data for Product
		  		</Text>	
		  		<FlexRow className='justify-around w-full flex-col surfaceDuo:flex-row'>
		  			<Div className='relative text-center'>
		  			{
		  				totalConversion === 0 && totalClicks === 0 ?
		  				<div className='h-24 w-24 border-grey-400 border-8 rounded-full' />
		  				:
		  				<Pie
		  				    activeInnerRadiusOffset={8}
		  				    colors={["#0C61B0", "#FF6C3E"]}
		  				    cornerRadius={3}
		  				    data={productData}
		  				    enableArcLabels={false}
		  				    enableArcLinkLabels={false}
		  				    height={100}
		  				    innerRadius={0.7}
		  				    padAngle={0.7}
		  				    width={100}
		  				/>
		  			}
		  			<Text className='absolute top-1/3 text-primary-100 font-bold text-center w-full'>
		  				{conversionRate > 0 ? Math.round(conversionRate) : 0.00}%
		  			</Text>
		  			</Div>
		  			<FlexRow className='items-center justify-between mt-4'>
		  			  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-32">
		  			    {totalClicks}
		  			    <span className='text-secondary-200 text-sm font-normal'>CLICKS</span>
		  			  </Text>
		  			  <div className='h-12 border-l-2 border-grey-400' />
		  			  <Text className="flex flex-col items-center text-primary-100 laptop:text-3xl phone:text-2xl font-bold w-32">
		  			    {totalConversion}
		  			    <span className='text-secondary-200 text-sm font-normal'>SALES</span>
		  			  </Text>
		  			</FlexRow>
		  		</FlexRow>
		  		</Div>
		  	</FlexRow>

		  	<Div className='mb-60'>

		  	<Text className="font-poppins font-bold text-2xl text-primary-100 phone:mt-4 tablet:mt-8 mb-4">
		  	 	CONVERSION RATE
		  	</Text>

		  	<FlexRow>
		  	  <Text className='text-primary-100 font-semibold mr-4 phone:ml-4 tabletWide:ml-0'>
		  	    Chart:
		  	  </Text>
		  	  {
				  	conversionRateProduct.length > 0 && conversionRateService.length > 0 ?
				  	<SelectV3
				  	  className='mr-12 cursor-pointer'
				  	  label=""
				  	  labelHead1="Single Charts"
				  	  labelHead2="Compare Charts"
				  	  name="selectedMethod"
				  	  onChange={setSelectedChart}
				  	  options={['Product', 'Service']}
				  	  options2={['-- SHOW ALL --']}
				  	  placeholder="Choose..."
				  	  value={selectedChart}
				  	/>
				  	:
				  	<Select
				  	  className='phone:w-60'
				  	  label=""
				  	  name="selectedMethod"
				  	  onChange={setSelectedChart}
				  	  options={['Product', 'Service']}
				  	  placeholder="Choose..."
				  	  value={selectedChart}
				  	/>
			  }
		  	</FlexRow>

		  	<div className={`w-full h-80 my-8 ${selectedChart === 'Product'? '' : 'hidden'}`}>
		  		<Text className='mb-4'>Rate by Product</Text>
		  	{
		  		conversionRateProduct.length > 0 && isShow?
		  		<ResponsiveLine
		  		           axisBottom={{
		  		            format: '%b %d',
		  		            tickPadding: 5,
		  		            tickRotation: 67,
		  		            tickValues: dimensions.width > 1000 ? 12 : 5,
		  		           }}
		  		           axisLeft={{
		  		             tickValues: 5,
		  		             tickPadding: 10,
		  		             format: (text) => Number(text),
		  		           }}
		  		           {...commonProperties}
		  		           colors={['#FF733F']}
		  		           curve="monotoneX"
		  		           data={[
		  		               {
		  		                   id: '% by Product',
		  		                   data: conversionRateProduct,
		  		               },
		  		           ]}
		  		           enableArea
		  		           enableGridX={false}
		  		           enableGridY={false}
		  		           enableSlices="x"
		  		           legends={[
		  		             {
		  		               anchor: 'top-left',
		  		               direction: 'row',
		  		               justify: false,
		  		               translateY: -40,
		  		               itemDirection: 'left-to-right',
		  		               itemWidth: 140,
		  		               itemHeight: 20,
		  		               symbolSize: 12,
		  		               symbolShape: 'square',
		  		               effects: [
		  		                 {
		  		                   on: 'hover',
		  		                   style: {
		  		                     itemBackground: 'rgba(0, 0, 0, .03)',
		  		                     itemOpacity: 1,
		  		                   },
		  		                 },
		  		               ],
		  		               toggleSerie: false,
		  		             },
		  		           ]}
		  		           sliceTooltip={
		  		             // eslint-disable-next-line react/no-unstable-nested-components
		  		             ({ slice }) => (
		  		               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
		  		                 {slice.points.map((point, i) => (
		  		                   <Div key={point.id}>
		  		                     <FlexCenter className="text-xs font-medium">
		  		                       {i === 0 ? point.data.xFormatted : ''}
		  		                     </FlexCenter>
		  		                     <Div className="flex flex-row pt-1">
		  		                       <Flex className="text-grey-700 text-sm">
		  		                         Rate at
		  		                       </Flex>
		  		                       <Flex className="text-right text-sm font-medium">
		  		                         {point.data.y.toLocaleString()}%
		  		                       </Flex>
		  		                     </Div>
		  		                   </Div>
		  		                 ))}
		  		               </Div>
		  		             )
		  		           }
		  		           theme={conversiontheme}
		  		           xFormat="time:%m/%d/%Y"
		  		           xScale={{
		  		               type: 'time',
		  		               format: '%m/%d/%Y',
		  		           }}
		  		           yScale={{
		  		             type: 'linear',
		  		           }}
		  		       />
		  	    :
		  	    <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
		  	    	<span className='text-center text-sm tablet:text-base'>No data recorded at this time.</span>
		  	    	<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
		  	    		<Text>10 -</Text>
		  	    		<Text>8 -</Text>
		  	    		<Text>6 -</Text>
		  	    		<Text>4 -</Text>
		  	    		<Text>2 -</Text>
		  	    		<Text>0 -</Text>
		  	    	</Flex>
		  	    </FlexRow>
		  	}
		  	</div>

		  	<div className={`w-full h-80 my-8 ${selectedChart === 'Service'? '' : 'hidden'}`}>
		  		<Text className='mb-4'>Rate by Service</Text>
		  	{
		  		conversionRateService.length > 0 && isShow?
		  		<ResponsiveLine
		  		           axisBottom={{
		  		            format: '%b %d',
		  		            tickPadding: 5,
		  		            tickRotation: 67,
		  		            tickValues: dimensions.width > 1000 ? 12 : 5,
		  		           }}
		  		           axisLeft={{
		  		             tickValues: 5,
		  		             tickPadding: 10,
		  		             format: (text) => Number(text),
		  		           }}
		  		           {...commonProperties}
		  		           colors={['#073763']}
		  		           curve="monotoneX"
		  		           data={[
		  		               {
		  		                   id: '% by Service',
		  		                   data: conversionRateService,
		  		               },
		  		           ]}
		  		           enableArea
		  		           enableGridX={false}
		  		           enableGridY={false}
		  		           enableSlices="x"
		  		           legends={[
		  		             {
		  		               anchor: 'top-left',
		  		               direction: 'row',
		  		               justify: false,
		  		               translateY: -40,
		  		               itemDirection: 'left-to-right',
		  		               itemWidth: 140,
		  		               itemHeight: 20,
		  		               symbolSize: 12,
		  		               symbolShape: 'square',
		  		               effects: [
		  		                 {
		  		                   on: 'hover',
		  		                   style: {
		  		                     itemBackground: 'rgba(0, 0, 0, .03)',
		  		                     itemOpacity: 1,
		  		                   },
		  		                 },
		  		               ],
		  		               toggleSerie: false,
		  		             },
		  		           ]}
		  		           sliceTooltip={
		  		             // eslint-disable-next-line react/no-unstable-nested-components
		  		             ({ slice }) => (
		  		               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
		  		                 {slice.points.map((point, i) => (
		  		                   <Div key={point.id}>
		  		                     <FlexCenter className="text-xs font-medium">
		  		                       {i === 0 ? point.data.xFormatted : ''}
		  		                     </FlexCenter>
		  		                     <Div className="flex flex-row pt-1">
		  		                       <Flex className="text-grey-700 text-sm">
		  		                         Rate at
		  		                       </Flex>
		  		                       <Flex className="text-right text-sm font-medium">
		  		                         {point.data.y.toLocaleString()}%
		  		                       </Flex>
		  		                     </Div>
		  		                   </Div>
		  		                 ))}
		  		               </Div>
		  		             )
		  		           }
		  		           theme={conversiontheme}
		  		           xFormat="time:%m/%d/%Y"
		  		           xScale={{
		  		               type: 'time',
		  		               format: '%m/%d/%Y',
		  		           }}
		  		           yScale={{
		  		             type: 'linear',
		  		           }}
		  		       />
		  	    :
		  	    <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
		  	    	<span className='text-center text-sm tablet:text-base'>No data recorded at this time.</span>
		  	    	<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
		  	    		<Text>10 -</Text>
		  	    		<Text>8 -</Text>
		  	    		<Text>6 -</Text>
		  	    		<Text>4 -</Text>
		  	    		<Text>2 -</Text>
		  	    		<Text>0 -</Text>
		  	    	</Flex>
		  	    </FlexRow>
		  	}
		  	</div>

		  	<div className={`w-full h-80 my-8 ${selectedChart === '' || selectedChart === '-- SHOW ALL --'? '' : 'hidden'}`}>
		  	{
		  		( conversionRateProduct.length > 0 || conversionRateService.length > 0 ) && isShow?
		  		<ResponsiveLine
		  		           axisBottom={{
		  		            format: '%b %d',
		  		            tickPadding: 5,
		  		            tickRotation: 67,
		  		            tickValues: dimensions.width > 1000 ? 12 : 5,
		  		           }}
		  		           axisLeft={{
		  		             tickValues: 5,
		  		             tickPadding: 10,
		  		             format: (text) => Number(text),
		  		           }}
		  		           {...commonProperties}
		  		           colors={['#073763','#FF733F']}
		  		           curve="monotoneX"
		  		           data={[
		  		           	   {
		  		                   id: '% by Service',
		  		                   data: conversionRateService,
		  		               },
		  		               {
		  		                   id: '% by Product',
		  		                   data: conversionRateProduct,
		  		               },
		  		           ]}
		  		           enableArea
		  		           enableGridX={false}
		  		           enableGridY={false}
		  		           enableSlices="x"
		  		           legends={[
		  		             {
		  		               anchor: 'top-left',
		  		               direction: 'row',
		  		               justify: false,
		  		               translateY: -40,
		  		               itemDirection: 'left-to-right',
		  		               itemWidth: 140,
		  		               itemHeight: 20,
		  		               symbolSize: 12,
		  		               symbolShape: 'square',
		  		               effects: [
		  		                 {
		  		                   on: 'hover',
		  		                   style: {
		  		                     itemBackground: 'rgba(0, 0, 0, .03)',
		  		                     itemOpacity: 1,
		  		                   },
		  		                 },
		  		               ],
		  		               toggleSerie: false,
		  		             },
		  		           ]}
		  		           sliceTooltip={
		  		             // eslint-disable-next-line react/no-unstable-nested-components
		  		             ({ slice }) => (
		  		               <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
		  		                 {slice.points.map((point, i) => (
		  		                   <Div key={point.id}>
		  		                     <FlexCenter className="text-xs font-medium">
		  		                       {i === 0 ? point.data.xFormatted : ''}
		  		                     </FlexCenter>
		  		                     <Div className="flex flex-row pt-1">
		  		                       <Flex className="text-grey-700 text-sm">
		  		                         Rate at
		  		                       </Flex>
		  		                       <Flex className="text-right text-sm font-medium">
		  		                         {point.data.y.toLocaleString()}%
		  		                       </Flex>
		  		                     </Div>
		  		                   </Div>
		  		                 ))}
		  		               </Div>
		  		             )
		  		           }
		  		           theme={conversiontheme}
		  		           xFormat="time:%m/%d/%Y"
		  		           xScale={{
		  		               type: 'time',
		  		               format: '%m/%d/%Y',
		  		           }}
		  		           yScale={{
		  		             type: 'linear',
		  		           }}
		  		       />
		  	    :
		  	    <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
		  	    	<span className='text-center text-sm tablet:text-base'>No data recorded at this time.</span>
		  	    	<Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
		  	    		<Text>10 -</Text>
		  	    		<Text>8 -</Text>
		  	    		<Text>6 -</Text>
		  	    		<Text>4 -</Text>
		  	    		<Text>2 -</Text>
		  	    		<Text>0 -</Text>
		  	    	</Flex>
		  	    </FlexRow>
		  	}
		  	</div>

		  	</Div>

		  </Div>
		</MobileModal>

	</FlexRow>
	</>
	)
};