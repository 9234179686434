import React, { FC } from 'react';

interface InputProps {
  className?: string;
  containerClass?: string;
  props?: any;
  id: string;
  placeholder?: string;
  label: string;
  onClick?: any;
  checked?: boolean;
}

export const Checkbox: FC<InputProps> = ({
  className,
  id,
  label,
  props,
  containerClass,
  onClick,
  checked,
}) => (
  <div {...props} className={`flex items-start mb-2 ${containerClass}`}>
    <div className="flex items-center h-5">
      <input
        aria-describedby={id}
        checked={checked}
        className="w-5 h-5 rounded border border-secondary-100 
                  focus:ring-3 focus:ring-primary-200 text-primary-100"
        id={id}
        onClick={onClick}
        readOnly
        type="checkbox"
      />
    </div>
    <div className="ml-3 text-sm">
      <label
        className={`font-poppins font-regular text-base text-secondary-100 ${className}`}
        htmlFor="remember"
      >
        {label}
      </label>
    </div>
  </div>
);
