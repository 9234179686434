import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { Div, Text, FlexRow, Flex } from '@core';
import { getCookie } from '@hooks';
import { API } from '@config';
import { CategoryList, PaginationV2, CreateCategoryModal } from '@components';

export const ManageCategoriesAdmin: FC<any> = () => {

  const token = getCookie('token');
  const { search } = useLocation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const [createCategoryOpen, setCreateCategoryOpen] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);

  const [categoryList, setCategoryList] = React.useState<any>([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage, setArticlesPerPage] = React.useState(5);
  const [pageItems, setPageItems] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newCategory = categoryList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    if(pageItems < 0 || pageItems > categoryList.length){
      setArticlesPerPage(5)
    }else if (pageItems > 0 && pageItems < categoryList.length){
      setArticlesPerPage(pageItems)
    }
  }, [pageItems])

  React.useEffect(() => {
    fetch(`${API}/get-all-role-commission`, {
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`}
      })
      .then(res => res.json())
      .then(data => {
          if(data.status === 1){
            setCategoryList(data.data)
            setIsShow(true)
          }else{
            setCategoryList([])
            setIsShow(true)
          }
      })
  }, [user])

  React.useEffect(() => {
    if(actionQuery === 'updated'){
      setIsShow(false)
      fetch(`${API}/get-all-role-commission`, {
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`}
      })
      .then(res => res.json())
      .then(data => {
        setCategoryList(data.data)
        navigate(`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}/settings`)
        setCreateCategoryOpen(false)
        setIsShow(true)
      })
    }else{
      navigate(`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}/settings`)
    }
  }, [actionQuery])

  return(
  <>
  <CreateCategoryModal isOpen={createCategoryOpen} onClose={() => setCreateCategoryOpen(false)} />
  <Flex className='desktop:pl-16 laptop:pt-9 phone:pt-0 pb-16 laptop:pr-20 phone:px-5 
  overflow-y-auto scrollbar-hide pb-72'>
        <Text className="font-poppins font-semibold text-xl phone:mt-3 tablet:mt-8 mb-1 text-primary-100">
            Affiliate Category Tagging
        </Text>
        <hr className="text-grey-400 mt-1" />

        <FlexRow className='justify-between phone:flex-col medium:flex-row tablet:flex-col tablet:items-start tabletWide:items-center tabletWide:flex-row w-full mt-7'>
          <FlexRow className='w-full mx-3 phone:flex-col surfaceDuo:flex-row medium:w-auto'>
            <Text className='text-lg font-semibold mr-auto text-primary-100'>All Categories</Text>
            <Text className='mr-auto medium:ml-4'>{categoryList.length} Total &#8226; {categoryList.length} Active</Text>
          </FlexRow>

          <Div className='medium:mx-3 mt-4 w-full medium:w-auto'>
            <button className='bg-primary-100 p-3 text-white hover:bg-blue-400 phone:w-full surfaceDuo:w-auto mt-4 surfaceDuo:mt-0 rounded' onClick={() => setCreateCategoryOpen(true)}>
              Add Category <i className="fas fa-plus text-white mx-3" />
            </button>
          </Div>
        </FlexRow>

        <FlexRow className='mx-auto phone:hidden w-full medium:flex tablet:hidden tabletWide:flex mt-8'>
          <i
            className="fas fa-edit text-white mr-2 cursor-pointer"
            title='Table'
          />
          <Text className='w-1/3 text-lg text-secondary-200 text-center font-semibold'>
            Category
          </Text>
          <Text className='w-1/3 text-lg text-secondary-200 text-center font-semibold'>
            Commission %
          </Text>
          <Text className='w-1/3 text-lg text-secondary-200 text-center font-semibold'>
            Affiliate Count
          </Text>
        </FlexRow>
        <hr className="mx-auto text-grey-400 mt-2 mb-4 phone:hidden medium:flex tablet:hidden tabletWide:flex" />


        {
          categoryList.length > 0 && isShow?
          <>
          <Div className={`w-full ${articlesPerPage > 10 ? 'h-96 overflow-y-scroll' : ''}`}>
          {
            newCategory.map((list:any, index: number) => (
              <CategoryList
                categoryColor={list.color}
                categoryCommission={list.commission}
                categoryCount={list.category_count}
                categoryName={list.role_name}
                id={list.id}
                key={index}
              />
              ))
          }
          {
            newCategory.length === 0 ?
            <Div className='bg-grey-400 py-4 my-2'>
            <Text className='text-center w-full'>
              Page is empty.
            </Text>
            <Text className='text-xs text-center w-full mt-4'>
              Go to Page 1
            </Text>
            </Div>
            :
            <></>
          }
          </Div>
          </>
          :
          <FlexRow className='w-full mx-auto'>
            {
              isShow?
              <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
                You have no category data.
                <span className='hover:underline block text-blue-100 cursor-pointer' onClick={() => setCreateCategoryOpen(true)}>
                  Click to add new category
                </span>
              </Text>
              :
              <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
                Loading data ...
                <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
              </Text>
            }
          </FlexRow>
        }

        {
          categoryList.length > 5 ?
          <Div className="w-full justify-center mt-4">

            <Text className='text-secondary-200 my-4'>
              Total records found: <b className='ml-4 mr-2'>{categoryList.length}</b> entries
            </Text>

            <Text className='flex flex-row items-center text-secondary-200 my-4'>
              <Popover className="relative my-4">
                    <Popover.Button>
                          <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
                    </Popover.Button>
                    <Popover.Panel className="absolute z-10 left-0">
                      <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
                        <p className='font-semibold text-sm'>
                          Items per Page
                        </p>
                        <hr className='text-primary-100 my-1'/>
                        <p className='text-sm mt-4'>
                          Input a number or press
                          <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
                          <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
                          to change number of entries per page.
                        </p>
                        <p className='mt-4 text-sm text-secondary-200'>
                          ** Click anywhere on screen to close. **
                        </p>
                      </div>
                    </Popover.Panel>
              </Popover>
              Items per Page:
              <input className='mx-2 w-10 px-2' onChange={(e:any) => setPageItems(e.target.value)} type='number' value={pageItems} />
              <button className='flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white'>
                Go
              </button>
            </Text>

            <PaginationV2
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={categoryList.length}
            />
          </Div> 
          :
          <div className="w-full flex justify-center">
            <PaginationV2
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={categoryList.length}
            />
          </div>
        }

  </Flex>
  </>
  )
};