import React, {FC} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Div, Text, Toast, FlexRow } from '@core';
import { API } from '@config';
import { getCookie } from '@hooks';
import { selectUser } from '@redux';

interface AffiliateProps {
	id: string;
	categoryName: string;
	categoryColor: string;
	categoryCommission: string;
	categoryCount: number;
}

export const CategoryList: FC<AffiliateProps> = ({
	id,
	categoryName,
	categoryColor,
	categoryCommission,
	categoryCount,
}) => {

	const user = useSelector(selectUser);
	const token = getCookie('token');
	const navigate = useNavigate();

	const [isEdit, setEdit] = React.useState(false);
	const [name, setName] = React.useState('');
	const [color, setColor] = React.useState(categoryColor);
	const [commission, setCommission] = React.useState(categoryCommission);

	const [isLoading, setLoading] = React.useState(false);

	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('');
	const [toastDesc, setToastDesc] = React.useState('');

	React.useEffect(() => {
		setName(categoryName)
	}, [categoryName])

	React.useEffect(() => {
		setColor(categoryColor)
	}, [categoryColor])

	React.useEffect(() => {
		setCommission(categoryCommission)
	}, [categoryCommission])

	React.useEffect(() => {
	  const regExp = /[a-zA-Z]/g;
	  if(parseFloat(commission) < 0 || regExp.test(commission) || parseFloat(commission) > 100){
	    setCommission('')
	  }
	}, [commission])

	const onUpdate = () => {
		if(name === '' || color === '' || commission === ''){
		  setToastTitle('ERROR');
		  setToastDesc('Please complete all fields.');
		  setToastKind('error');
		  setToastOpen(true);
		}else{
		  		setLoading(true)
		  		fetch(`${API}/update-role-commission/${id}`,{
		  			method: 'PUT',
		  			headers: {
		  				Authorization: `Bearer ${token}`,
		  				'Content-Type': 'application/json',
		  			},
		  			body: JSON.stringify({
		  	            role_name: name,
		  	            color,
		  	            commission
		          	})
		  		})
		  		.then(res => res.json())
		  		.then(data => {
		  			if(data.status === 1){
		  				setLoading(false)
		  				setEdit(false)
		  				setToastDesc('Category has been updated successfully')
		  				setToastOpen(true)
		  				setToastTitle('SUCCESS')
		  				setToastKind('success')
		  				if(user?.user_info?.role === 'merchant'){
		  					navigate('/merchant/settings?action=updated')
		  				}else{
		  					navigate(`/merchant/admin_dashboard/${user?.user_info.role.toLowerCase()}/settings?action=updated`)
		  				}
		  			}else{
		  				setLoading(false)
		  				setToastDesc(data.message || 'Something went wrong. Please try again.')
		  				setToastOpen(true)
		  				setToastTitle('ERROR')
		  				setToastKind('error')
		  			}
		  		})
		}
	}

	const onRemove = () => {
		setLoading(true)
		fetch(`${API}/remove-role-commission/${id}`,{
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
		.then(res => res.json())
		.then(data => {
			if(data.status === 1){
				setLoading(false)
				setEdit(false)
				setToastDesc('Category has been removed.')
				setToastOpen(true)
				setToastTitle('SUCCESS')
				setToastKind('success')
				if(user?.user_info?.role === 'merchant'){
					navigate('/merchant/settings?action=updated')
				}else{
					navigate(`/merchant/admin_dashboard/${user?.user_info.role.toLowerCase()}/settings?action=updated`)
				}
			}else{
				setLoading(false)
				setToastDesc(data.message || 'Something went wrong. Please try again.')
				setToastOpen(true)
				setToastTitle('ERROR')
				setToastKind('error')
			}
		})
	}

return(
	<>
	<Toast
	      description={toastDesc}
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	      title={toastTitle}
	      toastKind={toastKind}
	    />

	<div className={`mx-auto py-2 px-1 rounded w-full ${isEdit? 'bg-grey-500' : 'hover:bg-grey-500'}`}>
	<Div className='phone:flex phone:flex-row medium:items-center tablet:items-start' key={id}>

		{
			isEdit?
			<i
			  className="flex items-center justify-center fas fa-times text-primary-100 border-2 border-primary-100 rounded mr-2 cursor-pointer h-5 w-5"
			  onClick={() => setEdit(false)}
			  title='Update changes.'
			/>
			:
			<i
			  className="fas fa-edit text-primary-100 mr-2 cursor-pointer"
			  onClick={() => setEdit(true)}
			  title='Edit this field.'
			/>
		}

		<Div className='phone:flex phone:flex-col phone:w-1/2 phone:justify-start medium:hidden tablet:flex tabletWide:hidden'>
		    <Text
		      className={` text-base text-secondary-200 font-light w-full text-left ${isEdit? 'my-1.5' : 'mb-2'}`}
		    >
		      Category:
		    </Text>
		    <Text
		      className={` text-base text-secondary-200 font-light w-full text-left ${isEdit? 'my-1.5' : 'mb-2'}`}
		    >
		      Commission:
		    </Text>
		    <Text
		      className={`text-base text-secondary-200 font-light w-full text-left ${isEdit? 'my-1.5' : 'mb-2'}`}
		    >
		     Affiliates:
		    </Text>
		</Div>

		<Div className='phone:flex phone:flex-col phone:w-1/2 medium:flex-row medium:w-full medium:items-center tablet:flex-col tablet:items-start tablet:w-1/2 tabletWide:flex-row tabletWide:items-center tabletWide:w-full' >
			{
				isEdit?
				<>
				<FlexRow className='w-full medium:w-1/3 medium:justify-center tablet:w-full tablet:justify-start tabletWide:w-1/3 tabletWide:justify-center'>
					<input
			          className="mx-1 rounded-lg p-1 border-2 border-secondary-200 text-base text-secondary-200 font-light phone:w-20 surfaceDuo:w-44 text-left medium:text-center tablet:text-left tabletWide:text-center"
			          maxLength={12}
			          onChange={(e:any) => setName(e.target.value)}
			          value={name}
			        />
			        <input className='mx-1 w-10 h-8 border-2 border-secondary-200 rounded-lg cursor-pointer' name='color' onChange={(e:any) => setColor(e.target.value)} required type='color' value={color}/>
		        </FlexRow>
		        <FlexRow className='w-full medium:w-1/3 medium:justify-center tablet:w-full tablet:justify-start tabletWide:w-1/3 tabletWide:justify-center'>
			    	<input
			    	  className="mx-1 rounded-lg p-1 border-2 border-secondary-200 text-base text-secondary-200 font-light phone:w-20 surfaceDuo:w-44 text-left medium:text-center tablet:text-left tabletWide:text-center"
			    	  onChange={(e:any) => setCommission(e.target.value)}
			    	  type='number'
			    	  value={commission}
			    	/>
			    </FlexRow>
		        <FlexRow className='w-full medium:w-1/3 medium:justify-center tablet:w-full tablet:justify-start tabletWide:w-1/3 tabletWide:justify-center'>
			    	<Text className='text-secondary-200 mx-1 p-1 border-2 border-grey-400 bg-white rounded-lg surfaceDuo:w-44 medium:text-center tablet:text-left tabletWide:text-center'>
			    		{categoryCount} Partners
			    	</Text>
			    </FlexRow>
				</>
				:
				<>
				<Div
		          className="w-full medium:w-1/3 tablet:w-full tabletWide:w-1/3 flex flex-row justify-start medium:justify-center tablet:justify-start tabletWide:justify-center mb-1"
		        >
		          <div className={`${categoryName.length > 12 ? 'w-auto px-2' : 'w-36'} rounded-full text-base text-white font-semibold text-center border-2 border-grey-400`} style={{backgroundColor: categoryColor}}>
		          	{categoryName.toUpperCase()}
		          </div>
		        </Div>
		    	<Text
		    	  className=" text-base text-secondary-200 font-light w-full medium:w-1/3 tablet:w-full tabletWide:w-1/3 text-left medium:text-center tablet:text-left tabletWide:text-center mb-2"
		    	>
		    	  {parseFloat(categoryCommission).toFixed(2)}%
		    	</Text>
		    	<Text
		    	  className=" text-base text-secondary-200 font-light w-full medium:w-1/3 tablet:w-full tabletWide:w-1/3 text-left medium:text-center tablet:text-left tabletWide:text-center mb-2"
		    	>
		    	  {categoryCount} Partners
		    	</Text>
				</>
			}
		        
		</Div>

	</Div>
	{
		isEdit && !isLoading?
		<Text className='mt-4 ml-6 text-secondary-200 text-base font-light flex flex-col surfaceDuo:flex-row'>
			Actions:
			<Div>
			<span className='surfaceDuo:ml-3 text-primary-100 hover:underline cursor-pointer font-semibold' onClick={onUpdate}>Update</span>
			<span className='ml-3 text-primary-100 hover:underline cursor-pointer font-semibold' onClick={onRemove}>Remove</span>
			<span className='ml-3 text-primary-100 hover:underline cursor-pointer font-semibold' onClick={() => setEdit(false)}>Cancel</span>
			</Div>
		</Text>
		:
		<></>
	}
	{
		isLoading?
		<Text className='flex flex-row items-center mt-4 ml-6 text-secondary-200 text-base font-light'>
			Loading please wait.
			<i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
		</Text>
		:
		<></>
	}
	</div>
	<hr className="text-grey-400 mt-2 mb-4 w-full mx-auto" />
	</>
)
}