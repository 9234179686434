import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

export const getUser = async () => axios.get(`${API}/login`).then((response) => response);

export const getProfile = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProfileAdmin = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/profile-admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getNotifs = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/notification`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getNotifsAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/admin-notification/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getEmailNotifs = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-email-settings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliates = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-affiliates-merchant`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliatesAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-merchant-admin-affiliates/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getClicks = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-clicks-merchant`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getClicksAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-clicks-admin-merchant/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getConversions = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-conversions-merchant`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getConversionsAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-conversions-admin-merchant/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProductAnalytics = async (prodID: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/product-analytics/${prodID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getOverallAnalytics = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-analytics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getOverallAnalyticsAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/overall-admin-analytics/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getOverallAnalyticsAffiliate = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/affiliate-chart-analytics/90`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateList = async (prodID: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliates/${prodID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliatePage = async (
  prodID: string,
  page: number,
  sortBy: string,
  sortType: string,
) => {
  const token = getCookie('token');
  return axios
    .get(
      `${API}/get-affiliates-pagination/${prodID}/${page}?sorty_by=${sortBy}&sort_type=${sortType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => response);
};

export const searchAffiliatePage = async (
  prodID: string,
  search: string,
  sortBy: string,
  sortType: string,
) => {
  const token = getCookie('token');
  return axios
    .get(
      `${API}/get-affiliates-search/${prodID}?search=${search}&sorty_by=${sortBy}&sort_type=${sortType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => response);
};

export const sendEmailConfirmation = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/send-email-confirmation`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const subscription = async (subsPlan: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/subscription-payment/${subsPlan}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const markAsRead = async (body: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/mark-as-read`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        body,
      },
    })
    .then((response) => response);
};

export const getAffiliateOverallAnalytics = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/affiliate-overview-by-products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateGraphAnalytics = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/affiliate-overview-with-dates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateOverviewData = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/affiliate-total-overview`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getRevenueCommission = async (id: any) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-market-conversion/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getSuperAdmins = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-all-superadmin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAdminPermissions = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-permissions/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getSingleAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-superadmin/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateOverview = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliate-overview`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateAnalytics = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliate-analytics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliateCategory = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/affiliate-merchant-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getMerchantConversion = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-product-conversion-reports/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getUserDetails = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-profile-user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getCommissionDetails = async (id: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/commision-history-details/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

// /api/wix-get-checkout-url
export const getWixCheckoutUrl = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/wix-get-checkout-url`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};
