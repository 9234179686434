import React, { FC } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { Div, Text, FlexRow } from '@core';
import { NavBarSuperAdmin } from '@components';

export const TransactionFailed: FC<any> = () => {

  const user = useSelector(selectUser);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const refNum = query.get('ref');
  const affName = query.get('affName');
  const merchant = query.get('merchant');
  const date = query.get('date');
  const amount = query.get('amount');
  const reason = query.get('reason');

  return(
  <>

  {
    user?.user_info?.role === 'Super-Admin' ?
  <>
  <NavBarSuperAdmin/>
  <Div className='flex pt-28 px-2 phone:flex-col tabletWide:pt-36 tabletWide:flex-row w-full h-full'>

    <Div className='phone:w-full phone:px-2 surfaceDuo:px-4 tabletWide:w-64 flex justify-start tabletWide:justify-center mb-3'>
      <Link className='font-semibold text-primary-100' to='/admin/dashboard?action=pending'>
          <i className="fas fa-angle-left"/>
          <i className="fas fa-angle-left mr-3"/>
          Back to Settings
      </Link>
    </Div>

  <Div
    className="overflow-y-scroll scrollbar-hide phone:px-2 surfaceDuo:px-4 tabletWide:px-8 flex flex-col w-full"
  >
    <Text className="font-poppins font-semibold text-xl mb-3 text-primary-100">
      Transaction Details
    </Text>
    <hr className="text-grey-400 mb-10" />

    <Text className='text-secondary-200'>
      Reference ID: <b className='text-primary-100 ml-2'>{refNum || '--'}</b>
    </Text>
    <Text className='text-secondary-200 my-4'>
      A record of your disbursements made on <b className='text-primary-100'>{date || '--'}</b>.
    </Text>

    <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
      <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
        From Merchant:
      </Text>
      <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
        {merchant || '--'}
      </Text>
    </FlexRow>
    <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
      <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
        To Affiliate:
      </Text>
      <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
        {affName || '--'}
      </Text>
    </FlexRow>
    <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
      <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
        Error Description:
      </Text>
      <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
        {reason || '--'}
      </Text>
    </FlexRow>
    <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
      <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
        Total amount of commission:
      </Text>
      <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
        &#8369; {amount? parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'}
      </Text>
    </FlexRow>
    <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
      <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
        Total transacted amount:
      </Text>
      <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
        &#8369; {amount? parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'}
      </Text>
    </FlexRow>
    <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
      <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
        Received by affiliate:
      </Text>
      <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
        &#8369; 0.00
      </Text>
    </FlexRow>
    <FlexRow className='phone:flex-col surfaceDuo:flex-row medium:pl-28 my-1'>
      <Text className='text-secondary-200 text-base phone:w-full surfaceDuo:w-1/2 medium:w-2/3'>
        Affiliate paid:
      </Text>
      <Text className='font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 text-primary-100'>
        0 Affiliate(s)
      </Text>
    </FlexRow>
  
  </Div>

  </Div>
  </>
  :
  <Navigate to='/' />

  }

  </>
)};