import React, { FC } from 'react';
import { batch } from 'react-redux';
import { Tab, Popover } from '@headlessui/react';
import { Text, FlexRow, Flex, Div } from '@core';
import { classNames } from '@helpers';
import { ConversionData, ConversionLeadsData, PaginationV2 } from '@components';
import { getMerchantConversion } from '@services';

interface ProductProps {
  product: any;
  setSelected: any;
}

export const ConversionItem: FC<ProductProps> = ({ product, setSelected }) => {

  const [tabIndex] = React.useState(0);
  const tabs = ['Sales'];

  const [salesList, setSalesList] = React.useState([]);
  const [leadsList, setLeadsList] = React.useState([]);
  const [salesLoaded, setSalesLoaded] = React.useState(false);
  const [leadsLoaded, setLeadsLoaded] = React.useState(false);
  const [isShow, setShow] = React.useState(true);

  const [currentPageLead, setCurrentPageLead] = React.useState(1);
  const [articlesPerPageLead, setArticlesPerPageLead] = React.useState(5);
  const [pageItemsLead, setPageItemsLead] = React.useState(5);
  const [pageDescLead, setPageDescLead] = React.useState('');
  const indexOfLastArticleLead = currentPageLead * articlesPerPageLead;
  const indexOfFirstArticleLead = indexOfLastArticleLead - articlesPerPageLead;
  const currentLeads = leadsList.slice(indexOfFirstArticleLead, indexOfLastArticleLead);
  const paginateLead = (pageNumber: React.SetStateAction<number>) => setCurrentPageLead(pageNumber);

  const [currentPageSale, setCurrentPageSale] = React.useState(1);
  const [articlesPerPageSale, setArticlesPerPageSale] = React.useState(5);
  const [pageItemsSale, setPageItemsSale] = React.useState(5);
  const [pageDescSale, setPageDescSale] = React.useState('');
  const indexOfLastArticleSale = currentPageSale * articlesPerPageSale;
  const indexOfFirstArticleSale = indexOfLastArticleSale - articlesPerPageSale;
  const currentSales = salesList.slice(indexOfFirstArticleSale, indexOfLastArticleSale);
  const paginateSale = (pageNumber: React.SetStateAction<number>) => setCurrentPageSale(pageNumber);

  React.useEffect(() => {
    if(pageItemsLead < 0 || pageItemsLead > leadsList.length){
      setArticlesPerPageLead(5)
      setPageDescLead('Items per page must not exceed total records count or less than zero.')
    }else if (pageItemsLead > 0 && pageItemsLead <= leadsList.length){
      setArticlesPerPageLead(pageItemsLead)
    }
  }, [pageItemsLead])

  React.useEffect(() => {
    if(pageItemsLead > 0 && pageItemsLead <= leadsList.length){
      setPageDescLead('')
    }
  })

  React.useEffect(() => {
    if(pageItemsSale < 0 || pageItemsSale > salesList.length){
      setArticlesPerPageSale(5)
      setPageDescSale('Items per page must not exceed total records count or less than zero.')
    }else if (pageItemsSale > 0 && pageItemsSale <= salesList.length){
      setArticlesPerPageSale(pageItemsSale)
    }
  }, [pageItemsSale])

  React.useEffect(() => {
    if(pageItemsSale > 0 && pageItemsSale <= salesList.length){
      setPageDescSale('')
    }
  })

  function checkTabDesktop(tab: string) {
    switch (tab) {
      case tabs[0]:
        return tabs[0];
      case tabs[1]:
        return `Leads`;
      default:
        return '';
    }
  }

  React.useEffect(() => {
    setShow(false)
    batch(async() => {
      const conversions: any = await getMerchantConversion(product.id);
      if(conversions.data.status === 1){
        setSalesList(conversions.data.data.sales.concat(conversions.data.data.leads))
        setLeadsList(conversions.data.data.leads)
        setSalesLoaded(true)
        setLeadsLoaded(true)
        setTimeout(() => {
          setShow(true)
        }, 1000)
      }
    })
  }, [product])

  const onReload = () => {
    setShow(false)
    batch(async() => {
      const conversions: any = await getMerchantConversion(product.id);
      if(conversions.data.status === 1){
        setSalesList(conversions.data.data.sales.concat(conversions.data.data.leads))
        setLeadsList(conversions.data.data.leads)
        setSalesLoaded(true)
        setLeadsLoaded(true)
        setTimeout(() => {
          setShow(true)
        }, 1000)
      }
    })
  }

return(

  <div className='w-full'>

     <button
      className="text-grey-100 hover:text-grey-200 phone:hidden tabletWide:block mr-auto my-8 ml-8"
      onClick={() => setSelected({name: 'Product'})}
      type="button"
    >
      <Text className="items-center flex laptop:text-lg phone:base mr-3">
        <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to overview
      </Text>
    </button> 

    <Text className='text-primary-100 font-semibold text-2xl mt-8 phone:mx-4 tablet:mx-8'>
      {product.name.toUpperCase()}
    </Text>

    <FlexRow className="mt-4 phone:px-4 tablet:px-8 h-full overflow-y-scroll scrollbar-hide">
      <Tab.Group defaultIndex={tabIndex}>
        <Flex>
          <Tab.List className="tablet:space-x-8 hidden">
            {tabs.map((tab) => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    'py-3 font-semibold phone:w-1/2 tablet:w-auto',
                    selected
                      ? 'text-primary-100 text-xl border-b-4 border-blue-400'
                      : 'text-grey-700 text-xl  hover:text-grey-200',
                  )
                }
                key={tab}
              >
                {checkTabDesktop(tab)}
              </Tab>
            ))}
          </Tab.List>
          <hr className="text-grey-1200" />
          <Tab.Panels className="pt-9">
            <Tab.Panel>

              <Div className='hidden tabletWide:flex tabletWide:flex-row'>
                  <Text
                    className="text-base text-secondary-200 font-medium w-1/4 text-center"
                  >
                    Name
                  </Text> 
                  <Text
                    className="text-base text-secondary-200 font-medium w-1/4 text-center"
                  >
                    Email
                  </Text>
                  <Text
                    className="text-base text-secondary-200 font-medium w-1/4 text-center"
                  >
                    Transaction Date
                  </Text> 
                  <Text
                    className="text-base text-secondary-200 font-medium w-1/4 text-center "
                  >
                    Conversion
                  </Text>
              </Div>
              <hr className="hidden tabletWide:block text-grey-400 my-4" />

              {
                salesList.length > 0 && salesLoaded && isShow?
                <Div className=''>
                {
                  currentSales.map((item:any, index:number) => (
                    <ConversionData
                      amount={item.price || item.plan}
                      date={item.transaction_date || item.subscription_date}
                      email={item.email || '-- --'}
                      indexNum={index}
                      key={index}
                      name={item.customer_name || '-- --'}
                    />
                    ))
                }
                </Div>
                :
                <>
                {
                  !isShow ?
                  <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                      <Text>
                        Loading data ...
                        <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                      </Text>
                  </Div>
                  :
                  <Div className='bg-grey-500 py-8 mt-3 mb-10 '>
                      <Div className='flex flex-row justify-center font-robot font-regular'>
                        No data to be displayed for sales data.
                        <Popover className="relative">
                              <Popover.Button>
                                  <i className="fas fa-question text-xs ml-4 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                              </Popover.Button>

                              <Popover.Panel className="absolute z-10 right-0">
                                <Div className="bg-white w-64 shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-60">
                                  <Text className='font-semibold text-sm text-left'>
                                    Sales Data
                                  </Text>
                                  <hr className='text-primary-100 my-1'/>
                                  <Text className='text-sm my-2 text-left'>
                                    This applies to transactions from products purchase.
                                  </Text>
                                  
                                  <Text className='mt-4 text-sm text-secondary-200'>
                                    ** Click anywhere on screen to close. **
                                  </Text>
                                </Div>
                              </Popover.Panel>
                        </Popover>
                      </Div>
                  </Div>
                }
                </>
              }

                <div
                  className='font-robot font-regular text-blue-100 my-4 hover:underline cursor-pointer'
                  onClick={onReload}>
                  Reset records.
                </div>

                <Text className='text-secondary-200 my-4'>
                  Total records found: <b className='ml-4 mr-2'>{salesList.length}</b> entries
                </Text>

                <Text className='flex flex-row items-center text-secondary-200 my-4'>
                  <Popover className="relative my-4">
                        <Popover.Button>
                              <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 left-0">
                          <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
                            <p className='font-semibold text-sm'>
                              Items per Page
                            </p>
                            <hr className='text-primary-100 my-1'/>
                            <p className='text-sm mt-4'>
                              Input a number or press
                              <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
                              <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
                              to change number of entries per page.
                            </p>
                            <p className='mt-4 text-sm text-secondary-200'>
                              ** Click anywhere on screen to close. **
                            </p>
                          </div>
                        </Popover.Panel>
                  </Popover>
                  Items per Page:
                  <input className='mx-2 w-10 px-2' onChange={(e:any) => setPageItemsSale(e.target.value)} type='number' value={pageItemsSale} />
                  <button className='hidden flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white'>
                    Go
                  </button>
                </Text>

                {
                  salesList.length > 5 ?
                  <Text className='text-red-400'>
                    {pageDescSale}
                  </Text>
                  :
                  <></>
                }

              <div className="flex justify-center">
                <PaginationV2
                  currentPage={currentPageSale}
                  itemsPerPage={articlesPerPageSale}
                  paginate={paginateSale}
                  totalItems={salesList.length}
                />
              </div>  

            </Tab.Panel>
            <Tab.Panel>

                  <Div className='hidden tabletWide:flex tabletWide:flex-row'>
                      <Text
                        className="text-base text-secondary-200 font-medium w-1/3 text-center"
                      >
                        Name
                      </Text> 
                      <Text
                        className="text-base text-secondary-200 font-medium w-1/3 text-center"
                      >
                        Email
                      </Text>
                      <Text
                        className="text-base text-secondary-200 font-medium w-1/3 text-center"
                      >
                        Transaction Date
                      </Text> 
                      <Text
                        className="text-base text-secondary-200 font-medium w-1/4 text-center hidden"
                      >
                        Conversion
                      </Text>
                  </Div>
                  <hr className="hidden tabletWide:block text-grey-400 my-4" />

              {
                leadsList.length > 0 && leadsLoaded && isShow ?
                <Div className=''>
                {
                  currentLeads.map((item:any, index:number) => (
                    <ConversionLeadsData
                      amount={item.price || item.plan}
                      date={item.subscription_date}
                      email={item.email || '-- --'}
                      key={index}
                      name={item.customer_name || '-- --'}
                    />
                    ))
                }
                </Div>
                :
                <>
                {
                  !isShow ?
                  <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                      <Text>
                        Loading data ...
                        <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                      </Text>
                  </Div>
                  :
                  <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                      <Text className='flex flex-row justify-center'>
                        No data to be displayed for leads data.
                        <Popover className="relative">
                              <Popover.Button>
                                  <i className="fas fa-question text-xs ml-4 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                              </Popover.Button>

                              <Popover.Panel className="absolute z-10 right-0">
                                <Div className="bg-white w-64 shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-60">
                                  <Text className='font-semibold text-sm text-left'>
                                    Leads Data
                                  </Text>
                                  <hr className='text-primary-100 my-1'/>
                                  <Text className='text-sm my-2 text-left'>
                                    This applies to transactions of product subscription payments.
                                  </Text>
                                  
                                  <Text className='mt-4 text-sm text-secondary-200'>
                                    ** Click anywhere on screen to close. **
                                  </Text>
                                </Div>
                              </Popover.Panel>
                        </Popover>
                      </Text>
                  </Div>
                }
                </>
              }

                <div
                  className='font-robot font-regular text-blue-100 my-4 hover:underline cursor-pointer'
                  onClick={onReload}>
                  Reset records.
                </div>

                <Text className='text-secondary-200 my-4'>
                  Total records found: <b className='ml-4 mr-2'>{leadsList.length}</b> entries
                </Text>

                <Div className='flex flex-row items-center text-secondary-200 font-robot font-regular my-4'>
                  <Popover className="relative my-4">
                        <Popover.Button>
                              <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 left-0">
                          <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
                            <p className='font-semibold text-sm'>
                              Items per Page
                            </p>
                            <hr className='text-primary-100 my-1'/>
                            <p className='text-sm mt-4'>
                              Input a number or press
                              <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
                              <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
                              to change number of entries per page.
                            </p>
                            <p className='mt-4 text-sm text-secondary-200'>
                              ** Click anywhere on screen to close. **
                            </p>
                          </div>
                        </Popover.Panel>
                  </Popover>
                  Items per Page:
                  <input className='mx-2 w-10 px-2' onChange={(e:any) => setPageItemsLead(e.target.value)} type='number' value={pageItemsLead} />
                  <button className='hidden flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white'>
                    Go
                  </button>
                </Div>

                {
                  leadsList.length > 5 ?
                  <Text className='text-red-400'>
                    {pageDescLead}
                  </Text>
                  :
                  <></>
                }

              <div className="flex justify-center">
                <PaginationV2
                  currentPage={currentPageLead}
                  itemsPerPage={articlesPerPageLead}
                  paginate={paginateLead}
                  totalItems={leadsList.length}
                />
              </div>   

            </Tab.Panel>
          </Tab.Panels>
        </Flex>
      </Tab.Group>
    </FlexRow>

  </div>

  )
};