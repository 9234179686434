import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Div, Text, Icons, ToastV4 } from '@core';
import { API, SHARE } from '@config';

export const ReferralLanding = () => {

  const navigate = useNavigate();
  const { id } = useParams();

  const [isToastOpen4, setToastOpen4] = React.useState(false);
  const [toastKind4, setToastKind4] = React.useState('error');
  const [toastTitle4, setToastTitle4] = React.useState('ERROR');
  const [toastDesc4, setToastDesc4] = React.useState('');

  const [isValid, setIsValid] = React.useState<any>(true);

  React.useEffect(() => {
    if( id !== null ){
      fetch(`${API}/merchant-available-product/${id}`)
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          if(data.data !== null && data.data.length > 0){
            setIsValid(true);
            window.location.replace(`${SHARE}affiliate/onboard/signup/merchant/${id}/merchantStore/${data.merchant_store}`)
          }else if(data.data.length === 0){
            setTimeout(() => {
              setIsValid(false);
              setToastOpen4(true);
              setToastKind4('info');
              setToastTitle4('ACTION');
              setToastDesc4('Referral Link is invalid. Merchant must have at least one product to proceed.');
            }, 1000)
          }
        }else{
          setTimeout(() => {
            setIsValid(false);
            setToastOpen4(true);
            setToastKind4('info');
            setToastTitle4('ACTION');
            setToastDesc4('Referral Link is invalid. Please check the requested URL.');
          }, 1000)
        }
      })
    }else{
      setTimeout(() => {
          setIsValid(false);
          setToastOpen4(true);
          setToastKind4('info');
          setToastTitle4('ACTION');
          setToastDesc4('Referral Link is invalid. Please check the requested URL.');
        }, 1000)
    }
  }, [id])

  const onSubmit = () => {
    navigate('/')
  }

  const onRefresh = () => {
    window.location.reload()
  }

  return (
  <>
    <ToastV4
      btnText='Refresh'
      description={toastDesc4}
      isOpen={isToastOpen4}
      link=''
      onClick={onSubmit}
      onClick2={onRefresh}
      onClose={() => setToastOpen4(false)}
      submitBtnText='Home'
      title={toastTitle4}
      toastKind={toastKind4}
    />
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300 w-full" />
      <Flex className="items-center laptop:pt-32 phone:pt-20 tablet:w-500px phone:w-full">
        
        {
          (isValid === false)?
          <>
          <Text className="font-semibold text-secondary-100 text-2xl mb-5">Almost there!</Text>
          <Text
            className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
            text-center mb-10"
          >
            Validation has been completed.<span className="text-orange-100"> It seems the referral link is invalid or the merchant has no products listed.</span>
          </Text>
          <Text className="mb-10 text-center">
            Please carefully check if the requested URL  has not been tampered.
          </Text>
          </>
          : <></>
        }
        {
          (isValid === '')?
          <>
          <Text className="font-semibold text-secondary-100 text-2xl mb-5">Almost there!</Text>
          <Text
            className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
            text-center mb-10"
          >
            Please wait a few seconds.<span className="text-orange-100"> The referral link is being validated.</span>
          </Text>
          <Text className="mb-10 text-center">
            This may take up to 30 seconds. If you encounter a error please reload the page. Thank
            you!
          </Text>
          </>
          : <></>
        }
        
      </Flex>
    </Flex>
  </>
  );
};