import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../type';

const catalogs = (state: RootState) => state.catalogs;

export const selectcatalogsLoading = createSelector(catalogs, (state) => state.loading);

export const selectcatalogs = createSelector(catalogs, (state) => state.data ?? []);

export const selectAllcatalogsAndMetadata = createSelector(
  selectcatalogs,
  selectcatalogsLoading,
  (data = [], loading = false) => ({ data, loading }),
);
