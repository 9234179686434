import axios from 'axios';
import FormData from 'form-data';
import { API } from '@config';
import { getCookie } from '@hooks';

interface PRODUCT {
  id?: number;
  name: string;
  description: string;
  link: string;
  category: string;
  sub_category: string;
  price: string;
  commission: string;
  productPic: any;
  type: string;
}

export const addProduct = async (body: PRODUCT) => {
  const token = getCookie('token');
  const form = new FormData();
  form.append('name', body.name);
  form.append('description', body.description);
  form.append('link', body.link);
  form.append('category', body.category);
  form.append('sub_category', body.sub_category);
  form.append('price', body.price);
  form.append('commission', body.commission);
  form.append('image', body.productPic[0]);
  form.append('product_picture', body.productPic[0]);
  form.append('type', body.type);
  return axios({
    method: 'POST',
    url: `${API}/add-product`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const addProductAdmin = async (body: PRODUCT) => {
  const token = getCookie('token');
  const form = new FormData();
  form.append('name', body.name);
  form.append('description', body.description);
  form.append('link', body.link);
  form.append('category', body.category);
  form.append('sub_category', body.sub_category);
  form.append('price', body.price);
  form.append('commission', body.commission);
  form.append('image', body.productPic[0]);
  form.append('product_picture', body.productPic[0]);
  form.append('type', body.type);
  return axios({
    method: 'POST',
    url: `${API}/add-admin-product/${body.id}`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const getProductFiltered = async (body: any) =>
  axios.post(`${API}/product-filter`, body).then((response) => response);

  export const getProductFilteredSub = async (body: any) =>
  axios.post(`${API}/product-filter-subcategory`, body).then((response) => response);

export const getProductSearch = async (body: any) =>
  axios.post(`${API}/product-search`, body).then((response) => response);