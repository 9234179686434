import React, { FC, Fragment } from 'react';
import { Dialog, Transition, } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { FlexRow, Button, Div, Toast, InputV2, Text } from '@core';
import { getCookie, } from '@hooks';
import { API } from '@config';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  name: string;
  role: string;
  admin: string;
}

export const ResetPasswordModal: FC<ModalProps> = ({ isOpen, onClose, name, role, admin }) => {

  const navigate = useNavigate();
  const token = getCookie('token');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const onSubmit = () => {
    if(password === '' || confirmPassword === ''){
      setToastTitle('ERROR');
      setToastDesc('Please complete all fields.');
      setToastKind('error');
      setToastOpen(true);
    }else{
      setLoading(true)
      fetch(`${API}/reset-admin-password`,{
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
              admin_id: admin,
              password,
              password_confirmation: confirmPassword,
          })
      })
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setLoading(false)
          setToastTitle('SUCCESS');
          setToastDesc('Password has been updated.');
          setToastKind('success');
          setToastOpen(true);
          navigate('/merchant/settings?action=admin')
        }else{
          setLoading(false)
          setToastTitle('ERROR');
          setToastDesc(data.errors.message || 'Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
        }
      })
    }
  }

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            if(window.location.pathname === `/merchant/dashboard`){
              navigate('/merchant/dashboard');
            }else if(window.location.pathname === `/merchant/settings`){
              navigate('/merchant/settings')
            }
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">RESET ACCOUNT</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      if(window.location.pathname === `/merchant/dashboard`){
                        navigate('/merchant/dashboard');
                      }else if(window.location.pathname === `/merchant/settings`){
                        navigate('/merchant/settings')
                      }
                    }}
                  />
                </FlexRow>

                <Div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide w-full">

                <Text className="font-roboto font-bold text-lg text-orange-100 ml-2">
                  Reset User Password
                </Text>
                <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                  You are about to reset password of {name}, ({role}).
                </Text>
                <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                  <b>Note:</b> This admin can no longer login the account using 
                  the previous password. However, he or she will receive an 
                  email notification that password has been updated by his or her merchant.
                </Text>

                <InputV2
                    label="NEW PASSWORD"
                    name="password"
                    onChange={setPassword}
                    placeholder="New Password"
                    type="password"
                    value={password}
                  />
                <InputV2
                    label="CONFIRM NEW PASSWORD"
                    name="confirm-password"
                    onChange={setConfirmPassword}
                    placeholder="Confirm New Password"
                    type="password"
                    value={confirmPassword}
                  />

                </Div>

                <hr />
                <Div className='flex  mx-8 my-5'>
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    RESET PASSWORD
                  </Button>
                </Div>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};