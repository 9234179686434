/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { batch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Button, Div, DropdownSelect, Flex, FlexCenter, FlexRow, Pagination, Text } from '@src/core';
import { formatCount } from '@src/helpers/formatNumber';
import { partnersActions, selectProductAffiliateList, selectProductAffiliatePage, selectProductAffiliateSearch, useAppDispatch } from '@src/redux';

export const AffiliatePartners: FC<any> = ({affiliateCount, prodID}) => {
  const dispatch = useAppDispatch();

  const productaffiliates = useSelector(selectProductAffiliateList)
  const prodAffiliatesPage = useSelector(selectProductAffiliatePage)
  const prodAffiliatesSearch = useSelector(selectProductAffiliateSearch)

  // const [search, setSearch] = React.useState('')
  const [list, setList] = React.useState<any[]>([])
  const [searchList, setSearchList] = React.useState<any[]>([])
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [sortBy, setSortBy] = React.useState('desc')
  const [sortType, setType] = React.useState('name')

  // Get initial first page of partners
  React.useEffect(() => {
    batch(async () => {
      await dispatch(partnersActions.productAffiliatePage({
        prodID, 
        page: 0, 
        sortBy: 'name', 
        sortType: 'desc'
      }));
    })
    console.log('initial list')
    setList(productaffiliates.productAffiliates)
  }, [])

  // Searching for partners and setting list to all of the results
  React.useEffect(() => {
    console.log('switching to searching..', prodAffiliatesSearch)
    setSearchList(prodAffiliatesSearch.prodAffSearch)
    setList(prodAffiliatesSearch.prodAffSearch.slice(0, 5))
  }, [prodAffiliatesSearch])

  // Change PageData when page is changed
  React.useEffect(() => {
    console.log('page changed', list)
    if (search.length != 0) {
      const pr = page - 1
      const r1 = pr * 5
      const r2 = r1 + 5
      const pageData = searchList.slice(r1, r2);
      console.log('pageData', pageData, 'range', r1, r2)
      setList(pageData)
    }
  }, [page])

  // Positioning this first to load data and have the first page load after
  React.useEffect(() => {
      if (search.length === 0) {
        console.log('initial', prodAffiliatesPage)
        setList(prodAffiliatesPage.prodAffPage)
      }
  }, [prodAffiliatesPage])

  // Setting query to search and executing dispatch for searching
  function handleChange(query: string) {
    console.log('searching..')
    setSearch(query)
    dispatch(partnersActions.productAffiliateSearch({
      prodID, 
      search: query,
      sortBy, 
      sortType
    }))
  }

  return (
    <Flex>
      <Flex className="gap-4">
        <FlexRow className="justify-between">
          <FlexRow className='gap-4'>
              <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
                  rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-40 py-1 tablet:w-80 tablet:py-0"
              >
                  <i className="fas fa-search text-xl" />
                  <input className="placeholder-grey-700 laptop:text-base
                      tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
                      onChange={(e) => {
                        handleChange(e.target.value)
                      }}
                      placeholder="Search"
                  />
              </div>
              {/* <Div className="phone:w-32 tablet:w-40">
                  <DropdownSelect
                      className="text-secondary-100 border border-primary-100"
                      color="white"
                      current=""
                      items={[]}
                      onSelect={() => {}}
                      placeholder='Sort By'
                      width="w-full"
                  />
              </Div> */}
          </FlexRow>
          {/* <Button className='cursor-not-allowed bg-primary-400' disabled>
              Export to CSV
          </Button> */}
        </FlexRow>
        <FlexRow className="grid grid-cols-9 font-medium text-grey-1000 px-0 pt-0 tablet:px-4 tablet:pt-6">
          <Text className="hidden tablet:block col-span-4">Name</Text>
          <Text className="hidden tablet:block col-span-3">Social Link</Text>
          <Text className="hidden tablet:block col-span-2 text-right">Statistics</Text>
        </FlexRow>
        {list.length !== 0 ? (
          list.map((affiliate: any, i) => (
            <FlexRow className="bg-grey-900 p-6" key={`${i}-${affiliate.social_link}`}>
              <Div className="bg-grey-1200 p-1 rounded flex justify-center w-24">
                <i className="fas fa-user h-24 fa-4x text-grey-500 flex items-center" />
              </Div>
              <FlexRow className="pl-3 grid grid-cols-9 w-full">
                <Div className="col-span-6 flex phone:flex-col tablet:flex-row tablet:grid grid-cols-6">
                  <Text className="text-lg font-semibold tablet:col-span-3">
                    {affiliate?.firstname?.concat(' ', affiliate?.lastname) || 'N/A'}
                  </Text>
                  <Text className="text-sm font-medium text-grey-700 truncate ... tablet:col-span-3">
                    <a href={affiliate.social_link}>{affiliate?.social_link}</a>
                  </Text>
                </Div>
                <Div className="col-span-3 ml-auto gap-x-4 gap-y-2 text-primary-100 font-medium text-sm flex phone:flex-col tablet:flex-row">
                  <a data-for="revenue" data-tip>
                    <FlexRow className="bg-light-200 rounded-full p-2">
                      <i className="fas fa-hand-holding-usd text-orange-400 pr-2" />
                      <Text>{`${formatCount(affiliate.earnings)}.00`}</Text>
                    </FlexRow>
                  </a>
                  <ReactTooltip
                    backgroundColor="#FFFFFF"
                    delayHide={150}
                    effect="solid"
                    id="revenue"
                    textColor="#073763"
                  >
                    <Text>Revenue</Text>
                  </ReactTooltip>
                  <a data-for="clicks" data-tip>
                    <FlexRow className="bg-light-200 rounded-full p-2">
                      <i className="fas fa-mouse-pointer text-orange-400 pr-2" />
                      <Text>{formatCount(affiliate.total_cliked)}</Text>
                    </FlexRow>
                  </a>
                  <ReactTooltip
                    backgroundColor="#FFFFFF"
                    delayHide={150}
                    effect="solid"
                    id="clicks"
                    textColor="#073763"
                  >
                    <Text>Clicks</Text>
                  </ReactTooltip>
                  <a data-for="conversions" data-tip>
                    <FlexRow className="bg-light-200 rounded-full p-2">
                      <i className="fas fa-repeat text-orange-400 pr-2" />
                      <Text>{formatCount(affiliate.conversion)}</Text>
                    </FlexRow>
                  </a>
                  <ReactTooltip
                    backgroundColor="#FFFFFF"
                    delayHide={150}
                    effect="solid"
                    id="conversions"
                    textColor="#073763"
                  >
                    <Text>Conversions</Text>
                  </ReactTooltip>
                </Div>
              </FlexRow>
            </FlexRow>
          ))
        ) : (
          <FlexCenter className="col-span-2 pt-4">
            <Text>Currently no affiliates at this time.</Text>
          </FlexCenter>
        )}
        
      </Flex>
      <FlexCenter className='pt-8'>
        <Pagination 
          currentPage={page} 
          onPageChange={setPage} 
          pageSize={5} 
          siblingCount={2} 
          totalCount={search.length===0 ? affiliateCount : searchList.length} 
        />
      </FlexCenter>
    </Flex>
  );
};
