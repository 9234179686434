import axios from 'axios';
import FormData from 'form-data';
import { API } from '@config';
import { getCookie } from '@hooks';

interface FILES {
  terms_service: any;
  privacy_policy: any;
}

interface ASSET {
  title: string;
  content: string;
  hashtag: string;
  hidden?: string | null;
}

interface CREATEDISBURSEMENT {
  affiliate_id: string;
  transaction_id: string;
  amount: number;
}

export const uploadFile = async (body: FILES) => {
    const token = getCookie('token');
    const form = new FormData();
    form.append('terms_service', body.terms_service);
    form.append('privacy_policy', body.privacy_policy);
    return axios({
      method: 'POST',
      url: `${API}/upload-terms-and-conditions`,
      data: form,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    }).then((response) => response);
};

export const updateTerms = async (body: FILES) => {
    const token = getCookie('token');
    const form = new FormData();
    form.append('terms_service', body.terms_service);
    return axios({
      method: 'POST',
      url: `${API}/update-terms-and-conditions`,
      data: form,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    }).then((response) => response);
};

export const updatePolicy = async (body: FILES) => {
    const token = getCookie('token');
    const form = new FormData();
    form.append('privacy_policy', body.privacy_policy);
    return axios({
      method: 'POST',
      url: `${API}/update-terms-and-conditions`,
      data: form,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    }).then((response) => response);
};

export const updateFiles = async (body: FILES) => {
    const token = getCookie('token');
    const form = new FormData();
    form.append('terms_service', body.terms_service);
    form.append('privacy_policy', body.privacy_policy);
    return axios({
      method: 'POST',
      url: `${API}/update-terms-and-conditions`,
      data: form,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    }).then((response) => response);
};

export const createDisbursement = async (body: CREATEDISBURSEMENT) => {
  const token = getCookie('token');
  return axios({
    method: 'POST',
    url: `${API}/create-manual-disbursement`,
    data: body,
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const addAssetByMerchant = async (body: ASSET, id: number) =>
  axios.post(`${API}/create-asset/${id}`, body).then((response) => response);

export const addAssetByAffiliate = async (body: ASSET, id: number) =>
  axios.post(`${API}/affiliate-create-asset/${id}`, body).then((response) => response);