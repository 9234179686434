import React from 'react';
import { batch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import xendit from '../../assets/icons/xendit-big.png';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { Flex, FlexRow, Icons, UserDropdown, Text, Button } from '@core';

export const Failed = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const newDate = new Date().toString().substring(0, 16)

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, [dispatch, batch, usersActions]);

  return (
      <Flex className="laptop:px-20 tablet:px-10 phone:px-5">
        <FlexRow className="py-7">
          <Link className="mr-auto flex flex-row items-center" to="/merchant/dashboard">
            <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
            <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none phone:hidden tablet:block">
              Xffiliate
            </Text>
            <Text className="italic text-grey-100 pl-1 font-light self-end pb-2 phone:hidden tablet:block">
              Merchant
            </Text>
          </Link>
          <UserDropdown info={user.user_info} isWhite noHidden user={user} />
        </FlexRow>
        <hr className="text-secondary-300" />
        <Flex className="items-center laptop:pt-32 tablet:pt-20 phone:pt-12 overflow-y-scroll scrollbar-hide pb-10">
          <Text className="font-semibold text-secondary-100 medium:text-2xl mb-5">Hi {user?.user_info?.firstname}!</Text>
          <Text
            className="font-poppins font-bold medium:text-2xl text-primary-100
          text-center surfaceDuo:mb-10"
          >
            Sorry, it seems there is a problem with your subscription.
          </Text>

          <div className='medium:px-10 py-10 surfaceDuo:border-2 surfaceDuo:border-primary-100 rounded-lg w-full tabletWide:w-3/5 surfaceDuo:shadow-xl'>
            <Text className='text-secondary-200 font-semibold medium:text-2xl text-center'>
              TRANSACTION DETAILS:
            </Text>
            <hr className="text-grey-400 mt-3 mb-10" />

            <FlexRow className='justify-between medium:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Paid Amount:
              </Text>
              <Text className='medium:-xl text-secondary-200'>
                &#8369; 0.00
              </Text>
            </FlexRow>

            <FlexRow className='justify-between medium:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Transaction Amount:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                &#8369; 0.00
              </Text>
            </FlexRow>

            <FlexRow className='justify-between medium:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Transactions count:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                0
              </Text>
            </FlexRow>

            <FlexRow className='justify-between medium:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Transaction date:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                {newDate}
              </Text>
            </FlexRow>

            <FlexRow className='justify-center items-center mt-10'>
              <Text className='text-secondary-200 text-xs mx-2 font-semibold'>
                Powered by:
              </Text>
              <img alt="Bank Icon" className='mx-2 block' src={xendit} style={{width: '158px', height: '43px'}}/>
            </FlexRow>

          </div>

          <Text className="my-10 text-center">
            For more concerns, you may reach us from this link. <a className='mx-1 text-blue-100' href="https://xffiliate.com/contact-us" rel="noopener noreferrer" target="_blank">Contact Us</a>
          </Text>
          <Link to="/merchant/dashboard">
            <Button className="px-16">Go to Dashboard</Button>
          </Link>
        </Flex>
      </Flex>
  );
};