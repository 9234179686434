import React, { FC, Fragment } from 'react';
import { batch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { NotificationBell } from '../NotifBell';
import { SuperAdminLinks, SuperAdminDrawers } from '@constants';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { Div, Text, UserDropdownSA, Icons } from '@core';

interface ModalProps {
  isOpen: boolean;
  onClose?: any;
}

const NavigationDrawerSA: FC<ModalProps> = ({ isOpen }) => {
  const location = useLocation();

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed h-full mt-24 w-72 inset-0 z-10 overflow-hide "
        onClose={() => 'noop'}
      >
        <div className="h-full text-center flex justify-start">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 -translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-20 py-5 items-center
              align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
            >
              {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
              {SuperAdminLinks[0].links.map((value) => (
                <Link
                  className={`w-full py-5 tex-base font-medium hover:text-blue-100
                  pl-10 ${
                    location.pathname === value.url
                      ? 'border-r-4 text-blue-100'
                      : 'text-primary-100'
                  }`}
                  key={value.name}
                  to={value.url}
                >
                  <i className={`${value.icon} w-14 text-2xl`} />
                  <span className="font-poppins text-primary-100">{value.name}</span>
                </Link>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const UserDrawerSA: FC<ModalProps> = ({ isOpen }) => {
  const location = useLocation();

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed h-full mt-24 ml-auto w-72 inset-0 z-20 overflow-hide "
        onClose={() => 'noop'}
      >
        <div className="h-full text-center flex justify-start">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-20 py-5 items-center
              align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
            >
              {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
              {SuperAdminDrawers[0].links.map((value) => (
                <Link
                  className={`w-full py-5 tex-base font-medium hover:text-blue-100 pl-10
               ${
                 location.pathname === value.url ? 'border-l-4 text-blue-100' : 'text-primary-100'
               }`}
                  key={value.name}
                  to={value.url}
                >
                  <i className={`${value.icon} w-14 text-2xl`} />
                  <span className="font-poppins text-primary-100">{value.name}</span>
                </Link>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const NavBarSuperAdmin: FC<any> = () => {

  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [drawer, setDrawer] = React.useState(false);
  const [userDrawer, setuserDrawer] = React.useState(false);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, []);

  return (
    <>
      <Div
        className="h-24 tablet:px-10 phone:px-5 py-5 flex flex-row items-center 
    bg-primary-100 z-20 fixed w-full"
      >
        <NavigationDrawerSA isOpen={drawer} onClose={() => setDrawer(false)} />
        <UserDrawerSA isOpen={userDrawer} onClose={() => setuserDrawer(false)} />
        <Link className="mr-5 flex flex-row items-center" to="/admin/dashboard">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-white mr-auto text-3xl font-semibold select-none phone:hidden tablet:block">
            Xffiliate
          </Text>
        </Link>
        <div
          className="h-full w-16 rounded-lg bg-primary-500 flex justify-center items-center 
        hover:bg-blue-200 cursor-pointer mr-auto"
          onClick={() => {
            setuserDrawer(false);
            setDrawer(!drawer);
          }}
        >
          {drawer ? (
            <i className="fas fa-times laptop:text-3xl phone:text-2xl text-white" />
          ) : (
            <i className="fas fa-bars laptop:text-3xl phone:text-2xl text-white" />
          )}
        </div>
        <NotificationBell notifLink='/admin/dashboard' notifLink2='/admin/dashboard' userLink="/admin/notifications"/>
        <div
          className="h-full w-16 rounded-lg bg-primary-500 justify-center items-center 
        hover:bg-blue-200 cursor-pointer phone:ml-4 tabletWide:ml-0 tabletWide:hidden phone:flex"
          onClick={() => {
            setuserDrawer(!userDrawer);
            setDrawer(false);
          }}
        >
          {userDrawer ? (
            <i className="fas fa-times laptop:text-3xl phone:text-2xl text-white" />
          ) : (
            <i className="fas fa-user laptop:text-3xl phone:text-2xl text-white" />
          )}
        </div>
        <UserDropdownSA info={user.user_info} />
      </Div>
    </>
  );
};