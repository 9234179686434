import React, { FC } from 'react';

interface InputProps {
  className?: string;
  props?: any;
  name?: string;
  type: string;
  placeholder?: string;
  label: string;
  validation?: boolean;
  errorLabel?: string;
  onChange?: any;
  value?: any;
  lableSize?: string;
  containerClass?: string;
  rows?: number;
  maxlength?: number;
}

export const TextArea: FC<InputProps> = ({
  className,
  placeholder,
  label,
  onChange,
  value,
  name,
  lableSize,
  containerClass,
  rows,
}) => (
  <div className={`mb-6 ${containerClass}`}>
    <div className="flex flex-row">
      <label
        className={`block mb-2 font-poppins font-regular 
        text-primary-100 ${lableSize || 'text-lg'}`}
        htmlFor={name}
      >
        {label}
      </label>
    </div>

    <textarea
      className={`text-primary-100 font-poppins phone:text-sm tablet:text-base scrollbar-hide
      rounded-lg py-5 px-7 focus:ring-primary-200 focus:border-primary-200 block w-full ${className}`}
      id={name}
      name={name}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      rows={rows}
      value={value}
    />
  </div>
);
