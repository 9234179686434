import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Transition } from '@headlessui/react';
import { batch, useSelector } from 'react-redux';
import { FlexRow, Div, Text, ButtonIcon, Flex, FlexCenter, Icons, Toast, ToastCustom } from '@core';
import { Overview, UpdateProductModal, RemoveProductModal, MarketingAssets } from '@components';
import { classNames } from '@helpers';
import { LineGraph } from '@src/components/Graphs';
import { AffiliateApplications, AffiliateApplicationsAdmin, AffiliatePartnersV2 } from '@src/components/ProductTabs';
import { useWindowSize } from '@hooks';
import {
  useAppDispatch,
  partnersActions,
  selectProductsLoading,
  selectProductGraphData,
  selectUser,
} from '@redux';
import { convertedGraph1, convertedGraph2 } from '@src/helpers/convertGraphData';
import { getProductAffiliates, getProductApplicants } from '@services';

interface ProductProps {
  selectedProd?: any;
  setSelected: Function;
}

export const Product: FC<ProductProps> = ({ selectedProd, setSelected }) => {

  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const productLoading: any = useSelector(selectProductsLoading);
  const graphs = useSelector(selectProductGraphData);

  const [updateProdOpen, setUpdateProdOpen] = React.useState(false);
  const [removeProdOpen, setRemoveProdOpen] = React.useState(false);
  const [graph1, setGraph1] = React.useState(true);
  const [graph2, setGraph2] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [assetsView, setAssets] = React.useState(false);

  const [totalAffiliates, setTotalAffiliates] = React.useState(selectedProd?.total_affiliate);
  const [totalApplications, setTotalApplications] = React.useState(
    selectedProd?.total_applications);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const [isToastOpen2, setToastOpen2] = React.useState(false);

  const [g1, setG1] = React.useState<any>();
  const [g2, setG2] = React.useState<any>();

  const tabs = ['Demographics', 'Affiliate Partners', 'Affiliate Applications'];

  const window = useWindowSize();

  const [tabIndex, setTabIndex] = React.useState(0);
  const [onIndex, setOnIndex] = React.useState(0);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  React.useEffect(() => {
    const interval = setInterval(() => {

      batch(async() => {
        const affiliateCount = await getProductAffiliates(selectedProd.id);
        if(affiliateCount.data){  
          setTotalAffiliates(affiliateCount.data.data.length)
        }

        const applicantCount = await getProductApplicants(selectedProd.id);
        if(applicantCount.data){
          setTotalApplications(applicantCount.data.data.length)
        }
      })

    }, 10000);
    return () => clearInterval(interval);
  }, [selectedProd]);

  React.useEffect(() => {
    setTotalAffiliates(selectedProd?.total_affiliate)
    setTotalApplications(selectedProd?.total_applications)
  }, [selectedProd])

  React.useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      if(!assetsView){
        setLoading(false)
        batch(async() => {
          const productData = await dispatch(partnersActions.productGraphData(selectedProd.id));
          if(!productData.payload){
            setToastKind('warning');
            setToastTitle('SERVER');
            setToastDesc('Request Timeout! Refreshing server please wait.');
            setToastOpen(true);
          }
        })
      }
    }, 2000)
  }, [selectedProd]);

  React.useEffect(() => {
    setG1(convertedGraph1(graphs.productGraphs));
    setG2(convertedGraph2(graphs.productGraphs));
  }, [graphs]);

  function checkTabMobile(tab: string) {
    let icon: string = '';
    switch (tab) {
      case tabs[0]:
        icon = 'fas fa-chart-pie';
        break;
      case tabs[1]:
        icon = 'fas fa-users';
        break;
      case tabs[2]:
        icon = 'fas fa-user-plus';
        break;
      default:
        break;
    }
    return <i className={classNames(icon, 'fa-2x')} />;
  }

  function checkTabDesktop(tab: string) {
    switch (tab) {
      case tabs[0]:
        return tabs[0];
      case tabs[1]:
        return `Affilliate Partners (${totalAffiliates})`;
        /*
        or w/ Badge Component
        <div className='relative'>
          Affiliate Partners
          <div
            className='absolute z-20 -top-4 -right-4 bg-red-400 rounded-full
            flex items-center justify-center w-6 h-6'>
            <span className='text-xs text-white'>{totalAffiliates}</span>
          </div>
        </div>
        */
      case tabs[2]:
        return `Affilliate Applications (${totalApplications})`;
        /*
        or w/ Badge Component
        <div className='relative'>
          Affiliate Partners
          <div
            className='absolute z-20 -top-4 -right-4 bg-red-400 rounded-full
            flex items-center justify-center w-6 h-6'>
            <span className='text-xs text-white'>{totalApplications}</span>
          </div>
        </div>
        */
      default:
        return '';
    }
  }

  React.useEffect(() => {
    if(actionQuery === 'application'){
      setTabIndex(2)
      setOnIndex(2)
      navigate(user?.user_info?.role === 'merchant'? '/merchant/dashboard' : '/merchant/admin_dashboard/manager')
      setTimeout(() => {
        const element = document.getElementById('Tabs');
        if (element) {
          element.scrollIntoView({ behavior: "smooth"});
        }
      }, 2500)
    }else if(actionQuery === 'newassets'){
      setAssets(true)
      navigate(user?.user_info?.role === 'merchant'? '/merchant/dashboard' : '/merchant/admin_dashboard/manager')
    }
  }, [actionQuery])

  
  return (
    <>
      <ToastCustom
            isOpen={isToastOpen2}
            onClose={() => setToastOpen2(false)}
      >
        <Div className='w-full h-full'>
          <img
            alt="images"
            className="object-contain h-full w-full rounded-3xl"
            onError={() => setImageLoaded(true)}
            src={imageLoaded ? Icons.xffiliate2 : selectedProd?.image}
          />
        </Div>
      </ToastCustom>
      {!productLoading && !loading && !assetsView?(
        <>
          {' '}
          <Toast
            description={toastDesc}
            isOpen={isToastOpen}
            onClose={() => setToastOpen(false)}
            title={toastTitle}
            toastKind={toastKind}
          />
          <FlexRow className="justify-center mb-10">
            <button
              className="text-grey-100 hover:text-grey-200 phone:hidden tabletWide:block"
              onClick={() => setSelected('')}
              type="button"
            >
              <Text className="items-center flex laptop:text-lg phone:base mr-3">
                <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to overview
              </Text>
            </button>
            <ButtonIcon
              className="pt-2 pb-2 mt-2 justify-center text-primary-100 bg-white border border-grey-100 tabletWide:ml-auto 
              phone:ml-0"
              icon="fas fa-edit text-blue-100"
              onClick={() => setUpdateProdOpen(true)}
            >
              <span className="text-primary-100">Edit</span>
            </ButtonIcon>
            <ButtonIcon
              className="pt-2 pb-2 mt-2 justify-center text-primary-100 bg-white border border-grey-100 phone:mr-auto tabletWide:mr-0"
              icon="fas fa-trash text-red-100"
              onClick={() => setRemoveProdOpen(true)}
            >
              <span className="text-primary-100">Remove</span>
            </ButtonIcon>
          </FlexRow>
          <FlexRow>
            <UpdateProductModal
              isOpen={updateProdOpen}
              onClose={() => setUpdateProdOpen(false)}
              product={selectedProd}
            />
            <RemoveProductModal
              isOpen={removeProdOpen}
              onClose={() => setRemoveProdOpen(false)}
              product={selectedProd}
            />
            <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100">
              PRODUCT DETAILS
            </Text>
          </FlexRow>
          <FlexRow className="mt-5 w-full tablet:flex-row phone:flex-col">
            <div
              className="tablet:w-400px h-400px phone:w-full rounded-3xl"
              onClick={() => setToastOpen2(true)}
            >
              <img
                alt="images"
                className="object-contain h-full w-full rounded-3xl cursor-pointer"
                onError={() => setImageLoaded(true)}
                src={imageLoaded ? Icons.xffiliate2 : selectedProd?.image}
                title='Click to view full image'
              />
            </div>
            <Div
              className="flex flex-col phone:mt-10 w-full tablet:w-1/2 phone:ml-0 
                    tablet:ml-10 tablet:mt-0"
            >
              <FlexRow className='phone:flex-col tablet:flex-row'>
                <Text
                  className="laptop:text-lg phone:text-base font-medium bg-light-200 
                    rounded-lg text-center text-blue-100 px-4"
                >
                  {selectedProd?.category}
                </Text>
                <Text
                  className="phone:text-base font-medium 
                    rounded-lg text-center text-secondary-200 px-2 mt-2 tablet:mt-0"
                >
                  {selectedProd?.sub_category && selectedProd?.sub_category !== "null" && selectedProd?.category !== 'Others' ? selectedProd?.sub_category : ''}
                </Text>
              </FlexRow>
              <Text
                className="laptop:text-3xl phone:text-xl font-semibold
                      rounded-lg text-primary-100 mt-3"
              >
                {selectedProd?.name}
              </Text>
              <Text
                className="laptop:text-base phone:text-sm font-normal
                      rounded-lg text-secondary-100 hidden"
              >
                SKU: {selectedProd?.productCode}
              </Text>
              <div
                className='font-robot font-regular text-secondary-200 laptop:text-base phone:text-sm hover:underline cursor-pointer hidden'
                onClick={() => setToastOpen2(true)}>
                View full image size
              </div>
              <a
                className="laptop:text-base phone:text-sm font-normal
                      rounded-lg text-secondary-100 hover:underline hidden"
                href={`/full_image?action=${selectedProd?.id}`}
                rel="noopener noreferrer" 
                target='_blank'
              >
                View full image size
              </a>
              <Text className="text-orange-100 laptop:text-2xl phone:text-lg font-semibold mt-4">
                PHP{' '}
                {parseFloat(selectedProd?.price)
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'}
              </Text>
              <Text
                className="laptop:text-base phone:text-sm
                      rounded-lg text-secondary-100"
              >
                {selectedProd?.description}
              </Text>
              <Text
                className="laptop:text-xl phone:text-base font-semibold
                      rounded-lg text-secondary-100 mt-9"
              >
                <span className="laptop:text-3xl phone:text-xl mx-1 text-blue-400 font-semibold">
                  {selectedProd?.commission.replace('%', '')}%
                </span>
                commission
              </Text>
              <FlexRow className="mt-7 laptop:flex-row phone:flex-col">
                <a
                  className="phone:w-full laptop:w-40 mb-2 laptop:mb-0"
                  href={selectedProd?.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ButtonIcon
                    className="bg-grey-800 hover:bg-primary-300 phone:text-primary-500 pt-3 p-0 phone:w-full laptop:w-40 justify-center"
                    icon="fas fa-external-link"
                  >
                    Website
                  </ButtonIcon>
                </a>
                <ButtonIcon
                  className="bg-primary-100 pt-3 phone:mr-0 phone:w-full laptop:w-auto laptop:ml-2 justify-center"
                  icon="fas fa-file"
                  onClick={() => setAssets(true)}
                >
                  Marketing Assets
                </ButtonIcon>
              </FlexRow>
            </Div>
          </FlexRow>
          <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100 mt-12">
            PRODUCT OVERVIEW
          </Text>
          <FlexRow
            className="mt-5 laptop:flex-row phone:flex-col 
              laptop:space-x-5 laptop:space-y-0
              phone:space-x-0 phone:space-y-3"
          >
            <Overview icon="users" label="AFFILIATES" value={selectedProd.total_affiliate} />
            <Overview icon="mouse-pointer" label="CLICKS" value={selectedProd?.total_cliked} />
            <Overview icon="repeat" label="CONVERSIONS" value={selectedProd?.conversion || 0} />
          </FlexRow>
          <FlexRow className="py-8 justify-center">
            <button
              className="bg-grey-900 h-40 px-2"
              onClick={() => {
                setGraph1(true);
                setGraph2(false);
              }}
            >
              <i className="fas fa-angle-left text-4xl text-primary-100" />
            </button>
            <Div className="overflow-x-auto w-full h-full">
              <Transition
                appear
                className="h-400px tablet:w-full phone:w-1000px "
                enter="transition-opacity duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={graph1}
              >
                {graphs.productGraphs.data.graph1.commission.length !== 0 ||
                graphs.productGraphs.data.graph1.revenue.length !== 0 ? (
                  <LineGraph
                    color="#42B963"
                    color2="#0C61B0"
                    data={g1}
                    titles={['Commissions', 'Revenue']}
                  />
                ) : (
                  <FlexCenter>No data recorded at this time.</FlexCenter>
                )}
              </Transition>
              <Transition
                className="h-400px tablet:w-full phone:w-1000px"
                enter="transition-opacity duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={graph2}
              >
                {graphs.productGraphs.data.graph2.clicks.length !== 0 ||
                graphs.productGraphs.data.graph2.conversion.length !== 0 ? (
                  <LineGraph
                    color="#FF6C3E"
                    color2="#1184F0"
                    data={g2}
                    titles={['Commissions', 'Revenue']}
                  />
                ) : (
                  <FlexCenter>No data recorded at this time.</FlexCenter>
                )}
              </Transition>
            </Div>
            <button
              className="bg-grey-900 h-40 px-2"
              onClick={() => {
                setGraph2(true);
                setGraph1(false);
              }}
            >
              <i className="fas fa-angle-right text-4xl text-primary-100" />
            </button>
          </FlexRow>
          <FlexRow className="gap-x-10 phone:flex-col items-start tablet:flex-row hidden">
            <Div className="self-start">
              <Text className="text-grey-100 text-xs tracking-wider w-fit">Total Revenue</Text>
              <Text className="text-dark-100 font-bold text-2xl w-fit">{`PHP ${selectedProd?.earnings.toFixed(
                2,
              )}`}</Text>
              <Text className="pt-2 text-green-400 text-xs">
                <i className="fas fa-arrow-up pr-1" />
                0.00%
              </Text>
            </Div>
            <Div className="self-start">
              <Text className="text-grey-100 text-xs tracking-wider">Total Commissions due</Text>
              <FlexRow>
                <Text className="text-dark-100 font-bold text-2xl">PHP 0</Text>
                <button
                  className="ml-10 px-4 py-1 rounded tracking-wider text-xs bg-blue-400 text-grey-900 hover:bg-blue-300"
                  disabled
                >
                  DISBURSE
                </button>
              </FlexRow>
              <Text className="pt-2 text-grey-100 text-xs">
                Next disbursement cut-off: <b className="font-medium text-blue-400">--</b>
              </Text>
            </Div>
          </FlexRow>
          <FlexRow className="pt-16" id="Tabs">
            <Tab.Group
              defaultIndex={tabIndex}
              onChange={(indexTab) => {
                setOnIndex(indexTab)
              }}
            >
              <Flex>
                <Tab.List className="tabletWide:space-x-4">
                  {tabs.map((tab) => (
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          'py-3 font-semibold phone:w-1/3 tabletWide:w-auto',
                          selected
                            ? 'text-primary-100 border-b-4 border-blue-400'
                            : 'text-grey-700  hover:text-grey-200',
                        )
                      }
                      key={tab}
                    >
                      {window.width > 800 ? checkTabDesktop(tab) : checkTabMobile(tab)}
                    </Tab>
                  ))}
                </Tab.List>
                <hr className="text-grey-1200" />
                <Tab.Panels className="pt-9">
                  <Tab.Panel>
                    Coming Soon!
                    {/* <Demographics product="test" /> */}
                  </Tab.Panel>
                  <Tab.Panel>
                    <AffiliatePartnersV2
                      affiliateCount={totalAffiliates}
                      onIndex={onIndex}
                      prodID={selectedProd.id}
                      product={selectedProd.name}
                      user={user}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    {
                      user?.user_info?.role === 'merchant' ?
                      <AffiliateApplications
                        onIndex={onIndex}
                        prodID={selectedProd.id}
                        user={user}
                      />
                      :
                      <AffiliateApplicationsAdmin
                        onIndex={onIndex}
                        prodID={selectedProd.id}
                        user={user}
                      />
                    }
                  </Tab.Panel>
                </Tab.Panels>
              </Flex>
            </Tab.Group>
          </FlexRow>
        </>
      ) : (
        <>
        {
          assetsView?
          <MarketingAssets selectedProd={selectedProd} setAssets={setAssets} />
          :
          <Flex className="items-center justify-center w-full">
            <Div className="flex flex-row items-center">
              <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate || 'https://github.com/XtendlyORG/xffiliate-frontend/blob/development/src/assets/icons/xffiliate-logo.png?raw=true'} />
              <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
                Xffiliate
              </Text>
            </Div>
            <Text className='text-secondary-200 py-8 text-center'>
              Loading data ...
              <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
            </Text>
          </Flex>
        }
        </>
      )}
    </>
  );
};