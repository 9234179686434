import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addPartner, getLinks, getIPAddress, addClicked } from '@services';

interface LINK {
  user_id: string;
  product_id: string;
  status: string;
  role: string;
}

interface STATE {
  loading: boolean;
  data: [];
}

interface CLICKED {
  url: string;
  ip_address: string;
}

export const linksInitialState: STATE = {
  loading: false,
  data: [],
};

const linkList = createAsyncThunk('links/linkList', async () => {
  const data: any = await getLinks();
  const links = Object.values(data.data.data);
  return {
    data: links,
  };
});

const ipAddress = createAsyncThunk('links/ipAddress', async () => {
  const data: any = await getIPAddress();
  return data;
});

const linkClicked = createAsyncThunk('links/linkClicked', async (body: CLICKED) => {
  const data: any = await addClicked(body);
  return data;
});

const newLink = createAsyncThunk('links/newLink', async (body: LINK) => {
  const { data, status }: any = await addPartner(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const { actions, reducer } = createSlice({
  name: 'links',
  initialState: linksInitialState,
  reducers: {},
  extraReducers: {
    [linkList.pending.type]: (state) => {
      state.loading = true;
    },
    [linkList.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
  },
});

export const linksActions = {
  ...actions,
  newLink,
  linkList,
  ipAddress,
  linkClicked,
};

export const linksReducer = reducer;
