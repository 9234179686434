import React, { FC, Fragment } from 'react';
import { batch, useSelector } from 'react-redux';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { Pie } from '@nivo/pie';
import useSound from 'use-sound';
import { NotificationBell } from './NotifBell';
import { AllLinks, Create, UserDrawers } from '@constants';
import { usersActions, notifsActions, useAppDispatch, selectUser, selectNotifs } from '@redux';
import { Div, Dropdown, UserDropdown, Text, Icons, SoundMp3, FlexRow } from '@core';
import { useSubscription, getCookie } from '@hooks';
import { API } from '@config';

interface ModalProps {
  isOpen: boolean;
  onClose?: any;
}

const AccountData: FC<any> = ({ containerClass }) => {
  const token = getCookie('token');
  const user = useSelector(selectUser);
  const subscription = useSubscription();

  const productCount: any = user.merchant_data.total_products;
  const limitCount: any = subscription.product.limit;

  const affiliateCount: any = user.merchant_data.total_links;
  const affLimitCount: any = subscription.affiliate.limit;

  const [productRate, setProductRate] = React.useState(0);
  const [affiliateRate, setAffiliateRate] = React.useState(0);
  const [adminCount, setAdminCount] = React.useState(0);

  React.useEffect(() => {
    fetch(`${API}/get-merchant-users`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          setAdminCount(data.data.length);
        }
      });
  }, []);

  const productData = [
    {
      id: 'Current',
      label: 'Current',
      value: user?.merchant_data?.total_products,
    },
  ];

  const affiliateData = [
    {
      id: 'Current',
      label: 'Current',
      value: user?.merchant_data?.total_links,
    },
  ];

  const adminData = [
    {
      id: "Current",
      label: "Current",
      value: adminCount,
    },
  ];

  React.useEffect(() => {
    if (productCount === 0 && subscription.status === 'PRO PLAN') {
      setProductRate(0);
    } else if (productCount >= 0 && subscription.status === 'PRO PLAN') {
      setProductRate(100);
    } else if (productCount <= limitCount && subscription.status !== 'PRO PLAN') {
      setProductRate((productCount / limitCount) * 100);
    } else if (productCount >= limitCount) {
      setProductRate(100);
    }

    if (affiliateCount === 0 && subscription.status !== 'FREE PLAN') {
      setAffiliateRate(0);
    } else if (affiliateCount >= 0 && subscription.status !== 'FREE PLAN') {
      setAffiliateRate(100);
    } else if (affiliateCount <= affLimitCount && subscription.status === 'FREE PLAN') {
      setAffiliateRate((affiliateCount / affLimitCount) * 100);
    } else if (affiliateCount >= affLimitCount) {
      setAffiliateRate(100);
    }
  });

  return (
    <Menu as="div" className={`relative hidden tabletWide:inline-block ${containerClass}`}>
      <div>
        <Menu.Button>
          <i
            className="fas fa-chart-pie mr-3 tablet:mr-6 text-2xl text-white"
            title="Account Usage"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="phone:hidden tabletWide:flex absolute flex-col divide-y divide-grey-400 bg-white z-50 mt-2 shadow shadow-xl w-72 rounded-md right-0 border border-primary-100">
          <Menu.Item>
            <Div className="p-4 parentToolTip">
              <Div className="childTooltip bg-grey-500 border border-secondary-200 rounded-md w-72 phone:left-0 tabletWide:left-full py-4 px-8 z-10">
                <Text className="font-bold text-left">Products</Text>
                <FlexRow className="justify-between my-4">
                  <Div className="relative">
                    <Pie
                      activeInnerRadiusOffset={8}
                      colors='#0C61B0'
                      cornerRadius={3}
                      data={productData}
                      enableArcLabels={false}
                      enableArcLinkLabels={false}
                      height={100}
                      innerRadius={0.7}
                      padAngle={0.7}
                      width={100}
                    />
                    <Text className="absolute top-1/3 text-lg text-primary-100 font-bold text-center w-full">
                      {productRate > 0 ? 100 : 0.0}%
                    </Text>
                  </Div>
                  <Div>
                      <div className="text-sm flex flex-row items-center">
                        <i className="fas fa-circle text-xs mr-2 text-primary-100" />
                        <FlexRow className="phone:flex-col">
                          <span className="mr-auto font-bold text-primary-100 text-xl">
                            {productCount}
                          </span>
                          <span className="mr-auto text-xs">Current</span>
                        </FlexRow>
                      </div>
                  </Div>
                </FlexRow>
              </Div>
              <FlexRow className="w-full">
                {productCount === 0 ? (
                  <div title="No product data">
                    <i className='fas fa-box text-grey-400' />
                  </div>
                ) : (
                  <div title={`${user?.merchant_data?.total_products} Products`}>
                    <i className='fas fa-box text-blue-100' />
                  </div>
                )}
                <FlexRow className="ml-4 w-full justify-between">
                  <Text>Products</Text>
                  <Text>
                    {user?.merchant_data?.total_products}/
                    <i className="fas fa-infinity" />
                  </Text>
                </FlexRow>
              </FlexRow>
            </Div>
          </Menu.Item>
          <Menu.Item>
            <Div className="p-4 parentToolTip">
              <Div className="childTooltip bg-grey-500 border border-secondary-200 rounded-md w-72 phone:left-0 tabletWide:left-full py-4 px-8 z-10">
                <Text className="font-bold text-left">Affiliate Slots</Text>
                <FlexRow className="justify-between my-4">
                  <Div className="relative">
                    <Pie
                      activeInnerRadiusOffset={8}
                      colors='#0C61B0'
                      cornerRadius={3}
                      data={affiliateData}
                      enableArcLabels={false}
                      enableArcLinkLabels={false}
                      height={100}
                      innerRadius={0.7}
                      padAngle={0.7}
                      width={100}
                    />
                    <Text className="absolute top-1/3 text-lg text-primary-100 font-bold text-center w-full">
                      {affiliateRate > 0 ? 100 : 0.0}%
                    </Text>
                  </Div>
                  <Div>
                      <div className="text-sm flex flex-row items-center">
                        <i className="fas fa-circle text-xs mr-2 text-primary-100" />
                        <FlexRow className="phone:flex-col">
                          <span className="mr-auto font-bold text-primary-100 text-xl">
                            {affiliateCount}
                          </span>
                          <span className="mr-auto text-xs">Current</span>
                        </FlexRow>
                      </div>
                  </Div>
                </FlexRow>
              </Div>
              <FlexRow className="w-full">
                {affiliateCount === 0 ? (
                  <div title="No affiliate data">
                    <i className='fas fa-users text-grey-400' />
                  </div>
                ) : (
                  <div title={`${user?.merchant_data?.total_links} Affiliate slots`}>
                    <i className='fas fa-users text-blue-100' />
                  </div>
                )}

                <FlexRow className="ml-4 w-full justify-between">
                  <Text>Affiliate Slots</Text>
                  <Text>
                    {user?.merchant_data?.total_links}/
                    <i className="fas fa-infinity" />
                  </Text>
                </FlexRow>
              </FlexRow>
            </Div>
          </Menu.Item>

          <Menu.Item>
            <Div className="p-4 parentToolTip">
              <Div className="childTooltip bg-grey-500 border border-secondary-200 rounded-md w-72 phone:left-0 tabletWide:left-full py-4 px-8 z-10">
                <Text className="font-bold text-left">Users</Text>
                <FlexRow className="justify-between my-4">
                  <Div className="relative">
                    <Pie
                      activeInnerRadiusOffset={8}
                      colors={`${subscription.status === 'FREE PLAN' && adminCount > 0 ? '#FF733F' : "#0C61B0" }`}
                      cornerRadius={3}
                      data={adminData}
                      enableArcLabels={false}
                      enableArcLinkLabels={false}
                      height={100}
                      innerRadius={0.7}
                      padAngle={0.7}
                      width={100}
                    />
                    <Text className={`${subscription.status === 'FREE PLAN' && adminCount > 0 ? "text-orange-100" : "text-primary-100"} absolute top-1/3 text-lg font-bold text-center w-full`}>
                      {adminCount > 0 ? 100 : 0.0}%
                    </Text>
                  </Div>
                  <Div>
                        <div className="text-sm flex flex-row items-center">
                          <i className="fas fa-circle text-xs mr-2 text-primary-100" />
                          <FlexRow className="phone:flex-col">
                            <span className="mr-auto font-bold text-primary-100 text-xl">
                              {adminCount}
                            </span>
                            <span className="mr-auto text-xs">Current</span>
                          </FlexRow>
                        </div>
                  </Div>
                </FlexRow>
              </Div>
              <FlexRow className="w-full">
                {subscription.status === 'FREE PLAN' ? (
                  <div title={`${adminCount} Users`}>
                    <i className={`fas fa-user-lock ${adminCount > 0 ? "text-orange-100" : "text-grey-400"}`} />
                  </div>
                ) : (
                  <div title={`${adminCount} Users`}>
                    <i className='fas fa-user-lock text-blue-100' />
                  </div>
                )}
                <FlexRow className="ml-4 w-full justify-between">
                  <Text>Users</Text>
                  <Text>
                    {subscription.status === 'PRO PLAN' ? (
                      <>
                        {adminCount}/
                        <i className="fas fa-infinity" />
                      </>
                    ) : (
                      <>
                      {
                        adminCount === 0
                        ? <>
                            {adminCount}/1
                          </>
                        : <Link
                          className="px-2 text-white bg-orange-100 hover:bg-orange-200"
                          to="/merchant/subscription"
                        >
                          Upgrade
                        </Link>
                      }
                      </>
                    )}
                  </Text>
                </FlexRow>
              </FlexRow>
            </Div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const AccountDataDrawer: FC<ModalProps> = ({ isOpen, onClose }) => {
  const token = getCookie('token');
  const user = useSelector(selectUser);
  const subscription = useSubscription();

  const productCount: any = user.merchant_data.total_products;
  const limitCount: any = subscription.product.limit;
  const affiliateCount: any = user.merchant_data.total_links;
  const affLimitCount: any = subscription.affiliate.limit;

  const [productRate, setProductRate] = React.useState(0);
  const [affiliateRate, setAffiliateRate] = React.useState(0);
  const [adminCount, setAdminCount] = React.useState(0);

  React.useEffect(() => {
    fetch(`${API}/get-merchant-users`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          setAdminCount(data.data.length);
        }
      });
  }, []);

  const productData = [
    {
      id: 'Current',
      label: 'Current',
      value: user?.merchant_data?.total_products,
    },
  ];

  const affiliateData = [
    {
      id: 'Current',
      label: 'Current',
      value: user?.merchant_data?.total_links,
    },
  ];

  const adminData = [
    {
      id: "Current",
      label: "Current",
      value: adminCount,
    },
  ];

  React.useEffect(() => {
    if (productCount === 0 && subscription.status === 'PRO PLAN') {
      setProductRate(0);
    } else if (productCount >= 0 && subscription.status === 'PRO PLAN') {
      setProductRate(100);
    } else if (productCount <= limitCount && subscription.status !== 'PRO PLAN') {
      setProductRate((productCount / limitCount) * 100);
    } else if (productCount >= limitCount) {
      setProductRate(100);
    }

    if (affiliateCount === 0 && subscription.status !== 'FREE PLAN') {
      setAffiliateRate(0);
    } else if (affiliateCount >= 0 && subscription.status !== 'FREE PLAN') {
      setAffiliateRate(100);
    } else if (affiliateCount <= affLimitCount && subscription.status === 'FREE PLAN') {
      setAffiliateRate((affiliateCount / affLimitCount) * 100);
    } else if (affiliateCount >= affLimitCount) {
      setAffiliateRate(100);
    }
  });

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed h-full w-full inset-0 z-40 overflow-hide bg-secondary-200"
        onClose={() => 'noop'}
      >
        <div className="h-full text-center flex justify-start">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full ml-auto max-w-sm text-left z-40 items-center
              align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col border-l border-secondary-100"
            >
              <FlexRow
                className="w-full px-8 py-7 text-2xl font-poppins font-normal leading-6 
              text-white bg-primary-100 border border-secondary-100"
              >
                <Dialog.Title as="h3">USAGE DATA</Dialog.Title>
                <i
                  className="fas fa-times ml-auto hover:text-blue-100
              cursor-pointer"
                  onClick={() => onClose()}
                />
              </FlexRow>

              <Div className="w-full phone:px-4 surfaceDuo:px-8 overflow-y-auto">
                <Text className="font-roboto font-bold text-lg text-orange-100 text-left ml-2 mt-4">
                  Overview
                </Text>
                <Text className="font-roboto font-normal text-base text-grey-100 text-left ml-2 mt-4 mb-6">
                  This is the summary of all products, affiliates, and product links' usage and
                  limits.
                </Text>

                <Div className="bg-grey-500 border border-grey-400 rounded-md w-full py-4 px-8 my-4">
                  <Text className="font-roboto font-bold text-left">Products</Text>
                  <FlexRow className="justify-between my-4">
                    <Div className="relative">
                      <Pie
                        activeInnerRadiusOffset={8}
                        colors='#0C61B0'
                        cornerRadius={3}
                        data={productData}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        height={100}
                        innerRadius={0.7}
                        padAngle={0.7}
                        width={100}
                      />
                      <Text className="absolute top-1/3 text-lg text-primary-100 font-bold text-center w-full">
                        {productRate > 0 ? 100 : 0.0}%
                      </Text>
                    </Div>
                    <Div>
                        <div className="text-sm flex flex-row items-center">
                          <i className="fas fa-circle text-xs mr-2 text-primary-100" />
                          <FlexRow className="phone:flex-col">
                            <span className="mr-auto font-bold text-primary-100 text-xl">
                              {productCount}
                            </span>
                            <span className="mr-auto text-xs">Current</span>
                          </FlexRow>
                        </div>
                    </Div>
                  </FlexRow>
                </Div>
                <Div className="bg-grey-500 border border-grey-400 rounded-md w-full py-4 px-8 my-4">
                  <Text className="font-roboto font-bold text-left">Affiliate Slots</Text>
                  <FlexRow className="justify-between my-4">
                    <Div className="relative">
                      <Pie
                        activeInnerRadiusOffset={8}
                        colors='#0C61B0'
                        cornerRadius={3}
                        data={affiliateData}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        height={100}
                        innerRadius={0.7}
                        padAngle={0.7}
                        width={100}
                      />
                      <Text className="absolute top-1/3 text-lg text-primary-100 font-bold text-center w-full">
                        {affiliateRate > 0 ? 100 : 0.0}%
                      </Text>
                    </Div>
                    <Div>
                        <div className="text-sm flex flex-row items-center">
                          <i className="fas fa-circle text-xs mr-2 text-primary-100" />
                          <FlexRow className="phone:flex-col">
                            <span className="mr-auto font-bold text-primary-100 text-xl">
                              {affiliateCount}
                            </span>
                            <span className="mr-auto text-xs">Current</span>
                          </FlexRow>
                        </div>
                    </Div>
                  </FlexRow>
                </Div>

                <Div className="bg-grey-500 border border-grey-400 rounded-md w-full py-4 px-8 my-4">
                  <Text className="font-roboto font-bold text-left">Users</Text>
                  <FlexRow className="justify-between my-4">
                    <Div className="relative">
                      <Pie
                        activeInnerRadiusOffset={8}
                        colors={`${subscription.status === 'FREE PLAN' && adminCount > 0 ? '#FF733F' : "#0C61B0" }`}
                        cornerRadius={3}
                        data={adminData}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        height={100}
                        innerRadius={0.7}
                        padAngle={0.7}
                        width={100}
                      />
                      <Text className={`${subscription.status === 'FREE PLAN' && adminCount > 0 ? "text-orange-100" : "text-primary-100"} absolute top-1/3 text-lg font-bold text-center w-full`}>
                        {adminCount > 0 ? 100 : 0.0}%
                      </Text>
                    </Div>
                    <Div>
                        <div className="text-sm flex flex-row items-center">
                          <i className="fas fa-circle text-xs mr-2 text-primary-100" />
                          <FlexRow className="phone:flex-col">
                            <span className="mr-auto font-bold text-primary-100 text-xl">
                              {adminCount}
                            </span>
                            <span className="mr-auto text-xs">Current</span>
                          </FlexRow>
                        </div>
                    </Div>
                  </FlexRow>
                </Div>
              </Div>

              <div className="flex w-full phone:px-4 surfaceDuo:px-8 my-5 border-t border-secondary-100 pt-4 mt-auto">
                {subscription.status === 'FREE PLAN' ? (
                  <Link
                    className="py-4 px-4 font-poppins font-regular rounded-lg text-white w-full justify-center items-center text-center bg-blue-100 hover:bg-primary-100 text-lg"
                    to="/merchant/subscription"
                  >
                    UPGRADE MY PLAN
                  </Link>
                ) : (
                  <></>
                )}
                {subscription.status === 'PRO PLAN' ? (
                  <Text className="py-4 px-4 font-poppins font-regular rounded-lg text-white w-full justify-center items-center text-center bg-green-100 text-lg">
                    YOU ARE SUBSCRIBED
                  </Text>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const NavigationDrawer: FC<ModalProps> = ({ isOpen }) => {
  const location = useLocation();

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed h-full mt-24 w-72 inset-0 z-10 overflow-hide hidden"
        onClose={() => 'noop'}
      >
        <div className="h-full text-center flex justify-start">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 -translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-20 py-5 items-center
              align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
            >
              {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
              {AllLinks[0].links.map((value) => (
                <Link
                  className={`w-full py-5 tex-base font-medium hover:text-blue-100
                  hover:border-r-4 pl-10 ${
                    location.pathname === value.url
                      ? 'border-r-4 text-blue-100'
                      : 'text-primary-100'
                  }`}
                  key={value.name}
                  to={value.url}
                >
                  <i className={`${value.icon} w-14 text-2xl`} />
                  <span className="font-poppins text-primary-100">{value.name}</span>
                </Link>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const UserDrawer: FC<ModalProps> = ({ isOpen }) => {
  const location = useLocation();
  const subscription = useSubscription();

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed h-full mt-24 ml-auto w-72 inset-0 z-20 overflow-hide "
        onClose={() => 'noop'}
      >
        <div className="h-full text-center flex justify-start">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-20 py-5 items-center
              align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
            >
              <div className="w-full pl-6 border-b border-secondary-200">
                <div
                  className="p-1 group flex rounded-md items-center
                        w-full px-4 text-base font-medium text-primary-100"
                >
                  {subscription.status}
                </div>
                <FlexRow className="w-full justify-center mt-2 mb-4 pl-4 pr-8">
                  {subscription.status === 'FREE PLAN' ? (
                    <Link
                      className="px-5 py-3 text-white bg-orange-100 text-center w-full"
                      to="/merchant/subscription"
                    >
                      Upgrade My Plan
                    </Link>
                  ) : (
                    <></>
                  )}
                  {subscription.status === 'PLUS PLAN' ? (
                    <Link
                      className="px-5 py-3 text-white bg-green-100 text-center w-full"
                      to="/merchant/subscription"
                    >
                      Upgrade to PRO
                    </Link>
                  ) : (
                    <></>
                  )}
                  {subscription.status === 'PRO PLAN' ? (
                    <button className="px-5 py-3 text-white bg-green-100 text-center w-full">
                      YOU ARE SUBSCRIBED
                    </button>
                  ) : (
                    <></>
                  )}
                </FlexRow>
              </div>
              {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
              {UserDrawers[0].links.map((value) => (
                <Link
                  className={`w-full py-5 tex-base font-medium hover:text-blue-100 pl-10
               ${
                 location.pathname === value.url ? 'border-l-4 text-blue-100' : 'text-primary-100'
               }`}
                  key={value.name}
                  to={value.url}
                >
                  <i className={`${value.icon} w-14 text-2xl`} />
                  <span className="font-poppins text-primary-100">{value.name}</span>
                </Link>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const NavBar: FC<any> = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const notifList = useSelector(selectNotifs);

  const [drawer, setDrawer] = React.useState(false);
  const [userDrawer, setuserDrawer] = React.useState(false);
  const [dataDrawer, setDataDrawer] = React.useState(false);

  const [playSound1] = useSound(SoundMp3.Sound1);
  const [playSound7] = useSound(SoundMp3.Sound7);

  const count = localStorage.getItem('prevNotif');

  React.useEffect(() => {
     batch(async () => {
        const notifs = await dispatch(notifsActions.notifData());

        if (notifs.payload) {
          const list = await notifs.payload.data.data.filter(
            (notif: any) => notif.mark_as_read === null,
          );
          const currentCount = list.length.toString();
          const prevNotif: any = localStorage.getItem('prevNotif');
          localStorage.setItem('notif', user?.user_info?.id.toString());
          const userNum: any = localStorage.getItem('notif');

          if (!prevNotif || !userNum) {
            localStorage.setItem('prevNotif', currentCount);
            localStorage.setItem('notif', user?.user_info?.id.toString());
            if (list.length !== 0) {
              playSound7();
            }
          } else if (parseFloat(userNum) !== user?.user_info?.id) {
            localStorage.setItem('prevNotif', currentCount);
            localStorage.setItem('notif', user?.user_info?.id.toString());
            if (list.length !== 0) {
              playSound7();
            }
          } else if (
            parseFloat(prevNotif) !== list.length &&
            list.length !== 0 &&
            parseFloat(userNum) === user?.user_info?.id
          ) {
            localStorage.setItem('prevNotif', currentCount);
            playSound7();
          }
        }
      });
  }, []);

  React.useEffect(() => {
    if (notifList.data.length !== 0) {
      playSound7();
    }
  }, [count]);

  React.useEffect(() => {
    playSound1();
  }, [drawer, userDrawer]);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, []);

  return (
    <>
      <Div
        className="h-24 laptop:px-20 tablet:px-10 phone:px-2 mobile:px-5 py-5 flex flex-row items-center 
    bg-primary-100 z-30 fixed w-full"
      >
        <NavigationDrawer isOpen={drawer} onClose={() => setDrawer(false)} />
        <UserDrawer isOpen={userDrawer} onClose={() => setuserDrawer(false)} />
        <Link className="flex flex-row items-center mr-auto" to="/merchant/dashboard">
          <img
            alt="Xffiliate"
            className="phone:w-10 phone:h-10 surfaceDuo:w-12 surfaceDuo:h-12 mr-3 select-none"
            src={Icons.xffiliate}
          />
          <Text className="text-white mr-auto text-3xl font-semibold select-none phone:hidden surfaceDuo:block tablet:block">
            Xffiliate
          </Text>
          <Text className="italic text-white pl-1 font-light self-end pb-2 phone:hidden medium:block tablet:block">
            Merchant
          </Text>
        </Link>

        <div className="mr-6 tablet:mr-10 tabletWide:mr-0">
          <Dropdown
            className="bg-primary-500 text-white h-full ml-2"
            color="orange-200"
            current="CREATE"
            items={Create}
            width="tablet:w-40 phone:w-24"
          />
        </div>

        <div
          className="h-full tablet:border-r-4 phone:border-r-2 tabletWide:mr-6 ml-6 phone:hidden
       border-white tabletWide:block mr-4"
        />

        <AccountData />
        <AccountDataDrawer isOpen={dataDrawer} onClose={() => setDataDrawer(false)} />
        <i
          className="fas fa-chart-pie mr-3 mobile:mr-5 tablet:mr-6 text-2xl text-white tabletWide:hidden"
          onClick={() => setDataDrawer(true)}
        />

        <NotificationBell
          notifLink="/merchant/dashboard"
          notifLink2="/merchant/settings?action=payment"
          userLink="/merchant/notifications"
        />
        <div
          className="h-full w-16 rounded-lg bg-primary-500 justify-center items-center 
      hover:bg-blue-200 cursor-pointer tabletWide:hidden phone:flex"
          onClick={() => {
            setuserDrawer(!userDrawer);
            setDrawer(false);
          }}
        >
          {userDrawer ? (
            <i className="fas fa-times laptop:text-3xl phone:text-2xl text-white" />
          ) : (
            <i className="fas fa-user laptop:text-3xl phone:text-2xl text-white" />
          )}
        </div>
        <UserDropdown info={user.user_info} user={user} />
      </Div>
    </>
  );
};
