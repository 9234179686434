import React, { FC } from 'react';
import { Tab } from '@headlessui/react';
import { batch } from 'react-redux';
import { AssetData2 } from '@components';
import { FlexCenter, FlexRow, Flex, Div, Text, Toast, Icons } from '@core';
import { classNames } from '@helpers';
import { useWindowSize } from '@hooks';
import { getAssetByProduct } from '@services';

export const MarketingAssetsRequests: FC<any> = ({selectedProd} : any) => {

	const window = useWindowSize();
	const tabs = ['Pending', 'Approved', 'Rejected'];
	
	const [imageLoaded, setImageLoaded] = React.useState(false);
	const [isShow, setIsShow] = React.useState(false);

	const requestedAssets: any[] = [];
	const approvedAssets: any[] = [];
	const rejectedAssets: any[] = [];
	const [requestedList,setRequestedList] = React.useState<any>([]);
	const [approvedList,setApprovedList] = React.useState<any>([]);
	const [rejectedList,setRejectedList] = React.useState<any>([]);

	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('Account Created');
	const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

	React.useEffect(() => {
		setIsShow(false)
		batch(async() => {
			const assets = await getAssetByProduct(selectedProd.id);
			if(assets.data.status === 1){
				await assets.data.data.map((list: any) => {
					if(list.asset_status === 'Pending'){
						requestedAssets.push(list)
					}else if(list.asset_status === 'Approved'){
						approvedAssets.push(list)
					}else if(list.asset_status === 'Denied'){
						rejectedAssets.push(list)
					}
					return 0;
				})
				setRequestedList(requestedAssets)
				setApprovedList(approvedAssets)
				setRejectedList(rejectedAssets)
				setIsShow(true)
			}else{
				setRequestedList([])
				setApprovedList([])
				setRejectedList([])
				setIsShow(true)
			}
		})
	}, [selectedProd])

	const onRefresh = () => {
		setIsShow(false)
		batch(async() => {
			const assets = await getAssetByProduct(selectedProd.id);
			if(assets.data.status === 1){
				await assets.data.data.map((list: any) => {
					if(list.asset_status === 'Pending'){
						requestedAssets.push(list)
					}else if(list.asset_status === 'Approved'){
						approvedAssets.push(list)
					}else if(list.asset_status === 'Denied'){
						rejectedAssets.push(list)
					}
					return 0;
				})
				setRequestedList(requestedAssets)
				setApprovedList(approvedAssets)
				setRejectedList(rejectedAssets)
				setIsShow(true)
			}else{
				setRequestedList([])
				setApprovedList([])
				setRejectedList([])
				setIsShow(true)
			}
		})
	}

	const onCreate = async () => {
		setToastKind('error');
		setToastTitle('error');
		setToastDesc('Something went wrong.');
		setToastOpen(true);
	}

	const onToast = async (type:any, message:any) => {
		if(type === 'Reject'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('You have rejected the requested item.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'Approve'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('You have successfully approved the requested item.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'Remove'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('Asset successfully removed from the list.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'onSubmit'){
			setToastKind('success');
			setToastTitle('SUCCESS');
			setToastDesc('You have successfully updated the requested item.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}else if(type === 'Error'){
			setToastKind('error');
			setToastTitle('ERROR');
			setToastDesc(message || 'Something went wrong.');
			setToastOpen(true);
			setTimeout(() => {
				setToastOpen(false);
			}, 2000)
		}
	}

	function checkTabMobile(tab: string) {
	  switch (tab) {
	    case tabs[0]:
	      return <img alt='Pending' className='w-6 h-6 mx-auto' src={Icons.pending} />;
	    case tabs[1]:
	      return <img alt='Approved' className='w-6 h-6 mx-auto' src={Icons.approve} />;
	    case tabs[2]:
	      return <img alt='Denied' className='w-6 h-6 mx-auto' src={Icons.denied} />;
	    default:
	      return '';
	  }
	}

	function checkTabDesktop(tab: string) {
	  switch (tab) {
	    case tabs[0]:
	      return tabs[0];
	    case tabs[1]:
	      return 'Approved';
	    case tabs[2]:
	      return 'Rejected';
	    default:
	      return '';
	  }
	}
	
return(
	<>
	  {' '}
	  <Toast
	    description={toastDesc}
	    isOpen={isToastOpen}
	    onClose={() => setToastOpen(false)}
	    title={toastTitle}
	    toastKind={toastKind}
	  />
	  
	  <FlexRow className="mt-5 w-full tablet:flex-row phone:flex-col items-center">
	    <Div className="tablet:w-400px h-400px phone:w-full rounded-3xl">
	      <img
	        alt="images"
	        className="object-contain h-full w-full rounded-3xl"
	        onError={() => setImageLoaded(true)}
	        src={imageLoaded ? 'https://i.imgur.com/CGDGnf6.png' : selectedProd?.image}
	      />
	    </Div>
	    <Div
	      className="flex flex-col phone:mt-10 w-full tablet:w-1/2 phone:ml-0 
	            tablet:ml-10 tablet:mt-0 desktop:w-3/5"
	    >
	      <Text
	        className="laptop:text-3xl phone:text-xl font-semibold
	              rounded-lg text-primary-100 my-4"
	      >
	        {selectedProd?.name}
	      </Text>
	      <Text
	        className="text-xl font-semibold
	              rounded-lg text-primary-100 my-4"
	      >
	        Product Description
	      </Text>
	      <Text
	        className="laptop:text-base phone:text-sm
	              rounded-lg text-secondary-100"
	      >
	        {selectedProd?.description}
	      </Text>
	    </Div>
	  </FlexRow>

	  <button className='hidden' onClick={onCreate}>Hidden</button>

	  <FlexRow className="pt-8" id="Tabs">
	    <Tab.Group>
	      <Flex>
	        <Tab.List className="tabletWide:space-x-4">
	          {tabs.map((tab) => (
	            <Tab
	              className={({ selected }) =>
	                classNames(
	                  'py-3 font-semibold phone:w-1/3 tabletWide:w-auto',
	                  selected
	                    ? 'text-primary-100 border-b-4 border-blue-400'
	                    : 'text-grey-700  hover:text-grey-200',
	                )
	              }
	              key={tab}
	            >
	              {window.width > 800 ? checkTabDesktop(tab) : checkTabMobile(tab)}
	            </Tab>
	          ))}
	        </Tab.List>
	        <hr className="text-grey-1200" />
	        <Tab.Panels className="pt-9">
	          <Tab.Panel>
	          	<Text className='my-4 font-semibold text-primary-100 text-xl tablet:hidden'>
	          		Pending Marketing Asset Requests
	          	</Text>
	            {
					requestedList.length > 0 && isShow ?
					requestedList.map((list: any, index: number) => (
						<AssetData2
							affiliateName={list?.affiliate_name}
							content={list.content}
							date={list.created_at}
							id={list.asset_id}
							key={index}
							onRefresh={onRefresh}
							onToast={onToast}
							status={list?.asset_status}
							title={list.title}
						/>
					))
					:
					<FlexCenter className="py-4">
					{
					  !isShow?
					  <Text>
					    Loading data ...
					    <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
					  </Text>
					  :
					  <>
					  <Text>No marketing asset at this time.</Text>
					  <div className='text-sm text-blue-100 hover:underline cursor-pointer hidden' onClick={onRefresh}>
					    Click to reload
					    <i className="fas fa-repeat mx-3" />
					  </div>
					  </>
					}
					</FlexCenter>
				}
	          </Tab.Panel>
	          <Tab.Panel>
	          	<Text className='my-4 font-semibold text-primary-100 text-xl tablet:hidden'>
	          		Approved Marketing Asset Requests
	          	</Text>
	            {
					approvedList.length > 0 && isShow ?
					approvedList.map((list: any, index: number) => (
						<AssetData2
							affiliateName={list?.affiliate_name}
							content={list.content}
							date={list.created_at}
							id={list.asset_id}
							key={index}
							onRefresh={onRefresh}
							onToast={onToast}
							status={list?.asset_status}
							title={list.title}
						/>
					))
					:
					<FlexCenter className="py-4">
					{
					  !isShow?
					  <Text>
					    Loading data ...
					    <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
					  </Text>
					  :
					  <>
					  <Text>No marketing asset at this time.</Text>
					  <div className='text-sm text-blue-100 hover:underline cursor-pointer hidden' onClick={onRefresh}>
					    Click to reload
					    <i className="fas fa-repeat mx-3" />
					  </div>
					  </>
					}
					</FlexCenter>
				}
	          </Tab.Panel>
	          <Tab.Panel>
	          	<Text className='my-4 font-semibold text-primary-100 text-xl tablet:hidden'>
	          		Rejected Marketing Asset Requests
	          	</Text>
				{
					rejectedList.length > 0 && isShow ?
					rejectedList.map((list: any, index: number) => (
						<AssetData2
							affiliateName={list?.affiliate_name}
							content={list.content}
							date={list.created_at}
							id={list.asset_id}
							key={index}
							onRefresh={onRefresh}
							onToast={onToast}
							status={list?.asset_status}
							title={list.title}
						/>
					))
					:
					<FlexCenter className="py-4">
					{
					  !isShow?
					  <Text>
					    Loading data ...
					    <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
					  </Text>
					  :
					  <>
					  <Text>No marketing asset at this time.</Text>
					  <div className='text-sm text-blue-100 hover:underline cursor-pointer hidden' onClick={onRefresh}>
					    Click to reload
					    <i className="fas fa-repeat mx-3" />
					  </div>
					  </>
					}
					</FlexCenter>
				}
	          </Tab.Panel>
	        </Tab.Panels>
	      </Flex>
	    </Tab.Group>
	  </FlexRow>
	</>
)
}