import React, { FC, Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { selectUser, useAppDispatch, usersActions } from '@redux';
import { Div, FlexRow, Text, UserDropdown, Icons, FilterDropdown } from '@core';
import { NavBar } from '@components';
import { UserDrawers, FilterReport } from '@constants';
import { API } from '@config';
import { getCookie } from '@hooks';

interface ModalProps {
  isOpen: boolean;
  onClose?: any;
}

export const UserDrawerLocal: FC<ModalProps> = ({ isOpen }) => {
  const location = useLocation();

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed h-full mt-24 ml-auto w-72 inset-0 z-20 overflow-hide "
        onClose={() => 'noop'}
      >
        <div className="h-full text-center flex justify-start">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-20 py-5 items-center
              align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
            >
              {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
              {UserDrawers[0].links.map((value) => (
                <Link
                  className={`w-full py-5 tex-base font-medium hover:text-blue-100 pl-10
               ${
                 location.pathname === value.url ? 'border-l-4 text-blue-100' : 'text-primary-100'
               }`}
                  key={value.name}
                  to={value.url}
                >
                  <i className={`${value.icon} w-14 text-2xl`} />
                  <span className="font-poppins text-primary-100">{value.name}</span>
                </Link>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const Reports: FC<any> = () => {

	const token = getCookie('token');
	const dispatch = useAppDispatch();
	const { search } = useLocation();
	const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const user = useSelector(selectUser);
  const [userDrawer, setuserDrawer] = React.useState(false);

  const newdate: any = new Date();
  const Year: any = newdate.getFullYear();
  const zero: any = '0';
  const month: any = (1 + newdate.getMonth()).toString();
  const Month: any = month.length > 1 ? month : zero.concat(month);
  const day: any = newdate.getDate().toString();
  const Day = day.length > 1 ? day : zero.concat(day);
  const DateToday: any = Year.toString().concat('-', Month, '-', Day);
  const [displayDate, setDisplayDate] = React.useState(DateToday);

  const [totalRevenue, setTotalRevenue] = React.useState(0);
  const [pendingRevenue, setPendingRevenue] = React.useState(0);
  const [netRevenue, setNetRevenue] = React.useState(0);
  const [paidCommission, setPaidCommission] = React.useState(0);
  const [percentageRevenue, setPercentageRevenue] = React.useState(0);

  const [revenueData, setRevenueData] = React.useState([]);
  const [conversionData, setConversionData] = React.useState([]);

  const [isShow, setIsShow] = React.useState(false);

  const [report, setReport] = React.useState({
    label: DateToday,
    value: '90',
  });

  React.useEffect(() => {
    if(parseFloat(report.value) > 0){
      const result = new Date(DateToday);
      const days = parseFloat(report.value);
      const timestamp = result.setDate(result.getDate() - days)
      const fixedDate = new Date(timestamp)
      const newDay = fixedDate.getDate();
      const newMonth = 1+fixedDate.getMonth();
      const newYear = fixedDate.getFullYear();

      setDisplayDate(`${newYear}-${newMonth > 9 ? newMonth : `0${newMonth}`}-${newDay > 9 ? newDay : `0${newDay}`}`)
    }else{
      const dateA: any = new Date();
      const YearA: any = dateA.getFullYear();
      const zeroA: any = '0';
      const monthA: any = (1 + dateA.getMonth()).toString();
      const MonthA: any = monthA.length > 1 ? monthA : zeroA.concat(monthA);
      const dayA: any = dateA.getDate().toString();
      const DayA = dayA.length > 1 ? dayA : zeroA.concat(dayA);
      const DateTodayA: any = YearA.toString().concat('-', MonthA, '-', DayA);

      setDisplayDate(DateTodayA)
    }
  }, [report])

  	React.useEffect(() => {
  			setIsShow(false)
  	    batch(async () => {
  	      const revData = await dispatch(usersActions.revenueCommission(report.value));
  	      if (revData.payload !== null){
  	        setTotalRevenue(revData.payload.data.data.total_revenue);
  	        setNetRevenue(revData.payload.data.data.net_revenue);
  	        setPendingRevenue(revData.payload.data.data.pending_revenue);
  	        setPaidCommission(revData.payload.data.data.paid_commission);
  	      }
  	      await fetch(`${API}/chart-analytics/${report.value}`,{
  	      	method: 'GET',
  	      	headers: {Authorization: `Bearer ${token}`}
  	      })
  	      .then(res => res.json())
  	      .then(data => {
  	      	if(data.status === 1){
  	      		if(data.data.dailyConversion.length === 1 &&
  	      			data.data.dailyConversion[0].daily_conversions === 0){
  	      			setRevenueData([])
  	      			setConversionData([])
  	      		}else{
  	      			setRevenueData(data.data.dailyRevenue)
  	      			setConversionData(data.data.dailyConversion)
  	      		}
  	      	}
  	      	setIsShow(true)
  	      })
  	    });
  	}, [report]);

  	React.useEffect(() => {
  	  if(pendingRevenue === 0 && totalRevenue === 0){
  	    setPercentageRevenue(0.00)
  	  }else{
  	    setPercentageRevenue(pendingRevenue/totalRevenue*100)
  	  }
  	})

  	const onReload = async () => {
  		setIsShow(false)
  		batch(async () => {
  		  const revData = await dispatch(usersActions.revenueCommission(report.value));
  		  if (revData.payload !== null){
  		    setTotalRevenue(revData.payload.data.data.total_revenue);
  		    setNetRevenue(revData.payload.data.data.net_revenue);
  		    setPendingRevenue(revData.payload.data.data.pending_revenue);
  		    setPaidCommission(revData.payload.data.data.paid_commission);
  		  }
  		  await fetch(`${API}/chart-analytics/${report.value}`,{
  		  	method: 'GET',
  		  	headers: {Authorization: `Bearer ${token}`}
  		  })
  		  .then(res => res.json())
  		  .then(data => {
  		  	if(data.status === 1){
  		  		setRevenueData(data.data.dailyRevenue)
  		  		setConversionData(data.data.dailyConversion)
  		  	}
  		  })
  		  setReport({
  		    label: DateToday,
  		    value: '90',
  		  })
  		  setIsShow(true)
  		});
  	}

return(
<>
	<UserDrawerLocal isOpen={userDrawer} onClose={() => setuserDrawer(false)} />
	<NavBar />
	  <FlexRow className="h-24 laptop:px-20 tablet:px-10 phone:px-5 py-5 items-center 
    bg-primary-100 sticky w-full hidden">
	    <Link className="mr-auto flex flex-row items-center" to="/merchant/dashboard">
	      <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
	      <Text className="text-white mr-auto text-3xl font-semibold select-none phone:hidden surfaceDuo:block">
	        Xffiliate
	      </Text>
	      <Text className="italic text-white pl-1 font-light self-end pb-2 phone:hidden medium:block">
	        Merchant
	      </Text>
	    </Link>
	      <div
	        className="h-full w-16 rounded-lg bg-white border-2 border-primary-100 justify-center items-center cursor-pointer tabletWide:hidden phone:flex"
	        onClick={() => {
	          setuserDrawer(!userDrawer);
	        }}
	      >
	        {userDrawer ? (
	          <i className="fas fa-times laptop:text-3xl phone:text-2xl text-primary-100" />
	        ) : (
	          <i className="fas fa-user laptop:text-3xl phone:text-2xl text-primary-100" />
	        )}
	      </div>
	    <UserDropdown info={user.user_info} user={user} />
	  </FlexRow>

	<Div className="laptop:px-20 tablet:px-10 phone:px-5 overflow-y-scroll scrollbar-hide w-full h-full pb-60 mt-24">
		{
			actionQuery === 'revenue' ?
			<>
			<Text className="font-poppins font-semibold tablet:text-xl phone:text-xl text-primary-100 phone:mt-4 tablet:mt-8 mb-2">
				Revenue Report
			</Text>
			<hr className="text-grey-400 mb-10 hidden" />

			<FlexRow className='w-full phone:flex-col tablet:flex-row justify-start mt-8'>

				<FlexRow className='mr-auto mb-2 tablet:mb-0 tablet:mr-0'>
					<Text>From:</Text>
					<input className='w-44 mx-2' disabled type='date' value={displayDate} />
				</FlexRow>

				<FlexRow className='mr-auto mb-2 tablet:mb-0 tablet:mr-0'>
					<Text className='mr-5 tablet:mr-0'>To:</Text>
					<input className='w-44 mx-2' disabled type='date' value={DateToday} />
				</FlexRow>

				 {report.value === '90' ? (
				   <FilterDropdown
				   	 className='phone:px-0 tablet:px-5'
				     classNameBody='border-4 border-primary-100 rounded-lg'
				     color="white"
				     containerClass='mr-auto tablet:mr-0'
				     current={report?.label || ''}
				     items={FilterReport}
				     onSelect={setReport}
				     placeholder="Select a Range"
				   />
				 ) : (
				   <>
				     <div
				       className={`inline-flex items-center justify-center px-5 border-0
				py-3 font-poppins text-base font-regular text-primary-100
				hover:text-red-100 cursor-pointer mr-auto tablet:mr-0`}
				       onClick={() =>
				         setReport({
				           label: DateToday,
				           value: '90',
				         })
				       }
				     >
				       Reset
				       <i className="fas fa-filter text-2xl ml-1" />
				     </div>
				   </>
				 )}
				
			</FlexRow>

			<Text className='mt-4 text-primary-100'>
				Report Summary within <b>{report.value}</b> days up to date.
			</Text>

			<FlexRow className='phone:flex-row medium:flex-col medium:mt-8'>

			<FlexRow className='phone:flex-col medium:flex-row py-2 justify-around text-secondary-200 mt-4 mb-2 cursor-pointer hover:bg-grey-500 phone:w-1/2 medium:w-full'>
				<Text className="text-base text-secondary-200 medium:font-semibold phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					As of Date
				</Text>
				<Text className="text-base text-secondary-200 medium:font-semibold phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					Gross Revenue
				</Text>
				<Text className="text-base text-secondary-200 medium:font-semibold phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					Commissions
					<span className='hidden tabletWide:inline mx-1'>(Paid)</span>
				</Text>
				<Text className="text-base text-secondary-200 medium:font-semibold phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					Net Revenue
				</Text>
				<Text className="text-base text-secondary-200 medium:font-semibold phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					Percentage
				</Text>
			</FlexRow>

			<FlexRow className='phone:flex-col medium:flex-row py-2 justify-around text-secondary-200 mt-4 mb-2 cursor-pointer hover:bg-grey-500 phone:w-1/2 medium:w-full'>
				<Text className="text-base text-secondary-200 phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					{DateToday}
				</Text>
				<Text className="text-base text-secondary-200 phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					&#8369; {totalRevenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</Text>
				<Text className="text-base text-secondary-200 phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					&#8369; {paidCommission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</Text>
				<Text className="text-base text-secondary-200 phone:w-full medium:w-1/5 phone:text-left medium:text-center">
					&#8369; {netRevenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</Text>
				<Text className="text-base text-green-100 phone:w-full medium:w-1/5 phone:text-left medium:text-center">
            +{percentageRevenue.toFixed(2)}%
				</Text>
			</FlexRow>

			</FlexRow>

			<Text className='phone:mt-4 medium:mt-14 text-primary-100'>
				Daily Report Breakdown within <b>{report.value}</b> days up to date.
			</Text>

			<FlexRow className='justify-around text-secondary-200 mt-8 border-b border-grey-400 pb-2'>
				<Text className="text-base text-secondary-200 font-semibold w-1/3 text-center">
					Date
				</Text>
				<Text className="text-base text-secondary-200 font-semibold w-1/3 text-center">
					Revenue
				</Text>
				<Text className="text-base text-secondary-200 font-semibold w-1/3 text-center">
					Conversions
				</Text>
			</FlexRow>

			{
				!isShow ?
				<Div className='flex justify-center items-center h-36'>
					<Text className='text-center'>
							Loading...
						  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
					</Text>
				</Div>
				:
				<></>
			}

			{
				revenueData.length < 1 && conversionData.length < 1 && isShow?
				<Div className='flex justify-center items-center h-36'>
					<Text className='text-center'>
						No data to be displayed.
						<span className='block text-blue-100 hover:underline font-semibold cursor-pointer' onClick={onReload}>
						  Click to reload.
						  <i className="fas fa-repeat ml-2" />
						</span>
					</Text>
				</Div>
				:
				<FlexRow className={isShow? `h-80 overflow-y-scroll` : 'hidden'}>

				<Div className='w-1/3 h-72 relative'>
				{
					revenueData.length > 0 && isShow?
					<>
					{
						revenueData.map((item: any, index: number) => (
							<FlexRow className={`py-2 justify-around text-secondary-200 mb-2 cursor-pointer hover:bg-grey-500 ${item.daily_revenue !== 0? '' : 'hidden'}`} key={index}>
								<Text className="phone:text-sm surfaceDuo:text-base text-secondary-200 w-full text-center">
									{item.date_created}
								</Text>
								<Text className="text-base text-secondary-200 w-1/2 text-center hidden">
									&#8369; {item.daily_revenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								</Text>
							</FlexRow>
							))
					}
					</>
					:
					<></>
				}
				</Div>

				<Div className='w-1/3 h-72 relative'>
				{
					revenueData.length > 0 && isShow?
					<>
					{
						revenueData.map((item: any, index: number) => (
							<FlexRow className={`py-2 justify-around text-secondary-200 mb-2 cursor-pointer hover:bg-grey-500 ${item.daily_revenue !== 0? '' : 'hidden'}`} key={index}>
								<Text className="text-base text-secondary-200 w-1/2 text-center hidden">
									{item.date_created}
								</Text>
								<Text className="phone:text-sm surfaceDuo:text-base text-secondary-200 w-full text-center">
									&#8369; {item.daily_revenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								</Text>
							</FlexRow>
							))
					}
					</>
					:
					<></>
				}
				</Div>

				<Div className='w-1/3 h-72 relative'>
				{
					conversionData.length > 0 && isShow?
					<>
					{
						conversionData.map((item: any, index: number) => (
							<FlexRow className={`py-2 justify-around text-secondary-200 mb-2 cursor-pointer hover:bg-grey-500 ${item.daily_earned !== 0? '' : 'hidden'}`} key={index}>
								<Text className="text-base text-secondary-200 w-1/2 text-center hidden">
									{item.date_created}
								</Text>
								<Text className="phone:text-sm surfaceDuo:text-base text-secondary-200 w-full text-center">
									&#8369; {item.daily_earned.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								</Text>
							</FlexRow>
							))
					}
					</>
					:
					<></>
				}
				</Div>

				</FlexRow>
			}
			
			</>
			:
			<></>
		}
	</Div>
</>
	)
}