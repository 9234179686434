import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { FlexRow, Text, Icons } from '@core';

interface ButtonProps {
  className?: string;
  title: string;
  onClose: any;
  description: string;
  isOpen: boolean;
  toastKind: string;
  details: any;
  BannerTitle?: string;
  BannerSubTitle?: string;
  ImageAlt?: string;
  ImageSource?: string;
  queryInfo: string;
  style?: any;
}

export const ToastBanner: FC<ButtonProps> = ({
  title, description, details, onClose, BannerSubTitle,
  BannerTitle, ImageAlt, ImageSource, isOpen, toastKind,
  queryInfo, style
}) => {
  let color = 'green-100';

  let icon = 'fa-check';
  if (toastKind === 'success') {
    color = 'green-100';
    icon = 'fa-check';
  } else if (toastKind === 'error') {
    color = 'red-100';
    icon = 'fa-times';
  } else if (toastKind === 'warning') {
    color = 'yellow-100';
    icon = 'fa-exclamation';
  } else if (toastKind === 'info') {
    color = 'primary-100';
    icon = 'fa-exclamation';
  }

  const [iconLoaded, setIconLoaded] = React.useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const merchantId = query.get('merchant');
  const merchantCode = query.get('merchantCode');
  const merchantStore = query.get('merchantStore');

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-40 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="phone:w-full medium:w-3/4 tabletWide:w-3/5 phone:w-full h-screen text-center">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-50 px-5 pt-5
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <div
                className={`flex flex-col justify-start p-4 mb-4 text-gray-500 w-full
            bg-white border-4 border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins bg-white max-h-full`}
                id="toast-success"
                role="alert"
              >
              <FlexRow className='w-full'>
                <div
                  className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white rounded-lg mr-1`}
                >
                  <i className={`fas ${icon} hidden`} />
                  <img alt='logo' className='object-cover' src={Icons.xffiliate} />
                </div>
                <div>
                  <div className={`ml-3 font-semibold phone:text-base surfaceDuo:text-2xl text-${color}`}>
                    {title.toUpperCase()}
                  </div>
                  <div className="ml-3 phone:text-xs surfaceDuo:text-sm font-normal text-primary-100">{description}</div>
                </div>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
              </FlexRow>
              <hr className='text-grey-400 my-3' />

              <div className='max-h-full mt-3 overflow-y-scroll scrollbar-hide'>
                <Text className='text-orange-100 font-semibold phone:text-xs phone:text-center surfaceDuo:text-left surfaceDuo:text-base'>
                  {BannerTitle}
                </Text>
                <Text className='text-secondary-200 mt-3 phone:text-center laptop:text-lg surfaceDuo:px-8 surfaceDuo:text-justify'>
                  {BannerSubTitle}
                </Text>

                <FlexRow className='justify-center mt-3 w-full'>
                <div className='hover:shadow-xl rounded-lg mx-1 laptop:mx-10 phone:hidden tabletWide:block'>
                  <img alt={ImageAlt} className='object-cover w-64 h-64' src={ImageSource || Icons.banner} title='Xffiliate Banner' />
                </div>

                <FlexRow className='flex-col mx-1 laptop:mx-10'>
                  <Text className='text-primary-100 text-center text-lg mb-3'>
                    Merchant Store: <b>{queryInfo}</b>
                  </Text>

                  <div className={`grid ${details.length > 2 ? 'phone:grid-cols-2 mobile:grid-cols-3' : ''}`}>
                    {
                      details.length > 2 ?
                      <>
                        <div className='m-2 hover:shadow-xl rounded-lg border-4 border-white hover:border-orange-100 duration-500'>
                          <img
                            alt={ImageAlt}
                            className='object-contain w-20 h-20'
                            onError={() => setIconLoaded(true)}
                            src={
                              iconLoaded
                                ? 'https://i.imgur.com/CGDGnf6.png'
                                : details[0]?.image
                            }
                            title={details[0]?.name}
                            />
                        </div>
                        <div className='m-2 hover:shadow-xl rounded-lg border-4 border-white hover:border-orange-100 duration-500'>
                          <img
                            alt={ImageAlt}
                            className='object-contain w-20 h-20'
                            onError={() => setIconLoaded(true)}
                            src={
                              iconLoaded
                                ? 'https://i.imgur.com/CGDGnf6.png'
                                : details[1]?.image
                            }
                            title={details[1]?.name}
                            />
                        </div>
                        <div className='m-2 hover:shadow-xl rounded-lg border-4 border-white hover:border-orange-100 duration-500 phone:hidden mobile:flex'>
                          <img
                            alt={ImageAlt}
                            className='object-contain w-20 h-20'
                            onError={() => setIconLoaded(true)}
                            src={
                              iconLoaded
                                ? 'https://i.imgur.com/CGDGnf6.png'
                                : details[2]?.image
                            } 
                            title={details[2]?.name}
                            />
                        </div>
                      </>
                      :
                      <>
                        <div className='m-2 hover:shadow-xl rounded-lg border-4 border-white hover:border-orange-100'>
                          <img
                            alt={ImageAlt}
                            className='object-contain w-20 h-20'
                            onError={() => setIconLoaded(true)}
                            src={
                              iconLoaded
                                ? 'https://i.imgur.com/CGDGnf6.png'
                                : `${details[0]?.image}`
                            }
                            title={details[0]?.name}
                            />
                        </div>
                      </>
                    }
                  </div>

                    <Text className='text-xs text-center text-secondary-200 mt-3'>
                      Join Xffiliate and be a partner with {merchantStore}!
                    </Text>

                  </FlexRow>

                </FlexRow>

              </div>

              <div className={`${window.location.pathname === '/affiliate/merchant_catalogs' ? 'hidden' : ''}`}>

                <hr className='text-grey-400 mt-3' />

                <Text className='text-center text-sm text-secondary-200 mt-5 phone:hidden medium:block'>
                  Already have an affiliate account? Log In
                  <Link className='hover:underline text-blue-100 mx-1 font-semibold' title='Go to Login' to={`/affiliate/onboard/login?merchant=${merchantId}&merchantCode=${merchantCode}&merchantStore=${merchantStore}`}>here</Link>
                </Text>

                <FlexRow className='w-full mt-3 justify-center phone:flex-col medium:flex-row'>
                  <button className='px-3 py-2 rounded-lg mx-2 my-1 text-white font-semibold phone:text-xs phone:w-10/12 medium:w-1/2 tabletWide:text-base laptop:w-1/4' onClick={onClose} style={{backgroundColor: style}}>
                    CREATE ACCOUNT
                  </button>
                </FlexRow>

              </div>

              <div className={`${window.location.pathname === '/affiliate/merchant_catalogs' ? 'w-full flex flex-row justify-center border-t-2 border-grey-400 mt-3' : 'hidden'}`}>
                <button className='mt-3 bg-primary-100 hover:bg-blue-100 px-3 py-2 rounded-lg mx-2 my-1 text-white font-semibold phone:text-xs phone:w-10/12 medium:w-1/2 tabletWide:text-base laptop:w-1/4' onClick={onClose}>
                  GOT IT!
                </button>
              </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};