import React from 'react';

import { Link } from 'react-router-dom';

import { Flex, Div, Icons, Text, Button } from '@core';

export const Notfound = () => (
  <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
    <Div className="mr-auto flex flex-row items-center py-8">
      <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
      <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">Xffiliate</Text>
    </Div>
    <hr className="text-secondary-300 w-full" />
    <Flex className="items-center laptop:pt-32 phone:pt-20 tablet:w-500px phone:w-full">
      <Text className="font-semibold text-secondary-100 text-2xl mb-5">Page not found!</Text>
      <Text
        className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-10"
      >
        Woops! You've moved so
        <span className="text-orange-100"> fast</span> and got{' '}
        <span className="text-orange-100">lost...</span>
      </Text>
      <Div className='px-8'>
        <Text className="mb-4 font-semibold">
          This happens when:
        </Text>
        <Text className="mb-4">
          <b className='mr-2 text-sm'>&#9679;</b>
          The page you were looking for doesn't exist. Please check your URL for spellings or
          capitalizations.
        </Text>
        <Text className="mb-4">
          <b className='mr-2 text-sm'>&#9679;</b>
          OR Your session has already expired and logged out.
        </Text>
      </Div>
      <Link to="/">
        <Button className="px-16 mt-4">Home</Button>
      </Link>
    </Flex>
  </Flex>
);