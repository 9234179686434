import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FlexRow } from '@core'

interface ButtonProps {
  className?: string;
  title: string;
  onClose: any;
  description: string;
  isOpen: boolean;
  toastKind: string;
}

export const ToastContainer: FC<ButtonProps> = ({
  title, description, onClose, isOpen, toastKind, children
}) => {
  let color = 'green-100';

  let icon = 'fa-check';
  if (toastKind === 'success') {
    color = 'green-100';
    icon = 'fa-check';
  } else if (toastKind === 'error') {
    color = 'red-100';
    icon = 'fa-times';
  } else if (toastKind === 'warning') {
    color = 'yellow-100';
    icon = 'fa-exclamation';
  } else if (toastKind === 'info') {
    color = 'primary-100';
    icon = 'fa-exclamation';
  }

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-40 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="phone:w-full medium:w-3/4 tabletWide:w-1/2 phone:w-full h-screen text-center">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-50 px-5 pt-5
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <div
                className={`flex flex-col justify-around p-4 mb-4 text-gray-500 w-full
            bg-white border-4 border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins bg-white`}
                id="toast-success"
                role="alert"
              >
              <FlexRow className='w-full'>
                <div
                  className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white bg-${color} rounded-lg mr-1`}
                >
                  <i className={`fas ${icon}`} />
                </div>
                <div>
                  <div className={`ml-3 font-semibold text-base text-${color}`}>
                    {title.toUpperCase()}
                  </div>
                  <div className="ml-3 text-sm font-normal text-primary-100">{description}</div>
                </div>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
              </FlexRow>

              <div className='w-full'>
                {children}
              </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};