import React, { FC } from 'react';

interface FlexCenterProps {
  className?: string;
}

export const FlexCenter: FC<FlexCenterProps> = ({ className, ...props }) => (
  <div
    className={`w-full h-full flex flex-col justify-center items-center ${className}`}
    {...props}
  />
);
