import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getCookie } from '@src/hooks';
import { API } from '@src/config';

interface ButtonProps {
  onClose: any;
  isOpen: boolean;
}

export const ToastReviewModal: FC<ButtonProps> = ({ onClose, isOpen }) => {
  const token = getCookie('token');
  // let color = 'green-100';
  console.log('to remove error');
  // let icon = 'fa-check';
  // if (toastKind === 'success') {
  //   color = 'green-100';
  //   icon = 'fa-check';
  // } else if (toastKind === 'error') {
  //   color = 'red-100';
  //   icon = 'fa-times';
  // } else if (toastKind === 'warning') {
  //   color = 'yellow-100';
  //   icon = 'fa-exclamation';
  // } else if (toastKind === 'info') {
  //   color = 'primary-100';
  //   icon = 'fa-exclamation';
  // }

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed z-50 phone:w-full tabletWide:w-1/2 tabletWide:right-1/4 laptop:right-1/3 laptop:w-1/3 top-1/4 p-6"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="transition ease duration-300 transform"
          enterFrom="opacity-0 -translate-y-full"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease duration-300 transform"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-full"
        >
          <div className="pt-8 text-center bg-white border-2 border-primary-100 border-solid rounded-md pb-12 px-2">
            <h2 className="text-2xl ">Love our app? Help us by rating!</h2>
            <a
              href="https://www.wix.com/app-market/add-review/1903359e-9e5d-4fc2-9a70-d6f41b995b3c" // dev = "https://www.wix.com/app-market/add-review/31491d14-6a6a-458a-9639-fc5b18561a8f"
              onClick={async () => {
                await fetch(`${API}/wix-check-review-modal/done`, {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                });
                onClose();
              }}
              rel="noreferrer"
              target="_blank"
            >
              <h1 className="text-4xl font-bold mt-7 text-orange-200 cursor-pointer">
                Rate us now!
              </h1>
            </a>
            <button onClick={() => onClose()}>
              <h2 className="text-2xl mt-7 text-orange-200 cursor-pointer">Not now</h2>
            </button>
            <p
              className="text-sm mt-7 mx-auto"
              style={{
                maxWidth: '424px',
              }}
            >
              Your feedback matters to us. Let us know how we're doing by giving us a quick rating
            </p>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
