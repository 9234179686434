import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import { Popover } from '@headlessui/react';
import moment from 'moment';
import { Div, FlexRow, Text, Toast, ToastV4 } from '@core';
import { useAppDispatch, partnersActions } from '@redux';

export const ApplicationList: FC<any> = ({
  data, prodID, Select, selectedAll, selectedData, selection
}: any) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = React.useState<any>();

  const [loading, setLoading] = React.useState(false);
  const [loadingDenied, setLoadingDenied] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2, setToastKind2] = React.useState('success');
  const [toastTitle2, setToastTitle2] = React.useState('');
  const [toastDesc2, setToastDesc2] = React.useState('');

  React.useEffect(() => {
    if(selectedData.includes(data)){
      setIsChecked(true)
    }else{
      setIsChecked(null)
    }
  }, [selectedData, prodID])

  const onAccept = async () => {
    setLoading(true);
    batch(async () => {
      await dispatch(
        partnersActions.newStatus({
          id: data.id,
          status: '1',
          code: data.code,
        }),
      ).then(async (item: any) => {
        if (item?.payload?.data?.status === 1) {
          setToastTitle('Applicant Added');
          setToastDesc('Applicant is successfully added.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            navigate('/merchant/dashboard?action=applicants')
          }, 2000)
        } else {
          setToastTitle('ERROR');
          setToastDesc(item?.payload?.message || 'Something went wrong. Try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    });
  };

  const onDenied = async () => {
    setLoadingDenied(true);
    batch(async () => {
      await dispatch(
        partnersActions.newStatus({
          id: data.id,
          status: '-1',
          code: data.code,
        }),
      ).then(async (item: any) => {
        if (item?.payload?.data?.status === 1) {
          setToastTitle('Applicant Denied');
          setToastDesc('Applicant is successfully denied.');
          setToastKind('');
          setToastOpen(true);
          setLoadingDenied(false);
          setTimeout(() => {
            navigate('/merchant/dashboard?action=applicants')
          }, 2000)
        } else {
          setToastTitle('ERROR');
          setToastDesc(item?.payload?.message || 'Something went wrong. Try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoadingDenied(false);
        }
      });
    });
  };

  const Remove = async () => {
    if(data.firstname === 'none'){
      setToastDesc2(`Are you sure you want to remove ${data.company_name} completely on the list?`);
    }else{
      setToastDesc2(`Are you sure you want to remove ${data.firstname} ${data.lastname} completely on the list?`);
    }
    setToastTitle2('Action');
    setToastKind2('info');
    setToastOpen2(true);
  }

  const onRemove = () => {
    setToastOpen2(false)
    batch(async () => {
      const res: any = await dispatch(partnersActions.removeApplication(data.id));

      if (res?.payload?.data?.status === 1) {
        setToastTitle('Affiliate Removed');
        setToastDesc('Affiliate is successfully removed.');
        setToastKind('success');
        setToastOpen(true);
        setTimeout(() => {
          navigate('/merchant/dashboard?action=applicants')
        }, 2000)
      } else {
        setToastTitle('ERROR');
        setToastDesc(res?.payload?.data?.message || 'Something went wrong. Try again.');
        setToastKind('error');
        setToastOpen(true);
      }
    });
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <ToastV4
          btnText='Cancel'    
            description={toastDesc2}
            isOpen={isToastOpen2}
            link=''
            onClick={onRemove}
            onClick2={() => setToastOpen2(false)}
            onClose={() => setToastOpen2(false)}
            submitBtnText='Remove'
            title={toastTitle2}
            toastKind={toastKind2}
      />

      <FlexRow className='w-full my-4 py-2 border-b border-grey-400'>

        <input
          checked={isChecked}
          className={`mr-2 phone:w-3 phone:h-3 laptop:w-4 laptop:h-4 rounded border border-secondary-100 
                focus:ring-3 focus:ring-primary-200 text-primary-100 phone:mb-auto phone:mt-2 tabletWide:mt-auto cursor-pointer
                ${selection ? '' : 'hidden'}
                `}
          id={data.id}
          key={data.id}
          onClick={Select}
          title={selectedAll? 'Unselect all to choose items manually.' : 'Click to select/unselect this item'}
          type='checkbox'
        />

        <Div className='w-1/3 tabletWide:hidden'>
          <Text className='text-left text-secondary-200'>
            Name:
          </Text>
          <Text className='text-left text-secondary-200'>
            Email:
          </Text>
          <Text className='text-left text-secondary-200'>
            Social:
          </Text>
          <Text className='text-left text-secondary-200'>
            Status:
          </Text>
          <Text className='text-left text-secondary-200 mt-4'>
            Action:
          </Text>
        </Div>

        <FlexRow className="phone:w-2/3 phone:flex-col tabletWide:flex-row tabletWide:w-full">
          {
          data.firstname === 'none' ?
          <>
            <FlexRow className='phone:w-full tabletWide:w-2/8'>
              <Div className='items-center justify-center w-10 h-10 rounded-full bg-grey-400 mr-2 phone:hidden laptop:flex'>
                {data.company_name.substring(0, 1).toUpperCase()}
              </Div>
              <Text className="text-left text-secondary-200 flex flex-col">
                <span className='font-medium'>{data.company_name}</span>
                <span className='text-sm hidden'>{moment(data?.created_at).format('MMMM D, YYYY')}</span>
              </Text>
            </FlexRow>
            <Text className="text-sm font-medium text-grey-700 truncate phone:w-full tabletWide:w-2/8 text-left phone:hidden tabletWide:text-center laptop:text-left laptop:block laptop:text-base">           
                {data.company_email} 
            </Text>
            <Popover className="relative phone:w-full tabletWide:w-1/8 laptop:hidden">
                <Popover.Button className='flex flex-row w-full justify-start tabletWide:justify-center'>
                    <Text className='text-blue-100 tabletWide:text-sm'>
                      <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
                      Email
                    </Text>
                </Popover.Button>
                <Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
                  <Text className='text-sm text-secondary-200'>
                    Email address:
                  </Text>
                  <hr className='text-grey-400' />
                  <Text className='text-sm text-secondary-200 hover:text-blue-100 hover:underline break-words mt-4'>
                    {data.company_email}
                  </Text>
                </Popover.Panel>
            </Popover>
            <Popover className="relative phone:w-full tabletWide:w-1/8">
                  <Popover.Button className='flex flex-row w-full justify-start tabletWide:justify-center'>
                      <Text className='text-blue-100 tabletWide:hidden'>
                        <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
                        Social
                      </Text>
                      <a className='hidden text-blue-100 tabletWide:inline text-sm' href={data.company_link} rel="noopener noreferrer" target="_blank" title={data.company_link}>
                        <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
                        Social
                      </a>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
                    <Text className='text-sm text-secondary-200'>
                      Social Link:
                    </Text>
                    <hr className='text-grey-400' />
                    <Text className='text-sm text-secondary-200 hover:text-blue-100 hover:underline break-words mt-4'>
                      <a href={data.company_link} rel="noopener noreferrer" target="_blank">{data.company_link}</a>
                    </Text>
                  </Popover.Panel>
            </Popover>  
          </>
          :
          <>
            <FlexRow className='phone:w-full tabletWide:w-2/8'>
              <Div className='items-center justify-center w-10 h-10 rounded-full bg-grey-400 mr-2 phone:hidden laptop:flex'>
                {data.firstname.substring(0, 1).toUpperCase()}
              </Div>
              <Text className="text-left text-secondary-200 flex flex-col">
                <span className='font-medium'>{data.firstname} {data.lastname}</span>
                <span className='text-sm hidden'>{moment(data?.created_at).format('MMMM D, YYYY')}</span>
              </Text>
            </FlexRow>
            <Text className="text-sm font-medium text-grey-700 truncate phone:w-full tabletWide:w-2/8 text-left phone:hidden tabletWide:text-center laptop:text-left laptop:block laptop:text-base">
                {data.email}
            </Text>
            <Popover className="relative phone:w-full tabletWide:w-1/8 laptop:hidden">
                  <Popover.Button className='flex flex-row w-full justify-start tabletWide:justify-center'>
                      <Text className='text-blue-100 tabletWide:text-sm'>
                        <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
                        Email
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
                    <Text className='text-sm text-secondary-200'>
                      Email address:
                    </Text>
                    <hr className='text-grey-400' />
                    <Text className='text-sm text-secondary-200 hover:text-blue-100 hover:underline break-words mt-4'>
                      {data.email}
                    </Text>
                  </Popover.Panel>
            </Popover>
            <Popover className="relative phone:w-full tabletWide:w-1/8">
                  <Popover.Button className='flex flex-row w-full justify-start tabletWide:justify-center'>
                      <Text className='text-blue-100 tabletWide:hidden'>
                        <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
                        Social
                      </Text>
                      <a className='hidden text-blue-100 tabletWide:inline text-sm' href={data.social_link} rel="noopener noreferrer" target="_blank" title={data.social_link}>
                        <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
                        Social
                      </a>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
                    <Text className='text-sm text-secondary-200'>
                      Social Link:
                    </Text>
                    <hr className='text-grey-400' />
                    <Text className='text-sm text-secondary-200 hover:text-blue-100 hover:underline break-words mt-4'>
                      <a href={data.social_link} rel="noopener noreferrer" target="_blank">{data.social_link}</a>
                    </Text>
                  </Popover.Panel>
            </Popover>
          </>
          }
          <Text className='text-secondary-200 phone:w-full tabletWide:w-2/8 text-left tabletWide:text-center  laptop:w-1/8'>
            {data.status}
          </Text>
          <Div className='phone:w-full tabletWide:w-2/8 flex flex-row justify-start tabletWide:justify-center mt-3 tabletWide:mt-0'>
            {         
              <>
              <button className='phone:text-sm tabletWide:text-xs laptop:text-sm rounded-full bg-green-100 hover:bg-green-400 text-white font-medium text-center mr-1 py-1 w-36' onClick={onAccept}>
                {loading? <i className="fad fa-spinner-third animate-spin text-sm"/> : 'APPROVE'}
              </button>
              </>
            }
            {
              data.status === 'DENIED' ?
              <button className='phone:text-sm tabletWide:text-xs laptop:text-sm rounded-full bg-red-100 hover:bg-red-400 text-white font-medium text-center py-1 w-36' onClick={Remove}>
                {loadingDenied? <i className="fad fa-spinner-third animate-spin text-sm"/> : 'REMOVE'}
              </button>
              :
              <button className='phone:text-sm tabletWide:text-xs laptop:text-sm rounded-full bg-red-100 hover:bg-red-400 text-white font-medium text-center py-1 w-36' onClick={onDenied}>
                {loadingDenied? <i className="fad fa-spinner-third animate-spin text-sm"/> : 'REJECT'}
              </button>
            }
          </Div>
        </FlexRow>
      </FlexRow>
    </>
  );
};