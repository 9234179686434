import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import { selectUser, useAppDispatch, usersActions } from '@redux';
import { useCookie } from '@hooks';
import { Flex, Div, Icons, Text, Button } from '@core';

export const EmailMerchant = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const user = useSelector(selectUser);
  const [token, updateToken] = useCookie('token', '0');
  const [loading, setLoading] = React.useState(true);
  const query = new URLSearchParams(search);
  const xffiliateToken = query.get('xffiliate_token');

  React.useEffect(() => {
    if (xffiliateToken) {
      updateToken(xffiliateToken, 1);
    }
  }, [xffiliateToken]);

  React.useEffect(() => {
    batch(async () => {
      const data:any = await dispatch(usersActions.profileAdmin())
      if(data.payload === null){
        await dispatch(usersActions.profile());
        setLoading(false);
      }else{
        setLoading(false);
      }
    });
  }, [token]);

  return (
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300 w-full" />
      <Flex className="items-center laptop:pt-32 phone:pt-20 tablet:w-500px phone:w-full">
        {loading ? (
          <>
            <Text className="font-semibold text-secondary-100 text-2xl mb-5">Almost There!</Text>
            <Text
              className="font-poppins font-bold laptop:text-4xl phone:text-2xl text-primary-100
          text-center mb-10"
            >
              Please wait while we
              <span className="text-orange-100"> verify</span> your
              <span className="text-orange-100"> email address.</span>
            </Text>
            <Text className="mb-10 text-center">
              This may take up to 30 seconds. If you encounter a error please reload the page. Thank
              you!
            </Text>
          </>
        ) : (
          <>
            <Text className="font-semibold text-secondary-100 text-2xl mb-5">Email Verified!</Text>
            <Text
              className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-10"
            >
              Your email is now <span className="text-orange-100"> verified.</span> Thank you{' '}
              <span className="text-orange-100"> {user?.user_info?.firstname}</span> for joining!
            </Text>

            <Text className="mb-10 text-center">
              You can now start using Xffiliate! Create an effortless, integrated advocate sharing
              experience.
            </Text>
            {
              user?.user_info?.role === 'merchant'?
              <Link to='/merchant/dashboard'>
                <Button className="px-20">Go to Dashboard</Button>
              </Link>
              :
              <></>
            }
            {
              user?.user_info?.role === 'Manager' || user?.user_info?.role === 'Editor' ?
              <Link to={`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}`}>
                <Button className="px-20">Go to Dashboard</Button>
              </Link>
              :
              <></>
            }
            {
              user?.user_info?.role === 'Super-Admin'?
              <Link to='/admin/dashboard'>
                <Button className="px-20">Go to Dashboard</Button>
              </Link>
              :
              <></>
            }
          </>
        )}
      </Flex>
    </Flex>
  );
};
