import React, { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { UserDropdownManager as MenuItems, UserDropdownEditor } from '@constants';

export const UserDropdownAdmin: FC<any> = ({ info, user }) => (
  <div className='w-64 text-right phone:hidden tabletWide:block'>
    <Menu as="div" className="phone:w-20 surfaceDuo:w-64 relative inline-block text-left">
      <div>
        <Menu.Button
          className='flex flex-row items-center justify-center w-full px-5 py-0.5 font-poppins text-base font-normal  rounded-md hover:bg-opacity-20 text-white bg-primary-100 ring-primary-500 ring-1'  
        >
          <i
            className='fas fa-store text-2xl mr-4 hidden'
          />
          <img
            alt='Merchant Logo'
            className='w-10 h-10 mr-4 rounded-full object-cover'
            src={user?.merchant_data?.company[0].image}
          />
          <div className="phone:hidden surfaceDuo:flex surfaceDuo:flex-col">
            <p className="mr-auto truncate text-ellipsis text-left w-40">
              {`${info?.firstname} ${info?.lastname}`}
            </p>
            <span className="text-sm font-light text-left">
              {info?.role.toUpperCase()}
            </span>
            <span className="text-sm font-light text-left hidden">
              {user.user_info?.role.toUpperCase()}
            </span>
          </div>
          <i className="fas fa-chevron-down ml-2" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {info?.role === 'Manager' ? (
          <Menu.Items
            className={`absolute right-0 mt-1 origin-top-right font-poppins
            bg-white divide-y w-64 divide-secondary-300 
            rounded-md shadow-lg z-10`}
          >
            {MenuItems.map((value) => (
              <div className="px-1 py-1 " key={value.name}>
                {value.links.map((link) => (
                  <Menu.Item key={link.name}>
                    {({ active }) => (
                      <Link
                        className={`${
                          active ? 'bg-primary-100 text-white' : 'text-secondary-100'
                        }  group flex rounded-md items-center
                    w-full px-4 py-3 text-base`}
                        to={link.url}
                      >
                        {link.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            ))}
          </Menu.Items>
        ) : (
          <Menu.Items
            className={`absolute right-0 mt-1 origin-top-right font-poppins
            bg-white divide-y w-64 divide-secondary-300 
            rounded-md shadow-lg`}
          >
            {UserDropdownEditor.map((value) => (
              <div className="px-1 py-1 " key={value.name}>
                {value.links.map((link) => (
                  <Menu.Item key={link.name}>
                    {({ active }) => (
                      <Link
                        className={`${
                          active ? 'bg-primary-100 text-white' : 'text-secondary-100'
                        }  group flex rounded-md items-center
                    w-full px-4 py-3 text-base`}
                        to={link.url}
                      >
                        {link.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            ))}
          </Menu.Items>
        )}
      </Transition>
    </Menu>
  </div>
);