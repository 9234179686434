import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addUser,
  authentication,
  authenticationAdmin,
  addRole,
  addPurpose,
  getProfile,
  getProfileAdmin,
  changePassword,
  changeInfo,
  changeInfoAdmin,
  updateCompanyInfo,
  requestEmail,
  changeEmail,
  getAffiliates,
  getAffiliatesAdmin,
  getClicks,
  getConversions,
  getClicksAdmin,
  getConversionsAdmin,
  getAffiliateList,
  getOverallAnalytics,
  getOverallAnalyticsAffiliate,
  getOverallAnalyticsAdmin,
  getAffiliateOverallAnalytics,
  getAffiliateOverviewData,
  getAffiliateGraphAnalytics,
  getRevenueCommission,
  authenticationWixUsesr,
} from '@services';

interface REGISTER {
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
  merchant_link?: any;
  xffiliate_code?: any;
  wixInstance?: string;
}

interface LOGIN {
  email: string;
  password: string;
}

interface ROLE {
  is_company: boolean;
  user_id: string;
  firstname: string;
  lastname: string;
  contact: string;
  role: string;
  company_name?: string;
  company_link?: string;
  company_email?: string;
  contact_number?: string;
  social_link: string;
  store_name?: string;
  store_link?: string;
}

interface PURPOSE {
  user_id: string;
  min_budget: string;
  max_budget: string;
  purpose_xffiliate_links: string;
  icon: any;
  name: string;
  description: string;
}

interface USERINFO {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  contact: string;
  role: string;
  created_at: string;
  subscription: string;
  updated_at: string;
  merchant_id?: any;
}

interface MERCHANTINFO {
  info: {
    store_name: string;
    store_link: string;
    secret_key: any;
  };
  company: {
    name: string;
    description: string;
    image: string;
  }[];
  total_affiliate: Number;
  total_links: Number;
  total_products: Number;
  total_clicks: Number;
  total_conversions: Number;
  total_admins: Number;
}

interface MERCHANTDATA {
  info?: {
    store_name: string;
    store_link: string;
    secret_key: any;
  };
  company: {
    name: string;
    description: string;
    image: string;
  }[];
  total_affiliate?: Number;
  total_links?: Number;
  total_products?: Number;
  total_clicks?: Number;
  total_conversions?: Number;
  total_admins?: Number;
}

interface AFFILIATEINFO {
  company_link: string;
  company_email: string;
  company_name: string;
  contact_number: string;
}

interface AFFILIATEDATA {
  total_conversion: Number;
  totalClicks: Number;
  totalEarned: Number;
}

interface SUBSCRIPTION {
  plan: string;
  days_before_expiration: number;
  date_of_expiration: string;
}

interface REVENUE {
  total_revenue: number;
  total_commission: number;
  pending_revenue: number;
  pending_commission: number;
  paid_commission: number;
  net_revenue: number;
  not_paid_commission: number;
}

interface GRAPH {
  status: number;
  data: {
    graph1: {
      revenue: {
        date_created: string;
        clicked: number;
      }[];
      commission: {
        date_created: string;
        clicked: number;
      }[];
    };
    graph2: {
      clicks: {
        date_created: string;
        clicked: number;
      }[];
      conversion: {
        date_created: string;
        clicked: number;
      }[];
    };
  };
}

interface GRAPHAFF {
  status: number;
  data: {
    dailyConversion: {
      date_created: string;
      clicked: number;
    }[];
    dailyRevenue: {
      date_created: string;
      clicked: number;
    }[];
    dailyCommission: {
      date_created: string;
      clicked: number;
    }[];
    dailyClicks: {
      date_created: string;
      clicked: number;
    }[];
  };
}

interface AFFDATA {
  status: number;
  message: string;
  products: {
    affiliate_id: number;
    product_name: string;
    total_clicks: number;
    total_conversions: number;
    total_earnings: number;
  }[];
}

interface AFFGRAPH {
  status: number;
  message: string;
  data: {
    product: string;
    clicks: {
      date_created: string;
      clicked: number;
    }[];
    conversions: {
      date_created: string;
      converted: number;
    }[];
    earnings: {
      date_created: string;
      earned: number;
    }[];
  }[];
}

interface AFFOVERVIEW {
  status: number;
  message: string;
  total_clicks: number | string;
  total_conversion: number | string;
  total_earnings: number | string;
}

interface STATE {
  loading: boolean;
  data: {
    user_info: USERINFO;
    merchant_info: MERCHANTINFO;
    merchant_data: MERCHANTDATA;
    errors?: {};
    message?: string;
    affiliate_info?: any;
    affiliate_company_info?: AFFILIATEINFO;
    affiliate_data?: AFFILIATEDATA;
    subscription?: SUBSCRIPTION;
    status?: any;
    email_confirmed?: boolean;
    revenue_and_commissions?: REVENUE;
  };

  overallGraphs: GRAPH;
  overallGraphsAff: GRAPHAFF;
  overallGraphsLoading: boolean;

  affiliateAnalytics: AFFDATA;
  affiliateAnalyticsLoading: boolean;

  affiliateGraph: AFFGRAPH;
  affiliateGraphLoading: boolean;

  affiliateOverviewData: AFFOVERVIEW;
  affiliateOverviewDataLoading: boolean;
}

interface NEWPASS {
  old_password: string;
  new_password: string;
}

interface NEWINFO {
  firstname: string;
  lastname: string;
  contact: string;
  role: string;
  subscription: string;
}

interface COMPANYINFO {
  name: string;
  description: string;
  icon: any;
}

interface CHANGEEMAIL {
  email: string;
  code: string;
}

export const usersInitialState: STATE = {
  loading: false,
  data: {
    user_info: {
      id: 0,
      firstname: '',
      lastname: '',
      contact: '',
      email: '',
      role: '',
      created_at: '',
      updated_at: '',
      subscription: '',
      merchant_id: '',
    },
    merchant_info: {
      info: {
        store_name: '',
        store_link: '',
        secret_key: null,
      },
      company: [
        {
          name: '',
          description: '',
          image: '',
        },
      ],
      total_affiliate: 0,
      total_links: 0,
      total_products: 0,
      total_clicks: 0,
      total_conversions: 0,
      total_admins: 0,
    },
    merchant_data: {
      info: {
        store_name: '',
        store_link: '',
        secret_key: null,
      },
      company: [
        {
          name: '',
          description: '',
          image: '',
        },
      ],
      total_affiliate: 0,
      total_links: 0,
      total_products: 0,
      total_clicks: 0,
      total_conversions: 0,
      total_admins: 0,
    },
    affiliate_info: null,
    affiliate_company_info: {
      company_link: '',
      company_email: '',
      company_name: '',
      contact_number: '',
    },
    affiliate_data: {
      total_conversion: 0,
      totalClicks: 0,
      totalEarned: 0,
    },
    subscription: {
      plan: '',
      days_before_expiration: 0,
      date_of_expiration: '',
    },
    message: '',
    status: 0,
    email_confirmed: false,
    revenue_and_commissions: {
      total_revenue: 0,
      total_commission: 0,
      pending_revenue: 0,
      pending_commission: 0,
      paid_commission: 0,
      net_revenue: 0,
      not_paid_commission: 0,
    },
  },

  overallGraphs: {
    status: 0,
    data: {
      graph1: {
        revenue: [
          {
            date_created: '',
            clicked: 0,
          },
        ],
        commission: [
          {
            date_created: '',
            clicked: 0,
          },
        ],
      },
      graph2: {
        clicks: [
          {
            date_created: '',
            clicked: 0,
          },
        ],
        conversion: [
          {
            date_created: '',
            clicked: 0,
          },
        ],
      },
    },
  },

  overallGraphsAff: {
    status: 0,
    data: {
      dailyConversion: [
        {
          date_created: '',
          clicked: 0,
        },
      ],
      dailyRevenue: [
        {
          date_created: '',
          clicked: 0,
        },
      ],
      dailyCommission: [
        {
          date_created: '',
          clicked: 0,
        },
      ],
      dailyClicks: [
        {
          date_created: '',
          clicked: 0,
        },
      ],
    },
  },

  overallGraphsLoading: false,

  affiliateAnalytics: {
    status: 0,
    message: 'initial state',
    products: [],
  },
  affiliateAnalyticsLoading: false,

  affiliateGraph: {
    status: 0,
    message: 'initial state',
    data: [],
  },
  affiliateGraphLoading: false,

  affiliateOverviewData: {
    status: 0,
    message: 'initial state',
    total_clicks: 'Loading..',
    total_conversion: 'Loading..',
    total_earnings: 'Loading..',
  },
  affiliateOverviewDataLoading: false,
};

const login = createAsyncThunk('auth/login', async (body: LOGIN) => {
  const data: any = await authentication(body);
  return data.data;
});

const adminLogin = createAsyncThunk('auth/login', async (body: LOGIN) => {
  const data: any = await authenticationAdmin(body);
  return data.data;
});

const profile = createAsyncThunk('auth/profile', async () => {
  const data: any = await getProfile();
  return data;
});

const profileAdmin = createAsyncThunk('auth/profile-admin', async () => {
  const data: any = await getProfileAdmin();
  return data;
});

const wixInstanceLogin = createAsyncThunk(
  'auth/wix-instance-login',
  async (wixInstance: string) => {
    const data: any = await authenticationWixUsesr({ wixInstance });
    return data;
  },
);

const newUser = createAsyncThunk('auth/newUser', async (body: REGISTER) => {
  const { data, status }: any = await addUser(body);

  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newRole = createAsyncThunk('auth/newRole', async (body: ROLE) => {
  const { data, status }: any = await addRole(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newPurpose = createAsyncThunk('auth/newPurpose', async (body: PURPOSE) => {
  const { data, status }: any = await addPurpose(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const logout = createAsyncThunk('auth/logout', async () => usersInitialState.data);

const newPass = createAsyncThunk('auth/newPass', async (body: NEWPASS) => {
  const { data, status }: any = await changePassword(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newInfo = createAsyncThunk('auth/newInfo', async (body: NEWINFO) => {
  const { data, status }: any = await changeInfo(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newInfoAdmin = createAsyncThunk('auth/newInfo/admin', async (body: NEWINFO) => {
  const { data, status }: any = await changeInfoAdmin(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newCompanyInfo = createAsyncThunk('auth/newCompanyInfo', async (body: COMPANYINFO) => {
  const { data, status }: any = await updateCompanyInfo(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const requestChangeEmail = createAsyncThunk('auth/requestChangeEmail', async (email: string) => {
  const { data }: any = await requestEmail(email);
  return {
    status: data.status,
    message: data.message,
  };
});

const newEmail = createAsyncThunk('auth/newEmail', async (body: CHANGEEMAIL) => {
  const { data, status }: any = await changeEmail(body);
  if (status === 200) {
    return {
      status: data.status,
      message: data.message,
      error: false,
      userId: data.user_id,
    };
  }
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]],
      error: true,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const affiliates = createAsyncThunk('/overall-affiliates-merchant', async () => {
  const data: any = await getAffiliates();
  return data;
});

const affiliatesAdmin = createAsyncThunk(
  '/overall-admin-affiliates-merchant',
  async (id: number) => {
    const data: any = await getAffiliatesAdmin(id);
    return data;
  },
);

const clicks = createAsyncThunk('/overall-clicks-merchant', async () => {
  const data: any = await getClicks();
  return data;
});

const conversions = createAsyncThunk('/overall-conversions-merchant', async () => {
  const data: any = await getConversions();
  return data;
});

const clicksAdmin = createAsyncThunk('/overall-clicks-admin-merchant', async (id: number) => {
  const data: any = await getClicksAdmin(id);
  return data;
});

const conversionsAdmin = createAsyncThunk(
  '/overall-conversions-admin-merchant',
  async (id: number) => {
    const data: any = await getConversionsAdmin(id);
    return data;
  },
);

const affiliateList = createAsyncThunk('/get-affiliates', async (prodID: string) => {
  const data: any = await getAffiliateList(prodID);
  return data;
});

const overallAnalytics = createAsyncThunk('/overall-analytics', async () => {
  const data: any = await getOverallAnalytics();
  return data;
});

const overallAnalyticsAdmin = createAsyncThunk('/overall-analytics', async (id: number) => {
  const data: any = await getOverallAnalyticsAdmin(id);
  return data;
});

const overallAnalyticsAffiliate = createAsyncThunk('/overall-analytics-affiliates', async () => {
  const data: any = await getOverallAnalyticsAffiliate();
  return data;
});

const affiliateOverallAnalytics = createAsyncThunk('/affiliate-overview-by-products', async () => {
  const data: any = await getAffiliateOverallAnalytics();
  return data;
});

const affiliateGraphAnalytics = createAsyncThunk('/affiliate-overview-with-dates', async () => {
  const data: any = await getAffiliateGraphAnalytics();
  return data;
});

const affiliateOverviewData = createAsyncThunk('/affiliate-total-overview', async () => {
  const data: any = await getAffiliateOverviewData();
  return data;
});

const revenueCommission = createAsyncThunk('/revenue-commission', async (id: any) => {
  const data: any = await getRevenueCommission(id);
  return data;
});

const { actions, reducer } = createSlice({
  name: 'user',
  initialState: usersInitialState,
  reducers: {},
  extraReducers: {
    [profile.pending.type]: (state) => {
      state.loading = true;
    },
    [profile.fulfilled.type]: (state, { payload: { data } }) => {
      state.data = data;
      state.loading = false;

      if (
        data.onboarding === 'About You' &&
        window.location.pathname !== `/onboard/aboutyou` &&
        window.location.pathname !== `/affiliate/onboard/aboutyou` &&
        window.location.pathname !== `/affiliate/onboard/signup` &&
        data.merchant_link !== null
      ) {
        window.location.href = `/affiliate/onboard/aboutyou?id=${data.user_id}&merchant=${data.merchant_link}`;
      } else if (
        data.onboarding === 'About You' &&
        window.location.pathname !== `/onboard/aboutyou` &&
        window.location.pathname !== `/affiliate/onboard/aboutyou` &&
        window.location.pathname !== `/affiliate/onboard/signup` &&
        data.merchant_link === null
      ) {
        window.location.href = `/onboard/aboutyou?id=${data.user_id}`;
      } else if (data.onboarding === 'Setup' && window.location.pathname !== `/onboard/setup`) {
        window.location.href = `/onboard/setup?id=${data.user_id}&role=merchant`;
      }
    },
    [logout.fulfilled.type]: (state, { payload: { data } }) => {
      state.data = data;
      state.loading = false;
    },
    [profile.rejected.type]: (state) => {
      state.loading = false;
    },
    [profileAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [profileAdmin.fulfilled.type]: (state, { payload: { data } }) => {
      state.data = data;
      state.loading = false;
    },
    [profileAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    [overallAnalytics.pending.type]: (state) => {
      state.overallGraphsLoading = true;
    },
    [overallAnalytics.fulfilled.type]: (state, { payload }) => {
      state.overallGraphs = payload.data || [];
      state.overallGraphsLoading = false;
    },
    [overallAnalyticsAdmin.pending.type]: (state) => {
      state.overallGraphsLoading = true;
    },
    [overallAnalyticsAdmin.fulfilled.type]: (state, { payload }) => {
      state.overallGraphs = payload.data || [];
      state.overallGraphsLoading = false;
    },
    [overallAnalyticsAffiliate.pending.type]: (state) => {
      state.overallGraphsLoading = true;
    },
    [overallAnalyticsAffiliate.fulfilled.type]: (state, { payload }) => {
      state.overallGraphsAff = payload.data || [];
      state.overallGraphsLoading = false;
    },
    [affiliateOverallAnalytics.pending.type]: (state) => {
      state.affiliateAnalyticsLoading = true;
    },
    [affiliateOverallAnalytics.fulfilled.type]: (state, { payload }) => {
      state.affiliateAnalytics = payload.data || [];
      state.affiliateAnalyticsLoading = false;
    },
    [affiliateGraphAnalytics.pending.type]: (state) => {
      state.affiliateGraphLoading = true;
    },
    [affiliateGraphAnalytics.fulfilled.type]: (state, { payload }) => {
      state.affiliateGraph = payload.data || [];
      state.affiliateGraphLoading = false;
    },
    [affiliateOverviewData.pending.type]: (state) => {
      state.affiliateOverviewDataLoading = true;
    },
    [affiliateOverviewData.fulfilled.type]: (state, { payload }) => {
      state.affiliateOverviewData = payload.data || [];
      state.affiliateOverviewDataLoading = false;
    },
  },
});

export const usersActions = {
  ...actions,
  affiliates,
  affiliatesAdmin,
  affiliateList,
  affiliateOverallAnalytics,
  affiliateGraphAnalytics,
  affiliateOverviewData,
  clicks,
  conversions,
  clicksAdmin,
  conversionsAdmin,
  login,
  adminLogin,
  newUser,
  newRole,
  newPurpose,
  profile,
  profileAdmin,
  logout,
  newPass,
  newInfo,
  newInfoAdmin,
  newCompanyInfo,
  requestChangeEmail,
  newEmail,
  overallAnalytics,
  overallAnalyticsAffiliate,
  overallAnalyticsAdmin,
  revenueCommission,
  wixInstanceLogin,
};

export const usersReducer = reducer;
