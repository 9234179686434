import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import { selectUser } from '@redux';
import { FlexRow, Button, Text, Icons } from '@core';
import { SHARE } from '@config';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const QrCodeAdminModal: FC<ModalProps> = ({ isOpen, onClose }) => {

  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [download, setDownload] = React.useState(false);
  const [url, setUrl] = React.useState<any>('');
  const [copied, setCopied] = React.useState<any>(false);
  const query = new URLSearchParams(search);
  const refLink = query.get('referral_link');

  React.useEffect(() => {
    if (refLink) {
      setUrl(`${SHARE}${refLink}`);
    }
    setCopied(false);
  }, [refLink]);

  const handleDownloadImage = async () => {
    setDownload(true);
    const element: any = document.getElementById('qrcode');
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    link.href = data;
    link.download = 'QRCODE.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownload(false);
  };

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
        onClose={() => {
          onClose();
          navigate(`/merchant/admin_dashboard/${user?.user_info.role.toLowerCase()}`);
        }}
      >
        <div className="h-full text-center flex justify-end">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
            >
              <FlexRow
                className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
              >
                <Dialog.Title as="h3">REFERRAL LINK</Dialog.Title>
                <i
                  className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                  onClick={() => {
                    onClose();
                    navigate(`/merchant/admin_dashboard/${user?.user_info.role.toLowerCase()}`);
                  }}
                />
              </FlexRow>
              <div className="px-8 h-full py-8 flex flex-col items-center overflow-y-scroll scrollbar-hide">
                <Text className="font-bold text-lg mb-3 text-blue-100 select-none">{url}</Text>
                <QRCode
                  bgColor="#ffffff"
                  fgColor="#223141"
                  id="qrcode"
                  imageSettings={{
                    src: Icons.xffiliateQrcode,
                    height: 80,
                    width: 80,
                  }}
                  includeMargin={false}
                  level="H"
                  renderAs="canvas"
                  size={280}
                  value={`${url}`}
                />
                <Text className="font-poppins font-normal text-base text-grey-100  mt-6 mb-3">
                  This scannable code is a shortcut to your referral link.
                </Text>
                <Text className="font-poppins font-normal text-base text-grey-100  mb-5">
                  Place the code anywhere people can scan it with their phones: brochures, event
                  posters, invoices... The ideas are limitless.
                </Text>
              </div>
              <hr />
              <div className="flex flex-col  mx-8 my-5">
                <Button
                  className={`w-full justify-center items-center mb-5 ${
                    copied ? 'bg-green-100' : 'bg-blue-100'
                  }`}
                  onClick={() => {
                    setCopied(true);
                    navigator.clipboard.writeText(`${url}`);
                  }}
                >
                  {copied ? 'COPIED' : 'COPY LINK'}
                </Button>
                <Button
                  className="w-full justify-center items-center bg-blue-100"
                  isLoading={download}
                  onClick={handleDownloadImage}
                >
                  DOWNLOAD
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};