import React, {FC} from 'react';
import { Div, Text, FlexRow } from '@core';

interface DataProps {
	id: number;
	name: string;
	link: string;
	commission: string;
	price: string;
}

export const MerchantProductData: FC<DataProps> = ({
	id,
	name,
	link,
	commission,
	price,
}) => (
<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2'>
	<Div className='phone:flex phone:flex-row tabletWide:hidden' key={id}>
	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Product ID:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Product Name:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Product Link:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Commission:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Price:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {id}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {name}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left truncate"
	      >
	        {link}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {commission}%
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        &#8369; {price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
	      </Text>
	  </Div>
	</Div>

	<FlexRow className='w-full justify-between phone:hidden tabletWide:flex'>
		<Text
		  className="text-base text-secondary-200 font-light w-1/13"
		>
		  {id}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/4"
		>
		  {name}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-2/5 break-words"
		>
		  {link}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/12"
		>
		  {commission}%
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/12"
		>
		  &#8369; {price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		</Text>
	</FlexRow>
	
</div>
)