import React, { FC } from 'react';

interface H2Props {
  className?: string;
  props?: any;
  style?: any;
}

export const H2Custom: FC<H2Props> = ({ className, style, children, ...props }) => (
  <h2
    className={`font-roboto font-medium phone:text-xl 
    laptop:text-2xl desktop:text-3xl ${className}`}
    {...props}
    style={{color: style}}
  >
    {children}
  </h2>
);