import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { Tab } from '@headlessui/react';
import { BsFillHandbagFill } from 'react-icons/bs';
import { FaUserTag } from 'react-icons/fa';
import { MerchantAdminData, MerchantProductData, AffiliateData, Pagination } from '@components';
import { Div, Flex, FlexRow, FlexCenter, Text, Icons, ToastCustom } from '@core';
import { getUserDetails, getAffiliateOfMerchantFromAdmin, getProductOfMerchantFromAdmin } from '@services';
import { useWindowSize } from '@hooks';
import { formatDate2, formatTime, formatTime2, classNames } from '@helpers';

export const UserDetailsMerchant: FC<any> = ({ user }) => {

	const [userData, setUserData] = React.useState<any>('');
	const [productData, setProductData] = React.useState([]);
	const [affiliateData, setAffiliateData] = React.useState([]);
	const [isShow, setIsShow] = React.useState(false);
	const [isToastOpen, setToastOpen] = React.useState(false);
	const [imageLoaded, setImageLoaded] = React.useState(false);
	const [newDate, setNewDate] = React.useState('-- --');
	const [newDateRegister, setNewDateRegister] = React.useState<any>('-- --');

	const [adminList, setAdminList] = React.useState([]);

	const { search } = useLocation();
	const query = new URLSearchParams(search);
  const lastLogin = query.get('login');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentProducts = productData.slice(indexOfFirstArticle, indexOfLastArticle);
  const currentAffiliates = affiliateData.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const window = useWindowSize();
  const tabs = ['Personal Info', 'Other Info', 'Admin Accounts', 'Products', 'Affiliates'];

  function checkTabMobile(tab: string) {
    switch (tab) {
      case tabs[0]:
        return <i className='fas fa-user' />;
      case tabs[1]:
        return <i className='fas fa-clipboard-list' />;
      case tabs[2]:
        return <div className='flex flex-row justify-center'><FaUserTag /></div>;
      case tabs[3]:
        return <div className='flex flex-row justify-center'><BsFillHandbagFill /></div>;
      case tabs[4]:
        return <i className='fas fa-users' />;
      default:
        return <i className='fas fa-user' />;
    }
  }

  function checkTabDesktop(tab: string) {
    switch (tab) {
      case tabs[0]:
        return tabs[0];
      case tabs[1]:
        return `Other Info`;
      case tabs[2]:
        return `Admin`;
      case tabs[3]:
        return `Products`;
      case tabs[4]:
        return `Affiliates`;
      default:
        return '';
    }
  }

	React.useEffect(() => {
	  batch(async() => {
	  	setToastOpen(true)
	  	setIsShow(false)
	    const data:any = await getUserDetails(user);
	    if(data?.data?.status === 1){
	    	const newDateA = new Date(data?.data?.user_info?.created_at);
	    	const tempData: any = [...data?.data?.merchant_data?.admins];
	    	const sortedData: any = await tempData.sort((a: any, b: any) => {
	    		if(a.name < b.name) { return -1; }
	    		if(a.name > b.name) { return 1; }
	    		return 0;
	    	});

	      setUserData(data?.data)
	      setNewDateRegister(newDateA)
	      setAdminList(sortedData)
	    }

	    const products:any = await getProductOfMerchantFromAdmin(user);
	    if(products?.data?.status === 1){
	    	const tempData: any = [...products?.data?.data];
	    	const sortedData: any = await tempData.sort((a: any, b: any) => a.id - b.id);
	    	setProductData(sortedData)
	    }

	    const affiliates:any = await getAffiliateOfMerchantFromAdmin(user);
	    if(affiliates?.data?.status === 1){
	    	const tempData: any = [...affiliates?.data?.data];
	    	const sortedData: any = await tempData.sort((a: any, b: any) => {
	    		if(a.firstname < b.firstname) { return -1; }
	    		if(a.firstname > b.firstname) { return 1; }
	    		return 0;
	    	});
	    	setAffiliateData(sortedData)
	    }

	    setTimeout(() => {
	      	setIsShow(true)
	      	setToastOpen(false)
	    }, 750)
	  })
	}, [user])

	React.useEffect(() => {
		if(lastLogin && lastLogin !== '-- --'){
			setNewDate(`${formatDate2(lastLogin.substring(0, 10))} at ${formatTime(lastLogin.substring(10, 18))}`)
		}
	}, [lastLogin])
	
return( 
	<>
	{
	userData === '' || !isShow ?
	<ToastCustom
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	>
		<Div className='w-full h-full flex items-center justify-center'>
			<Text className='text-xl text-white font-semibold items-center flex flex-row'>
			  Please wait...
			  <i className="fad fa-spinner-third animate-spin text-2xl mx-4" />
			</Text>
		</Div>
	</ToastCustom>
	:
	<>
	<Text className="font-poppins font-semibold text-2xl text-primary-100 mb-4">
        User Information
  </Text>
  <hr className="text-grey-400 mb-8" />

  <FlexRow className='phone:flex-col items-center tablet:flex-row'>
  	<Div className="h-40 w-40 border-primary-100 border-4 rounded-full overflow-hidden">
  	  <img
  	      alt="Comapny Logo"
  	      className="w-full h-full object-contain"
  	      onError={() => setImageLoaded(true)}
  	      src={
  	        imageLoaded
  	          ? Icons.xffiliate2
  	          : userData?.merchant_data?.company[0]?.image
  	      }
  	  />
  	</Div>

  	<Div className='w-full tablet:w-4/5 px-4 tablet:px-8 laptop:px-20'>
  		{
  			userData?.user_info?.role === 'affiliate' &&
        userData?.user_info?.firstname === 'none' ?
  			<Text className='text-blue-100 font-semibold laptop:text-xl mt-4'>
  				{userData?.affiliate_company_info?.company_name}
  			</Text>
  			:
  			<Text className='text-blue-100 font-semibold laptop:text-xl mt-4 mr-auto tablet:mr-4'>
  				{`${userData?.user_info?.firstname} ${userData?.user_info?.lastname}`}
  			</Text>
  		}
  		<Text className='text-secondary-200'>
  			{userData?.user_info?.role.toUpperCase()}
  		</Text>
  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
	  		<Text className='font-semibold mr-auto tablet:mr-4'>
	  			Registered:
	  		</Text>
	  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
	  			{`${formatDate2(newDateRegister)} at ${formatTime2(newDateRegister)}`}
	  		</Text>
  		</FlexRow>
  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
	  		<Text className='font-semibold mr-auto tablet:mr-4'>
	  			Last Login:
	  		</Text>
	  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
	  			{newDate}
	  		</Text>
  		</FlexRow>
  		<FlexRow className='my-4'>
	  		<Text className='font-semibold mr-4'>
	  			Account:
	  		</Text>
	  		{
	  			userData?.email_confirmed?
	  			<Text className='bg-green-100 text-white font-semibold px-4 py-1 rounded-full'>
	  				VERIFIED
	  			</Text>
	  			:
	  			<Text className='bg-red-400 text-white font-semibold px-4 py-1 rounded-full'>
	  				NOT VERIFIED
	  			</Text>
	  		}
  		</FlexRow>
  	</Div>
  </FlexRow>

  <FlexRow className="mt-12">
    <Tab.Group>
      <Flex>
        <Tab.List className="tabletWide:space-x-8">
          {tabs.map((tab) => (
            <Tab
              className={({ selected }) =>
                classNames(
                  'py-3 font-semibold phone:w-1/5 tabletWide:w-auto',
                  selected
                    ? 'text-primary-100 text-xl border-b-4 border-blue-400'
                    : 'text-grey-700 text-xl  hover:text-grey-200',
                )
              }
              key={tab}
            >
              {window.width > 1000 ? checkTabDesktop(tab) : checkTabMobile(tab)}
            </Tab>
          ))}
        </Tab.List>
        <hr className="text-grey-1200" />
        <Tab.Panels className="pt-9">

        	{/* Personal Info */}
          <Tab.Panel>
            	<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			First Name:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.firstname}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Last Name:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.lastname}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Email:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.email}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Mobile:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.contact}
					  		</Text>
				  		</FlexRow>
          </Tab.Panel>

        	{/* Other Info */}
          <Tab.Panel>
            	<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Merchant Store:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.merchant_data?.company[0].name}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Store Link:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.merchant_data?.info.store_link}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4 mb-auto'>
					  			Description:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4 mb-auto'>
					  			{userData?.merchant_data?.company[0].description}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Subscription Plan:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.subscription?.plan.toUpperCase()}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Subscribed Until:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.subscription?.date_of_expiration}
					  		</Text>
				  		</FlexRow>
          </Tab.Panel>

          {/* Admin Info */}
          <Tab.Panel>
          	{
          		adminList.length > 0 ?
          		<>
          		<FlexRow className='w-full justify-start mt-4 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
          			<div className='text-primary-100 font-medium py-2 w-1/4'>
          				Admin Name
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/4'>
          				Admin Type
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/4'>
          				Email
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/4'>
          				Mobile No.
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          		</FlexRow>

          		<Text className="font-poppins font-semibold text-primary-100 py-2 border-b border-grey-400 mb-4 tabletWide:hidden">
          		  List of Admins
          		</Text>
          		{
          			adminList.map((admin: any, index: number) => (
          				<MerchantAdminData
          					contact={admin?.contact}
          					email={admin?.email}
          					id={admin?.id}
          					key={index}
          					name={`${admin?.firstname} ${admin?.lastname}`}
          					type={admin?.role.toUpperCase()}
          				/>
          			))
          		}
          		</>
          		:
          		<FlexCenter className="col-span-2 py-12">
          			<Text>
          			  Currently no admins listed.
          			</Text>
          		</FlexCenter>
          	}
            
          </Tab.Panel>

        	{/* Products Info */}
          <Tab.Panel>
            {
          		productData.length > 0 ?
          		<>
          		<FlexRow className='w-full justify-between mt-4 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
          			<div className='text-primary-100 font-medium py-2 w-1/13'>
          				Product ID
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/4'>
          				Product Name
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-2/5'>
          				Product Link
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/12'>
          				Commission
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/12'>
          				Price
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          		</FlexRow>

          		<Text className="font-poppins font-semibold text-primary-100 py-2 border-b border-grey-400 mb-4 tabletWide:hidden">
          		  List of Products
          		</Text>
          		{
          			currentProducts.map((product: any, index: number) => (
          				<MerchantProductData
          					commission={product?.commission}
          					id={product?.id}
          					key={index}
          					link={product?.link}
          					name={product?.name}
          					price={product?.price}
          				/>
          			))
          		}
          		</>
          		:
          		<FlexCenter className="col-span-2 py-12">
          			<Text>
          			  Currently no products listed.
          			</Text>
          		</FlexCenter>
          	}

          	<div className='mb-36 mt-8'>
          		<Pagination
          		  currentPage={currentPage}
          		  itemsPerPage={articlesPerPage}
          		  paginate={paginate}
          		  totalItems={productData.length}
          		/>
          	</div>
          </Tab.Panel>

        	{/* Affiliates Info */}
          <Tab.Panel>
          	{
          		affiliateData.length > 0 ?
          		<>
          		<FlexRow className='w-full justify-between mt-4 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
          			<div className='text-primary-100 font-medium py-2 w-1/5'>
          				Affiliate Name
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/5'>
          				Email
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/12'>
          				Mobile No.
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-5/12'>
          				Social Link
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          		</FlexRow>
          		
          		<Text className="font-poppins font-semibold text-primary-100 py-2 border-b border-grey-400 mb-4 tabletWide:hidden">
          		  List of Affiliates
          		</Text>
          		{
          			currentAffiliates.map((affiliate: any, index: number) => (
          				<AffiliateData
          					contact={affiliate?.contact}
          					email={affiliate?.email}
          					id={affiliate?.id}
          					key={index}
          					link={affiliate?.social_link}
          					name={affiliate?.name}
          				/>
          			))
          		}
          		</>
          		:
          		<FlexCenter className="col-span-2 py-12">
          			<Text>
          			  Currently no affiliates listed.
          			</Text>
          		</FlexCenter>
          	}

          	<div className='mb-36 mt-8'>
          		<Pagination
          		  currentPage={currentPage}
          		  itemsPerPage={articlesPerPage}
          		  paginate={paginate}
          		  totalItems={affiliateData.length}
          		/>
          	</div>
          </Tab.Panel>

        </Tab.Panels>
      </Flex>
    </Tab.Group>
  </FlexRow>

  </>
	}
  </>
 )
}; 