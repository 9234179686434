import React, { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import { Wait } from '@pages';
import { useAppDispatch, catalogsActions, partnersActions, selectUser } from '@redux';
import { NavBarAffiliate } from '@components';
import { Button, ButtonIcon, Div, Flex, FlexRow, Text, Toast, Icons, ToastCustom } from '@core';

export const CatalogDetails: FC<any> = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [details, setDetails] = React.useState<any>();
  const query = new URLSearchParams(search);
  const productid = query.get('productid');
  const merchantId = query.get('merchant');
  const merchantStore = query.get('merchantStore');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [iconLoaded, setIconLoaded] = React.useState(false);

  const [isToastOpen2, setToastOpen2] = React.useState(false);

  const onSubmit = () => {
    batch(async () => {
      setLoading(true);
      const data: any = await dispatch(
        partnersActions.newPartner({
          user_id: `${user?.user_info?.id}`,
          product_id: `${productid}`,
          status: '0',
          role: 'affiliate',
        }),
      );
      if (data?.payload?.status === 200) {
        setToastTitle('Successfully Added');
        setToastDesc(`Affiliate application added.`);
        setToastKind('success');
        setToastOpen(true);
        setLoading(false);
      } else {
        setToastTitle('Denied');
        setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    batch(async () => {
      await dispatch(catalogsActions.catalogDetails(`${productid}`)).then((res) => {
        setDetails(res?.payload?.data);
      });
    });
  }, [batch, dispatch, catalogsActions]);

  if (!details) {
    return <Wait />;
  }

  return (
    <Div className="h-full overflow-x-scroll scrollbar-hide">
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <ToastCustom
            isOpen={isToastOpen2}
            onClose={() => setToastOpen2(false)}
      >
        <Div className='w-full h-full'>
          <img
            alt="images"
            className="object-contain h-full w-full rounded-3xl"
            onError={() => setImageLoaded(true)}
            src={imageLoaded ? Icons.xffiliate2 : details?.data?.image}
          />
        </Div>
      </ToastCustom>
      <NavBarAffiliate />
      <Flex className="pt-32 desktop:px-96 laptop:px-72 tablet:px-12 phone:px-8">
        {
          (merchantId === null || merchantId === 'undefined' || merchantId === 'null') ?
          <Link to="/affiliate/catalogs">
            <Text className="font-medium text-primary-100 hover:text-blue-100">
              <i className="fas fa-angle-left text-lg mr-3" />
              Back to Catalog
            </Text>
          </Link>
          :
          <Link to={`/affiliate/merchant_catalogs?merchant=${merchantId}&merchantStore=${merchantStore}`}>
            <Text className="font-medium text-primary-100 hover:text-blue-100">
              <i className="fas fa-angle-left text-lg mr-3" />
              Back to Catalog
            </Text>
          </Link>
        }
        <FlexRow className="mt-10 w-full tablet:flex-row phone:flex-col pb-12">
          <div
            className="tablet:w-400px h-400px phone:w-full rounded-3xl parentToolTip"
            onClick={() => setToastOpen2(true)}
          >
            <img
              alt="images"
              className="h-full w-full object-contain cursor-pointer"
              onError={() => setImageLoaded(true)}
              src={imageLoaded ? Icons.xffiliate2 : `${details?.data?.image}`}
              title='Click to view full image'
            />
          </div>
          <Div
            className="flex flex-col phone:mt-10 laptop:w-700px phone:w-full phone:ml-0 
            tablet:ml-10 tablet:mt-0"
          >
            <FlexRow className='phone:flex-col tablet:flex-row'>
              <Text
                className="text-lg font-medium bg-blue-300 
              rounded-lg text-center text-blue-100 px-4"
              >
                {details?.data?.category}
              </Text>
              <Text
                className="phone:text-base font-medium 
                      rounded-lg text-center text-secondary-200 px-2 mt-2 tablet:mt-0"
              >
                {details?.data?.sub_category && details?.data?.sub_category !== "null" && details?.data?.category !== 'Others' ? details?.data?.sub_category : ''}
              </Text>
            </FlexRow>
            <Text
              className="text-3xl font-semibold
              rounded-lg text-primary-100 mt-3"
            >
              {details?.data?.name}
            </Text>
            <Text
              className="text-base font-normal
              rounded-lg text-grey-200 hidden"
            >
              {details?.data?.productCode}
            </Text>
            <div
              className='font-robot font-regular text-secondary-200 laptop:text-base phone:text-sm hover:underline cursor-pointer hidden'
              onClick={() => setToastOpen2(true)}>
              View full image size
            </div>
            <Text className="text-orange-100 text-2xl font-semibold mt-4">
              PHP{' '}
              {parseFloat(details?.data?.price)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </Text>
            <Text
              className="text-lg mt-5 mb-2 font-semibold
              rounded-lg text-secondary-100"
            >
              Description
            </Text>
            <Text className="text-base rounded-lg text-secondary-100">
              {details?.data?.description}
            </Text>
            <Text
              className="text-xl font-semibold
              rounded-lg text-secondary-100 mt-4"
            >
              Earn{' '}
              <span className="text-3xl mx-1 text-orange-100 font-bold">
                {details?.data?.commission}%
              </span>
              commission
            </Text>
            <FlexRow className="mt-7 tablet:flex-row phone:flex-col">
              <a
                className="phone:w-full tablet:w-56"
                href={details?.data?.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                <ButtonIcon
                  className="bg-grey-800 hover:bg-primary-300 phone:text-primary-500 pt-3 pb-3 phone:w-full tablet:w-56 justify-center"
                  icon="fas fa-external-link"
                >
                  Visit website
                </ButtonIcon>
              </a>

              <Button
                className="pt-3.5 pb-3.5 phone:w-full tablet:w-56 justify-center ml-2 bg-primary-100"
                isLoading={loading}
                onClick={onSubmit}
              >
                Become an affiliate
              </Button>
            </FlexRow>
          </Div>
        </FlexRow>
        <hr className="text-grey-200 mt-10 mb-5" />
        <FlexRow className="items-center">
          <Div className="border border-blue-100 rounded-full h-24 w-24 p-1 overflow-hidden">
            <a
              href={details?.merchant_detail?.store_link}
              rel="noopener noreferrer"
              target="_blank"
              title='Go to Merchant Website'
            >
            <img
              alt="images"
              className="h-full w-full object-cover rounded-full"
              onError={() => setIconLoaded(true)}
              src={
                iconLoaded
                  ? Icons.xffiliate2
                  : `${details?.company_detail?.company_icon}`
              }
            />
            </a>
          </Div>
          <Div className="ml-4">
            <Text
              className="text-lg font-semibold
              rounded-lg text-secondary-100"
            >
              {details?.company_detail?.name}
            </Text>
            <Text
              className="text-base font-normal
              rounded-lg text-grey-200"
            >
              Merchant
            </Text>
          </Div>
          <a
            className="ml-auto phone:hidden"
            href={details?.merchant_detail?.store_link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button className="pt-3.5 pb-3.5 ml-auto bg-grey-800 hover:bg-primary-300 phone:text-primary-500">Go to Merchant page</Button>
          </a>
        </FlexRow>
      </Flex>
    </Div>
  );
};