import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Div, Icons, Text, Button, Input, Toast } from '@core';
import { requestForgotPassword } from '@services';

export const RequestReset = () => {
  const [email, setEmail] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const onSubmit = async () => {
    setLoading(true);
    const res = await requestForgotPassword({ email });
    console.log('res', res.data);

    if (res.data.status === 0) {
      setToastKind('error');
      setToastTitle('Not found');
      setToastDesc('Email not found!');
      setToastOpen(true);
      setLoading(false);
    } else {
      setToastKind('success');
      setToastTitle('Sent');
      setToastDesc('Email successfully sent!');
      setToastOpen(true);
      setLoading(false);
      setSuccess(true);
    }
  };

  return (
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center pb-20 overflow-scroll scrollbar-hide">
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300 w-full" />

      {
        !success?
        <Link className="mr-auto mt-10 tabletWide:pl-20 phone:pl-0" to="/">
          <button className="text-primary-500 hover:text-primary-200" type="button">
            <Text className="items-center flex laptop:text-lg phone:base">
              <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to Login
            </Text>
          </button>
        </Link>
        :
        <></>
      }

      <Flex className="items-center laptop:pt-28 phone:pt-20 tablet:w-500px phone:w-full">
        {!success ? (
          <>
            <Text
              className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-5"
            >
              Forgot your <span className="text-orange-100">Password</span>?
            </Text>
            <Text className="mb-10 text-center">
              Please enter your registered email address. An email notification with a password
              reset link will be sent to you.
            </Text>
            <Input
              containerClass="w-full"
              label="Email address"
              onChange={setEmail}
              placeholder="eg: sample@gmail.com"
              type="email"
              value={email}
            />
            <Button className="px-16 mt-10" isLoading={loading} onClick={() => onSubmit()}>
              Send reset link
            </Button>
          </>
        ) : (
          <>
            <Text
              className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-5 mt-20"
            >
              Reset link <span className="text-orange-100">sent</span>!
            </Text>
            <Text className="mb-10 text-center">A password reset link has been sent to {email}</Text>
            <Link to="/">
              <Button className="px-10">Go to login page</Button>
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  );
};
