export const Titles = [
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Individual Contributor',
    value: 'individual contributor',
  },
  {
    label: 'Manager',
    value: 'manager',
  },
  {
    label: 'Director',
    value: 'director',
  },
  {
    label: 'VP',
    value: 'vp',
  },
  {
    label: 'Executive',
    value: 'executive',
  },
  {
    label: 'Not Applicable',
    value: 'not applicable',
  },
];

export const Departments = [
  {
    label: 'Digital Marketing',
    value: 'digital marketing',
  },
  {
    label: 'Product / Tech',
    value: 'Product / Tech',
  },
  {
    label: 'Social Media',
    value: 'Social Media',
  },
];

export const OriganizationSizes = [
  {
    label: 'Less than 50',
    value: 'Less than 50',
  },
  {
    label: '50-200',
    value: '50-200',
  },
  {
    label: '200-500',
    value: '200-500',
  },
];

export const PaymentMethods = ['Visa', 'Master Card', 'GCash', 'Paymaya', 'GrabPay'];

export const AddPaymentMethods = [

  'BPI/BPI Family Savings Bank',
  'BDO Unibank Inc',
  'China Bank',
  'Metrobank',
  'Security Bank',
  'Citi Bank, NA',
];

export const EWallet = [

  'GCash',
  'Paymaya',
  'GrabPay'
  
];

export const Categories = [
  'Health & Beauty',
  'Accessories',
  'Clothing',
  'Personal Brand',
  'Health & Wellness',
  'Home & Kitchen',
  'Pet Supplies',
  'Software',
  'Food & Beverages',
  'Others',
];

export const MainCategories = [
  'Computer & Technology',
  'Health & Wellness',
  'Fashion & Accessories',
  'Clothing',
  'Hobbies & Crafts',
  'Sports & Fitness',
  'Home & Appliances',
  'Pet Supplies',
  'Food & Beverages',
  'Automotive',
  'Others',
];

export const SubsCategories = [

  [
    'Mobile',
    'Tablets',
    'Laptops',
    'Desktops',
    'Security Cameras',
    'Actions/Video Cameras',
    'Digital Cameras',
    'Gaming Consoles',
    'Gadgets',
    'Mobile Accessories',
    'Tablet Accessories',
    'Computer Accessories',
    'Camera Accessories',
    'Console Accessories',
    'Printers',
    'Storage',
    'Computer Components',
    'Network Components',
    'Wearable Technology',
    'Software/Service',
    'Audio',
    'Digital Goods',
    'Monitor/Projectors',
    'TVs & Accessories',
  ],
  [
    'Make-up',
    'Skincare',
    'Hair Care',
    'Bath & Body',
    'Personal Care',
    "Men's Care",
    'Fragrances',
    'Beauty Tools',
    'Food Supplements',
    'Medical Supplies',
    'Sexual Wellness',
    'Adult Diapers & Incontinence',
  ],
  [
    "Women's Accessories",
    "Women's Bags",
    "Women's Watches",
    "Women's Jewelry",
    "Men's Accessories",
    "Men's Bags",
    "Men's Watches",
    "Men's Jewelry",
    "Kid's Accessories",
    "Kid's Bags",
    "Kid's Watches",
    "Kid's Jewelry",
  ],
  [
    "Women's Clothing",
    "Women's Sports Clothing",
    "Women's Shoes",
    "Women's Sports Shoes",
    "Lingerie, Sleep & Lounge",
    "Men's Clothing",
    "Men's Shoes",
    "Men's Sports Clothing",
    "Men's Sports Shoes",
    "Underwear",
    "Girl's Clothing",
    "Girl's Shoes",
    "Girl's Underwear",
    "Girl's Sports Clothing",
    "Girl's Sports Shoes",
    "Boy's Clothing",
    "Boy's Shoes",
    "Boy's Underwear",
    "Boy's Sports Clothing",
    "Boy's Sports Shoes",
  ],
  [
    "E-Books",
    "Books and Magazine",
    "Writing Materials",
    "Arts and Crafts",
    "School Supplies",
    "Packaging",
    "Musical Instruments",
  ],
  [
    "Training Equipment",
    "Fitness Stands/Bikes",
    "Gym Machines",
    "Fitness Accessories",
    "Sports Wear",
  ],
  [
    "Bath",
    "Bedding",
    "Furniture",
    "Storage & Organisation",
    "Laundry & Cleaning Equipment",
    "Kitchen & Dining",
    "Lighting",
    "Home Decor",
    "Outdoor Garden",
    "Tools & Home Improvement",
  ],
  [
    "Pet Food",
    "Grooming",
    "Aquarium Needs",
    "Litter & Toilet",
    "Bowls & Feeders",
    "Leashes, Collars & Muzzles",
    "Beds, Mats & Houses",
    "Cages, Crates & Doors",
    'Toys',
    "Pet Healthcare",
  ],
  [
    "Non-Alcoholic Drinks",
    "Alcoholic Drinks",
    "Food Staples & Cooking Essentials",
    "Chocolate, Snacks & Sweets",
    "Breakfast Cereals & Spreads",
    "Frozen",
    "Bakery",
    "Fruit & Vegetables",
    "Meat & Seafood",
  ],
  [
    "Cars & Car Care",
    "Electronics & Accessories",
    "Oils & Fluids",
    "Tires & Wheels",
    "Tools & Equipment",
    "Trucks & Heavy Duty Vehicles",
    "Motorcycles & Parts",
  ],
  [
    '-- --',
  ],
];

export const ActionsSelect = [
  {
    icon: 'check',
    label: 'Approve Selected',
    value: 'Approve Selected',
  },
  {
    icon: 'times',
    label: 'Reject Selected',
    value: 'Reject Selected',
  },
]

export const ActionsSelect2 = [
  {
    icon: 'times',
    label: 'Remove Selected',
    value: 'Remove Selected',
  },
]

export const ActionsSelect3 = [
  {
    icon: 'check',
    label: 'Update Selected',
    value: 'Update Selected',
  },
  {
    icon: 'trash-alt',
    label: 'Clear Selected',
    value: 'Clear Selected',
  },
  {
    icon: 'list',
    label: 'Select/Unselect',
    value: 'Select/Unselect',
  },
]

export const CategoriesTagging = [
  {
    label: 'By Role',
    value: 'By Role',
  },
  {
    label: 'By Commissions',
    value: 'By Commissions',
  },
  {
    label: 'Non-Categorized',
    value: 'Non-Categorized',
  },
]

export const CategoriesSelect = [
  {
    label: 'Health & Beauty',
    value: 'Health & Beauty',
  },
  {
    label: 'Accessories',
    value: 'Accessories',
  },
  {
    label: 'Clothing',
    value: 'Clothing',
  },
  {
    label: 'Personal Brand',
    value: 'Personal Brand',
  },
  {
    label: 'Health & Wellness',
    value: 'Health & Wellness',
  },
  {
    label: 'Home & Kitchen',
    value: 'Home & Kitchen',
  },
  {
    label: 'Pet Supplies',
    value: 'Pet Supplies',
  },
  {
    label: 'Software',
    value: 'Software',
  },
  {
    label: 'Food & Beverages',
    value: 'Food & Beverages',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

export const MainCategoriesSelect = [
  {
    label: 'Computer & Technology',
    value: 'ComputerTechnology',
  },
  {
    label: 'Health & Wellness',
    value: 'HealthWellness',
  },
  {
    label: 'Fashion & Accessories',
    value: 'FashionAccessories',
  },
  {
    label: 'Clothing',
    value: 'Clothing',
  },
  {
    label: 'Hobbies & Crafts',
    value: 'HobbiesCrafts',
  },
  {
    label: 'Sports & Fitness',
    value: 'SportsFitness',
  },
  {
    label: 'Home & Appliances',
    value: 'HomeAppliances',
  },
  {
    label: 'Pet Supplies',
    value: 'PetSupplies',
  },
  {
    label: 'Food & Beverages',
    value: 'FoodBeverages',
  },
  {
    label: 'Automotive',
    value: 'Automotive',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

export const SubCategories = [

  [
    {
      label: 'Mobile',
      value: 'Mobile',
    },
    {
      label: 'Tablets',
      value: 'Tablets',
    },
    {
      label: 'Laptops',
      value: 'Laptops',
    },
    {
      label: 'Desktops',
      value: 'Desktops',
    },
    {
      label: 'Security Cameras',
      value: 'Security Cameras',
    },
    {
      label: 'Action/Video Cameras',
      value: 'Actions/Video Cameras',
    },
    {
      label: 'Digital Cameras',
      value: 'Digital Cameras',
    },
    {
      label: 'Gaming Consoles',
      value: 'Gaming Consoles',
    },
    {
      label: 'Gadgets',
      value: 'Gadgets',
    },
    {
      label: 'Mobile Accessories',
      value: 'Mobile Accessories',
    },
    {
      label: 'Tablet Accessories',
      value: 'Tablet Accessories',
    },
    {
      label: 'Computer Accessories',
      value: 'Computer Accessories',
    },
    {
      label: 'Camera Accessories',
      value: 'Camera Accessories',
    },
    {
      label: 'Console Accessories',
      value: 'Console Accessories',
    },
    {
      label: 'Printers',
      value: 'Printers',
    },
    {
      label: 'Storage',
      value: 'Storage',
    },
    {
      label: 'Computer Components',
      value: 'Computer Components',
    },
    {
      label: 'Network Components',
      value: 'Network Components',
    },
    {
      label: 'Wearable Technology',
      value: 'Wearable Technology',
    },
    {
      label: 'Software/Service',
      value: 'Software/Service',
    },
    {
      label: 'Audio',
      value: 'Audio',
    },
    {
      label: 'Digital Goods',
      value: 'Digital Goods',
    },
    {
      label: 'Monitor/Projectors',
      value: 'Monitor/Projectors',
    },
    {
      label: 'TVs & Accessories',
      value: 'TVs & Accessories',
    },
  ],
  [
    {
      label: 'Make-up',
      value: 'Make-up',
    },
    {
      label: 'Skincare',
      value: 'Skincare',
    },
    {
      label: 'Hair Care',
      value: 'Hair Care',
    },
    {
      label: 'Bath & Body',
      value: 'Bath & Body',
    },
    {
      label: 'Personal Care',
      value: 'Personal Care',
    },
    {
      label: "Men's Care",
      value: "Men's Care",
    },
    {
      label: 'Fragrances',
      value: 'Fragrances',
    },
    {
      label: 'Beauty Tools',
      value: 'Beauty Tools',
    },
    {
      label: 'Food Supplements',
      value: 'Food Supplements',
    },
    {
      label: 'Medical Supplies',
      value: 'Medical Supplies',
    },
    {
      label: 'Sexual Wellness',
      value: 'Sexual Wellness',
    },
    {
      label: 'Adult Diapers & Incontinence',
      value: 'Adult Diapers & Incontinence',
    },
  ],
  [
    {
      label: "Women's Accessories",
      value: "Women's Accessories",
    },
    {
      label: "Women's Bags",
      value: "Women's Bags",
    },
    {
      label: "Women's Watches",
      value: "Women's Watches",
    },
    {
      label: "Women's Jewelry",
      value: "Women's Jewelry",
    },
    {
      label: "Men's Accessories",
      value: "Men's Accessories",
    },
    {
      label: "Men's Bags",
      value: "Men's Bags",
    },
    {
      label: "Men's Watches",
      value: "Men's Watches",
    },
    {
      label: "Men's Jewelry",
      value: "Men's Jewelry",
    },
    {
      label: "Kid's Accessories",
      value: "Kid's Accessories",
    },
    {
      label: "Kid's Bags",
      value: "Kid's Bags",
    },
    {
      label: "Kid's Watches",
      value: "Kid's Watches",
    },
    {
      label: "Kid's Jewelry",
      value: "Kid's Jewelry",
    },
  ],
  [
    {
      label: "Women's Clothing",
      value: "Women's Clothing",
    },
    {
      label: "Women's Sports Clothing",
      value: "Women's Sports Clothing",
    },
    {
      label: "Women's Shoes",
      value: "Women's Shoes",
    },
    {
      label: "Women's Sports Shoes",
      value: "Women's Sports Shoes",
    },
    {
      label: "Lingerie, Sleep & Lounge",
      value: "Lingerie, Sleep & Lounge",
    },
    {
      label: "Men's Clothing",
      value: "men's Clothing",
    },
    {
      label: "Men's Shoes",
      value: "Men's Shoes",
    },
    {
      label: "Men's Sports Clothing",
      value: "men's Sports Clothing",
    },
    {
      label: "Men's Sports Shoes",
      value: "Men's Sports Shoes",
    },
    {
      label: "Underwear",
      value: "Underwear",
    },
    {
      label: "Girl's Clothing",
      value: "Girl's Clothing",
    },
    {
      label: "Girl's Shoes",
      value: "Girl's Shoes",
    },
    {
      label: "Girl's Underwear",
      value: "Girl's Underwear",
    },
    {
      label: "Girl's Sports Clothing",
      value: "Girl's Sports Clothing",
    },
    {
      label: "Girl's Sports Shoes",
      value: "Girl's Sports Shoes",
    },
    {
      label: "Boy's Clothing",
      value: "Boy's Clothing",
    },
    {
      label: "Boy's Shoes",
      value: "Boy's Shoes",
    },
    {
      label: "Boy's Underwear",
      value: "Boy's Underwear",
    },
    {
      label: "Boy's Sports Clothing",
      value: "Boy's Sports Clothing",
    },
    {
      label: "Boy's Sports Shoes",
      value: "Boy's Sports Shoes",
    },
  ],
  [
    {
      label: "E-Books",
      value: "E-Books",
    },
    {
      label: "Books and Magazine",
      value: "Books and Magazine",
    },
    {
      label: "Writing Materials",
      value: "Writing Materials",
    },
    {
      label: "Arts and Crafts",
      value: "Arts and Crafts",
    },
    {
      label: "School Supplies",
      value: "School Supplies",
    },
    {
      label: "Packaging",
      value: "Packaging",
    },
    {
      label: "Musical Instruments",
      value: "Musical Instruments",
    },
  ],
  [
    {
      label: "Training Equipment",
      value: "Training Equipment",
    },
    {
      label: "Fitness Stands/Bikes",
      value: "Fitness Stands/Bikes",
    },
    {
      label: "Gym Machines",
      value: "Gym Machines",
    },
    {
      label: "Fitness Accessories",
      value: "Fitness Accessories",
    },
    {
      label: "Sports Wear",
      value: "Sports Wear",
    },
  ],
  [
    {
      label: "Bath",
      value: "Bath",
    },
    {
      label: "Bedding",
      value: "Bedding",
    },
    {
      label: "Furniture",
      value: "Furniture",
    },
    {
      label: "Storage & Organisation",
      value: "Storage & Organisation",
    },
    {
      label: "Laundry & Cleaning Equipment",
      value: "Laundry & Cleaning Equipment",
    },
    {
      label: "Kitchen & Dining",
      value: "Kitchen & Dining",
    },
    {
      label: "Lighting",
      value: "Lighting",
    },
    {
      label: "Home Decor",
      value: "Home Decor",
    },
    {
      label: "Outdoor Garden",
      value: "Outdoor Garden",
    },
    {
      label: "Tools & Home Improvement",
      value: "Tools & Home Improvement",
    },

  ],
  [
    {
      label: "Pet Food",
      value: "Pet Food",
    },
    {
      label: "Grooming",
      value: "Grooming",
    },
    {
      label: "Aquarium Needs",
      value: "Aquarium Needs",
    },
    {
      label: "Litter & Toilet",
      value: "Litter & Toilet",
    },
    {
      label: "Bowls & Feeders",
      value: "Bowls & Feeders",
    },
    {
      label: "Leashes, Collars & Muzzles",
      value: "Leashes, Collars & Muzzles",
    },
    {
      label: "Beds, Mats & Houses",
      value: "Beds, Mats & Houses",
    },
    {
      label: "Cages, Crates & Doors",
      value: "Cages, Crates & Doors",
    },
    {
      label: "Toys",
      value: "Toys",
    },
    {
      label: "Pet Healthcare",
      value: "Pet Healthcare",
    },
  ],
  [
    {
      label: "Non-Alcoholic Drinks",
      value: "Non-Alcoholic Drinks",
    },
    {
      label: "Alcoholic Drinks",
      value: "Alcoholic Drinks",
    },
    {
      label: "Food Staples & Cooking Essentials",
      value: "Food Staples & Cooking Essentials",
    },
    {
      label: "Chocolate, Snacks & Sweets",
      value: "Chocolate, Snacks & Sweets",
    },
    {
      label: "Breakfast Cereals & Spreads",
      value: "Breakfast Cereals & Spreads",
    },
    {
      label: "Frozen",
      value: "Frozen",
    },
    {
      label: "Bakery",
      value: "Bakery",
    },
    {
      label: "Fruit & Vegetables",
      value: "Fruit & Vegetables",
    },
    {
      label: "Meat & Seafood",
      value: "Meat & Seafood",
    },
  ],
  [
    {
      label: "Cars & Car Care",
      value: "Cars & Car Care",
    },
    {
      label: "Electronics & Accessories",
      value: "Electronics & Accessories",
    },
    {
      label: "Oils & Fluids",
      value: "Oils & Fluids",
    },
    {
      label: "Tires & Wheels",
      value: "Tires & Wheels",
    },
    {
      label: "Tools & Equipment",
      value: "Tools & Equipment",
    },
    {
      label: "Trucks & Heavy Duty Vehicles",
      value: "Trucks & Heavy Duty Vehicles",
    },
    {
      label: "Motorcycles & Parts",
      value: "Motorcycles & Parts",
    },
  ],
  [
    {
      label: '',
      value: '',
    },
  ],
];

export const Commissions = ["10", "15", "20", "25"]

export const BankMethods = [
  
  {
    id: '1',
    bank: 'BPI/BPI Family Savings Bank',
    accName: 'Charles Bon L. Chua',
    accNum: '00008428946634',
    defaultBank: true,
  },
];

export const HistoryTransactions = [

  {
    id: '1',
    date: 'May 31, 2022',
    amount: 2300,
    referenceNum: 'XYZ00034',
    bank: 'BPI/BPI Family Savings Bank'
  },
];

export const ListOfRequests = [
  {
    id: '1',
    name: 'Samantha Day',
    refNum: 'DAS23367BHJH',
    company: 'Company A',
    amount: 1495,
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
    status: 'PENDING',
  },
  {
    id: '2',
    name: 'John Smith',
    refNum: 'DAS23367BHJH',
    company: 'Company B',
    amount: 795,
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
    status: 'PENDING',
  },
  {
    id: '3',
    name: 'James Bond',
    refNum: 'DAS23367BHJH',
    company: 'Company C',
    amount: 795,
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
    status: 'COMPLETED',
  },
  {
    id: '4',
    name: 'Frances Mendoza',
    refNum: 'DAS23367BHJH',
    company: 'Company D',
    amount: 2616,
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
    status: 'PENDING',
  },
]

export const ListOfAffiliates = [
  
  {
    id: '1',
    name: 'Samantha Day',
    email: 'iamsamantha@mail.com',
    company: 'Company A',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555'
  },
  {
    id: '2',
    name: 'Carlos Bond',
    email: 'iamcarlos@mail.com',
    company: 'Company B',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '3',
    name: 'John Smith',
    email: 'iamjohn@mail.com',
    company: 'Company C ',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '4',
    name: 'James Bond',
    email: 'iambond@mail.com',
    company: 'Company D',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '5',
    name: 'Eren Jaeger',
    email: 'iameren@mail.com',
    company: 'Company E',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555'
  },
  {
    id: '6',
    name: 'Mikasa Ackerman',
    email: 'iammikasa@mail.com',
    company: 'Company F',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '7',
    name: 'Levi Ackerman',
    email: 'iamsmall@mail.com',
    company: 'Company G',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '8',
    name: 'Reiner Braun',
    email: 'iamreiner@mail.com',
    company: 'Company H',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '9',
    name: 'Samantha Day',
    email: 'iamsamantha@mail.com',
    company: 'Company I',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555'
  },
  {
    id: '10',
    name: 'Carlos Bond',
    email: 'iamcarlos@mail.com',
    company: 'Company J',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '11',
    name: 'John Smith',
    email: 'iamjohn@mail.com',
    company: 'Company k ',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '12',
    name: 'James Bond',
    email: 'iambond@mail.com',
    company: 'Company l',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '13',
    name: 'Eren Jaeger',
    email: 'iameren@mail.com',
    company: 'Company m',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555'
  },
  {
    id: '14',
    name: 'Mikasa Ackerman',
    email: 'iammikasa@mail.com',
    company: 'Company n',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '15',
    name: 'Levi Ackerman',
    email: 'iamsmall@mail.com',
    company: 'Company o',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
  {
    id: '16',
    name: 'Reiner Braun',
    email: 'iamreiner@mail.com',
    company: 'Company p',
    contact: '09123456789',
    date: 'Jun 15, 2022',
    bank: 'PH_BDO',
    bankNum: '80020425425555',
  },
];

export const PaymentHistory = [
  
  {
    id: '60',
    paymentType: 'Disbursement',
    paymentDate: 'Jun 8, 2022',
    referenceNum: '202206XYZ',
    amount: 3645.75,
    file: 'report.pdf'
  },
]

export const ChartsSingle = ['Clicks', 'Conversions', 'Revenues', 'Commissions'];

export const ChartsCompare = ['Clicks - Conversions', 'Revenues - Commissions'];

export const ChartsAll = ['Clicks', 'Conversions', 'Revenues', 'Commissions', 'Clicks-Conversions', 'Revenues-Commissions'];

export const ChartsAffiliate = ['Clicks', 'Conversions', 'Earnings'];

export const ChartDefault = [
  {
    date_created:  "1/01/2022",
    clicked: 0
  },
];

export const FilterReport = [
  {
    label: 'Last 7 Days',
    value: '7',
  },
  {
    label: 'Last 30 Days',
    value: '30',
  },
  {
    label: 'Last 3 Months',
    value: '90',
  },
];