import React, {FC} from 'react';
import { batch, useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { Div, Text, FlexRow, Button, ToastV4, ToastInputDouble } from '@core';
import { Days, MonthsFull } from '@constants';
import { updateAsset, deleteAsset } from '@services';
import { useWindowSize } from '@hooks';

interface DataProps {
	affiliateName: string;
	title: string;
	content: string;
	date: string;
	key: number;
	status: string;
	id: number;
	onRefresh: any;
	onToast: any;
}

export const AssetData2: FC<DataProps> = ({
	affiliateName,
	title,
	content,
	date,
	key,
	status,
	id,
	onRefresh,
	onToast,
}) => {

	const dimension = useWindowSize();
	const user = useSelector(selectUser);
	const newDate: any = new Date(date);

	const rejectAsset = 'Denied';
	const approveAsset = 'Approved';

	const [editTitle, setTitle] = React.useState(title);
	const [editContent, setContent] = React.useState(content);

	const [loading, setLoading] = React.useState(false);
	const [loadingA, setLoadingA] = React.useState(false);
	const [loadingR, setLoadingR] = React.useState(false);
	const [loadingS, setLoadingS] = React.useState(false);
	const [isViewOpen, setIsViewOpen] = React.useState(false);
	const [isEditOpen, setIsEditOpen] = React.useState(false);

	const [isToastOpen2, setToastOpen2] = React.useState(false);
	const [toastKind2] = React.useState('info');
	const [toastTitle2] = React.useState('Update');
	const [toastDesc2] = React.useState('Edit requested marketing asset.');

	const [isToastOpen3, setToastOpen3] = React.useState(false);
	const [isToastOpen4, setToastOpen4] = React.useState(false);
	const [isToastOpen5, setToastOpen5] = React.useState(false);

	const [lines, setLines] = React.useState(0);

	React.useEffect(() => {
		batch(async() => {
			const numberOfLineBreaks: number = await (editContent.match(/\n/g)||[]).length;
			setLines(numberOfLineBreaks)
		})
	}, [isViewOpen])

	const Reject = async () => {
		setToastOpen4(true)
	}

	const Approve = async () => {
		setToastOpen3(true)
	}

	const Remove = async () => {
		setToastOpen5(true)
	}

	const onEdit = async () => {
		if(dimension.width > 1000){
			setIsEditOpen(false)
			setToastOpen2(true)
		}else{
			setIsEditOpen(true)
			setToastOpen2(false)
		}
	}

	const onReject = async () => {
		setToastOpen4(false)
		setLoadingR(true)
		batch(async() => {
			const reject = await updateAsset({
				id,
				title: editTitle,
				content: editContent,
				hashtag: editTitle,
				status: rejectAsset,
			})
			setLoadingR(false)
			if(reject.data){
				setIsViewOpen(false);
				setIsEditOpen(false);
				onToast('Reject');
				onRefresh();
			}else{
				onToast('Error', reject?.data?.message);
			}
		})
	}

	const onApprove = async () => {
		setToastOpen3(false)
		setLoadingA(true)
		batch(async() => {
			const approve = await updateAsset({
				id,
				title: editTitle,
				content: editContent,
				hashtag: editTitle,
				status: approveAsset,
			})
			setLoadingA(false)
			if(approve.data){
				setIsViewOpen(false);
				setIsEditOpen(false);
				onToast('Approve');
				onRefresh();
			}else{
				onToast('Error', approve?.data?.message);
			}
		})
	}

	const onRemove = async () => {
		setToastOpen5(false)
		setLoading(true)
		batch(async() => {
			const remove = await deleteAsset(id);
			setLoading(false)
			if(remove.data){
				setIsViewOpen(false);
				setIsEditOpen(false);
				onToast('Remove');
				onRefresh();
			}else{
				onToast('Error', remove?.data?.message);
			}
		})
	}

	const onHide = () => {
		setIsViewOpen(false);
	}

	const onClickSubmit = () => {
		batch(async() => {
			const onUpdate = await updateAsset({
				id,
				title: editTitle,
				content: editContent,
				hashtag: editTitle,
			})
			if(onUpdate.data){
				setToastOpen2(false)
				setIsViewOpen(false);
				setIsEditOpen(false);
				onToast('Submit');
				onRefresh();
			}else{
				onToast('Error', onUpdate?.data?.message);
			}
		})
	}

	const onSubmit = (e: any) => {
		e.preventDefault()
		setLoadingS(true)
		batch(async() => {
			const onUpdate = await updateAsset({
				id,
				title: editTitle,
				content: editContent,
				hashtag: editTitle,
			})
			setLoadingS(false);
			if(onUpdate.data){
				setToastOpen2(false)
				setIsViewOpen(false);
				setIsEditOpen(false);
				onToast('onSubmit');
				onRefresh();
			}else{
				onToast('Error', onUpdate?.data?.message);
			}
		})
	}

return(
	<>
	<ToastV4
		  btnText='Cancel'		
	      description={`Approve the Marketing Asset requested by ${affiliateName}?`}
	      isOpen={isToastOpen3}
	      link=''
	      onClick={onApprove}
	      onClick2={() => setToastOpen3(false)}
	      onClose={() => setToastOpen3(false)}
	      submitBtnText='Approve'
	      title='ACTION'
	      toastKind='info'
	/>
	<ToastV4
		  btnText='Cancel'		
	      description={`Reject the Marketing Asset requested by ${affiliateName}?`}
	      isOpen={isToastOpen4}
	      link=''
	      onClick={onReject}
	      onClick2={() => setToastOpen4(false)}
	      onClose={() => setToastOpen4(false)}
	      submitBtnText='Reject'
	      title='ACTION'
	      toastKind='info'
	/>
	<ToastV4
		  btnText='Cancel'		
	      description='Completely remove marketing asset?'
	      isOpen={isToastOpen5}
	      link=''
	      onClick={onRemove}
	      onClick2={() => setToastOpen5(false)}
	      onClose={() => setToastOpen5(false)}
	      submitBtnText='Remove'
	      title='ACTION'
	      toastKind='info'
	/>
	<ToastInputDouble
		  clear={() => setContent('')}
	      description={toastDesc2}
	      emoji={(e:any) => setTitle(editTitle.concat(e.emoji))}
	      emoji2={(e:any) => setContent(editContent.concat(e.emoji))}
	      isOpen={isToastOpen2}
	      loading={loadingS}
	      onChange={(e:any) => setTitle(e.target.value)}
	      onChange2={(e:any) => setContent(e.target.value)}
	      onClickSubmit={onClickSubmit}
	      onClose={() => setToastOpen2(false)}
	      onSubmit={onSubmit}
	      placeholder='Title'
	      placeholder2='Content'
	      submitBtnText='UPDATE'
	      title={toastTitle2}
	      toastKind={toastKind2}
	      type='text'
	      value={editTitle}
	      value2={editContent}
	/>
	<div
		className={`mb-6 px-2 py-4 bg-grey-500 ${status === 'Denied' && user?.user_info.role === 'affiliate'? 'cursor-not-allowed' : ''}`}
		key={key}
	>
		
		{
			isViewOpen || isEditOpen ?
			<Div>
				{
					isEditOpen?
					<>
					<form onSubmit={(e:any) => onSubmit(e)}>
						<input
							className='w-full my-2 rounded-lg'
							onChange={(e:any) => setTitle(e.target.value)}
							placeholder='Enter title here'
							required
							type='text'
							value={editTitle}
						/>
						<textarea
						  className='scrollbar-hide rounded-lg block w-full my-2 resize-none'
						  maxLength={1000}
						  onChange={(e:any) => setContent(e.target.value)}
						  placeholder='Enter content here'
						  required
						  rows={editContent.length > 100? 4 : lines+1}
						  value={editContent}
						/>
						<FlexRow className='justify-end'>
							{
							  editContent !== ''?
							  <Text className={`${editContent.length > 1000 ? 'text-red-400' : 'text-green-100'}`}>
							    {editContent?.length}/1000
							  </Text>
							  :
							  <Text className='text-secondary-200'>
							    0/1000
							  </Text>
							}
						</FlexRow>

						<FlexRow className='justify-end mt-4'>
							<Button className='phone:bg-white phone:border phone:border-secondary-200 phone:text-primary-100 phone:px-4 phone:py-2' onClick={() => setIsEditOpen(false)}>
								Cancel
							</Button>
							<Button className='phone:py-2 phone:px-4 phone:mr-0' isLoading={loadingS} type='submit'>
								Update
							</Button>
						</FlexRow>
					
					</form>
					</>
					:
					<>
					<Text className='text-2xl text-secondary-200 my-2 flex flex-row items-center'>
						{editTitle}
					</Text>
					<textarea
					  className='font-poppins text-secondary-200 phone:text-sm tablet:text-base laptop:text-lg focus:outline-none focus:border-none outline-none border-none resize-none bg-grey-500 rounded-lg block w-full px-0'
					  disabled
					  rows={editContent.length > 100? 4 : lines+1}
					  value={editContent}
					/>
					<div className='cursor-pointer text-secondary-200 mt-2' onClick={() => setIsViewOpen(false)}>[Hide]</div>
					</>
				}
			</Div>
			:
			<Div>
				<Text className='text-2xl text-secondary-200 my-2 flex flex-row items-center'>
					{editTitle}
				</Text>
				<Text className='text-secondary-200 laptop:text-lg'>
					{editContent.substring(0, 50)}
					<span className={`mx-2 cursor-pointer ${editContent.length > 50 || lines > 0 ? '' : 'hidden'}`} onClick={() => setIsViewOpen(true)}>[Show]</span>
				</Text>
			</Div>
		}

		<FlexRow className='my-4 items-start justify-start phone:flex-col tablet:flex-row'>
			<Text className='mr-auto text-sm text-secondary-200 tablet:mr-0'>
				<b className='mr-2'>Created on:</b> {Days[newDate.getDay()]}, {MonthsFull[newDate.getMonth()]} {new Date(date).toString().substring(8, 11)}, {new Date(date).toString().substring(11, 15)}
			</Text>
			{
				user?.user_info?.role === 'affiliate'?
				<Text className='text-sm text-secondary-200 tablet:ml-4 mr-auto'>
					<b className='mr-2'>Status:</b> {status}
				</Text>
				:
				<Text className='text-sm text-secondary-200 tablet:ml-4 mr-auto'>
					<b className='mr-2'>Created by:</b> {affiliateName}
				</Text>
			}
		</FlexRow>

		<FlexRow className={`w-full justify-between ${status !== 'Created'? '' : 'hidden'}`}>
			<FlexRow className='phone:flex-col tablet:flex-row w-full'>
				{
					user?.user_info?.role !== 'affiliate'?
					<>
					<Button className={`phone:py-2 phone:px-0 phone:w-full tablet:w-32 flex items-center justify-center mt-2 ${status !== 'Approved'? '' : 'phone:bg-secondary-200 hidden'}`} isLoading={loadingA} onClick={Approve}>
						Approve
					</Button>
					<Button className={`phone:py-2 phone:px-0 phone:w-full tablet:w-32 flex items-center justify-center mt-2 ${status === 'Denied' ? 'hidden' : `${status !== 'Approved'? 'phone:bg-red-400' : 'hidden'}`}`} isLoading={loadingR} onClick={Reject}>
						Reject
					</Button>
					<Button className={`phone:py-2 phone:px-0 phone:w-full tablet:w-32 flex items-center justify-center mt-2 ${status !== 'Pending'? `${status === 'Approved'? '' : 'hidden'}` : 'bg-white border border-secondary-200 phone:text-secondary-200 '}`} onClick={onEdit}>
						Edit
					</Button>
					<Button className={`phone:py-2 phone:px-0 phone:w-full tablet:w-32 bg-white border border-secondary-200 phone:text-secondary-200 flex items-center justify-center mt-2 ${status === 'Denied' || status === 'Approved' ? '' : 'hidden'}`} onClick={Remove}>
						Remove
					</Button>
					</>
					:
					<>
					<Button className={`phone:py-2 phone:px-0 phone:w-full tablet:w-32 flex items-center justify-center mt-2 ${status !== 'Pending'? 'hidden' : ''}`} onClick={onEdit}>
						Edit
					</Button>
					<Button className={`phone:py-2 phone:px-0 phone:w-full tablet:w-32 bg-white border border-secondary-200 phone:text-secondary-200 flex items-center justify-center mt-2 ${status === 'Denied' || status === 'Approved' ? 'hidden' : ''}`} onClick={Remove}>
						Remove
					</Button>
					</>
				}
			</FlexRow>

			<span className={`text-sm text-primary-100 font-semibold hover:underline mr-4 cursor-pointer ${isViewOpen? 'hidden' : 'hidden'}`} onClick={onHide}>Hide</span>
		</FlexRow>

		<FlexRow className={`w-full justify-between ${status === 'Created' && user?.user_info?.role !== 'affiliate' ? '' : 'hidden'}`}>
			<FlexRow className='phone:flex-col tablet:flex-row w-full'>
				<Button className='phone:py-2 phone:px-0 phone:w-full mt-2 tablet:w-32 flex items-center justify-center' onClick={onEdit}>
					Edit
				</Button>
				<Button className='phone:py-2 phone:px-0 phone:w-full mt-2 tablet:w-32 bg-white border border-secondary-200 phone:text-secondary-200 flex items-center justify-center' isLoading={loading} onClick={Remove}>
					Remove
				</Button>
			</FlexRow>

			<span className={`text-sm text-primary-100 font-semibold hover:underline mr-4 cursor-pointer ${isViewOpen? 'hidden' : 'hidden'}`} onClick={onHide}>Hide</span>
		</FlexRow>

	</div>
	</>
)
}