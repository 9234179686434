import React, { FC } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import useSound from 'use-sound';
import { Wait } from '@pages';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import {
  Icons,
  H2,
  Info,
  Div,
  Flex,
  FlexRow,
  ButtonIcon,
  Input,
  PasswordInput,
  Button,
  Text,
  Toast,
  SoundMp3,
} from '@core';
import { useCookie } from '@hooks';
import { API } from '@config';

export const Login: FC<any> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [cookie, updateCookie] = useCookie('token', '0');

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const [playSound1] = useSound(SoundMp3.Sound1);

  // ----- Session Timeout -----
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('session');
  const wixInstance = query.get('instance');

  React.useEffect(() => {
    batch(async () => {
      const data: any = await dispatch(usersActions.profileAdmin());
      if (data.payload === null) {
        const userData: any = await dispatch(usersActions.profile());
        if (
          userData?.payload?.onboarding === 'About You' &&
          window.location.pathname !== `/onboard/aboutyou` &&
          window.location.pathname !== `/affiliate/onboard/aboutyou` &&
          window.location.pathname !== `/affiliate/onboard/signup` &&
          userData?.payload?.merchant_link !== null
        ) {
          window.location.href = `/affiliate/onboard/aboutyou?id=${data.user_id}&merchant=${userData?.payload?.merchant_link}`;
        } else if (
          userData?.payload?.onboarding === 'About You' &&
          window.location.pathname !== `/onboard/aboutyou` &&
          window.location.pathname !== `/affiliate/onboard/aboutyou` &&
          window.location.pathname !== `/affiliate/onboard/signup` &&
          userData?.payload?.merchant_link === null
        ) {
          window.location.href = `/onboard/aboutyou?id=${userData?.payload?.user_id}`;
        } else if (
          userData?.payload?.onboarding === 'Setup' &&
          window.location.pathname !== `/onboard/setup`
        ) {
          window.location.href = `/onboard/setup?id=${userData?.payload?.user_id}&role=merchant`;
        }
      }
      if (user?.user_info?.role === 'merchant') {
        navigate(`/merchant/dashboard`);
      } else if (user?.user_info?.role === 'affiliate') {
        navigate(`/affiliate/dashboard`);
      } else if (user?.user_info?.role === 'Manager') {
        navigate(`/merchant/admin_dashboard/manager`);
      } else if (user?.user_info?.role === 'Editor') {
        navigate(`/merchant/admin_dashboard/editor`);
      } else if (user?.user_info?.role === 'Super-Admin') {
        navigate(`/admin/dashboard`);
      }
    });
  }, [user, cookie]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (email === '') {
      setToastKind('error');
      setToastTitle('Email is Required');
      setToastDesc('Please enter your email address.');
      setToastOpen(true);
    } else if (password === '') {
      setToastKind('error');
      setToastTitle('Password is Required');
      setToastDesc('Please enter your password.');
      setToastOpen(true);
    } else if (password !== '' && email !== '') {
      batch(async () => {
        let initialBody: any = {
          email,
          password,
        };
        if (wixInstance) {
          initialBody = {
            ...initialBody,
            wixInstance,
          };
        }
        setLoading(true);
        fetch(`${API}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(initialBody),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === 1) {
              playSound1();
              setToastTitle('Logged In');
              setToastDesc('Redirecting to dashboard.');
              setToastKind('success');
              setToastOpen(true);
              setLoading(false);
              batch(async () => {
                if (data?.user_info?.role === 'merchant' || data?.user_info?.role === 'affiliate') {
                  updateCookie(`${data?.access_token}`, 0.5);
                } else if (data?.user_info?.role === 'Super-Admin') {
                  updateCookie(`${data?.access_token}`, 0.5);
                } else {
                  updateCookie(`${data?.access_token}`, 0.5);
                }

                await dispatch(usersActions.profile());
                setTimeout(() => {
                  setToastOpen(false);
                  if (
                    data?.merchant_data?.company.length !== 0 &&
                    data?.user_info?.role === 'merchant'
                  ) {
                    navigate(`/merchant/dashboard`);
                  } else if (data?.user_info?.role === 'affiliate') {
                    navigate(`/affiliate/dashboard`);
                  } else if (data?.user_info?.role === 'Super-Admin') {
                    navigate(`/admin/dashboard`);
                  }
                }, 2000);
              });
            } else {
              setToastTitle('ERROR');
              setToastDesc(data?.message || 'Please check your credentials.');
              setToastKind('error');
              setToastOpen(true);
              setLoading(false);
            }
          });
      });
    } else {
      setToastTitle('ERROR');
      setToastDesc('Something went wrong. Please try again.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    }
  };

  const onHome = () => {
    navigate('/');
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      {user?.user_info?.role !== '' ? (
        <Wait />
      ) : (
        <Flex className="px-20 phone:px-5 tablet:px-10 laptop:px-20 overflow-y-scroll">
          <Toast
            description={toastDesc}
            isOpen={isToastOpen}
            onClose={() => setToastOpen(false)}
            title={toastTitle}
            toastKind={toastKind}
          />
          <Div className="mr-auto flex flex-row items-center py-8">
            <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
            <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
              Xffiliate
            </Text>
          </Div>
          <hr className="text-secondary-300" />
          <Div className="mt-10 flex flex-col items-center justify-center h-full">
            <H2 className="text-primary-100">Login</H2>
            <Info className="text-secondary-100 mt-2">
              Don’t have an account?{' '}
              <Link className="underline text-primary-100" to="/new/signup">
                Sign Up
              </Link>
            </Info>
            <Info className="text-secondary-100 mt-8 mb-4 hidden">Log in with:</Info>

            <Div className="mb-20 phone:w-full tablet:w-6/12 laptop:w-4/12">
              <FlexRow className="justify-center w-full space-x-4 phone:space-x-1 hidden">
                <ButtonIcon className="w-full justify-center " icon="fab fa-google font-bold">
                  Google
                </ButtonIcon>
                <ButtonIcon className="w-full justify-center " icon="fab fa-facebook-square">
                  Facebook
                </ButtonIcon>
              </FlexRow>
              <FlexRow className="hidden">
                <Div className="border-t-2 border-secondary-200 w-full" />
                <Info className="text-secondary-100 font-semibold mx-4 ">OR</Info>
                <Div className="border-t-2 border-secondary-200 w-full" />
              </FlexRow>

              {actionQuery === 'timeout' ? (
                <div className="bg-orange-100 py-2 pl-2 pr-8 tablet:pl-4 tablet:py-4 rounded-lg text-white mt-4 relative">
                  <Text>
                    <i className="fas fa-exclamation text-sm text-orange-100 bg-white rounded px-2 mr-2" />
                    You have been logged out due to inactivity.
                  </Text>
                  <FlexRow className="absolute right-0 top-0 p-2 tablet:p-4 z-10 text-orange-100 hover:text-white w-full justify-end items-center cursor-pointer">
                    <button
                      className="border border-orange-100 w-6 h-6 flex items-center justify-center hover:border-white rounded-full"
                      onClick={onHome}
                      title="Close"
                    >
                      <i className="fas fa-times text-sm" />
                    </button>
                  </FlexRow>
                </div>
              ) : (
                <></>
              )}

              <form className="mt-4" onSubmit={(e) => onSubmit(e)}>
                <Input
                  label="Email address "
                  name="email"
                  onChange={setEmail}
                  pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                />
                <PasswordInput
                  label="Password"
                  name="Password"
                  onChange={setPassword}
                  placeholder="Enter your password"
                  value={password}
                />

                <Text className="text-base text-right mb-3">
                  <Link className="underline text-primary-100" to="/reset/password">
                    Forgot your password?
                  </Link>
                </Text>

                <Button className="w-full py-5 justify-center" isLoading={loading} type="submit">
                  Log In
                </Button>
              </form>
            </Div>
          </Div>
        </Flex>
      )}
    </>
  );
};
