import React, {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import { Text, FlexRow, Div } from '@core';
import { formatCount } from '@src/helpers/formatNumber';

interface DataProps {
	image: string;
	name: string;
	commission: string;
	categoryCommission: string;
	conversions: number;
	earnings: number;
	clicks: number;
	id: number;
	index: number;
}

export const TopProductData: FC<DataProps> = ({
	image,
	name,
	commission,
	categoryCommission,
	conversions,
	earnings,
	clicks,
	index,
	id,
}) => {

	const [iconLoaded, setIconLoaded] = React.useState(false);

return(
<div className='w-full bg-grey-500 my-2 p-2 flex flex-row items-start tabletWide:items-center justify-between' key={id}>
	
	<Div className='flex flex-row mr-2 w-7/12'>
		<img
		  alt={name}
		  className='w-20 h-20 object-contain rounded-xl phone:ml-0 surfaceDuo:ml-2 phone:mr-2 surfaceDuo:mr-4 tabletWide:mr-10 border-4 border-grey-400 phone:hidden surfaceDuo:block'
		  onError={() => setIconLoaded(true)}
		  src={
		    iconLoaded
		      ? 'https://i.imgur.com/CGDGnf6.png'
		      : image
		  } 
		/>

		<FlexRow className='flex-col w-auto justify-around'>
			<Text
			  className="phone:text-sm surfaceDuo:text-base text-primary-100 font-light w-full text-left font-light"
			>
			  # {index+1}
			</Text>
			<Text
			  className="phone:text-sm surfaceDuo:text-base text-primary-100 font-semibold w-full text-left"
			>
			  {name}
			</Text>
			<Text
			  className="phone:text-sm surfaceDuo:text-base text-primary-100 font-light w-full text-left"
			>
			  Commission: {categoryCommission === '0' ? commission : categoryCommission}%
			</Text>
		</FlexRow>
	</Div>

	<Div className="col-span-3 gap-x-4 gap-y-2 text-primary-100 font-medium text-sm flex phone:flex-col tablet:flex-row phone:hidden">
	  <div data-for="revenue" data-tip>
	    <FlexRow className="bg-light-200 rounded-full p-2">
	      <i className="fas fa-hand-holding-usd text-orange-400 pr-2" />
	      <Text>&#8369; {earnings.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
	    </FlexRow>
	  </div>
	  <ReactTooltip
	    backgroundColor="#FFFFFF"
	    delayHide={150}
	    effect="solid"
	    id="revenue"
	    textColor="#073763"
	  >
	    <Text>Revenue</Text>
	  </ReactTooltip>
	  <div data-for="clicks" data-tip>
	    <FlexRow className="bg-light-200 rounded-full p-2">
	      <i className="fas fa-mouse-pointer text-orange-400 pr-2" />
	      <Text>{formatCount(clicks)}</Text>
	    </FlexRow>
	  </div>
	  <ReactTooltip
	    backgroundColor="#FFFFFF"
	    delayHide={150}
	    effect="solid"
	    id="clicks"
	    textColor="#073763"
	  >
	    <Text>Clicks</Text>
	  </ReactTooltip>
	  <div data-for="conversions" data-tip>
	    <FlexRow className="bg-light-200 rounded-full p-2">
	      <i className="fas fa-repeat text-orange-400 pr-2" />
	      <Text>{formatCount(conversions)}</Text>
	    </FlexRow>
	  </div>
	  <ReactTooltip
	    backgroundColor="#FFFFFF"
	    delayHide={150}
	    effect="solid"
	    id="conversions"
	    textColor="#073763"
	  >
	    <Text>Conversions</Text>
	  </ReactTooltip>
	</Div>

	<FlexRow className='phone:flex-col laptop:flex-row mr-2 laptop:justify-around laptop:w-5/12'>
		<FlexRow className="laptop:flex-col laptop:ml-2 laptop:mr-2 mr-auto">
		   <i className="fas fa-mouse-pointer text-orange-400 pr-2 mr-1" />
		  <Text className="font-poppins font-semibold text-primary-100 mr-2 hidden tablet:block">
		    Clicks
		  </Text>
		  <Text className='text-sm text-primary-100 surfaceDuo:text-base'>{clicks}</Text>
		</FlexRow>
		<FlexRow className="laptop:flex-col laptop:ml-2 laptop:mr-2 mr-auto">
		  <i className="fas fa-repeat text-orange-400 pr-2" />
		  <Text className="font-poppins font-semibold text-primary-100 mr-2 hidden tablet:block">
		    Conversions
		  </Text>
		  <Text className='text-sm text-primary-100 surfaceDuo:text-base'>{conversions}</Text>
		</FlexRow>
		<FlexRow className="laptop:flex-col laptop:ml-2 laptop:mr-2 mr-auto">
		  <i className="fas fa-hand-holding-usd text-orange-400 pr-2" />
		  <Text className="font-poppins font-semibold text-primary-100 mr-2 hidden tablet:block">
		    Earnings
		  </Text>
		  <Text className='text-sm text-primary-100 surfaceDuo:text-base laptop:hidden'>
		  	&#8369; {earnings.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		  </Text>
		  <Text className='text-sm text-primary-100 phone:hidden surfaceDuo:text-base laptop:block'>
		  	&#8369; {earnings.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		  </Text>
		</FlexRow>
	</FlexRow>
	
</div>
)
};