import masterCard from '@assets/bankicons/masterCard.png';
import PH_AUB from '@assets/bankicons/PH_AUB.png';
import PH_BDO from '@assets/bankicons/PH_BDO.png';
import PH_BOC from '@assets/bankicons/PH_BOC.gif';
import PH_BPI from '@assets/bankicons/PH_BPI.png';
import PH_BPIDB from '@assets/bankicons/PH_BPIDB.png';
import PH_CBC from '@assets/bankicons/PH_CBC.png';
import PH_CBS from '@assets/bankicons/PH_CBS.jpg';
import PH_CEBRUR from '@assets/bankicons/PH_CEBRUR.png';
import PH_CIMB from '@assets/bankicons/PH_CIMB.png';
import PH_CITI from '@assets/bankicons/PH_CITI.png';
import PH_COINS from '@assets/bankicons/PH_COINS.jpg';
import PH_CTBC from '@assets/bankicons/PH_CTBC.png';
import PH_DBP from '@assets/bankicons/PH_DBP.png';
import PH_EQB from '@assets/bankicons/PH_EQB.png';
import PH_EWB from '@assets/bankicons/PH_EWB.png';
import PH_EWR from '@assets/bankicons/PH_EWR.png';
import PH_GCASH from '@assets/bankicons/PH_GCASH.jpg';
import PH_GRABPAY from '@assets/bankicons/PH_GRABPAY.png';
import PH_HSBC from '@assets/bankicons/PH_HSBC.jpg';
import PH_HSBI from '@assets/bankicons/PH_HSBI.jpg';
import PH_ING from '@assets/bankicons/PH_ING.jpg';
import PH_JPM from '@assets/bankicons/PH_JPM.png';
import PH_LBP from '@assets/bankicons/PH_LBP.jpg';
import PH_MET from '@assets/bankicons/PH_MET.png';
import PH_MPI from '@assets/bankicons/PH_MPI.png';
import PH_OMNI from '@assets/bankicons/PH_OMNI.png';
import PH_ONB from '@assets/bankicons/PH_ONB.png';
import PH_PAYMAYA from '@assets/bankicons/PH_PAYMAYA.png';
import PH_PBB from '@assets/bankicons/PH_PBB.jpg';
import PH_PBC from '@assets/bankicons/PH_PBC.jpg';
import PH_PNB from '@assets/bankicons/PH_PNB.gif';
import PH_PSB from '@assets/bankicons/PH_PSB.png';
import PH_PTC from '@assets/bankicons/PH_PTC.gif';
import PH_PVB from '@assets/bankicons/PH_PVB.jpg';
import PH_RCBC from '@assets/bankicons/PH_RCBC.jpg';
import PH_ROB from '@assets/bankicons/PH_ROB.png';
import PH_SBA from '@assets/bankicons/PH_SBA.jpg';
import PH_SEC from '@assets/bankicons/PH_SEC.jpg';
import PH_SPY from '@assets/bankicons/PH_SPY.jpg';
import PH_UBP from '@assets/bankicons/PH_UBP.png';
import PH_UCBSB from '@assets/bankicons/PH_UCBSB.png';
import PH_UCPB from '@assets/bankicons/PH_UCPB.png';


export const BankIcons: any = {
 	masterCard, PH_AUB, PH_BDO, PH_BOC, PH_BPI, PH_BPIDB, PH_CBC,
 	PH_CBS, PH_CEBRUR, PH_CIMB, PH_CITI, PH_COINS, PH_CTBC, PH_DBP,
 	PH_EQB, PH_EWB, PH_EWR, PH_GCASH, PH_GRABPAY, PH_HSBC, PH_HSBI,
 	PH_ING, PH_JPM, PH_LBP, PH_MET, PH_MPI, PH_OMNI, PH_ONB,
 	PH_PAYMAYA, PH_PBB, PH_PBC, PH_PNB, PH_PSB, PH_PTC, PH_PVB,
 	PH_RCBC, PH_ROB, PH_SBA, PH_SEC, PH_SPY, PH_UBP, PH_UCBSB, PH_UCPB
};