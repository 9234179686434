import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface ButtonProps {
    className?: string;
    title: string;
    onClose: any;
    description: string;
    isOpen: boolean;
    isLoaded?: boolean;
    toastKind: string;
    onClick?: any;
    onClick2?: any;
    submitBtnText: string;
    btnText: string;
    onChange?: any;
    placeholder?: string;
    value?: string;
}

export const ToastRawInput: FC<ButtonProps> = ({
    title, isLoaded, description, onClose, isOpen, toastKind,
    onClick, onClick2, submitBtnText, btnText, onChange,
    placeholder, value
}) => {
    let color = 'green-100';

    let icon = 'fa-check';
    if (toastKind === 'success') {
        color = 'green-100';
        icon = 'fa-check';
    } else if (toastKind === 'error') {
        color = 'red-100';
        icon = 'fa-times';
    } else if (toastKind === 'warning') {
        color = 'yellow-100';
        icon = 'fa-exclamation';
    } else if (toastKind === 'info') {
        color = 'primary-100';
        icon = 'fa-exclamation';
    }

    return (
        <Transition appear as={Fragment} show={isOpen}>
            <Dialog
                as="div"
                className='fixed z-50 overflow-hide phone:w-full tabletWide:w-1/2 tabletWide:right-1/4 laptop:right-1/3 laptop:w-1/3 top-1/4'
                onClose={onClose}
            >
                <div className="h-full w-full text-center flex justify-end">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease duration-300 transform"
                        enterFrom="opacity-0 -translate-y-full"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease duration-300 transform"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-full"
                    >
                        <div
                            className="h-full w-full overflow-hidden text-left z-20 px-5 pt-5
      align-middle transition-all transform flex flex-col items-center"
                        >
                            <div
                                className={`flex flex-col items-center justify-between p-4 mb-4 text-gray-500 w-full
            bg-white border-2 border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins`}
                                id="toast-success"
                                role="alert"
                            >
                                <div className='w-full flex flex-row items-center phone:justify-between tablet:justify-center'>
                                    <div
                                        className={`phone:hidden mobile:inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white bg-${color} rounded-lg mr-1`}
                                    >
                                        <i className={`fas ${icon}`} />
                                    </div>
                                    <div>
                                        <div className={`ml-3 font-semibold phone:text-sm mobile:text-base text-${color}`}>
                                            {title.toUpperCase()}
                                        </div>
                                        <div className="ml-3 phone:text-xs mobile:text-sm font-normal text-primary-100 text-justify mr-2">  {description}
                                        </div>

                                    </div>
                                    <button
                                        aria-label="Close"
                                        className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                                        data-collapse-toggle="toast-success"
                                        onClick={onClose}
                                        type="button"
                                    >
                                        <i className="fas fa-times text-lg" />
                                    </button>
                                </div>

                                <input className='my-4' onChange={onChange} placeholder={placeholder} type='text' value={value} />

                                <div>
                                    {
                                        isLoaded ?
                                            <button className='focus:bg-primary-200 hover:bg-blue-200 w-24 py-2 rounded bg-primary-100 text-white mx-1'>
                                                <i className="fad fa-spinner-third animate-spin text-base" />
                                            </button>
                                            :
                                            <button className='focus:bg-primary-200 hover:bg-blue-200 w-24 py-2 rounded bg-primary-100 text-white mx-1' onClick={onClick}>{submitBtnText}</button>
                                    }
                                    <button className='focus:bg-primary-200 hover:bg-secondary-100 w-24 py-2 rounded bg-secondary-200 text-white mx-1' onClick={onClick2}>{btnText}</button>
                                </div>

                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};