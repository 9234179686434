import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../type';

const user = (state: RootState) => state.user;

const defaultData = {
  user_info: {
    id: 0,
    firstname: '',
    lastname: '',
    contact:'',
    email: '',
    role: '',
    created_at: '',
    updated_at: '',
    subscription: '',
    merchant_id: '',
  },
  merchant_info: {
    info: {
      store_name: '',
      store_link: '',
      secret_key: null,
    },
    company: [
      {
        name: '',
        description: '',
        image: '',
      }
    ],
    total_affiliate: 0,
    total_links: 0,
    total_products: 0,
    total_clicks: 0,
    total_conversions: 0,
    total_admins: 0,
  },
  merchant_data: {
    info: {
      store_name: '',
      store_link: '',
      secret_key: null,
    },
    company: [
      {
        name: '',
        description: '',
        image: '',
      }
    ],
    total_affiliate: 0,
    total_links: 0,
    total_products: 0,
    total_clicks: 0,
    total_conversions: 0,
    total_admins: 0,
  },
  affiliate_company_info: {
    company_link: '',
    company_email: '',
    company_name: '',
    contact_number: '',
  },
  affiliate_data: {
      total_conversion: 0,
      totalClicks: 0,
      totalEarned: 0,
  },
  subscription: {
    plan: '',
    days_before_expiration: 0,
    date_of_expiration: '',
  },
  email_confirmed: false,
  revenue_and_commissions: {
    total_revenue: 0,
    total_commission: 0,
    pending_revenue: 0,
    pending_commission: 0,
    paid_commission: 0,
    net_revenue: 0,
    not_paid_commission: 0,
  },
};

export const selectUserLoading = createSelector(user, (state) => state.loading);

export const selectUser = createSelector(user, (state) => state.data ?? defaultData);

export const selectAllUserAndMetadata = createSelector(
  selectUser,
  selectUserLoading,
  (data = defaultData, loading = false) => ({ data, loading }),
);

export const selectOverallGraphData = createSelector(
  user , (state) => ({
    overallGraphsLoading : state.overallGraphsLoading,
    overallGraphsData: state.overallGraphs
  })
)

export const selectOverallGraphAffData = createSelector(
  user , (state) => ({
    overallGraphsLoading : state.overallGraphsLoading,
    overallGraphsData: state.overallGraphsAff
  })
)

export const selectAffiliateOverallAnalytics = createSelector(
  user, (state) => ({
    affiliateAnalytics: state.affiliateAnalytics,
    affiliateAnalyticsLoading: state.affiliateAnalyticsLoading
  })
)

export const selectAffiliateGraphAnalytics = createSelector(
  user, (state) => ({
    affiliateGraph: state.affiliateGraph,
    affiliateGraphLoading: state.affiliateGraphLoading
  })
)

export const selectAffiliateOverviewData = createSelector(
  user, (state) => ({
    affiliateOverviewData: state.affiliateOverviewData,
    affiliateOverviewDataLoading: state.affiliateOverviewDataLoading,
  })
)