import React, {FC} from 'react';
import { Popover } from '@headlessui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Div, Text, Toast, FlexRow, ToastV4 } from '@core';
import { ResetPasswordModal } from '@components';
import { Owner, Manager, Editor } from '@constants';
import { API } from '@config';
import { getCookie } from '@hooks';

interface AffiliateProps {
	id: string;
	adminName: string;
	adminRole: string;
	adminEmail: string;
	userId: string;
}

export const AdminRoleList: FC<AffiliateProps> = ({
	id,
	adminName,
	adminRole,
	adminEmail,
	userId
}) => {

	const token = getCookie('token');
	const navigate = useNavigate();

	const { search } = useLocation();
	const query = new URLSearchParams(search)
	const actionQuery = query.get('action');

	const roleOptions: any[] = ['Manager', 'Editor'];
	const [isEdit, setEdit] = React.useState(false);
	const [roleValue, setRoleValue] = React.useState('');

	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('');
	const [toastDesc, setToastDesc] = React.useState('');

	const [isToastOpen2, setToastOpen2] = React.useState(false);
	const [toastKind2, setToastKind2] = React.useState('success');
	const [toastTitle2, setToastTitle2] = React.useState('');
	const [toastDesc2, setToastDesc2] = React.useState('');

	const [list, setList] = React.useState<any>([]);
	const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false);

	React.useEffect(() => {
	  if(actionQuery === 'admin'){
	  	navigate('/merchant/settings')
	    setResetPasswordOpen(false)
	  }
	}, [actionQuery])

	React.useEffect(() => {
		if(adminRole === 'Owner'){
			setList(Owner)
		}else if(adminRole === 'Manager'){
			setList(Manager)
		}else if(adminRole === 'Editor'){
			setList(Editor)
		}
	})

	const onUpdate = () => {
		fetch(`${API}/update-merchant-user/${id}`,{
		  method: 'PUT',
		  headers: {
		    Authorization: `Bearer ${token}`,
		    'Content-Type': 'application/json'
		  },
		  body: JSON.stringify({
		        role: roleValue
		    })
		})
		.then(res => res.json())
		.then(data => {
			if(data.status === 1){
				setEdit(false)
				setToastDesc('Admin role has been updated successfully')
				setToastOpen(true)
				setToastTitle('SUCCESS')
				setToastKind('success')
				navigate('/merchant/settings?action=updated')
			}else{
				setEdit(false)
				setToastDesc(data.message || 'Something went wrong. Please try again.')
				setToastOpen(true)
				setToastTitle('ERROR')
				setToastKind('error')
			}
		})
	}

	const Delete = async () => {
	  setToastTitle2('Action');
	  setToastDesc2(`Are you sure you want to remove admin ${adminName}?`);
	  setToastKind2('info');
	  setToastOpen2(true);
	}

	const onDelete = () => {
		fetch(`${API}/delete-merchant-user/${id}`,{
		  method: 'DELETE',
		  headers: { Authorization: `Bearer ${token}`,}
		})
		.then(res => res.json())
		.then(data => {
			if(data.status === 1){
				setEdit(false)
				setToastDesc('Admin has been removed successfully')
				setToastOpen(true)
				setToastTitle('SUCCESS')
				setToastKind('success')
				navigate('/merchant/settings?action=updated')
			}else{
				setEdit(false)
				setToastDesc(data.message || 'Something went wrong. Please try again.')
				setToastOpen(true)
				setToastTitle('ERROR')
				setToastKind('error')
			}
		})
	}

return(
	<>
	<Toast
	      description={toastDesc}
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	      title={toastTitle}
	      toastKind={toastKind}
	    />
	<ToastV4
		  btnText='Cancel'		
	      description={toastDesc2}
	      isOpen={isToastOpen2}
	      link=''
	      onClick={onDelete}
	      onClick2={() => setToastOpen2(false)}
	      onClose={() => setToastOpen2(false)}
	      submitBtnText='Remove'
	      title={toastTitle2}
	      toastKind={toastKind2}
	    />
	<ResetPasswordModal
		admin={userId}
		isOpen={resetPasswordOpen}
		name={adminName}
		onClose={() => setResetPasswordOpen(false)}
		role={adminRole}
	/>

	<div className={`py-2 px-1 rounded ${isEdit? 'bg-grey-500' : 'hover:bg-grey-500'}`}>
	<Div className='phone:flex phone:flex-row medium:items-center tablet:items-start' key={id}>

		{
			isEdit?
			<i
			  className="flex items-center justify-center fas fa-times text-primary-100 border-2 border-primary-100 rounded medium:mb-2 tablet:mt-2 tabletWide:mt-4 mr-2 cursor-pointer h-5 w-5"
			  onClick={() => setEdit(false)}
			  title='Close this field'
			/>
			:
			<>
			{
				adminRole === 'Owner'?
				<i
				  className="fas fa-edit text-primary-100 medium:mb-2 tablet:mt-2 tabletWide:mt-4 mr-2 cursor-not-allowed"
				  title='Edit this field.'
				/>
				:
				<i
				  className="fas fa-edit text-primary-100 medium:mb-2 tablet:mt-2 tabletWide:mt-4 mr-2 cursor-pointer"
				  onClick={() => setEdit(true)}
				  title='Edit this field.'
				/>
			}
			</>
		}

		<Div className='phone:flex phone:flex-col phone:w-1/3 phone:justify-between medium:hidden tablet:flex tablet:w-1/3 tabletWide:hidden'>
		    <Text
		      className=" text-base text-secondary-200 font-light w-full text-left mb-2"
		    >
		      Name:
		    </Text>
		    <Text
		      className=" text-base text-secondary-200 font-light  w-full text-left mb-2"
		    >
		      Role:
		    </Text>
		    <Text
		      className=" text-base text-secondary-200 font-light  w-full text-left"
		    >
		      Actions:
		    </Text>
		</Div>

		<Div className='phone:flex phone:flex-col phone:w-2/3 medium:flex-row medium:w-full medium:items-center tablet:flex-col tablet:items-start tablet:w-2/3 tabletWide:flex-row tabletWide:items-center tabletWide:w-full' >
		    <FlexRow
		      className="w-full medium:w-1/3 tablet:w-full tabletWide:w-2/5 text-left justify-start tabletWide:pl-8 laptop:pl-14 mb-2"
		    >
		      <div className='phone:hidden tabletWide:flex items-center justify-center w-10 h-10 rounded-full bg-grey-400 mr-2'>
		      	{adminName.substring(0, 1).toUpperCase()}
		      </div>
		      <div className='flex flex-col'>
			      <span className='block text-base text-secondary-200 font-light w-full'>{adminName}</span>
			      <span className='hidden laptop:block text-base text-secondary-200 font-light w-full'>{adminEmail}</span>
		      </div>
		    </FlexRow>
		    {
		    	isEdit?
		    	<FlexRow className='w-full phone:justify-start medium:w-1/3 medium:justify-center tablet:justify-start tablet:w-full tabletWide:w-1/5 tabletWide:justify-center mb-2'>
		    	<select
		    		className='rounded-full w-36 border-none text-primary-100 cursor-pointer bg-grey-400 py-0'
		    		name="sort"
		    		onChange={(e:any) => setRoleValue(e.target.value)}
		    		value={adminRole === 'Owner'? 'Owner' : roleValue}>
		    	    <option disabled selected value="">Choose</option>
		    	    <option disabled value="Owner">Owner</option>
		    	    {roleOptions.map((option:any) => (
		    	      <option key={option} value={option}>
		    	        {option}
		    	      </option>
		    	    ))}
		    	</select>
		    	</FlexRow>
		    	:
		    	<FlexRow
		    	  className="flex flex-row text-base text-secondary-200 font-light w-full medium:w-1/3 tablet:w-full tabletWide:w-1/5 justify-start medium:justify-center tablet:justify-start tabletWide:justify-center mb-2"
		    	>
		    	  {adminRole}
		    	  <Popover className="relative">
		    	        <Popover.Button>
		    	            <i className="fas fa-question text-xs ml-4 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
		    	        </Popover.Button>

		    	        <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
		    	          <Div className="bg-white w-64 shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-60">
		    	            <Text className='font-semibold text-sm'>
		    	              {adminRole}
		    	            </Text>
		    	            <hr className='text-primary-100 my-1'/>
		    	            <Text className='text-sm my-2'>
		    	              {adminRole} permissions and responsibilities:
		    	            </Text>

		    	            <Div className=' h-36 overflow-y-scroll'>
		    	            {
		    	            	list.map((value: any) => (
			    	              <Div className="text-grey-100 flex items-center text-sm mb-2" key={value}>
			    	                <i className="fas fa-check-circle text-primary-100 text-sm mr-5" />
			    	                {value}
			    	              </Div>
		    	            ))}
		    	            </Div>
		    	            
		    	            <Text className='mt-4 text-sm text-secondary-200'>
		    	              ** Click anywhere on screen to close. **
		    	            </Text>
		    	          </Div>
		    	        </Popover.Panel>
		    	  </Popover>
		    	</FlexRow>
		    }
		    {
		    	adminRole === 'Owner'?
		    	<div
		    	  className="hover:underline text-base text-primary-100 font-semibold phone:text-sm mobile:text-base w-full medium:w-1/3 tablet:w-full tabletWide:w-2/5 text-left medium:text-center tablet:text-left tabletWide:text-center cursor-not-allowed"
		    	>
		    	  Reset Password
		    	</div>
		    	:
		    	<div
		    	  className="hover:underline text-base text-primary-100 font-semibold phone:text-sm mobile:text-base w-full medium:w-1/3 tablet:w-full tabletWide:w-2/5 text-left medium:text-center tablet:text-left tabletWide:text-center cursor-pointer"
		    	  onClick={() => setResetPasswordOpen(true)}
		    	>
		    	  Reset Password
		    	</div>
		    }
		    
		</Div>

	</Div>
	{
		isEdit?
		<Text className='mt-2 ml-6 text-secondary-200 text-base font-light phone:flex-col tabletWide:flex-row'>
			Click to update changes.
			<FlexRow className='tabletWide:inline'>
			<span className='tabletWide:ml-3 text-blue-100 hover:underline cursor-pointer font-semibold' onClick={onUpdate}>Update</span>
			<span className='ml-3 text-blue-100 hover:underline cursor-pointer font-semibold' onClick={Delete}>Remove</span>
			<span className='ml-3 text-blue-100 hover:underline cursor-pointer font-semibold hidden' onClick={() => setEdit(false)}>Cancel</span>
			</FlexRow>
		</Text>
		:
		<></>
	}
	</div>
	<hr className="text-grey-400 mt-2 mb-4" />
	</>
)
}