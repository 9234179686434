import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

interface NEWPASS {
  old_password: string;
  new_password: string;
}

interface NEWINFO {
  firstname: string;
  lastname: string;
  contact: string;
  role: string;
  subscription: string;
}

interface NEWADMIN {
  id: any;
  firstname: string;
  lastname: string;
  contact: string;
}

interface PERMISSION {
  user_id: number;
  user_create: number;
  user_view: number;
  user_archive: number;
  user_update: number;
  transaction_view: number;
  transaction_write: number;
  disbursement_view: number;
  disbursement_write: number;
  account_view: number;
  account_write: number;
  permission_write: number;
}

interface EMAILNOTIF {
  merchant_product_update: number,
  affiliate_category_invite: number,
  merchant_category_update: number,
  merchant_category_removed: number,
  affiliate_category_update: number,
  affiliate_category_removal: number,
  affiliate_product_removal: number,
  merchant_product_removal: number,
  terms_and_privacy_update: number,
  conversion_success: number,
  merchant_new_product: number,
  store_link_update: number,
  product_link_update: number,
  merchant_subscription: number,
  commission: number,
}

export const changePassword = async (body: NEWPASS) => {
  const token = getCookie('token');
  return axios
    .put(`${API}/change-password`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const changeInfo = async (body: NEWINFO) => {
  const token = getCookie('token');
  const form = new FormData();
  form.append('firstname', body.firstname);
  form.append('lastname', body.lastname);
  form.append('contact', body.contact);
  form.append('role', body.role);
  form.append('subscription', body.subscription);

  return axios({
    method: 'POST',
    url: `${API}/update-role-info`,
    data: form,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
  }).then((response) => response);
};

export const changeInfoAdmin = async (body: NEWINFO) => {
  const token = getCookie('token');
  return axios({
    method: 'PUT',
    url: `${API}/update-admin-profile`,
    data: JSON.stringify({
      firstname: body.firstname,
      lastname: body.lastname,
      contact: body.contact,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const updatePermissions = async (body: PERMISSION) => {
  const token = getCookie('token');
  return axios({
    method: 'PUT',
    url: `${API}/update-superadmin-permissions/${body.user_id}`,
    data: JSON.stringify({
      user_create: body.user_create,
      user_view: body.user_view,
      user_archive: body. user_archive,
      user_update: body.user_update,
      transaction_view: body.transaction_view,
      transaction_write: body.transaction_write,
      disbursement_view: body. disbursement_view,
      disbursement_write: body.disbursement_write,
      account_view: body.account_view,
      account_write: body.account_write,
      permission_write: body.permission_write,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const changeInfoSuperAdmin = async (body: NEWINFO) => {
  const token = getCookie('token');
  return axios({
    method: 'PUT',
    url: `${API}/update-superadmin`,
    data: JSON.stringify({
      firstname: body.firstname,
      lastname: body.lastname,
      contact: body.contact,
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const changeInfoSuperAdminA = async (body: NEWADMIN) => {
  const token = getCookie('token');
  const form = new FormData();
  form.append('firstname', body.firstname);
  form.append('lastname', body.lastname);
  form.append('contact', body.contact);

  return axios({
    method: 'PUT',
    url: `${API}/update-a-superadmin/${body.id}`,
    data: form,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
  }).then((response) => response);
};

export const updateEmailNotif = async (body: EMAILNOTIF) => {
  const token = getCookie('token');
  return axios({
    method: 'PUT',
    url: `${API}/update-email-settings`,
    data: JSON.stringify({
      merchant_product_update: body.merchant_product_update,
      affiliate_category_invite: body.affiliate_category_invite,
      merchant_category_update: body.merchant_category_update,
      merchant_category_removed: body.merchant_category_removed,
      affiliate_category_update: body.affiliate_category_update,
      affiliate_category_removal: body.affiliate_category_removal,
      affiliate_product_removal: body.affiliate_product_removal,
      merchant_product_removal: body.merchant_product_removal,
      terms_and_privacy_update: body.terms_and_privacy_update,
      conversion_success: body.conversion_success,
      merchant_new_product: body.merchant_new_product,
      store_link_update: 1,
      product_link_update: body.product_link_update,
      merchant_subscription: body.merchant_subscription,
      commission: body.commission
    }),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};