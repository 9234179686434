import React, { FC } from 'react';
import { batch } from 'react-redux';
import validator from 'validator';
import { useNavigate, useLocation } from 'react-router-dom';
import { usersActions, useAppDispatch } from '@redux';
import { useCookie } from '@hooks';
import { Div, Flex, FlexRow, Text, PasswordInput, Button, Toast, Input } from '@core';
import { sendEmailConfirmation } from '@services';

export const Security: FC<any> = ({ user }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [token, updateToken] = useCookie('token', '0');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const [currentPass, setCurrentPAss] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [codeLoading, setCodeLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const [copied, setCopied] = React.useState<any>(false);

  const [startTime, setStart] = React.useState(false);
  const [time, setTime] = React.useState(60);

  React.useEffect(() => {
      const interval = setInterval(() => {
        if(startTime && time !== 0){
          if(time < 10){
            setTime(parseFloat(`0${time-1}`))
          }else{
            setTime(time-1)
          }
        }else{
          setStart(false)
        }
      }, 1000);
      return () => clearInterval(interval);
  }, [startTime, time]);

  React.useEffect(() => {
    if(actionQuery === 'verify'){
      if(user?.user_info?.role === 'merchant'){
        navigate(`/merchant/settings`);  
      }else if(user?.user_info?.role === 'affiliate'){
        navigate(`/affiliate/settings`); 
      }else if(user?.user_info?.role === 'Manager'){
        navigate(`/merchant/admin_dashboard/manager/settings`)
      }else if(user?.user_info?.role === 'Editor'){
        navigate(`/merchant/admin_dashboard/editor/settings`)
      }
    }
  });

  const onSubmit = () => {
    const isPassword = validator.isStrongPassword(password, {
      minLength: 6,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });

    if (currentPass === '' || password === '' || confirmPassword === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if (!isPassword) {
      setToastTitle('Invalid Password');
      setToastDesc('Please follow the required format.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if (password !== confirmPassword) {
      setToastTitle(`Password don't match`);
      setToastDesc('Please check your password.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newPass({
            old_password: currentPass,
            new_password: password,
          }),
        );
        console.log('HEY', data);
        if (data?.payload?.status === 200) {
          setToastTitle('Password Updated');
          setToastDesc('Logging out! Redirecting to login page.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          batch(async () => {
            console.log(token);
            setTimeout(async () => {
              await dispatch(usersActions.logout());
              updateToken('', 1);
              navigate(`/`);
            }, 2000);
          });
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    }
  };

  const onSend = () => {
    batch(async () => {
      setCodeLoading(true);
      const data: any = await dispatch(usersActions.requestChangeEmail(user?.user_info.email));
      if (data?.payload?.status === 1) {
        setStart(true)
        setToastTitle('Code Sent');
        setToastDesc('Verification code has been sent to your current email.');
        setToastKind('success');
        setToastOpen(true);
        setCodeLoading(false);
      } else {
        setToastTitle('Error');
        setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
        setToastKind('error');
        setToastOpen(true);
        setCodeLoading(false);
      }
    });
  };

  const onUpdateEmail = () => {
    batch(async () => {
      setUpdateLoading(true);
      const data: any = await dispatch(
        usersActions.newEmail({
          email,
          code,
        }),
      );
      if (data?.payload?.status === 1) {
        await dispatch(usersActions.profile());
        setToastTitle('Email Changed');
        setToastDesc('Email successfully changed!');
        setToastKind('success');
        setToastOpen(true);
        setUpdateLoading(false);
        setEditEmail(false);
      } else {
        setToastTitle('Error');
        setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
        setToastKind('error');
        setToastOpen(true);
        setUpdateLoading(false);
      }
    });
  };

  const [confirmLoad, setConfirmLoad] = React.useState(false);
  const sendEmailConfrimation = () => {
    batch(async () => {
      setConfirmLoad(true);
      const data = await sendEmailConfirmation();
      console.log('sendEmailConfirmation', data.data);
      setConfirmLoad(false);
      if (data.data?.status === 1 || data.data?.status === '1') {
        setToastTitle('SUCCESS');
        setToastDesc('Verification code has been sent to your email.');
        setToastKind('success');
        setToastOpen(true);
        setCodeLoading(false);
      } else {
        setToastTitle('ERROR');
        setToastDesc(data.data.message || 'Something went wrong. Try again!');
        setToastKind('error');
        setToastOpen(true);
        setCodeLoading(false);
      }
    });
  };

  return (
    <Flex
      className="desktop:pl-16 phone:pt-0 laptop:pr-20 phone:px-5"
    >
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Text className="font-poppins font-semibold text-xl phone:mt-4 tablet:mt-8 mb-3">
        Contact
      </Text>
      <hr className="text-grey-400 mb-10" />
      <Text className="text-base text-grey-100 mb-5 font-light">
        Where we send important messages about your account.
      </Text>
      <FlexRow
        className="mb-10 phone:flex-col phone:items-start laptop:flex-row 
      laptop:items-center"
      >
        <Text
          className="w-36 mr-36 mb-auto text-base text-grey-100 font-medium 
        laptop:mb-auto phone:mb-5"
        >
          Email
        </Text>
        {editEmail ? (
          <Div className="w-full">
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full"
              label="New Email Address"
              lableSize="text-sm"
              onChange={setEmail}
              placeholder="New email address"
              type="text"
              value={email}
            />
            <FlexRow>
              <Input
                className="pt-3 pb-3 pl-3 pr-3"
                containerClass="tablet:w-60 phone:w-full"
                label="Verification Code"
                lableSize="text-sm"
                onChange={setCode}
                placeholder="Verification code"
                type="text"
                value={code}
              />
              {
                startTime?
                <Button
                  className="justify-center items-center pt-3 pb-3 mt-1 ml-2 w-32 pl-2 pr-2 bg-blue-100"
                >
                  {startTime? `${time < 10 ? `00:0${time}` : `00:${time}`}` : 'Send Code'}
                </Button>
                :
                <Button
                  className="justify-center items-center pt-3 pb-3 mt-1 ml-2 w-32 pl-2 pr-2 bg-blue-100"
                  isLoading={codeLoading}
                  onClick={onSend}
                >
                  Send Code
                </Button>
              }
            </FlexRow>
            {email === '' || code === '' ? (
              <Button className="w-56 justify-center cursor-not-allowed bg-grey-100" disabled>
                Update Email
              </Button>
            ) : (
              <Button
                className={`w-56 justify-center `}
                isLoading={updateLoading}
                onClick={onUpdateEmail}
              >
                Update Email
              </Button>
            )}
          </Div>
        ) : (
          <Div className="w-full">
            <FlexRow className="space-x-3 w-full">
              <Text
                className="font-semibold text-xl text-secondary-100 w-56 tablet:mr-32 
                phone:mr-10"
              >
                {user?.user_info.email}
              </Text>
              {user?.email_confirmed ? (
                <div onClick={() => setEditEmail(true)}>
                  <i className="fas fa-pencil-alt text-xl hover:text-blue-100 cursor-pointer" />
                </div>
              ) : (
                <></>
              )}
            </FlexRow>
            <Text className="font-semibold text-base text-blue-100  mr-20 mt-5">
              {user?.email_confirmed ? 'Verified.' : 'Not Verified.'}{' '}
              <span className="text-secondary-200 font-normal">
                This email is linked to your account.
              </span>
            </Text>
            {user?.email_confirmed ? (
              <></>
            ) : (
              <Button
                className="w-56 justify-center mt-8"
                isLoading={confirmLoad}
                onClick={sendEmailConfrimation}
              >
                Send Verification
              </Button>
            )}
          </Div>
        )}
      </FlexRow>

      <Text className="font-poppins font-semibold text-xl mt-8 mb-3">Security</Text>
      <hr className="text-grey-400 mb-10" />
      <Text className="text-base text-grey-100 mb-5 font-light">
        You will be required to login after changing your password.
      </Text>
      <FlexRow
        className="pb-10 phone:flex-col phone:items-start laptop:flex-row 
      laptop:items-center"
      >
        <Text
          className="w-36 mr-36 mb-auto text-base text-grey-100 font-medium 
        laptop:mb-auto phone:mb-5"
        >
          Password
        </Text>
        <Div className="w-full">
          <PasswordInput
            className="pt-3 pb-3 pl-3 pr-3"
            containerClass="tablet:w-96 phone:w-full"
            label="Current password"
            lableSize="text-sm"
            onChange={setCurrentPAss}
            value={currentPass}
          />
          <PasswordInput
            className="pt-3 pb-3 pl-3 pr-3"
            containerClass="tablet:w-96 phone:w-full"
            label="New password"
            lableSize="text-sm"
            onChange={setPassword}
            value={password}
          />
          <Div
            className="flex tablet:flex-row ml-10 tablet:items-center mb-5 font-poppins 
              text-base phone:text-sm tablet:text-base phone:flex-col phone:items-start"
          >
            <ul className="list-disc">
              <li
                className={
                  password.length === 0
                    ? 'text-grey-100'
                    : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                }
              >
                <Text className=" mb-1">At least 6 characters</Text>
              </li>
              <li
                className={
                  password.length === 0
                    ? 'text-grey-100'
                    : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                }
              >
                <Text className=" mb-1">At least 1 capital letter</Text>
              </li>
            </ul>
            <ul className="list-disc tablet:ml-20 phone:ml-0">
              <li
                className={
                  password.length === 0
                    ? 'text-grey-100'
                    : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                }
              >
                <Text className=" mb-1">At least 1 number</Text>
              </li>
              <li
                className={
                  password.length === 0
                    ? 'text-grey-100'
                    : `${
                        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                          ? 'text-green-100'
                          : 'text-red-100'
                      }`
                }
              >
                <Text className="mb-1">At least 1 special character</Text>
              </li>
            </ul>
          </Div>
          <PasswordInput
            className="pt-3 pb-3 pl-3 pr-3"
            containerClass="tablet:w-96 phone:w-full"
            label="Confirm new password"
            lableSize="text-sm"
            onChange={setConfirmPassword}
            value={confirmPassword}
          />
          <Button className="w-56 justify-center" isLoading={loading} onClick={onSubmit}>
            Change Password
          </Button>
        </Div>
      </FlexRow>

      {
        user.user_info.role === 'merchant'?
        <>
        <Text className="font-poppins font-semibold text-xl phone:mt-3 tablet:mt-8 mb-3">
          Conversion Private Key
        </Text>
        <hr className="text-grey-400 mb-10" />

        <Text className="text-base text-grey-100 mb-5 font-light">
          Your unique key for the conversion script.
        </Text>

        <FlexRow
          className="pb-10 phone:flex-col phone:items-start laptop:flex-row 
        laptop:items-center"
        >
          <Text
            className="w-36 mr-36 mb-auto text-base text-grey-100 font-medium 
          laptop:mb-auto phone:mb-5"
          >
            Secret Key
          </Text>
          {
            user.email_confirmed?
            <FlexRow className='w-full flex-col items-start'>
            <PasswordInput
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full mr-auto"
              label="Private Key:"
              lableSize="text-sm"
              value={user?.merchant_data?.info?.secret_key ? user?.merchant_data?.info?.secret_key : ''}
            />
            <button
                    className={`mr-auto py-3 rounded-lg w-32 justify-center items-center rounded
                  ${ copied ? 'bg-white border border-secondary-200' : 'bg-primary-100 text-white'}
                  `}
                onClick={() => {
                  setCopied(true);
                  navigator.clipboard.writeText(user?.merchant_data?.info?.secret_key || '');
                  }}
                >
                  {
                    copied ?
                    <p className='w-64 px-2 py-1 rounded-lg w-auto'>COPIED!</p>
                    :
                    <>
                    <p className='w-64 px-2 py-1 rounded-lg w-auto'>COPY KEY</p>
                    </>
                  }
             </button>  
             </FlexRow>
             :
             <FlexRow className='w-full flex-col items-start'>
                <Text className="text-base text-red-400 mb-5 font-light w-full text-left">
                  Verify email to access your private key
                </Text>
                <button className='mr-auto py-4 rounded-lg w-32 justify-center items-center rounded bg-secondary-200 text-white cursor-not-allowed'>
                  COPY KEY
                </button>  
             </FlexRow>
          }
        </FlexRow>
        </>
        :
        <></>
      }

    </Flex>
  );
};