import React, { FC, Fragment } from 'react';
import { Dialog, Transition, Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { FlexRow, Button, Div, Toast, InputV2, SelectV2, Text } from '@core';
import { getCookie, } from '@hooks';
import { API } from '@config';
import { Editor, Manager, AdminRoles } from '@constants';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const CreateInviteAdmin: FC<ModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const token = getCookie('token');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [adminEmail, setEmail] = React.useState('');
  const [adminRole, setRole] = React.useState('');

  const onSubmit = async () => {

    if(adminEmail === '' || adminRole === ''){
      setToastTitle('ERROR');
      setToastDesc('Please complete all fields.');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 3000)
    }else{
      setLoading(true)
      fetch(`${API}/add-merchant-user`,{
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
              email: adminEmail,
              role: adminRole
          })
      })
      .then(res => res.json())
      .then(data => {
          if(data.status === 1){
            setLoading(false)
            setToastTitle('SUCCESS');
            setToastDesc('Email invitation has been sent.');
            setToastKind('success');
            setToastOpen(true);
            navigate('/merchant/settings?action=updated')
            setTimeout(() => {
              setToastOpen(false)
            }, 3000)
          }else{
            setLoading(false)
            setToastTitle('ERROR');
            setToastDesc(data.message || 'Something went wrong. Please try again.');
            setToastKind('error');
            setToastOpen(true);
            setTimeout(() => {
              setToastOpen(false)
            }, 3000)
          }
        })
    }
  }

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={onClose}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">INVITE USER</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      if(window.location.pathname === `/merchant/settings`){
                        navigate('/merchant/settings?action=updated')
                      }
                    }}
                  />
                </FlexRow>

                <Div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide w-full">

                <Text className="font-roboto font-bold text-lg text-orange-100 ml-2">
                  User Information
                </Text>
                <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                  Create a user by sending email invite.
                </Text>

                <form  className='w-full'>

                  <SelectV2
                    label="USER ROLE"
                    name="role"
                    onChange={setRole}
                    options={AdminRoles}
                    placeholder="Choose"
                    value={adminRole}
                  />
                  <InputV2
                    label="EMAIL"
                    name="email"
                    onChange={setEmail}
                    placeholder="Enter User Email"
                    type="email"
                    value={adminEmail}
                  />

                    <Popover className="relative mt-14 mb-4">
                          <Popover.Button>
                              <Text className='text-blue-100 font-semibold hover:underline'>
                                FAQs | Manager
                                <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                              </Text>
                          </Popover.Button>
                          <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
                            <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-80">
                              <Text className='font-semibold text-sm text-secondary-200'>
                                Manager
                              </Text>
                              <hr className='text-secondary-200 my-1'/>
                              <Text className='text-sm my-2 text-secondary-200'>
                                Manager permissions and responsibilities:
                              </Text>

                              <Div className=' h-24 overflow-y-scroll'>
                              {
                                Manager.map((list) => (
                                  <Div className="text-grey-100 flex items-center text-sm mb-2" key={list}>
                                    <i className="fas fa-check-circle text-primary-100 text-sm mr-5" />
                                    {list}
                                  </Div>
                              ))}
                              </Div>
                              <p className='mt-4 text-sm text-secondary-200'>
                                ** Click anywhere on screen to close. **
                              </p>
                            </div>
                          </Popover.Panel>
                    </Popover>

                    <Popover className="relative mb-4">
                          <Popover.Button>
                              <Text className='text-blue-100 font-semibold hover:underline'>
                                FAQs | Editor
                                <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                              </Text>
                          </Popover.Button>
                          <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
                            <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-80">
                              <Text className='font-semibold text-sm text-secondary-200'>
                                Manager
                              </Text>
                              <hr className='text-secondary-200 my-1'/>
                              <Text className='text-sm my-2 text-secondary-200'>
                                Manager permissions and responsibilities:
                              </Text>

                              <Div className=' h-24 overflow-y-scroll'>
                              {
                                Editor.map((list) => (
                                  <Div className="text-grey-100 flex items-center text-sm mb-2" key={list}>
                                    <i className="fas fa-check-circle text-primary-100 text-sm mr-5" />
                                    {list}
                                  </Div>
                              ))}
                              </Div>
                              <p className='mt-4 text-sm text-secondary-200'>
                                ** Click anywhere on screen to close. **
                              </p>
                            </div>
                          </Popover.Panel>
                    </Popover>
                
                </form>

                </Div>

                <hr />
                <Div className='flex  mx-8 my-5'>
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    INVITE USER
                  </Button>
                </Div>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};