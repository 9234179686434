import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import { productsActions, useAppDispatch, selectUser } from '@redux';
import { FlexRow, Button, Div, Text, InputV2, Toast, SelectV2, Checkbox, Flex, InputWLength, Icons, InputPrice } from '@core';
import { MainCategories, SubsCategories } from '@constants';
import { useSubscription } from '@hooks';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  setSelected?: any;
}

export const CreateProductModal: FC<ModalProps> = ({ isOpen, onClose, setSelected }) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user: any = useSelector(selectUser);
  const subscription = useSubscription();

  const [name, setName] = React.useState('');
  const [link, setLink] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [price, setPrice] = React.useState('0');
  const [category, setCategory] = React.useState('');
  const [subcategory, setSubCategory] = React.useState('');
  const [categoryIndex, setCategoryIndex] = React.useState(10);
  const [serviceType, setServiceType] = React.useState(false);
  const [type, setType] = React.useState('product');

  // eslint-disable-next-line no-unused-vars
  const [commission, setCommission] = React.useState('');
  const [productPic, setProductPic] = React.useState<any>();
  const [imageValid, setImageValid] = React.useState<any>();

  const [assets, setAssets] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const inputFile = React.useRef<any>(null);
  const [prevPic, setPrevPic] = React.useState<any>();

  React.useEffect(() => {
      if(productPic){
        if(productPic[0]){
          setPrevPic(productPic)
          if(productPic[0].type.includes('image')){
            setImageValid(true)
          }else{
            setImageValid(false)
          }
        }else{
          setProductPic(prevPic)
        }
      }
    })

  React.useEffect(() => {
    if(serviceType){
      setType('service')
    }else{
      setType('product')
    }

    if(category === 'Others'){
      setSubCategory('')
    }
  })

  React.useEffect(() => {
    if(category === 'Computer & Technology'){
      setCategoryIndex(0)
    }else if(category === 'Health & Wellness'){
      setCategoryIndex(1)
    }else if(category === 'Fashion & Accessories'){
      setCategoryIndex(2)
    }else if(category === 'Clothing'){
      setCategoryIndex(3)
    }else if(category === 'Hobbies & Crafts'){
      setCategoryIndex(4)
    }else if(category === 'Sports & Fitness'){
      setCategoryIndex(5)
    }else if(category === 'Home & Appliances'){
      setCategoryIndex(6)
    }else if(category === 'Pet Supplies'){
      setCategoryIndex(7)
    }else if(category === 'Food & Beverages'){
      setCategoryIndex(8)
    }else if(category === 'Automotive'){
      setCategoryIndex(9)
    }else if(category === 'Others'){
      setCategoryIndex(10)
    }
  }, [category])

  React.useEffect(() => {
    setName('');
    setLink('');
    setLink('');
    setDescription('');
    setPrice('0');
    setCategory('');
    setSubCategory('');
    setProductPic('');
    if (!user?.email_confirmed && isOpen) {
      onClose();
      navigate(`/merchant/dashboard`);
      setTimeout(() => {
        setToastTitle('UNVERIFIED');
        setToastDesc('Please confirm your email address.');
        setToastKind('warning');
        setToastOpen(true);
      }, 1000);
    } else if (subscription?.product?.limit === subscription?.product?.total && isOpen) {
      onClose();
      navigate(`/merchant/dashboard`);
      setTimeout(() => {
        setToastTitle('WARNING');
        setToastDesc('You have already reach your product limit.');
        setToastKind('warning');
        setToastOpen(true);
      }, 1000);
    }
  }, [navigate, isOpen]);

  React.useEffect(() => {
    if(parseFloat(price) < 0){
      setPrice('0');
      setToastTitle('Invalid Input');
      setToastDesc('Rejecting negative values');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }else if(parseFloat(commission) < 0){
      setCommission('');
      setToastTitle('Invalid Input');
      setToastDesc('Rejecting negative values');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }else if(parseFloat(price) > 500000){
      setPrice('0');
      setToastTitle('Invalid Input');
      setToastDesc('Price should not exceed PHP 500,000.');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }else if(parseFloat(commission) > 100 || commission.includes('.')){
      setCommission('');
      setToastTitle('Invalid Input');
      setToastDesc('Commission must be a whole number and should not exceed 100%.');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }
  }, [price, commission])

  React.useEffect(() => {
    if(price === ''){
      setPrice('0')
    }
  }, [price])

  const onSubmit = () => {
      if (name === '' || link === '' || description === '' || price === '' || category === '' || (parseFloat(price) < 0) || (parseFloat(commission) < 0)) {
        setToastTitle('Invalid Input');
        setToastDesc('Please complete all the fields');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
        setTimeout(() => {
          setToastOpen(false);
        }, 3000)
      } else if (subcategory === '' && category !== 'Others') {
        setToastTitle('Invalid Input');
        setToastDesc('Please select a sub-category for this item.');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
        setTimeout(() => {
          setToastOpen(false);
        }, 3000)
      } else if (!imageValid && productPic) {
        setToastTitle('Invalid file type');
        setToastDesc('File must be an image type.');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
        setTimeout(() => {
          setToastOpen(false);
        }, 3000)
      } else {
        batch(async () => {
          setLoading(true);
          const data: any = await dispatch(
            productsActions.newProduct({
              name,
              description,
              category,
              sub_category: subcategory,
              price,
              link,
              commission,
              productPic,
              type,
            }),
          );
          if (data?.payload?.data?.status === 1) {

            setToastTitle('Product Created');
            setToastDesc('Product is successfully created.');
            setToastKind('success');
            setToastOpen(true);
            setLoading(false);
            setTimeout(() => {
              batch(async () => {
                await dispatch(productsActions.productList());
                setSelected('')
                if(assets){
                  navigate(user.user_info.role === 'merchant'? `/merchant/dashboard?item=${name}&action=newitem` : `/merchant/admin_dashboard/manager?item=${name}&action=newitem`);
                }else{
                  navigate(user.user_info.role === 'merchant'? '/merchant/dashboard' : '/merchant/admin_dashboard/manager');
                }
              });
              setToastOpen(false);
              onClose();
            }, 1000);
          } else {
            setToastTitle('ERROR');
            setToastDesc(data?.payload?.message || 'Something went wrong. Try again.');
            setToastKind('error');
            setToastOpen(true);
            setLoading(false);
            setTimeout(() => {
              setToastOpen(false);
            }, 2000)
          }
        });
      }
    };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            navigate('/merchant/dashboard');
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">CREATE PRODUCT</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate('/merchant/dashboard');
                    }}
                  />
                </FlexRow>
                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll">
                  <Text className="font-roboto font-bold text-lg text-orange-100 ml-2">
                    Product Details
                  </Text>
                  <Text className="font-roboto font-normal text-base text-blue-100 ml-2 mt-3 mb-5">
                    Enter the information below.
                  </Text>
                  <InputV2
                    containerClass="mt-2"
                    label="PRODUCT NAME"
                    name="product-name"
                    onChange={setName}
                    placeholder="Enter Product Name"
                    value={name}
                  />
                  <InputV2
                    label="PRODUCT LINK"
                    name="product-link"
                    onChange={setLink}
                    placeholder="Eg: http://mystore.com/product"
                    value={link}
                  />
                  <InputWLength
                    label="DESCRIPTION"
                    maxlength={255}
                    name="product-description"
                    onChange={setDescription}
                    placeholder="Enter Product Description"
                    value={description}
                  />
                  <Div className='py-4 border border-secondary-100 rounded-lg mb-4'>
                      <details>
                      <summary className='laptop:text-sm phone:text-xs text-secondary-100 phone:font-bold phone:font-poppins mb-1 text-left list-none px-5'>
                        PRODUCT IMAGE
                        <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                      </summary>

                      <Div>
                      <hr className='text-secondary-200 my-2 mx-5' />
                      <Text className='text-secondary-200 font-semibold my-2 px-5'>
                        Recommended Properties
                      </Text>
                      <Text className='text-secondary-200 mt-2 mb-4 px-5'>
                        Resolution: Min. 200x200; Max. 1200x600
                      </Text>
                      <Text className='text-secondary-200 mt-2 mb-4 px-5'>
                        Ratio: 1x1 (square-type photos); 
                        3x2 (rectangular-type, image will be centered)
                      </Text>
                      <Text className='text-secondary-200 mt-2 mb-4 px-5'>
                        Types: .jpg,jpeg (recommended), png(optional)
                      </Text>
                      <Text className='text-secondary-200 mt-2 mb-4 px-5'>
                        Size: Max. 2MB
                      </Text>

                      <Div
                        className="rounded-3xl w-72 justify-self-center bg-primary-100 shadow-lg cursor-pointer 
                      border border-primary-100 duration-500 flex flex-col justify-center overflow-hidden my-4 mx-auto"
                      >
                      <div>
                        <Flex>
                          <Div className="bg-primary-300 h-52">
                            {
                              productPic? (
                              <img
                                alt="Comapny Logo"
                                className="w-full h-full object-cover"
                                src={
                                  productPic[0]
                                  ? window.URL.createObjectURL(productPic[0])
                                  : window.URL.createObjectURL(prevPic[0])
                                }
                              />
                              ) : (
                              <img
                                alt="Comapny Logo"
                                className="w-full h-full object-cover"
                                src={Icons.xffiliate2}
                              />
                            )}
                            <input
                              accept="image/*"
                              className="hidden"
                              onChange={(event) => {
                                if(productPic){
                                  if(productPic.length === 1){
                                    if(productPic[0].type.includes('image')){
                                      setProductPic(event.target.files)  
                                    }  
                                  }
                                }
                              }}
                              ref={inputFile}
                              type="file"
                            />
                          </Div>
                          <Div className="px-5 pt-4 pb-2 h-40 flex flex-col">
                            <Text className="text-base text-blue-100">{category || 'Category'}</Text>
                            <Text className="font-bold h-full text-lg text-white">{name || 'Name'}</Text>
                            <FlexRow className="h-full justify-between">
                              <Text className="font-semibold text-white mr-auto ">
                                Earn <span className="text-2xl text-orange-100 font-bold">{commission !== ''? parseFloat(commission).toFixed(2) : '0.00'}%</span>
                              </Text>
                              <Text className='text-white'>100 Affiliates</Text>
                            </FlexRow>
                          </Div>
                        </Flex>
                      </div>
                      </Div>
                      </Div>

                      </details>
                      <FlexRow className='px-5'>
                      <button
                        className='text-sm py-1 px-2 mr-2 border border-secondary-200 bg-grey-500 rounded w-28'
                        onClick={() => inputFile?.current.click()}
                        type="button"
                      >
                        Choose File
                      </button>
                      {
                        productPic?
                        <Text className='text-sm text-secondary-200 w-52 truncate'>
                          {productPic[0]? productPic[0].name : 'No file chosen'}
                        </Text>
                        :
                        <Text className='text-sm text-secondary-200 w-52 truncate'>
                          No file chosen
                        </Text>
                      }
                      </FlexRow>
                    </Div>
                    <input
                      accept="image/*"
                      className="hidden"
                      onChange={(event) => setProductPic(event.target.files)}
                      ref={inputFile}
                      type="file"
                    />
                  <InputPrice
                    currency='peso'
                    label="PRICE"
                    name="product-price"
                    onChange={setPrice}
                    placeholder="Enter Product Price"
                    value={price}
                  />
                  <SelectV2
                    className='px-2'
                    label="MAIN CATEGORY"
                    name="category"
                    onChange={setCategory}
                    options={MainCategories}
                    placeholder="Select a Main Category"
                    value={category}
                  />
                  <SelectV2
                    className='px-2'
                    containerClass={category === 'Others'? 'hidden' : ''}
                    label="SUB CATEGORY"
                    name="category"
                    onChange={setSubCategory}
                    options={SubsCategories[categoryIndex]}
                    placeholder="Select a Sub-Category"
                    value={subcategory}
                  />
                  <InputV2
                    label="COMMISSION (%)"
                    name="product-commission"
                    onChange={setCommission}
                    pattern="[0-9]*"
                    placeholder="Enter Commission"
                    type="number"
                    value={commission}
                  />
                  <Checkbox
                    checked={serviceType}
                    className=''
                    containerClass="mt-4"
                    id='type'
                    label='This is a service-type product'
                    onClick={() => setServiceType(!serviceType)}
                  />
                  <details>
                    <summary className='text-blue-100 font-semibold hover:underline list-none mb-4 cursor-pointer'>
                        FAQs | Product Types
                        <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                    </summary>

                    <div className="my-4 p-2">
                      <Text className='font-semibold text-secondary-200 text-sm'>
                        Product type
                      </Text>
                      <hr className='text-primary-100 my-1'/>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        This type includes all tangible products, perceptible by touch, 
                        and in material form which earn revenue through purchases., 
                        ie. <i>pencil, coffee, IPhone X</i>
                      </Text>
                      <Text className='font-semibold text-secondary-200 text-sm mt-4'>
                        Service type
                      </Text>
                      <hr className='text-primary-100 my-1'/>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        This type includes all intangible products which are 
                        in the form of digital like software applications that 
                        earn revenue through subscriptions, 
                        memberships and any kind of digital purchase.
                      </Text>
                    </div>
                  </details>

                  <Checkbox
                    checked={assets}
                    className=''
                    containerClass="mt-4"
                    id='assets'
                    label='Enable Marketing assets'
                    onClick={() => setAssets(!assets)}
                  />
                  <details className=''>
                    <summary className='text-blue-100 font-semibold hover:underline list-none cursor-pointer'>
                        FAQs | Marketing Assets
                        <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                    </summary>

                    <div className='p-2 my-4'>
                      <Text className='font-semibold text-secondary-200 text-sm'>
                        Marketing Assets
                      </Text>
                      <hr className='text-primary-100 my-1'/>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        Ticking this will redirect you to the marketing asset page 
                        for this product where you can:
                      </Text>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        &#8226; Improve your product’s recognition through 
                        customized contents and insights
                      </Text>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        &#8226; Provide your affiliates with the right content 
                        to promote your product
                      </Text>
                    </div>

                  </details>

                  <FlexRow
                    className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg mt-8"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      Be sure to complete all fields to create a product.
                    </Text>
                  </FlexRow>
                  <Div className="h-20 w-2" />
                </div>
                <hr />
                <div className=" flex  mx-8 my-5">
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}

                  >
                    CREATE PRODUCT
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};