import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import { selectPartners, partnersActions, useAppDispatch } from '@redux';
import { AllLinks } from '@constants';
import { FlexRow, Radio, ButtonIcon, Div, Dropdown } from '@core';
import { NavBar, CreateLinkModal, QrCodeModal, CreateProductModal } from '@components';

const AffiliatesTable: FC<any> = ({ data }: any) => (
  <div className="flex flex-col font-poppins mt-3">
    <div className="-my-2 overflow-x-auto -mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full px-6 lg:px-8">
        <div className="overflow-hidden border border-primary-100 rounded-lg">
          <table className="min-w-full divide-y divide-primary-200">
            <thead
              className="bg-primary-100 text-left text-sm font-medium text-white 
                  uppercase"
            >
              <tr>
                <th className="px-6 py-5 tracking-wider" scope="col">
                  ID
                </th>
                <th className="px-6 py-3 tracking-wider" scope="col">
                  Name
                </th>
                <th className="px-6 py-3 tracking-wider" scope="col">
                  Contacts
                </th>
                <th className="px-6 py-3 tracking-wider" scope="col">
                  Social Links
                </th>
                <th className="px-6 py-3 tracking-wider" scope="col">
                  Mode of Payments
                </th>
                <th className="px-6 py-3 tracking-wider" scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data?.map((value: any) => (
                <tr key={value?.user_id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-secondary-100">{value?.user_id}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      {/* <div className="flex-shrink-0 h-10 w-10">
                        <img alt="" className="h-10 w-10 rounded-full" src={value.profile} />
                      </div> */}
                      <div className="text-sm font-medium text-secondary-100">
                        {`${value?.firstname} ${value?.lastname}`}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-secondary-100">{value.email}</div>
                    <div className="text-sm text-secondary-200">{value.contact}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-blue-100">{value.social_link}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-secondary-100">{value.payment_method}</div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <a className="text-secondary-100 hover:text-blue-100 ml-2" href="/">
                      <i className="fas fa-edit" />
                    </a>
                    <a className="text-secondary-100 hover:text-blue-100 ml-4" href="/">
                      <i className="fas fa-trash-alt" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export const Affiliates: FC<any> = () => {
  const dispatch = useAppDispatch();
  const Partners: any = useSelector(selectPartners);
  const [createLinkOpen, setCreateLinkOpen] = React.useState(false);
  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);
  const [createProdOpen, setCreateProdOpen] = React.useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  React.useEffect(() => {
    if (actionQuery === 'createlink') {
      setCreateLinkOpen(true);
    } else if (actionQuery === 'qrcode') {
      setQrCodeOpen(true);
    } else if (actionQuery === 'product') {
      setCreateProdOpen(true);
    }
  }, [actionQuery]);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(partnersActions.partnersList());
    });
  }, [dispatch, batch, actionQuery, partnersActions]);

  return (
    <Div className="h-full">
      <NavBar />
      <CreateLinkModal isOpen={createLinkOpen} onClose={() => setCreateLinkOpen(false)} />
      <QrCodeModal isOpen={qrCodeOpen} onClose={() => setQrCodeOpen(false)} />
      <CreateProductModal isOpen={createProdOpen} onClose={() => setCreateProdOpen(false)} />
      <Div className="px-14 pt-28 items-center bg-white w-full">
        <FlexRow>
          <Radio
            containerClass="mb-3 mr-5"
            label="Date Created"
            name="filters"
            optionId="filters-option-1"
          />
          <Radio
            containerClass="mb-3 mr-auto"
            label="Top Performing"
            name="filters"
            optionId="filters-option-1"
          />
          {/* <Button className="pt-2 pb-2 ">Upgrade for custom links</Button>
          <Button className="pt-2 pb-2 bg-grey-100">SELECT DATE</Button> */}
        </FlexRow>
      </Div>
      <Div className="px-14 pt-5">
        <FlexRow>
          <ButtonIcon className="pt-2 pb-2 mr-5" icon="fas fa-filter">
            Filters
          </ButtonIcon>
          <Dropdown
            className="text-secondary-100 border border-secondary-100 mb-2"
            color="white"
            current="Tag"
            items={AllLinks}
            width="w-44"
          />
          <ButtonIcon className="pt-2 pb-2 ml-auto" icon="fas fa-plus">
            Add Partner
          </ButtonIcon>
        </FlexRow>
        <AffiliatesTable data={Partners} />
      </Div>
    </Div>
  );
};
