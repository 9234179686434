import React, { FC } from 'react';
import { Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { batch } from 'react-redux';
import { Div, Flex, Text, Button, Checkbox, Toast, ToastConfirm, FlexRow } from '@core';
import { CommissionData, Pagination } from '@components';
import { useSubscription, getCookie } from '@hooks';
import { API } from '@config';
import { FrequencyDataDefault, Months } from '@constants';

export const Payment: FC<any> = ({ user }) => {
  const navigate = useNavigate();
  const token = getCookie('token');
  const subscription = useSubscription();
  const newDate = new Date().toString().substring(0, 33);
  const baseDate = new Date();
  const resultDate = new Date(baseDate);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');
  const [password, setPassword] = React.useState('');

  const [disburse, setDisburse] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [selectedAll, setSelectedAll] = React.useState(true);
  const [isViewOpen, setIsViewOpen] = React.useState(false);
  const [selecting, setSelecting] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);

  const [tax, setTax] = React.useState(0);
  const [totalGross, setTotalGross] = React.useState(0);
  const [totalTransFee, setTotalTransFee] = React.useState(0);
  const [transHistory, setTransHistory] = React.useState(0);
  const [commissionPending, setComPending] = React.useState(0);
  const totalAmount: number = tax + commissionPending + totalTransFee;

  const [genTax, setGenTax] = React.useState(0);
  const [transFee, setTransFee] = React.useState(0);

  const commission: any[] = [];
  const [newList, setNewList] = React.useState<any>([]);
  const [SortList, setSortList] = React.useState<any>([]);

  const [date, setDate] = React.useState<any>('-- --');
  const [date2, setDate2] = React.useState<any>('-- --');
  const [frequencyData, setFrequencyData] = React.useState<any>(FrequencyDataDefault);
  const [noData, setData] = React.useState(true);
  const [cutoff, setCutoff] = React.useState('-- --');
  const [disbursement, setDisbursement] = React.useState('-- --');

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [isToastOpenC, setToastOpenC] = React.useState(false);
  const [toastKindC, setToastKindC] = React.useState('error');
  const [toastTitleC, setToastTitleC] = React.useState('ERROR');
  const [toastDescC, setToastDescC] = React.useState('');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const currentAffiliates = SortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const onReload = () => {
    setLoading(true);
    fetch(`${API}/commission-report`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDisburse(true);
        setSortList(data.data);
        setTransHistory(data.data.length);
        setTax(data.data.length * genTax);
        setTotalTransFee(data.data.length * transFee);
        setTotalGross(user?.revenue_and_commissions?.total_revenue);

        SortList.forEach((list: any) => {
          newList.push(list.affiliate_id);
          commission.push(list.earned);
          const initialValue = 0;
          setComPending(
            commission.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              initialValue,
            ),
          );
        });
        setSelecting(false);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (actionQuery === 'payment') {
      navigate('/merchant/settings');
    } else if (actionQuery === 'disbursed') {
      navigate('/merchant/settings');
      onReload();
    }
  });

  React.useEffect(() => {
    fetch(`${API}/commission-report`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDisburse(true);
        setSortList(data.data);
        setTransHistory(data.data.length);
        setTax(data.data.length * genTax);
        setTotalTransFee(data.data.length * transFee);
        setTotalGross(user?.revenue_and_commissions?.total_revenue);

        SortList.forEach((list: any) => {
          newList.push(list.affiliate_id);
          commission.push(list.earned);
          const initialValue = 0;
          setComPending(
            commission.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              initialValue,
            ),
          );
        });
        setSelecting(false);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (subscription.status === 'FREE PLAN') {
      setTransFee(20);
      setGenTax(transFee * 0.12);
    } else if (subscription.status === 'PLUS PLAN') {
      setTransFee(18);
      setGenTax(transFee * 0.12);
    } else if (subscription.status === 'PRO PLAN') {
      setTransFee(25);
      setGenTax(transFee * 0.12);
    }
  });

  React.useEffect(() => {
    if (noData) {
      fetch(`${API}/get-disbursement-frequency`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 1) {
            setFrequencyData(data.data);

            const tempDate = new Date();
            const result = new Date(tempDate);
            const days = data.data.date_cutoff - result.getDay() + 7;
            const timestamp = result.setDate(result.getDate() + days);
            const fixedDate = new Date(timestamp);
            setDate(fixedDate.toString().substring(4, 15));

            const tempDate2 = new Date();
            const result2 = new Date(tempDate2);
            const days2 = 5 - result2.getDay() + 7;
            const timestamp2 = result2.setDate(result2.getDate() + days2);
            const fixedDate2 = new Date(timestamp2);
            setDate2(fixedDate2.toString().substring(4, 15));

            setTimeout(() => {
              setData(false);
            }, 1000);
            if (data.data.frequency === 'Weekly') {
              setCutoff(date);
              setDisbursement(date2);
            } else if (data.data.frequency === 'Monthly') {
              if (resultDate.getDate() > 3 && resultDate.getFullYear() !== 11) {
                setCutoff(
                  `${Months[resultDate.getMonth() + 1]} ${
                    data.data.date_cutoff
                  }, ${resultDate.getFullYear()}`,
                );
                setDisbursement(
                  `${Months[resultDate.getMonth() + 1]} 3, ${resultDate.getFullYear()}`,
                );
              } else if (resultDate.getDate() > 3 && resultDate.getFullYear() === 11) {
                setCutoff(
                  `${Months[resultDate.getMonth() + 1]} ${data.data.date_cutoff}, ${
                    resultDate.getFullYear() + 1
                  }`,
                );
                setDisbursement(
                  `${Months[resultDate.getMonth() + 1]} 3, ${resultDate.getFullYear() + 1}`,
                );
              } else if (resultDate.getDate() <= 3) {
                setCutoff(
                  `${Months[resultDate.getMonth()]} ${
                    data.data.date_cutoff
                  }, ${resultDate.getFullYear()}`,
                );
                setDisbursement(`${Months[resultDate.getMonth()]} 3, ${resultDate.getFullYear()}`);
              }
            } else if (data.data.frequency === 'Bi-Monthly') {
              if (resultDate.getDate() > 16 && resultDate.getMonth() !== 11) {
                setCutoff(`${Months[resultDate.getMonth() + 1]} 1, ${resultDate.getFullYear()}`);
                setDisbursement(
                  `${Months[resultDate.getMonth() + 1]} 3, ${resultDate.getFullYear()}`,
                );
              } else if (resultDate.getDate() > 16 && resultDate.getMonth() === 11) {
                setCutoff(
                  `${Months[resultDate.getMonth() + 1]} 1, ${resultDate.getFullYear() + 1}`,
                );
                setDisbursement(
                  `${Months[resultDate.getMonth() + 1]} 3, ${resultDate.getFullYear() + 1}`,
                );
              } else if (resultDate.getDate() <= 16) {
                setCutoff(`${Months[resultDate.getMonth()]} 16, ${resultDate.getFullYear()}`);
                setDisbursement(`${Months[resultDate.getMonth()]} 18, ${resultDate.getFullYear()}`);
              }
            }
          } else {
            setFrequencyData(FrequencyDataDefault);
            setData(true);
          }
        });
    }
  }, []);

  const Sort = () => {
    setSelecting(true);
    setSortList(SortList.sort((a: any, b: any) => a.earned - b.earned));
    setTimeout(() => {
      setSelecting(false);
    }, 1000);
  };

  const SortAlpha = () => {
    setSelecting(true);
    setSortList(
      SortList.sort((a: any, b: any) => {
        if (a.affiliate_name < b.affiliate_name) {
          return -1;
        }
        if (a.affiliate_name > b.affiliate_name) {
          return 1;
        }
        return 0;
      }),
    );
    setTimeout(() => {
      setSelecting(false);
    }, 1000);
  };

  const SortEmail = () => {
    setSelecting(true);
    setSortList(
      SortList.sort((a: any, b: any) => {
        if (a.affiliate_email < b.affiliate_email) {
          return -1;
        }
        if (a.affiliate_email > b.affiliate_email) {
          return 1;
        }
        return 0;
      }),
    );
    setTimeout(() => {
      setSelecting(false);
    }, 1000);
  };

  const SortDate = () => {
    setSelecting(true);
    setSortList(
      SortList.sort((a: any, b: any) => {
        if (a.cutOffDate < b.cutOffDate) {
          return -1;
        }
        if (a.cutOffDate > b.cutOffDate) {
          return 1;
        }
        return 0;
      }),
    );
    setTimeout(() => {
      setSelecting(false);
    }, 1000);
  };

  const SortBank = () => {
    setSelecting(true);
    setSortList(
      SortList.sort((a: any, b: any) => {
        if (a.bank_name < b.bank_name) {
          return -1;
        }
        if (a.bank_name > b.bank_name) {
          return 1;
        }
        return 0;
      }),
    );
    setTimeout(() => {
      setSelecting(false);
    }, 1000);
  };

  const SortAccName = () => {
    setSelecting(true);
    setSortList(
      SortList.sort((a: any, b: any) => {
        if (a.account_name < b.account_name) {
          return -1;
        }
        if (a.account_name > b.account_name) {
          return 1;
        }
        return 0;
      }),
    );
    setTimeout(() => {
      setSelecting(false);
    }, 1000);
  };

  const SortAccNum = () => {
    setSelecting(true);
    setSortList(
      SortList.sort((a: any, b: any) => {
        if (a.account_number < b.account_number) {
          return -1;
        }
        if (a.account_number > b.account_number) {
          return 1;
        }
        return 0;
      }),
    );
    setTimeout(() => {
      setSelecting(false);
    }, 1000);
  };

  const onDisburse = async () => {
    setFetching(true);
    if (newList.length > 0) {
      setToastOpenC(true);
      setToastKindC('info');
      setToastTitleC('ACTION');
      setToastDescC(
        `You are about to disburse commissions amounting PHP ${totalAmount
          .toFixed(2)
          .toString()
          .replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ',',
          )}. Please type your account ${'PASSWORD'} to proceed.`,
      );
      setFetching(false);
    } else {
      setTimeout(() => {
        setFetching(false);
        setToastTitle('ERROR');
        setToastDesc('No entries selected');
        setToastKind('error');
        setToastOpen(true);
      }, 1000);
    }
  };

  const Disburse = async () => {
    setFetching(true);
    fetch(`${API}/check-password`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          setToastKindC('info');
          setToastTitleC('ACTION');
          setToastDescC('Password confirmed. Processing disbursement request. Please wait');
          fetch(`${API}/create-disbursement-invoice`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              selectedAffiliates: newList,
            }),
          })
            .then((res) => res.json())
            .then((dataA) => {
              if (dataA.status === 1) {
                setFetching(false);
                setToastTitle('SUCCESS');
                setToastDesc(dataA.message);
                setToastKind('success');
                setToastOpen(true);
                setTimeout(() => {
                  navigate(`/merchant/payment?invoice=${dataA.data.invoice_url}`);
                }, 2000);
              } else {
                setFetching(false);
                setToastTitle('ERROR');
                setToastDesc(dataA.message || 'Something went wrong. Try again.');
                setToastKind('error');
                setToastOpen(true);
                setToastOpenC(false);
              }
            });
        } else {
          setFetching(false);
          setToastTitleC('ERROR');
          setToastDescC('Password is incorrect! Please try again.');
          setToastKindC('error');
          setPassword('');
        }
      });
  };

  const Select = (item: any) => {
    if (newList.includes(item.affiliate_id)) {
      setNewList(newList.filter((value: any) => value !== item.affiliate_id));
      setComPending(commissionPending - item.earned);
      setTax(tax - genTax);
      setTotalTransFee(totalTransFee - transFee);
      if (newList.length === 0) {
        setDisburse(false);
      } else {
        setDisburse(true);
      }
    } else {
      newList.push(item.affiliate_id);
      setNewList(newList);
      setComPending(item.earned + commissionPending);
      setTax(tax + genTax);
      setTotalTransFee(totalTransFee + transFee);
      if (newList.length === 0) {
        setDisburse(false);
      } else {
        setDisburse(true);
      }
    }
  };

  const SelectAll = () => {
    setSelectedAll(!selectedAll);
    setSelecting(true);
    if (selectedAll) {
      setComPending(0);
      setTax(0);
      setTotalTransFee(0);
      newList.splice(0, newList.length);
      setDisburse(false);
      setTimeout(() => {
        setSelecting(false);
      }, 1000);
    } else {
      fetch(`${API}/commission-report`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setDisburse(true);
          setSortList(data.data);
          setTransHistory(data.data.length);
          setTax(data.data.length * genTax);
          setTotalTransFee(data.data.length * transFee);
          setTotalGross(user?.revenue_and_commissions?.total_revenue);

          SortList.forEach((list: any) => {
            newList.push(list.affiliate_id);
            commission.push(list.earned);
            const initialValue = 0;
            setComPending(
              commission.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                initialValue,
              ),
            );
          });
          setTimeout(() => {
            setSelecting(false);
          }, 1000);
        });
    }
  };

  const ViewReport = () => {
    setIsViewOpen(!isViewOpen);
    setSelecting(true);
    fetch(`${API}/commission-report`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        batch(async () => {
          setDisburse(true);
          setSortList(data.data);
          setTransHistory(data.data.length);
          setTax(data.data.length * genTax);
          setTotalTransFee(data.data.length * transFee);
          setTotalGross(user?.revenue_and_commissions?.total_revenue);

          await SortList.forEach((list: any) => {
            newList.push(list.affiliate_id);
            commission.push(list.earned);
            const initialValue = 0;
            setComPending(
              commission.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                initialValue,
              ),
            );
          });

          const element = document.getElementById('Bottom');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }

          setTimeout(() => {
            setSelecting(false);
          }, 1500);
        });
      });
  };

  return (
    <>
      {console.log(user, 'user unique log')}
      {user?.user_info?.role === 'merchant' ? (
        <>
          <Toast
            description={toastDesc}
            isOpen={isToastOpen}
            onClose={() => setToastOpen(false)}
            title={toastTitle}
            toastKind={toastKind}
          />
          <ToastConfirm
            btnText="Cancel"
            description={toastDescC}
            isLoaded={fetching}
            isOpen={isToastOpenC}
            link=""
            onChange={(e: any) => setPassword(e.target.value)}
            onClick={Disburse}
            onClick2={() => setToastOpenC(false)}
            onClose={() => setToastOpenC(false)}
            placeholder="Enter password"
            submitBtnText="Proceed"
            title={toastTitleC}
            toastKind={toastKindC}
            value={password}
          />
          <Flex className="desktop:pl-16 phone:pt-0 laptop:pr-20 phone:px-5">
            <Div className="phone:px-5 phone:w-full phone:mr-2 medium:px-10 tabletWide:w-full bg-grey-500 mt-4 py-2">
              {loading ? (
                <>
                  <Text className="text-3xl font-bold my-5 text-grey-100">Loading...</Text>
                </>
              ) : (
                <>
                  <Text className="text-3xl font-bold my-5">
                    &#8369;{' '}
                    {commissionPending
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Text>
                </>
              )}
              <Flex className="phone:flex-col tabletWide:flex-row">
                <Text className="text-xs text-secondary-200 mx-1 my-2">
                  Amount due as of {newDate},
                </Text>
                {frequencyData.frequency === 'None' ? (
                  <>
                    <Text className="text-xs text-secondary-200 my-2">
                      (No disbursement cut-off set yet.)
                    </Text>
                  </>
                ) : (
                  <>
                    {frequencyData.frequency === 'Weekly' ? (
                      <>
                        <Text className="text-xs text-secondary-200 my-2">
                          Next disbursement is on
                          <span className="font-bold text-blue-100 mx-1">
                            {date2} at 8:00 PM PHT
                          </span>
                        </Text>
                      </>
                    ) : (
                      <></>
                    )}
                    {frequencyData.frequency === 'Bi-Monthly' ? (
                      <Text className="text-xs text-secondary-200 my-2">
                        Disbursement on
                        <span className="font-bold text-blue-100 mx-1">
                          {disbursement} at 8:00 PM PHT
                        </span>
                      </Text>
                    ) : (
                      <></>
                    )}
                    {frequencyData.frequency === 'Monthly' ? (
                      <Text className="text-xs text-secondary-200 my-2">
                        Disbursement on
                        <span className="font-bold text-blue-100 mx-1">
                          {disbursement} at 8:00 PM PHT
                        </span>
                      </Text>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Flex>
            </Div>

            <Text className="font-poppins font-semibold text-xl mt-8 mb-3 mr-auto">Summary</Text>
            <hr className="text-grey-400 mb-10" />

            <Text className="text-base text-secondary-200 font-medium mb-4 mr-auto">
              A summarized breakdown of your current gross sales and current commission due as of{' '}
              {newDate}.
            </Text>

            <Flex className="phone:h-auto phone:px-5 surfaceDuo:flex-row my-3 medium:px-10 tabletWide:px-20">
              <Text className="text-secondary-200 text-base laptop:text-xl font-medium phone:w-full surfaceDuo:w-1/2 medium:w-2/3">
                Total Gross Sales
              </Text>
              <Text className="font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 laptop:text-xl">
                {loading ? (
                  <>Loading...</>
                ) : (
                  <>
                    &#8369;{' '}
                    {totalGross
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </>
                )}
              </Text>
            </Flex>

            <Flex className="phone:h-auto phone:px-5 surfaceDuo:flex-row my-3 medium:px-10 tabletWide:px-20">
              <Text className="text-secondary-200 text-base font-medium phone:w-full surfaceDuo:w-1/2 medium:w-2/3">
                Total Affiliate Commission
              </Text>
              <Text className="font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10">
                {loading ? (
                  <>Loading...</>
                ) : (
                  <>
                    &#8369;{' '}
                    {commissionPending
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </>
                )}
              </Text>
            </Flex>

            <Flex className="phone:h-auto phone:px-5 surfaceDuo:flex-row my-3 medium:px-10 tabletWide:px-20">
              <Div className="flex flex-row items-center phone:w-full surfaceDuo:w-1/2 medium:w-2/3 font-robot font-regular">
                <span className="text-secondary-200 text-base font-medium">
                  {' '}
                  Estimated Disbursement Fee{' '}
                </span>
                <Popover className="relative">
                  <Popover.Button>
                    <i className="fas fa-question text-xs ml-1 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
                    <div className="bg-white w-64 shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-60">
                      <p className="font-semibold text-sm">
                        Actual disbursement fee is calculated based on successful disbursement.
                      </p>
                      {subscription?.status === 'FREE PLAN' ? (
                        <p>Transaction Fee = &#8369; 20</p>
                      ) : (
                        <></>
                      )}
                      {subscription?.status === 'PLUS PLAN' ? (
                        <p>Transaction Fee = &#8369; 18</p>
                      ) : (
                        <></>
                      )}
                      {subscription?.status === 'PRO PLAN' ? (
                        <p>Disbursement Fee = &#8369; 25</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Popover.Panel>
                </Popover>
              </Div>
              <Text className="font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10">
                {loading ? (
                  <>Loading...</>
                ) : (
                  <>
                    &#8369;{' '}
                    {totalTransFee
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </>
                )}
              </Text>
            </Flex>

            <Flex className="phone:h-auto phone:px-5 surfaceDuo:flex-row mt-3 medium:px-10 tabletWide:px-20">
              <Text className="text-secondary-200 text-base font-medium phone:w-full surfaceDuo:w-1/2 medium:w-2/3">
                Tax
              </Text>
              <Text className="font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10 mb-4">
                {loading ? (
                  <>Loading...</>
                ) : (
                  <>
                    &#8369;{' '}
                    {tax
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </>
                )}
              </Text>
            </Flex>

            <Flex className="phone:h-auto phone:px-5 surfaceDuo:flex-row my-3 medium:px-10 tabletWide:px-20 border-t border-grey-400 pt-4">
              <Flex className="phone:w-full surfaceDuo:w-1/2 medium:w-2/3">
                <Text className="text-secondary-200 text-base font-bold">Total Amount Due</Text>
                <Text className="text-secondary-200 text-sm font-medium">
                  (This will be debited from your account)
                </Text>
              </Flex>

              <Text className="font-bold phone:w-full surfaceDuo:w-1/2 medium:w-1/3 surfaceDuo:px-10">
                {loading ? (
                  <>Loading...</>
                ) : (
                  <>
                    &#8369;{' '}
                    {totalAmount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </>
                )}
              </Text>
            </Flex>

            <FlexRow className="w-full justify-start">
              {isViewOpen ? (
                <button
                  className="hidden w-72 py-5 bg-secondary-200 rounded-lg justify-center mx-5 mt-8 mb-1 text-white hover:bg-grey-200"
                  onClick={() => setIsViewOpen(!isViewOpen)}
                >
                  Close Table
                </button>
              ) : (
                <button
                  className="w-72 py-3 rounded-lg justify-center surfaceDuo:mx-5 bg-primary-100 text-white mb-1 mt-8 hover:bg-blue-100"
                  onClick={ViewReport}
                >
                  View Affiliate Commission Report
                </button>
              )}
            </FlexRow>

            <Div className={`w-full mt-8 tabletWide:pb-36 ${isViewOpen ? '' : 'hidden'}`}>
              <div className="w-full hidden flex-row justify-end surfaceDuo:hidden">
                <div
                  className="p-2 flex flex-row items-center hover:bg-grey-500 cursor-pointer"
                  onClick={() => setIsViewOpen(!isViewOpen)}
                >
                  <span className="hidden surfaceDuo:flex hover:underline">Close Window</span>
                  <i className="fas fa-times text-lg mx-2" />
                </div>
              </div>

              <Text className="font-poppins font-semibold text-xl my-3">
                Affiliates Commission Due Report
              </Text>
              <hr className="text-grey-400 mb-5" />

              <FlexRow className="mt-3 mb-8 phone:flex-col phone:justify-start phone:items-start medium:flex-row medium:items-center medium:justify-between text-secondary-200 tablet:hidden tabletWide:flex">
                <Text className="m-2 text-left">An overview of your affiliates' commission.</Text>
                <Link
                  className="hidden m-2 text-blue-100 text-base font-bold"
                  download
                  target="_blank"
                  to="/report.pdf"
                >
                  <i className="fas fa-download text-blue-100 mx-2" />
                  Download
                </Link>
              </FlexRow>

              <FlexRow className="mt-3 mb-8 flex-col justify-start text-secondary-200 phone:hidden tablet:flex tabletWide:hidden">
                <Text className="m-2">An overview of your affiliates' commission.</Text>
                <Link
                  className="hidden m-2 text-blue-100 text-base font-bold"
                  download
                  target="_blank"
                  to="/report.pdf"
                >
                  Download via Excel,CSV or PDF
                </Link>
              </FlexRow>

              {subscription.status === 'PRO PLAN' ? (
                <>
                  <FlexRow className="phone:flex-col surfaceDuo:flex-row surfaceDuo:justify-start surfaceDuo:items-center w-full px-2 mb-4">
                    <Checkbox
                      checked={selectedAll}
                      className="mx-2"
                      containerClass="phone:mr-auto surfaceDuo:mr-0"
                      id="check"
                      label={selectedAll ? 'Unselect Entries' : 'Select All Entries'}
                      onClick={SelectAll}
                      placeholder=""
                    />

                    <Text className="text-secondary-200 phone:mr-auto surfaceDuo:mr-0 surfaceDuo:ml-2 surfaceDuo:mb-2">
                      You have selected <b>{selectedAll ? transHistory : newList.length}</b>{' '}
                      entries.
                    </Text>
                  </FlexRow>

                  <Div className="phone:hidden medium:flex medium:flex-row tablet:hidden tabletWide:flex-row tabletWide:flex mt-5">
                    <div className="bg-white mx-2 p-3 rounded my-auto" />

                    <div
                      className="text-base text-secondary-200 font-medium w-1/5 text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                      onClick={SortAlpha}
                    >
                      Name
                      <i className="fas fa-chevron-down text-white mx-2" />
                    </div>
                    <div
                      className="text-base text-secondary-200 font-medium w-1/5 text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                      onClick={SortDate}
                    >
                      Cut-off Date
                      <i className="fas fa-chevron-down text-white mx-2" />
                    </div>
                    <div
                      className="text-base text-secondary-200 font-medium w-1/5 text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                      onClick={SortEmail}
                    >
                      Email
                      <i className="fas fa-chevron-down text-white mx-2" />
                    </div>
                    <div
                      className="text-base text-secondary-200 font-medium w-1/5 text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                      onClick={Sort}
                    >
                      Amount
                      <i className="fas fa-chevron-down text-white mx-2" />
                    </div>
                    <div className="text-base text-secondary-200 font-medium w-1/5 text-center flex items-center justify-center py-2">
                      Status
                    </div>
                  </Div>
                </>
              ) : (
                <></>
              )}

              <div>
                {transHistory === 0 && !selecting ? (
                  <>
                    <Div className="bg-grey-500 text-center py-8 mt-3 mb-10 hidden">
                      <Text>No data to be displayed for pending commissions.</Text>
                    </Div>
                  </>
                ) : (
                  <></>
                )}
                {transHistory > 0 && !selecting ? (
                  <>
                    <Div
                      className={`w-full ${
                        subscription.status === 'PRO PLAN' ? '' : 'overflow-x-scroll mb-4'
                      }`}
                    >
                      {subscription.status === 'PRO PLAN' ? (
                        <></>
                      ) : (
                        <>
                          <Div className="phone:hidden medium:flex medium:flex-row w-2200px mt-5">
                            <div
                              className="text-base text-secondary-200 font-medium medium:w-200px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                              onClick={SortAlpha}
                            >
                              Name
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div
                              className="text-base text-secondary-200 font-medium medium:w-200px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                              onClick={SortDate}
                            >
                              Cut-off Date
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div
                              className="text-base text-secondary-200 font-medium medium:w-300px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                              onClick={SortEmail}
                            >
                              Email
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div
                              className="text-base text-secondary-200 font-medium medium:w-200px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                              onClick={Sort}
                            >
                              Amount
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div
                              className="text-base text-secondary-200 font-medium medium:w-200px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                              onClick={SortBank}
                            >
                              Bank Name
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div
                              className="text-base text-secondary-200 font-medium medium:w-200px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                              onClick={SortAccName}
                            >
                              Account Name
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div
                              className="text-base text-secondary-200 font-medium medium:w-200px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2"
                              onClick={SortAccNum}
                            >
                              Account Number
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div className="text-base text-secondary-200 font-medium medium:w-300px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2">
                              Proof of Transaction
                              <i className="fas fa-chevron-down mx-2" />
                            </div>
                            <div className="text-base text-secondary-200 font-medium medium:w-200px text-center cursor-pointer rounded-full hover:bg-grey-200 hover:text-white flex items-center justify-center py-2">
                              Action
                            </div>
                            <div className="text-base text-secondary-200 font-medium medium:w-200px text-center flex items-center justify-center py-2">
                              Status
                            </div>
                          </Div>
                          <hr className="hidden medium:block text-grey-400 my-4 w-2200px" />
                        </>
                      )}
                      {currentAffiliates.map((list: any) => (
                        <CommissionData
                          accountName={list.account_name}
                          accountNum={list.account_number}
                          affiliateEmail={list.affiliate_email}
                          affiliateName={list.affiliate_name}
                          bankName={list.bank_name}
                          checked={selectedAll}
                          commissionAmt={list.earned}
                          cutOffDate={frequencyData.frequency === 'None' ? '-- --' : cutoff}
                          id={list.affiliate_id}
                          isWixUser={user?.is_wix_user}
                          key={list.affiliate_id}
                          list={newList}
                          onClick={() => Select(list)}
                          status={list.status}
                          userType={subscription.status}
                        />
                      ))}
                    </Div>
                  </>
                ) : (
                  <></>
                )}
                {selecting ? (
                  <Div className="bg-grey-500 text-center py-8 mt-3 mb-10 ">
                    <Text>
                      Loading data ...
                      <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                    </Text>
                  </Div>
                ) : (
                  <></>
                )}
              </div>

              <div className="flex justify-center h-24" id="Bottom">
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={articlesPerPage}
                  paginate={paginate}
                  totalItems={SortList.length}
                />
              </div>

              {subscription.status === 'PRO PLAN' ? (
                <FlexRow className="w-full mb-8 bg-white justify-end tabletWide:mb-0 tabletWide:w-9.25/12 laptop:w-9.5/12 tabletWide:fixed tabletWide:right-8 laptop:right-24 tabletWide:bottom-0 tabletWide:border-t tabletWide:border-grey-400 tabletWide:py-4">
                  <Div className="w-full surfaceDuo:w-auto">
                    <Text className="font-poppins text-primary-100 mb-4 laptop:text-xl">
                      TOTAL:{' '}
                      <span className="mx-3 font-bold">
                        &#8369;{' '}
                        {totalAmount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </span>
                    </Text>
                    {disburse && newList.length > 0 ? (
                      <>
                        <Button
                          className="phone:w-full surfaceDuo:w-64 justify-center laptop:text-xl"
                          isLoading={fetching}
                          onClick={onDisburse}
                        >
                          Disburse
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          className="phone:w-full surfaceDuo:w-64 justify-center bg-secondary-200 laptop:text-xl"
                          disabled
                        >
                          Disburse
                        </Button>
                      </>
                    )}
                  </Div>
                </FlexRow>
              ) : (
                <></>
              )}
            </Div>
          </Flex>
        </>
      ) : (
        <Navigate to="/merchant/dashboard" />
      )}
    </>
  );
};
