import React, { FC } from 'react';
import { classNames } from '@helpers';

interface InputProps {
  className?: string;
  props?: any;
  name?: string;
  label: string;
  labelHead1?: string;
  labelHead2?: string;
  validation?: boolean;
  errorLabel?: string;
  onChange?: any;
  value?: string;
  options: string[];
  options2: string[];
  placeholder?: string;
}

export const SelectV3: FC<InputProps> = ({
  className,
  label,
  labelHead1,
  labelHead2,
  props,
  errorLabel,
  validation,
  onChange,
  value,
  name,
  options,
  options2,
  placeholder,
}) => (
  <div className="mb-2">
    <div className="flex flex-row">
      <label
        className="block mb-2 text-lg font-poppins font-regular 
      text-primary-100"
        htmlFor={name}
      >
        {label}
      </label>
      {validation ? (
        <p className="font-poppins text-sm text-right mt-1 ml-auto text-red-100">
          {errorLabel || 'Invalid'}
        </p>
      ) : (
        <p />
      )}
    </div>
    <select
      className={classNames(
        `border ${
          validation ? 'border-red-100' : 'border-primary-100'
        } text-primary-100 font-poppins 
      text-base rounded-lg py-5 px-7 focus:ring-primary-200 focus:border-primary-200 block w-full`,
        className,
      )}
      id={name}
      name={name}
      {...props}
      onChange={(event) => onChange(event.target.value)}
      value={value}
    >
      <option value="">
        {placeholder}
      </option>

      <optgroup className='mt-3' label={labelHead1}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      </optgroup>

      <optgroup className='mt-3' label={labelHead2}>
      {options2.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      </optgroup>
    </select>
  </div>
);