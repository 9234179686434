import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../type';

const partners = (state: RootState) => state.partners;

export const selectPartnersLoading = createSelector(partners, (state) => state.loading);

export const selectPartners = createSelector(partners, (state) => state.data ?? []);

export const selectApplicationList = createSelector(partners, (state) => ({
  applicationListLoading: state.applicationListLoading,
  applicationList: state.applicationList,
}));

export const selectProductAffiliateList = createSelector(
  partners, (state) => ({
  productAffiliatesLoading: state.productAffiliateListLoading,
  productAffiliates: state.productAffiliateList,
}));

export const selectProductAffiliatePage = createSelector(
  partners, (state) => ({
  prodAffPageLoading: state.productAffiliatePageLoading,
  prodAffPage: state.productAffiliatePage,
}));

export const selectProductAffiliateSearch = createSelector(
  partners, (state) => ({
  prodAffSearchLoading: state.productAffiliateSearchLoading,
  prodAffSearch: state.productAffiliateSearch,
}));

export const selectAllPartnersAndMetadata = createSelector(
  selectPartners,
  selectPartnersLoading,
  (data = [], loading = false) => ({ data, loading }),
);

export const selectProductGraphData = createSelector(
  partners, (state) => ({
    productGraphsLoading : state.productGraphsLoading,
    productGraphs: state.productGraphs
  })
);