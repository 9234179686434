import React, { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { SubCategories } from '@constants';

interface MenuItems {
  label: string;
  value: string;
}

interface DropdownProps {
  current: string;
  className?: string;
  classNameBody?: string;
  containerClass?: string;
  color: string;
  width?: string;
  items: MenuItems[];
  onSelect?: any;
  onSelectSub?: any;
  placeholder: string;
}

export const FilterDropdownV2: FC<DropdownProps> = ({
  className, classNameBody, containerClass,
  items, width, onSelect, onSelectSub
}) => {

  const [clicked, setClicked] = React.useState(false);

  return(
  <div className={`text-right ${containerClass}`}>
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={` inline-flex items-center justify-center w-full px-5 border-0
           py-3 font-poppins text-base font-regular text-primary-100
           hover:text-blue-100 ${className}`}
        >
          Filter
          <i className="fas fa-filter text-2xl ml-1" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 mt-1 origin-top-right font-poppins
          bg-white divide-y ${width || 'w-56'} divide-secondary-300 
          rounded-md shadow-lg border border-primary-100 ${classNameBody}`}
        >
          {items.map((value: any, index: number) => (
            <details className='p-1' key={value.value}>
              <summary
                className={`py-1 px-2 list-none cursor-pointer rounded-md text-secondary-100 hover:text-white hover:bg-primary-100 ${clicked === value.value? 'bg-primary-100 text-white' : 'bg-white text-secondary-100'}`}
                onClick={() => setClicked(value.value)}>
                {value.label}
              </summary>

              <div className={`h-64 overflow-y-scroll scrollbar-hide relative ${clicked === value.value? 'bg-grey-500' : ''}`}>

              <div className='pl-3 py-2 mb-2 mt-4 text-xs text-blue-100 hover:underline font-semibold cursor-pointer bg-grey-400 sticky' onClick={() => onSelect(value.label)} title={`Search by ${value.label}`}>
                {value.label}
                <i className="fas fa-angle-right ml-2"/>
                <i className="fas fa-angle-right"/>
              </div>

              {
                SubCategories[index].map((subValue: any) => (
                  <div className="px-2 py-1 cursor-pointer" key={subValue.value} title={`Search by ${subValue.label}`}>
                    <Menu.Item
                      onClick={() => {
                        onSelect(value.label)
                        onSelectSub(subValue)
                        }}>
                      {({ active }) => (
                        <p
                          className={`${
                            active ? 'bg-primary-100 text-white' : 'text-secondary-100'
                          }  group flex rounded-md items-center
                          w-full p-1 text-base`}
                        >
                          {subValue.label}
                        </p>
                      )}
                    </Menu.Item>
                  </div>
                  ))
              }
              </div>
              
            </details>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);
};