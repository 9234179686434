import React, { FC } from 'react';
import { useNavigate, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { Div, Flex, FlexRow, Text, Button, Toast } from '@core';
import { BankData, HistoryData, Pagination } from '@components';
import { getCookie } from '@hooks';
import { API } from '@config';

export const Commissions: FC<any> = () => {

  const navigate = useNavigate();
  const token = getCookie('token');
  const user = useSelector(selectUser);
  const newDate = new Date().toString().substring(0, 33)

  const [bankDefault, setBankDefault] = React.useState<any[]>([]);
  const [bankSecondary, setBankSecondary] = React.useState<any[]>([]);

  const [loading, setLoading] = React.useState(true);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const [newComPending, setNewComPending] = React.useState('');
  const [newComReceive, setNewComReceive] = React.useState('');
  const [methods, setMethods] = React.useState(0);
  const [transHistory, setTransHistory] = React.useState(0);
  const [commissionPending, setComPending] = React.useState(0);
  const [commissionDisbursed, setComDisbursed] = React.useState(0);
  const [transactionList, setTransactionList] = React.useState<any[]>([]);

  const [comAmt] = React.useState<any>([])
  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentHistory = transactionList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    fetch(`${API}/get-payment-method`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.data.default.length !== 0) {
          setMethods(1)
        }
        setBankDefault(data.data.default)
        setBankSecondary(data.data.secondary)
      })
  }, [token])

  React.useEffect(() => {
    fetch(`${API}/commission-history`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.data.length > 0) {
          setTransHistory(1)
          setTransactionList(data.data)
          setComDisbursed(data.commission_received)
          setComPending(data.commission_pending)
        } else {
          setTransHistory(0)
          setTransactionList([])
        }
      })
  }, [token])

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  })

  const onSubmit = async () => {
    if (user?.email_confirmed) {
      navigate('/payment_method_setup')
    } else {
      setTimeout(() => {
        setToastTitle('UNVERIFIED');
        setToastDesc('Please confirm your email address.');
        setToastKind('warning');
        setToastOpen(true);
      }, 1000);
    }
  }

  const Refresh = async () => {
    setMethods(0);
    setTransHistory(0);
    setComPending(0);
    setComDisbursed(0);
    setLoading(true)
  }
  const Simulate = async () => {
    setMethods(1);
    setTransHistory(1);
    setComPending(8575.75);
    setComDisbursed(comAmt.reduce((a: any, b: any) => a + b))
  }

  React.useEffect(() => {
    setNewComPending(commissionPending.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    setNewComReceive(commissionDisbursed.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
  })

  return (
    <>
      {
        user?.user_info?.role === 'affiliate' ?
          <>
            <Toast
              description={toastDesc}
              isOpen={isToastOpen}
              onClose={() => setToastOpen(false)}
              title={toastTitle}
              toastKind={toastKind}
            />
            <Flex
              className="laptop:pl-16 laptop:pt-9 phone:pt-0 pb-16 laptop:pr-20 phone:px-5 
  overflow-y-auto pb-36"
            >
              <Text className="font-poppins font-semibold text-xl mt-8 mb-3">Overview</Text>
              <hr className="text-grey-400 mb-10" />

              <FlexRow className='phone:justify-center medium:justify-start hidden'>
                <Button className='phone:w-full surfaceDuo:w-40 justify-center m-1' onClick={Refresh}>
                  Refresh
                </Button>
                <span className='text-sm mx-4 text-blue-100 border-b-2 border-white hover:border-b-2 hover:border-blue-100 cursor-pointer' onClick={Simulate}>
                  Simulate
                </span>
              </FlexRow>

              <FlexRow className='phone:flex phone:flex-col medium:flex-row'>

                <Div className="phone:w-full medium:w-1/2 bg-grey-500 mb-5 py-3 p-2 mx-1">

                  <Text className="text-xs">
                    TOTAL COMMISSION PENDING
                  </Text>
                  {
                    loading ?
                      <>
                        <Text className="text-3xl font-bold my-5 text-grey-100">
                          Loading...
                        </Text>
                      </>
                      :
                      <>
                        <Text className="text-3xl font-bold my-5">
                          &#8369; {newComPending}
                        </Text>
                      </>
                  }
                  <Text className="text-xs text-secondary-200">
                    as of {newDate}
                  </Text>

                </Div>

                <Div className="phone:w-full medium:w-1/2 bg-grey-500 mb-5 py-3 p-2 mx-1">

                  <Text className="text-xs">
                    TOTAL COMMISSION RECEIVED
                  </Text>
                  {
                    loading ?
                      <>
                        <Text className="text-3xl font-bold my-5 text-grey-100">
                          Loading...
                        </Text>
                      </>
                      :
                      <>
                        <Text className="text-3xl font-bold my-5">
                          &#8369; {newComReceive}
                        </Text>
                      </>
                  }
                  <Text className="text-xs text-secondary-200">
                    as of {newDate}
                  </Text>

                </Div>

              </FlexRow>

              <Text className="font-poppins font-semibold text-xl mt-8 mb-3">Bank Details</Text>
              <hr className="text-grey-400 mb-10" />

              <Div className="flex phone:flex-col surfaceDuo:flex-row py-5">

                <Div className='w-full surfaceDuo:w-1/2 medium:w-1/3'>
                  <Text className='mb-5 font-semibold text-secondary-200'>Saved Accounts</Text>
                </Div>
                {
                  methods !== 0 ?
                    <>
                      <Div className='w-full'>
                        {
                          bankDefault.map((bankMethod: any) => (
                            <BankData
                              accName={bankMethod.account_name}
                              accNum={bankMethod.account_number}
                              bank={bankMethod.bank_name}
                              code={bankMethod.channel_code}
                              defaultBank='default'
                              id={bankMethod.id}
                              key={bankMethod.id}
                            />
                          ))
                        }
                        {
                          bankSecondary.map((bankMethod: any) => (
                            <BankData
                              accName={bankMethod.account_name}
                              accNum={bankMethod.account_number}
                              bank={bankMethod.bank_name}
                              code={bankMethod.channel_code}
                              defaultBank='secondary'
                              id={bankMethod.id}
                              key={bankMethod.id}
                            />
                          ))
                        }
                        {
                          (bankSecondary.length !== 1 || bankSecondary === null) ?
                            <>
                              <Div className='w-full'>
                                <Text className="text-base text-secondary-200 font-light">
                                  You have no secondary payment method saved.
                                  Click the button below to save another method to your account.
                                </Text>
                                <Button className="my-4" onClick={onSubmit}>
                                  Add Payment Method
                                </Button>
                              </Div>
                            </>
                            :
                            <>
                            </>
                        }
                      </Div>
                    </>
                    :
                    <>
                      <Div className='w-full'>
                        <Text className="text-base text-secondary-200 font-light">
                          You have no payment methods saved.
                          Click the button below to save a method to your account.
                        </Text>
                        <Button className="my-4" onClick={onSubmit}>
                          Add Payment Method
                        </Button>
                      </Div>
                    </>
                }

              </Div>


              <Text className="font-poppins font-semibold text-xl mt-8 mb-3">Commission History Report</Text>
              <hr className="text-grey-400 mb-10" />

              <Div className='hidden tablet:flex tablet:flex-row'>

                <Text
                  className="text-base text-secondary-200 font-medium w-1/5"
                >
                  Date
                </Text>
                <Text
                  className="text-base text-secondary-200 font-medium w-1/5"
                >
                  Reference No.
                </Text>
                <Text
                  className="text-base text-secondary-200 font-medium w-1/5"
                >
                  Net Amount
                </Text>
                <Text
                  className="text-base text-secondary-200 font-medium w-1/5"
                >
                  Deposited to
                </Text>
                <Text
                  className="text-base text-secondary-200 font-medium w-1/5 text-center"
                >
                  Action
                </Text>

              </Div>
              <hr className="hidden tablet:block text-grey-400 my-4" />

              {
                transHistory === 0 ?
                  <>
                    <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                      <Text>
                        No data to be displayed for transaction history.
                      </Text>
                    </Div>
                  </>
                  :
                  <>
                    <Div className='w-full'>
                      {
                        currentHistory.map((transaction: any) => (
                          <HistoryData
                            amount={transaction.amount}
                            bank={transaction.deposited_to}
                            date={transaction.date_received.toString().substring(0, 10)}
                            externalId={transaction.external_id}
                            key={transaction.transaction_id}
                            referenceNum={transaction.transaction_id}
                          />
                        ))
                      }
                    </Div>
                    <div className="flex justify-center">
                      <Pagination
                        currentPage={currentPage}
                        itemsPerPage={articlesPerPage}
                        paginate={paginate}
                        totalItems={transactionList.length}
                      />
                    </div>
                  </>
              }


            </Flex>
          </>
          :
          <Navigate to='/merchant/dashboard' />

      }

    </>
  )
};