import React, { FC } from 'react';
import { FlexRow, Div, Text } from '@core';

interface OverviewProp {
  className?: string;
  value: any;
  label: string;
  icon: string;
}

export const Overview: FC<OverviewProp> = ({ value, label, icon, className }) => (
    <Div className={`px-4 tablet:py-5 phone:py-4 bg-grey-500 rounded-2xl w-full ${className}`}>
      <FlexRow>
        <Div className="mr-2 desktop:mr-5 laptop:w-20 tablet:w-16 phone:w-16 tablet:py-5 phone:py-4 bg-transparent rounded-lg justify-center flex">
          <i className={`fas fa-${icon} desktop:text-5xl laptop:text-4xl phone:text-3xl text-orange-400`} />
        </Div>
        <Div>
          <Text className="text-primary-100 laptop:text-3xl phone:text-2xl font-bold mb-2">
            {value}
          </Text>
          <Text className="laptop:text-lg phone:text-base text-grey-100">{label}</Text>
        </Div>
      </FlexRow>
    </Div>
  );