import React from 'react';
import { FlexRow } from '../flexBox';
import { usePagination, DOTS } from '@src/hooks/usePagination';
import { classNames } from '@src/helpers';

type propsType = {
    onPageChange:Function,
    totalCount:number,
    siblingCount:number,
    currentPage:number,
    pageSize:number,
}

// eslint-disable-next-line react/prefer-exact-props
export const Pagination = (props:propsType) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  
  return (
    <FlexRow className='w-full justify-center mt-6'>
        <i className={classNames("fas fa-chevron-left text-2xl text-primary-100 hover:text-blue-100 cursor-pointer",
                currentPage !== 1 ?
                    "text-primary-100" :
                    "text-grey-400  hover:text-grey-200 pointer-events-none"
            )}
            onClick={onPrevious}
        />
        {
            paginationRange.map((pageNumber) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li className="pagination-item dots">&#8230;</li>;
                }

                // Render our Page Pills
                return (
                    <div className={classNames('font-semibold py-2 px-4 rounded-lg text-white cursor-pointer mx-1', 
                            pageNumber !== currentPage ?
                                "bg-primary-100" :
                                "bg-grey-1100"
                        )}
                        key={pageNumber}
                        onClick={() => onPageChange(pageNumber)}
                    >
                    {pageNumber}
                    </div>
                );
            })
        }
        <i
            className={classNames("fas fa-chevron-right text-2xl text-primary-100 hover:text-blue-100 cursor-pointer",
                currentPage !== lastPage ?
                    "text-primary-100" :
                    "text-grey-400  hover:text-grey-200 pointer-events-none"
            )}
            onClick={onNext}
        />
    </FlexRow>
  );
};
