import React, { FC } from 'react';
import validator from 'validator';
import { batch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usersActions, useAppDispatch } from '@redux';
import { Text, Icons, H2, Info, Div, Flex, FlexRow, Input, Button, Toast, Radio } from '@core';
import { Stage } from '@components';
import { useCookie } from '@hooks';

export const AffiliateAboutYou: FC<any> = () => {

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(search);
  const userId = query.get('id');
  const merchantId = query.get('merchant');
  const xffiliateToken: any = query.get('token');
  const [token, updateToken] = useCookie('token', '0');

  const [registerAs, setRegisterAs] = React.useState('individual');

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');
  const [socialLink, setSocialLink] = React.useState('');

  const [isCompany, setIsCompany] = React.useState(false);
  const [companyName, setCompanyName] = React.useState('');
  const [companyLink, setCompanyLink] = React.useState('');
  const [companyNumber, setCompanyNumber] = React.useState('');
  const [companyEmail, setCompanyEmail] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  React.useEffect(() => {
      if(xffiliateToken){
        updateToken(xffiliateToken, 1);
        console.log(token)
        batch(async() => {
          const data = await dispatch(usersActions.profile());
          if(data.payload){
            navigate(`/affiliate/onboard/aboutyou?id=${data.payload.data.user_id}&merchant=${merchantId}`)
            window.location.reload()
          }
        })
      }
    }, [])

  React.useEffect(() => {
    if(registerAs !== 'individual'){
      setIsCompany(true)
      setFirstName('none')
      setLastName('none')
      setContactNumber('none')
      setSocialLink('none')
      setCompanyLink('')
      setCompanyEmail('')
      setCompanyNumber('')
      setCompanyName('')
    }else{
      setIsCompany(false)
      setCompanyLink('none')
      setCompanyEmail('none')
      setCompanyNumber('none')
      setCompanyName('none')
      setFirstName('')
      setLastName('')
      setContactNumber('')
      setSocialLink('')
    }
  }, [registerAs])

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if(isCompany){
      if(parseInt(companyNumber) < 0 || regExp.test(companyNumber)){
        setCompanyNumber('')
      }
    }else if (!isCompany){
      if(parseInt(contactNumber) < 0 || regExp.test(contactNumber)){
        setContactNumber('')
      }
    }
  }, [contactNumber, companyNumber])
 
  const onSubmit = async () => {
    const batching = async () => {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newRole({
            is_company: isCompany,
            user_id: userId || '0',
            firstname: firstName,
            lastname: lastName,
            contact: contactNumber,
            social_link: socialLink,
            company_name: companyName,
            company_link: companyLink,
            company_email: companyEmail,
            contact_number: companyNumber,
            role: 'affiliate',
            store_name: 'none',
            store_link: 'none',
          }),
        );
        if (data?.payload?.status === '200' || data?.payload?.status === 200) {
          setToastTitle('Successfully Added');
          setToastDesc('Redirecting to Setup page.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
            navigate(`/affiliate/onboard/setup?id=${userId}&merchant=${merchantId}&role=affiliate`);
          }, 2000);
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    };
    if ( isCompany ) {
      if (companyName === '' || companyEmail === '' || companyLink === '' || companyNumber === '' ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      } else if (
      !validator.isURL(companyLink, {
        protocols: ['http', 'https'],
        require_host: true,
        require_protocol: true,
        })
        ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please enter a valid social link.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      } else if(parseInt(companyNumber) < 0 || companyNumber.length < 11){
      setToastTitle('Invalid Input');
      setToastDesc('Please enter a valid contact number.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);  
      } else {
        await batching();
      }
    } else if( !isCompany ){
      if (firstName === '' || lastName === '' || socialLink === '' || contactNumber === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      } else if (
      !validator.isURL(socialLink, {
        protocols: ['http', 'https'],
        require_host: true,
        require_protocol: true,
        })
        ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please enter a valid social link.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      } else if(parseInt(contactNumber) < 0 || contactNumber.length < 11){
      setToastTitle('Invalid Input');
      setToastDesc('Please enter a valid contact number.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);  
      } else {
        await batching();
      }
    }
  };

  const Next = () => navigate('/affiliate/onboard/setup')

  return (
    <Flex className="laptop:px-12 phone:px-2 overflow-y-scroll items-center">
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="phone:px-5 tablet:px-10 laptop:px-20 w-full">
        <Div className="mr-auto flex flex-row items-center py-8">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
        </Div>
        <hr className="text-secondary-300 laptop:mb-20 phone:mb-10" />
      </Div>
      <Div className="phone:px-4 pb-10 phone:w-full tablet:w-3/5 laptop:w-9/12">
        <FlexRow className="h-full phone:flex-col laptop:flex-row">
          <Flex className="mr-10 phone:mr-0 phone:items-center laptop:items-start">
            
              <>
                <H2 className="text-primary-100 phone:text-center laptop:text-left">
                  Your Links Are Everything
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center laptop:text-left tablet:pr-10"
                >
                  Links carry your brand and connect you audience in ways that no other medium can.
                  They’re kind of a big deal.
                </Info>
                <Info
                  className="text-secondary-100 mt-4 pr-10 phone:pr-0  
            phone:text-center laptop:text-left tablet:pr-10"
                >
                  You’re about to start creating powerful links. Easier-to-manage links. Branded
                  links. Xffiliate links.
                </Info>
              </>
            
          </Flex>
          <Flex className="phone:mt-10">
            <FlexRow
              className="laptop:mb-7 phone:mb-2 phone:flex-col phone:space-y-4
            tablet:flex-row items-center tablet:space-y-0 w-full phone:text-center phone:justify-center laptop:justify-start"
            >
              <Stage isCurrent label="About you" stageNumber="1" />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Select Products"
                stageNumber="2"
              />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:flex" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Complete"
                stageNumber="3"
              />
            </FlexRow>

            <Info className="text-primary-100 mb-4 mt-5">Join Xffiliate as...</Info>

            <Text className='font-semibold mx-3 text-2xl text-primary-100'>Affiliate Partner</Text>
            
            <div className='px-10 my-5'>
                <Radio
                  isChecked={registerAs === 'company'}
                  label="as Company or Company Representative"
                  name="registerAs2"
                  onClick={() => setRegisterAs('company')}
                  optionId="registerAs"
                />
                <div className='bg-white py-2' />
                <Radio
                  isChecked={registerAs === 'individual'}
                  label="as Individual"
                  name="registerAs"
                  onClick={() => setRegisterAs('individual')}
                  optionId="registerAs"
                />
            </div>

            <form>
              
                <Div className="mt-8">

                {
                  registerAs === 'individual' ?
                  <>
                    <Input
                      label="First name *"
                      name="first-name"
                      onChange={setFirstName}
                      placeholder="Enter your first name"
                      type="text"
                      value={firstName}
                    />
                    <Input
                      label="Last name *"
                      name="last-name"
                      onChange={setLastName}
                      placeholder="Enter your last name"
                      type="text"
                      value={lastName}
                    />
                    <Input
                      inputmode="numeric"
                      label="Contact Number *"
                      maxlength={11}
                      name="contact-number"
                      onChange={setContactNumber}
                      pattern="[0-9]*"
                      placeholder="Enter your contact number"
                      type="text"
                      value={contactNumber}
                    />
                    <Input
                      label="Social Link *"
                      name="social-link"
                      onChange={setSocialLink}
                      placeholder="eg: FB, Instagram, Twitter"
                      type="text"
                      value={socialLink}
                    />
                  </>
                  :
                  <>
                  <Input
                    label="Company Name *"
                    name="companyName"
                    onChange={setCompanyName}
                    placeholder="Enter your first name"
                    type="text"
                    value={companyName}
                  />
                  <Input
                    inputmode="numeric"
                    label="Company Contact Number *"
                    maxlength={11}
                    name="companyNumber"
                    onChange={setCompanyNumber}
                    pattern="[0-9]*"
                    placeholder="Enter your contact number"
                    type="text"
                    value={companyNumber}
                  />
                  <Input
                    label="Company Link *"
                    name="companylink"
                    onChange={setCompanyLink}
                    placeholder="eg: FB, Instagram, Twitter"
                    type="text"
                    value={companyLink}
                  />
                  <Input
                    label="Company Email *"
                    name="companyEmail"
                    onChange={setCompanyEmail}
                    placeholder="Enter your first name"
                    type="text"
                    value={companyEmail}
                  />
                  </>
                }

                  {/* <Select
                    label="Mode of Payment *"
                    name="mode-payment"
                    onChange={setPayment}
                    options={PaymentMethods}
                    placeholder="Select your payment method"
                    value={payment}
                  /> */}
                </Div>

              <FlexRow>
                <Button
                  className="hidden mt-5 px-14 ml-auto phone:w-full phone:justify-center"
                  onClick={Next}
                >
                  Next
                </Button>
                <Button
                  className="mt-5 px-14 ml-auto phone:w-full phone:justify-center"
                  isLoading={loading}
                  onClick={onSubmit}
                >
                  Next
                </Button>
              </FlexRow>

            </form>


          </Flex>
        </FlexRow>
      </Div>
    </Flex>
  );
};