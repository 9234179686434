import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import { productsActions, useAppDispatch, selectUser } from '@redux';
import { FlexRow, Button, Div, Text, InputV2, Toast, SelectV2, Checkbox, InputWLength, InputPrice } from '@core';
import { MainCategories, SubsCategories } from '@constants';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  product: any;
}

export const UpdateProductAdminModal: FC<ModalProps> = ({ isOpen, onClose, product }) => {

  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [name, setName] = React.useState(product?.name);
  const [link, setLink] = React.useState('');
  const [description, setDescription] = React.useState(product?.description);
  const [productPic, setProductPic] = React.useState<any>();
  const [price, setPrice] = React.useState(product?.price);
  const [category, setCategory] = React.useState(product?.category);
  const [subcategory, setSubCategory] = React.useState(product?.sub_category || '');
  const [commission, setCommission] = React.useState(product?.commission);
  const [imageValid, setImageValid] = React.useState<any>();
  const [categoryIndex, setCategoryIndex] = React.useState(10);
  const [serviceType, setServiceType] = React.useState<any>(product?.type === 'service');
  const [type, setType] = React.useState('product');
  const [assets, setAssets] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  React.useEffect(() => {
    if(productPic){
      if(productPic[0]){
        if(productPic[0].type.includes('image')){
          setImageValid(true)
        }else{
          setImageValid(false)
        }
      }
    }
  })

  React.useEffect(() => {
    if(serviceType){
      setType('service')
    }else{
      setType('product')
    }

    if(category === 'Others'){
      setSubCategory('')
    }
  })

  React.useEffect(() => {
    if(category === 'Computer & Technology'){
      setCategoryIndex(0)
    }else if(category === 'Health & Wellness'){
      setCategoryIndex(1)
    }else if(category === 'Fashion & Accessories'){
      setCategoryIndex(2)
    }else if(category === 'Clothing'){
      setCategoryIndex(3)
    }else if(category === 'Hobbies & Crafts'){
      setCategoryIndex(4)
    }else if(category === 'Sports & Fitness'){
      setCategoryIndex(5)
    }else if(category === 'Home & Appliances'){
      setCategoryIndex(6)
    }else if(category === 'Pet Supplies'){
      setCategoryIndex(7)
    }else if(category === 'Food & Beverages'){
      setCategoryIndex(8)
    }else if(category === 'Automotive'){
      setCategoryIndex(9)
    }else if(category === 'Others'){
      setCategoryIndex(10)
    }
  }, [category])

  React.useEffect(() => {
    if(product !== ''){
    setName(product?.name);
    setLink(product?.link);
    setDescription(product?.description);
    setPrice(product?.price);
    setCategory(product?.category);
    setSubCategory(product?.sub_category);
    setCommission(product?.commission);
    }
  }, [product]);

  React.useEffect(() => {
    if (!user?.email_confirmed && isOpen) {
      onClose();
      navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`);
      setTimeout(() => {
        setToastTitle('UNVERIFIED');
        setToastDesc('Please confirm your email address.');
        setToastKind('warning');
        setToastOpen(true);
      }, 1000);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if(parseFloat(price) < 0){
      setPrice('0');
      setToastTitle('Invalid Input');
      setToastDesc('Rejecting negative values');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }else if(parseFloat(commission) < 0){
      setCommission('');
      setToastTitle('Invalid Input');
      setToastDesc('Rejecting negative values');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }else if(parseFloat(price) > 500000){
      setPrice('0');
      setToastTitle('Invalid Input');
      setToastDesc('Price should not exceed PHP 500,000.');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }else if(parseFloat(commission) > 100 || commission.includes('.')){
      setCommission('');
      setToastTitle('Invalid Input');
      setToastDesc('Commission must be a whole number and should not exceed 100%.');
      setToastKind('error');
      setToastOpen(true);
      setTimeout(() => {
        setToastOpen(false)
      }, 2000)
    }
  }, [price, commission])

  React.useEffect(() => {
    if(price === ''){
      setPrice('0')
    }
  }, [price])

  const onSubmit = () => {
    if (name === '' || link === '' || description === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000)
    } else if (!imageValid && productPic) {
      setToastTitle('Invalid file type');
      setToastDesc('File must be an image type.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000)
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          productsActions.UpdateProduct({
            id: `${product?.id}`,
            name,
            description,
            link,
            productPic,
            price,
            category,
            sub_category: subcategory,
            commission,
            type,
          }),
        );

        if (data?.payload?.data) {
          setToastTitle('Product Updated');
          setToastDesc('Product is successfully updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
            onClose();
            navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=products`);
          }, 1000);
        } else {
          setToastTitle('ERROR');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
          }, 1500);
          
        }
      });
    }
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`);
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">UPDATE PRODUCT</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`);
                    }}
                  />
                </FlexRow>
                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide">
                  <Text className="font-roboto font-bold text-lg text-grey-100 ml-2">
                    Product Details
                  </Text>
                  <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                    Enter the information below.
                  </Text>
                  <InputV2
                    containerClass="mt-2"
                    label="PRODUCT NAME"
                    name="product-name"
                    onChange={setName}
                    placeholder="Enter Product Name"
                    value={name}
                  />
                  <InputV2
                    label="PRODUCT LINK"
                    name="product-link"
                    onChange={setLink}
                    placeholder="Eg: http://mystore.com/product"
                    value={link}
                  />
                  <InputWLength
                    label="DESCRIPTION"
                    maxlength={255}
                    name="product-description"
                    onChange={setDescription}
                    placeholder="Enter Product Description"
                    value={description}
                  />
                  <InputV2
                    label="PRODUCT IMAGE"
                    name="product-image"
                    onChange={setProductPic}
                    placeholder="Select Product Image"
                    type="file"
                    value={productPic?.path}
                  />
                  <InputPrice
                    currency='peso'
                    label="PRICE"
                    name="product-price"
                    onChange={setPrice}
                    placeholder="Enter Product Price"
                    value={price}
                  />
                  <SelectV2
                    className='px-2'
                    label="MAIN CATEGORY"
                    name="category"
                    onChange={setCategory}
                    options={MainCategories}
                    placeholder="Select a Main Category"
                    value={category}
                  />
                  <SelectV2
                    className='px-2'
                    containerClass={category === 'Others'? 'hidden' : ''}
                    label="SUB CATEGORY"
                    name="category"
                    onChange={setSubCategory}
                    options={SubsCategories[categoryIndex]}
                    placeholder="Select a Sub-Category"
                    value={subcategory}
                  />
                  <InputV2
                    label="COMMISSION (%)"
                    name="product-commission"
                    onChange={setCommission}
                    pattern="[0-9]*"
                    placeholder="Enter Commission"
                    type="number"
                    value={commission}
                  />

                  <Checkbox
                    checked={serviceType}
                    className=''
                    containerClass="mt-4 hidden"
                    id='type'
                    label='This is a service-type product'
                    onClick={() => setServiceType(!serviceType)}
                  />

                  <details className='hidden'>
                    <summary className='text-blue-100 font-semibold hover:underline list-none mb-4 cursor-pointer'>
                        FAQs | Product Types
                        <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                    </summary>

                    <div className="my-4 p-2">
                      <Text className='font-semibold text-secondary-200 text-sm'>
                        Product type
                      </Text>
                      <hr className='text-primary-100 my-1'/>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        This type includes all tangible products, perceptible by touch, 
                        and in material form which earn revenue through purchases., 
                        ie. <i>pencil, coffee, IPhone X</i>
                      </Text>
                      <Text className='font-semibold text-secondary-200 text-sm mt-4'>
                        Service type
                      </Text>
                      <hr className='text-primary-100 my-1'/>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        This type includes all intangible products which are 
                        in the form of digital like software applications that 
                        earn revenue through subscriptions, 
                        memberships and any kind of digital purchase.
                      </Text>
                    </div>
                  </details>

                  <Checkbox
                    checked={assets}
                    className=''
                    containerClass="mt-4 hidden"
                    id='assets'
                    label='Enable Marketing assets'
                    onClick={() => setAssets(!assets)}
                  />

                  <details className='hidden'>
                    <summary className='text-blue-100 font-semibold hover:underline list-none cursor-pointer'>
                        FAQs | Marketing Assets
                        <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                    </summary>

                    <div className='p-2 my-4'>
                      <Text className='font-semibold text-secondary-200 text-sm'>
                        Marketing Assets
                      </Text>
                      <hr className='text-primary-100 my-1'/>
                      <Text className='text-sm text-secondary-200 mt-4'>
                        Improve the product's recognition using 
                        customized page contents and insights
                      </Text>
                    </div>

                    <div className="phone:w-48 mobile:w-80 h-72 overflow-y-scroll my-4 p-2">
                      <img 
                        alt='Post Sample'
                        className='my-4 p-4 object-contain border border-grey-400'
                        src={'https://scontent.fmnl17-4.fna.fbcdn.net/v/t1.15752-9/305168582_779752936688798_3883111251734515176_n.png?_nc_cat=105&ccb=1-7&_nc_sid=ae9488&_nc_ohc=CD5MS1nq-PEAX_yEjkN&_nc_ht=scontent.fmnl17-4.fna&oh=03_AVIyhVfuDPG9ugZrcPgYXUF1hnAp25-rpLAEZ_0670JjOg&oe=633E9FB3' || 'https://i.imgur.com/CGDGnf6.png'}
                      />
                      <a className='hover:underline text-blue-100 mb-4' href='https://scontent.fmnl17-4.fna.fbcdn.net/v/t1.15752-9/305168582_779752936688798_3883111251734515176_n.png?_nc_cat=105&ccb=1-7&_nc_sid=ae9488&_nc_ohc=CD5MS1nq-PEAX_yEjkN&_nc_ht=scontent.fmnl17-4.fna&oh=03_AVIyhVfuDPG9ugZrcPgYXUF1hnAp25-rpLAEZ_0670JjOg&oe=633E9FB3' rel="noopener noreferrer" target='_blank'>
                        View Full Image
                      </a>
                      <img 
                        alt='Post Sample'
                        className='my-4 p-4 object-contain border border-grey-400'
                        src={'https://scontent.fmnl17-2.fna.fbcdn.net/v/t1.15752-9/299232212_775052760274647_3104936666937478268_n.png?_nc_cat=109&ccb=1-7&_nc_sid=ae9488&_nc_ohc=dXdaBCVrhKsAX-qKZf5&_nc_ht=scontent.fmnl17-2.fna&oh=03_AVLdZd6Z-b014WpRWh8gxJhNvpeM2Y7E_WKmEADe4PQN8w&oe=633DEA08' || 'https://i.imgur.com/CGDGnf6.png'}
                      />
                      <a className='hover:underline text-blue-100 mb-4' href='https://scontent.fmnl17-2.fna.fbcdn.net/v/t1.15752-9/299232212_775052760274647_3104936666937478268_n.png?_nc_cat=109&ccb=1-7&_nc_sid=ae9488&_nc_ohc=dXdaBCVrhKsAX-qKZf5&_nc_ht=scontent.fmnl17-2.fna&oh=03_AVLdZd6Z-b014WpRWh8gxJhNvpeM2Y7E_WKmEADe4PQN8w&oe=633DEA08' rel="noopener noreferrer" target='_blank'>
                        View Full Image
                      </a>
                    </div>

                     unused {type}
                  </details>

                  <FlexRow
                    className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      Be sure to complete all fields to update the product.
                    </Text>
                  </FlexRow>
                  <Div className="h-20 w-2" />
                </div>
                <hr />
                <div className=" flex  mx-8 my-5">
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    UPDATE PRODUCT
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};