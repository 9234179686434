/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { FC } from 'react';
import { Transition, Popover } from '@headlessui/react';
import { batch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie, Pie } from '@nivo/pie';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import { Overview } from '../Merchant';
import { TopProductData } from './TopProductData';
import { Div, Flex, FlexCenter, FlexRow, Text, Select } from '@src/core';
import { useAppDispatch, usersActions, partnersActions, selectProducts, selectMerchantAnalyticsProducts, selectOverallGraphAffData } from '@src/redux';
import { formatCount } from '@src/helpers/formatNumber';
import { radarTest, radarKey, radarEmpty, affiliatesD } from '@src/constants/TempData';
import { useWindowSize } from '@src/hooks';
import { getAffiliateAnalytics, getPartnerAffiliates } from '@services';
import { ChartsAffiliate } from '@constants';

const radTheme = {
  // "background": "#F5F6F7",
  textColor: '#9C9C9C',
  fontFamily: 'Poppins',
  fontSize: 12,
  legends: {
    text: {
      fontSize: 14,
      fill: '#333333',
    },
  },
};

const barTheme = {
  // "background": "#F5F6F7",
  textColor: '#F5F6F7',
  fontFamily: 'Poppins',
  fontSize: 12,
  legends: {
    text: {
      fontSize: 14,
      fill: '#333333',
    },
  },
  axis: {
    ticks: {
      text: {
        fill: '#9C9C9C',
      },
    },
  },
};

const colors = ['#3173AF', '#FF733F', '#FBDD8C', '#F37777', '#63C9A8'];

export const AffiliateAnalytics: FC<any> = ({
  clicks, conversions, earnings, mobile, setPartner
}) => {

  const titles = ['Clicks', 'Conversions', 'Earnings', 'Loading ...'];
  const color = ['#FF6C3E','#3173AF', '#42B963', '#3173AF']
  const [graphPage, setGraphPage] = React.useState(4);
  const [graphTitle, setTitle] = React.useState(titles[3]);
  const [selectedChart, setSelectedChart] = React.useState<any>('');

  const graphs = useSelector(selectOverallGraphAffData);
  const graphAnalytics = useSelector(selectMerchantAnalyticsProducts);
  const products: any = useSelector(selectProducts);
  const [topProducts, setTopProducts] = React.useState<any>(products);

  const [isShow, setIsShow] = React.useState(true);
  const hasTooltip: boolean = false;

  const dispatch = useAppDispatch();
  const dimensions = useWindowSize();

  React.useEffect(() => {
    batch(async() => {
      await dispatch(usersActions.overallAnalyticsAffiliate());
      setGraphPage(1)
      setTitle(titles[0])
    })
  },[])

  React.useEffect(() => {
    if(selectedChart === 'Clicks'){
      setGraphPage(1)
      setTitle(titles[0])
    }else if(selectedChart === 'Conversions'){
      setGraphPage(2)
      setTitle(titles[1])
    }else if(selectedChart === 'Earnings'){
      setGraphPage(3)
      setTitle(titles[2])
    }
  }, [selectedChart])

  const clicktheme = {
    textColor: '#9C9C9C',
    fontFamily: 'Poppins',
    fontSize: 14,
    legends: {
      title: {
        text: {
          fontSize: 14,
          fill: '#333333',
        },
      },
      text: {
        fontSize: 14,
        fill: '#333333',
      },
      ticks: {
        line: {},
        text: {
          fontSize: 14,
          fill: '#333333',
        },
      },
    },
  };

  const commonProperties = {
    height: 500,
    margin: { top: 50, right: 50, bottom: 75, left: 50 },
  };

  const commonPropertiesA = {
    height: dimensions.width > 520 ? 300 : 200,
    margin: { top: 50, right: 20, bottom: 10, left: 20 },
  };

  const commonPropertiesB = {
    height: 300,
    margin: { top: 20, right: 20, bottom: 50, left: 90 },
  };

  function convertGraphData(data: any[]) {
    const convData: any = [];
    data.map((entry) => {
      const clickdates: any = []
      clickdates.push(entry.date_created)
      return convData.push({
        x: entry.date_created,
        y: entry.daily_clicks,
      });
    });
    return convData;
  }

  function convertGraphDataA(data: any[]) {
    const convData: any = [];
    data.map((entry) => {
      const clickdates: any = []
      clickdates.push(entry.date_created)
      return convData.push({
        x: entry.date_created,
        y: entry.daily_conversions,
      });
    });
    return convData;
  }

  function convertGraphDataB(data: any[]) {
    const convData: any = [];
    data.map((entry) => {
      const clickdates: any = []
      clickdates.push(entry.date_created)
      return convData.push({
        x: entry.date_created,
        y: entry.daily_commission,
      });
    });
    return convData;
  }

  const clicksgraph: any = convertGraphData(graphs.overallGraphsData.data.dailyClicks);
  const conversionsgraph: any = convertGraphDataA(graphs.overallGraphsData.data.dailyConversion);
  const earningsgraph: any = convertGraphDataB(graphs.overallGraphsData.data.dailyCommission);

  const [productList, setProductList] = React.useState<any[]>([]);
  const [productList1, setProductList1] = React.useState<any[]>([]);
  const [productClicks, setProductClicks] = React.useState<any[]>([]);
  const [productConversions, setProductConversions] = React.useState<any[]>([]);

  const [dataClicks, setDataClicks] = React.useState(0);
  const [dataConversions, setDataConversions] = React.useState(0);

  const onSort = (e:any) => {
    const list: any[] = [...products];
    if (e === 'earnings'){
      setIsShow(false)
      setTopProducts(list.sort((a, b) => b.earnings - a.earnings ))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'clicks'){
      setIsShow(false)
      setTopProducts(list.sort((a, b) => b.total_cliked - a.total_cliked ))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'conversions'){
      setIsShow(false)
      setTopProducts(list.sort((a, b) => b.conversion - a.conversion))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'reset'){
      setIsShow(false)
      setTopProducts(products)
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    }
  }

  React.useEffect(() => {
    batch(async () => {
      const tempList: any = [...products]
                            .sort((a:any, b:any) => b.total_cliked - a.total_cliked )
                            .splice(0,5);
      const filterProducts: any = [];
      for (let i = 0; i < tempList.length; i++) {
        filterProducts.push({
          label: tempList[i].name,
          color: colors[i],
          value: tempList[i].total_cliked,
        });
        if (i === tempList.length - 1) {
          setProductList(filterProducts);
        }
        if(i === colors.length - 1){
          colors.push('#3173AF', '#FF733F', '#FBDD8C', '#F37777', '#63C9A8')
        }
      }

      const tempList1: any = [...products]
                            .sort((a:any, b:any) => b.conversion - a.conversion )
                            .splice(0,5);
      const filterProducts1: any = [];
      for (let i = 0; i < tempList1.length; i++) {
        filterProducts1.push({
          label: tempList1[i].name,
          color: colors[i],
          value: tempList1[i].conversion,
        });
        if (i === tempList1.length - 1) {
          setProductList1(filterProducts1);
        }
        if(i === colors.length - 1){
          colors.push('#3173AF', '#FF733F', '#FBDD8C', '#F37777', '#63C9A8')
        }
      }

      const filterProducts2: any = [];
      const clicksSum: any = [];
      for (let i = 0; i < products.length; i++) {
        filterProducts2.push({
          id: `ID#${products[i].product_id}: ${products[i].name}`,
          label: products[i].name,
          value: products[i].total_cliked
        });
        clicksSum.push(products[i].total_cliked)
        
        if (i === products.length - 1) {
          setProductClicks(
            filterProducts2.sort((a:any, b:any) => b.value - a.value ).splice(0,5)
          );
          setDataClicks(
            clicksSum.reduce((partialSum: number, a: number) => partialSum + a, 0)
          );
        }
      }

      const filterProducts3: any = [];
      const conversionSum: any = [];
      for (let i = 0; i < products.length; i++) {
        filterProducts3.push({
          id: `ID#${products[i].product_id}: ${products[i].name}`,
          label: products[i].name,
          value: products[i].conversion
        });
        conversionSum.push(products[i].conversion)
        
        if (i === products.length - 1) {
          setProductConversions(
            filterProducts3.sort((a:any, b:any) => b.value - a.value ).splice(0,5)
          );
          setDataConversions(
            conversionSum.reduce((partialSum: number, a: number) => partialSum + a, 0)
          );
        }
      }

      setTopProducts(products)
      onSort('clicks')
    });
  }, [products]);

  return (
    <Div className="py-14 surfaceDuo:px-5 tablet:px-10 w-full">
      <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100 laptop:px-20">
        OVERVIEW
      </Text>
      <FlexRow
        className="mt-5 laptop:flex-row phone:flex-col laptop:space-x-5 phone:space-y-5 
        laptop:space-y-0 phone:space-x-0 laptop:px-20"
      >
        <Overview icon="mouse-pointer" label="TOTAL CLICKS GENERATED" value={clicks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
        <Overview icon="repeat" label="TOTAL CONVERSIONS" value={conversions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
        <Overview icon="money-bill" label="TOTAL EARNINGS" value={<>&#8369; {earnings.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</>} />
      </FlexRow>
      <Text className="font-poppins font-bold phone:text-xl tablet:text-2xl text-primary-100 mt-8 mb-4 laptop:px-20">
        PERFORMANCE
      </Text>

      {/* Hidden Component */}
      <FlexRow className="pb-8 w-full justify-center phone:flex-col laptop:flex-row laptop:px-20 hidden">
        <Div className="h-96 my-2 w-full laptop:w-1/2 laptop:mx-2">
        <Transition
          appear
          className="w-full h-full bg-grey-500 rounded-md"
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          show
        >
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline my-2 ml-4'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Legends :
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-52 shadow-xl p-3 rounded-lg border-2 border-grey-400 h-60 overflow-y-scroll scrollbar-hide">
                      <Text className='text-primary-100 text-sm mb-2'>
                        Total: {products.length}
                      </Text>
                      {
                        productList.map((product: any) => (
                          <div className='text-secondary-200 text-sm' key={product.label}>
                            <i className='fas fa-circle pr-2 text-xs' style={{color: product.color}} />
                            {product.label}
                          </div>
                          ))
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <ResponsiveRadar
            colors={colors}
            {...commonPropertiesA}
            curve="cardinalClosed"
            data={graphAnalytics.data}
            dotSize={8}
            gridLabelOffset={dimensions.width > 425 ? 30 : 5}
            indexBy="stat"
            isInteractive={false}
            keys={graphAnalytics.products}
            legends={[]}
            theme={radTheme}
          />
        </Transition>
        </Div>
        <Div className="h-96 my-2 w-full laptop:w-1/2 laptop:mx-2">
        <Transition
          appear
          className="w-full h-full bg-grey-500 rounded-md"
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          show
        >
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline my-2 ml-4'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Legends :
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-52 shadow-xl p-3 rounded-lg border-2 border-grey-400 h-60 overflow-y-scroll scrollbar-hide">
                      <Text className='text-primary-100 text-sm mb-2'>
                        Total: {products.length}
                      </Text>
                      {
                        productList.map((product: any) => (
                          <div className='text-secondary-200 text-sm' key={product.label}>
                            <i className='fas fa-circle pr-2 text-xs' style={{color: product.color}} />
                            {product.label}
                          </div>
                          ))
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <ResponsiveBar
            axisBottom={{
                  tickPadding: 10,
                  tickRotation: 67,
                  tickValues: dimensions.width > 1000 ? 12 : 5,
            }}
            colors={colors}
            {...commonPropertiesB}
            data={graphAnalytics.data}
            enableGridX
            enableGridY={false}
            indexBy="stat"
            keys={graphAnalytics.products}
            layout="horizontal"
            padding={0.4}
            theme={barTheme}
          />
        </Transition>
        </Div>
      </FlexRow>

      <FlexRow className="pb-8 w-full justify-center phone:flex-col laptop:flex-row laptop:px-20">
        <Div className="w-full h-96 my-8 laptop:w-1/2 laptop:ml-2">
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline mb-2'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Top Performing Products per Click:
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-72 shadow-xl p-3 rounded-lg border-2 border-grey-400">
                      {
                        productList.length > 0?
                        <></>
                        :
                        <Text className='text-primary-100 text-sm my-2'>
                          No data for top products per click.
                        </Text>
                      }
                      {
                        productList.map((product: any) => (
                          <FlexRow className='text-secondary-200 text-sm my-1' key={product.label}>
                            <i className='fas fa-circle pr-2 text-xs w-1/12' style={{color: product.color}} />
                            <span className='w-11/12'>
                              {product.label} <b className='mx-1 underline hidden'>{product.value}</b>
                            </span>
                          </FlexRow>
                          ))
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <Transition
            appear
            className="w-full h-full bg-grey-500 rounded-md"
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            show
          >
            {
              dataClicks > 0?
              <FlexRow className='w-full h-full justify-center items-center'>
              <Pie
                  activeInnerRadiusOffset={8}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [
                          [
                              'brighter',
                              2
                          ]
                      ]
                  }}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  colors={colors}
                  cornerRadius={3}
                  data={productClicks}
                  enableArcLinkLabels={false}
                  height={300}
                  innerRadius={0.7}
                  padAngle={0.7}
                  width={300}
              />
              </FlexRow>
              :
              <FlexRow className='w-full h-300px justify-center items-center text-secondary-200'>
                No data available at this time.
              </FlexRow>
            }
          </Transition>
        </Div>
        <Div className="w-full h-96 my-8 laptop:w-1/2 laptop:ml-2">
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline mb-2'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Top Performing Products per Conversion:
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-72 shadow-xl p-3 rounded-lg border-2 border-grey-400">
                      {
                        productList1.length > 0?
                        <></>
                        :
                        <Text className='text-primary-100 text-sm my-2'>
                          No data for top products per conversion.
                        </Text>
                      }
                      {
                        productList1.map((product: any) => (
                          <FlexRow className='text-secondary-200 text-sm my-1' key={product.label}>
                            <i className='fas fa-circle pr-2 text-xs w-1/12' style={{color: product.color}} />
                            <span className='w-11/12'>
                              {product.label} <b className='mx-1 underline hidden'>{product.value}</b>
                            </span>
                          </FlexRow>
                          ))
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <Transition
            appear
            className="w-full h-full bg-grey-500 rounded-md"
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            show
          >
            {
              dataConversions > 0?
              <FlexRow className='w-full h-full justify-center items-center'>
              <Pie
                  activeInnerRadiusOffset={8}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [
                          [
                              'brighter',
                              2
                          ]
                      ]
                  }}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  colors={colors}
                  cornerRadius={3}
                  data={productConversions}
                  enableArcLinkLabels={false}
                  height={300}
                  innerRadius={0.7}
                  padAngle={0.7}
                  width={300}
              />
              </FlexRow>
              :
              <FlexRow className='w-full h-300px justify-center items-center text-secondary-200'>
                No data available at this time.
              </FlexRow>
            }
          </Transition>
        </Div>
      </FlexRow>

      <FlexRow className='phone:flex-col phone:items-start laptop:flex-row laptop:items-center laptop:justify-between w-full phone:px-4 tablet:px-10 laptop:px-20 hidden'>
        <FlexRow className='phone:flex-col surfaceDuo:flex-row pr-4 pl-4 surfaceDuo:pl-0 surfaceDuo:items-center'>
          <Text className='text-primary-100 font-semibold mr-auto surfaceDuo:mr-4'>
            Chart:
          </Text>
          <Select
            className='phone:py-4 surfaceDuo:w-72 laptop:w-auto'
            label=""
            name="selectedMethod"
            onChange={setSelectedChart}
            options={ChartsAffiliate}
            placeholder="Choose..."
            value={selectedChart}
          />
        </FlexRow>
      </FlexRow>

      <Text className="font-poppins font-semibold text-lg text-primary-100 text-center my-8 laptop:px-20">
        {graphTitle}
      </Text>
      <FlexRow className="h-96 justify-center laptop:px-20">
        <button
          className="bg-grey-900 h-40 mobile:px-2 text-primary-100 disabled:text-grey-100"
          disabled={graphPage === 1}
          onClick={() => {
            if (graphPage > 1) {
              setGraphPage(graphPage - 1);
              setTitle(titles[graphPage - 2]);
            }
          }}
        >
          <i className="fas fa-angle-left phone:text-2xl tablet:text-4xl" />
        </button>
        <Transition
          appear
          className="w-full h-full"
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={graphPage === 1}
        >
          {
            clicksgraph.length > 0 ? 
            <AffLineGraph
              color={color[graphPage-1]}
              data={[
                  {
                      id: 'Clicks',
                      data: clicksgraph,
                  },
              ]}
              titles={[]}
            />
            :
            <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
              <Text className='text-center text-xs surfaceDuo:text-sm tabletWide:text-base'>
                No data recorded at this time.
              </Text>
              <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                <Text>10 -</Text>
                <Text>8 -</Text>
                <Text>6 -</Text>
                <Text>4 -</Text>
                <Text>2 -</Text>
                <Text>0 -</Text>
              </Flex>
            </FlexRow>
          }
        </Transition>
        <Transition
          appear
          className="w-full h-full"
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={graphPage === 2}
        >
          
          {
            conversionsgraph.length > 0 ?
            <AffLineGraph
              color={color[graphPage-1]}
              data={[
                  {
                      id: 'Conversions',
                      data: conversionsgraph,
                  },
              ]}
              titles={[]}
            />
            :
            <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
              <Text className='text-center text-xs surfaceDuo:text-sm tabletWide:text-base'>
                No data recorded at this time.
              </Text>
              <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                <Text>10 -</Text>
                <Text>8 -</Text>
                <Text>6 -</Text>
                <Text>4 -</Text>
                <Text>2 -</Text>
                <Text>0 -</Text>
              </Flex>
            </FlexRow>
          }
        </Transition>
        <Transition
          appear
          className="w-full h-full"
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={graphPage === 3}
        >
          {
            earningsgraph.length > 0 ?
            <AffLineGraph
              color={color[graphPage-1]}
              data={[
                  {
                      id: 'Earnings',
                      data: earningsgraph,
                  },
              ]}
              titles={[]}
            />
            :
            <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
              <Text className='text-center text-xs surfaceDuo:text-sm tabletWide:text-base'>
                No data recorded at this time.
              </Text>
              <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                <Text>10 -</Text>
                <Text>8 -</Text>
                <Text>6 -</Text>
                <Text>4 -</Text>
                <Text>2 -</Text>
                <Text>0 -</Text>
              </Flex>
            </FlexRow>
          }
        </Transition>
        <Transition
          appear
          className="w-full h-full"
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={graphPage === 4}
        >
          <div className='h-96'>
            <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative flex-col'>
              <Text className='text-center'>
                Loading data ...
                <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
              </Text>
              <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                <Text>10 -</Text>
                <Text>8 -</Text>
                <Text>6 -</Text>
                <Text>4 -</Text>
                <Text>2 -</Text>
                <Text>0 -</Text>
              </Flex>
            </FlexRow>
          </div>
        </Transition>
        <button
          className="bg-grey-900 h-40 mobile:px-2 text-primary-100 disabled:text-grey-100"
          disabled={graphPage === 3}
          onClick={() => {
            if (graphPage < 3) {
              setGraphPage(graphPage + 1);
              setTitle(titles[graphPage]);
            }
          }}
        >
          <i className="fas fa-angle-right phone:text-2xl tablet:text-4xl" />
        </button>
      </FlexRow>

      <Text className='text-secondary-200 text-xs mt-2 mb-8 gap-x-2 laptop:px-20'>
        * Initial loaded records shown are based by 90-day timeline.
      </Text>

      <FlexRow className="grid grid-cols-7 gap-x-2 laptop:px-20">
        <Text className="col-span-7 font-poppins font-bold text-primary-100 phone:text-xl tablet:text-2xl mb-4">
          TOP PERFORMING PRODUCTS
        </Text>
      </FlexRow>

      <div className='flex flex-row items-center phone:w-full surfaceDuo:w-auto mt-4 mb-8 laptop:px-20'>
      <label className='hidden medium:flex mx-3' htmlFor='sort'>Sort by:</label>
      <select className='rounded-lg phone:w-full surfaceDuo:w-56 shadow-xl border-2 border-primary-100' defaultValue="" name="sort" onChange={(e:any) => onSort(e.target.value)}>
          <option value="">SORT</option>
          <option value="clicks">Top Clicks</option>
          <option value="conversions">Top Conversions</option>
          <option value="earnings">Top Earnings</option>
          <option value="clicks">-- RESET --</option>
          
      </select>
      </div>

      {
        products.length > 0 && isShow ?
        <div className='w-full laptop:px-20'>
        {
          topProducts.slice(0, 3).map((item:any, index: number) => (
            <TopProductData
              categoryCommission={item.category_commission}
              clicks={item.total_cliked}
              commission={item.commission}
              conversions={item.conversion}
              earnings={item.earnings === null ? 0 : item.earnings}
              id={item.id}
              image={item.image}
              index={index}
              key={index}
              name={item.name}
            />
            ))
        }
        </div>
        :
        <div className="py-10 mt-4 bg-grey-500 laptop:mx-20">
        {
          !isShow?
          <Text className='text-center'>
            Loading data ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
          :
          <Text className='text-center'>Currently no affiliates at this time.</Text>
        }

        </div>
      }

    </Div>
  );
};

interface LineGraphInputs {
  titles: string[];
  data: any[];
  color: string;
}

const AffLineGraph: FC<LineGraphInputs> = ({ data, color }) => {

  const dimensions = useWindowSize();

  const theme = {
    textColor: '#9C9C9C',
    fontFamily: 'Poppins',
    fontSize: 14,
    legends: {
      title: {
        text: {
          fontSize: 14,
          fill: '#333333',
        },
      },
      text: {
        fontSize: 14,
        fill: '#333333',
      },
      ticks: {
        line: {},
        text: {
          fontSize: 14,
          fill: '#333333',
        },
      },
    },
  };

  const commonProperties = {
    height: 350,
    margin: { top: 50, right: 50, bottom: 75, left: 50 },
  };

  return (
    <ResponsiveLine
               axisBottom={{
                format: '%b %d',
                tickPadding: 5,
                tickRotation: 67,
                tickValues: dimensions.width > 1000 ? 12 : 5,
               }}
               axisLeft={{
                 tickValues: 5,
                 tickPadding: 10,
                 format: (text) => Number(text),
               }}
               {...commonProperties}
               colors={color}
               curve="monotoneX"
               data={data}
               enableArea
               enableGridX={false}
               enableGridY={false}
               enableSlices="x"
               legends={[
                 {
                   anchor: 'top-left',
                   direction: 'row',
                   justify: false,
                   translateY: -40,
                   itemDirection: 'left-to-right',
                   itemWidth: 140,
                   itemHeight: 20,
                   symbolSize: 12,
                   symbolShape: 'square',
                   effects: [
                     {
                       on: 'hover',
                       style: {
                         itemBackground: 'rgba(0, 0, 0, .03)',
                         itemOpacity: 1,
                       },
                     },
                   ],
                   toggleSerie: false,
                 },
               ]}
               sliceTooltip={
                 // eslint-disable-next-line react/no-unstable-nested-components
                 ({ slice }) => (
                   <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl">
                     {slice.points.map((point, i) => (
                       <Div key={point.id}>
                         <FlexCenter className="text-xs font-medium">
                           {i == 0 ? moment(point.data.xFormatted).format('MMMM Do YYYY') : ''}
                         </FlexCenter>
                         <Div className="flex flex-row pt-1">
                           <Flex className="text-grey-700 text-sm">{point.serieId}</Flex>
                           <Flex className="text-right text-sm font-medium">
                             {point.data.y.toLocaleString()}
                           </Flex>
                         </Div>
                       </Div>
                     ))}
                   </Div>
                 )
               }
               theme={theme}
               xFormat="time:%m/%d/%Y"
               xScale={{
                   type: 'time',
                   format: '%m/%d/%Y',
               }}
               yScale={{
                 type: 'linear',
               }}
           />
  );
};