import React, { FC } from 'react';
import CurrencyInput from 'react-currency-input-field';

interface InputProps {
  className?: string;
  props?: any;
  placeholder?: string;
  label?: string;
  containerClass?: string;
  onChange?: any;
  value?: string;
  name?: string;
}

export const MoneyInput: FC<InputProps> = ({
  className,
  onChange,
  name,
  label,
  value,
  placeholder,
}) => (
  <div className="group w-full">
    <div
      className={`border w-full bg-white text-primary-100 font-poppins text-base
    rounded-lg ml-auto flex flex-row py-2 px-7 items-center overflow-hidden 
    group-focus:bg-primary-100 ${className}`}
    >
      <p className="font-poppins text-primary-100">{label}</p>
      <CurrencyInput
        className={`bg-white text-primary-100 focus:border-4
      py-3.5 ml-3 w-full outline-none border-none block focus:outline-none border-transparent 
      focus:border-transparent focus:ring-0`}
        decimalsLimit={2}
        defaultValue={1000}
        name={name}
        onValueChange={(target) => onChange(target)}
        placeholder={placeholder}
        value={value}
      />
    </div>
  </div>
);
