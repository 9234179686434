import React, { FC } from 'react';
import { batch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usersActions, useAppDispatch } from '@redux';
import {
  Icons,
  Text,
  H2,
  Info,
  Div,
  Flex,
  FlexRow,
  Checkbox,
  Button,
  MoneyInput,
  Toast,
  Input,
} from '@core';
import { Stage } from '@components';
import { Using1, Using2 } from '@constants';

export const Setup: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = React.useState('');
  const [companyDesc, setCompanyDesc] = React.useState('');
  const [companyImage, setCompanyImage] = React.useState<any>('');
  const [min, setMin] = React.useState('');
  const [max, setMax] = React.useState('');
  const [purpose, setPurpose] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const query = new URLSearchParams(search);
  const userId = query.get('id');
  const role = query.get('role');

  const onSubmit = async () => {
    const batching = async () => {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newPurpose({
            user_id: userId || '0',
            min_budget: min,
            max_budget: max,
            purpose_xffiliate_links: purpose,
            icon: companyImage,
            name: companyName,
            description: companyDesc,
          }),
        );
        if (data?.payload?.status === 200) {
          setToastTitle('Successfully Added');
          setToastDesc('Redirecting to Complete page.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
            navigate(`/onboard/complete?id=${userId}&role=${role}`);
          }, 2000);
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    };
    if (
      min === '' ||
      max === '' ||
      purpose === '' ||
      companyName === '' ||
      companyDesc === '' ||
      companyImage === ''
    ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if (parseInt(min) >= parseInt(max)) {
      setToastTitle('Invalid Input');
      setToastDesc('Max Budget should be greater than minimum budget.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      await batching();
    }
  };

  return (
    <Flex className="px-12 pb-10 phone:px-2 overflow-y-scroll items-center">
      <Div className="phone:px-5 tablet:px-10 laptop:px-20 w-full">
        <Div className="mr-auto flex flex-row items-center py-8">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
        </Div>
        <hr className="text-secondary-300 laptop:mb-20 phone:mb-10" />
      </Div>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="phone:px-4 phone:w-full tablet:w-6/12 laptop:w-9/12">
        <FlexRow className="h-full phone:flex-col laptop:flex-row">
          <Flex className="mr-10 phone:mr-0 phone:items-center tablet:items-start">
            {role === 'merchant' ? (
              <>
                <H2 className="text-primary-100 phone:text-center tablet:text-left">
                  Monetize Your Network
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Grow sales by creating and enabling your affiliate network to earn commissions by
                  sharing your store and product links.
                </Info>
              </>
            ) : (
              <>
                <H2 className="text-primary-100 phone:text-center tablet:text-left">
                  Your Links Are Everything
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Links carry your brand and connect you audience in ways that no other medium can.
                  They’re kind of a big deal.
                </Info>
                <Info
                  className="text-secondary-100 mt-4 pr-10 phone:pr-0  
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  You’re about to start creating powerful links. Easier-to-manage links. Branded
                  links. Xffiliate links.
                </Info>
              </>
            )}
          </Flex>
          <Flex className="phone:mt-10">
            <FlexRow
              className="items-center mb-7 phone:flex-col phone:items-start phone:space-y-4
            tablet:flex-row tablet:items-center tablet:space-y-0"
            >
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent
                label="About you"
                stageNumber="1"
              />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage isCurrent label="Setup" stageNumber="2" />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent={false}
                label="Complete"
                stageNumber="3"
              />
            </FlexRow>
            <form>
              <Input
                label="Company Name *"
                name="company-name"
                onChange={setCompanyName}
                placeholder="Enter your company name"
                type="text"
                value={companyName}
              />
              <Input
                label="Company Logo *"
                name="company-logo"
                onChange={setCompanyImage}
                placeholder="Select your company logo"
                type="file"
                value={companyImage?.path}
              />
              <Input
                label="Company Description *"
                name="company-name"
                onChange={setCompanyDesc}
                placeholder="Enter your company description"
                type="text"
                value={companyDesc}
              />
              <Info className="text-primary-100 mb-4">Tell us your budget</Info>
              <FlexRow className="phone:flex-col phone:space-y-4 laptop:flex-row laptop:space-y-0">
                <MoneyInput
                  label="PHP"
                  name="min"
                  onChange={setMin}
                  placeholder="Min Budget"
                  value={min}
                />
                <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden laptop:block" />
                <MoneyInput
                  label="PHP"
                  name="max"
                  onChange={setMax}
                  placeholder="Max Budget"
                  value={max}
                />
              </FlexRow>

              <Info className="text-primary-100 mb-6 mt-10">
                Where will you be using Xffiliate links?
              </Info>
              <FlexRow className="phone:flex-col laptop:flex-row">
                <Flex>
                  {Using1.map((value) => (
                    <Checkbox
                      containerClass="mb-4"
                      id={value.id}
                      key={value.id}
                      label={value.label}
                      onClick={() => setPurpose(`${purpose}, ${value.label}`)}
                    />
                  ))}
                </Flex>
                <Flex>
                  {Using2.map((value) => (
                    <Checkbox
                      containerClass="mb-4"
                      id={value.id}
                      key={value.id}
                      label={value.label}
                      onClick={() => setPurpose(`${purpose}, ${value.label}`)}
                    />
                  ))}
                </Flex>
              </FlexRow>
              <FlexRow className="mt-6">
                {/* <Text
                  className="text-base text-left ml-auto mr-10 mt-3 phone:w-full 
              phone:text-center tablet:w-auto"
                >
                  <Link className="text-primary-100" to="/onboard/aboutyou">
                    Back
                  </Link>
                </Text> */}
                <Button
                  className="mt-10 px-14 ml-auto phone:w-full phone:justify-center"
                  isLoading={loading}
                  onClick={onSubmit}
                >
                  Next
                </Button>
              </FlexRow>
            </form>
          </Flex>
        </FlexRow>
      </Div>
    </Flex>
  );
};
