import React from 'react';
import { batch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { Flex, FlexRow, Icons, UserDropdown, Text, Button } from '@core';

export const Congrats = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const plan = query.get('type');

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, [dispatch, batch, usersActions]);

  return (
      <Flex className="laptop:px-20 tablet:px-10 phone:px-5">
        <FlexRow className="py-7">
          <Link className="mr-auto flex flex-row items-center" to="/merchant/dashboard">
            <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
            <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none phone:hidden tablet:block">
              Xffiliate
            </Text>
            <Text className="italic text-grey-100 pl-1 font-light self-end pb-2 phone:hidden tablet:block">
              Merchant
            </Text>
          </Link>
          <UserDropdown info={user.user_info} isWhite noHidden user={user} />
        </FlexRow>
        <hr className="text-secondary-300" />
        <Flex className="items-center laptop:pt-32 tablet:pt-20 phone:pt-12">
          <Text className="font-semibold text-secondary-100 text-2xl mb-5">Congratulations!</Text>
          <Text
            className="font-poppins font-bold text-4xl text-primary-100
          text-center mb-10"
          >
            You have been upgraded to the
              {
                plan === 'pro' ?
                <span className="text-orange-100 mx-2">PRO Plan</span>
                :
                <span className="text-orange-100 mx-2">PLUS Plan</span>
              }
          </Text>
          <Text className="mb-10">
            Thank you for choosing Xffiliate. We have sent your order confirmation to your email.
          </Text>
          <Link to="/merchant/dashboard">
            <Button className="px-16">Go to Dashboard</Button>
          </Link>
        </Flex>
      </Flex>  
  );
};