import React, { FC } from 'react';

interface InputProps {
  className?: string;
  props?: any;
  name?: string;
  placeholder?: string;
  label: string;
  validation?: boolean;
  errorLabel?: string;
  onChange?: any;
  value?: string;
  lableSize?: string;
  containerClass?: string;
}

export const PasswordInput: FC<InputProps> = ({
  className,
  placeholder,
  label,
  props,
  validation,
  onChange,
  value,
  name,
  lableSize,
  containerClass,
}) => {
  const [show, setShow] = React.useState(true);
  return (
    <div className={`mb-6 ${containerClass}`}>
      <div className="flex flex-row">
        <label
          className={`block mb-2 font-poppins font-regular 
        text-primary-100 ${lableSize || 'text-lg'}`}
          htmlFor={name}
        >
          {label}
        </label>
        <div
          className="flex flex-row items-center ml-auto cursor-pointer 
          text-primary-100 hover:text-primary-200"
          onClick={() => setShow(!show)}
        >
          <i className={`${show ? 'fas fa-eye' : 'fas fa-eye-slash'} mr-1 text-2xl `} />
          {/* <p className="font-robot font-regular  select-none">{show ? 'Show' : 'Hide'}</p> */}
        </div>
      </div>
      <input
        className={`border ${
          validation ? 'border-red-100' : 'border-primary-100'
        } text-primary-100 font-poppins text-base rounded-lg 
    py-5 px-7 focus:ring-primary-200 focus:border-primary-200 block w-full 
    ${className}`}
        id={name}
        name={name}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        required
        type={show ? 'password' : 'text'}
        value={value}
        {...props}
      />
    </div>
  );
};
