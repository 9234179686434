import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { Popover } from '@headlessui/react';
import { ApplicationList } from './ApplicationList';
import { useAppDispatch, productsActions, partnersActions } from '@redux';
import { Div, Flex, FlexCenter, FlexRow, Text, Toast, ToastInput, ToastV4, ToastContainer, ActionDropdown } from '@core';
import { PaginationV2 } from '@components';
import { getCookie } from '@hooks';
import { API } from '@config';
import { ActionsSelect } from '@constants';
import { updatePartnerStatus } from '@services';

export const AffiliateApplications: FC<any> = ({ prodID, user, onIndex }) => {

  const dispatch = useAppDispatch();
  const token = getCookie('token');
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const actionQuery = query.get('action');
  const [count, setCount] = React.useState(0);

  const [selection, setSelection] = React.useState(true);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<any>([]);
  const [indexDeny, setIndexDeny] = React.useState(0);
  const [startDeny, setStartDeny] = React.useState(false);
  const [indexApprove, setIndexApprove] = React.useState(0);
  const [startApprove, setStartApprove] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState<any>();
  const [actionSelected, setActionSelected] = React.useState('');

  const [isShow, setIsShow] = React.useState(true);
  const [isToastOpen5, setToastOpen5] = React.useState(false);
  const [toastKind5, setToastKind5] = React.useState('success');
  const [toastTitle5, setToastTitle5] = React.useState('');
  const [toastDesc5, setToastDesc5] = React.useState('');

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2, setToastKind2] = React.useState('success');
  const [toastTitle2, setToastTitle2] = React.useState('');
  const [toastDesc2, setToastDesc2] = React.useState('');

  const [isToastOpen3, setToastOpen3] = React.useState(false);

  const [isToastOpen4, setToastOpen4] = React.useState(false);
  const [toastKind4, setToastKind4] = React.useState('success');
  const [toastTitle4, setToastTitle4] = React.useState('');
  const [toastDesc4, setToastDesc4] = React.useState('');

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [mainList, setMainList] = React.useState<any>([]);
  const [list, setList] = React.useState<any>([]);
  const resultList: any[] = [];
  const emptyList: any[] = [];
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [searchOpen, setSearchOpen] = React.useState(true);
  const [resultDesc, setResultDesc] = React.useState('');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentList = list.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const onRefresh = () => {
    setSearchKeyword('')
    setSearchOpen(true)
    setIsShow(false)
    fetch(`${API}/get-applicants/${prodID}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => res.json())
    .then(dataA => {
      if(dataA.status === 1){
        setList(dataA?.data)
        setMainList(dataA?.data)
      }
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    })
  }

  const getAffiliates = () => {
    fetch(`${API}/get-affiliates/${prodID}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => res.json())
    .then(dataA => {
      if(dataA.status === 1){
        setCount(dataA?.data.length)
      }
    })
  }

  React.useEffect(() => {
    if(onIndex === 2){
      getAffiliates();
      setIsShow(false)
      fetch(`${API}/get-applicants/${prodID}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.json())
      .then(dataA => {
        if(dataA.status === 1){
          setList(dataA?.data)
          setMainList(dataA?.data)
        }
        setTimeout(() => {
          setIsShow(true)
        }, 2000)
      })
    }
  }, [prodID])

  React.useEffect(() => {
    if(actionQuery === 'applicants'){
      batch(async() => {
        await dispatch(productsActions.productList());
      })
      if(user?.user_info?.role === 'merchant'){
        navigate('/merchant/dashboard?action=application')
      }else{
        navigate('/merchant/admin_dashboard/manager?action=application')
      }
    }
  }, [actionQuery])

  React.useEffect(() => {
    setList(mainList)
  }, [searchKeyword])

  React.useEffect(() => {
    if(selectedAll){
      setIsChecked(true)
    }else{
      setIsChecked(null)
    }
  }, [selectedAll])

  /* Loop function for Approve */
  React.useEffect(() => {
    const interval = setInterval(() => {
      if(selectedData.length === 0 && startApprove){
        setToastOpen3(false)
        setStartApprove(false)
        setToastTitle('ERROR');
        setToastDesc('Please select an applicant to proceed.');
        setToastKind('error');
        setToastOpen(true);
      }else if(indexApprove !== selectedData.length && startApprove){
        batch(async () => {
          await updatePartnerStatus({
              id: selectedData[indexApprove].id,
              status: '1',
              code: selectedData[indexApprove].code,
          })
          .then(data => {
            if(data?.data?.status === 1){
              setIndexApprove(indexApprove+1)
            }else{
              setIndexApprove(0)
              setSelectedAll(false)
              setToastOpen3(false)
              setStartApprove(false)
              setSelectedData([])
              setToastTitle('ERROR');
              setToastDesc(data?.data?.message || 'Something went wrong. The processing of applications has been terminated');
              setToastKind('error');
              setTimeout(() => {
                setToastOpen(true);
                getAffiliates();
                onRefresh();
              }, 1500)
            }
          })
          .catch(err => {
            if(err){
              setIndexApprove(0)
              setSelectedAll(false)
              setToastOpen3(false)
              setStartApprove(false)
              setSelectedData([])
              setToastTitle('ERROR');
              setToastDesc('Something went wrong. The processing of applications has been terminated.');
              setToastKind('error');
              setTimeout(() => {
                setToastOpen(true);
                getAffiliates();
                onRefresh();
              }, 1500)
            }
          })
          
        });
      }else if(indexApprove === selectedData.length && startApprove){
        getAffiliates();
        setIndexApprove(0)
        setSelectedAll(false)
        setToastOpen3(false)
        setStartApprove(false)
        setToastTitle('Applicants Added');
        setToastDesc('Applicants are successfully added.');
        setToastKind('success');
        setSelectedData([])
        setToastOpen(true);
        setTimeout(() => {
          navigate('/merchant/dashboard?action=applicants')
        }, 1500)
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [startApprove, indexApprove])

  /* Loop function for Deny */
  React.useEffect(() => {
    const interval = setInterval(() => {
      if(selectedData.length === 0 && startDeny){
        setToastOpen3(false)
        setStartDeny(false)
        setToastTitle('ERROR');
        setToastDesc('Please select an applicant to proceed.');
        setToastKind('error');
        setToastOpen(true);
      }else if(indexDeny !== selectedData.length && startDeny){
        batch(async () => {
          await dispatch(
            partnersActions.newStatus({
              id: selectedData[indexDeny].id,
              status: '-1',
              code: selectedData[indexDeny].code,
            }),
          ).then(async (item: any) => {
            if (!item?.payload?.data) {
              setToastTitle('ERROR');
              setToastDesc(item?.payload?.message || 'Something went wrong. Try again.');
              setToastKind('error');
              setToastOpen(true);
            }
          });
          setIndexDeny(indexDeny+1)
        });
      }else if(indexDeny === selectedData.length && startDeny){
        getAffiliates();
        setIndexDeny(0)
        setSelectedAll(false)
        setToastOpen3(false)
        setStartDeny(false)
        setToastTitle('Applicants Denied');
        setToastDesc('Applicants are successfully denied.');
        setToastKind('success');
        setSelectedData([])
        setToastOpen(true);
        setTimeout(() => {
          navigate('/merchant/dashboard?action=applicants')
        }, 1500)
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [startDeny, indexDeny])

  const onClick = () => {
      getAffiliates();
      setSearchKeyword('')
      fetch(`${API}/get-applicants/${prodID}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.json())
      .then(dataA => {
        if(dataA.status === 1){
          setList(dataA?.data)
          setMainList(dataA?.data)
        }
      })
      setToastOpen5(true)
      setToastTitle5('ACTION')
      setToastKind5('info')
      setToastDesc5('Please enter a name to search partner.')
  }

  const onSearch = async () => {
    if(/[a-zA-Z]/.test(searchKeyword)){
      setList(mainList)
      setSearchOpen(false)
      await mainList.map((item:any) => {
        const fullName = item?.firstname.concat(" ", item?.lastname);
        if(item.firstname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.lastname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.firstname === 'none'){
            if(item.company_name.toLowerCase().includes(searchKeyword.toLowerCase())){
              resultList.push(item)
              setList(resultList)
              setToastOpen5(false)
              setToastKind5('info')
              setCurrentPage(1)
            }
        } else if(fullName.toLowerCase().includes(searchKeyword.toLowerCase())){
            resultList.push(item)
            setList(resultList)
            setToastOpen5(false)
            setToastKind5('info')
            setCurrentPage(1)
        } else{
          setToastDesc5('No matches found.')
          setToastTitle5('ERROR')
          setTimeout(() => {
            setToastKind5('error')
          }, 500)
        }
        return 1;
      })
      if(resultList.length === 0){
        setList(emptyList)
        setResultDesc(`You've searched for "${searchKeyword}". (0) result(s) found.`)
        setToastDesc5('No matches found your search.')
        setToastTitle5('ERROR')
        setTimeout(() => {
          setToastKind5('error')
        }, 500)
      } else {
        setResultDesc(`You've searched for "${searchKeyword}". (${resultList.length}) result(s) found.`)
      }
    }
  }

  const onSubmit = async (e:any) => {
    e.preventDefault()
    if(/[a-zA-Z]/.test(searchKeyword)){
      setList(mainList)
      setSearchOpen(false)
      await mainList.map((item:any) => {
        const fullName = item?.firstname.concat(" ", item?.lastname);
        if(item.firstname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.lastname.toLowerCase().includes(searchKeyword.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        } else if(item.firstname === 'none'){
            if(item.company_name.toLowerCase().includes(searchKeyword.toLowerCase())){
              resultList.push(item)
              setList(resultList)
              setToastOpen5(false)
              setToastKind5('info')
              setCurrentPage(1)
            }
        } else if(fullName.toLowerCase().includes(searchKeyword.toLowerCase())){
            resultList.push(item)
            setList(resultList)
            setToastOpen5(false)
            setToastKind5('info')
            setCurrentPage(1)
        } else{
          setToastDesc5('No matches found.')
          setToastTitle5('ERROR')
          setTimeout(() => {
            setToastKind5('error')
          }, 500)
        }
        return 1;
      })
      if(resultList.length === 0){
        setList(emptyList)
        setResultDesc(`You've searched for "${searchKeyword}". (0) result(s) found.`)
      } else {
        setResultDesc(`You've searched for "${searchKeyword}". (${resultList.length}) result(s) found.`)
      }
    }
  }

  const onSort = (e:any) => {
    if(e === 'ascending'){
      setIsShow(false)
      setList(mainList.sort((a:any, b:any) => {
      if(a.firstname === 'none' && b.firstname !== 'none'){
        if(a.company_name < b.firstname) { return -1; }
        if(a.company_name > b.firstname) { return 1; }
      }else if(a.firstname !== 'none' && b.firstname === 'none'){
        if(a.firstname < b.company_name) { return -1; }
        if(a.firstname > b.company_name) { return 1; }
      }else if(a.firstname === 'none' && b.firstname === 'none'){
        if(a.company_name < b.company_name) { return -1; }
        if(a.company_name > b.company_name) { return 1; }
      }else{
        if(a.firstname < b.firstname) { return -1; }
        if(a.firstname > b.firstname) { return 1; }
      }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'descending'){
      setIsShow(false)
      setList(mainList.sort((a:any, b:any) => {
      if(a.firstname === 'none' && b.firstname !== 'none'){
        if(a.company_name > b.firstname) { return -1; }
        if(a.company_name < b.firstname) { return 1; }
      }else if(a.firstname !== 'none' && b.firstname === 'none'){
        if(a.firstname > b.company_name) { return -1; }
        if(a.firstname < b.company_name) { return 1; }
      }else if(a.firstname === 'none' && b.firstname === 'none'){
        if(a.company_name > b.company_name) { return -1; }
        if(a.company_name < b.company_name) { return 1; }
      }else{
        if(a.firstname > b.firstname) { return -1; }
        if(a.firstname < b.firstname) { return 1; }
      }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'newest'){
      setIsShow(false)
      setList(mainList.sort((a: any, b: any) => {
      if(a.firstname > b.firstname) { return -1; }
      if(a.firstname < b.firstname) { return 1; }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'oldest'){
      setIsShow(false)
      setList(mainList.sort((a: any, b: any) => {
      if(a.firstname > b.firstname) { return -1; }
      if(a.firstname < b.firstname) { return 1; }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'status'){
      setIsShow(false)
      setList(mainList.sort((a: any, b: any) => {
      if(a.status > b.status) { return -1; }
      if(a.status < b.status) { return 1; }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === ''){
      onRefresh()
    }
  }

  const Select = (data: any) => {
    setActionSelected('')
    if(!selectedData.includes(data)){
      selectedData.push(data)
      setSelectedData(selectedData)
    }else{
      setSelectedData(selectedData.filter((value: number) => value !== data))
    }
  }

  const Approve = async () => {
    if(selectedData.length > 0){
      setSelection(false)
      setToastDesc4(`Are you sure you want to approve all ${selectedData.length} affiliate(s) selected?`);
      setToastTitle4('Action');
      setToastKind4('info');
      setToastOpen4(true);
      setSelection(true)
    }else{
      setToastTitle('ERROR');
        setToastDesc('Please select an applicant to proceed.');
        setToastKind('error');
        setToastOpen(true);
    }
  }

  const Deny = async () => {
    if(selectedData.length > 0){
      setSelection(false)
      setToastDesc2(`Are you sure you want to reject all ${selectedData.length} affiliate(s) selected?`);
      setToastTitle2('Action');
      setToastKind2('info');
      setToastOpen2(true);
      setSelection(true)
    }else{
      setToastTitle('ERROR');
      setToastDesc('Please select an applicant to proceed.');
      setToastKind('error');
      setToastOpen(true);
    }
  }

  const onApprove = async () => {
    const tempData: any = [...selectedData];
    await setSelectedData(tempData.sort((a:any , b: any) => {
      const newDateA: any = new Date(a.created_at);
      const newDateB: any = new Date(b.created_at);
      return newDateA - newDateB;
    }))
    setToastOpen4(false);
    setToastOpen3(true);
    setTimeout(() => {
      setStartApprove(true)
    }, 1500)
  };

  const onDeny = async () => {
    setToastOpen2(false);
    setToastOpen3(true);
    setTimeout(() => {
      setStartDeny(true)
    }, 1500)
  };

  const SelectAll = async () => {
    setActionSelected('')
    setIsShow(false)
    if(selectedData.length === mainList.length || selectedAll){
      setSelectedAll(false)
      setSelectedData([])
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    }else{
      setSelectedAll(true)
      setSelectedData(mainList)
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    }
  }

  React.useEffect(() => {
    if(actionSelected === 'Approve Selected'){
      Approve()
    }else if (actionSelected === 'Reject Selected'){
      Deny()
    }
  }, [actionSelected])

  return (
    <Flex className='gap-4'>
    <Toast
          description={toastDesc}
          isOpen={isToastOpen}
          onClose={() => setToastOpen(false)}
          title={toastTitle}
          toastKind={toastKind}
        />
    <ToastV4
          btnText='Cancel'    
          description={toastDesc2}
          isOpen={isToastOpen2}
          link=''
          onClick={onDeny}
          onClick2={() => {
            setActionSelected('')
            setToastOpen2(false)
          }}
          onClose={() => {
            setActionSelected('')
            setToastOpen2(false)
          }}
          submitBtnText='Reject'
          title={toastTitle2}
          toastKind={toastKind2}
        />

    <ToastV4
          btnText='Cancel'    
          description={toastDesc4}
          isOpen={isToastOpen4}
          link=''
          onClick={onApprove}
          onClick2={() => {
            setActionSelected('')
            setToastOpen4(false)
          }}
          onClose={() => {
            setActionSelected('')
            setToastOpen4(false)
          }}
          submitBtnText='Approve'
          title={toastTitle4}
          toastKind={toastKind4}
        />
    <ToastInput
          description={toastDesc5}
          isOpen={isToastOpen5}
          onChange={(e:any) => setSearchKeyword(e.target.value)}
          onClickSubmit={onSearch}
          onClose={() => setToastOpen5(false)}
          onSubmit={onSubmit}
          placeholder='Search'
          submitBtnText='Search'
          title={toastTitle5}
          toastKind={toastKind5}
          type='search'
          value={searchKeyword}
        />
    <ToastContainer
          description='Affiliate Applications'
          isOpen={isToastOpen3}
          onClose={() => setToastOpen3(false)}
          title='ACTION'
          toastKind='info'
    >
      <Div>
        <Text className='text-secondary-200 py-1 mt-2'>
          <b className='mr-1'>REMINDER:</b>
          Please refrain from reloading this page as we complete this action.
        </Text>
      </Div>
      <Div className={`${startApprove? '' : 'hidden'}`}>
        {
          indexApprove === 0?
          <Text className='text-secondary-200'>
            Loading data ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
          :
          <>
          <Text className='text-secondary-200'>
            Processing applications ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
          <FlexRow className='pr-4 py-2 w-full justify-between'>
            <div
              className='rounded-full background-play h-2.5'
              style={{
                width: `${indexApprove === 0? 0 : ((indexApprove/selectedData.length)*100).toFixed(0)}%`
              }}
            />
            <Text className='ml-4'>
              {indexApprove === 0? 0 : ((indexApprove/selectedData.length)*100).toFixed(0)}%
            </Text>
          </FlexRow>
          </>
        }
      </Div>
      <Div className={`${startDeny? '' : 'hidden'}`}>
        {
          indexDeny === 0?
          <Text className='text-secondary-200'>
            Loading data ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
          :
          <>
          <Text className='text-secondary-200'>
            Processing applications ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
          <FlexRow className='pr-4 py-2 w-full justify-between'>
            <div
              className='rounded-full background-play h-2.5'
              style={{
                width: `${indexDeny === 0? 0 : ((indexDeny/selectedData.length)*100).toFixed(0)}%`
              }}
            />
            <Text className='ml-4'>
              {indexDeny === 0? 0 : ((indexDeny/selectedData.length)*100).toFixed(0)}%
            </Text>
          </FlexRow>
          </>
        }
      </Div>
    </ToastContainer>

      {
        searchOpen?
        <>
        </>
        :
        <div>
            <p className='phone:text-center surfaceDuo:text-left mb-2'>{resultDesc}</p>
            <span className='block text-center surfaceDuo:text-left hover:underline text-blue-100 cursor-pointer' onClick={onRefresh}>Clear Searches</span>
        </div>
      }
      <FlexRow className="justify-between">

        <FlexRow className='gap-4 phone:items-start phone:mx-5 surfaceDuo:items-center surfaceDuo:mx-0 surfaceDuo:justify-between w-full flex phone:flex-col surfaceDuo:flex-row'>

              <ActionDropdown
                className='phone:text-white phone:py-2'
                classNameBody='border border-grey-400 rounded-lg z-10 phone:left-0'
                color="white"
                containerClass='bg-primary-100 hover:bg-blue-100 phone:rounded-lg phone:hidden laptop:block'
                current={actionSelected}
                items={ActionsSelect}
                onSelect={setActionSelected}
                placeholder="Select an action"
              />

              <div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
                <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
                    rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
                >
                    <form onSubmit={onSubmit}>
                    <input className="placeholder-grey-700 laptop:text-base
                        tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
                        onChange={(e:any) => {
                          setSearchKeyword(e.target.value)
                        }}
                        placeholder="Search"
                        value={searchKeyword}
                    />
                    <button className='hidden' type='submit'>Search</button>
                    </form>
                </div>
                <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearch}/>
              </div>

              <button className='hidden tabletWide:flex laptop:ml-auto w-52 flex-row items-center justify-start text-primary-100 hover:text-white py-2 px-8 rounded-full border-2 border-primary-100 bg-white hover:bg-primary-100 shadow-xl' onClick={onClick}>
                <i className="fas fa-search text-base mr-2" />Search 
              </button>
            
              <div className='flex flex-row items-center phone:w-full surfaceDuo:w-auto'>
              <label className='hidden medium:flex mx-3' htmlFor='sort'>Sort by:</label>
              <select className='rounded-lg phone:w-full surfaceDuo:w-56 shadow-xl border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
                  <option value="">ALL</option>
                  <option value="ascending">A - Z (Name)</option>
                  <option value="descending">Z - A (Name)</option>
                  <option value="status">Status</option>
              </select>
              </div>

        </FlexRow>
      </FlexRow>

      {/* Substitute Component */}
      <Div className='phone:px-5 surfaceDuo:px-0 surfaceDuo:mt-4'>
        <ActionDropdown
          className='phone:text-white phone:py-2'
          classNameBody='border border-grey-400 rounded-lg z-10 phone:left-0'
          color="white"
          containerClass='bg-primary-100 hover:bg-blue-100 phone:rounded-lg laptop:hidden w-full surfaceDuo:w-40'
          current={actionSelected}
          items={ActionsSelect}
          onSelect={setActionSelected}
          placeholder="Select an action"
        />
      </Div>

      {/* Hide Component */}
      <FlexRow className='items-center w-full mt-8 hidden'>
        <button
          className='text-blue-100 font-semibold hover:underline'
          onClick={() => setSelection(!selection)}
        >
          {selection? 'Disable selection' : 'Enable Selection'}
        </button>
        <Popover className="relative inline mx-2">
              <Popover.Button className='inline border border-primary-100 bg-white hover:bg-primary-100 text-primary-100 hover:text-white rounded-full px-2'>
                <i className='fas fa-question text-xs' />
              </Popover.Button>
              <Popover.Panel className="absolute z-10 -right-8 mt-4">
                <Div className='bg-white border border-primary-100 phone:w-48 surfaceDuo:w-60 rounded-lg py-4 px-2'>
                  <Text className='text-sm text-secondary-200 text-left'>
                    Select entries to execute a specific action on chosen items.
                  </Text>
                  <Text className='mt-4 text-sm text-secondary-200'>
                    ** Click anywhere on screen to close. **
                  </Text>
                </Div>
              </Popover.Panel>
        </Popover>
      </FlexRow>

      <Div className='w-full tabletWide:hidden'>
        <Text className="font-poppins font-semibold text-secondary-200 my-2 text-center text-lg">
          List of Applications
        </Text>
        <hr className="text-grey-400" />
      </Div>

      {
        selection?
        <FlexRow className='items-center mt-2 phone:flex tabletWide:hidden'>
          <input
            checked={isChecked}
            className={`mr-2 phone:w-3 phone:h-3 laptop:w-4 laptop:h-4 rounded border border-secondary-100 
                  focus:ring-3 focus:ring-primary-200 text-primary-100
                  cursor-pointer
                  ${selection ? '' : 'hidden'}
                  `}
            onClick={SelectAll}
            title={selectedData.length === mainList.length
                    ? 'Click to Unselect All'
                    : 'Click to Select All'
                  }
            type='checkbox'
          />
          <Text className='ml-2 text-blue-100 font-semibold'>
            {selectedData.length === mainList.length
              ? 'Unselect All'
              : 'Select All'
            }
          </Text>
        </FlexRow>
        :
        <></>
      }

      <FlexRow className="w-full font-medium text-grey-1000 pt-0 tablet:pt-6 phone:hidden tabletWide:flex pb-2 border-b border-grey-400">
        <input
          checked={isChecked}
          className={`mr-2 phone:w-3 phone:h-3 laptop:w-4 laptop:h-4 rounded border border-secondary-100 
                focus:ring-3 focus:ring-primary-200 text-primary-100
                cursor-pointer
                ${selection ? '' : 'hidden'}
                `}
          onClick={SelectAll}
          title={selectedData.length === mainList.length
                  ? 'Click to Unselect All'
                  : 'Click to Select All'
                }
          type='checkbox'
        />
        <Text className="w-2/8 text-left">Name</Text>
        <Text className="w-1/8 text-left tabletWide:text-center laptop:text-left laptop:w-2/8">Email</Text>
        <Text className="w-1/8 text-left tabletWide:text-center">Social</Text>
        <Text className="w-2/8 text-center laptop:w-1/8">Status</Text>
        <Text className="w-2/8 text-center">Actions</Text>
      </FlexRow>

      <Div className="tabletWide:mt-4 w-full">
        {list.length !== 0  && isShow? (
          currentList.map((applicant: any, index: number) => (
            <ApplicationList
              count={count}
              data={applicant}
              key={`${applicant.created_at}-${index}`}
              prodID={prodID}
              Select={() => Select(applicant)}
              selectedAll={selectedAll}
              selectedData={selectedData}
              selection={selection}
            />
          ))
        ) : (
          <FlexCenter className="col-span-2 py-4">
            {
              !isShow?
              <Text>
                Loading data ...
                <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
              </Text>
              :
              <>
              <Text>Currently no affiliates at this time.</Text>
              <div className='text-sm text-blue-100 hover:underline cursor-pointer' onClick={onRefresh}>
                Click to reload
                <i className="fas fa-repeat text-2xl mx-3" />
              </div>
              </>
            }
          </FlexCenter>
        )}
      </Div>

      <div className="flex justify-center">
      <PaginationV2
        currentPage={currentPage}
        itemsPerPage={articlesPerPage}
        paginate={paginate}
        totalItems={list.length}
      />
      </div>

    </Flex>
  );
};