import React, { FC } from 'react';
import { batch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, FlexRow, Div, Text } from  '@core';
import { getFailedDisbursements } from '@services';
import { RePaymentData, PaginationV2 } from '@components';

export const Pending: FC<any> = () => {

  const [list, setList] = React.useState([]);
  const [isShow, setIsShow] = React.useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const currentList = list.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    setIsShow(true)
    batch(async() => {
      const data: any = await getFailedDisbursements();
      if(data.data.status === 1 && data.data.data.length > 0){
        setList(data.data.data)
        setIsShow(false)
      }else{
        setIsShow(false)
      }
    })
  }, [])

  React.useEffect(() => {
    if(actionQuery === 'updated'){
      setIsShow(true)
      batch(async() => {
        const data: any = await getFailedDisbursements();
        if(data.data.status === 1 && data.data.data.length > 0){
          setList(data.data.data)
          setIsShow(false)
          navigate('/merchant/settings')
        }
      })
    }
  })

return(
  <Flex
    className="desktop:pl-16 phone:pt-0 laptop:pr-20 phone:px-5"
  >

  <Text className="font-poppins font-semibold text-xl mt-8 mb-3 mr-auto">Pending Disbursements</Text>
  <hr className="text-grey-400 mb-10" />

  <Text className='text-secondary-200'>
    List of failed transactions that were not completed during batch disbursements.
  </Text>

  <FlexRow className='w-full mt-8 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
    <div
      className="text-base text-secondary-200 font-medium w-1/5 text-center rounded-full flex items-center justify-center py-2"
    >
      Name
      <i className="fas fa-chevron-down text-white mx-2 hidden" />
    </div>
    <div
      className="text-base text-secondary-200 font-medium w-1/5 text-center rounded-full flex items-center justify-center py-2"
    >
      Amount
      <i className="fas fa-chevron-down text-white mx-2 hidden" />
    </div>
    <div
      className="text-base text-secondary-200 font-medium w-1/5 text-center rounded-full flex items-center justify-center py-2"
    >
      Reason
      <i className="fas fa-chevron-down text-white mx-2 hidden" />
    </div>
    <div
      className="text-base text-secondary-200 font-medium w-1/5 text-center rounded-full flex items-center justify-center py-2"
    >
      Method
      <i className="fas fa-chevron-down text-white mx-2 hidden" />
    </div>
    <div
      className="text-base text-secondary-200 font-medium w-1/5 text-center flex items-center justify-center py-2"
    >
      Actions
    </div>
  </FlexRow>

  {
    list.length > 0 && !isShow ?
    <>
    {
      currentList.map((listA: any) =>(
        <RePaymentData
          amount={listA.total_amount}
          date={listA.date}
          id={listA.id}
          key={listA.id}
          name={listA.affiliate_name}
          primary={listA.payment_method.primary || 'NONE'}
          reason={listA.failure_code}
          referenceNum={listA.reference_id}
          secondary={listA.payment_method.secondary || 'NONE'}
        />
        ))
    }
    </>
    :
    <>
    {
      isShow ?
      <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
          <Text>
            Loading data ...
            <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
          </Text>
      </Div>
      :
      <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
          <Text>
            No data to be displayed for pending disbursements.
          </Text>
      </Div>
    }
    </>
  }

  <div className="flex justify-center">
    <PaginationV2
      currentPage={currentPage}
      itemsPerPage={articlesPerPage}
      paginate={paginate}
      totalItems={list.length}
    />
  </div> 

  </Flex>
  )
};