/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { batch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { selectProducts, useAppDispatch, productsActions, selectUser } from '@redux';
import { Button, Div, DropdownSelect, Flex, FlexCenter, FlexRow, Text, ToastInput, Icons } from '@src/core';
import { PaginationV2 } from '@components';
import { getCookie } from '@hooks';
import { formatCount } from '@src/helpers/formatNumber';

export const ProductsAdmin: FC<any> = () => {

  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const products: any = useSelector(selectProducts);
  const affiliateCount: any = products.length;

  const [isShow, setIsShow] = React.useState(true);
  const [iconLoaded, setIconLoaded] = React.useState(false);
  const [isToastOpen5, setToastOpen5] = React.useState(false);
  const [toastKind5, setToastKind5] = React.useState('success');
  const [toastTitle5, setToastTitle5] = React.useState('');
  const [toastDesc5, setToastDesc5] = React.useState('');

  const [list, setList] = React.useState<any[]>([])
  const [mainList, setMainList] = React.useState<any[]>([])
  const [sortList, setSortList] = React.useState(list)
  const individualList: any[] = [];
  const companyList: any[] = [];
  const resultList: any[] = [];
  const emptyList: any[] = [];
  const [search, setSearch] = React.useState('')
  const [searchOpen, setSearchOpen] = React.useState(true);
  const [resultDesc, setResultDesc] = React.useState('');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentList = list.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    batch(async () => {
      setIsShow(false)
      const data: any = await dispatch(productsActions.productListAdmin(user.user_info.merchant_id))
      setList(data.payload.data)
      setMainList(data.payload.data)
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    })
  }, [user])

  React.useEffect(() => {
    setList(mainList)
  }, [search])

  const onClick = async () => {
      setSearch('')
      batch(async () => {
        const data: any = await dispatch(
          productsActions.productListAdmin(user.user_info.merchant_id)
        )
        setList(data.payload.data)
        setMainList(data.payload.data)
      })
      setToastOpen5(true)
      setToastTitle5('ACTION')
      setToastKind5('info')
      setToastDesc5('Please enter a keyword to search a product.')
  }

  const onSearch = async () => {
    if(/[a-zA-Z]/.test(search)){
      setList(mainList)
      setSearchOpen(false)
      await list.map((item:any) => {
        if(item.name.toLowerCase().includes(search.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        }else{
          setToastDesc5('No matches found.')
          setToastTitle5('ERROR')
          setTimeout(() => {
            setToastKind5('error')
          }, 500)
        }
        return 1;
      })
      if(resultList.length === 0){
        setList(emptyList)
        setResultDesc(`You've searched for "${search}". (0) result(s) found.`)
      } else {
        setResultDesc(`You've searched for "${search}". (${resultList.length}) result(s) found.`)
      }
    }
  }

  const onSubmit = async (e:any) => {
    e.preventDefault()
    if(/[a-zA-Z]/.test(search)){
      setList(mainList)
      setSearchOpen(false)
      await list.map((item:any) => {
        if(item.name.toLowerCase().includes(search.toLowerCase())){
          resultList.push(item)
          setList(resultList)
          setToastOpen5(false)
          setToastKind5('info')
          setCurrentPage(1)
        }else{
          setToastDesc5('No matches found.')
          setToastTitle5('ERROR')
          setTimeout(() => {
            setToastKind5('error')
          }, 500)
        }
        return 1;
      })
      if(resultList.length === 0){
        setList(emptyList)
        setResultDesc(`You've searched for "${search}". (0) result(s) found.`)
      } else {
        setResultDesc(`You've searched for "${search}". (${resultList.length}) result(s) found.`)
      }
    }
  }

  const onRefresh = async () => {
    setSearch('')
    setSearchOpen(true)
    batch(async () => {
      setIsShow(true)
      const data: any = await dispatch(productsActions.productListAdmin(user.user_info.merchant_id))
      setList(data.payload.data)
      setMainList(data.payload.data)
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    })
  }

  const searchAgain = async () => {
    setSearchOpen(true)
    setSearch('')
    setToastKind5('info')
    setToastTitle5('ACTION')
    setToastDesc5('Please enter a keyword to search a product.')
    batch(async () => {
      const data: any = await dispatch(productsActions.productListAdmin(user.user_info.merchant_id))
      setList(data.payload.data)
      setMainList(data.payload.data)
    })
    setToastOpen5(true)
  }

  const onSort = async (e:any) => {
    const forSort = [...mainList]
    if(e === 'ascending'){
      setIsShow(false)
      setList(forSort.sort((a, b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'descending'){
      setIsShow(false)
      setList(forSort.sort((a, b) => {
      if(a.name > b.name) { return -1; }
      if(a.name < b.name) { return 1; }
      return 0;
      }))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'company'){
      setIsShow(false)
      await forSort.map((partner:any) => {
          if(partner.firstname === 'none'){
            companyList.push(partner)
          }else{
            individualList.push(partner)
          }
          return 1;
          })
          setList(companyList)
          setTimeout(() => {
            setIsShow(true)
          }, 1500)
    } else if (e === 'individual'){
      setIsShow(false)
      await forSort.map((partner:any) => {
          if(partner.firstname === 'none'){
            companyList.push(partner)
          }else{
            individualList.push(partner)
          }
          return 1;
          })
          setList(individualList)
          setTimeout(() => {
            setIsShow(true)
          }, 1500)
    } else if (e === 'clicks'){
      setIsShow(false)
      setList(forSort.sort((a, b) => b.total_cliked - a.total_cliked ))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'conversions'){
      setIsShow(false)
      setList(forSort.sort((a, b) => a.conversion - b.conversion))
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    } else if (e === 'reset'){
      setIsShow(false)
      setList(mainList)
      setTimeout(() => {
        setIsShow(true)
      }, 1500)
    }
  }

  return (
    <Flex>
    <ToastInput
          description={toastDesc5}
          isOpen={isToastOpen5}
          onChange={(e:any) => setSearch(e.target.value)}
          onClickSubmit={onSearch}
          onClose={() => setToastOpen5(false)}
          onSubmit={onSubmit}
          placeholder='Search'
          submitBtnText='Search'
          title={toastTitle5}
          toastKind={toastKind5}
          type='search'
          value={search}
        />

      <Flex className="gap-4 mt-10">
        {
          searchOpen?
          <>
          </>
          :
          <div>
              <p className='phone:text-center surfaceDuo:text-left mb-2'>{resultDesc}</p>
              <span className='block text-center surfaceDuo:text-left hover:underline text-blue-100 cursor-pointer' onClick={onRefresh}>Clear Searches</span>
          </div>
        }
        <FlexRow className="justify-between">
          <FlexRow className='gap-4 phone:items-start phone:mx-5 surfaceDuo:items-center surfaceDuo:mx-0 surfaceDuo:justify-between w-full flex phone:flex-col surfaceDuo:flex-row'>

                <div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
                  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
                      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
                  >
                      <form onSubmit={onSubmit}>
                      <input className="placeholder-grey-700 laptop:text-base
                          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
                          onChange={(e:any) => {
                            setSearch(e.target.value)
                          }}
                          placeholder="Search"
                          value={search}
                      />
                      <button className='hidden' type='submit'>Search</button>
                      </form>
                  </div>
                  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearch}/>
                </div>

                <button className='hidden tabletWide:flex w-52 flex-row items-center justify-start text-primary-100 hover:text-white py-2 px-8 rounded-full border-2 border-primary-100 bg-white hover:bg-primary-100 shadow-xl' onClick={onClick}>
                  <i className="fas fa-search text-base mr-2" />Search 
                </button>
              
              <div className='flex flex-row items-center phone:w-full surfaceDuo:w-auto'>
              <label className='hidden medium:flex mx-3' htmlFor='sort'>Sort by:</label>
              <select className='rounded-lg phone:w-full surfaceDuo:w-56 shadow-xl border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
                  <option disabled selected value="">SORT</option>
                  <option value="ascending">A - Z</option>
                  <option value="descending">Z - A</option>
                  <option value="conversions">Conversions</option>
                  <option value="clicks">Clicks</option>
                  <option value="reset">-- RESET --</option>
              </select>
              </div>
              
          </FlexRow>
        </FlexRow>
        
        <FlexRow className="grid grid-cols-9 font-medium text-grey-1000 px-0 pt-0 tablet:px-4 tablet:pt-6">
          <Text className="hidden tablet:block col-span-4">Name</Text>
          <Text className="hidden tablet:block col-span-3">Social Link</Text>
          <Text className="hidden tablet:block col-span-2 text-right">Statistics</Text>
        </FlexRow>
        {(currentList.length !== 0 && isShow) ? (
          currentList.map((product: any, i) => (
            <Link className="py-6 hover:bg-grey-500 cursor-pointer flex flex-row items-center" key={product.id} to={`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=details_product&product=${product.id}`}>
                <img
                  alt={`${product.name}`}
                  className='w-20 h-20 object-cover rounded-xl shadow-xl phone:ml-0 surfaceDuo:ml-4 phone:mr-2 surfaceDuo:mr-10 border-4 border-grey-400'
                  onError={() => setIconLoaded(true)}
                  src={
                    iconLoaded
                      ? Icons.xffiliate2
                      : product.image
                  } 
                />
              <FlexRow className="tablet:pl-2 grid grid-cols-9 w-full">
                <Div className="col-span-6 flex phone:flex-col tablet:flex-row tablet:grid grid-cols-6">
                  <Text className="phone:text-base tablet:text-lg font-semibold tablet:col-span-3">
                        {product?.name || 'N/A'}
                      </Text>
                      <Text className="text-sm font-medium text-grey-700 truncate ... tablet:col-span-3">
                        <a href={product.link}>{product?.link}</a>
                      </Text>
                </Div>
                <Div className="col-span-3 ml-auto gap-x-2 gap-y-2 text-primary-100 font-medium text-sm flex phone:flex-col tablet:flex-row">
                  <a data-for="revenue" data-tip>
                    <FlexRow className="bg-light-200 rounded-full p-2">
                      <i className="fas fa-hand-holding-usd text-orange-400 pr-2" />
                      <Text>{`${formatCount(product.earnings)}`}</Text>
                    </FlexRow>
                  </a>
                  <ReactTooltip
                    backgroundColor="#FFFFFF"
                    delayHide={150}
                    effect="solid"
                    id="revenue"
                    textColor="#073763"
                  >
                    <Text>Commissions</Text>
                  </ReactTooltip>
                  <a data-for="clicks" data-tip>
                    <FlexRow className="bg-light-200 rounded-full p-2">
                      <i className="fas fa-mouse-pointer text-orange-400 pr-2" />
                      <Text>{formatCount(product.total_cliked)}</Text>
                    </FlexRow>
                  </a>
                  <ReactTooltip
                    backgroundColor="#FFFFFF"
                    delayHide={150}
                    effect="solid"
                    id="clicks"
                    textColor="#073763"
                  >
                    <Text>Clicks</Text>
                  </ReactTooltip>
                  <a data-for="conversions" data-tip>
                    <FlexRow className="bg-light-200 rounded-full p-2">
                      <i className="fas fa-repeat text-orange-400 pr-2" />
                      <Text>{formatCount(product.conversion)}</Text>
                    </FlexRow>
                  </a>
                  <ReactTooltip
                    backgroundColor="#FFFFFF"
                    delayHide={150}
                    effect="solid"
                    id="conversions"
                    textColor="#073763"
                  >
                    <Text>Conversions</Text>
                  </ReactTooltip>
                </Div>
              </FlexRow>
            </Link>
          ))
        ) : (
          <FlexCenter className="col-span-2 py-8 my-4 bg-grey-500">
          {
            !isShow?
            <Text>
              Loading data ...
              <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
            </Text>
            :
            <Text>Currently no products at this time.</Text>
          }

          </FlexCenter>
        )}

        <div className="flex justify-center">
        <PaginationV2
          currentPage={currentPage}
          itemsPerPage={articlesPerPage}
          paginate={paginate}
          totalItems={list.length}
        />
        </div> 
        
      </Flex>
    </Flex>
  );
};