import React, {FC} from 'react';
import { Div, Text, FlexRow } from '@core';

interface DataProps {
	id: number;
	name: string;
	link: string;
	email: string;
	contact: string;
}

export const AffiliateData: FC<DataProps> = ({
	id,
	name,
	link,
	email,
	contact,
}) => (
<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2'>
	<Div className='phone:flex phone:flex-row tabletWide:hidden' key={id}>
	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Affiliate Name:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Email:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Mobile No:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Social Link:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {name}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left truncate"
	      >
	        {email}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {contact}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left truncate"
	      >
	        {link}
	      </Text>
	  </Div>
	</Div>

	<FlexRow className='w-full justify-between phone:hidden tabletWide:flex flex-wrap'>
		<Text
		  className="text-base text-secondary-200 font-light w-1/5"
		>
		  {name}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/5 break-words"
		>
		  {email}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/12"
		>
		  {contact}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-5/12 break-words"
		>
		  {link}
		</Text>
	</FlexRow>
	
</div>
)