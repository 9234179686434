import React, { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate, Link } from 'react-router-dom';
import { Dialog, Transition, Popover } from '@headlessui/react';
import xendit from '../../assets/icons/xendit-big.png';
import { selectUser } from '@redux';
import { UserDrawers } from '@constants';
import { Flex, FlexRow, Icons, UserDropdown, Text, Button } from '@core';
import {API} from '@config'
import {getCookie} from '@hooks';

interface ModalProps {
  isOpen: boolean;
  onClose?: any;
}

export const UserDrawerV2: FC<ModalProps> = ({ isOpen }) => {
  const location = useLocation();

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed h-full mt-24 ml-auto w-72 inset-0 z-20 overflow-hide "
        onClose={() => 'noop'}
      >
        <div className="h-full text-center flex justify-start">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-20 py-5 items-center
              align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
            >
              {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
              {UserDrawers[0].links.map((value) => (
                <Link
                  className={`w-full py-5 tex-base font-medium hover:text-blue-100 pl-10
               ${
                 location.pathname === value.url ? 'border-l-4 text-blue-100' : 'text-primary-100'
               }`}
                  key={value.name}
                  to={value.url}
                >
                  <i className={`${value.icon} w-14 text-2xl`} />
                  <span className="font-poppins text-primary-100">{value.name}</span>
                </Link>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const DisbursementFailed = () => {

  const token = getCookie('token');
  const user = useSelector(selectUser);
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const reference = query.get('reference');

  const [userDrawer, setuserDrawer] = React.useState(false);

  const [date, setDate] = React.useState<any>('#########');
  const [amount, setAmount] = React.useState(0);
  const [refNum, setRefNum] = React.useState('#########');
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    fetch(`${API}/get-transaction-details/${reference}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.json())
    .then( data => {
      if(data.items.length > 0){
        const newDate: any = new Date(data.data.created_at)
        setAmount(
          parseFloat(data.data.total_amount) +
          parseFloat(data.data.total_transaction_fee) +
          parseFloat(data.data.tax_fee)
        )
        setRefNum(data.data.reference_id)
        setCount(data.items.length)
        setDate(newDate.toString().substring(0, 16))
      } 
    })
  }, [token])

  return (
    <>
    {
    user?.user_info?.role === 'merchant' || user?.user_info?.role === 'Manager' || user?.user_info?.role === 'Editor' ?
      <Flex className="laptop:px-20 tablet:px-10 phone:px-5">
      <UserDrawerV2 isOpen={userDrawer} onClose={() => setuserDrawer(false)} />
        <FlexRow className="py-7">
          <Link className="mr-auto flex flex-row items-center" to="/merchant/dashboard">
            <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
            <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none phone:hidden surfaceDuo:block">
              Xffiliate
            </Text>
            <Text className="italic text-primary-100 pl-1 font-light self-end pb-2 phone:hidden medium:block">
              Merchant
            </Text>
          </Link>
          <div
            className="h-full w-16 rounded-lg bg-white border-2 border-primary-100 justify-center items-center cursor-pointer tabletWide:hidden phone:flex"
            onClick={() => {
              setuserDrawer(!userDrawer);
            }}
          >
            {userDrawer ? (
              <i className="fas fa-times laptop:text-3xl phone:text-2xl text-primary-100" />
            ) : (
              <i className="fas fa-user laptop:text-3xl phone:text-2xl text-primary-100" />
            )}
          </div>
          <UserDropdown info={user.user_info} isWhite user={user} />
        </FlexRow>
        <hr className="text-secondary-300" />
        <Flex className="items-center laptop:pt-32 tablet:pt-20 phone:pt-12 pb-36 overflow-y-scroll scrollbar-hide">
          <Text className="font-semibold text-secondary-100 medium:text-2xl mb-5">Hi {user?.user_info?.firstname}!</Text>
          <Text
            className="font-poppins font-bold medium:text-2xl text-primary-100
          text-center surfaceDuo:mb-10"
          >
            Sorry, it seems a problem occured during the disbursement process.
          </Text>

          <div className='medium:px-10 py-10 surfaceDuo:border-2 surfaceDuo:border-grey-400 rounded-lg w-full tabletWide:w-3/5 surfaceDuo:shadow-xl'>
            <Text className='text-secondary-200 font-semibold medium:text-2xl text-center'>
              TRANSACTION DETAILS:
            </Text>
            <hr className="text-grey-400 mt-3 mb-10" />

            <FlexRow className='justify-between surfaceDuo:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Disbursed Amount:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                &#8369; 0.00
              </Text>
            </FlexRow>

            <FlexRow className='justify-between surfaceDuo:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Transaction Amount:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                &#8369; 0.00
              </Text>
              <Text className='medium:text-xl text-secondary-200 hidden'>
                &#8369; {amount}
              </Text>
            </FlexRow>

            <FlexRow className='justify-between surfaceDuo:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Transactions count:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                0 Affiliate(s)
              </Text>
              <Text className='medium:text-xl text-secondary-200 hidden'>
                {count} Affiliate(s)
              </Text>
            </FlexRow>

            <FlexRow className='justify-between surfaceDuo:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Reference Number:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                {refNum}
              </Text>
            </FlexRow>

            <FlexRow className='justify-between surfaceDuo:px-10 my-3'>
              <Text className='medium:text-xl text-secondary-200 font-semibold'>
                Transaction date:
              </Text>
              <Text className='medium:text-xl text-secondary-200'>
                {date}
              </Text>
            </FlexRow>

            <FlexRow className='justify-center items-center mt-10'>
              <Text className='text-secondary-200 text-xs mx-2 font-semibold'>
                Powered by:
              </Text>
              <img alt="Bank Icon" className='mx-2 block' src={xendit} style={{width: '158px', height: '43px'}}/>
            </FlexRow>

            <Popover className="relative hidden surfaceDuo:block px-10">
                  <Popover.Button className='mt-4 text-xs text-primary-100'>
                    Why this happened ?
                    <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0 mt-4">
                    <div className="bg-white shadow-xl p-4 rounded-lg border-2 border-primary-100 w-full">
                      <Text className='text-sm text-secondary-200'>
                        Disbursement Errors
                      </Text>
                      <hr className='my-2 text-secondary-200' />
                      <Text className='mt-4 text-sm text-secondary-200'>
                        In some cases, you will receive this error when the destination 
                        account is inactive or the bank has blocked the account.
                      </Text>
                      <Text className='mt-4 text-sm text-secondary-200'>
                        Please retry after confirming with the beneficiary that the destination 
                        bank account exists and is able to receive funds. If the account is 
                        indeed active, please retry the disbursement in 1-3 hours.
                      </Text>
                      <Text className='mt-4 text-sm text-secondary-200'>
                        ** Click anywhere on screen to close. **
                      </Text>
                    </div>
                  </Popover.Panel>
            </Popover>

          </div>

          <Text className="my-10">
            For more concerns, you may reach us from this link. <a className='mx-1 text-blue-100' href="https://xffiliate.com/contact-us" rel="noopener noreferrer" target="_blank">Contact Us</a>
          </Text>
          <Link to="/merchant/dashboard">
            <Button className="px-16">Go to Dashboard</Button>
          </Link>
        </Flex>
      </Flex>
      :
      <Navigate to='/' />
    }
  </>
  );
};