import React, { FC } from 'react';
import { FlexRow, Div, Text, Flex } from '@core';

interface AffiliateItemProps {
  partners?: any;
  selectedPartner?: any;
  setSelected?: any;
  onSelect?: any;
  available: boolean;
}

export const AffiliateItem: FC<AffiliateItemProps> = ({
  partners,
  selectedPartner,
  setSelected,
  onSelect,
  available,
}) => (
  <div
    className="group cursor-pointer"
    key={partners?.id}
    onClick={() => {
      if(available){
        setSelected(partners);
        onSelect();
      }
    }}
  >
    <li
      className="relative pr-4 hover:bg-coolGray-100 border-b-2 
                        border-grey-400 "
    >
      <FlexRow className={`${available ? 'cursor-pointer' : 'opacity-60 cursor-not-allowed'}`} title={`${available ? '' : `${partners?.name} temporarily unavailable from merchant.`}`}>
        <Div
          className={`h-28 w-2 mr-4 ${
            partners?.id === selectedPartner?.id ? 'bg-blue-200' : 'bg-white'
          } `}
        />
        <Flex className="py-5">
          <FlexRow className='justify-between'>
            <Div className='w-2/3'>
              <Text className="font-poppins text-sm text-grey-100">{partners?.category}</Text>
              <h3
                className="font-poppins text-base font-semibold leading-5 mb-1 
              text-secondary-100 "
              >
                {partners?.name}
              </h3>
              <Text className="font-poppins font-medium text-sm text-grey-100 ">
                {partners?.productCode}
              </Text>
            </Div>

            <Text
              className={`font-poppins text-lg rounded-full 
              text-primary-100`}
            >
              {partners?.total_cliked}
              <i className="fas fa-hand-pointer ml-1" />
            </Text>
          </FlexRow>
        </Flex>
      </FlexRow>
    </li>
  </div>
);