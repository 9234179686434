import React, { FC } from 'react';

interface ButtonProps {
  className?: string;
  props?: any;
  onClick?: Function;
  type?: string;
  isLoading?: boolean;
  disabled?: boolean;
  as?: any;
  to?: string;
}

export const ButtonV2: FC<ButtonProps> = ({
  as,
  to,
  className,
  children,
  props,
  onClick,
  type,
  isLoading,
  disabled,
}) => (
  <button
    as={as}
    className={`font-semibold hover:text-white text-primary-100 bg-primary-100 hover:bg-opacity-80 focus:ring-2 focus:ring-secondary-300 
    font-poppins font-regular rounded-lg px-5 py-4 text-center
     inline-flex items-center laptop:text-lg phone:text-sm tablet:text-base mr-2 ${className}`}
    disabled={disabled || false}
    type={type || 'button'}
    {...props}
    onClick={onClick}
    to={to}
  >
    {isLoading ? <i className="fad fa-spinner-third animate-spin text-2xl" /> : children}
  </button>
);