import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Div, Icons, Text, Button } from '@core';

export const LinkError = () => (
  <>
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">Xffiliate</Text>
      </Div>
      <hr className="text-secondary-300 w-full" />
      <Flex className="items-center laptop:pt-32 phone:pt-20 tablet:w-500px phone:w-full">
        <Text className="font-semibold text-secondary-100 text-2xl mb-5">Page not found!</Text>
        <Text
          className="font-poppins font-bold laptop:text-3xl phone:text-xl text-primary-100
            text-center mb-10"
        >
          It seems the affiliate link your trying to access does not exist, 
          expired, or no permission to visit.
        </Text>
        <Text className="mb-10 text-center">
          Please check your URL for spellings or
          capitalizations.
        </Text>
        <Link to="/">
          <Button className="px-16">Home</Button>
        </Link>
      </Flex>
    </Flex>
  </>
);