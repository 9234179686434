import React, { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

interface MenuItems {
  icon: string;
  label: string;
  value: string;
}

interface DropdownProps {
  current: string;
  className?: string;
  classNameBody?: string;
  containerClass?: string;
  color: string;
  width?: string;
  items: MenuItems[];
  onSelect?: any;
  placeholder: string;
}

export const ActionDropdown: FC<DropdownProps> = ({
  className, classNameBody, containerClass, items, width, onSelect
}) => (
  <div className={`text-right ${containerClass}`}>
    <Menu as="div" className="relative inline-block text-left w-full">
      <div className='w-full'>
        <Menu.Button
          className={` inline-flex items-center justify-between w-full px-5 border-0
           py-3 font-poppins text-base font-regular text-primary-100
           ${className}`}
        > 
          <div>
            <i className="fas fa-bars phone:mr-4 surfaceDuo:mr-2" />
            Actions
          </div>
          <i className="fas fa-chevron-down ml-4" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 mt-1 origin-top-right font-poppins
          bg-white divide-y ${width || 'w-56'} divide-secondary-300 
          rounded-md shadow-lg ${classNameBody}`}
        >
          {items.map((value) => (
            <div className="px-1 py-1 cursor-pointer" key={value.value}>
              <Menu.Item onClick={() => onSelect(value.label)}>
                {({ active }) => (
                  <p
                    className={`${
                      active ? 'bg-primary-100 text-white' : 'text-secondary-100'
                    }  group flex rounded-md items-center
                    w-full px-4 py-3 text-base`}
                  >
                    <i className={`fas fa-${value.icon} mr-2`} />
                    {value.label}
                  </p>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);