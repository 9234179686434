import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import { getProduct } from '@services';

export const ViewImages = () => {
	
	const navigate = useNavigate();
	const { search } = useLocation();
	const query = new URLSearchParams(search)
	const actionQuery = query.get('action'); 

	const [source, setSource] = React.useState<any>('https://i.imgur.com/CGDGnf6.png');
	const [prodname, setName] = React.useState<any>('product name');
	const [id, setId] = React.useState<any>(0);

	React.useEffect(() => {
		if(source === 'https://i.imgur.com/CGDGnf6.png'){
			setId(actionQuery)
			batch(async() => {
				const img = await getProduct(id)
				if(img.data.status === 1){
					setSource(img?.data?.data?.image)
					setName(img?.data?.data?.name)
					navigate('/full_image')
				}else{
					setSource('Test')
					setName('Test')
					navigate('/full_image')
				}
			})
		}
	})

return(
	<div className='w-screen h-screen overflow-scroll bg-white'>

		<img 
			alt={prodname}
			src={source}
		/>

	</div>
)
}