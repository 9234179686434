import React, { FC, Fragment } from 'react';
import { Dialog, Transition, Popover } from '@headlessui/react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import validator from 'validator';
import {
  usersActions,
  productsActions,
  partnersActions,
  selectProducts,
  useAppDispatch,
  selectUser,
} from '@redux';
import { FlexRow, Button, Div, Text, InputV2, DropdownSelect, Toast, SelectV2 } from '@core';
import { getCookie } from '@hooks';
import { API } from '@config';
import { sendPartnerEmail } from '@services';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const CreatePartnerAdminModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user: any = useSelector(selectUser);
  const token = getCookie('token');
  const id: number = user?.user_info?.merchant_id;

  const products: any = useSelector(selectProducts);
  const newDate: any = new Date();
  const userNum: number = newDate.getTime();

  const [selectedProduct, setSelectedProduct]: any = React.useState('');
  const [email, setEmail] = React.useState('');
  const username: any = `user${userNum}`;
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');
  const [socialLink, setSocialLink] = React.useState('');
  const [commission, setCommission] = React.useState('');
  const [affiliateCategory, setAffiliateCategory] = React.useState('');
  const [categoryId, setCategoryId] = React.useState(0);

  const [productList, setProductList] = React.useState<any>([]);
  const categoryList: any[] = [];
  const productIdList: any[] = [];
  const [newProdIdList, setNewProdIdList] = React.useState<any>(['A']);
  const [newCategoryList, setNewCategoryList] = React.useState<any>(['-- SELECT --']);
  const [options, setOptions] = React.useState<any>([]);
  const [index, setIndex] = React.useState(0);
  const [start, setStart] = React.useState(false);
  const [affiliated, setAffiliated] = React.useState(false);
  const [affiliateId, setAffiliateId] = React.useState('0');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [isCompany] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [role, setRole] = React.useState(false);
  const [prod, setProd] = React.useState(false);

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if(parseFloat(commission) < 0 || regExp.test(commission) || parseFloat(commission) > 100){
      setCommission('')
    }
    if(parseInt(contactNumber) < 0 || regExp.test(contactNumber)){
        setContactNumber('')
      }
  }, [commission, contactNumber])

  React.useEffect(() => {
    if(affiliateCategory === 'Non-Categorized' || affiliateCategory === ''){
      setCommission('0')
    }else{
      options.map((option:any) => {
        if(option.role_name === affiliateCategory){
          setCategoryId(option.id)
          setCommission(option.commission)
        }
        return 1;
      })
    }
  })

  React.useEffect(() => {
    setSelectedProduct('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setFirstName('');
    setLastName('');
    setContactNumber('');
    setSocialLink('');
    if (!user?.email_confirmed && isOpen) {
      onClose();
      navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`);
      setTimeout(() => {
        setToastTitle('UNVERIFIED');
        setToastDesc('Please confirm your email address.');
        setToastKind('warning');
        setToastOpen(true);
      }, 1000);
    }
  }, [navigate, isOpen]);

  React.useEffect(() => {
      fetch(`${API}/get-all-role-commission`, {
          method: 'GET',
          headers: {Authorization: `Bearer ${token}`}
        })
        .then(res => res.json())
        .then(data => {
            if(data.status === 1){
              if(data.data.length > 0 && data.data.length !== 0){
                setOptions(data.data)
              }
            }
        })
  }, [isOpen])

  const loadCategory = async () => {
    if(options.length > 0){
      categoryList.push('Non-Categorized')
      await options.map((option: any) => (
          categoryList.push(option.role_name)
      ))
      setNewCategoryList(categoryList)
    }else{
      setNewCategoryList([
        'Non-Categorized',
        '(No category data)'
        ])
    }
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      if(index !== newProdIdList.length && start){
        batch(async () => {
          const partner: any = await dispatch(
            partnersActions.newPartnerAdmin({
              user_id: affiliateId,
              product_id: newProdIdList[index].ID,
              status: '1',
              role: 'affiliate',
            }),
          );
          setIndex(index+1)
          if (!partner?.payload) {
              setToastTitle(`Error on product ${newProdIdList[index].ID}`);
              setToastDesc(partner?.payload?.message || 'Something went wrong. Try again!');
              setToastKind('error');
              setToastOpen(true);
              setLoading(false);
              setTimeout(() => {
                setToastOpen(false);
              }, 2000)
          }
        })
      }else if(index === newProdIdList.length && start){
        setStart(false)
        setAffiliated(true)
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [start, index])

  React.useEffect(() => {
    if(affiliated){
      setProd(true)
      fetch(`${API}/update-affiliate-category/${affiliateId}`,{
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            category_id: categoryId,
            commission
            })
      })
      .then(res => res.json())
      .then(response => {
        if(response.status === 1){
          setToastTitle('Partner Created');
          setToastDesc(`${firstName} is now your affiliate partner.`);
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          batch(async () => {
            await dispatch(partnersActions.partnersListAdmin(user?.user_info?.merchant_id));
          })
          setTimeout(() => {
            onClose(false);
            navigate(
              `/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}`,
            );
          }, 2000);
        }else{
          setLoading(false)
          setToastDesc(response.message || 'Something went wrong. Please try again.')
          setToastOpen(true)
          setToastTitle('ERROR')
          setToastKind('error')
          setTimeout(() => {
            setToastOpen(false);
          }, 2000)
        }
      })
    }
  }, [affiliated])

  const onSubmit = async () => {
    const isEmail = validator.isEmail(email);
    const isPassword = validator.isStrongPassword(password, {
      minLength: 6,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    const isPasswordSame = password === confirmPassword;

    if (
      email === '' ||
      password === '' ||
      confirmPassword === '' ||
      firstName === '' ||
      lastName === '' ||
      contactNumber === '' ||
      socialLink === ''
    ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000)
    } else if (selectedProduct === '' && (affiliateCategory === '' || affiliateCategory === 'Non-Categorized')) {
      setToastTitle('Invalid Input');
      setToastDesc('Please choose a product.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000)
    } else if (productList.length === 0 && (affiliateCategory !== '' && affiliateCategory !== 'Non-Categorized')) {
      setToastTitle('PRODUCT ERROR');
      setToastDesc('Please create a product.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000)
    } else if (!isEmail) {
      setToastTitle('Invalid Email');
      setToastDesc('Please enter a valid email address.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000)
    } else if (!isPassword) {
      setToastTitle('Invalid Password');
      setToastDesc('Please enter a valid password.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000)
    } else if (!isPasswordSame) {
      setToastTitle('Password not match');
      setToastDesc('Please enter your password.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 2000)
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newUser({
            email,
            username,
            password,
            password_confirmation: confirmPassword,
          }),
        );
        if (data?.payload?.status === 200) {
          setSignUp(true)
          setAffiliateId(data?.payload?.userId)
          await sendPartnerEmail({email, password, id});
          const info: any = await dispatch(
            usersActions.newRole({
              is_company: isCompany,
              user_id: data?.payload?.userId,
              firstname: firstName,
              lastname: lastName,
              contact: contactNumber,
              role: 'affiliate',
              social_link: socialLink,
            }),
          );
          if (info?.payload?.status === 200) {
            setRole(true)
            if(affiliateCategory === '' || affiliateCategory === 'Non-Categorized'){
              const partner: any = await dispatch(
                partnersActions.newPartnerAdmin({
                  user_id: data?.payload?.userId,
                  product_id: selectedProduct?.value,
                  status: '1',
                  role: 'affiliate',
                }),
              );
              if (partner?.payload?.status === 200) {
                  setProd(true)
                  setToastTitle('Partner Created');
                  setToastDesc(`${firstName} is now your affiliate partner.`);
                  setToastKind('success');
                  setToastOpen(true);
                  setLoading(false);
                  batch(async () => {
                    await dispatch(partnersActions.partnersListAdmin(user?.user_info?.merchant_id));
                  })
                  setTimeout(() => {
                    onClose(false);
                    navigate(
                      `/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}?action=qrcode&referral_link=${partner?.payload?.data?.referral_link}`,
                    );
                  }, 2000);
              } else {
                setToastTitle('Invalid Input');
                setToastDesc(partner?.payload?.message || 'Something went wrong. Try again!');
                setToastKind('error');
                setToastOpen(true);
                setLoading(false);
                setTimeout(() => {
                  setToastOpen(false);
                }, 2000)
              }
            }else{
              setStart(true)
            }
          }
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
          }, 2000)
        }
      });
    }
  };

  React.useEffect(() => {
    batch(async () => {
      await dispatch(productsActions.productListAdmin(user?.user_info?.merchant_id));
      const filterProducts: any[] = [];
      for (let i = 0; i < products.length; i++) {
        filterProducts.push({
          label: products[i].name,
          value: products[i].id,
          title: products[i].name,
          price: products[i].price,
          commission: `${products[i].commission}%`,
        });
        productIdList.push({
          ID: products[i].id,
          INDEX: i+1,
        });
        if (i === products.length - 1) {
          setProductList(filterProducts);
          setNewProdIdList(productIdList)
        }
      }
    });
  }, [isOpen, affiliateCategory]);

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            if(window.location.pathname === `/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`){
              navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`);
            }else if(window.location.pathname === `/merchant/settings`){
              navigate('/merchant/settings')
            }
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">CREATE PARTNER</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      if(window.location.pathname === `/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`){
                        navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`);
                      }else if(window.location.pathname === `/merchant/settings`){
                        navigate('/merchant/settings')
                      }
                    }}
                  />
                </FlexRow>

                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll">

                  <div onClick={loadCategory}>
                  <div className="font-roboto font-bold text-lg text-orange-100 ml-2 mt-3">
                    Category Information
                  </div>
                  <Popover className="relative ml-2 my-4">
                        <Popover.Button>
                            <Text className='text-blue-100 font-semibold hover:underline'>
                              FAQs | Category
                              <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                            </Text>
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
                          <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-80">
                            <p className='font-semibold text-sm'>
                              Absolute Commission
                            </p>
                            <hr className='text-primary-100 my-1'/>
                            <p className='text-sm mt-4'>
                              Setting commissions on a specific role will be 
                              applied to all products affiliated of the categorized partner.
                            </p>
                            <p className='mt-4 text-sm text-secondary-200'>
                              ** Click anywhere on screen to close. **
                            </p>
                          </div>
                        </Popover.Panel>
                  </Popover>
                  <Link className="block hover:underline cursor-pointer font-roboto font-normal text-base text-blue-100 ml-2 mt-3 mb-5" onClick={onClose} to={`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}/settings?action=category`}>
                    Click to create new Category
                  </Link>
                  <SelectV2
                    className='px-2'
                    label="AFFILIATE CATEGORY"
                    name="affiliateCategory"
                    onChange={setAffiliateCategory}
                    options={newCategoryList}
                    placeholder="Choose"
                    value={affiliateCategory}
                  />
                  {
                    affiliateCategory === '' || affiliateCategory === 'Non-Categorized' ?
                    <></>
                    :
                    <InputV2
                    label="CATEGORY COMMISSION %"
                    name="commission"
                    onChange={setCommission}
                    placeholder="Enter Category Commission"
                    type="text"
                    value={commission}
                  />
                  }
                  </div>

                  {
                    affiliateCategory === '' || affiliateCategory === 'Non-Categorized' ?
                    <>
                    <Text className="font-roboto font-bold text-lg text-orange-100 ml-2 mt-4">
                      Product Information
                    </Text>
                    <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-4">
                      This option will set the affiliate as regular partner of the product. 
                      Product commission will be set as default commission of the partner.
                    </Text>
                    <Text className="font-roboto font-bold text-lg text-primary-100 ml-2">
                      {selectedProduct?.title || ''}
                    </Text>
                    <Text className="font-roboto font-semibold text-lg text-secondary-100 ml-2">
                      {selectedProduct?.price ? (
                        <>
                          PHP{' '}
                          {parseFloat(selectedProduct?.price)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </>
                      ) : (
                        ''
                      )}
                    </Text>
                    <Text className="font-roboto font-semibold text-lg text-secondary-100 ml-2 mb-4">
                      {selectedProduct?.commission ? `${selectedProduct?.commission} Commission` : ''}
                    </Text>
                    <DropdownSelect
                      className="text-secondary-100 border border-secondary-100 py-5"
                      color="white"
                      current={selectedProduct?.label || ''}
                      items={productList}
                      onSelect={setSelectedProduct}
                      placeholder="Select a product"
                      width="w-full"
                    />
                    </>
                    :
                    <>
                    <Text className="font-roboto font-bold text-lg text-orange-100 ml-2 mt-4">
                      Product Information
                    </Text>
                    <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                      This option will set the affiliate as categorized partner of 
                      <b>ALL</b> merchant products. Product commission will be set as 
                      <b>ABSOLUTE</b> commission of affiliated products.
                    </Text>
                    <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                      Listed products: <b>{productList.length}</b>
                    </Text>
                    <div className='border border-secondary-100 rounded-lg py-5 px-5'>
                      <Text className='flex flex-row justify-between items-center text-grey-100 font-poppins font-regular'>
                        All Products
                        <i className="fas fa-chevron-down ml-auto" />
                      </Text>
                    </div>
                    </>
                  }

                  <div>                 
                  <Text className="font-roboto font-bold text-lg text-orange-100 ml-2 mt-3">
                    Partner Information
                  </Text>
                  <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                    Enter the information below.
                  </Text>
                  <InputV2
                    label="EMAIL"
                    name="email"
                    onChange={setEmail}
                    placeholder="Enter Partner's Email"
                    type="email"
                    value={email}
                  />
                  <InputV2
                    label="PASSWORD"
                    name="password"
                    onChange={setPassword}
                    placeholder="Enter Partner's Password"
                    type="password"
                    value={password}
                  />
                  <Div className="flex flex-col  ml-10  mb-5 font-poppins text-base">
                    <ul className="list-disc">
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                        }
                      >
                        <Text className=" mb-1">At least 6 characters</Text>
                      </li>
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                        }
                      >
                        <Text className=" mb-1">At least 1 capital letter</Text>
                      </li>
                    </ul>
                    <ul className="list-disc">
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                        }
                      >
                        <Text className=" mb-1">At least 1 number</Text>
                      </li>
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${
                                /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                                  ? 'text-green-100'
                                  : 'text-red-100'
                              }`
                        }
                      >
                        <Text className="mb-1">At least 1 special character</Text>
                      </li>
                    </ul>
                  </Div>
                  <InputV2
                    label="CONFIRM PASSWORD"
                    name="confirm-password"
                    onChange={setConfirmPassword}
                    placeholder="Confirm Password"
                    type="password"
                    value={confirmPassword}
                  />
                  <InputV2
                    label="FIRST NAME"
                    name="first-name"
                    onChange={setFirstName}
                    placeholder="Enter Partner's First Name"
                    value={firstName}
                  />
                  <InputV2
                    label="LAST NAME"
                    name="last-name"
                    onChange={setLastName}
                    placeholder="Enter Partner's Last Name"
                    value={lastName}
                  />
                  <InputV2
                    label="CONTACT NUMBER"
                    maxlength={11}
                    name="contact-number"
                    onChange={setContactNumber}
                    pattern="[0-9]*"
                    placeholder="Enter Partner's Contact Number"
                    type="text"
                    value={contactNumber}
                  />
                  <InputV2
                    label="SOCIAL LINK"
                    name="social-link"
                    onChange={setSocialLink}
                    placeholder="Eg: Facebook, Instagram, Twitter"
                    value={socialLink}
                  />

                  <FlexRow
                    className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      Be sure to complete all fields to create a partner.
                    </Text>
                  </FlexRow>

                  <Div className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg mt-4">
                    {
                      (
                            email === '' ||
                            username === '' ||
                            password === '' ||
                            confirmPassword === '' ||
                            firstName === '' ||
                            lastName === '' ||
                            contactNumber === '' ||
                            socialLink === ''
                      ) ?
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fad fa-spinner-third animate-spin mr-5" />
                        Reading input data...
                      </Text>
                      :
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        Ready to create
                      </Text>
                    }
                    {
                      signUp?
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        Registration completed.
                      </Text>
                      :
                      <></>
                    }
                    {
                      role?
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        Data processing completed.
                      </Text>
                      :
                      <></>
                    }
                    {
                      prod?
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        Affiliated products completed.
                      </Text>
                      :
                      <></>
                    }
                    {
                      loading && affiliateCategory !== '' && affiliateCategory !== 'Non-Categorized' && !prod ?
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fad fa-spinner-third animate-spin text-xl mr-5" />
                        Products: <b>{index}/{productList.length}</b> items completed.
                      </Text>
                      :
                      <></>
                    }
                  </Div>

                  <Div className="h-20 w-2" />

                </div>

                </div>

                <hr />
                <div className='flex mx-8 my-5'>
                  <Button
                    className=" w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    CREATE PARTNER
                  </Button>
                </div>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};