import React, {FC} from 'react';
import { Div, Text, FlexRow } from '@core';

interface DataProps {
	id: number;
	name: string;
	email: string;
	contact: string;
	type: string;
}

export const MerchantAdminData: FC<DataProps> = ({
	id,
	name,
	email,
	contact,
	type,
}) => (
	<>
	<div className='w-full hover:bg-grey-500 my-2 py-2'>
		<Div className='phone:flex phone:flex-row tabletWide:hidden' key={id}>
		  <Div className='phone:flex phone:flex-col phone:w-1/2'>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		       	Admin Name:
		      </Text>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		       	Admin Type:
		      </Text>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		        Email:
		      </Text>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		        Mobile No:
		      </Text>
		  </Div>

		  <Div className='phone:flex phone:flex-col phone:w-1/2'>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		        {name}
		      </Text>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		        {type}
		      </Text>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		        {email}
		      </Text>
		      <Text
		        className="text-base text-secondary-100 font-light w-full text-left"
		      >
		        {contact}
		      </Text>
		  </Div>
		</Div>

		<FlexRow className='w-full justify-start phone:hidden tabletWide:flex'>
			<Text
			  className="text-base text-secondary-200 font-light w-1/4"
			>
			  {name}
			</Text>
			<Text
			  className="text-base text-secondary-200 font-light w-1/4"
			>
			  {type}
			</Text>
			<Text
			  className="text-base text-secondary-200 font-light w-1/4"
			>
			  {email}
			</Text>
			<Text
			  className="text-base text-secondary-200 font-light w-1/4"
			>
			  {contact}
			</Text>
		</FlexRow>
	</div>

	<div className='hidden tabletWide:block w-900px'>
		<hr className='text-grey-400' />
	</div>
	</>
)