import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { Tab } from '@headlessui/react';
import { FaUserTag } from 'react-icons/fa';
import { Div, Flex, FlexRow, FlexCenter, Text, ToastCustom } from '@core';
import { Pagination, ProductData } from '@components';
import { getUserDetails, getProductOfAffiliateFromAdmin } from '@services';
import { useWindowSize } from '@hooks';
import { formatDate2, formatTime, formatTime2, classNames } from '@helpers';

export const UserDetailsAffiliate: FC<any> = ({ user }) => {

	const [userData, setUserData] = React.useState<any>('');
	const [productData, setProductData] = React.useState<any>([]);
	const [isShow, setIsShow] = React.useState(true);
	const [isToastOpen, setToastOpen] = React.useState(false);
	const [newDate, setNewDate] = React.useState('-- --');
	const [newDateRegister, setNewDateRegister] = React.useState<any>('-- --');

	const { search } = useLocation();
	const query = new URLSearchParams(search);
  const lastLogin = query.get('login');
  const type = query.get('type');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentProducts = productData.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const window = useWindowSize();
  const tabs = ['Personal Info', 'Affiliated Products'];

  function checkTabMobile(tab: string) {
    switch (tab) {
      case tabs[0]:
        return <i className='fas fa-user' />;
      case tabs[1]:
        return <div className='flex flex-row justify-center'><FaUserTag /></div>;
      default:
        return <i className='fas fa-user' />;
    }
  }

  function checkTabDesktop(tab: string) {
    switch (tab) {
      case tabs[0]:
        return tabs[0];
      case tabs[1]:
        return `Affilliated Products`;
      default:
        return '';
    }
  }

	React.useEffect(() => {
	  batch(async() => {
	  	setToastOpen(true)
	  	setIsShow(false)
	    const data:any = await getUserDetails(user);
	    if(data?.data?.status === 1){
	    	const newDateA = new Date(data?.data?.user_info?.created_at);

	      setUserData(data?.data)
	      setNewDateRegister(newDateA)
	    }

	    const products:any = await getProductOfAffiliateFromAdmin(user);
	    if(products?.data?.status === 1){
	    	const tempData: any = [...products?.data?.data];
	    	const sortedData: any = await tempData.sort((a: any, b: any) => a.id - b.id);
	    	setProductData(sortedData)
	    }

	    setTimeout(() => {
	      	setIsShow(true)
	      	setToastOpen(false)
	    }, 750)
	  })
	}, [user])

	React.useEffect(() => {
		if(lastLogin && lastLogin !== '-- --'){
			setNewDate(`${formatDate2(lastLogin.substring(0, 10))} at ${formatTime(lastLogin.substring(10, 18))}`)
		}
	}, [lastLogin])
	
return( 
	<>
	{
	userData === '' || !isShow ?
	<ToastCustom
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	>
		<Div className='w-full h-full flex items-center justify-center'>
			<Text className='text-xl text-white font-semibold items-center flex flex-row'>
			  Please wait...
			  <i className="fad fa-spinner-third animate-spin text-2xl mx-4" />
			</Text>
		</Div>
	</ToastCustom>
	:
	<>
	<Text className="font-poppins font-semibold text-2xl text-primary-100 mb-4">
        User Information
  </Text>
  <hr className="text-grey-400 mb-8" />

  <FlexRow className='phone:flex-col items-center tablet:flex-row'>
  	<Div className="h-40 w-40 border-primary-100 border-4 rounded-full overflow-hidden">
  	  <i className="fas fa-user h-full fa-8x text-grey-400 flex items-end justify-center" />
  	</Div>

  	<Div className='w-full tablet:w-4/5 px-4 tablet:px-8 laptop:px-20'>
  		{
        userData?.affiliate_company_info ?
  			<Text className='text-blue-100 font-semibold laptop:text-xl mt-4'>
  				{userData?.affiliate_company_info?.company_name}
  			</Text>
  			:
  			<Text className='text-blue-100 font-semibold laptop:text-xl mt-4'>
  				{`${userData?.user_info?.firstname} ${userData?.user_info?.lastname}`}
  			</Text>
  		}
  		<Text className='text-secondary-200'>
  			{type}
  		</Text>
  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
	  		<Text className='font-semibold mr-auto tablet:mr-4'>
	  			Registered:
	  		</Text>
	  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
	  			{`${formatDate2(newDateRegister)} at ${formatTime2(newDateRegister)}`}
	  		</Text>
  		</FlexRow>
  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
	  		<Text className='font-semibold mr-auto tablet:mr-4'>
	  			Last Login:
	  		</Text>
	  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
	  			{newDate}
	  		</Text>
  		</FlexRow>
  		<FlexRow className='my-4'>
	  		<Text className='font-semibold mr-4'>
	  			Account:
	  		</Text>
	  		{
	  			userData?.email_confirmed?
	  			<Text className='bg-green-100 text-white font-semibold px-4 py-1 rounded-full'>
	  				VERIFIED
	  			</Text>
	  			:
	  			<Text className='bg-red-400 text-white font-semibold px-4 py-1 rounded-full'>
	  				NOT VERIFIED
	  			</Text>
	  		}
  		</FlexRow>
  	</Div>
  </FlexRow>

  <FlexRow className="mt-12">
    <Tab.Group>
      <Flex>
        <Tab.List className="tabletWide:space-x-8">
          {tabs.map((tab) => (
            <Tab
              className={({ selected }) =>
                classNames(
                  'py-3 font-semibold phone:w-1/2 tabletWide:w-auto',
                  selected
                    ? 'text-primary-100 text-xl border-b-4 border-blue-400'
                    : 'text-grey-700 text-xl  hover:text-grey-200',
                )
              }
              key={tab}
            >
              {window.width > 800 ? checkTabDesktop(tab) : checkTabMobile(tab)}
            </Tab>
          ))}
        </Tab.List>
        <hr className="text-grey-1200" />
        <Tab.Panels className="pt-9">

        	{/* Personal Info */}
          <Tab.Panel>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
				  			{
				  				userData?.user_info?.firstname === 'none' ?
				  				<>
				  				<Text className='font-semibold mr-auto tablet:mr-4'>
				  					Company Name:
				  				</Text>
				  				<Text className='text-secondary-200 mr-auto tablet:mr-4'>
				  					{userData?.affiliate_company_info?.company_name}
				  				</Text>
				  				</>
				  				: 
				  				<>
				  				<Text className='font-semibold mr-auto tablet:mr-4'>
				  					First Name:
				  				</Text>
				  				<Text className='text-secondary-200 mr-auto tablet:mr-4'>
				  					{userData?.user_info?.firstname}
				  				</Text>
				  				</>
				  			}
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		{
					  			userData?.user_info?.firstname === 'none' ?
					  			<>
					  			<Text className='font-semibold mr-auto tablet:mr-4'>
					  				Company Link:
					  			</Text>
					  			<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  				{userData?.affiliate_company_info?.company_link}
					  			</Text>
					  			</>
					  			: 
					  			<>
					  			<Text className='font-semibold mr-auto tablet:mr-4'>
					  				Last Name:
					  			</Text>
					  			<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  				{userData?.user_info?.lastname}
					  			</Text>
					  			</>
					  		}
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		{
					  			userData?.user_info?.firstname === 'none' ?
					  			<>
					  			<Text className='font-semibold mr-auto tablet:mr-4'>
					  				Company Email:
					  			</Text>
					  			<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  				{userData?.affiliate_company_info?.company_email}
					  			</Text>
					  			</>
					  			: 
					  			<>
					  			<Text className='font-semibold mr-auto tablet:mr-4'>
					  				Email:
					  			</Text>
					  			<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  				{userData?.user_info?.email}
					  			</Text>
					  			</>
					  		}
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		{
				  				userData?.user_info?.firstname === 'none' ?
				  				<>
				  				<Text className='font-semibold mr-auto tablet:mr-4'>
				  					Company Number:
				  				</Text>
				  				<Text className='text-secondary-200 mr-auto tablet:mr-4'>
				  					{userData?.affiliate_company_info?.contact_number}
				  				</Text>
				  				</>
				  				: 
				  				<>
				  				<Text className='font-semibold mr-auto tablet:mr-4'>
				  					Mobile:
				  				</Text>
				  				<Text className='text-secondary-200 mr-auto tablet:mr-4'>
				  					{userData?.user_info?.contact}
				  				</Text>
				  				</>
				  			}
				  		</FlexRow>
				  		<FlexRow className='my-4 hidden'>
					  		<Text className='font-semibold mr-4'>
					  			Total Conversions:
					  		</Text>
					  		<Text>
					  			{userData?.affiliate_data?.total_conversion}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 hidden'>
					  		<Text className='font-semibold mr-4'>
					  			Total Clicks:
					  		</Text>
					  		<Text>
					  			{userData?.affiliate_data?.totalClicks}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 hidden'>
					  		<Text className='font-semibold mr-4'>
					  			Total Earnings:
					  		</Text>
					  		<Text>
					  			{userData?.affiliate_data?.totalEarned}
					  		</Text>
				  		</FlexRow>
          </Tab.Panel>

        	{/* Affiliated Products */}
          <Tab.Panel>
            {
          		productData.length > 0 ?
          		<>
          		<FlexRow className='w-full justify-between mt-4 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
          			<div className='text-primary-100 font-medium py-2 w-1/12'>
          				Product ID
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/4'>
          				Product Name
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/4'>
          				Product Link
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/12'>
          				Commission
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/12'>
          				Price
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          			<div className='text-primary-100 font-medium py-2 w-1/6'>
          				Merchant
          				<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
          			</div>
          		</FlexRow>
          		
          		{
          			currentProducts.map((product: any, index: number) => (
          				<ProductData
          					commission={product?.commission}
          					id={product?.id}
          					key={index}
          					link={product?.link}
          					merchant={product?.merchant_name}
          					name={product?.name}
          					price={product?.price}
          				/>
          			))
          		}
          		</>
          		:
          		<FlexCenter className="col-span-2 py-12">
          			<Text>
          			  Currently no products listed.
          			</Text>
          		</FlexCenter>
          	}

          	<div className='mb-36 mt-8'>
          		<Pagination
          		  currentPage={currentPage}
          		  itemsPerPage={articlesPerPage}
          		  paginate={paginate}
          		  totalItems={productData.length}
          		/>
          	</div>
          </Tab.Panel>

        </Tab.Panels>
      </Flex>
    </Tab.Group>
  </FlexRow>

  </>
	}
  </>
 )
}; 