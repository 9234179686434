import axios from 'axios';
import FormData from 'form-data';
import { API } from '@config';
import { getCookie } from '@hooks';

interface REGISTER {
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
  refferal_id?: any;
  xffiliate_code?: any;
  wixInstance?: any;
}

interface CREATESA {
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
  firstname: string;
  lastname: string;
  contact: string;
}

interface LOGIN {
  email: string;
  password: string;
}

interface ROLE {
  is_company: boolean;
  user_id: string;
  firstname: string;
  lastname: string;
  contact: string;
  role: string;
  company_name?: string;
  company_link?: string;
  company_email?: string;
  contact_number?: string;
  social_link: string;
  store_name?: string;
  store_link?: string;
}

interface PURPOSE {
  user_id: string;
  min_budget: string;
  max_budget: string;
  purpose_xffiliate_links: string;
  icon: any;
  name: string;
  description: string;
}

interface COMPANYINFO {
  name: string;
  description: string;
  icon: any;
}

interface CHANGEEMAIL {
  email: string;
  code: string;
}

interface PERMISSION {
  user_id: number;
  user_create: number;
  user_view: number;
  user_archive: number;
  user_update: number;
  transaction_view: number;
  transaction_write: number;
  disbursement_view: number;
  disbursement_write: number;
  account_view: number;
  account_write: number;
  permission_write: number;
}

interface SUBSCRIPTION {
  description: string;
  amount: number;
}

export const addUser = async (body: REGISTER) =>
  axios.post(`${API}/register`, body).then((response) => response);

export const authentication = async (body: LOGIN) =>
  axios.post(`${API}/login`, body).then((response) => response);

export const authentication2 = async (body: { email: string; password: string }) => {
  const something: any[] = [];
  something.push(body);
  return axios
    .put(`${API}/login`, { email: body.email, password: body.password })
    .then((response) => response);
};

export const authenticationAdmin = async (body: LOGIN) =>
  axios.post(`${API}/login-admin`, body).then((response) => response);

export const authenticationWixUsesr = async (body: { wixInstance: string }) =>
  axios.post(`${API}/wix-instance-login`, body).then((response) => response);

export const addRole = async (body: ROLE) =>
  axios.post(`${API}/add-role`, body).then((response) => response);

export const addPurpose = async (body: PURPOSE) => {
  const form = new FormData();
  form.append('user_id', body.user_id);
  form.append('min_budget', body.min_budget);
  form.append('max_budget', body.max_budget);
  form.append('purpose_xffiliate_links', body.purpose_xffiliate_links);
  form.append('icon', body.icon[0]);
  form.append('name', body.name);
  form.append('description', body.description);

  return axios({
    method: 'POST',
    url: `${API}/add-purpose`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((response) => response);
};

export const updateCompanyInfo = async (body: COMPANYINFO) => {
  const token = getCookie('token');
  const form = new FormData();
  form.append('name', body.name);
  form.append('description', body.description);
  form.append('icon', body?.icon ? body?.icon[0] : '');

  return axios({
    method: 'POST',
    url: `${API}/update-company`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const requestEmail = async (email: string) => {
  const token = getCookie('token');

  return axios({
    method: 'POST',
    url: `${API}/change-email-request`,
    data: {
      email,
    },
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const changeEmail = async (body: CHANGEEMAIL) => {
  const token = getCookie('token');

  return axios({
    method: 'POST',
    url: `${API}/change-email`,
    data: body,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const createPermissions = async (body: PERMISSION) => {
  const token = getCookie('token');

  return axios({
    method: 'POST',
    url: `${API}/create-superadmin-permissions`,
    data: body,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const createSuperAdmin = async (body: CREATESA) => {
  const token = getCookie('token');

  return axios({
    method: 'POST',
    url: `${API}/create-superadmin`,
    data: body,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};

export const subscription2 = async (body: SUBSCRIPTION) => {
  const token = getCookie('token');

  return axios({
    method: 'POST',
    url: `${API}/create-xendit-recurring`,
    data: body,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};
