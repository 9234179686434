import React, {FC} from 'react';
import { Popover } from '@headlessui/react';
import { Div, Text} from '@core';

interface DataProps {
	date: string;
	email: string;
	amount: string;
	name: string;
	key: number;
}

export const ConversionLeadsData: FC<DataProps> = ({
	date,
	email,
	amount,
	name,
	key,
}) => (
<>
	<div className='phone:flex phone:flex-row tabletWide:block' key={key}>
	  
	  <Div className='phone:flex phone:flex-col phone:w-1/2 tabletWide:hidden'>
	      <Text
	        className=" text-base text-secondary-200 font-light w-full text-left tabletWide:text-center"
	      >
	        Name:
	      </Text>
	      <Text
	        className=" text-base text-secondary-200 font-light w-full text-left tabletWide:text-center"
	      >
	        Email:
	      </Text>
	      <Text
	        className=" text-base text-secondary-200 font-light w-full text-left tabletWide:text-center"
	      >
	        Transaction Date:
	      </Text>
	      <Text
	        className=" text-base text-secondary-200 font-light w-full text-left tabletWide:text-center hidden"
	      >
	        Amount:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2 tabletWide:flex-row tabletWide:w-full' >
	      <Text
	        className=" text-base text-secondary-200 font-light w-full tabletWide:w-1/3 text-left tabletWide:text-center"
	      >
	        {name}
	      </Text>
	      <Popover className="relative w-full tabletWide:w-1/3 flex flex-row tabletWide:justify-center">
	            <Popover.Button className=' w-full'>
	                <Text className='text-blue-100 truncate w-full phone:text-left tabletWide:text-center tabletWide:px-2'>
	                  {email}
	                </Text>
	            </Popover.Button>
	            <Popover.Panel className="absolute z-10 right-0 top-full mt-2 text-secondary-200 border-2 border-primary-100 bg-white px-2 rounded w-64 py-4">
	            	<Text className='text-sm text-secondary-200'>
	            		Email:
	            	</Text>
	            	<hr className='text-grey-400' />
	            	<Text className='text-sm text-secondary-200 break-words mt-4'>
	            		{email}
	            	</Text>
	            </Popover.Panel>
	      </Popover>
	      <Text
	        className=" text-base text-secondary-200 font-light w-full tabletWide:w-1/3  text-left tabletWide:text-center"
	      >
	        {date.substring(0, 10)}
	      </Text>
	      <Text
	        className=" text-base text-secondary-200 font-light w-full tabletWide:w-1/4  text-left tabletWide:text-center hidden"
	      >
	        {parseFloat(amount)? `P ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : amount}
	      </Text>
	  </Div>

	</div>
	
	<hr className="text-grey-400 my-4" />
</>
	)