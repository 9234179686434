import React, { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

interface Links {
  name: string;
  icon: string;
  url: string;
}

interface MenuItems {
  name: string;
  links: Links[];
}

interface DropdownProps {
  current: string;
  className?: string;
  color: string;
  width?: string;
  items?: MenuItems[];
}

export const Dropdown: FC<DropdownProps> = ({ className, current, items, color, width }) => (
  <div className={`${width || 'w-56'} text-right`}>
    <Menu as="div" className={`${width || 'w-56'} relative inline-block text-left`}>
      <div>
        <Menu.Button
          className={` inline-flex items-center justify-center w-full laptop:px-5 phone:py-3
           laptop:py-3 font-poppins text-base font-regular  bg-${color || 'blue-100'} 
           rounded-md hover:bg-opacity-20 ring-4 phone:text-sm phone:px-3 h-full
           ring-${color} ${className}`}
        >
          {current}
          <i className="fas fa-chevron-down ml-auto" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 mt-1 origin-top-right font-poppins
          bg-white divide-y ${width || 'w-56'} divide-secondary-300 
          rounded-md shadow-lg`}
        >
          {items?.map((value) => (
            <div className="px-1 py-1 " key={value.name}>
              {value.links.map((link) => (
                <Menu.Item key={link.name}>
                  {({ active }) => (
                    <Link
                      className={`${
                        active ? 'bg-primary-100 text-white' : 'text-secondary-100'
                      }  group flex rounded-md items-center
                    w-full px-4 py-3 laptop:text-base phone:text-sm`}
                      to={link.url}
                    >
                      <i className={`${link.icon} mr-4 hidden tablet:inline`} />
                      {link.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);