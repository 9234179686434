import React, { FC } from 'react';
import { Transition, Popover } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { batch } from 'react-redux';
import { ResponsiveRadar } from '@nivo/radar';
import { Pie } from "@nivo/pie";
import { Overview } from './Overview';
import { Charts } from './Charts';
import { usersActions, useAppDispatch } from '@redux';
import { Div, FlexRow, Text, Select, FilterDropdown } from '@src/core';
import { ChartsAll, FilterReport } from '@constants';

export const AnalyticsAdmin: FC<any> = ({
  affiliates, clicks, conversions, netRevenue, paidCommission,
  pendingCommission, pendingRevenue, totalCommission, totalRevenue,
  user, productData, productKeys, productPie, partnerData,
  partnerKeys, partnerPie
} : any) => {

  const dispatch = useAppDispatch();

  const [productList, setProductList] = React.useState<any[]>([]);
  const [partnerList, setPartnerList] = React.useState<any[]>([]);

  const [selectedChart, setSelectedChart] = React.useState<any>('');

  const [percentageRevenue, setPercentageRevenue] = React.useState(0);
  const [percentageCommission, setPercentageCommission] = React.useState(0);
  const [dateFunc, setDateFunc] = React.useState(false);
  const [isShowProduct, setIsShowProduct] = React.useState(false);
  const [isShowPartner, setIsShowPartner] = React.useState(false);

  const date: any = new Date();
  const Year: any = date.getFullYear();
  const zero: any = '0';
  const month: any = (1 + date.getMonth()).toString();
  const Month: any = month.length > 1 ? month : zero.concat(month);
  const day: any = date.getDate().toString();
  const Day = day.length > 1 ? day : zero.concat(day);
  const DateToday: any = Year.toString().concat('-', Month, '-', Day);
  const [displayDate, setDisplayDate] = React.useState(DateToday);
  const [finalDate, setFinalDate] = React.useState(DateToday);

  const [report, setReport] = React.useState({
    label: 'Present',
    value: '0',
  });

  const commonProperties = {
    height: 350,
    margin: { top: 50, right: 20, bottom: 10, left: 20 }
  };

  const colors = ['#3173AF', '#FF733F', '#FBDD8C', '#F37777', '#63C9A8'];
  const radTheme = {
    // "background": "#F5F6F7",
    textColor: '#9C9C9C',
    fontFamily: 'Poppins',
    fontSize: 12,
    legends: {
      text: {
        fontSize: 14,
        fill: '#333333',
      },
    },
  };

  const onDate = () => {
    setDateFunc(!dateFunc)
  }

  React.useEffect(() => {
    batch(async() => {
      await dispatch(usersActions.overallAnalyticsAdmin(user?.user_info?.merchant_id));
      setTimeout(() => {
        setSelectedChart('Clicks')
      }, 1500)
    })
  }, [user])

  React.useEffect(() => {
    if(parseFloat(report.value) > 0){
      const result = new Date(DateToday);
      const days = parseFloat(report.value);
      const timestamp = result.setDate(result.getDate() - days)
      const fixedDate = new Date(timestamp)
      const newDay = fixedDate.getDate();
      const newMonth = 1+fixedDate.getMonth();
      const newYear = fixedDate.getFullYear();

      setDisplayDate(`${newYear}-${newMonth > 9 ? newMonth : `0${newMonth}`}-${newDay > 9 ? newDay : `0${newDay}`}`)
      setFinalDate(DateToday)
      setDateFunc(false)
    }else{
      setDisplayDate('')
      setFinalDate('')
      setDateFunc(false)
    }
  }, [report])


  React.useEffect(() => {
    batch(async () => {
      const filterProducts: any = [];
      for (let i = 0; i < productKeys.length; i++) {
        filterProducts.push({
          label: productKeys[i],
          color: colors[i]
        });
        if (i === productKeys.length - 1) {
          setProductList(filterProducts);
          setIsShowProduct(true);
        }
        if(i === colors.length - 1){
          colors.push('#3173AF', '#FF733F', '#FBDD8C', '#F37777', '#63C9A8')
        }
      }

      const filterPartners: any = [];
      for (let i = 0; i < partnerKeys.length; i++) {
        filterPartners.push({
          label: partnerKeys[i],
          color: colors[i],
        });
        if (i === partnerKeys.length - 1) {
          setPartnerList(filterPartners);
          setIsShowPartner(true);
        }
        if(i === colors.length - 1){
          colors.push('#3173AF', '#FF733F', '#FBDD8C', '#F37777', '#63C9A8')
        }
      }
    });
  }, [productKeys, partnerKeys]);

  React.useEffect(() => {
    if(pendingRevenue === 0 && totalRevenue === 0){
      setPercentageRevenue(0.00)
    }else{
      setPercentageRevenue(pendingRevenue/totalRevenue*100)
    }
  })

  React.useEffect(() => {
    if(pendingCommission === 0 && netRevenue === 0){
      setPercentageCommission(0.00)
    }else{
      setPercentageCommission(pendingCommission/netRevenue*100)
    }
  })

  return (
    <Div className="grid gap-y-8 pb-36 w-full">
      <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100">
        OVERVIEW
      </Text>
      
      <FlexRow
        className="laptop:flex-row phone:flex-col laptop:space-x-5 phone:space-y-5 
          laptop:space-y-0 phone:space-x-0 w-full"
      >
        <Overview icon="users" label="AFFILIATE PARTNERS" value={affiliates} />
        <Overview icon="mouse-pointer" label="TOTAL CLICKS" value={clicks} />
        <Overview icon="repeat" label="TOTAL CONVERSIONS" value={conversions} />
      </FlexRow>

      <Link className='laptop:hidden font-poppins text-blue-100 hover:underline my-4 hidden' title='Go to conversion reports page.' to='/merchant/admin/reports/conversion'>
        See More Reports
        <i className='fas fa-angle-right ml-2' />
        <i className='fas fa-angle-right' />
      </Link>

      <FlexRow className='justify-between items-center mt-8 mb-4'>
      <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100">
        REVENUE & COMMISSIONS SUMMARY
      </Text>
      <Link className='hidden laptop:hidden flex-row items-center font-poppins text-blue-100 hover:underline' title='Go to Conversion Reports page.' to='/merchant/admin/reports/conversion'>
        See More Reports
        <i className='fas fa-angle-right ml-2' />
        <i className='fas fa-angle-right' />
      </Link>
      </FlexRow>

      <FlexRow className="gap-x-10 phone:flex-col items-start tablet:flex-row">
        <Div className="self-start phone:w-full laptop:w-1/2 py-2">
          <Text className="text-grey-100 tracking-wider w-fit mb-2">
            Net Revenue
            <Popover className="relative inline">
                  <Popover.Button>
                        <i className="fas fa-question text-xs ml-4 px-1 rounded-full bg-blue-100 text-white border-4 border-blue-100" />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 phone:-right-8 medium:-left-8">
                    <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 medium:w-350px">
                      <Text className='font-semibold text-sm text-secondary-200'>
                        Total Revenue
                      </Text>
                      <hr className='text-secondary-200 my-1'/>
                      <Text className='text-sm my-2 text-secondary-200'>
                        Gross Revenue: &#8369; {totalRevenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text className='text-sm my-2 text-secondary-200'>
                        New Sales: &#8369; {pendingRevenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text className='text-sm my-2 text-secondary-200'>
                        Paid Commissions: &#8369; {paidCommission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text className='text-sm my-2 text-secondary-200'>
                        Net: &#8369; {netRevenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text className='mt-4 text-sm text-secondary-200'>
                        ** Click anywhere on screen to close. **
                      </Text>
                    </div>
                  </Popover.Panel>
            </Popover>
          </Text>
          <Text className="text-dark-100 font-bold text-2xl w-fit">&#8369; {netRevenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
          <Text className="pt-2 text-green-400 text-xs">
            <i className="fas fa-arrow-up mr-2" />
            +{Math.round(percentageRevenue)}%
          </Text>
        </Div>
        <Div className="self-start phone:w-full laptop:w-1/2 py-2">
          <Text className="text-grey-100 tracking-wider mb-2">
            Total Commissions Due
            <Popover className="relative inline">
                  <Popover.Button>
                        <i className="fas fa-question text-xs ml-4 px-1 rounded-full bg-blue-100 text-white border-4 border-blue-100" />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 phone:right-0 medium:-left-8">
                    <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 medium:w-350px">
                      <Text className='font-semibold text-sm text-secondary-200'>
                        Total Pending Commissions
                      </Text>
                      <hr className='text-secondary-200 my-1'/>
                      <Text className='text-sm my-2 text-secondary-200'>
                        Gross Revenue: &#8369; {totalRevenue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text className='text-sm my-2 text-secondary-200'>
                        New Commissions to disburse: &#8369; {pendingCommission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text className='text-sm my-2 text-secondary-200'>
                        Total Commissions paid: &#8369; {totalCommission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                      <Text className='text-sm my-2 text-secondary-200'>
                        Percentage: &#8369; {percentageCommission}%
                      </Text>
                      <Text className='mt-4 text-sm text-secondary-200'>
                        ** Click anywhere on screen to close. **
                      </Text>
                    </div>
                  </Popover.Panel>
            </Popover>
          </Text>
          <FlexRow>
            <Text className="text-dark-100 font-bold text-2xl"> &#8369; {pendingCommission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
          </FlexRow>
          <Text className="pt-2 text-grey-100 text-xs hidden">
            Next disbursement cut-off: <b className="font-medium text-blue-400">--</b>
          </Text>
          <Text className="pt-2 text-secondary-200 text-xs">
            {Math.round(percentageCommission)}% from Total Gross Sales
          </Text>
        </Div>
      </FlexRow>

      <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100">
        ANALYTICS
      </Text>

      <FlexRow className='phone:flex-col phone:items-start laptop:flex-row laptop:items-center laptop:justify-between w-full'>
        <FlexRow className='phone:flex-col surfaceDuo:flex-row pr-4 pl-4 surfaceDuo:pl-0 surfaceDuo:items-center'>
          <Text className='text-primary-100 font-semibold mr-auto surfaceDuo:mr-4'>
            Chart:
          </Text>
          <Select
            className='phone:py-4 surfaceDuo:w-72 laptop:w-auto'
            label=""
            name="selectedMethod"
            onChange={setSelectedChart}
            options={ChartsAll}
            placeholder="Choose..."
            value={selectedChart}
          />
        </FlexRow>

        <FlexRow className='phone:flex-col surfaceDuo:flex-row laptop:ml-auto mb-4'>
         {
          report.value !== '0' ?
          <FlexRow className='phone:flex-col surfaceDuo:flex-row'>
          <FlexRow className='phone:flex-col surfaceDuo:flex-row'>
            <Text className='text-primary-100 font-semibold mb-2 mr-auto surfaceDuo:mr-4 ml-4 surfaceDuo:ml-0 surfaceDuo:block laptop:ml-0 laptop:mt-4'>
              From:
            </Text>
            <input className='phone:mx-4 surfaceDuo:mx-1 py-5 px-2 mb-4 surfaceDuo:mb-0 border border-primary-100 text-primary-100 rounded-lg cursor-not-allowed bg-grey-500 phone:w-64 surfaceDuo:w-44' disabled title='Must reset filter first' type='date' value={displayDate} />
          </FlexRow>
          <FlexRow className='phone:flex-col surfaceDuo:flex-row'>
            <Text className='text-primary-100 font-semibold mb-2 mr-auto surfaceDuo:mr-4 ml-4 surfaceDuo:ml-2 surfaceDuo:block laptop:ml-0 laptop:mt-4'>
              To:
            </Text>
            <input className='phone:mx-4 surfaceDuo:mx-1 py-5 px-2 mb-4 surfaceDuo:mb-0 border border-primary-100 text-primary-100 rounded-lg cursor-not-allowed bg-grey-500  phone:w-64 surfaceDuo:w-44' disabled title='Must reset filter first' type='date' value={finalDate} />
          </FlexRow>
          <button className='bg-primary-100 hover:bg-blue-100 text-white font-semibold flex flex-row items-center justify-center rounded-lg p-5 phone:w-64 surfaceDuo:w-auto hidden' onClick={onDate}>
             GO
          </button>
          </FlexRow>
          :
          <FlexRow className='phone:flex-col surfaceDuo:flex-row'>
          <FlexRow className='phone:flex-col surfaceDuo:flex-row'>
            <Text className='text-primary-100 font-semibold mb-2 mr-auto surfaceDuo:mr-4 ml-4 surfaceDuo:ml-0 surfaceDuo:block laptop:ml-0 laptop:mt-4'>
              From:
            </Text>
            <input className='phone:mx-4 surfaceDuo:mx-1 py-5 px-2 mb-4 surfaceDuo:mb-0 border border-primary-100 text-primary-100 rounded-lg cursor-pointer hover:bg-grey-500  phone:w-64 surfaceDuo:w-44' onChange={(e:any) => setDisplayDate(e.target.value)} title='Enter reference date.' type='date' value={displayDate} />
          </FlexRow>
          <FlexRow className='phone:flex-col surfaceDuo:flex-row'>
            <Text className='text-primary-100 font-semibold mb-2 mr-auto surfaceDuo:mr-4 ml-4 surfaceDuo:ml-2 surfaceDuo:block laptop:ml-0 laptop:mt-4'>
              To:
            </Text>
            <input className='phone:mx-4 surfaceDuo:mx-1 py-5 px-2 mb-4 surfaceDuo:mb-0 border border-primary-100 text-primary-100 rounded-lg cursor-pointer hover:bg-grey-500  phone:w-64 surfaceDuo:w-44' onChange={(e:any) => setFinalDate(e.target.value)} title='Enter reference date.' type='date' value={finalDate} />
          </FlexRow>
          <button className='bg-primary-100 hover:bg-blue-100 text-white font-semibold flex flex-row items-center justify-center rounded-lg p-5 phone:w-64 surfaceDuo:w-auto hidden' onClick={onDate}>
             GO
          </button>
          </FlexRow>
         }
         </FlexRow>
      </FlexRow>

      <FlexRow className='mb-4'>
         {report.value === '0' ? (
           <FilterDropdown
             className='phone:px-4 surfaceDuo:px-0'
             classNameBody='border border-primary-100 rounded-lg z-10 left-0'
             color="white"
             current={report?.label || ''}
             items={FilterReport}
             onSelect={setReport}
             placeholder="Select a Range"
           />
         ) : (
           <>
             <div
               className={`inline-flex items-center justify-center phone:px-4 surfaceDuo:px-0 border-0
        py-3 font-poppins text-base font-regular text-primary-100
        hover:text-red-100 cursor-pointer`}
               onClick={() => setReport({
                  label: DateToday,
                  value: '0',
               })}
             >
               Reset
               <i className="fas fa-filter text-2xl ml-1" />
             </div>
           </>
         )}
      </FlexRow>

      <Charts
        chart={selectedChart}
        date={DateToday}
        finalDate={finalDate}
        initDate={displayDate}
        onDate={dateFunc}
        value={report.value}
      />

      <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100">
        PRODUCTS
      </Text>

      <FlexRow className="w-full phone:flex-col laptop:flex-row">
        <Div className="w-full h-96 my-8 laptop:w-1/2 laptop:mr-2">
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline my-2'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Top Performing Products:
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-72 shadow-xl p-3 rounded-lg border-2 border-grey-400">
                      {
                        productList.length > 0?
                        <></>
                        :
                        <Text className='text-primary-100 text-sm my-2'>
                          No data for top performing products.
                        </Text>
                      }
                      {
                        isShowProduct?
                        <>
                        {
                          productList.map((product: any) => (
                            <FlexRow className='text-secondary-200 text-sm my-1' key={product.label}>
                              <i className='fas fa-circle pr-2 text-xs w-1/12' style={{color: product.color}} />
                              <span className='w-11/12'>
                                {product.label}
                              </span>
                            </FlexRow>
                            ))
                        }
                        </>
                        :
                        <></>
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <Transition
            appear
            className="w-full h-full bg-grey-500 rounded-md"
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            show
          >
            <ResponsiveRadar
              colors={colors}
              {...commonProperties}
              curve="cardinalClosed"
              data={productData}
              dotSize={8}
              gridLabelOffset={30}
              indexBy="stat"
              keys={productKeys}
              legends={[]}
              theme={radTheme}
            />
          </Transition>
        </Div>
        <Div className="w-full h-96 my-8 laptop:w-1/2 laptop:ml-2">
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline my-2'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Most Popular Products per Click:
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-72 shadow-xl p-3 rounded-lg border-2 border-grey-400">
                      {
                        productList.length > 0?
                        <></>
                        :
                        <Text className='text-primary-100 text-sm my-2'>
                          No data for top performing products.
                        </Text>
                      }
                      {
                        isShowProduct?
                        <>
                        {
                          productList.map((product: any) => (
                            <FlexRow className='text-secondary-200 text-sm my-1' key={product.label}>
                              <i className='fas fa-circle pr-2 text-xs w-1/12' style={{color: product.color}} />
                              <span className='w-11/12'>
                                {product.label}
                              </span>
                            </FlexRow>
                            ))
                        }
                        </>
                        :
                        <></>
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <Transition
            appear
            className="w-full h-full bg-grey-500 rounded-md"
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            show
          >
            <FlexRow className='w-full h-full justify-center items-center'>
            <Pie
                activeInnerRadiusOffset={8}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'brighter',
                            2
                        ]
                    ]
                }}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                colors={colors}
                cornerRadius={3}
                data={productPie}
                enableArcLinkLabels={false}
                height={300}
                innerRadius={0.7}
                padAngle={0.7}
                width={300}
            />
            </FlexRow>
          </Transition>
        </Div>
      </FlexRow>

      <Text className="font-poppins font-bold tablet:text-2xl phone:text-xl text-primary-100 mt-4">
        PARTNERS
      </Text>

      <FlexRow className="w-full phone:flex-col laptop:flex-row">
        <Div className="w-full h-96 my-8 laptop:w-1/2 laptop:mr-2">
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline mb-2'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Top Performing Partners:
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-72 shadow-xl p-3 rounded-lg border-2 border-grey-400">
                      {
                        partnerList.length > 0?
                        <></>
                        :
                        <Text className='text-primary-100 text-sm my-2'>
                          No data for top performing partners.
                        </Text>
                      }
                      {
                        isShowPartner?
                        <>
                        {
                          partnerList.map((partner: any) => (
                            <FlexRow className='text-secondary-200 text-sm my-1' key={partner.label}>
                              <i className='fas fa-circle pr-2 text-xs w-1/12' style={{color: partner.color}} />
                              <span className='w-11/12'>
                                {partner.label}
                              </span>
                            </FlexRow>
                            ))
                        }
                        </>
                        :
                        <></>
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <Transition
            appear
            className="w-full h-full bg-grey-500 rounded-md"
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            show
          >
            <ResponsiveRadar
              colors={colors}
              {...commonProperties}
              curve="cardinalClosed"
              data={partnerData}
              dotSize={8}
              gridLabelOffset={30}
              indexBy="stat"
              keys={partnerKeys}
              legends={[]}
              theme={radTheme}
            />
          </Transition>
        </Div>
        <Div className="w-full h-96 my-8 laptop:w-1/2 laptop:ml-2">
          <Popover className="relative">
                  <Popover.Button>
                      <Text className='text-primary-100 hover:underline mb-2'>
                        <i className="fas fa-chart-line text-primary-100 mr-2" />
                        Top Performing Partners per Conversion:
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 left-0">
                    <Div className="bg-white w-72 shadow-xl p-3 rounded-lg border-2 border-grey-400">
                      {
                        partnerList.length > 0?
                        <></>
                        :
                        <Text className='text-primary-100 text-sm my-2'>
                          No data for top performing partners.
                        </Text>
                      }
                      {
                        isShowPartner?
                        <>
                        {
                          partnerList.map((partner: any) => (
                            <FlexRow className='text-secondary-200 text-sm my-1' key={partner.label}>
                              <i className='fas fa-circle pr-2 text-xs w-1/12' style={{color: partner.color}} />
                              <span className='w-11/12'>
                                {partner.label}
                              </span>
                            </FlexRow>
                            ))
                        }
                        </>
                        :
                        <></>
                      }
                    </Div>
                  </Popover.Panel>
          </Popover>
          <Transition
            appear
            className="w-full h-full bg-grey-500 rounded-md"
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            show
          >
            <FlexRow className='w-full h-full justify-center items-center'>
            <Pie
                activeInnerRadiusOffset={8}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'brighter',
                            2
                        ]
                    ]
                }}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                colors={colors}
                cornerRadius={3}
                data={partnerPie}
                enableArcLinkLabels={false}
                height={300}
                innerRadius={0.7}
                padAngle={0.7}
                width={300}
            />
            </FlexRow>
            <FlexRow className='w-full h-full items-center justify-center hidden'>
              <Text>
                No chart data
              </Text>
            </FlexRow>
          </Transition>
        </Div>
      </FlexRow>
    </Div>
  );
};