const DEVAPI = 'https://xffiliate-api.xtendly.com/api';
const PRODAPI = 'https://api.xffiliate.com/api';
const PROD = 'https://app.xffiliate.com/';
const DEV = 'https://xffiliate.xtendly.com/';
const DEVWEB = 'https://productshare-dev.xtendly.com/';
const PRODWEB = 'https://share.xffiliate.com/';

export const API =
  process.env.REACT_APP_HOST_TYPE?.trim() === 'development' || !process.env.REACT_APP_HOST_TYPE
    ? DEVAPI
    : PRODAPI;
export const MAIN =
  process.env.REACT_APP_HOST_TYPE?.trim() === 'development' || !process.env.REACT_APP_HOST_TYPE
    ? DEV
    : PROD;
export const SHARE =
  process.env.REACT_APP_HOST_TYPE?.trim() === 'development' || !process.env.REACT_APP_HOST_TYPE
    ? DEVWEB
    : PRODWEB;