import React, { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, LinkedinShareButton, LinkedinIcon } from "react-share";
import { FlexRow, Text, Button } from '@core';
import { AffiliateAssetData } from '@components';
import { getAffiliateAssetByProduct } from '@services';

interface ButtonProps {
  className?: string;
  title: string;
  onClose: any;
  description: string;
  isOpen: boolean;
  toastKind: string;
  product: any;
  url: string;
}

export const ToastAssets: FC<ButtonProps> = ({
  title, description, onClose, isOpen, toastKind, product, url
}) => {
  let color = 'green-100';

  let icon = 'fa-check';
  if (toastKind === 'success') {
    color = 'green-100';
    icon = 'fa-check';
  } else if (toastKind === 'error') {
    color = 'red-100';
    icon = 'fa-times';
  } else if (toastKind === 'warning') {
    color = 'yellow-100';
    icon = 'fa-exclamation';
  } else if (toastKind === 'info') {
    color = 'primary-100';
    icon = 'fa-exclamation';
  }

  const navigate = useNavigate();
  const [isShow, setIsShow] = React.useState(false);
  const [assetList, setAssetList] = React.useState<any>([]);
  const [assetList2, setAssetList2] = React.useState<any>([]);
  const [noHide, setNoHide] = React.useState(false);

  React.useEffect(() => {
    if(isOpen){
      setIsShow(false)
      batch(async() => {
        const assets = await getAffiliateAssetByProduct(product.product_id);
        if(assets.data.status === 1){
          const requestedAssets: any[] = [];
          const merchantAssets: any[] = [];
          assets.data.data.forEach((list: any) => {
            if(list.asset_status === 'Approved'){
              requestedAssets.push(list)
            }else if(list.asset_status === 'Created'){
              merchantAssets.push(list)
            }
          })
          setAssetList(requestedAssets)
          setAssetList2(merchantAssets)
          setIsShow(true)
        }else{
          setAssetList([])
          setIsShow(true)
        }
      })
    }
  }, [isOpen])

  const onRefresh = () => {
    setIsShow(false)
    batch(async() => {
      const assets = await getAffiliateAssetByProduct(product.product_id);
      if(assets.data.status === 1){
        const requestedAssets: any[] = [];
        const merchantAssets: any[] = [];
        assets.data.data.forEach((list: any) => {
          if(list.asset_status === 'Approved'){
            requestedAssets.push(list)
          }else if(list.asset_status === 'Created'){
            merchantAssets.push(list)
          }
        })
        setAssetList(requestedAssets)
        setAssetList2(merchantAssets)
        setIsShow(true)
      }else{
        setAssetList([])
        setIsShow(true)
      }
    })
  }

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-60 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="phone:w-full tablet:w-5/6 tabletWide:w-4/6 phone:w-full h-screen text-center">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-60 phone:px-2 mobile:px-5 pt-5
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <div
                className={`flex flex-col tablet:justify-around phone:p-2 mobile:p-4 mb-4 text-gray-500 w-full
            bg-white border-4 border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins bg-white phone:h-full tablet:h-auto overflow-y-auto scrollbar-hide`}
                id="toast-success"
                role="alert"
              >
              <FlexRow className='w-full hidden'>
                <div
                  className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white bg-${color} rounded-lg mr-1`}
                >
                  <i className={`fas ${icon}`} />
                </div>
                <div>
                  <div className={`ml-3 font-semibold text-base text-${color}`}>
                    {title.toUpperCase()}
                  </div>
                  <div className="ml-3 text-sm font-normal text-primary-100">{description}</div>
                </div>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
              </FlexRow>

              <FlexRow className='w-full justify-end'>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
              </FlexRow>

              <FlexRow className='w-full tablet:mt-6 phone:flex-col tablet:flex-row'>
                <FlexRow className='phone:w-full tablet:w-1/2 laptop:w-1/3 tablet:h-96 px-4 flex-col justify-between tablet:justify-center'>
                  <div className='mr-auto'>
                    <Text className='text-2xl text-secondary-200 font-semibold'>
                      Share your Link
                    </Text>
                    <FlexRow className='w-full items-center my-4'>
                      <FacebookShareButton
                        className="mr-2"
                        url={url}
                      >
                        <FacebookIcon round size={40} />
                      </FacebookShareButton>

                      <TwitterShareButton
                        className='mx-2'
                        url={url}
                      >
                        <TwitterIcon round size={40} />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        className='ml-2'
                        summary='Summary test'
                        title='Title test'
                        url={url}
                      >
                        <LinkedinIcon round size={40} />
                      </LinkedinShareButton>
                    </FlexRow>
                  </div>
                  <div>
                    <Text className='text-secondary-200'>
                      Create your own marketing asset to personlize your 
                      content sharing for your audience
                    </Text>
                    <Button
                      className='w-full justify-center items-center my-4 bg-blue-100'
                      onClick={() => {
                        onClose()
                        navigate('/affiliate/dashboard?asset=create')
                      }}
                    >
                      CREATE YOUR OWN ASSET
                    </Button>
                  </div>
                </FlexRow>

                <div className='phone:w-full tablet:w-1/2 laptop:w-2/3 tablet:h-96 px-4 overflow-y-auto'>
                  <FlexRow className='w-full phone:flex-col tablet:flex-row justify-between mb-2'>
                    <Text className='text-primary-100 text-xs mr-auto tablet:mr-0 desktop:text-sm hover:underline'>
                      Lists ({assetList.length+assetList2.length})
                    </Text>
                    <div
                      className='text-primary-100 text-xs mr-auto tablet:mr-0 desktop:text-sm font-robot font-regular hover:underline cursor-pointer'
                      onClick={() => setNoHide(!noHide)}
                    >
                      Show all hidden
                    </div>
                  </FlexRow>

                  <Text className='text-primary-100 desktop:text-lg font-semibold mb-2'>
                    Merchant Marketing Assets
                  </Text>
                  {
                      assetList2.length > 0 && isShow ?
                      assetList2.map((list: any, index: number) => (
                        <AffiliateAssetData
                          content={list.content}
                          key={index}
                          nohide={noHide}
                          title={list.title}
                        />
                      ))
                      :
                      <FlexRow className="mt-2 py-4 bg-grey-500 phone:flex-col justify-center items-center">
                      {
                        !isShow?
                        <Text>
                          Loading data ...
                          <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                        </Text>
                        :
                        <>
                        <Text>No marketing asset at this time.</Text>
                        <div className='text-sm text-blue-100 hover:underline cursor-pointer hidden' onClick={onRefresh}>
                          Click to reload
                          <i className="fas fa-repeat mx-3" />
                        </div>
                        </>
                      }
                      </FlexRow>
                  }
                  <Text className='text-primary-100 desktop:text-lg font-semibold mb-2 mt-8'>
                    Your Marketing Assets
                  </Text>
                  {
                      assetList.length > 0 && isShow ?
                      assetList.map((list: any, index: number) => (
                        <AffiliateAssetData
                          content={list.content}
                          key={index}
                          nohide={noHide}
                          title={list.title}
                        />
                      ))
                      :
                      <FlexRow className="mt-2 py-4 bg-grey-500 phone:flex-col justify-center items-center">
                      {
                        !isShow?
                        <Text>
                          Loading data ...
                          <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                        </Text>
                        :
                        <>
                        <Text>No marketing asset at this time.</Text>
                        <div className='text-sm text-blue-100 hover:underline cursor-pointer hidden' onClick={onRefresh}>
                          Click to reload
                          <i className="fas fa-repeat mx-3" />
                        </div>
                        </>
                      }
                      </FlexRow>
                  }
                </div>
              </FlexRow>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};