import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { usersActions, useAppDispatch } from '@redux';
import { Icons, H2, Info, Div, Flex, FlexRow, Button, Text } from '@core';
import { Stage } from '@components';

export const AffiliateComplete: FC<any> = () => {

  localStorage.removeItem('signup');
  localStorage.removeItem('applied');
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const role = query.get('role');

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, []);

  return (
    <Flex className="px-12 phone:px-4 overflow-y-scroll items-center">
      <Div className="phone:px-5 tablet:px-10 laptop:px-20 w-full">
        <Div className="mr-auto flex flex-row items-center py-8">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
        </Div>
        <hr className="text-secondary-300 laptop:mb-20 phone:mb-10" />
      </Div>
      <Div className="phone:px-4 pb-10 phone:w-full tablet:w-6/12 laptop:w-9/12">
        <FlexRow className="h-full phone:flex-col laptop:flex-row">
          <Flex className="mr-10 phone:mr-0 phone:items-center tablet:items-start">
            {role === 'merchant' ? (
              <>
                <H2 className="text-primary-100 phone:text-center tablet:text-left">
                  Monetize Your Network
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Grow sales by creating and enabling your affiliate network to earn commissions by
                  sharing your store and product links.
                </Info>
              </>
            ) : (
              <>
                <H2 className="text-primary-100 phone:text-center tablet:text-left">
                  Your Links Are Everything
                </H2>
                <Info
                  className="text-secondary-100 mt-2 pr-10 phone:pr-0 
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  Links carry your brand and connect you audience in ways that no other medium can.
                  They’re kind of a big deal.
                </Info>
                <Info
                  className="text-secondary-100 mt-4 pr-10 phone:pr-0  
            phone:text-center tablet:text-left tablet:pr-10"
                >
                  You’re about to start creating powerful links. Easier-to-manage links. Branded
                  links. Xffiliate links.
                </Info>
              </>
            )}
          </Flex>
          <Flex className="phone:mt-10">
            <FlexRow
              className="items-center mb-7 phone:flex-col phone:items-start phone:space-y-4
            tablet:flex-row tablet:items-center tablet:space-y-0"
            >
              <Stage
                className="phone:hidden tablet:flex"
                isCurrent
                label="About you"
                stageNumber="1"
              />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage className="phone:hidden tablet:flex" isCurrent label="Select Products" stageNumber="2" />
              <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden tablet:block" />
              <Stage isCurrent label="Complete" stageNumber="3" />
            </FlexRow>
            <Text className="text-primary-100 font-bold text-2xl phone:mt-5 laptop:mt-0">
              Welcome to Xffiliate!
            </Text>
            <Info className="text-secondary-100 mt-4 mb-5">
              We’re glad to have you here. <br /> You can start creating your first link, or explore
              your dashboard.
            </Info>
            {role === 'merchant' ? (
              <Link className='hidden' to="/merchant/dashboard?action=product&complete=true">
                <Button className="mt-4 w-full justify-center px-14">
                  Create your first product
                </Button>
              </Link>
            ) : (
              <Link className='hidden' to="/affiliate/catalogs?complete=true">
                <Button className="mt-4 w-full justify-center px-14">
                  Find More Products
                </Button>
              </Link>
            )}

            {role === 'merchant' ? (
              <Link to="/merchant/dashboard?complete=true">
                <Button className="mt-2 w-full justify-center px-14">
                  <span className="">Go to Dashboard</span>
                </Button>
              </Link>
            ) : (
              <Link to="/affiliate/dashboard?complete=true">
                <Button className="mt-2 w-full justify-center px-14">
                  <span className="">Go to Dashboard</span>
                </Button>
              </Link>
            )}

            {/* 
          <Text className="text-base text-left mt-3">
            <Link className="text-primary-100" to="/">
              Skip this step
            </Link>
          </Text> */}
          </Flex>
        </FlexRow>
      </Div>
    </Flex>
  );
};