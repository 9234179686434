import React, {FC} from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { Div, Text, Toast } from '@core';
import { API } from '@config';
import { getCookie } from '@hooks';

interface PaymentProps {
  referenceNum: string;
  amount: number;
  name: string;
  reason: string;
  id?: string;
  key: any;
  date: any;
  primary: any;
  secondary: any;
}

export const RePaymentData: FC<PaymentProps> = ({
  referenceNum,
  amount,
  reason,
  name,
  id,
  key,
  date,
  primary,
  secondary,
}) => {

  const token = getCookie('token');
  const navigate = useNavigate();
  const [method, setMethod] = React.useState('');
  const [payment, setPayment] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const onDisburse = async () => {
    setLoading(true)
    fetch(`${API}/retry-disbursement/${id}`,{
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        channel_code: payment.channel_code,
        account_name: payment.account_name,
        account_number: payment.account_number,
      })
    })
    .then(res => res.json())
    .then(data => {
      if(data.status === 1){
        setToastTitle('SUCCESS');
        setToastDesc('Disbursement successful.');
        setToastKind('success');
        setToastOpen(true);
        setTimeout(() => {
          navigate('/merchant/settings?action=updated')
        }, 1500)
      }else{
        setToastTitle('ERROR');
        setToastDesc(data.message ||'Something went wrong. Please try again.');
        setToastKind('error');
        setToastOpen(true);
      }
    })
  }

  React.useEffect(() => {
    if(method === 'primary'){
      setPayment(primary)
    }else{
      setPayment(secondary)
    }
  }, [method])

  console.log(payment)

return(
  <>
  <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

  <Div className='phone:flex phone:flex-row items-center my-1 hover:bg-grey-500'>

      <Div className='mr-2 phone:flex phone:flex-col phone:w-2/3 tabletWide:hidden'>
          <Text className='hidden' key={key}>{id}{referenceNum}</Text>
          <Text
            className=" text-base text-secondary-200 font-light w-full text-left"
          >
            Name:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Amount:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Date:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Ref ID:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Failure:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left"
          >
            Method:
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full text-left mt-2"
          >
            Action:
          </Text>
      </Div>

      <Div className='phone:flex phone:flex-col phone:w-1/2 tabletWide:hidden cursor-pointer py-2' >
                <Text
                  className=" text-base text-secondary-200 font-light w-full text-left"
                >
                  {name}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light w-full text-left"
                >
                  &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light w-full text-left"
                >
                  {date.substring(0, 10)}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light w-full text-left"
                >
                  {referenceNum}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light w-full text-left"
                >
                  {reason}
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-light w-full text-left"
                >
                  <select
                    className='rounded-full w-36 border-none text-primary-100 cursor-pointer bg-grey-400 py-0'
                    name="method"
                    onChange={(e) => setMethod(e.target.value)}
                    >
                      <option disabled selected value="">Choose</option>
                      <option value='primary'>Primary Bank</option>
                      <option value='secondary'>Secondary Bank</option>
                  </select>
                </Text>
                <div
                  className="flex items-center text-base text-blue-100 font-light  w-full mt-2"
                >
                  {
                    method === '' ?
                    <button className='bg-secondary-200 text-white text-xs py-1 px-3 rounded-lg cursor-not-allowed w-20' title='Select a method'>
                      DISBURSE
                    </button>
                    :
                    <>
                    {
                      loading?
                      <button className='bg-primary-100 hover:bg-blue-100 text-white text-xs py-1 px-3 rounded-lg w-20'>
                        <i className="fad fa-spinner-third animate-spin" />
                      </button>
                      :
                      <button className='bg-primary-100 hover:bg-blue-100 text-white text-xs py-1 px-3 rounded-lg w-20' onClick={onDisburse}>
                        DISBURSE
                      </button>
                    }
                    </>
                  }
                </div>
      </Div>

      <details className='w-full py-2'>
        <summary className='phone:hidden tabletWide:flex flex-row w-full'>
          <Text
            className=" text-base text-secondary-200 font-light w-full medium:w-1/5 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/5"
          >
            {name}
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light w-full medium:w-1/5 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/5"
          >
            &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full medium:w-1/5 text-left medium:text-center tabletWide:mx-auto tabletWide:w-1/5 laptop:hidden"
          >
            <Popover>
                  <Popover.Button className='relative'>
                      <Text className='text-blue-100 mr-auto'>
                        View Error
                      </Text>
                  </Popover.Button>
                  <Popover.Panel className="absolute z-10 mt-2 text-secondary-200 border border-primary-100 bg-white px-2 rounded w-64 py-4">
                    <Text className='text-sm text-secondary-200 text-left'>
                      Error Code:
                    </Text>
                    <hr className='text-grey-400' />
                    <Text className='text-sm text-secondary-200 break-words mt-4 text-left'>
                      {reason}
                    </Text>
                  </Popover.Panel>
            </Popover>
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light w-full hidden laptop:block laptop:text-center laptop:w-1/5"
          >
            {reason}
          </Text>
          <Text
            className=" text-base text-secondary-200 font-light  w-full medium:w-1/5 text-left medium:text-center tablet:text-left tablet:w-1/2 tabletWide:text-center tabletWide:w-1/5"
          >
            <select
              className='rounded-full w-32 border-none text-primary-100 cursor-pointer bg-grey-400 py-0'
              name="sort"
              onChange={(e) => setMethod(e.target.value)}
              >
                <option disabled selected value="">Choose</option>
                <option value='primary'>Primary Bank</option>
                <option value='secondary'>Secondary Bank</option>
            </select>
            <span className='text-blue-100 cursor-pointer font-bold ml-2'>
              &#8942;&#8942;
            </span>
          </Text>
          <div
            className="flex medium:justify-center tablet:justify-start tabletWide:justify-center items-center text-base text-blue-100 font-light  w-full medium:w-1/5 tablet:w-1/2 tabletWide:w-1/5"
          >
            {
              method === '' ?
              <button className='bg-secondary-200 text-white text-xs py-1 px-3 rounded-lg cursor-not-allowed w-20' title='Select a method'>
                DISBURSE
              </button>
              :
              <>
              {
                loading?
                <button className='bg-primary-100 hover:bg-blue-100 text-white text-xs py-1 px-3 rounded-lg w-20'>
                  <i className="fad fa-spinner-third animate-spin" />
                </button>
                :
                <button className='bg-primary-100 hover:bg-blue-100 text-white text-xs py-1 px-3 rounded-lg w-20' onClick={onDisburse}>
                  DISBURSE
                </button>
              }
              </>
            }
            <span className='text-blue-100 cursor-pointer font-bold ml-2'>
              &#8942;&#8942;
            </span>
          </div>
        </summary>

        <hr className="text-grey-400 mt-2 mb-4" />

        <Div className='ml-4'>
          <Text className='text-secondary-200 font-semibold my-4'>
              Transaction Details:
          </Text>
          <Text className='text-secondary-200 font-light'>
            Reference Id: {referenceNum}
          </Text>
          <Text className='text-secondary-200 font-light'>
            Disbursement Date: {date.substring(0, 10)}
          </Text>
        </Div>

        <Div className='ml-4'>
          <Text className='text-secondary-200 font-semibold my-4'>
              Selected Bank Method:
          </Text>
          {
            method !== '' ?
            <>
            <Text className='text-secondary-200 font-light'>
              Bank: {payment.bank_name}
            </Text>
            <Text className='text-secondary-200 font-light'>
              Bank Number: {payment.account_number}
            </Text>
            <Text className='text-secondary-200 font-light'>
              Account Name: {payment.account_name}
            </Text>
            </>
            :
            <>
            <Text className='text-secondary-200 font-light'>
              -- NONE --
            </Text>
            </>
          }
        </Div>

      </details>

  </Div>

  {
    method !== '' ?
    <details className='mb-2 tabletWide:hidden'>
      <summary className='text-blue-100 font-light hover:underline list-none'>
          Click to view selected bank method.
      </summary>

      <Text className='text-secondary-200 font-light mt-4'>
        Bank: <b className='font-semibold'>{payment.bank_name}</b>
      </Text>
      <Text className='text-secondary-200 font-light'>
        Bank Number: <b className='font-semibold  '>{payment.account_number}</b>
      </Text>
      <Text className='text-secondary-200 font-light'>
        Account Name: <b className='font-semibold '>{payment.account_name}</b>
      </Text>

    </details>
    :
    <></>
  }

  <hr className="text-grey-400 mb-4" />
  </>
  )
};