import React from 'react';
import { Popover } from '@headlessui/react';
import { classNames } from '@src/helpers';

export const Pagination = ({
  currentPage,
  itemsPerPage,
  paginate,
  totalItems,
}: {
  currentPage: number;
  itemsPerPage: number;
  paginate: any;
  totalItems: number;
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const lastPage: number = Math.ceil(totalItems/itemsPerPage);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [isLastPage, setIsLastPage] = React.useState(false);
  const [isFirstPage, setIsFirstPage] = React.useState(false);

  React.useEffect(() => {
    if(totalItems !== 0){
      if(pageNumber > lastPage || currentPage > lastPage){
        setPageNumber(lastPage)
        paginate(lastPage)
      }else if(pageNumber < 0 || currentPage < 0){
        setPageNumber(1)
        paginate(1)
      }
    }
  }, [currentPage, pageNumber])

  React.useEffect(() => {
    if(currentPage !== pageNumber){
      setPageNumber(currentPage)
    }
  }, [currentPage])

  React.useEffect(() => {
    if(currentPage === lastPage || pageNumber === lastPage){
      setIsLastPage(true)
      setIsFirstPage(false)
    }else if(currentPage === 1 || pageNumber === 1){
      setIsFirstPage(true)
      setIsLastPage(false)
    }else{
      setIsLastPage(false)
      setIsFirstPage(false)
    }
  }, [currentPage, pageNumber, itemsPerPage])

  return (
    <div className="py-2">
      <nav className="block">

        {
          lastPage > 4 ?
          <div className='flex flex-row justify-center'>
          <i className={classNames("fas fa-chevron-left text-2xl text-primary-100 hover:text-blue-100 cursor-pointer",
                  currentPage !== 1 ?
                      "text-primary-100" :
                      "text-grey-400  hover:text-grey-200 pointer-events-none"
              )}
              onClick={() => {
                setPageNumber(currentPage-1)
                paginate(currentPage-1)
              }}
          />
          <ul className="flex pl-0 list-none flex-wrap">
              {
                currentPage === 1 || pageNumber === 1 || isFirstPage ?
                <></>
                :
                <>
                <li className="page-item">
                  <button
                    className='font-semibold text-grey-300 relative inline-flex items-center pl-4 py-2 text-sm font-medium rounded-lg mx-1'
                    type="button"
                  >
                    1
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className='font-semibold text-grey-400 relative inline-flex items-center py-2 text-sm font-medium rounded-lg mx-1'
                    type="button"
                  >
                    ...
                  </button>
                </li>
                </>
              }
              <li className="page-item">
                <button
                  className='font-semibold text-white relative inline-flex items-center px-4 py-2 text-sm font-medium bg-primary-100 rounded-lg mx-1'
                  type="button"
                >
                  {currentPage}
                </button>
              </li>
              {
                currentPage === lastPage || pageNumber === lastPage || isLastPage ?
                <></>
                :
                <>
                <li className="page-item">
                  <button
                    className='font-semibold text-grey-400 relative inline-flex items-center py-2 text-sm font-medium rounded-lg mx-1'
                    type="button"
                  >
                    ...
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className='font-semibold text-grey-400 relative inline-flex items-center pr-4 py-2 text-sm font-medium rounded-lg mx-1'
                    type="button"
                  >
                    {lastPage}
                  </button>
                </li>
                </>
              }
          </ul>
          <i
              className={classNames("fas fa-chevron-right text-2xl text-primary-100 hover:text-blue-100 cursor-pointer",
                  currentPage !== lastPage ?
                      "text-primary-100" :
                      "text-grey-400  hover:text-grey-200 pointer-events-none"
              )}
              onClick={() => {
                setPageNumber(currentPage+1)
                paginate(currentPage+1)
              }}
          />
          </div>
          :
          <div className='flex flex-row justify-center'>
          <i className={classNames("fas fa-chevron-left text-2xl text-primary-100 hover:text-blue-100 cursor-pointer",
                  currentPage !== 1 ?
                      "text-primary-100" :
                      "text-grey-400  hover:text-grey-200 pointer-events-none"
              )}
              onClick={() => {
                setPageNumber(currentPage-1)
                paginate(currentPage-1)
              }}
          />
          <ul className="flex pl-0 list-none flex-wrap">
            {pageNumbers.map((number) => (
              <li className="page-item" key={number}>
                <button
                  className={
                    currentPage === number || pageNumber === number
                      ? 'font-semibold text-white bg-white border-none bg-grey-400 relative inline-flex items-center px-4 py-2 border rounded-lg text-sm font-medium mx-1'
                      : 'font-semibold text-primary-100 hover:text-white relative inline-flex items-center px-4 py-2 text-sm font-medium hover:bg-primary-100 rounded-lg mx-1'
                  }
                  onClick={() => paginate(number)}
                  type="button"
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
          <i
              className={classNames("fas fa-chevron-right text-2xl text-primary-100 hover:text-blue-100 cursor-pointer",
                  currentPage !== lastPage ?
                      "text-primary-100" :
                      "text-grey-400  hover:text-grey-200 pointer-events-none"
              )}
              onClick={() => {
                setPageNumber(currentPage+1)
                paginate(currentPage+1)
              }}
          />
          </div>

        }
        
        <div className='flex flex-row justify-center items-center text-secondary-200 mt-2 pb-80 hidden'>
        <Popover className="relative ml-2 my-4">
              <Popover.Button>
                    <i className="fas fa-question text-xs mx-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
              </Popover.Button>
              <Popover.Panel className="absolute z-10 left-0">
                <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
                  <p className='font-semibold text-sm'>
                    Go to Page
                  </p>
                  <hr className='text-primary-100 my-1'/>
                  <p className='text-sm mt-4'>
                    Input a number or press
                    <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
                    <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
                    to navigate pages.
                  </p>
                  <p className='mt-4 text-sm text-secondary-200'>
                    ** Click anywhere on screen to close. **
                  </p>
                </div>
              </Popover.Panel>
        </Popover>
        Go to Page:
          <input
            className='mx-2 w-10 px-2'
            // max={lastPage+1}
            min={0}
            onChange={(e:any) => {
              setPageNumber(e.target.value)
              paginate((e.target.value-pageNumber)+currentPage)
            }}
            type='number'
            value={pageNumber} />
          <button className='hidden flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white' onClick={() => paginate(pageNumber)}>
            Go
          </button>
        </div>
      </nav>
    </div>
  );
};