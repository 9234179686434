import axios from 'axios';
import FormData from 'form-data';
import { API } from '@config';
import { getCookie } from '@hooks';

interface PRODUCT {
  name: string;
  description: string;
  link: string;
  category: string;
  sub_category: string;
  price: string;
  commission: string;
  productPic: any;
  id: string;
  type: string;
}

export const updateProduct = async (body: PRODUCT) => {
  const token = getCookie('token');
  const form = new FormData();
  form.append('name', body.name);
  form.append('description', body.description);
  form.append('link', body.link);
  form.append('category', body.category);
  form.append('sub_category', body.sub_category);
  form.append('price', body.price);
  form.append('commission', body.commission);
  form.append('type', body.type);

  if (body.productPic) {
    form.append('image', body.productPic ? body.productPic[0] : '');
  }

  return axios({
    method: 'POST',
    url: `${API}/update-product/${body.id}`,
    data: form,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
  }).then((response) => response);
};