import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

export const getLinks = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliate-table`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getIPAddress = async () =>
  axios.get(`https://geolocation-db.com/json/`).then((response) => response);
