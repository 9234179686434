import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FlexRow, Text } from '@core';

interface AlertMessageProps {
  containerClass?: string;
  message: string;
  linkTo: string;
  btnText: string;
  btnClass?: string;
}

export const AlertMessage: FC<AlertMessageProps> = ({
  containerClass,
  message,
  linkTo,
  btnText,
  btnClass,
}) => {

  const [hide, setHide] = React.useState(false);

  const onClose = async () => {
    setHide(true)
    localStorage.setItem('message', 'onRead')
  }

return(
  <FlexRow className={`w-full tablet:pl-10 phone:pr-4 phone:pl-4 py-2 bg-yellow-200 ${containerClass} ${hide? 'hidden' : ''}`}>
    <i className="fas fa-exclamation-triangle mr-4 text-primary-100" />
    <Text className="text-primary-100 tablet:text-base phone:text-sm">{message}</Text>
    <Link className="ml-auto phone:hidden tablet:block" to={linkTo}>
      <button
        className={` text-white bg-green-100 hover:bg-opacity-80 focus:ring-2 focus:ring-secondary-300 
font-poppins font-regular rounded-lg px-5 py-2 text-center ml-auto
inline-flex items-center laptop:text-base phone:text-xs tablet:text-sm mr-2 ${btnClass}`}
        type="button"
      >
        {btnText}
      </button>
    </Link>
    <i className="fas fa-times phone:ml-2 tablet:ml-5 text-primary-100 cursor-pointer" onClick={onClose} title="Hide this message."/>
  </FlexRow>
);
}