import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../type';

const products = (state: RootState) => state.products;

export const selectProductsLoading = createSelector(products, (state) => state.loading);

export const selectProducts = createSelector(products, (state) => state.data ?? []);

export const selectAllProductsAndMetadata = createSelector(
  selectProducts,
  selectProductsLoading,
  (data = [], loading = false) => ({ data, loading }),
);