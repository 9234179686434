import React, { FC } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import {
  Icons,
  H2,
  Info,
  Div,
  Flex,
  FlexRow,
  ButtonIcon,
  Input,
  PasswordInput,
  Button,
  Text,
  Toast,
} from '@core';
import { useCookie } from '@hooks';
import { SHARE } from '@config';

export const AffiliateOnLogin: FC<any> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [cookie, updateCookie] = useCookie('token', '0');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const merchantId = query.get('merchant');
  const merchantStore = query.get('merchantStore');

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  React.useEffect(() => {
    if(user?.user_info?.role === 'affiliate'){
      navigate(`/affiliate/merchant_catalogs?merchant=${merchantId}&merchantStore=${merchantStore}`)
    }
  })

  const onSubmit = () => {
    if (email === '') {
      setToastKind('error');
      setToastTitle('Email is Required');
      setToastDesc('Please enter your email address.');
      setToastOpen(true);
    } else if (password === '') {
      setToastKind('error');
      setToastTitle('Password is Required');
      setToastDesc('Please enter your password.');
      setToastOpen(true);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(usersActions.login({ email, password }));

        if (data?.payload?.status === 1) {
          setToastTitle('Logged In');
          setToastDesc('Redirecting to dashboard.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          updateCookie(`${data?.payload?.access_token}`, 24);
          await dispatch(usersActions.profile());
          setTimeout(() => {
            setToastOpen(false);

            if (data?.payload?.merchant_data?.company.length !== 0 &&
                    data?.payload?.user_info?.role === 'merchant') {

                navigate(`/merchant/dashboard`);
            }
            else if (data?.payload?.user_info?.role === 'affiliate') {
                navigate(`/affiliate/merchant_catalogs?merchant=${merchantId}&merchantStore=${merchantStore}`);
            }
          });
        } else {
          setToastTitle('Invalid Credentials');
          setToastDesc(data?.payload?.message || 'Email or password is incorrect.');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    }
  };

  const onSubmitV2 = (e: any) => {
    e.preventDefault()

    if (email === '') {
      setToastKind('error');
      setToastTitle('Email is Required');
      setToastDesc('Please enter your email address.');
      setToastOpen(true);
    } else if (password === '') {
      setToastKind('error');
      setToastTitle('Password is Required');
      setToastDesc('Please enter your password.');
      setToastOpen(true);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(usersActions.login({ email, password }));

        if (data?.payload?.status === 1) {
          setToastTitle('Logged In');
          setToastDesc('Redirecting to dashboard.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          updateCookie(`${data?.payload?.access_token}`, 24);
          await dispatch(usersActions.profile());
          setTimeout(() => {
            setToastOpen(false);

            if (data?.payload?.merchant_data?.company.length !== 0 &&
                    data?.payload?.user_info?.role === 'merchant') {

                navigate(`/merchant/dashboard`);
            }
            else if (data?.payload?.user_info?.role === 'affiliate') {
                navigate(`/affiliate/merchant_catalogs?merchant=${merchantId}&merchantStore=${merchantStore}`);
            }
          });
        } else {
          setToastTitle('Invalid Credentials');
          setToastDesc(data?.payload?.message || 'Email or password is incorrect.');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    }
  };

  return (
    <Flex className="px-20 phone:px-5 tablet:px-10 laptop:px-20 overflow-y-scroll">
      <div className='hidden'>Unused {cookie}</div>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300" />
      <Div className="mt-10 flex flex-col items-center justify-center h-full">
        <H2 className="text-primary-100">Login</H2>
        <Info className="text-secondary-100 mt-2">
          Don’t have an account?{' '}
          <a className="underline text-primary-100" href={`${SHARE}affiliate/onboard/signup/merchant/${merchantId}/merchantStore/${merchantStore}`} rel="noopener noreferrer">
            Sign Up
          </a>
        </Info>
        <Info className="text-secondary-100 mt-8 mb-4 hidden">Log in with:</Info>

        <Div className="mb-20 phone:w-full tablet:w-6/12 laptop:w-4/12">
          <FlexRow className="justify-center w-full space-x-4 phone:space-x-1 hidden">
            <ButtonIcon className="w-full justify-center " icon="fab fa-google font-bold">
              Google
            </ButtonIcon>
            <ButtonIcon className="w-full justify-center " icon="fab fa-facebook-square">
              Facebook
            </ButtonIcon>
          </FlexRow>
          <FlexRow className='hidden'>
            <Div className="border-t-2 border-secondary-200 w-full" />
            <Info className="text-secondary-100 font-semibold mx-4 ">OR</Info>
            <Div className="border-t-2 border-secondary-200 w-full" />
          </FlexRow>
          <form className="mt-7" onSubmit={e => onSubmitV2(e)}>
            <Input
              label="Email address "
              name="email"
              onChange={setEmail}
              pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder="Enter your email"
              type="email"
              value={email}
            />
            <PasswordInput
              label="Password"
              name="Password"
              onChange={setPassword}
              placeholder="Enter your password"
              value={password}
            />

            <Text className="text-base text-right mb-3">
              <Link className="underline text-primary-100" to="/reset/password">
                Forgot your password?
              </Link>
            </Text>
            <Button className="w-full py-5 justify-center" isLoading={loading} onClick={onSubmit}  type='submit'>
              Log In
            </Button>
          </form>
        </Div>
      </Div>
    </Flex>
  );
};