import React, { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition, Popover } from '@headlessui/react';
import { API } from '@config';
import { FlexRow, Text } from '@core'

interface ButtonProps {
  className?: string;
  title: string;
  onClose: any;
  description: string;
  isOpen: boolean;
  toastKind: string;
  onClickSubmit?: any;
}

export const ToastV5: FC<ButtonProps> = ({
  title, description, onClose, onClickSubmit, isOpen, toastKind
}) => {
  let color = 'green-100';

  let icon = 'fa-check';
  if (toastKind === 'success') {
    color = 'green-100';
    icon = 'fa-check';
  } else if (toastKind === 'error') {
    color = 'red-100';
    icon = 'fa-times';
  } else if (toastKind === 'warning') {
    color = 'yellow-100';
    icon = 'fa-exclamation';
  } else if (toastKind === 'info') {
    color = 'primary-100';
    icon = 'fa-exclamation';
  }

  const navigate = useNavigate();
  const [bankList, setBankList] = React.useState<any[]>([])
  const [newList, setNewList] = React.useState<any[]>([])
  const resultList: any[] = [];

  const [sortList, setSortList] = React.useState<any>(bankList);
  const [search , setSearch] = React.useState('');
  const [type, setType] = React.useState('bank');

  const [itemBank, setItemBank] = React.useState('');
  const [itemCode, setItemCode] = React.useState('');
  const [itemType, setItemType] = React.useState('');
  const [result, setResult] = React.useState(false);

  React.useEffect(() => {
    fetch(`${API}/get-payment-channels`)
    .then(res => res.json())
    .then(data => {
        setBankList(data.data)
        setSortList(data.data)
    })
  }, [isOpen])

  const onSearch = () => {
    bankList.map((item:any) => {
      if(item.bank_name.toLowerCase().includes(search.toLowerCase())){
        setResult(true)
        resultList.push(item)
        setNewList(resultList)
        setItemBank(item.bank_name)
        setItemCode(item.channel_code)
        setItemType(item.channel_type)
        return 0;
      }
      return 0;
    })
  }

  const onSubmit = (e:any) => {
    e.preventDefault();
    bankList.map((item:any) => {
      if(item.bank_name.toLowerCase().includes(e)){
        setResult(true)
        setItemBank(item.bank_name)
        setItemCode(item.channel_code)
        setItemType(item.channel_type)
        return 0;
      }
      return 0;
    })
  }

  const onClear = () => {
    setSearch('');
    setResult(false);
  }

  const SortType = () => {
    if(type === 'bank'){
      setType('wallet');
      setResult(false);
      setSortList(bankList.sort((a, b) => {
      if(b.channel_type < a.channel_type) { return -1; }
      if(b.channel_type > a.channel_type) { return 1; }
      return 0;
      }))
    } else if (type === 'wallet'){
      setType('bank');
      setResult(false);
      setSortList(bankList.sort((a, b) => {
      if(a.channel_type < b.channel_type) { return -1; }
      if(a.channel_type > b.channel_type) { return 1; }
      return 0;
      }))
    }
    
  }

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-40 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="phone:w-full medium:w-3/4 tabletWide:w-1/2 phone:w-full h-screen text-center">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-50 px-5 pt-5
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <div
                className={`phone:h-full surfaceDuo:h-2/3 flex flex-col justify-start p-4 mb-4 text-gray-500 w-full
            bg-white border-4 border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins bg-white`}
                id="toast-success"
                role="alert"
              >
              <FlexRow className='w-full'>
                <div
                  className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white bg-${color} rounded-lg mr-1`}
                >
                  <i className={`fas ${icon}`} />
                </div>
                <div>
                  <div className={`ml-3 font-semibold text-base text-${color}`}>
                    {title.toUpperCase()}
                  </div>
                  <div className="ml-3 text-sm font-normal text-primary-100">{description}</div>
                </div>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
                </FlexRow>
                <hr className='text-primary-100 my-3'/>
                
                <div className='my-2 flex phone:flex-col surfaceDuo:flex-row items-center'>
                  <form onSubmit={(e:any) => onSubmit(e)}>
                  <input className='border-2 border-secondary-200 p-1 rounded' onChange={(e) => setSearch(e.target.value)} placeholder='Search' value={search}/>
                  <button className="phone:hidden surfaceDuo:inline bg-white" onClick={onSearch} type='submit'>
                  <i className="fas fa-search text-xl mx-1 hover:bg-grey-400 cursor-pointer px-2 py-1 rounded"/>
                  </button>
                  </form>
                  <FlexRow>
                  <button className="phone:flex surfaceDuo:hidden bg-white" onClick={onSearch} >
                  <i className="fas fa-search text-xl mx-1 hover:bg-grey-400 cursor-pointer px-2 py-1 rounded"/>
                  </button>
                  <Popover className="relative">
                        <Popover.Button>
                          <i className="fas fa-question text-xl hover:bg-grey-400 mb-1 px-2 py-1 rounded" />
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10">
                          <div className="bg-white w-full shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-60">
                            <p className='font-semibold text-sm'>
                              Hint:
                            </p>
                            <hr className='mt-1 mb-2 text-grey-400'/>
                            <Text className='text-sm text-secondary-200 mt-4'>
                              Hint: Please use keyword for searches. Ex. Metrobank / grab
                            </Text>
                            <Text className='text-sm text-secondary-200'>
                              Hint: Please use significant spaces. Ex. Banco de Oro / east west
                            </Text>
                          </div>
                        </Popover.Panel>
                      </Popover>
                  <span className='hover:underline cursor-pointer text-blue-200 hover:bg-grey-400 p-2 rounded' onClick={onClear}>Clear</span>
                  <div className='phone:flex surfaceDuo:hidden hover:underline cursor-pointer text-blue-200 hover:bg-grey-400 p-2 rounded' onClick={SortType}>
                    Sort
                  </div>
                  </FlexRow>
                </div>
                
                <FlexRow className='phone:hidden surfaceDuo:flex w-full mt-5'>
                  <div className='w-1/4 text-center font-semibold'>
                    Bank Code
                  </div>
                  <div className='w-1/4 text-center font-semibold hover:bg-grey-400 py-1 rounded cursor-pointer' onClick={SortType}>
                    Type
                    <i className="fas fa-chevron-down text-primary-100 mx-2" />
                  </div>
                  <div className='w-1/2 text-center font-semibold'>
                    Bank Name
                  </div>
                </FlexRow>
                <hr className='my-2 phone:hidden surfaceDuo:block'/>
                <div className='w-full h-full overflow-y-scroll'>
                {
                  !result ?
                  <>
                  {sortList.map((bank:any) => (
                    <Fragment key={bank.id}>
                    <div className="flex flex-row w-full" key={bank.id}>
                    <FlexRow className='phone:flex-col surfaceDuo:hidden w-full'>
                      <div className='w-full text-left text-sm'>
                        Bank Code
                      </div>
                      <div className='w-full text-left text-sm'>
                        Type
                      </div>
                      <div className='w-full text-left text-sm'>
                        Bank Name
                      </div>
                    </FlexRow>
                    <div className='flex phone:flex-col surfaceDuo:flex-row w-full hover:bg-blue-300 surfaceDuo:py-2 rounded cursor-pointer surfaceDuo:border-b-2 surfaceDuo:border-secondary-200 surfaceDuo:my-1'
                      key={bank.id}
                      onClick={() => {
                      navigate(`/payment_method_setup?bank_name=${bank.bank_name}&channel_code=${bank.channel_code}&channel_type=${bank.channel_type}`)
                      window.location.reload()
                      }}
                      >

                      <span className='hidden'>{bank.id}</span>
                      <Text className='phone:w-full surfaceDuo:w-1/4 phone:text-left surfaceDuo:text-center text-secondary-200 phone:text-sm surfaceDuo:text-base'>
                        {bank.channel_code}
                      </Text>
                      <Text className='phone:w-full surfaceDuo:w-1/4 phone:text-left surfaceDuo:text-center text-secondary-200 phone:text-sm surfaceDuo:text-base'>
                        {bank.channel_type}
                      </Text>
                      <Text className='phone:w-full surfaceDuo:w-1/2 phone:text-left surfaceDuo:text-center text-secondary-200 phone:text-sm surfaceDuo:text-base'>
                        {bank.bank_name}
                      </Text>
                    </div>
                    </div>
                    <hr className='my-2 text-secondary-200 phone:block surfaceDuo:hidden'/>
                    </Fragment>
                  ))
                  }</>
                  :
                  <>
                  <form className='mb-4' onSubmit={(e:any) => onClickSubmit(e)}>
                  <span className='text-secondary-200'>
                    Search result:
                  </span>
                  <button className='flex flex-row w-full p-3 cursor-pointer rounded bg-secondary-300 hover:bg-blue-300 my-2' onClick={onClose} type='submit'>
                      <FlexRow className='flex-col surfaceDuo:hidden w-full'>
                      <Text className='w-full text-left text-primary-100 text-base'>
                        Bank Code
                      </Text>
                      <Text className='w-full text-left text-primary-100 text-base'>
                        Type
                      </Text>
                      <Text className='w-full text-left text-primary-100 text-base'>
                        Bank Name
                      </Text>
                      </FlexRow>

                      <FlexRow className='phone:flex-col surfaceDuo:flex-row w-full'>
                      <Text className='phone:w-full surfaceDuo:w-1/4 phone:text-left surfaceDuo:text-center text-primary-100 phone:text-sm surfaceDuo:text-base'>
                        {itemCode}
                      </Text>
                      <Text className='phone:w-full surfaceDuo:w-1/4 phone:text-left surfaceDuo:text-center text-primary-100 phone:text-sm surfaceDuo:text-base'>
                        {itemType}
                      </Text>
                      <Text className='phone:w-full surfaceDuo:w-1/2 phone:text-left surfaceDuo:text-center text-primary-100 phone:text-sm surfaceDuo:text-base'>
                        {itemBank}
                      </Text>
                      </FlexRow>
                  </button>
                    <input className='hidden' name='bank_name' readOnly value={itemBank}/>
                    <input className='hidden' name='channel_type' readOnly value={itemType}/>
                    <input className='hidden' name='channel_code' readOnly value={itemCode}/>
                  </form>
                  <hr className='my-2 text-secondary-200'/>
                  <span className='text-secondary-200'>
                    Other results: &#40;{newList.length}&#41;
                  </span>
                  {newList.map((bank:any) => (
                    <Fragment key={bank.id}>
                    <div className="flex flex-row w-full" key={bank.id}>
                    <FlexRow className='phone:flex-col surfaceDuo:hidden w-full'>
                      <div className='w-full text-left text-sm'>
                        Bank Code
                      </div>
                      <div className='w-full text-left text-sm'>
                        Type
                      </div>
                      <div className='w-full text-left text-sm'>
                        Bank Name
                      </div>
                    </FlexRow>
                    <div className='flex phone:flex-col surfaceDuo:flex-row w-full hover:bg-blue-300 surfaceDuo:py-2 rounded cursor-pointer surfaceDuo:border-b-2 surfaceDuo:border-secondary-200 surfaceDuo:my-1'
                      key={bank.id}
                      onClick={() => {
                      navigate(`/payment_method_setup?bank_name=${bank.bank_name}&channel_code=${bank.channel_code}&channel_type=${bank.channel_type}`)
                      window.location.reload()
                      }}
                      >

                      <span className='hidden'>{bank.id}</span>
                      <Text className='phone:w-full surfaceDuo:w-1/4 phone:text-left surfaceDuo:text-center text-secondary-200 phone:text-sm surfaceDuo:text-base'>
                        {bank.channel_code}
                      </Text>
                      <Text className='phone:w-full surfaceDuo:w-1/4 phone:text-left surfaceDuo:text-center text-secondary-200 phone:text-sm surfaceDuo:text-base'>
                        {bank.channel_type}
                      </Text>
                      <Text className='phone:w-full surfaceDuo:w-1/2 phone:text-left surfaceDuo:text-center text-secondary-200 phone:text-sm surfaceDuo:text-base'>
                        {bank.bank_name}
                      </Text>
                    </div>
                    </div>
                    <hr className='my-2 text-secondary-200 phone:block surfaceDuo:hidden'/>
                    </Fragment>
                  ))
                  }
                  </>
                }
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};