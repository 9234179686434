import React, { FC } from 'react';

interface InputProps {
  className?: string;
  props?: any;
  placeholder?: string;
  label: string;
  containerClass?: string;
  onChange?: any;
  value?: string;
  name?: string;
  type?: string;
  maxlength?: number;
  pattern?: string;
  disabled?: boolean;
}

export const InputV2: FC<InputProps> = ({
  className,
  placeholder,
  label,
  props,
  containerClass,
  onChange,
  value,
  name,
  type,
  maxlength,
  pattern,
  disabled,
}) => {
  const onChangeInput = (event: any) => {
    if (type === 'file') {
      onChange(event.target.files);
    } else if (maxlength) {
      if (maxlength >= event.target.value?.length) {
        onChange(event.target.value);
      }
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <div
      {...props}
      className={`px-5 py-4 border border-secondary-100 rounded-lg mb-4 ${containerClass}`}
    >
      <p className="font-poppins font-bold laptop:text-sm phone:text-xs text-secondary-100 mb-1">
        {label}
      </p>
      <input
        className={`outline-0 outline-none w-full border-0 font-poppins font-light 
      text-secondary-100 p-0 focus:outline-none border-transparent 
      focus:border-transparent focus:ring-0 laptop:text-sm phone:text-xs ${className}`}
        disabled={disabled || false}
        id={name}
        name={name}
        onChange={(event) => onChangeInput(event)}
        onWheel={(e: any) => e.target.blur()}
        pattern={pattern || ''}
        placeholder={placeholder}
        type={type || 'auto'}
        value={value}
      />
    </div>
  );
};