import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllMerchant, getAnalytics, getAnalyticsAdmin, getAffiliateAnalytics } from '@services';

interface STATE {
  loading: boolean;
  data: [];
  analyticsProducts: {
    products: any;
    data: any;
  };
  analyticsPartners: {
    partners: any;
    data: any;
  };
}

export const merchantsInitialState: STATE = {
  loading: false,
  data: [],
  analyticsProducts: {
    products: [],
    data: [],
  },
  analyticsPartners: {
    partners: [],
    data: [],
  },
};

const merchantList = createAsyncThunk('merchants/merchantList', async () => {
  const data: any = await getAllMerchant();
  const merchants = Object.values(data.data.data);
  return {
    data: merchants,
  };
});

const merchantAnalytics = createAsyncThunk('merchants/analytics', async () => {
  const data: any = await getAnalytics();
  return data.data;
});

const merchantAnalyticsAdmin = createAsyncThunk('merchants/analytics', async (id: number) => {
  const data: any = await getAnalyticsAdmin(id);
  return data.data;
});

const affiliateAnalytics = createAsyncThunk('affiliate/analytics', async () => {
  const data: any = await getAffiliateAnalytics();
  return data.data;
});

const { actions, reducer } = createSlice({
  name: 'merchants',
  initialState: merchantsInitialState,
  reducers: {},
  extraReducers: {
    [merchantList.pending.type]: (state) => {
      state.loading = true;
    },
    [merchantList.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
    [merchantAnalytics.pending.type]: (state) => {
      state.loading = true;
    },
    [merchantAnalytics.fulfilled.type]: (state, { payload: { data } }) => {
      const products = Object.keys(data.product_data[0]).map((key) => key);
      const partners = Object.keys(data.partner_data[0]).map((key) => key);
      products.shift();
      partners.shift();
      state.analyticsProducts.products = products;
      state.analyticsProducts.data = [
        data.product_data[2],
        data.product_data[1],
        data.product_data[0],
      ];
      state.analyticsPartners.partners = partners;
      state.analyticsPartners.data = [
        data.partner_data[2],
        data.partner_data[1],
        data.partner_data[0],
      ];
      state.loading = false;
    },
    [merchantAnalyticsAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [merchantAnalyticsAdmin.fulfilled.type]: (state, { payload: { data } }) => {
      const products = Object.keys(data.product_data[0]).map((key) => key);
      const partners = Object.keys(data.partner_data[0]).map((key) => key);
      products.shift();
      partners.shift();
      state.analyticsProducts.products = products;
      state.analyticsProducts.data = [
        data.product_data[2],
        data.product_data[1],
        data.product_data[0],
      ];
      state.analyticsPartners.partners = partners;
      state.analyticsPartners.data = [
        data.partner_data[2],
        data.partner_data[1],
        data.partner_data[0],
      ];
      state.loading = false;
    },
    [affiliateAnalytics.pending.type]: (state) => {
      state.loading = true;
    },
    [affiliateAnalytics.fulfilled.type]: (state, { payload: { data } }) => {
      const products = Object.keys(data.product_data[0]).map((key) => key);
      products.shift();
      state.analyticsProducts.products = products;
      state.analyticsProducts.data = [
        data.product_data[2],
        data.product_data[1],
        data.product_data[0],
      ];
      state.loading = false;
    },
  },
});

export const merchantsActions = {
  ...actions,
  merchantList,
  merchantAnalytics,
  merchantAnalyticsAdmin,
  affiliateAnalytics,
};

export const merchantsReducer = reducer;