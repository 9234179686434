import React, { FC } from 'react';
import { Popover } from '@headlessui/react';
import { Div, Text, Checkbox, ToastRawInput, Toast, ToastReviewModal } from '@core';
import { getCookie } from '@hooks';
import { API } from '@config';

interface CommissionProps {
  accountName: string;
  accountNum: number;
  affiliateName: string;
  affiliateEmail: string;
  bankName: string;
  cutOffDate: string;
  commissionAmt: number;
  status: boolean;
  onClick?: any;
  id: string;
  checked?: boolean;
  list: any;
  userType: string;
  isWixUser: boolean;
}

export const CommissionData: FC<CommissionProps> = ({
  accountName,
  accountNum,
  affiliateName,
  affiliateEmail,
  bankName,
  cutOffDate,
  commissionAmt,
  id,
  checked,
  onClick,
  status,
  list,
  userType,
  isWixUser,
}) => {
  const token = getCookie('token');
  const [fetching, setFetching] = React.useState(false);
  const [proofId, setProofId] = React.useState('');
  const [isToastOpenC, setToastOpenC] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  // Review modal related states
  const [showReviewModal, setShowReviewModal] = React.useState<boolean>(false);

  const onUpdate = () => {
    setToastOpen(false);
    setFetching(true);
    fetch(`${API}/create-manual-disbursement`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        affiliate_id: id,
        transaction_id: proofId,
        amount: commissionAmt,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFetching(false);
        setToastOpenC(false);
        if (data.status === 1) {
          setToastDesc('Disbursement has been updated successfully.');
          setToastOpen(true);
          setToastTitle('SUCCESS');
          setToastKind('success');
        } else {
          setToastTitle('ERROR');
          setToastDesc(data.message || 'Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
        }
      })
      .catch((err) => {
        setToastTitle('ERROR');
        setToastDesc(err.message || 'Something went wrong. Please try again.');
        setToastKind('error');
        setToastOpen(true);
      })
      .finally(async () => {
        setTimeout(() => {
          setToastOpen(false);
        }, 3000);
        if (isWixUser) {
          const data = await fetch(`${API}/wix-check-review-modal/check`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          const reviewModalInfo = await data.json();
          console.log(reviewModalInfo, 'modal info');
          setShowReviewModal(reviewModalInfo.has_opened_review_modal === 0);
        }
      });
  };

  return (
    <>
      <ToastReviewModal
        isOpen={isWixUser && showReviewModal}
        onClose={() => setShowReviewModal(false)}
      />
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <ToastRawInput
        btnText="Cancel"
        description="Please enter proof of transaction ( ie., Transaction ID, Reference ID, etc )"
        isLoaded={fetching}
        isOpen={isToastOpenC}
        onChange={(e: any) => setProofId(e.target.value)}
        onClick={onUpdate}
        onClick2={() => setToastOpenC(false)}
        onClose={() => setToastOpenC(false)}
        placeholder="Transaction ID"
        submitBtnText="Proceed"
        title="Action"
        toastKind="info"
        value={proofId}
      />
      {userType === 'PRO PLAN' ? (
        <>
          <Div className="phone:flex phone:flex-row items-center justify-around mt-5 hover:bg-grey-500 py-2 rounded">
            <div className="hidden">
              {bankName}
              {accountName}
              {accountNum}
            </div>

            <Checkbox
              checked={list.includes(id)}
              containerClass={`phone:mt-3 medium:mt-0 tablet:mt-3 tabletWide:mt-0 mb-auto w-1/12 ${
                checked ? 'hidden' : 'flex'
              }`}
              id={id}
              key={id}
              label=""
              onClick={onClick}
            />

            <div
              className={`${
                checked
                  ? 'mx-2 mb-auto phone:mt-3 medium:mt-0 tablet:mt-3 tabletWide:mt-0'
                  : 'hidden'
              }`}
              title='Please uncheck the "Select All" options.'
            >
              <i className="fas fa-check bg-primary-100 text-white text-sm px-1 rounded cursor-not-allowed" />
            </div>

            <Div className="phone:hidden flex-col tablet:w-1/2 tablet:flex tabletWide:hidden pl-16">
              <Text className=" text-base text-secondary-200 font-light w-full text-left">
                Name:
              </Text>
              <Text className=" text-base text-secondary-200 font-light w-full text-left">
                Cut-off Date:
              </Text>
              <Text className=" text-base text-secondary-200 font-light  w-full text-left">
                Email:
              </Text>
              <Text className=" text-base text-secondary-200 font-light  w-full text-left">
                Amount
              </Text>
              <Text className=" text-base text-secondary-200 font-light  w-full text-left">
                Status
              </Text>
            </Div>

            <Div className="phone:flex phone:flex-col phone:w-full mobile:w-1/2 medium:flex-row medium:w-full tablet:flex-col tablet:w-1/2 tabletWide:w-full tabletWide:flex-row">
              <Text className=" text-base text-secondary-200 font-light w-full medium:w-1/5 text-left medium:text-center tablet:w-full tabletWide:w-1/5 tablet:text-left tabletWide:text-center">
                {affiliateName}
              </Text>
              <Text className=" text-base text-secondary-200 font-light w-full medium:w-1/5 text-left medium:text-center tablet:w-full tabletWide:w-1/5 tablet:text-left tabletWide:text-center">
                {cutOffDate}
              </Text>
              <input
                className=" text-base text-secondary-200 font-light  w-full medium:w-1/5  text-left medium:text-center tablet:w-full tabletWide:w-1/5 tablet:text-left tabletWide:text-center bg-transparent"
                disabled
                value={affiliateEmail}
              />
              <Text className=" text-base text-secondary-200 font-light  w-full medium:w-1/5 text-left medium:text-center tablet:w-full tabletWide:w-1/5 tablet:text-left tabletWide:text-center">
                &#8369;{' '}
                {commissionAmt
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Text>
              <Text className="flex flex-row justify-start items-center w-full medium:w-1/5 medium:justify-center tablet:w-full tabletWide:w-1/5 tablet:justify-start tabletWide:justify-center">
                <span className="w-36 text-base text-white text-center bg-secondary-200 rounded-full font-light">
                  {status}
                </span>
              </Text>
            </Div>
          </Div>
          <hr className="text-grey-400 mb-2 mt-1" />
        </>
      ) : (
        <>
          <Div className="phone:flex phone:flex-row items-center justify-around mt-5 hover:bg-grey-500 py-2 rounded medium:justify-start medium:w-2200px">
            <Div className="phone:hidden phone:flex-col surfaceDuo:w-1/2 surfaceDuo:flex medium:w-0">
              <Text className="medium:hidden text-base text-secondary-200 font-light w-full text-left">
                Name:
              </Text>
              <Text className="medium:hidden text-base text-secondary-200 font-light w-full text-left">
                Cut-off Date:
              </Text>
              <Text className="medium:hidden text-base text-secondary-200 font-light  w-full text-left">
                Email:
              </Text>
              <Text className="medium:hidden text-base text-secondary-200 font-light  w-full text-left">
                Amount
              </Text>
              <Text className="medium:hidden text-base text-secondary-200 font-light  w-full text-left">
                Status
              </Text>
            </Div>

            <Div className="phone:flex phone:flex-col phone:w-full mobile:w-1/2 medium:flex-row medium:w-2200px">
              <Text className="text-base text-secondary-200 text-left font-light w-full medium:w-200px medium:text-left">
                {affiliateName}
              </Text>
              <Text className="text-base text-secondary-200 text-left font-light w-full medium:w-200px medium:text-center">
                {cutOffDate}
              </Text>
              <input
                className="text-base text-secondary-200 text-left font-light w-full medium:w-300px medium:text-center"
                disabled
                value={affiliateEmail}
              />
              <Text className="text-base text-secondary-200 text-left font-light w-full medium:w-200px medium:text-center">
                &#8369;{' '}
                {commissionAmt
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Text>
              <Text className="text-base text-secondary-200 text-left font-light w-full medium:w-200px medium:text-center">
                {bankName}
              </Text>
              <Text className="text-base text-secondary-200 text-left font-light w-full medium:w-200px medium:text-center">
                {accountName}
              </Text>
              <Popover className="relative w-full medium:w-200px flex flex-row medium:justify-center">
                <Popover.Button className="">
                  <Text className="text-blue-100">View Account No.</Text>
                </Popover.Button>
                <Popover.Panel className="absolute z-10 right-0 top-full mt-2 text-secondary-200 border-2 border-primary-100 bg-white px-2 rounded w-64 py-4">
                  <Text className="text-sm text-secondary-200">View Account No.</Text>
                  <hr className="text-grey-400" />
                  <Text className="text-sm text-secondary-200 break-words mt-4">{accountNum}</Text>
                </Popover.Panel>
              </Popover>
              <Text className="text-base text-secondary-200 text-left font-light w-full medium:w-300px medium:text-center">
                - - - -
              </Text>
              <Text className="cursor-pointer text-base text-blue-100 text-left font-bold w-full medium:w-200px medium:text-center">
                <span onClick={() => setToastOpenC(true)}>Update</span>
              </Text>
              <Text className="text-base text-secondary-200 text-left font-light w-full medium:w-200px medium:text-center">
                <span className="w-full block text-base text-white text-center bg-secondary-200 rounded-full font-light">
                  {status}
                </span>
              </Text>
            </Div>
          </Div>
          <hr className="text-grey-400 mb-2 mt-1 w-2200px" />
        </>
      )}
    </>
  );
};
