import React, { FC } from 'react';
import { batch } from 'react-redux';
// import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { partnersActions, useAppDispatch } from '@redux';
import { FlexRow, Div, Text, ButtonIcon, Flex, Icons } from '@core';
import { Overview, AffiliateInfoModal, UpdateAffilaiteModal } from '@components';
import { useSubscription } from '@hooks';
import { formatCount } from '@src/helpers/formatNumber';

interface ProductProps {
  selectedPartner?: any;
  setSelected?: any;
  setPartner?: any;
}

const ApplicationItems = ({ data, selectedPartner }: any) => {
  const subscription = useSubscription();
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [updateInfo, setUpdateInfo] = React.useState(false);

  return (
    <Div
      className="bg-grey-500 w-full rounded-2xl overflow-hidden mb-5 px-5 py-4"
      key={data.affiliate_id}
    >
      <AffiliateInfoModal
        data={data}
        isOpen={infoOpen}
        onClose={() => setInfoOpen(false)}
        selectedPartner={selectedPartner}
      />
      <UpdateAffilaiteModal
        data={data}
        isOpen={updateInfo}
        onClose={() => setUpdateInfo(false)}
        selectedPartner={selectedPartner}
      />
      <FlexRow>
        <Div className="w-20 h-20 bg-orange-100 rounded-md flex flex-col items-center justify-center mr-5">
          <Text
            className={`font-bold text-white ${
              data?.commission.length > 3 ? 'text-xl' : 'text-3xl'
            }`}
          >
            {data?.commission}%
          </Text>
          {/* <Text className="font-medium text-white text-xl">%</Text> */}
        </Div>
        <Div>
          <Text className="font-semibold laptop:text-lg phone:text-base text-primary-100">
            {data?.name}
          </Text>
          <Text className=" text-grey-100">
            PHP{' '}
            {parseFloat(data?.price)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
          </Text>
          <Text className=" text-grey-100">{data?.category}</Text>
        </Div>
        {subscription.status !== 'FREE PLAN' ? (
          <i
            className="fas fa-edit text-primary-100 text-2xl mr-2 mb-auto ml-auto cursor-pointer"
            onClick={() => setUpdateInfo(true)}
          />
        ) : (
          <></>
        )}
      </FlexRow>
      <FlexRow className="mt-10 phone:flex-col tablet:flex-row">
        <FlexRow className="phone:w-full tablet:w-auto phone:flex-col tablet:flex-row">
          <FlexRow
            className="px-5 py-2 bg-light-200 rounded-full w-full
          tablet:mb-0 phone:mb-4 phone:mr-auto tablet:mr-2 cursor-pointer"
            data-for="revenue"
            data-tip
          >
            <i className="fas fa-hand-holding-usd text-orange-100 text-2xl mr-2" />
            <Text className="text-primary-100 font-semibold">
              &#8369;
              {data?.earnings
                ? formatCount(data?.earnings).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                : `0.00`}
            </Text>
            <ReactTooltip
              backgroundColor="#FFFFFF"
              delayHide={150}
              effect="solid"
              id="revenue"
              textColor="#073763"
            >
              <Text>Commissions</Text>
            </ReactTooltip>
          </FlexRow>
          <FlexRow
            className="px-5 py-2 bg-light-200 rounded-full w-full 
          tablet:mb-0 phone:mb-4 phone:mr-auto tablet:mr-2 cursor-pointer"
            data-for="clicks"
            data-tip
          >
            <i className="fas fa-hand-pointer text-orange-100 text-2xl mr-2" />
            <Text className="text-primary-100 font-semibold">{data?.total_cliked}</Text>
            <ReactTooltip
              backgroundColor="#FFFFFF"
              delayHide={150}
              effect="solid"
              id="clicks"
              textColor="#073763"
            >
              <Text>Clicks</Text>
            </ReactTooltip>
          </FlexRow>
          <FlexRow
            className="px-5 py-2 bg-light-200 rounded-full tablet:mb-0 phone:mb-4 phone:mr-auto tablet:mr-2 w-full cursor-pointer"
            data-for="conversions"
            data-tip
          >
            <i className="fas fa-repeat text-orange-100 text-2xl mr-2" />
            <Text className="text-primary-100 font-semibold">{data?.conversion}</Text>
            <ReactTooltip
              backgroundColor="#FFFFFF"
              delayHide={150}
              effect="solid"
              id="conversions"
              textColor="#073763"
            >
              <Text>Conversions</Text>
            </ReactTooltip>
          </FlexRow>
        </FlexRow>
        {data?.status === '2' ? (
          <ButtonIcon
            className="pt-3 pb-3 mt-2 justify-center bg-secondary-200
              phone:w-full tablet:w-auto ml-auto phone:mt-5 tablet:mt-0 cursor-not-allowed"
            icon="fas fa-clipboard-list laptop:hidden desktop:inline"
          >
            Details
          </ButtonIcon>
        ) : (
          <ButtonIcon
            className="pt-3 pb-3 mt-2 justify-center bg-primary-100
              phone:w-full tablet:w-auto ml-auto phone:mt-5 tablet:mt-0"
            icon="fas fa-clipboard-list laptop:hidden desktop:inline"
            onClick={() => setInfoOpen(true)}
          >
            Details
          </ButtonIcon>
        )}
      </FlexRow>
    </Div>
  );
};

export const Partner: FC<ProductProps> = ({ selectedPartner, setSelected, setPartner }) => {
  const dispatch = useAppDispatch();

  const [affiliated, setAffiliated] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);

  React.useEffect(() => {
    setLoading(true);
    batch(async () => {
      await dispatch(partnersActions.affiliatedProducts(selectedPartner.user_id)).then((item) => {
        setAffiliated(item?.payload?.data?.data);
      });
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  }, [selectedPartner]);

  return (
    <>
      {selectedPartner ? (
        <>
          {!loading ? (
            <>
              <button
                className="text-grey-100 hover:text-grey-200 phone:hidden tabletWide:block mb-8"
                onClick={() => {
                  setSelected('');
                  setPartner('');
                }}
                type="button"
              >
                <Text className="items-center flex laptop:text-lg phone:base mr-3">
                  <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to overview
                </Text>
              </button>
              <Text className="font-poppins font-bold text-2xl text-primary-100">
                AFFILIATE OVERVIEW
              </Text>
              <FlexRow
                className="mt-5 laptop:flex-row phone:flex-col laptop:space-x-5 phone:space-y-5 
              laptop:space-y-0 phone:space-x-0"
              >
                <Overview
                  icon="hand-holding-usd"
                  label="EARNINGS"
                  value={
                    selectedPartner?.total_earnings ? (
                      <>
                        &#8369;{' '}
                        {selectedPartner?.total_earnings
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </>
                    ) : (
                      `0.00`
                    )
                  }
                />
                <Overview
                  icon="mouse-pointer"
                  label="CLICKS"
                  value={selectedPartner?.total_cliked}
                />
                <Overview
                  icon="repeat"
                  label="CONVERSIONS"
                  value={selectedPartner?.total_conversion || 0}
                />
              </FlexRow>

              <Text className="font-poppins font-bold text-2xl text-primary-100 mt-12">
                PROFILE
              </Text>
              <FlexRow className="mt-5 w-full">
                <Div
                  className="w-56 h-56 bg-orange-200 rounded-3xl 
            overflow-hidden flex text-9xl font-bold text-white items-center justify-center tablet:flex phone:hidden"
                >
                  {selectedPartner?.firstname?.substring(0, 1).toUpperCase()}
                </Div>
                <Div className="flex flex-col tablet:mt-0 tablet:ml-10 phone:ml-0">
                  <FlexRow className="phone:flex-col medium:flex-row tabletWide:flex-col laptop:flex-row">
                    <Text
                      className="text-3xl font-semibold
              rounded-lg text-primary-100"
                    >
                      {selectedPartner?.firstname === 'none'
                        ? selectedPartner?.company_name
                        : `${selectedPartner?.firstname} ${selectedPartner?.lastname}`}
                    </Text>
                    <Text
                      className="laptop:text-lg phone:text-base font-medium bg-light-200 
                          rounded-lg text-center text-blue-100 px-4 mr-auto medium:ml-2 tabletWide:ml-0 laptop:ml-2 hidden"
                    >
                      {selectedPartner?.role_name
                        ? `${selectedPartner?.role_name} ${selectedPartner?.commission}%`
                        : ''}
                    </Text>
                  </FlexRow>
                  <Text
                    className="text-base font-normal
              rounded-lg text-grey-200"
                  >
                    {selectedPartner?.email}
                  </Text>
                  <Text className="text-blue-100 text-lg font-medium">
                    {selectedPartner?.firstname === 'none'
                      ? selectedPartner?.contact_number
                      : selectedPartner?.contact}{' '}
                  </Text>
                  <FlexRow className="mt-7 tablet:flex-row phone:flex-col">
                    <a
                      className="phone:w-full tablet:w-56"
                      href={selectedPartner?.social_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <ButtonIcon
                        className="bg-grey-800 hover:bg-primary-300 phone:text-primary-500 pt-3 pb-3 phone:w-full tablet:w-56 justify-center"
                        icon="fas fa-external-link"
                      >
                        Social Link
                      </ButtonIcon>
                    </a>
                  </FlexRow>
                </Div>
              </FlexRow>

              <Text className="font-poppins font-bold text-2xl text-primary-100 mt-12">
                PRODUCTS
              </Text>
              <Div className="w-full mt-5 grid gap-x-6 phone:grid-cols-1 laptop:grid-cols-2">
                {affiliated?.map((value: any) => (
                  <ApplicationItems
                    data={value}
                    key={value.affiliate_id}
                    selectedPartner={selectedPartner}
                  />
                ))}
              </Div>
            </>
          ) : (
            <Flex className="items-center justify-center w-full">
              <Div className="flex flex-row items-center">
                <img
                  alt="Xffiliate"
                  className="w-12 h-12 mr-3 select-none"
                  src={
                    Icons.xffiliate ||
                    'https://github.com/XtendlyORG/xffiliate-frontend/blob/development/src/assets/icons/xffiliate-logo.png?raw=true'
                  }
                />
                <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
                  Xffiliate
                </Text>
              </Div>
              <Text className="text-secondary-200 py-8 text-center">
                Loading data ...
                <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
              </Text>
            </Flex>
          )}
        </>
      ) : (
        <Flex className="items-center justify-center w-full">
          <Text className="font-semibold text-secondary-100 text-2xl mb-5">Partner details</Text>
          <Text
            className="font-poppins font-bold text-4xl text-primary-100
        text-center mb-10"
          >
            No <span className="text-blue-100">content</span> yet.
          </Text>
        </Flex>
      )}
    </>
  );
};
