import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { batch } from 'react-redux';
import { Div, Flex, FlexRow, Text, Icons, Button } from '@core';
import { pricingPro, pricingPlus, Days, MonthsFull } from '@constants';
import { getCookie } from '@hooks';
import { API } from '@config';

export const Billings: FC<any> = ({ subscription, user }) => {

  const token = getCookie('token');
  const [totalAff, setTotalAff] = React.useState<any>(0);
  const newDate: any = new Date(user?.subscription?.date_of_expiration);
  const [adminCount, setAdminCount] = React.useState(0);

  React.useEffect(() => {
    batch(async () => {
      await fetch(`${API}/get-affiliate-table`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setTotalAff(data.data.length);
        });
    });
  }, [token]);

  React.useEffect(() => {
    fetch(`${API}/get-merchant-users`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          setAdminCount(data.data.length);
        }
      });
  }, [token]);

  return (
    <Flex className="desktop:pl-16 phone:pt-0 laptop:pr-20 phone:px-5">
      <Text className="font-poppins font-semibold text-xl phone:mt-4 tablet:mt-8 mb-3">Plan</Text>
      <hr className="text-grey-400 mb-10" />

      <FlexRow className="mb-10 phone:flex-col phone:items-start laptop:flex-row">
        {subscription.status === 'FREE PLAN' ? (
          <Div
            className="tablet:h-36 tablet:w-60 phone:h-28 phone:w-full tablet:px-44 py-28 
              bg-primary-300 rounded-lg flex justify-center items-center phone:px-10"
          >
            <Text className="font-bold text-4xl text-center">{subscription?.status}</Text>
          </Div>
        ) : (
          <Div
            className="relative tablet:h-36 tablet:w-60 phone:h-28 phone:w-full tablet:px-44 py-28 
        }
      bg-primary-300 flex justify-center items-center phone:px-10"
          >
            <div>
              <Text className="text-center text-xs text-primary-300 w-24">.</Text>
              <img alt="xffiliate" className="w-24 h-24" src={Icons.xffiliate} />
            </div>
            <div className="absolute z-10 -bottom-20 -right-20">
              <div className="text-white font-semibold flex items-start justify-center pt-2 w-36 h-36 bg-green-100 demo-button m-2">
                {subscription?.status}
              </div>
            </div>
          </Div>
        )}

        <Div className="laptop:ml-10 laptop:mt-0 phone:ml-0 phone:mt-10">
          <Text className="text-lg font-semibold text-primary-100 mb-3">What's Included:</Text>

          {subscription.status === 'PRO PLAN' ? (
            <>
              {pricingPro.map((value) => (
                <Div className="text-grey-100 flex items-center text-base mb-2" key={value}>
                  <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                  {value}
                </Div>
              ))}
              <Text className="text-lg text-grey-100 my-4">
                Your subscription will expire on:
                <span className="text-lg font-semibold text-primary-100 mb-3 mx-2">
                  {Days[newDate.getDay()]}, {MonthsFull[newDate.getMonth()]}{' '}
                  {new Date(newDate).toString().substring(8, 11)},{' '}
                  {new Date(newDate).toString().substring(11, 15)}
                </span>
              </Text>
            </>
          ) : (
            <></>
          )}

          {subscription.status === 'PLUS PLAN' ? (
            <>
              {pricingPlus.map((value) => (
                <Div className="text-grey-100 flex items-center text-base mb-2" key={value}>
                  <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                  {value}
                </Div>
              ))}
              <Text className="text-lg text-grey-100 my-4">
                Your subscription will expire on:
                <span className="text-lg font-semibold text-primary-100 mb-3 mx-2">
                  {Days[newDate.getDay()]},
                  <span className="mx-1">{MonthsFull[newDate.getMonth()]}</span>
                  <span>
                    {new Date(newDate).toString().substring(8, 11)},{' '}
                    {new Date(newDate).toString().substring(11, 15)}
                  </span>
                </span>
              </Text>
              <Link to="/merchant/subscription">
                <Button className="bg-orange-100">Upgrade to PRO</Button>
              </Link>
            </>
          ) : (
            <></>
          )}

          {subscription.status === 'FREE PLAN' ? (
            <>
              <Text className="text-lg text-grey-100">
                <i className="fas fa-check-circle text-green-100 text-xl mr-2" />Unlimited products
              </Text>
              <Text className="text-lg text-grey-100 mb-5">
                <i className="fas fa-check-circle text-green-100 text-xl mr-2" />Unlimited affiliates per
                product
              </Text>
              <Link to="/merchant/subscription">
                <Button className="bg-orange-100">Upgrade</Button>
              </Link>
              <Text className="text-lg text-grey-100 my-4">
                Your subscription will expire on:
                <span className="text-lg font-semibold text-primary-100 mb-3 mx-2">
                  Free Forever
                </span>
              </Text>
            </>
          ) : (
            <></>
          )}
        </Div>
      </FlexRow>

      <Text className="font-poppins font-semibold text-xl mt-4 mb-3">Usage</Text>
      <hr className="text-grey-400 mb-10" />
      
      {/* Products Section */}
      <FlexRow className="laptop:items-center laptop:flex-row phone:flex-col phone:items-start">
        <FlexRow className="phone:w-full laptop:w-1/3">
          <Text className="surfaceDuo:text-lg text-grey-100 phone:mb-3 laptop:mb-0">Total Products</Text>
          <Text className="surfaceDuo:text-lg text-grey-100 ml-auto mb-3 phone:block laptop:hidden">
            {subscription?.product?.total}/Unlimited
          </Text>
        </FlexRow>
        <div
          className="laptop:w-1/3 phone:w-full bg-grey-300 rounded-full h-2.5 cursor-pointer"
          title={`${subscription?.product?.total}`}
        >
          <div
            className={`${subscription?.product?.total > 0 ? "bg-blue-100" : "bg-grey-300"} h-2.5 rounded-full w-full`}
          />
        </div>
        <Text className="surfaceDuo:text-lg text-grey-100 ml-3 mr-auto phone:hidden laptop:block">
          {subscription?.product?.total}/Unlimited
        </Text>
      </FlexRow>
      
      {/* Affiliates Slots Section */}
      <FlexRow
        className="laptop:items-center laptop:flex-row phone:flex-col 
    phone:items-start mt-8"
      >
        <FlexRow className="phone:w-full laptop:w-1/3">
          <Text className="surfaceDuo:text-lg text-grey-100 phone:mb-3 laptop:mb-0">
            Total Affiliates Slots
          </Text>
          <Text className="surfaceDuo:text-lg text-grey-100 ml-auto mb-3 phone:block laptop:hidden">
            {totalAff}/Unlimited
          </Text>
        </FlexRow>
        <div
          className="laptop:w-1/3 phone:w-full bg-grey-300 rounded-full h-2.5 cursor-pointer"
          title={totalAff}
        >
          <div
            className={`${totalAff > 0 ? "bg-blue-100" : "bg-grey-300"} h-2.5 rounded-full w-full`}
          />
        </div>
        <Text className="surfaceDuo:text-lg text-grey-100 ml-3 mr-auto phone:hidden laptop:block">
          {totalAff}/Unlimited
        </Text>
      </FlexRow>
      
      {/* Users Section */}
      <FlexRow
        className="laptop:items-center laptop:flex-row phone:flex-col 
        phone:items-start mt-8"
      >
        <FlexRow className="phone:w-full laptop:w-1/3">
          <Text className="surfaceDuo:text-lg text-grey-100 phone:mb-3 laptop:mb-0">Users</Text>
          <Text className='text-grey-100 surfaceDuo:text-lg ml-auto mb-3 phone:block laptop:hidden'>
            {subscription.status === 'PRO PLAN' ? (
              <>
                {adminCount}
                <span>/Unlimited</span>
              </>
            ) : (
              <>
                {
                  adminCount === 0
                  ? `${adminCount}/1`
                  : <Link className='text-orange-100 font-semibold' to="/merchant/subscription">Upgrade</Link>
                }
              </>
            )}
          </Text>
        </FlexRow>
        <div
          className="laptop:w-1/3 phone:w-full bg-grey-300 rounded-full h-2.5 cursor-pointer"
          title={`${adminCount}`}
        >
          {
            adminCount === 0
            ? <div
                className='h-2.5 rounded-full bg-grey-300'
              />
            : <div
                className={`${
                  subscription.status === 'PRO PLAN' ? 'bg-blue-100' : 'bg-orange-100'
                } h-2.5 rounded-full`}
              />
          }
        </div>
        <Text
          className='text-grey-100 surfaceDuo:text-lg ml-3 mr-auto phone:hidden laptop:block'>
          {subscription.status === 'PRO PLAN' ? (
            <>
              {adminCount}
              <span>/Unlimited</span>
            </>
          ) : (
            <>
              {
                adminCount === 0
                ? `${adminCount}/1`
                : <Link className='text-orange-100 font-semibold' to="/merchant/subscription">Upgrade</Link>
              }
            </>
          )}
          {/* {adminCount} */}
        </Text>
      </FlexRow>

      {/* SizedBox */}
      <Div className='p-20'/>

    </Flex>
  );
};
