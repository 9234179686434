import React, { FC } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import validator from 'validator';
import { batch } from 'react-redux';
import { useCookie } from '@hooks';
import { usersActions, useAppDispatch } from '@redux';
import {
  Icons,
  H2,
  Info,
  Div,
  Flex,
  FlexRow,
  ButtonIcon,
  Input,
  PasswordInput,
  Button,
  Text,
  Toast,
} from '@core';

export const Signup: FC<any> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const newDate: any = new Date();
  const userNum: number = newDate.getTime();

  const [cookie, updateCookie] = useCookie('token', '0');
  const [userId, updateUserId] = useCookie('userId', '0');
  const [email, setEmail] = React.useState('');
  const username: any = `user${userNum}`;
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [validEmail, setValidEmail] = React.useState('');
  const [validPassword, setValiPassword] = React.useState('');
  const [validConfirmPassword, setValisetConfirmPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const xffiliateCode = query.get('xffiliate');
  const wixInstance = query.get('instance');

  React.useEffect(() => {
    if (xffiliateCode) {
      localStorage.setItem('xffiliate', xffiliateCode);
    }
    if (wixInstance) {
      const getUserInfo = async () => {
        const userInfo = await dispatch(usersActions.profile());
        return userInfo;
      };

      const loginAsWix = async () => {
        const data = await dispatch(usersActions.wixInstanceLogin(wixInstance));
        if (data) {
          updateCookie(`${data?.payload?.data?.access_token}`, 24);
          updateUserId(`${data?.payload?.data?.user_info?.id}`, 24);
          const userInfo = await getUserInfo();

          if (userInfo?.payload?.data?.onboarding === 'Complete') {
            navigate(`/merchant/dashboard`);
          }
          if (userInfo?.payload?.data?.onboarding === 'About You') {
            navigate(`/onboard/aboutyou?id=${userInfo?.payload?.data?.user_id}`);
          }
          if (userInfo?.payload?.data?.onboarding === 'Setup') {
            navigate(`/onboard/setup?id=${userInfo?.payload?.data?.user_id}`);
          }
        }
      };
      loginAsWix();
    }
  }, []);

  const onSubmit = () => {
    const isEmail = validator.isEmail(email);
    const isPassword = validator.isStrongPassword(password, {
      minLength: 6,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    const isPasswordSame = password === confirmPassword;
    setValidEmail(email === '' ? 'Email is required' : `${!isEmail ? 'Invalid email' : ''}`);
    setValiPassword(
      password === '' ? 'Password is required' : `${!isPassword ? 'Invalid password' : ''}`,
    );
    setValisetConfirmPassword(
      confirmPassword === ''
        ? 'Password is required'
        : `${password !== confirmPassword ? `Password don't match` : ''}`,
    );

    if (email !== '' && password !== '' && confirmPassword !== '') {
      if (isEmail && isPassword && isPasswordSame) {
        batch(async () => {
          setLoading(true);
          let body: any = {
            email,
            username,
            password,
            password_confirmation: confirmPassword,
            xffiliate_code: xffiliateCode,
          };
          if (wixInstance) {
            body = {
              ...body,
              wixInstance,
            };
          }
          const data: any = await dispatch(usersActions.newUser(body));
          if (data?.payload?.status === 200 || data?.payload?.status === 1) {
            setToastTitle('Account Created');
            setToastDesc('Redirecting to onboarding.');
            setToastKind('success');
            setToastOpen(true);
            setLoading(false);
            const login: any = await dispatch(usersActions.login({ email, password }));
            updateCookie(`${login?.payload?.access_token}`, 24);
            updateUserId(`${login?.payload?.userInfo?.id}`, 24);
            await dispatch(usersActions.profile());
            setTimeout(() => {
              setToastOpen(false);
              navigate(`/onboard/aboutyou?id=${data?.payload?.userId}`);
            }, 2000);
          } else {
            setToastTitle('Invalid Input');
            setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
            setToastKind('error');
            setToastOpen(true);
            setLoading(false);
          }
        });
      }
    }
  };

  return (
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 overflow-y-auto h-full">
      <div className="hidden">
        Unused Variables {cookie}
        {userId}
      </div>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300" />
      <Div
        className="flex flex-col
      items-center"
      >
        <H2 className="text-primary-100 mt-20">Sign Up</H2>
        <Info className="text-secondary-100 mt-2">
          Already have an account?{' '}
          <Link
            className="underline text-primary-100"
            to={wixInstance ? `/?instance=${wixInstance}` : '/'}
          >
            Login
          </Link>
        </Info>
        <Div className="phone:w-full medium:w-3/4 tabletWide:w-3/5 laptop:w-2/5 mt-10">
          <ButtonIcon
            className="w-full justify-center hidden"
            icon="fab fa-google font-bold"
            // onClick={() => setToastOpen(true)}
          >
            Sign up with Google
          </ButtonIcon>

          <FlexRow className="hidden">
            <Div className="border-t-2 border-secondary-200 w-full" />
            <Info className="text-secondary-100 font-semibold mx-4 ">OR</Info>
            <Div className="border-t-2 border-secondary-200 w-full" />
          </FlexRow>

          <form className="mt-7">
            <Input
              containerClass="w-full surfaceDuo:mr-2"
              errorLabel={validEmail}
              label="Email address *"
              name="email"
              onChange={setEmail}
              placeholder="Enter your email"
              type="email"
              validation={validEmail !== ''}
              value={email}
            />
            <FlexRow className="w-full phone:flex-col surfaceDuo:flex-row">
              <PasswordInput
                containerClass="w-full surfaceDuo:mr-2"
                label="Password *"
                name="password"
                onChange={setPassword}
                placeholder="Enter your Password"
                validation={validPassword !== ''}
                value={password}
              />
              <PasswordInput
                containerClass="w-full surfaceDuo:ml-2"
                label="Confirm Password *"
                name="confirm-password"
                onChange={setConfirmPassword}
                placeholder="Enter your Password"
                validation={validConfirmPassword !== ''}
                value={confirmPassword}
              />
            </FlexRow>

            <Div
              className="flex tablet:flex-row tablet:items-center mb-5 font-poppins 
            text-base phone:text-sm tablet:text-base phone:flex-col phone:items-start w-full"
            >
              <Div className="phone:w-full tablet:w-1/2">
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">&#8226; At least 6 characters</Text>
                </Div>
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">&#8226; At least 1 capital letter</Text>
                </Div>
              </Div>
              <Div className="phone:w-full tablet:w-1/2">
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">&#8226; At least 1 number</Text>
                </Div>
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${
                          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                            ? 'text-green-100'
                            : 'text-red-100'
                        }`
                  }
                >
                  <Text className="mb-1">&#8226; At least 1 special character</Text>
                </Div>
              </Div>

              <ul className="list-disc hidden">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 6 characters</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 1 capital letter</Text>
                </li>
              </ul>
              <ul className="list-disc tablet:ml-20 phone:ml-0 hidden">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 1 number</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${
                          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                            ? 'text-green-100'
                            : 'text-red-100'
                        }`
                  }
                >
                  <Text className="mb-1">At least 1 special character</Text>
                </li>
              </ul>
            </Div>

            <Button className="w-full py-5 justify-center" isLoading={loading} onClick={onSubmit}>
              Sign Up Now
            </Button>
            <Text className="text-base text-center mt-14 mb-10">
              By creating an account, you agree to Xffiliate’s{' '}
              <a
                className="underline text-primary-100"
                href="https://xffiliate.com/terms-of-service/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>{' '}
              Xffiliate’s{' '}
              <a
                className="underline text-primary-100"
                href="https://xffiliate.com/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </Text>
          </form>
        </Div>
      </Div>
    </Flex>
  );
};
