import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

export const deleteAsset = async (id: number) => {
  const token = getCookie('token');

  return axios({
    method: 'DELETE',
    url: `${API}/delete-asset/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => response);
};