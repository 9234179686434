import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FlexRow } from '@core';

interface ModalProps {
  isOpen: boolean;
  onClose?: any;
  title?: string;
}

export const MobileModal: FC<ModalProps> = ({ isOpen, onClose, children, title }) => (
  <Transition appear as={Fragment} show={isOpen}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-hide bg-secondary-200 w-full font-poppins tabletWide:hidden phone:fixed"
      onClose={() => {}}
    >
      <div className="h-full w-full text-center flex justify-end">
        <Transition.Child
          as={Fragment}
          enter="transition ease duration-700 transform"
          enterFrom="opacity-0 translate-y-full"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease duration-1000 transform"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-full"
        >
          <div
            className="h-full w-full overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
          >
            <FlexRow
              className="px-4 py-6 text-xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
            >
              <Dialog.Title as="h3" className="font-poppins text-xl">
                {title?.toUpperCase()}
              </Dialog.Title>
              <i
                className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                onClick={() => onClose()}
              />
            </FlexRow>
            <div className="px-4 h-full py-10 flex flex-col overflow-y-scroll scrollbar-hide">
              {children}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);
