import approve from "../assets/icons/notifIcons/approve2.png";
import reject from "../assets/icons/notifIcons/reject2.png";
import credit from "../assets/icons/notifIcons/credit2.png";
import email from "../assets/icons/notifIcons/email2.png";
import assetCreate from "../assets/icons/pending.png";
import assetApprove from "../assets/icons/approve.png";
import assetDenied from "../assets/icons/denied.png";

export function checkImageType(category : String) {
    switch(category) {
        case "approve":
        case "approved":
            return approve
        case "denied":
        case "reject":
            return reject
        case "credit":
            return credit
        case "applied":
            return email
        case "conversion created":
            return credit
        case "disbursement":
            return credit
        case "product update":
            return email
        case "affiliate categorized":
            return email
        case "affiliate update category":
            return email
        case "affiliate remove category":
            return email
        case "category removed":
            return email
        case "category update":
            return email
        case "affiliate removed":
            return reject
        case "product removed":
            return email
        case "affiliate marketing asset":
            return assetCreate
        case "marketing asset approved":
            return assetApprove
        case "marketing asset denied":
            return assetDenied
        case "marketing asset removed":
            return assetDenied
        case "new marketing asset":
            return assetCreate
        default:
            return 'error'
    }
}

export function checkMessageType(category: string) {
    switch(category) {
        case "approve":
        case "approved":
            return 'successfuly approved your application for'
        case "denied":
        case "rejected":
            return 'denied your application for'
        case "credit":
            return 'was credited to your account'
        case "applied":
            return 'applied to your product'
        case "conversion created":
            return 'You had a successful conversion with'
        case "disbursement":
            return 'has completely processed your disbursement'
        case "product update":
            return 'has updated the product'
        case "affiliate categorized":
            return 'categorized you as'
        case "affiliate update category":
            return 'updated your category as'
        case "affiliate remove category":
            return 'removed your category as'
        case "category removed":
            return 'removed the category'
        case "category update":
            return 'updated the category'
        case "affiliate removed":
            return 'removed you as an affiliate of'
        case "product removed":
            return 'removed the product'
        case "affiliate marketing asset":
            return 'created a new marketing asset content for'
        case "marketing asset approved":
            return 'approved your customed marketing asset content for'
        case "marketing asset denied":
            return 'denied your customed marketing asset content for'
        case "marketing asset removed":
            return 'removed a marketing asset content for'
        case "new marketing asset":
            return 'created a new marketing asset content for'
        default:
            return `[unknown message - ${category}]`
    }
}