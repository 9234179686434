import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { batch, useSelector } from 'react-redux';
import { AffiliatesInfo } from './AffiliatesInfo';
import { MobileModal } from './MobileModal';
import { useAppDispatch, usersActions, selectProducts, productsActions, merchantsActions, selectUser, selectProductsLoading } from '@redux';
import { classNames } from '@helpers';
import { useCookie, getCookie } from '@hooks';
import { FlexRow, Div, Flex, Text, Toast } from '@core';
import { NavBarAffiliate, QrCodeModal, AccountInformationModal, AffiliateItem, AlertMessage } from '@components';
import { AffiliateAnalytics } from '@src/components/Affiliate';
import { API } from '@config';
import { data3 } from '@src/constants/TempData';

export const DashboardAffiliate: FC<any> = () => {

  const user: any = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [token, updateToken] = useCookie('token', '0');

  const Partners: any = useSelector(selectProducts);
  const productLoading: any = useSelector(selectProductsLoading);
  const [selectedPartner, setPartner] = React.useState('');

  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);
  const [accountInfoOpen, setAccountInfoOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openOverview, setOpenOverview] = React.useState(false);

  const [totalEarnings, setTotalEarnings] = React.useState(0);
  const [totalClicks, setTotalClicks] = React.useState(0);
  const [totalConversion, setTotalConversion] = React.useState(0);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');
  const complete = query.get('complete');
  const notification = query.get('notification');
  const type = query.get('type');

  const unused: any[] = [];
  unused.push(token);

  const tokenAPI = getCookie('token');

  const [prodgraphClicks, setProdGraphClicks] = React.useState<any>(data3);
  const [prodgraphConversion, setProdGraphConversion] = React.useState<any>(data3);
  const [prodgraphEarnings, setProdGraphEarnings] = React.useState<any>(data3);

  const [loadedClicks, setLoadedClicks] = React.useState(false);
  const [loadedConversion, setLoadedConversion] = React.useState(false);
  const [loadedEarnings, setLoadedEarnings] = React.useState(false);

  React.useEffect(() => {
    if(complete){
      navigate('/affiliate/dashboard')
      window.location.reload();
    }
  })

  React.useEffect(() => {
    if (actionQuery === 'qrcode') {
      setQrCodeOpen(true);
    } else if (actionQuery === 'account') {
      setAccountInfoOpen(true);
    } else if (actionQuery === 'logout') {
      batch(async () => {
        await dispatch(usersActions.logout());
        updateToken('', 1);
        navigate(`/`);
      });
    } else if (actionQuery === 'unverified') {
      navigate(`/affiliate/dashboard`);
      setTimeout(() => {
        setToastTitle('UNVERIFIED');
        setToastDesc('Please confirm you email address.');
        setToastKind('warning');
        setToastOpen(true);
      }, 1000);
    } else if (actionQuery === 'notif') {
      batch(async() => {
        await dispatch(productsActions.productAffiliateList());
        if(!productLoading && Partners.length > 0){
          batch(async() => {
            Partners.map((product: any) => {
              if(product.name === notification && product.status !== '2'){
                setPartner(product)
                if(type === 'apply'){
                  navigate('/affiliate/dashboard?action=application')
                }else{
                  navigate('/affiliate/dashboard')
                }
              }else{
                navigate('/affiliate/dashboard')
              }
              return 1;
            })
          })
        }
      })
    }
  }, [actionQuery]);

  // Reset States
  React.useEffect(() => {
    setPartner('');
  }, []);

  React.useEffect(() => {
    setTotalConversion(user?.affiliate_data?.total_conversion)
    setTotalClicks(user?.affiliate_data?.totalClicks)
    setTotalEarnings(user?.affiliate_data?.totalEarned)
    batch(async () => {
      if(!loadedClicks){
        await fetch(`${API}/affiliate-product-clicks`, {
          method: "GET",
          headers: {Authorization: `Bearer ${tokenAPI}`}
        })
        .then(res => res.json())
        .then(data => {
          if(data.status === 1){
            setProdGraphClicks(data.data)
            setLoadedClicks(true)
          }else{
            setProdGraphClicks([])
            setLoadedClicks(true)
          }
        })
      }
      if(!loadedConversion){
        await fetch(`${API}/affiliate-product-conversion`, {
          method: "GET",
          headers: {Authorization: `Bearer ${tokenAPI}`}
        })
        .then(res => res.json())
        .then(data => {
          if(data.status === 1){
            setProdGraphConversion(data.data)
            setLoadedConversion(true)
          }else{
            setProdGraphConversion([])
            setLoadedConversion(true)
          }
        })
      }
      if(!loadedEarnings){
        await fetch(`${API}/affiliate-product-earned`, {
          method: "GET",
          headers: {Authorization: `Bearer ${tokenAPI}`}
        })
        .then(res => res.json())
        .then(data => {
          if(data.status === 1){
            setProdGraphEarnings(data.data)
            setLoadedEarnings(true)
          }else{
            setProdGraphEarnings([])
            setLoadedEarnings(true)
          }
        })
      }
      await dispatch(productsActions.productAffiliateList());
      await dispatch(merchantsActions.affiliateAnalytics());

    });
  }, []);

  return (
    <Div className="h-full">
      <NavBarAffiliate />
      <QrCodeModal isOpen={qrCodeOpen} onClose={() => setQrCodeOpen(false)} />
      <AccountInformationModal isOpen={accountInfoOpen} onClose={() => setAccountInfoOpen(false)} />
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Flex className="h-full w-full pt-24">

      {user?.email_confirmed ? (
        <></>
      ) : (
        <AlertMessage
          btnText="Go to Settings"
          linkTo="/affiliate/settings?action=verify"
          message={`Your email address ${user?.user_info?.email} is currently not verified. Please confirm your email address.`}
        />
      )}

      <FlexRow className="h-full w-full relative overflow-hidden">
          <div
            className="bottom-10 z-10 right-6 p-5 bg-primary-100 rounded-full font-semibold text-white
              phone:fixed tabletWide:hidden"
            onClick={() => setOpenOverview(true)}
          >
              Overview
          </div>
        <Div className=" border-t-2 border-grey-300 w-full absolute top-0" />
        <Div
          className="h-full tabletWide:w-700px phone:w-full flex flex-col bg-grey-500 pb-5 
        overflow-y-scroll scrollbar-hide"
        >
          <Tab.Group>
            <Div className="bg-orange-100">
              <Tab.List className="flex space-x-1 bg-orange-100">
                <div className="w-full">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        `w-full py-6 text-sm leading-5 font-medium text-blue-700 `,
                        `focus:outline-none`,
                        selected
                          ? 'bg-orange-200 text-white shadow'
                          : 'text-white hover:bg-white/[0.12] hover:text-primary-100',
                      )
                    }
                  >
                    AFFILIATED PRODUCTS
                  </Tab>
                </div>
              </Tab.List>
            </Div>
            <Tab.Panels>
              <Tab.Panel>
                <ul>
                  {Partners?.length === 0 ? (
                    <Div className="px-6 w-full flex justify-center pt-10">
                      <Text>You have no affiliated products yet</Text>
                    </Div>
                  ) : (
                    Partners?.map((partner: any) => (
                      <AffiliateItem
                          available
                          key={partner?.id}
                          onSelect={() => setOpenModal(true)}
                          partners={partner}
                          selectedPartner={selectedPartner}
                          setSelected={setPartner}
                        />
                    ))
                  )}
                </ul>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Div>
        <Flex className="overflow-y-auto phone:hidden tabletWide:flex">
          {
            selectedPartner !== '' ? 
              <AffiliatesInfo
                graphClicks={prodgraphClicks}
                graphConversion={prodgraphConversion}
                graphEarnings={prodgraphEarnings}
                selectedPartner={selectedPartner}
                setPartner={setPartner}
              />
              :
              <AffiliateAnalytics
                clicks={totalClicks}
                conversions={totalConversion}
                earnings={totalEarnings}
                mobile={openOverview}
                setPartner={Partners}
              />
          }
        </Flex>
        <MobileModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          title="Affiliated Product"
        >
          <AffiliatesInfo
            graphClicks={prodgraphClicks}
            graphConversion={prodgraphConversion}
            graphEarnings={prodgraphEarnings}
            selectedPartner={selectedPartner}
            setPartner={setPartner}
          />
        </MobileModal>
        <MobileModal
            isOpen={openOverview}
            onClose={() => setOpenOverview(false)}
            title="OVERVIEW"
          >
            <AffiliateAnalytics
              clicks={totalClicks}
              conversions={totalConversion}
              earnings={totalEarnings}
              mobile={openOverview}
              setPartner={Partners}
            />
          </MobileModal>
      </FlexRow>
      </Flex>
    </Div>
  );
};