import React from 'react';
import Iframe from 'react-iframe';
import { batch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { Flex, FlexRow, Icons, UserDropdown, Text } from '@core';

export const Payment = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const asd = useLocation();
  const user = useSelector(selectUser);
  console.log('search', asd);

  const query = new URLSearchParams(search);
  const invoice = query.get('invoice');

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, [dispatch, batch, usersActions]);

  return (
    <Flex className="laptop:px-20 tablet:px-10 phone:px-0">
      <FlexRow className="py-7 phone:px-5">
        <Link className="mr-auto flex flex-row items-center" to="/merchant/dashboard">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none phone:hidden tablet:block">
            Xffiliate
          </Text>
          <Text className="italic text-grey-100 pl-1 font-light self-end pb-2 phone:hidden tablet:block">
            Merchant
          </Text>
        </Link>
        <UserDropdown info={user.user_info} isWhite noHidden user={user} />
      </FlexRow>
      <hr className="text-secondary-300" />

      <Flex className="overflow-y-scroll scrollbar-hide">
        <Iframe className="w-full h-full" url={invoice || ''} />
      </Flex>
    </Flex>
  );
};
