import React from 'react';
import { Flex, Div, Icons, Text } from '@core';

export const Maintenance = () => (
  <>
  <Div className='phone:px-5 tablet:px-10 laptop:px-20'>
    <Div className="mr-auto flex flex-row items-center py-8">
      <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
      <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
        Xffiliate
      </Text>
    </Div>
    <hr className="text-secondary-300 w-full" />
  </Div>
  <Flex className="phone:px-5 justify-center items-center overflow-y-auto py-4 tabletWide:py-0">
    <img alt='Maintenance' className='w-300px h-200px tablet:w-500px tablet:h-350px block mx-auto mb-10' src={Icons.maintenance || 'https://internal-api-lark-file.larksuite.com/api/image/keys/img_v2_08593659-546f-44f0-8b10-b7df90e6166h?message_id=7150502288627367941'} />
    <Text className='text-center text-primary-100 tablet:text-2xl font-bold'>
      SYSTEM MAINTENANCE
      <i className="fas fa-wrench text-xl ml-3" />
    </Text>
    <Text className='text-center text-secondary-200 mt-10 text-sm tablet:text-base tablet:w-500px'>
      We are currently under maintenance to improve our site experience. 
      We'll be back shortly. 
      If you need help, you may reach us at our contact support
      <a className='text-blue-100 hover:underline ml-1' href='https://xffiliate.com/contact-us/' rel="noopener noreferrer" target='_blank' title='Contact Support Link'>
        link.
      </a>
    </Text>
  </Flex>
  </>
);