import React from 'react';
import validator from 'validator';
import { Link, useLocation } from 'react-router-dom';
import { Flex, Div, Icons, Text, Button, PasswordInput, Toast } from '@core';
import { forgotPasswordNew } from '@services';

export const ChangePassword = () => {
  const { search } = useLocation();

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [validPassword, setValiPassword] = React.useState('');
  const [validConfirmPassword, setValisetConfirmPassword] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const query = new URLSearchParams(search);
  const code = query.get('code');

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const onSubmit = async () => {
    const isPassword = validator.isStrongPassword(password, {
      minLength: 6,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    const isPasswordSame = password === confirmPassword;
    setValiPassword(
      password === '' ? 'Password is required' : `${!isPassword ? 'Invalid password' : ''}`,
    );
    setValisetConfirmPassword(
      confirmPassword === ''
        ? 'Password is required'
        : `${password !== confirmPassword ? `Password don't match` : ''}`,
    );

    if (password !== '' && confirmPassword !== '') {
      if (isPassword && isPasswordSame && code) {
        setLoading(true);
        const res = await forgotPasswordNew({
          password,
          password_confirmation: confirmPassword,
          code,
        });

        if (res.data.status === 0) {
          setToastKind('error');
          setToastTitle('Invalid');
          setToastDesc('Invalid reset link.');
          setToastOpen(true);
          setLoading(false);
        } else {
          setToastKind('success');
          setToastTitle('Changed');
          setToastDesc('Password successfully changed!');
          setToastOpen(true);
          setLoading(false);
          setSuccess(true);
        }
      }
    }
  };

  return (
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300 w-full" />
      <Flex className="items-center phone:pt-20 tablet:w-500px phone:w-full">
        {!success ? (
          <>
            <Text
              className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-5"
            >
              Change <span className="text-orange-100">Password</span>
            </Text>
            <Text className="mb-10 text-center">
              You can now change your password. Please enter your new password. You can only use
              this reset link once.
            </Text>
            <PasswordInput
              containerClass="w-full"
              label="New Password *"
              name="password"
              onChange={setPassword}
              placeholder="Enter your new password"
              validation={validPassword !== ''}
              value={password}
            />

            <Div
              className="flex tablet:flex-row ml-10 tablet:items-center mb-5 font-poppins 
            text-base phone:text-sm tablet:text-base phone:flex-col phone:items-start w-full"
            >
              <ul className="list-disc">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 6 characters</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 1 capital letter</Text>
                </li>
              </ul>
              <ul className="list-disc tablet:ml-20 phone:ml-0">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className=" mb-1">At least 1 number</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${
                          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                            ? 'text-green-100'
                            : 'text-red-100'
                        }`
                  }
                >
                  <Text className="mb-1">At least 1 special character</Text>
                </li>
              </ul>
            </Div>

            <PasswordInput
              containerClass="w-full"
              label="Confirm New Password *"
              name="confirm-password"
              onChange={setConfirmPassword}
              placeholder="Enter your new password"
              validation={validConfirmPassword !== ''}
              value={confirmPassword}
            />
            <Button className="px-10 mt-10" isLoading={loading} onClick={() => onSubmit()}>
              Change Password
            </Button>
          </>
        ) : (
          <>
            <Text
              className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-5"
            >
              You have <span className="text-orange-100">successfully</span> changed your{' '}
              <span className="text-orange-100">password!</span>
            </Text>
            <Text className="mb-10 text-center">
              You can now login to your account using the new password!
            </Text>
            <Link to="/">
              <Button className="px-10">Go to login page</Button>
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  );
};
