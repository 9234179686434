import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import {AffiliateLogin} from '@pages';
import { useAppDispatch, partnersActions, selectUser } from '@redux';
import { useCookie } from '@hooks';
import { Flex, Div, Icons, Text, Button, Toast } from '@core';

export const AffiliateApplications = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const user = useSelector(selectUser);

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [error, setError] = React.useState(false);

  const [token, updateToken] = useCookie('token', '0');
  const [loading, setLoading] = React.useState(true);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const query = new URLSearchParams(search);
  const xffiliateToken: any = query.get('token');
  const id: any = query.get('id');
  const status: any = query.get('status');
  const code: any = query.get('code');

  React.useEffect(() => {
    if(user?.user_info?.role === 'merchant'){
      if (xffiliateToken) {
      updateToken(xffiliateToken, 1);
      console.log(token);
      }
      batch(async () => {
      await dispatch(
        partnersActions.newStatus({
          id,
          status,
          code,
          token: xffiliateToken,
        }),
      ).then(async (item: any) => {
        if (item?.payload?.data?.status === 1) {
          setEmail(item?.payload?.data?.email);
          setName(item?.payload?.data?.affiliate_name);
          setToastOpen(false);
          setLoading(false);
          setError(false);
          console.log('Application executed successfully.')
        } else {
          setTimeout(() =>{
          setToastTitle('ERROR');
          setToastDesc(item?.payload?.message || 'Something went wrong. Try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
          setError(true);
          }, 15000);
        }
      });
    });
  }else{
    console.log('Invalid user. Cannot process application')
  }
  }, [xffiliateToken, id, status, code, user]);

  return (

  (user?.user_info?.role === 'merchant')?

    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300 w-full" />
      <Flex className="items-center laptop:pt-32 phone:pt-20 tablet:w-500px phone:w-full">
        {loading ? (
          <>
            <Text className="font-semibold text-secondary-100 text-2xl mb-5">Almost There!</Text>
            <Text
              className="font-poppins font-bold laptop:text-4xl phone:text-2xl text-primary-100
          text-center mb-10"
            >
              Please wait while we
              <span className="text-orange-100"> {status === '1' ? 'approve' : 'deny'}</span> the
              affiliate
              <span className="text-orange-100"> application.</span>
            </Text>
            <Text className="mb-10 text-center">
              This may take up to 30 seconds. 
              If you encounter ans error please reload the page. Thank
              you!
            </Text>
          </>
        ) : (
          <>
            {error ? (
              <>
                <Text className="font-semibold text-secondary-100 text-2xl mb-5">
                  Something went wrong!
                </Text>
                <Text
                  className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-5"
                >
                  {toastDesc}
                </Text>

                <Text className="mb-10 text-center">
                  Somthing went wrong. Please try again! It's either the application is expired or
                  not available.
                </Text>

                <Link to="/">
                  <Button className="px-20">Go Back</Button>
                </Link>
              </>
            ) : (
              <>
                <Text className="font-semibold text-secondary-100 text-2xl mb-5">
                  {status === '1' ? 'Affiliate Approved!' : 'Affiliate Denied!'}
                </Text>
                {status === '1' ? (
                  <Text
                    className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-5"
                  >
                    <span className="text-orange-100">{name}</span> is now your affiliate
                    <span className="text-orange-100"> partner.</span>
                  </Text>
                ) : (
                  <Text
                    className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-5"
                  >
                    <span className="text-orange-100">{name}</span> application have been
                    <span className="text-orange-100"> denied.</span>
                  </Text>
                )}

                <Text className="font-semibold text-blue-100 text-2xl mb-10">{email}</Text>
                {status === '1' ? (
                  <Text className="mb-10 text-center">
                    You can track {name} performance in the Xffiliate Partners Tab! Create an
                    effortless, integrated advocate sharing experience.
                  </Text>
                ) : (
                  <Text className="mb-10 text-center">
                    Create an effortless, integrated advocate sharing experience.
                  </Text>
                )}

                <Link to="/merchant/dashboard">
                  <Button className="px-20">Go to Dashboard</Button>
                </Link>
              </>
            )}
          </>
        )}
      </Flex>
    </Flex>

  :

  <>

    <AffiliateLogin />

  </>
  );
};