import React, {FC} from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, FlexRow, Toast } from '@core';
import { getCookie } from '@hooks';
import { API } from '@config';

interface DataProps {
	refNum: string;
	status: string;
	accName: string;
	bankName: string;
	bankType: string;
	date: string;
	id: string;
	key: any;
	amount: number;
	loading: boolean;
	readTransactions: boolean;
	writeTransactions: boolean;
}

export const TransactionData: FC<DataProps> = ({
	refNum,
	status,
	accName,
	bankName,
	bankType,
	date,
	id,
	key,
	amount,
	loading,
	readTransactions,
	writeTransactions,
}) => {

	const token = getCookie('token');
	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('');
	const [toastDesc, setToastDesc] = React.useState('');

	const onApprove = async () => {
	  	  fetch(`${API}/approve-disbursement/${id}`, {
	  	  	method: 'POST',
	  	  	headers:	{Authorization: `Bearer ${token}`}
	  	  })
	  	  .then(res => res.json())
	        .then(data => {
	        	if(data.status === 1){
	        		setToastTitle('Success');
	        		setToastDesc('Disbursement has been approved');
	        		setToastKind('success');
	        		setToastOpen(true);
	        		setTimeout(() => {
	        			window.location.reload();
	        		}, 2000)
	        	}else{
	        		setToastTitle('Error');
	        		setToastDesc( data?.message || 'Something went wrong. Try again.');
	        		setToastKind('error');
	        		setToastOpen(true);
	        	}
	        })
	}

return(
<>
<Toast
      description={toastDesc}
      isOpen={isToastOpen}
      onClose={() => setToastOpen(false)}
      title={toastTitle}
      toastKind={toastKind}
    />
{
readTransactions || writeTransactions ?

<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2'>
	<Div className='phone:flex phone:flex-row tabletWide:hidden' key={key}>
	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Merchant Name:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Status:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {accName}
	      </Text>
	      {
	      	status === 'PENDING' ?
	      	<Text
	      	  className="w-52 text-left"
	      	>
	      	  <button className='bg-secondary-200 text-white text-xs py-1 px-3 rounded-full'>
	      	  	{status}
	      	  </button>
	      	</Text>
	      	:
	      	<Text
	      	  className="w-52 text-left"
	      	>
	      	  <button className='bg-green-100 text-white text-xs py-1 px-3 rounded-full'>
	      	  	{status}
	      	  </button>
	      	</Text>
	      }
	  </Div>
	</Div>

	<details className='tabletWide:hidden'>
	  <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100'>
	    Details 
	    <i className='fas fa-chevron-down ml-2' />
	  </summary>

	  <Div className='phone:flex phone:flex-row laptop:hidden'>
	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Reference No.
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Amount:
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Disburse Date:
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Bank Name:
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          Bank Type:
	        </Text>
	    </Div>

	    <Div className='phone:flex phone:flex-col phone:w-1/2'>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {refNum}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {date}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {bankName}
	        </Text>
	        <Text
	          className="text-base text-secondary-200 font-light w-full text-left"
	        >
	          {bankType}
	        </Text>
	    </Div>
	  </Div>
	  <FlexRow className='w-full justify-start border-t border-grey-400 pt-2 mt-2'>
	  	<Text className='mr-4 text-secondary-200 inline'>
	  		Actions:
	  	</Text>
	  	{
	  		status === 'PENDING' ?
	  		<>
	  		{
	  			writeTransactions?
	  			<Text
	  			  className="text-center inline mr-4"
	  			>
	  			  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
	  			  	VIEW
	  			  </Link>
	  			</Text>
	  			:
	  			<Text
	  			  className="text-center inline mr-4"
	  			>
	  			  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
	  			  	VIEW
	  			  </Link>
	  			</Text>
	  		}
	  		</>
	  		:
	  		<Text
	  		  className="text-center inline mr-4"
	  		>
	  		  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
	  		  	VIEW
	  		  </Link>
	  		</Text>
	  	}
	  </FlexRow>

	</details>

	<Div className='w-full phone:hidden tabletWide:block laptop:hidden'>
	<details title='Click to view Actions'>
	<summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100'>
	<FlexRow className='w-full justify-around'>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {date}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {refNum}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {accName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {bankName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-1/6 text-center"
		>
		  {bankType}
		</Text>
	</FlexRow>
	<i className={`fas fa-edit cursor-pointer ${status === 'COMPLETED' ? 'text-green-100' : 'text-red-100'}`} title='Click to view Actions.'/>
	</summary>

	<FlexRow className='w-full justify-end border-t border-grey-400 pt-2'>
		<Text className='mr-4 font-semibold text-blue-100 inline'>
			Actions:
		</Text>
		{
			status === 'PENDING' ?
			<Text
			  className="text-center inline mr-4"
			>
			  <button className='bg-secondary-200 text-white text-xs py-1 px-3 rounded-full'>
			  	{status}
			  </button>
			</Text>
			:
			<Text
			  className="text-center inline mr-4"
			>
			  <button className='bg-green-100 text-white text-xs py-1 px-3 rounded-full'>
			  	{status}
			  </button>
			</Text>
		}
		{
			status === 'PENDING' ?
			<>
			{
				writeTransactions?
				<Text
				 className="text-center inline mr-4"
				  >
				  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
				  	VIEW
				  </Link>
				 </Text>
				:
				<Text
				 className="text-center inline mr-4"
				  >
				  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
				  	VIEW
				  </Link>
				</Text>
			}
			</>
			:
			<Text
	  		  className="text-center inline mr-4"
	  		>
	  		  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
	  		  	VIEW
	  		  </Link>
	  		</Text>
		}
	</FlexRow>
	</details>
	
	</Div>

	<FlexRow className='w-full justify-around phone:hidden laptop:flex'>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {date}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {refNum}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {accName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  &#8369; {amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {bankName}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-52 text-center"
		>
		  {bankType}
		</Text>
		{
			status === 'PENDING' ?
			<Text
			  className="w-52 text-center"
			>
			  <button className='bg-secondary-200 text-white text-xs py-1 px-3 rounded-full'>
			  	{status}
			  </button>
			</Text>
			:
			<Text
			  className="w-52 text-center"
			>
			  <button className='bg-green-100 text-white text-xs py-1 px-3 rounded-full'>
			  	{status}
			  </button>
			</Text>
		}
		{
			status === 'PENDING' ?
			<>
			{
				writeTransactions?
				<Text
				  className="w-52 text-center"
				>
				  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
				  	VIEW
				  </Link>
				</Text>
				:
				<Text
				  className="w-52 text-center"
				>
				  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
				  	VIEW
				  </Link>
				</Text>
			}
			</>
			:
			<Text
			  className="w-52 text-center"
			>
			  <Link className='text-blue-100 laptop:text-xs text-center hover:underline font-semibold' to={`/admin/transactions?action=${refNum}`}>
			  	VIEW
			  </Link>
			</Text>
		}
		
	</FlexRow>

	<button className='bg-green-100 text-white text-xs py-1 px-3 rounded-full hidden' onClick={onApprove}>
		{status}
	</button>
	
</div>
:
<>
<div className='w-full hover:bg-grey-500 border-b-2 border-grey-400 my-2 py-2 cursor-not-allowed'>
	{
		loading?
		<Text className='my-4 text-center text-secondary-200'>
		  Loading data ...
		  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
		</Text>
		:
		<Text className='my-4 text-center text-secondary-200'>
			Record cannot be viewed.
		</Text>
	}
</div>
</>
}
</>
)
};