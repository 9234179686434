import React, { FC, Fragment } from 'react';
import { Dialog, Transition, Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { FlexRow, Button, Div, Toast, InputV2, SelectV2, Text } from '@core';
import { getCookie, } from '@hooks';
import { API } from '@config';
import { AffiliateCategory, ColorsList } from '@constants';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const CreateCategoryModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const token = getCookie('token');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [categoryLabel, setCategoryLabel] = React.useState('Dealer');
  const [categoryCommission, setCategoryCommission] = React.useState('');
  const [categoryColor, setCategoryColor] = React.useState('#ffffff');

  const [moreColors, setMoreColors] = React.useState(false);

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if(
        parseFloat(categoryCommission) < 0 ||
        regExp.test(categoryCommission) ||
        parseFloat(categoryCommission) > 100
      ){
      setCategoryCommission('')
    }
  }, [categoryCommission])

  React.useEffect(() => {
    if(categoryLabel === '-- CUSTOM --'){
      setCategoryLabel('')
    }
  }, [categoryLabel])

  const onSubmit = (e:any) => {
    e.preventDefault()

    if(categoryLabel === '' || categoryColor === '' || categoryCommission === ''){
      setToastTitle('ERROR');
      setToastDesc('Please complete all fields.');
      setToastKind('error');
      setToastOpen(true);
    }else{
      setLoading(true)
      fetch(`${API}/add-role-commission`,{
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
              role_name: categoryLabel,
              color: categoryColor,
              commission: categoryCommission
          })
      })
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setLoading(false)
          setToastTitle('SUCCESS');
          setToastDesc('New affiliate role has been created.');
          setToastKind('success');
          setToastOpen(true);
          navigate('/merchant/settings?action=updated')
        }else{
          setLoading(false)
          setToastTitle('ERROR');
          setToastDesc(data.message || 'Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
        }
      })
    }

  }

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            if(window.location.pathname === `/merchant/dashboard`){
              navigate('/merchant/dashboard');
            }else if(window.location.pathname === `/merchant/settings`){
              navigate('/merchant/settings')
            }
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">CREATE CATEGORY</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      if(window.location.pathname === `/merchant/dashboard`){
                        navigate('/merchant/dashboard');
                      }else if(window.location.pathname === `/merchant/settings`){
                        navigate('/merchant/settings')
                      }
                    }}
                  />
                </FlexRow>

                <Div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide w-full">

                <Text className="font-roboto font-bold text-lg text-orange-100 ml-2">
                  Category Information
                </Text>
                <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                  Choose from pre-defined list of categories or create a customed category.
                </Text>



                <form  className='w-full' onSubmit={(e:any) => onSubmit(e)}>

                  <SelectV2
                    label="Select Category"
                    name="affiliateCategory"
                    onChange={setCategoryLabel}
                    options={AffiliateCategory}
                    placeholder="Choose"
                    value={categoryLabel}
                  />

                  {
                    categoryLabel === 'Dealer' || categoryLabel === 'Sub-Dealer' || categoryLabel === 'Distributor' || categoryLabel === 'Reseller' ?
                    <></>
                    :
                    <>
                      <label className='hidden flex text-left text-sm mb-2 text-secondary-200' htmlFor='name'>Enter Category Name:</label>
                      <input className='hidden border-2 border-primary-100 rounded-lg phone:w-72 tabletWide:w-96' maxLength={12} name='name' onChange={(e:any) => setCategoryLabel(e.target.value)} placeholder='Enter Category Name' required type='text' value={categoryLabel}/>
                      <InputV2
                        label="Category Name %"
                        name="category"
                        onChange={setCategoryLabel}
                        placeholder="Enter Category Commission"
                        type="text"
                        value={categoryLabel}
                      />
                    </>
                  }

                  

                  <label className='hidden flex text-left text-sm mt-2 mb-2 text-secondary-200' htmlFor='role'>Category Absolute Commission:
                  </label>
                  <input className='hidden border-2 border-primary-100 rounded-lg phone:w-72 tabletWide:w-96' inputMode="numeric" name='role' onChange={(e:any) => setCategoryCommission(e.target.value)} placeholder='Enter Commission' required type='number' value={categoryCommission}/>
                  <InputV2
                    label="Category Commission %"
                    name="commission"
                    onChange={setCategoryCommission}
                    placeholder="Enter Category Commission"
                    type="text"
                    value={categoryCommission}
                  />

                    <Popover className="relative">
                          <Popover.Button className='px-5 py-3 border border-secondary-100 rounded-lg w-full'>
                            <Text className='text-sm text-secondary-100 font-poppins font-bold text-left'>Select Colors:</Text>
                            <FlexRow className='mt-4 justify-start items-center'>
                              <div className='w-4 h-4 rounded border-2 border-secondary-200' style={{backgroundColor: categoryColor}} />
                              <Text className='ml-4 text-sm text-secondary-200'>
                                {categoryColor}
                              </Text>
                            </FlexRow>
                          </Popover.Button>
                          <Popover.Panel className="absolute z-10 left-0 mt-4">
                            <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 w-64 mobile:w-80">
                              <p className='font-semibold mb-2'>
                                Choose color:
                              </p>

                              <Div className='grid grid-cols-5'>
                                {
                                  ColorsList.map((color:any) => (
                                      <div className='w-6 h-6 m-2 rounded shadow-lg border-2 hover:border-secondary-200 border-white cursor-pointer' key={color.hex} onClick={() => setCategoryColor(color.hex)} style={{backgroundColor: `${color.hex}`}} title={color.name}/>
                                    ))
                                }
                              </Div>
                              <div className='text-blue-100 hover:underline my-2 font-semibold cursor-pointer' onClick={() => setMoreColors(!moreColors)}>
                                More Colors
                                <i className="fas fa-angle-right ml-2"/>
                                <i className="fas fa-angle-right"/>
                              </div>

                              <FlexRow className={`mt-4 justify-start ${moreColors? '' : 'hidden'}`}>
                                <label className='text-sm text-secondary-200' htmlFor='role'>Select:</label>
                                <input className='ml-4 h-7 border-2 border-primary-100 rounded-lg cursor-pointer' name='role' onChange={(e:any) => setCategoryColor(e.target.value)} required type='color' value={categoryColor}/>
                                <input className='ml-4 pl-2 w-28 border-2 border-primary-100 rounded-lg' onChange={(e:any) => setCategoryColor(e.target.value)} value={categoryColor} />
                              </FlexRow>

                              <p className='mt-4 text-sm text-secondary-200'>
                                ** Click anywhere on screen to close. **
                              </p>
                            </div>
                          </Popover.Panel>
                    </Popover>

                  <Popover className="relative mt-14 mb-4">
                        <Popover.Button>
                            <Text className='text-blue-100 font-semibold hover:underline'>
                              FAQs | Commissions
                              <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                            </Text>
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
                          <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-80">
                            <p className='font-semibold text-sm'>
                              Absolute Commission
                            </p>
                            <hr className='text-primary-100 my-1'/>
                            <p className='text-sm mt-4'>
                              Setting commissions on a specific role will be applied 
                              to all products affiliated of the categorized partner.
                            </p>
                            <p className='mt-4 text-sm text-secondary-200'>
                              ** Click anywhere on screen to close. **
                            </p>
                          </div>
                        </Popover.Panel>
                  </Popover>

                  <Popover className="relative mb-96">
                        <Popover.Button>
                            <Text className='text-blue-100 font-semibold hover:underline'>
                              FAQs | Colors
                              <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
                            </Text>
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10 phone:right-0">
                          <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 phone:w-48 mobile:w-80">
                            <p className='font-semibold text-sm'>
                              Color Codes
                            </p>
                            <hr className='text-primary-100 my-1'/>
                            <p className='text-sm my-2'>
                              Colors can be specified in different ways:
                            </p>
                            <a className='text-sm block hover:underline hover:text-blue-100' href='https://www.w3.org/wiki/CSS/Properties/color/keywords' rel="noopener noreferrer" target='_blank'>
                              &#8226; Color names
                            </a>
                            <a className='text-sm block hover:underline hover:text-blue-100' href='https://www.rapidtables.com/web/color/RGB_Color.html' rel="noopener noreferrer" target='_blank'>
                              &#8226; RGB values
                            </a>
                            <a className='text-sm block hover:underline hover:text-blue-100' href='https://www.w3.org/wiki/CSS/Properties/color/keywords' rel="noopener noreferrer" target='_blank'>
                              &#8226; Hexadecimal values
                            </a>
                            <p className='mt-4 text-sm text-secondary-200'>
                              ** Click anywhere on screen to close. **
                            </p>
                          </div>
                        </Popover.Panel>
                  </Popover>

                  {
                    loading?
                    <button className='hidden bg-primary-100 hover:bg-blue-100 rounded-lg py-4 text-center text-white my-8 w-44'>
                      <i className="fad fa-spinner-third animate-spin" />
                    </button>
                    :
                    <button className='hidden bg-primary-100 hover:bg-blue-100 rounded-lg py-4 text-center text-white my-8 w-44' type='submit'>
                      Add New Category
                    </button>
                  }
                  
                </form>

                </Div>

                <hr />
                <Div className='flex  mx-8 my-5'>
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    CREATE CATEGORY
                  </Button>
                </Div>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};