import React, { FC } from 'react';
import { batch } from 'react-redux';
import { Link } from 'react-router-dom';
import { usersActions, useAppDispatch } from '@redux';
import { PaginationV2, AffiliateCategoryList } from '@components';
import { Div, Flex, FlexRow, Text, Input, Button, Toast, TextArea, Icons } from '@core';
import { pricingPro, pricingPlus } from '@constants';
import { getCookie } from '@hooks';
import { API } from '@config';
import { getAffiliateCategory } from '@services';

export const AccountDetails: FC<any> = ({ subscription, user }) => {
  
  const dispatch = useAppDispatch();
  const inputFile = React.useRef<any>(null);
  const token = getCookie('token');

  const [totalAff, setTotalAff] = React.useState<any>(0);
  const [firstname, setFirstname] = React.useState(user?.user_info?.firstname);
  const [lastname, setLastname] = React.useState(user?.user_info?.lastname);
  const [contactNum, setContactNum] = React.useState(user?.user_info?.contact);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [imageValid, setImageValid] = React.useState<any>();

  const [companyName, setCompanyName] = React.useState(user?.affiliate_company_info?.company_name);
  const [companyLink, setCompanyLink] = React.useState(user?.affiliate_company_info?.company_link);
  const [companyNumber, setCompanyNumber] = React.useState(
    user?.affiliate_company_info?.contact_number);

  const [comLogo, setComLogo] = React.useState<any>();
  const [comName, setComName] = React.useState(user?.merchant_data?.company[0]?.name);
  const [comDes, setComDes] = React.useState(user?.merchant_data?.company[0]?.description);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  const [isCompany, setIsCompany] = React.useState(false);

  const [categoryList, setCategoryList] = React.useState<any>([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newCategory = categoryList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    if(comLogo){
      if(comLogo[0].type.includes('image')){
        setImageValid(true)
      }else{
        setImageValid(false)
      }
    }
  })

  React.useEffect(() => {
    if(user?.affiliate_company_info !== null){
      setFirstname(user?.affiliate_company_info?.company_name)
      setLastname(user?.affiliate_company_info?.company_link)
      setContactNum(user?.affiliate_company_info?.contact_number)
      setIsCompany(true)
    }else{
      setFirstname(user?.user_info?.firstname);
      setLastname(user?.user_info?.lastname);
      setContactNum(user?.user_info?.contact);
    }
  }, [user]);

  React.useEffect(() => {
    batch(async() => {
      await fetch(`${API}/get-affiliate-table`, {
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`}
      })
      .then(res => res.json())
      .then(data => {
        setTotalAff(data.data.length)
      })
      const categoryData = await getAffiliateCategory();
      if(categoryData.data.status === 1){
        setCategoryList(categoryData.data.data)
      }
    })
  }, [token])



  const onSubmit = () => {
    if (firstname === '' || lastname === '' || contactNum === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newInfo({
            firstname,
            lastname,
            contact: contactNum,
            role: user?.user_info?.role,
            subscription: user?.user_info?.subscription,
          }),
        );

        if (data?.payload?.status === 200) {
          setToastTitle('Account Update');
          setToastDesc('Account details updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          await dispatch(usersActions.profile());
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    }
  };

  const onUpdate = () => {
    if (companyNumber === '' || companyName === '' || companyLink === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      setLoading(true)
      fetch(`${API}/update-affiliate-company`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_name: companyName,
          company_link: companyLink,
          contact_number: companyNumber,
          company_email: user?.user_info?.email
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data?.status === 1) {
          setToastTitle('Account Update');
          setToastDesc('Account details updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          batch(async () => {
            await dispatch(usersActions.profile());
          })
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      })
    }
  };

  const onUpdateCompany = () => {
    if (!comLogo && comName !== '' && comDes !== '') {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newCompanyInfo({
            icon: comLogo,
            name: comName,
            description: comDes,
          }),
        );
        if (data?.payload?.status === 200) {
          setToastTitle('Account Update');
          setToastDesc('Company details updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          await dispatch(usersActions.profile());
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    }else if (comLogo && comName !== '' && comDes !== '' && imageValid) {
      batch(async () => {
        setLoading(true);
        const data: any = await dispatch(
          usersActions.newCompanyInfo({
            icon: comLogo,
            name: comName,
            description: comDes,
          }),
        );
        if (data?.payload?.status === 200) {
          setToastTitle('Account Update');
          setToastDesc('Company details updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          await dispatch(usersActions.profile());
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
    } else {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields and file must be an image.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000)
    }
  };

  return (
    <Flex
      className="desktop:pl-16 phone:pt-0 laptop:pr-20 phone:px-5"
    >
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Text className="font-poppins font-semibold text-xl phone:mt-4 tablet:mt-8 mb-3">
        Profile
      </Text>
      <hr className="text-grey-400 mb-10" />
      <FlexRow
        className="mb-10 phone:flex-col phone:items-start laptop:flex-row 
      laptop:items-center"
      >
        <Text
          className="laptop:w-36 laptop:mr-36 laptop:mb-auto phone:mb-5 text-base 
      text-grey-100 font-medium"
        >
          About You
        </Text>
        {
          isCompany?
          <Div className="w-full">
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full"
              label="Company Name"
              lableSize="text-sm"
              onChange={setCompanyName}
              type="text"
              value={companyName}
            />
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full"
              label="Company Link"
              lableSize="text-sm"
              onChange={setCompanyLink}
              type="text"
              value={companyLink}
            />
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full "
              label="Company Number"
              lableSize="text-sm"
              maxlength={11}
              onChange={setCompanyNumber}
              pattern="[0-9]*"
              type="number"
              value={companyNumber}
            />
            {companyName == user?.affiliate_company_info?.company_name &&
            companyLink == user?.affiliate_company_info?.company_link &&
            companyNumber == user?.affiliate_company_info?.contact_number ? (
              <Button className="w-56 justify-center cursor-not-allowed bg-grey-100" disabled>
                Update Details
              </Button>
            ) : (
              <Button className={`w-56 justify-center `} isLoading={loading} onClick={onUpdate}>
                Update Details
              </Button>
            )}
          </Div>
          :
          <Div className="w-full">
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full"
              label="First Name"
              lableSize="text-sm"
              onChange={setFirstname}
              type="text"
              value={firstname}
            />
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full"
              label="Last Name"
              lableSize="text-sm"
              onChange={setLastname}
              type="text"
              value={lastname}
            />
            <Input
              className="pt-3 pb-3 pl-3 pr-3"
              containerClass="tablet:w-96 phone:w-full "
              label="Contact Number"
              lableSize="text-sm"
              maxlength={11}
              onChange={setContactNum}
              pattern="[0-9]*"
              type="number"
              value={contactNum}
            />
            {firstname == user?.user_info?.firstname &&
            lastname == user?.user_info?.lastname &&
            contactNum == user?.user_info?.contact ? (
              <Button className="w-56 justify-center cursor-not-allowed bg-grey-100" disabled>
                Update Details
              </Button>
            ) : (
              <Button className={`w-56 justify-center `} isLoading={loading} onClick={onSubmit}>
                Update Details
              </Button>
            )}
          </Div>
        }
      </FlexRow>
      

      {user?.user_info?.role === 'merchant' ? (
        <>
          <hr className="text-grey-400 mb-10" />
          <FlexRow
            className="pb-10 phone:flex-col phone:items-start laptop:flex-row 
      laptop:items-center"
          >
            <Text
              className="laptop:w-36 laptop:mr-36 laptop:mb-auto phone:mb-5 text-base 
      text-grey-100 font-medium"
            >
              Company Information
            </Text>
            <Div className="w-full">
              <Div className="h h-40 w-40 border-primary-100 border-4 rounded-full overflow-hidden">
                {comLogo ? (
                  <img
                    alt="Comapny Logo"
                    className="w-full h-full object-contain"
                    onError={() => setImageLoaded(true)}
                    src={window.URL.createObjectURL(comLogo[0])}
                  />
                ) : (
                  <img
                    alt="Comapny Logo"
                    className="w-full h-full object-contain"
                    onError={() => setImageLoaded(true)}
                    src={
                      imageLoaded
                        ? 'https://i.imgur.com/CGDGnf6.png'
                        : user?.merchant_data?.company[0]?.image
                    }
                  />
                )}
              </Div>
              <button
                className={`text-blue-100 bg-grey-500 hover:bg-opacity-80 focus:ring-2 focus:ring-blue-300 
            font-poppins font-medium rounded-lg laptop:text-lg px-5 py-2 text-center
            inline-flex items-center phone:text-sm tablet:text-base mr-2 mt-5 mb-10`}
                onClick={() => inputFile?.current.click()}
                type="button"
              >
                Change Logo
              </button>
              <input
                accept="image/*"
                className="hidden"
                onChange={(event) => setComLogo(event.target.files)}
                ref={inputFile}
                type="file"
              />
              <Input
                className="pt-3 pb-3 pl-3 pr-3"
                containerClass="tablet:w-96 phone:w-full"
                label="Company Name"
                lableSize="text-sm"
                onChange={setComName}
                type="text"
                value={comName}
              />
              <TextArea
                className="pt-3 pb-3 pl-3 pr-3"
                containerClass="tablet:w-96 phone:w-full"
                label="Company Description"
                lableSize="text-sm"
                onChange={setComDes}
                placeholder="Company Description"
                rows={4}
                type="text"
                value={comDes}
              />
              {!comLogo &&
              comName == user?.merchant_data?.company[0]?.name &&
              comDes == user?.merchant_data?.company[0]?.description ? (
                <Button className="w-56 justify-center cursor-not-allowed bg-grey-100" disabled>
                  Update
                </Button>
              ) : (
                <Button
                  className={`w-56 justify-center `}
                  isLoading={loading}
                  onClick={onUpdateCompany}
                >
                  Update
                </Button>
              )}
            </Div>
          </FlexRow>
          <Text className="font-poppins font-semibold text-xl mt-8 mb-3 hidden">Plan</Text>
          <hr className="text-grey-400 mb-10 hidden" />
          <FlexRow
            className="mb-10 phone:flex-col phone:items-start laptop:flex-row 
      laptop:items-center hidden"
          >
          {
            subscription.status === 'FREE PLAN' ?
            <Div
              className="tablet:h-36 tablet:w-60 phone:h-28 phone:w-full tablet:px-44 py-28 
                bg-primary-300 rounded-lg flex justify-center items-center phone:px-10"
            >
              <Text className="font-bold text-4xl text-center">{subscription?.status}</Text>
            </Div>
            :
            <Div
              className="relative tablet:h-36 tablet:w-60 phone:h-28 phone:w-full tablet:px-44 py-28 
          }
        bg-primary-300 flex justify-center items-center phone:px-10"
            >
              <div>
                <Text className="text-center text-xs text-primary-300 w-24">.</Text>
                <img alt='xffiliate' className='w-24 h-24' src={Icons.xffiliate} />
              </div>
              <div className='absolute z-10 -bottom-20 -right-20'>
                <div className='text-white font-semibold flex items-start justify-center pt-2 w-36 h-36 bg-green-100 demo-button m-2'>{subscription?.status}</div>
              </div>
            </Div>
          }
            
            <Div className="laptop:ml-10 laptop:mt-0 phone:ml-0 phone:mt-10">
              <Text className="text-lg font-semibold text-primary-100 mb-3">
                What's Included:
              </Text>

              {subscription.status === 'PRO PLAN' ? (
                <>
                  {pricingPro.map((value) => (
                <Div className="text-grey-100 flex items-center text-base mb-2" key={value}>
                  <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                  {value}
                </Div>
              ))}
                <Text className="text-lg text-grey-100 my-4">
                    Your subscription will expire on:
                    <span className='text-lg font-semibold text-primary-100 mb-3 mx-2'>
                        {user?.subscription?.date_of_expiration.toString().substring(0, 10)}
                    </span>
                </Text>
                </>
              ) :
                <></>
              }

              {
                subscription.status === 'PLUS PLAN' ?
                  <>
                    {pricingPlus.map((value) => (
                  <Div className="text-grey-100 flex items-center text-base mb-2" key={value}>
                    <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                    {value}
                  </Div>

                ))}
                  <Text className="text-lg text-grey-100 my-4">
                      Your subscription will expire on:
                      <span className='text-lg font-semibold text-primary-100 mb-3 mx-2'>
                          {user?.subscription?.date_of_expiration.toString().substring(0, 10)}
                      </span>
                  </Text>
                  <Link to="/merchant/subscription">
                    <Button className="bg-red-100">Upgrade to PRO</Button>
                  </Link>
                  </>
                  :
                  <></>
              }

              {
                subscription.status === 'FREE PLAN' ?
                  <>
                    <Text className="text-lg text-grey-100">
                      <i className="fas fa-check-circle text-green-100 text-xl mr-2" />
                      3 products
                    </Text>
                    <Text className="text-lg text-grey-100 mb-5">
                      <i className="fas fa-check-circle text-green-100 text-xl mr-2" />{' '}
                      5 affiliates per product
                    </Text>
                    <Link to="/merchant/subscription">
                      <Button className="bg-red-100">Upgrade</Button>
                    </Link>
                    <Text className="text-lg text-grey-100 my-4">
                        Your subscription will expire on:
                        <span className='text-lg font-semibold text-primary-100 mb-3 mx-2'>
                            Free Forever
                        </span>
                    </Text>
                  </>
                  :
                  <></>
              }

            </Div>
          </FlexRow>
          <Text className="font-poppins font-semibold text-xl mt-8 mb-3 hidden">Usage</Text>
          <hr className="text-grey-400 mb-10 hidden" />
          <FlexRow className="laptop:items-center laptop:flex-row phone:flex-col phone:items-start hidden">
            <FlexRow className="phone:w-full laptop:w-1/3">
              <Text className="text-lg text-grey-100 phone:mb-3 laptop:mb-0">Total Products</Text>
              {
                subscription.product.total >= subscription.product.limit && subscription.status !== 'PRO PLAN' ?
                <Text className="text-lg text-red-400 font-semibold ml-auto phone:block laptop:hidden">
                  Limit Reached
                </Text>
                :
                <Text className="text-lg text-grey-100 ml-auto phone:block laptop:hidden">
                  {subscription?.product?.total}/
                  {subscription.status === 'PRO PLAN'? 'Unlimited' : subscription?.product?.limit}
                </Text>
              }
            </FlexRow>
            <div className="laptop:w-1/3 phone:w-full bg-grey-300 rounded-full h-2.5">
              <div
                className={`${subscription.product.total >= subscription.product.limit && subscription.status !== 'PRO PLAN' ? 'bg-red-400' : 'bg-blue-100'} h-2.5 rounded-full`}
                style={{
                  width: `${subscription.product.remaining > 10 ? 100 : subscription.product.remaining }%`,
                }}
              />
            </div>
            {
              subscription.product.total >= subscription.product.limit && subscription.status !== 'PRO PLAN' ?
              <Text className="text-lg text-red-400 font-semibold ml-3 mr-auto phone:hidden laptop:block">
                Limit Reached
              </Text>
              :
              <Text className="text-lg text-grey-100 ml-3 mr-auto phone:hidden laptop:block">
                {subscription?.product?.total}/
                {subscription.status === 'PRO PLAN'? 'Unlimited' : subscription?.product?.limit}
              </Text>
            }
          </FlexRow>
          <FlexRow
            className="laptop:items-center laptop:flex-row phone:flex-col 
      phone:items-start mt-8 hidden"
          >
            <FlexRow className="phone:w-full laptop:w-1/3">
              <Text className="text-lg text-grey-100 phone:mb-3 laptop:mb-0">
                Total Affiliates Slots
              </Text>
              {
                subscription.status === 'FREE PLAN' && totalAff >= 15 ?
                <Text className="text-lg text-red-400 font-semibold ml-auto phone:block laptop:hidden">
                  Limit Reached
                </Text>
                :
                <Text className="text-lg text-grey-100 ml-auto phone:block laptop:hidden">
                  {totalAff}/
                  {subscription.status === 'PRO PLAN' || subscription.status === 'PLUS PLAN' ? 'Unlimited' : ''}
                  {subscription.status === 'FREE PLAN' ? '15' : ''}
                </Text>
              }
            </FlexRow>
            <div className="laptop:w-1/3 phone:w-full bg-grey-300 rounded-full h-2.5">
              <div
                className={`${subscription.status === "FREE PLAN" && totalAff >= 15 ? 'bg-red-400' : 'bg-blue-100'} h-2.5 rounded-full`}
                style={{
                  width: `${
                    subscription.status === 'FREE PLAN' && totalAff <= 15 ? totalAff*6.66 : 100 
                  }%`,
                }}
              />
            </div>
            {
              subscription.status === 'FREE PLAN' && totalAff >= 15 ?
              <Text className="text-lg text-red-400 font-semibold ml-3 mr-auto phone:hidden laptop:block">
                Limit Reached
              </Text>
              :
              <Text className="text-lg text-grey-100 ml-3 mr-auto phone:hidden laptop:block">
                {totalAff}/
                {subscription.status === 'PRO PLAN' || subscription.status === 'PLUS PLAN' ? 'Unlimited' : ''}
                {subscription.status === 'FREE PLAN' ? '15' : ''}
              </Text>
            }
          </FlexRow>
          <FlexRow
            className="laptop:items-center laptop:flex-row phone:flex-col 
      phone:items-start mt-8 hidden"
          >
            <FlexRow className="phone:w-full laptop:w-1/3">
              <Text className="text-lg text-grey-100 phone:mb-3 laptop:mb-0">
                Total Affiliates Links
              </Text>
              <Text className="text-lg text-grey-100 ml-auto phone:block laptop:hidden">
                {subscription?.totalLinks}
              </Text>
            </FlexRow>
            <div className="laptop:w-1/3 phone:w-full bg-grey-300 rounded-full h-2.5">
              <div
                className="bg-blue-100 h-2.5 rounded-full"
                style={{
                  width: `100%`,
                }}
              />
            </div>
            <Text className="text-lg text-grey-100 ml-3 phone:hidden laptop:block">
              {subscription?.totalLinks}
            </Text>
          </FlexRow>
        </>
      ) : (
        <></>
      )}

      {
        user?.user_info?.role === 'affiliate' ?
        <>
        <Text className="font-poppins font-semibold text-xl phone:mt-3 tablet:mt-8 mb-3">
          Partnered Merchants
        </Text>
        <hr className="text-grey-400 mb-10" />

        <FlexRow className='mx-auto phone:hidden w-full medium:flex tablet:hidden tabletWide:flex mt-8'>
          <Text className='w-1/3 text-lg text-secondary-200 text-center font-semibold'>
            Affiliate Category
          </Text>
          <Text className='w-1/3 text-lg text-secondary-200 text-center font-semibold'>
            Affiliate Commission %
          </Text>
          <Text className='w-1/3 text-lg text-secondary-200 text-center font-semibold'>
            Merchant Data
          </Text>
        </FlexRow>
        <hr className="mx-auto text-grey-400 mt-2 mb-4 phone:hidden medium:flex tablet:hidden tabletWide:flex" />

        {
          categoryList.length > 0 ?
          <>
          <Div className='w-full'>
          {
            newCategory.map((list:any, index: number) => (
              <AffiliateCategoryList
                categoryCommission={list.category_commission}
                categoryName={list.category_name}
                companyDesc={list.company_description}
                companyIcon={list.company_icon}
                companyName={list.company_name}
                key={index}
                merchantFName={list.merchant_firstname}
                merchantLName={list.merchant_lastname}
              />
              ))
          }
          {
            newCategory.length === 0 && categoryList.length > 0 ?
            <Div className='bg-grey-400 py-4 my-2'>
            <Text className='text-center w-full'>
              Page is empty.
            </Text>
            <Text className='text-xs text-center w-full mt-4'>
              Go to Page 1
            </Text>
            </Div>
            :
            <></>
          }
          </Div>
          </>
          :
          <FlexRow className='w-full mx-auto'>
            <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
              You have no category data.
            </Text>
          </FlexRow>
        }

        <details>
          <summary className='list-none text-blue-100 hover:underline font-semibold cursor-pointer my-4'>
            FAQs | Category Commission
            <i className="fas fa-question text-sm ml-4 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
          </summary>

          <Div className='mt-4 mb-8'>
            <Text className='text-secondary-200 font-semibold text-left'>
              Category Commission:
            </Text>
            <Text className='text-secondary-200 text-left mt-2'>
              If an affiliate is considered to be categorized by its merchant, 
              category commission will be the commission rate to be considered for conversions, 
              earnings, commissions and disbursements.
            </Text>
            <Text className='text-secondary-200 font-semibold text-left mt-4'>
              Reminders:
            </Text>
            <Text className='text-secondary-200 text-left mt-2'>
              For non-categorized affiliates, 
              regular commission rate from individual products will be applied.
            </Text>
          </Div>
        </details>

        <div>
        <PaginationV2
          currentPage={currentPage}
          itemsPerPage={articlesPerPage}
          paginate={paginate}
          totalItems={categoryList.length}
        />
        </div>
        </> 
        :
        <></>
      }
    </Flex>
  );
};