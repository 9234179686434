// Configuration    
const COUNT_FORMATS =
[
  { // 0 - 999
    letter: '',
    limit: 1e3
  },
  { // 1,000 - 999,999
    letter: 'K',
    limit: 1e6
  },
  { // 1,000,000 - 999,999,999
    letter: 'M',
    limit: 1e9
  },
  { // 1,000,000,000 - 999,999,999,999
    letter: 'B',
    limit: 1e12
  },
  { // 1,000,000,000,000 - 999,999,999,999,999
    letter: 'T',
    limit: 1e15
  }
];
    
// Format Method:
export function formatCount(value:(string | number)) {
    let nvalue: number = (typeof value === 'string') ? parseInt(value) : value

    const format = COUNT_FORMATS.filter(f => (nvalue < f.limit));
    // console.log('format', format, value, nvalue)

    nvalue = (1000 * nvalue / format[0].limit);
    nvalue = Math.round(nvalue * 10) / 10;

    return (nvalue + format[0].letter);
}