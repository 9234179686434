import React, { FC, Fragment } from 'react';
import { batch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { AccountSettings } from './AccountSettings';
import { SecuritySettings } from './SecuritySettings';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { useSubscription } from '@hooks';
import { Div, H2, FlexRow } from '@core';
import { NavBarSuperAdmin } from '@components';
import { SuperAdminTabs } from '@constants';

export const AdminSettings: FC<any> = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const subscription = useSubscription();
  const [tab, setTab] = React.useState('details');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if(actionQuery === 'verify'){
      setTab('security')
    }
    if(user.user_info.role === ''){
      batch(async () => {
        await dispatch(usersActions.profileAdmin());
      });
    }
  });

  return (
    <Div className='h-full'>
      <NavBarSuperAdmin />

      <FlexRow
        className='h-full w-full text-primary-100 
      phone:flex-col tablet:flex-row mt-20'
      >
        <Div
          className='tablet:h-full phone:w-full phone:h-auto phone:pt-5 
          tablet:pl-10 tablet:w-72 tablet:pt-6'
        >
      
          <FlexRow className='w-full py-1 items-center'>
            <H2 className="font-poppins font-semibold phone:text-2xl tablet:text-2xl ml-4 mb-2 phone:mt-4 tabletWide:mt-2">Settings</H2>
            <div className='ml-auto mr-4 w-10 h-10 rounded-lg bg-grey-500 border-2 border-grey-400 flex items-center justify-center tablet:hidden' onClick={() => setIsOpen(!isOpen)}>
              <i className="fas fa-bars text-secondary-200" />
            </div>
          </FlexRow>

          <Transition appear as={Fragment} show={isOpen}>
            <Dialog
              as="div"
              className="fixed h-full mt-20 w-64 inset-0 z-10 overflow-hide tablet:hidden"
              onClose={() => 'noop'}
            >
              <div className="h-full text-center flex justify-start">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease duration-700 transform"
                  enterFrom="opacity-0 -translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition ease duration-1000 transform"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-full"
                >
                  <div
                    className="w-full overflow-y-scroll scrollbar-hide text-left z-20 py-5 items-center
                    align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
                  >
                    <div className='ml-auto mr-4 w-6 h-6 rounded-lg border-2 border-grey-400 bg-grey-500 flex items-center justify-center tablet:hidden mt-2' onClick={() => setIsOpen(!isOpen)}>
                      <i className="fas fa-times text-secondary-200" />
                    </div>
                    {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
                    {SuperAdminTabs[0].links.map((value:any) => (
                      <div
                        className={`pl-4 w-full py-2 tex-base font-medium hover:text-blue-100
                         ${
                          tab === value.tab
                            ? 'border-r-4 text-secondary-200 font-bold'
                            : 'text-primary-100'
                        }`}
                        key={value.name}
                        onClick={() => {setTab(value.tab)}}
                      >
                        <i className={`${value.icon} w-10`} />
                        <span className="font-poppins">{value.name}</span>
                      </div>
                    ))}
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>

          <Div
            className="overflow-x-scroll overflow-y-scroll scrollbar-hide phone:mt-2 tabletWide:mt-4 space-y-1 w-full phone:hidden tablet:flex tablet:pb-0 tablet:flex-col tablet:text-left"
          >
                  <div
                    className={`hover:bg-grey-400 rounded-lg text-base font-medium px-3 py-2 cursor-pointer phone:w-full
                ${
                  tab === 'details'
                    ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                    : 'phone:text-grey-700 surfaceDuo:text-secondary-100 hover:text-primary-100'
                }`}
                    onClick={() => setTab('details')}
                  >
                    <p className='phone:hidden surfaceDuo:block'>Account Info</p>
                    <i className='fas fa-user text-2xl phone:block surfaceDuo:hidden' />
                  </div>
                  <div
                    className={`hover:bg-grey-400 rounded-lg text-base font-medium px-3 py-2  cursor-pointer phone:w-full
                ${
                  tab === 'security'
                    ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                    : 'phone:text-grey-700 surfaceDuo:text-secondary-100 hover:text-primary-100 '
                }`}
                    onClick={() => setTab('security')}
                  >
                    <p className='phone:hidden surfaceDuo:block'>Security</p>
                    <i className='fas fa-key text-2xl phone:block surfaceDuo:hidden' />
                  </div>
          </Div>
            
        </Div>
        {tab === 'details' ? <AccountSettings user={user} /> : <></>}
        {tab === 'security' ? <SecuritySettings subscription={subscription} user={user} /> : <></>}
      </FlexRow>
    </Div>
  );
};