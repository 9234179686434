import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addProduct, addProductAdmin, getProducts, getProductsAdmin, deleteProduct, updateProduct, getPartnerAffiliates } from '@services';

interface PRODUCT {
  id?: number;
  name: string;
  description: string;
  link: string;
  category: string;
  sub_category: string;
  price: string;
  commission: string;
  productPic: any;
  type: string;
}

interface UPDATEPRODUCT {
  name: string;
  description: string;
  link: string;
  category: string;
  sub_category: string;
  price: string;
  commission: string;
  productPic: any;
  id: string;
  type: string;
}

interface STATE {
  loading: boolean;
  data: [];
}

export const productsInitialState: STATE = {
  loading: false,
  data: [],
};

const productList = createAsyncThunk('products/productList', async () => {
  const data: any = await getProducts();
  const products = Object.values(data.data.data);
  return {
    data: products,
  };
});

const productAffiliateList = createAsyncThunk('products/productAffiliateList', async () => {
  const data: any = await getPartnerAffiliates();
  const products = Object.values(data.data.data);
  return {
    data: products,
  };
});

const productListAdmin = createAsyncThunk('products/productListAdmin', async (id: number) => {
  const data: any = await getProductsAdmin(id);
  const products = Object.values(data.data.data);
  return {
    data: products,
  };
});

const newProduct = createAsyncThunk('products/newProduct', async (body: PRODUCT) => {
  const { data, status }: any = await addProduct(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]],
      error: true,
    };
  }
  if (status === 0) {
    return {
      status: 422,
      message: data.message,
      error: true,
      data,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const newProductAdmin = createAsyncThunk('products/newProduct', async (body: PRODUCT) => {
  const { data, status }: any = await addProductAdmin(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]],
      error: true,
    };
  }
  if (status === 0) {
    return {
      status: 422,
      message: data.message,
      error: true,
      data,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const UpdateProduct = createAsyncThunk('products/UpdateProduct', async (body: UPDATEPRODUCT) => {
  const { data, status }: any = await updateProduct(body);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 0) {
    return {
      status: 422,
      message: data.message,
      error: true,
      data,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const RemoveProduct = createAsyncThunk('products/RemoveProduct', async (productId: number) => {
  const { data, status }: any = await deleteProduct(productId);
  if (data.errors) {
    return {
      status: 422,
      userId: 0,
      message: data.errors[Object.keys(data.errors)[0]][0],
      error: true,
    };
  }
  if (status === 0) {
    return {
      status: 422,
      message: data.message,
      error: true,
      data,
    };
  }
  if (status === 200) {
    return {
      status: 200,
      message: data.message,
      error: false,
      data,
    };
  }
  return {
    status: 500,
    userId: 0,
    message: 'Something went wrong.',
    error: true,
  };
});

const { actions, reducer } = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {},
  extraReducers: {
    [productList.pending.type]: (state) => {
      state.loading = true;
    },
    [productList.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
    [productListAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [productListAdmin.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
    [productAffiliateList.pending.type]: (state) => {
      state.loading = true;
    },
    [productAffiliateList.fulfilled.type]: (state, { payload: { data } }: PayloadAction<STATE>) => {
      state.data = data;
      state.loading = false;
    },
  },
});

export const productsActions = {
  ...actions,
  productList,
  productAffiliateList,
  productListAdmin,
  newProduct,
  newProductAdmin,
  RemoveProduct,
  UpdateProduct,
};

export const productsReducer = reducer;