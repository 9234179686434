export const Using1 = [
  {
    id: 'socail',
    label: 'Social media marketing',
  },
  {
    id: 'digital',
    label: 'Digital Campaigns',
  },
  {
    id: 'sms',
    label: 'SMS notifications',
  },
  {
    id: 'branding',
    label: 'Branding',
  },
];

export const Using2 = [
  {
    id: 'campaigns',
    label: 'Offline Campaigns',
  },
  {
    id: 'influencer',
    label: 'Influencer marketing',
  },
  {
    id: 'communications',
    label: 'Internal communications',
  },
  {
    id: 'others',
    label: 'Other',
  },
];
