import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotifs, getNotifsAdmin, markAsRead } from "@src/services";

// interface Notification {
//     user_id: number,
//     category: string,
//     created_at: string,
//     mark_as_read: number|null,
//     product_name: string,
//     merchant_name: string,
//     affiliate_name: string,
//

interface NOTIFICATION {
    status: number;
    message: string;
    data: [
      {
        content: string;
        created_at: string;
        product_name: string;
        merchant_name: string;
        user_id: number;
        affiliate_name: string;
        affiliate_company_name: string;
        category: string;
        mark_as_read: string|null;
        affiliate_category: any;
      },
    ] | [];
}
  

interface STATE {
    loading: boolean,
    data: NOTIFICATION,
}

export const notifInitialState: STATE = {
    loading: false,
    data: {
        status: 0,
        message: 'default',
        data: []
    },
}

const notifData = createAsyncThunk('notifs/notification', async () => {
    const data: any = await getNotifs();
    return data;
});

const notifAdminData = createAsyncThunk('notifs/admin-notification', async (id: number) => {
    const data: any = await getNotifsAdmin(id);
    return data;
});

const markNotifRead = createAsyncThunk('notifs/mark-as-read', async (read: string) => {
    const data: any = await markAsRead(read);
    return data;
})

const {actions, reducer} = createSlice({
    name: 'notifs',
    initialState: notifInitialState,
    reducers: {},
    extraReducers: {
        [notifData.pending.type]: (state) => {
            state.loading = true
        },
        [notifData.fulfilled.type]: (state, {payload}) => {
            state.data = payload.data || []
        },
        [notifAdminData.pending.type]: (state) => {
            state.loading = true
        },
        [notifAdminData.fulfilled.type]: (state, {payload}) => {
            state.data = payload.data || []
        },
    }
})

export const notifsActions = {
    ...actions,
    notifData,
    notifAdminData,
    markNotifRead,
}

export const notifsReducer = reducer