import React, { FC } from 'react';
import { batch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, partnersActions } from '@redux';
import { Flex, Text, Div, FlexRow, Toast, ToastV4, Icons } from '@core';

interface CatalogItemProps {
  title: string;
  commision: any;
  remaining: any;
  category: string;
  id: string;
  image: string;
  status: any;
  link: string;
}

export const CatalogItemV2: FC<CatalogItemProps> = ({
  title,
  commision,
  remaining,
  id,
  image,
  category,
}) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const userId = query.get('id');
  const merchantId = query.get('merchant');

  const [isLoaded, setLoaded] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const [isToastOpenA, setToastOpenA] = React.useState(false);
  const [toastKindA, setToastKindA] = React.useState('success');
  const [toastTitleA, setToastTitleA] = React.useState('');
  const [toastDescA, setToastDescA] = React.useState('');

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2, setToastKind2] = React.useState('success');
  const [toastTitle2, setToastTitle2] = React.useState('');
  const [toastDesc2, setToastDesc2] = React.useState('');

  const [selected, setSelected] = React.useState(false);

  const Affiliate = () => {
    if(selected){
      setSelected(!selected);
    }else{
    setToastOpen2(true);
    setToastTitle2('Action');
    setToastDesc2(`Become affiliate of the product ${title}?`);
    setToastKind2('info');
    setSelected(!selected);
    }
  }

  React.useEffect(() => {
    if(isToastOpen2 === false){
      setSelected(false)
    }
  })

  const onSubmit = () => {
    setLoading(true);
    batch(async () => {
      const data: any = await dispatch(
        partnersActions.newPartner({
          user_id: `${userId}`,
          product_id: `${id}`,
          status: "0",
          role: "affiliate",
        }),
      );

      if (data?.payload?.status === 200 || data?.payload?.status === '200') {
        localStorage.setItem('applied', 'products')
        navigate(`/affiliate/onboard/setup?id=${userId}&merchant=${merchantId}&role=affiliate&applied=true`)
        setLoading(false);
        setToastOpen2(false);
        setToastTitleA('Successfully Added');
        setToastDescA(`Affiliate product added.`);
        setToastKindA('success');
        setToastOpenA(true);
      } else {
        setLoading(false);
        setToastOpen2(false);
        setToastTitleA('Denied');
        setToastDescA(data?.payload?.message || 'Something went wrong. Try again!');
        setToastKindA('error');
        setToastOpenA(true);
      }
    });
  };

  return (
    <>
    <ToastV4
          btnText='Cancel'
          description={toastDesc2}
          isLoaded={isLoading}
          isOpen={isToastOpen2}
          link=''
          onClick={onSubmit}
          onClick2={() => setToastOpen2(false)}
          onClose={() => setToastOpen2(false)}
          submitBtnText='Submit'
          title={toastTitle2}
          toastKind={toastKind2}
        />
    <Toast
        description={toastDescA}
        isOpen={isToastOpenA}
        onClose={() => setToastOpenA(false)}
        title={toastTitleA}
        toastKind={toastKindA}
      />

    <div
      className={`rounded-3xl w-72 justify-self-center bg-primary-100 shadow-lg cursor-pointer 
    border-4 ${selected ? 'border-orange-100' : 'border-white'} hover:border-4 hover:border-orange-100 flex flex-col justify-center overflow-hidden duration-500`}
      onClick={Affiliate}
    >
      <Flex>
        <Div className="bg-primary-300 h-52">
          <img
            alt="Pic"
            className={`w-full h-full ${title === 'Prosperna myChat' ? 'object-contain' : 'object-cover'}`}
            onError={() => setLoaded(true)}
            src={isLoaded ? Icons.xffiliate2 : image}
          />
        </Div>
        <Div className="px-5 pt-4 pb-2 h-36 flex flex-col justify-between">
          <FlexRow className='justify-between items-start'>
          <div>
          <Text className="text-base text-blue-100">{category}</Text>
          <Text className="font-bold h-full text-lg text-white">{title}</Text>
          </div>

          <div className='hidden'>
            <Text className="font-semibold text-white mr-auto mb-5">
              Earn <span className="text-2xl text-orange-100 font-bold">{commision}%</span>
            </Text>
            <Text className="hidden">{remaining}</Text>
            <span className='hidden'>{id}</span>
          </div>
          </FlexRow>

          <FlexRow className='justify-between items-start'>
          <Text className="font-semibold text-white mr-auto">
              Earn <span className="text-2xl text-orange-100 font-bold">{commision}%</span>
          </Text>
          <Text className="text-white">{remaining} Affiliates</Text>
          </FlexRow>
        </Div>
      </Flex>
    </div>
  </>
  );
};