import Pusher from "pusher-js";
import { notifsActions } from "@src/redux";

interface NOTIFICATION {
    message: {
        user_id: string,
    }
}


export function enableNotifications(user: any, dispatch: any) {
    // Pusher.logToConsole = true;
    const unused: any[] = [];

    unused.push(user)
    const pusher = new Pusher('c09765fec852fc2568e1', {
      cluster: 'ap1',
    });

    const channel = pusher.subscribe('xffiliate');

    channel.bind('subscription_succeeded', async (data: NOTIFICATION) => {
        if (data.message.user_id === user.user_info.id) {
            await dispatch(notifsActions.notifData());
        }
    });

    channel.bind('affiliate_application_status', (data: any) => {
        unused.push(data)
    });
}