import React, { FC, Fragment } from 'react';
import { batch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { AccountAdminDetails } from './AccountAdminDetails';
import { Security } from './Security';
import { InviteLinks } from './InviteLinks';
import { PaymentAdmin } from './PaymentAdmin';
import { TransactionsAdmin } from './TransactionsAdmin';
import { ManageCategoriesAdmin } from './ManageCategoriesAdmin';
import { AffiliateTaggingAdmin } from './AffiliateTaggingAdmin';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { ManagerTabs, EditorTabs } from '@constants';
import { useSubscription } from '@hooks';
import { Div, H2, FlexRow, Text } from '@core';
import { NavBarAdmin } from '@components';

export const SettingsAdmin: FC<any> = () => {
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const subscription = useSubscription();
  const [tab, setTab] = React.useState('details');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');
  const [isOpen, setIsOpen] = React.useState(false);
  const [menu, setMenu] = React.useState<any>(ManagerTabs);

  React.useEffect(() => {
    if(actionQuery === 'verify'){
      setTab('security')
    }else if(actionQuery === 'category'){
      setTab('manageRole')
    }else if(actionQuery === 'transaction'){
      setTab('paymentSettings')
    }else if(actionQuery === 'payment'){
      setTab('payment')
    }else if(actionQuery === 'info'){
      setTab('details')
      navigate(`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}/settings`)
    }
    if(user.user_info.role === ''){
      batch(async () => {
        await dispatch(usersActions.profileAdmin());
      });
    }
  });

  React.useEffect(() => {
    if(user.user_info.role === 'Manager'){
      setMenu(ManagerTabs)
    }else if (user.user_info.role === 'Editor'){
      setMenu(EditorTabs)
    }
  })

  return (
    <>
    <NavBarAdmin />
    <Div className='h-full pt-24'>

      {
        (tab === 'payment') ?
        <>
        <Div className='fixed phone:hidden tablet:block px-10 bg-white w-full'>
          <H2 className="my-4 font-poppins font-semibold phone:text-2xl tablet:text-2xl text-primary-100">Payment Center</H2>
          <Text>
              To set or change frequency, go to
                <span className='mx-2 text-blue-100 border-b-2 border-white hover:border-b-2 hover:border-blue-100 cursor-pointer' onClick={() => setTab('paymentSettings')}>
                    Transaction Activity
                </span>
          </Text>
          <hr className="text-grey-400 mt-5" />
        </Div>
        </>
        :
        <>
        </>
      }

      <FlexRow className={`h-full w-full text-primary-100 phone:flex-col tablet:flex-row ${tab === 'payment'? 'tablet:pt-28' : ''}`}>
        <Div
          className='tablet:h-full phone:w-full phone:h-auto tablet:py-5 tablet:pl-10 tablet:w-72 tablet:overflow-y-auto scrollbar-hide'
        >
      
          <FlexRow className='w-full py-1 items-center'>
            <H2 className="font-poppins font-semibold phone:text-2xl tablet:text-2xl ml-4 mb-2 phone:mt-4 tabletWide:mt-2">Settings</H2>
            <div className='ml-auto mr-4 w-10 h-10 rounded-lg bg-grey-500 border-2 border-grey-400 flex items-center justify-center tablet:hidden' onClick={() => setIsOpen(!isOpen)}>
              <i className="fas fa-bars text-secondary-200" />
            </div>
          </FlexRow>

          {
            tab === 'payment'?
            <span className='text-sm mx-4 text-blue-100 border-b-2 border-white hover:border-b-2 hover:border-blue-100 cursor-pointer phone:block tablet:hidden' onClick={() => setTab('paymentSettings')}>
                 Go to Payment Center Settings
                 <i className="fas fa-angle-right ml-2"/>
                 <i className="fas fa-angle-right"/>
            </span>
            :
            <>
            </>

          }

          <Transition appear as={Fragment} show={isOpen}>
            <Dialog
              as="div"
              className="fixed h-full pt-20 w-64 inset-0 z-10 overflow-hide tablet:hidden"
              onClose={() => 'noop'}
            >
              <div className="h-full text-center flex justify-start">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease duration-700 transform"
                  enterFrom="opacity-0 -translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition ease duration-1000 transform"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-full"
                >
                  <div
                    className="w-full h-full text-left z-20 py-5 items-center
                    align-middle transition-all transform bg-grey-600 shadow-xl flex flex-col"
                  >
                    <div className='ml-auto mr-4 w-6 h-6 rounded-lg border-2 border-grey-400 bg-grey-500 flex items-center justify-center tablet:hidden my-2' onClick={() => setIsOpen(!isOpen)}>
                      <i className="fas fa-times text-secondary-200" />
                    </div>
                    <div className='w-full h-full overflow-y-scroll scrollbar-hide'>
                    {/* <H1 className="text-primary-100 mb-7 mr-10">Xffiliate</H1> */}
                    {menu[0].links.map((value:any) => (
                      <div
                        className={`pl-4 w-full py-2 tex-base font-medium hover:text-blue-100
                         ${
                          tab === value.tab
                            ? 'border-r-4 text-secondary-200 font-bold'
                            : 'text-primary-100'
                        }`}
                        key={value.name}
                        onClick={() => {setTab(value.tab)}}
                      >
                        <i className={`${value.icon} w-10`} />
                        <span className="font-poppins">{value.name}</span>
                      </div>
                    ))}
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>

          <Div
            className="overflow-x-scroll overflow-y-scroll scrollbar-hide phone:mt-2 tabletWide:mt-4 space-y-1 w-full phone:hidden tablet:flex tablet:pb-0 tablet:flex-col tablet:text-left"
          >

            {
              user?.user_info?.role === 'Manager' ?
              <>
                    <div
                      className={`text-base font-medium px-3 py-2 hover:bg-primary-300 cursor-pointer phone:w-full text-primary-100
                  ${
                    tab === 'links'
                      ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300 surfaceDuo:rounded-md'
                      : 'rounded-md hover:text-primary-100'
                  }`}
                      onClick={() => setTab('links')}
                    >
                      <p className='phone:hidden surfaceDuo:block'>Invite Links</p>
                      <i className='fas fa-user-plus text-2xl phone:block surfaceDuo:hidden' />
                    </div>

                  <details>
                    <summary className='flex flex-row justify-between items-center list-none px-3 py-2 cursor-pointer font-medium rounded-md hover:bg-primary-300 text-primary-100'>
                      Commissions
                      <i className='fas fa-chevron-down text-grey-400' />
                    </summary>

                      <div
                        className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-pointer phone:w-full text-primary-100
                    ${
                      tab === 'payment'
                        ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                        : 'hover:text-primary-100'
                    }`}
                        onClick={() => setTab('payment')}
                      >
                        <p className='phone:hidden surfaceDuo:block'>Payment</p>
                        <i className='fas fa-coins text-2xl phone:block surfaceDuo:hidden' />
                      </div>
                      <div
                        className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-pointer phone:w- text-primary-100
                    ${
                      tab === 'paymentSettings'
                        ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                        : 'hover:text-primary-100'
                    }`}
                        onClick={() => setTab('paymentSettings')}
                      >
                        <p className='phone:hidden surfaceDuo:block'>Transaction</p>
                        <i className='fas fa-money-check text-2xl phone:block surfaceDuo:hidden' />
                      </div>

                  </details>

                  <details>
                    <summary className='flex flex-row justify-between items-center list-none px-3 py-2 cursor-pointer font-medium rounded-md hover:bg-primary-300 text-primary-100'>
                      Manage
                      <i className='fas fa-chevron-down text-grey-400' />
                    </summary>

                      <div
                        className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-not-allowed phone:w-full text-primary-100
                    ${
                      tab === 'adminRole'
                        ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                        : 'hover:text-primary-100'
                    }`}
                      >
                        <p className='phone:hidden surfaceDuo:block'>Admins</p>
                        <i className='fas fa-user-lock text-2xl phone:block surfaceDuo:hidden' />
                      </div>
                      <div
                        className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-pointer phone:w-full text-primary-100
                    ${
                      tab === 'tagging'
                        ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                        : 'hover:text-primary-100'
                    }`}
                        onClick={() => setTab('tagging')}
                      >
                        <p className='phone:hidden surfaceDuo:block'>Affiliates</p>
                        <i className='fas fa-users text-2xl phone:block surfaceDuo:hidden' />
                      </div>
                      <div
                        className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-pointer phone:w-full text-primary-100
                    ${
                      tab === 'manageRole'
                        ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                        : 'hover:text-primary-100'
                    }`}
                        onClick={() => setTab('manageRole')}
                      >
                        <p className='phone:hidden surfaceDuo:block'>Affiliate Categories</p>
                        <i className='fas fa-user-tag text-2xl phone:block surfaceDuo:hidden' />
                      </div>

                  </details>
              </>
              :
              <>
                <div
                  className={`text-base font-medium px-3 py-2 hover:bg-primary-300 cursor-pointer phone:w-full text-primary-100
              ${
                tab === 'links'
                  ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300 surfaceDuo:rounded-md'
                  : 'rounded-md hover:text-primary-100'
              }`}
                  onClick={() => setTab('links')}
                >
                  <p className='phone:hidden surfaceDuo:block'>Invite Links</p>
                  <i className='fas fa-user-plus text-2xl phone:block surfaceDuo:hidden' />
                </div>

                <details>
                  <summary className='flex flex-row justify-between items-center list-none px-3 py-2 cursor-pointer font-medium rounded-md hover:bg-primary-300 text-primary-100'>
                    Manage Users
                    <i className='fas fa-chevron-down text-grey-400' />
                  </summary>

                    <div
                      className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-not-allowed phone:w-full text-primary-100
                  ${
                    tab === 'adminRole'
                      ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                      : 'hover:text-primary-100'
                  }`}
                    >
                      <p className='phone:hidden surfaceDuo:block'>Admins</p>
                      <i className='fas fa-user-lock text-2xl phone:block surfaceDuo:hidden' />
                    </div>
                    <div
                      className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-pointer phone:w-full text-primary-100
                  ${
                    tab === 'tagging'
                      ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                      : 'hover:text-primary-100'
                  }`}
                      onClick={() => setTab('tagging')}
                    >
                      <p className='phone:hidden surfaceDuo:block'>Affiliates</p>
                      <i className='fas fa-users text-2xl phone:block surfaceDuo:hidden' />
                    </div>
                    <div
                      className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-pointer phone:w-full text-primary-100
                  ${
                    tab === 'manageRole'
                      ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                      : 'hover:text-primary-100'
                  }`}
                      onClick={() => setTab('manageRole')}
                    >
                      <p className='phone:hidden surfaceDuo:block'>Affiliate Categories</p>
                      <i className='fas fa-user-tag text-2xl phone:block surfaceDuo:hidden' />
                    </div>

                </details>
              </>
            }

            <details>
              <summary className='flex flex-row justify-between items-center list-none px-3 py-2 cursor-pointer font-medium rounded-md hover:bg-primary-300 text-primary-100'>
                Account Settings
                <i className='fas fa-chevron-down text-grey-400' />
              </summary>
              
                  <div
                    className={`hover:bg-grey-400 ml-4 mt-1 text-base font-medium px-3 py-2 cursor-pointer phone:w-full text-primary-100
                ${
                  tab === 'details'
                    ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                    : 'hover:text-primary-100'
                }`}
                    onClick={() => setTab('details')}
                  >
                    <p className='phone:hidden surfaceDuo:block'>Account Info</p>
                    <i className='fas fa-user text-2xl phone:block surfaceDuo:hidden' />
                  </div>
                  <div
                    className={`hover:bg-grey-400 ml-4 text-base font-medium px-3 py-2  cursor-pointer phone:w-full text-primary-100
                ${
                  tab === 'security'
                    ? 'phone:border-b-4 phone:border-primary-100 surfaceDuo:border-none surfaceDuo:bg-primary-300'
                    : 'hover:text-primary-100 '
                }`}
                    onClick={() => setTab('security')}
                  >
                    <p className='phone:hidden surfaceDuo:block'>Security</p>
                    <i className='fas fa-key text-2xl phone:block surfaceDuo:hidden' />
                  </div>

            </details>
            {/* <div
              className={`text-base font-medium px-5 py-4 cursor-pointer phone:w-full
          ${
            tab === 'billing'
              ? 'bg-primary-300 rounded-md'
              : 'text-secondary-100 rounded-md hover:text-blue-100'
          }`}
              onClick={() => setTab('billing')}
            >
              Billing
            </div> */}
          </Div>
          {/* <Text className="font-poppins font-semibold text-lg mt-8">Developer Settings</Text>
          <Div className="mt-4 space-y-1 w-full flex flex-col">
            <a
              className="text-base font-medium px-5 py-4 text-secondary-100 rounded-md
          hover:text-primary-100 hover:bg-primary-300"
              href="/"
            >
              API
            </a>
          </Div> */}
        </Div>

        <Div className='w-full h-full overflow-y-auto overflow-x-hidden'>
          {tab === 'details' ? <AccountAdminDetails user={user} /> : <></>}
          {tab === 'security' ? <Security subscription={subscription} user={user} /> : <></>}
          {tab === 'links' ? <InviteLinks subscription={subscription} user={user} /> : <></>}
          {tab === 'payment' ? <PaymentAdmin subscription={subscription} user={user} /> : <></>}
          {tab === 'paymentSettings' ? <TransactionsAdmin subscription={subscription} user={user} /> : <></>}
          {tab === 'manageRole' ? <ManageCategoriesAdmin /> : <></>}
          {tab === 'tagging' ? <AffiliateTaggingAdmin /> : <></>}
        </Div>
      </FlexRow>
    </Div>
    </>
  );
};