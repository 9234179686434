/* eslint-disable no-unused-vars */
import React, { FC } from "react";
import { batch, useSelector } from "react-redux";
import { NavBarSuperAdmin, NavBarAffiliate } from "@src/components";
import { Div, FlexCenter, FlexRow } from "@src/core";
import { NotificationCard } from "@src/components/NotifCard";
import { notifsActions, selectNotifs, selectUser, useAppDispatch } from "@src/redux";

export const NotificationsSA: FC<any> = () => {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser)
    const notifs = useSelector(selectNotifs);
    const userType = user.affiliate_company_info === null;
    
    React.useEffect(() => {
        batch(async () => {
          await dispatch(notifsActions.notifData());
        });
      }, []);

    const markRead = () => {
        batch(async () => {
            await dispatch(notifsActions.markNotifRead(''))
            await dispatch(notifsActions.notifData())
        })
    }

    return(
        <Div className="h-full">
            <NavBarSuperAdmin />
            <Div className="tablet:pt-24 phone:pt-12 w-full h-full items-start">
                <FlexRow className="flex items-start w-full h-full flex-col gap-5 phone:pt-24 tablet:pt-12">
                    <FlexRow className="flex justify-between tablet:w-1/2 phone:w-5/6">
                        <h4 className="tablet:text-2xl phone:text-xl font-semibold text-primary-100">All Notifications</h4>
                        <button 
                            className="px-2 rounded-lg tablet:text-base phone:text-sm font-medium text-blue-400 hover:bg-grey-600"
                            onClick={markRead}
                        >
                            Mark all as read
                        </button>
                    </FlexRow>
                    <FlexRow className="tablet:w-1/2 phone:w-5/6 flex flex-col h-full grow divide-y divide-grey-400 overflow-y-auto">
                        {
                            notifs.data !== [] ?
                                [...notifs.data].reverse().map((notif: any, i:number) => (
                                    <NotificationCard 
                                        affiliateCompany={`${notif.affiliate_name === 'none, none' ? notif.affiliate_company_name : ''}`}
                                        affiliateName={notif.affiliate_name}
                                        category={notif.category}
                                        createdAt={notif.created_at}
                                        key={i}
                                        merchant={userType}
                                        merchantName={notif.merchant_name}
                                        notifLink='/admin/dashboard' 
                                        notifLink2='/admin/dashboard' 
                                        productName={notif.product_name} 
                                        read={notif.mark_as_read}
                                        role={notif.affiliate_category}
                                    />
                                ))
                                :
                                <FlexCenter className="pb-10 h-max">
                                    <Div>
                                        <h1>You currently have no notifications.</h1>
                                    </Div>
                                </FlexCenter>
                        }
                    </FlexRow>
                </FlexRow>
            </Div>
        </Div>
    )
}