import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Text, Div, FlexRow, Icons } from '@core';

interface CatalogItemProps {
  title: string;
  commision: any;
  remaining: any;
  category: string;
  id: string;
  image: string;
  link: string;
  merchantSub: string;
  status: any;
}

export const CatalogItem: FC<CatalogItemProps> = ({
  title,
  commision,
  remaining,
  id,
  image,
  category,
}) => {

  const [isLoaded, setLoaded] = React.useState(false);

  return (
  <>
    <Link
      className="rounded-3xl w-80 justify-self-center bg-primary-100 shadow-lg cursor-pointer 
    border-4 border-white hover:border-4 hover:border-orange-100 duration-500 flex flex-col justify-center overflow-hidden"
      to={`/affiliate/catalog/details?productid=${id}`}
    >
    <div>
      <Flex>
        <Div className="bg-primary-300 h-52">
          <img
            alt="Pic"
            className={`w-full h-full ${title === 'Prosperna myChat' ? 'object-contain' : 'object-cover'}`}
            onError={() => setLoaded(true)}
            src={isLoaded ? Icons.xffiliate2 : image}
          />
        </Div>
        <Div className="px-5 pt-4 pb-2 h-40 flex flex-col">
          <Text className="text-base text-blue-100">{category}</Text>
          <Text className="font-bold h-full text-lg text-white">{title}</Text>
          <FlexRow className="h-full">
            <Text className="font-semibold text-white mr-auto ">
              Earn <span className="text-2xl text-orange-100 font-bold">{commision}%</span>
            </Text>
            <Text className="text-white">{remaining} Affiliates</Text>
          </FlexRow>
        </Div>
      </Flex>
    </div>
    </Link>
  </>
  );
};