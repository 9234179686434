import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate, Link } from 'react-router-dom';
import { FlexRow, Button, Div, Text, InputV2, Toast } from '@core';
import { MAIN } from '@config';
import { updateAffiliate, updateAffiliate2 } from '@services';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  data: any;
  selectedPartner: any;
}

export const UpdateAffilaiteModal: FC<ModalProps> = ({
  isOpen, onClose, data, selectedPartner
}) => {
  const navigate = useNavigate();
  const [link, setLink] = React.useState(data?.affiliate_link);
  const [commission, setCommission] = React.useState(data?.commission);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const onSubmit = async () => {
    setLoading(true);
    if (link === '' || commission === '') {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if(data?.affiliate_link !== link){
      const response = await updateAffiliate({
        id: data.affiliate_id,
        link,
        commission,
      });
      if (response.data.status === 1) {
        setToastTitle('SUCCESS');
        setToastDesc(response.data.message);
        setToastKind('success');
        setToastOpen(true);
        setTimeout(() => {
          setToastOpen(false);
          onClose();
          window.location.reload();
        }, 1000);
      } else {
        setToastTitle('ERROR');
        setToastDesc(response.data.message);
        setToastKind('error');
        setToastOpen(true);
        setTimeout(() => {
          setToastOpen(false);
        }, 1500);
      }
      setLoading(false);
    }else {
      const response = await updateAffiliate2({
        id: data.affiliate_id,
        commission,
      });
      if (response.data.status === 1) {
        setToastTitle('SUCCESS');
        setToastDesc(response.data.message);
        setToastKind('success');
        setToastOpen(true);
        setTimeout(() => {
          setToastOpen(false);
          onClose();
          window.location.reload();
        }, 1000);
      } else {
        setToastTitle('ERROR');
        setToastDesc(response.data.message);
        setToastKind('error');
        setToastOpen(true);
        setTimeout(() => {
          setToastOpen(false);
        }, 1500);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            navigate('/merchant/dashboard');
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">UPDATE AFFILIATE</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate('/merchant/dashboard');
                    }}
                  />
                </FlexRow>
                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide">
                  <Text className="font-bold text-lg text-grey-100 ml-2">Affiliate Details</Text>
                  <Text className="font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                    Enter the information below.
                  </Text>
                  <InputV2
                    containerClass="mt-2"
                    label="AFFILIATE LINK"
                    name="product-name"
                    onChange={setLink}
                    placeholder={`${MAIN}ChangeMe`}
                    value={link}
                  />
                  {
                    selectedPartner?.role_name?
                    <InputV2
                      containerClass='cursor-not-allowed'
                      disabled
                      label="AFFILIATE COMMISSION (%)"
                      name="affiliate-commission"
                      pattern="[0-9]*"
                      placeholder="Enter Commission"
                      type="number"
                      value={commission}
                    />
                    :
                    <InputV2
                      label="AFFILIATE COMMISSION (%)"
                      name="affiliate-commission"
                      onChange={setCommission}
                      pattern="[0-9]*"
                      placeholder="Enter Commission"
                      type="number"
                      value={commission}
                    />
                  }

                  <details>
                    <summary className='list-none text-blue-100 hover:underline font-semibold cursor-pointer mt-2 mb-4'>
                      FAQs | Commission
                      <i className="fas fa-question text-sm ml-4 px-1 rounded-full hover:bg-primary-100 hover:text-white border border-primary-100" />
                    </summary>

                    <Div className='mt-4 mb-8'>
                      <Text className='text-secondary-200 font-semibold text-left'>
                        Note:
                      </Text>
                      <Text className='text-secondary-200 text-left mt-2'>
                        For non-categorized affiliates, 
                        regular commission rate from individual products will be applied.
                      </Text>
                      <Text className='text-secondary-200 text-left mt-2'>
                        You can set or change affiliate's individual commission 
                        per product at this window.
                      </Text>
                      <Text className='text-secondary-200 font-semibold text-left mt-4'>
                        Category Commission:
                      </Text>
                      <Text className='text-secondary-200 text-left mt-2'>
                        If an affiliate is considered to be categorized by its merchant, 
                        category commission will be the commission rate to be considered for 
                        conversions, earnings, commissions and disbursements.
                      </Text>
                      <Text className='text-secondary-200 text-left mt-2'>
                        You can set or change your category commissions at the
                        <Link
                          className='mx-1 text-blue-100 hover:underline'
                          to='/merchant/settings?action=category'>
                          Settings
                        </Link>
                        page.
                      </Text>
                    </Div>
                  </details>

                  <FlexRow
                    className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      Be sure to complete all fields to update the product.
                    </Text>
                  </FlexRow>
                  <Div className="h-20 w-2" />
                </div>
                <hr />
                <div className=" flex  mx-8 my-5">
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    UPDATE AFFILIATE
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};