import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { FlexRow, Button, Div, Text, InputV2, Toast, Checkbox, Flex } from '@core';
import { API } from '@config';
import { getCookie } from '@hooks';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const CreateAdminModal: FC<ModalProps> = ({ isOpen, onClose }) => {

  const navigate = useNavigate();
  const token = getCookie('token');

  const newDate: any = new Date();
  const userNum: number = newDate.getTime();

  const [email, setEmail] = React.useState('');
  const username: any = `user${userNum}`;
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [readTransactions , setReadTrans] = React.useState(false);
  const [writeTransactions , setWriteTrans] = React.useState(false);

  const [readDisbursements , setReadDisbursements] = React.useState(false);
  const [writeDisburse , setWriteDisburse] = React.useState(false);

  const [readAccounts , setReadAccounts] = React.useState(false);
  const [writeAccounts , setWriteAccounts] = React.useState(false);

  const [readUsers , setReadUsers] = React.useState(false);
  const [updateUsers , setUpdateUsers] = React.useState(false);
  const [createUsers , setCreateUsers] = React.useState(false);
  const [removeUsers , setRemoveUsers] = React.useState(false);

  const [signUp, setSignUp] = React.useState(false);
  const [permissions, setPermissions] = React.useState(false);

  React.useEffect(() => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setFirstName('');
    setLastName('');
    setContactNumber('');
  }, [navigate, isOpen]);

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if(parseInt(contactNumber) < 0 || regExp.test(contactNumber)){
        setContactNumber('')
      }
  }, [contactNumber])

  const onSubmit = async () => {
    const isEmail = validator.isEmail(email);
    const isPassword = validator.isStrongPassword(password, {
      minLength: 6,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    const isPasswordSame = password === confirmPassword;

    if (
      email === '' &&
      username === '' &&
      password === '' &&
      confirmPassword === '' &&
      firstName === '' &&
      lastName === '' &&
      contactNumber === ''
    ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if (!isEmail) {
      setToastTitle('Invalid Email');
      setToastDesc('Please enter a valid email address.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if (!isPassword) {
      setToastTitle('Invalid Password');
      setToastDesc('Please enter a valid password.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else if (!isPasswordSame) {
      setToastTitle('Password not match');
      setToastDesc('Please enter your password.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
        setLoading(true);
        fetch(`${API}/create-superadmin`,{
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              email,
              username,
              password,
              password_confirmation: confirmPassword,
              lastname: lastName,
              firstname: firstName,
              contact: contactNumber
            })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            if (data?.status === 1) {
              setSignUp(true)
              fetch(`${API}/create-superadmin-permissions`,{
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: data.user_info.id,
                    user_create: createUsers? 1 : 0,
                    user_view: readUsers? 1 : 0,
                    user_archive:removeUsers? 1 : 0,
                    user_update: updateUsers? 1 : 0,
                    transaction_view: readTransactions? 1 : 0,
                    transaction_write: writeTransactions? 1 : 0,
                    disbursement_view: readDisbursements? 1 : 0,
                    disbursement_write: writeDisburse? 1 : 0,
                    account_view: readAccounts? 1 : 0,
                    account_write: writeAccounts? 1 : 0,
                     permission_write: 0
                  })
                })
                .then(res => res.json())
                .then(info => {
                  console.log(info)
                  if (info?.status === 1) {
                    setPermissions(true)
                    setToastTitle('New Admin created');
                    setToastDesc(`${firstName} is now an admin.`);
                    setToastKind('success');
                    setToastOpen(true);
                    setLoading(false);
                    setTimeout(() => {
                      navigate(`/admin/manage_users?action=updated`)
                    }, 2000)
                  } else {
                    setToastTitle('Invalid Input');
                    setToastDesc(info?.message || 'Something went wrong. Try again!');
                    setToastKind('error');
                    setToastOpen(true);
                    setLoading(false);
                    setTimeout(() => {
                      setToastOpen(false);
                    }, 2000)
                  }
                })
            } else {
              setToastTitle('Invalid Input');
              setToastDesc(data?.message || 'Something went wrong. Try again!');
              setToastKind('error');
              setToastOpen(true);
              setLoading(false);
              setTimeout(() => {
                setToastOpen(false);
              }, 2000)
            }
          })
    }
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            if(window.location.pathname === `/merchant/dashboard`){
              navigate('/merchant/dashboard');
            }else if(window.location.pathname === `/merchant/settings`){
              navigate('/merchant/settings')
            }
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">CREATE ADMIN USER</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      if(window.location.pathname === `/merchant/dashboard`){
                        navigate('/merchant/dashboard');
                      }else if(window.location.pathname === `/merchant/settings`){
                        navigate('/merchant/settings')
                      }
                    }}
                  />
                </FlexRow>
                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll">
                  <Text className="font-roboto font-bold text-lg text-orange-100 ml-2">
                    Admin User Details
                  </Text>
                  <Text className="font-roboto font-normal text-base text-blue-100 ml-2 mt-3 mb-5">
                    Enter the information below
                  </Text>
                  <InputV2
                    label="FIRST NAME"
                    name="first-name"
                    onChange={setFirstName}
                    placeholder="Enter First Name"
                    value={firstName}
                  />
                  <InputV2
                    label="LAST NAME"
                    name="last-name"
                    onChange={setLastName}
                    placeholder="Enter Last Name"
                    value={lastName}
                  />
                  <InputV2
                    label="CONTACT NUMBER"
                    maxlength={11}
                    name="contact-number"
                    onChange={setContactNumber}
                    pattern="[0-9]*"
                    placeholder="Enter Contact Number"
                    type="text"
                    value={contactNumber}
                  />
                  <InputV2
                    label="EMAIL"
                    name="email"
                    onChange={setEmail}
                    placeholder="Enter Email"
                    type="email"
                    value={email}
                  />
                  <InputV2
                    label="PASSWORD"
                    name="password"
                    onChange={setPassword}
                    placeholder="Enter Password"
                    type="password"
                    value={password}
                  />
                  <Div className="flex flex-col  ml-10  mb-5 font-poppins text-base">
                    <ul className="list-disc">
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                        }
                      >
                        <Text className=" mb-1">At least 6 characters</Text>
                      </li>
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                        }
                      >
                        <Text className=" mb-1">At least 1 capital letter</Text>
                      </li>
                    </ul>
                    <ul className="list-disc">
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                        }
                      >
                        <Text className=" mb-1">At least 1 number</Text>
                      </li>
                      <li
                        className={
                          password.length === 0
                            ? 'text-grey-100'
                            : `${
                                /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                                  ? 'text-green-100'
                                  : 'text-red-100'
                              }`
                        }
                      >
                        <Text className="mb-1">At least 1 special character</Text>
                      </li>
                    </ul>
                  </Div>
                  <InputV2
                    label="CONFIRM PASSWORD"
                    name="confirm-password"
                    onChange={setConfirmPassword}
                    placeholder="Confirm Password"
                    type="password"
                    value={confirmPassword}
                  />

                  <Div className='px-5 py-4 border border-secondary-100 rounded-lg mb-4'>
                    <Text className="font-poppins font-bold laptop:text-sm phone:text-xs text-secondary-100 mb-1">
                      PERMISSIONS & CONTROLS
                    </Text>

                    <details>
                      <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100 hover:underline'>
                        Users Management
                      </summary>
                      <Flex className='shadow px-2'>
                        <Checkbox
                          checked={readUsers}
                          className='tabletWide:text-sm text-secondary-200'
                          containerClass="my-2"
                          id='user_view'
                          label='View Users'
                          onClick={() => setReadUsers(!readUsers)}
                        />
                        <Checkbox
                          checked={createUsers}
                          className='tabletWide:text-sm text-secondary-200'
                          containerClass="my-2"
                          id='user_create'
                          label='Create Users'
                          onClick={() => setCreateUsers(!createUsers)}
                        />
                        <Checkbox
                          checked={updateUsers}
                          className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                          containerClass="my-2"
                          id='user_update'
                          label='Update Users'
                          onClick={() => setUpdateUsers(false)}
                        />
                        <Checkbox
                          checked={removeUsers}
                          className='tabletWide:text-sm text-secondary-200'
                          containerClass="my-2"
                          id='user_archive'
                          label='Remove Users'
                          onClick={() => setRemoveUsers(!removeUsers)}
                        />
                      </Flex>
                    </details>

                    <details>
                      <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100 hover:underline'>
                         Accounts Dashboard
                      </summary>
                      <Flex className='shadow px-2'>
                        <Checkbox
                          checked={readAccounts}
                          className='tabletWide:text-sm text-secondary-200'
                          containerClass="my-2"
                          id='account_view'
                          label='Read-only Accounts'
                          onClick={() => setReadAccounts(!readAccounts)}
                        />
                        <Checkbox
                          checked={writeAccounts}
                          className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                          containerClass="my-2"
                          id='account_write'
                          label='Update Accounts'
                          onClick={() => setWriteAccounts(false)}
                        />
                      </Flex>
                    </details>

                    <details>
                      <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100 hover:underline'>
                         Transactions Access
                      </summary>
                      <Flex className='shadow px-2'>
                        <Checkbox
                          checked={readTransactions}
                          className='tabletWide:text-sm text-secondary-200'
                          containerClass="my-2"
                          id='transaction_view'
                          label='Read-only Transactions'
                          onClick={() => setReadTrans(!readTransactions)}
                        />
                        <Checkbox
                          checked={writeTransactions}
                          className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                          containerClass="my-2"
                          id='transaction_view'
                          label='Update Transactions'
                          onClick={() => setWriteTrans(false)}
                        />
                      </Flex>
                    </details>

                    <details>
                      <summary className='flex flex-row justify-start items-center list-none py-2 cursor-pointer font-medium text-blue-100 hover:underline'>
                        Disbursements Applications
                      </summary>
                      <Flex className='shadow px-2'>
                        <Checkbox
                          checked={readDisbursements}
                          className='tabletWide:text-sm text-secondary-200'
                          containerClass="my-2"
                          id='disbursement_view'
                          label='Read-only Disbursements'
                          onClick={() => setReadDisbursements(!readDisbursements)}
                        />
                        <Checkbox
                          checked={writeDisburse}
                          className='tabletWide:text-sm text-grey-400 cursor-not-allowed'
                          containerClass="my-2"
                          id='disbursement_view'
                          label='Update Disbursements'
                          onClick={() => setWriteDisburse(false)}
                        />
                      </Flex>
                    </details>

                  </Div>
                  
                  <FlexRow
                    className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      Be sure to complete all fields to create a partner.
                    </Text>
                  </FlexRow>

                  <Div className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg mt-4">
                    {
                      (
                            email === '' ||
                            username === '' ||
                            password === '' ||
                            confirmPassword === '' ||
                            firstName === '' ||
                            lastName === '' ||
                            contactNumber === ''
                      ) ?
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fad fa-spinner-third animate-spin mr-5" />
                        Reading input data...
                      </Text>
                      :
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        Ready to create
                      </Text>
                    }
                    {
                      signUp?
                      <>
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        Registration completed.
                      </Text>
                      {
                        signUp && !permissions ?
                        <Text className='my-2 text-left text-secondary-100'>
                          <i className="fad fa-spinner-third animate-spin mr-5" />
                          Reading permissions data...
                        </Text>
                        :
                        <></>
                      }
                      </>
                      :
                      <></>
                    }
                    {
                      permissions?
                      <Text className='my-2 text-left text-secondary-100'>
                        <i className="fas fa-check-circle text-green-100 text-xl mr-5" />
                        Data records completed.
                      </Text>
                      :
                      <></>
                    }
                    
                  </Div>

                  <Div className="h-20 w-2" />
                </div>
                <hr />
                <div className=" flex  mx-8 my-5">
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    CREATE
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};