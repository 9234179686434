import React, { FC } from 'react';

interface H2Props {
  className?: string;
  props?: any;
}

export const H2: FC<H2Props> = ({ className, children, ...props }) => (
  <h2
    className={`font-roboto font-medium phone:text-xl 
    laptop:text-2xl desktop:text-3xl ${className}`}
    {...props}
  >
    {children}
  </h2>
);
