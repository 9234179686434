import React, { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import { FlexRow, Div, Flex, FlexCenter, Text, ButtonIcon, FilterDropdown, Select, ToastCustom, Icons } from '@core';
import { Overview, AssetModal, AffiliateMarketingAssets } from '@components';
import { useWindowSize } from '@hooks';
import { FilterReport } from '@constants';

interface ProductProps {
  selectedPartner?: any;
  setPartner: any;
  graphClicks: any;
  graphConversion: any;
  graphEarnings: any;
}

interface LineGraphInputs {
  titles: string[];
  data: any[];
}

const AffLineGraph: FC<LineGraphInputs> = ({ data }) => {

  const dimensions = useWindowSize();
  const colors = ['#3173AF', '#FF733F', '#FBDD8C', '#F37777', '#63C9A8'];

  const commonProperties = {
    height: 400,
    margin: { top: 50, right: 50, bottom: 75, left: 50 },
  };

  return (
    <ResponsiveLine
      areaOpacity={0.1}
      axisBottom={{
        tickValues: dimensions.width > 1000 ? 12 : 5,
        format: '%b %d',
        tickPadding: 5,
        tickRotation: 67,
      }}
      axisLeft={{
        tickValues: 5,
        tickPadding: 10,
        format: (text) => Number(text),
      }}
      colors={colors}
      {...commonProperties}
      curve="monotoneX"
      data={data}
      enableArea
      enableGridX={false}
      enableGridY={false}
      enableSlices="x"
      legends={[
          {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateY: -40,
            itemDirection: 'left-to-right',
            itemWidth: 140,
            itemHeight: 20,
            symbolSize: 12,
            symbolShape: 'square',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
            toggleSerie: false,
          },
        ]}
      sliceTooltip={
        // eslint-disable-next-line react/no-unstable-nested-components
        ({ slice }) => (
          <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl">
            {slice.points.map((point, i) => (
              <Div key={point.id}>
                <FlexCenter className="text-xs font-medium">
                  {i == 0 ? moment(point.data.xFormatted).format('MMMM Do YYYY') : ''}
                </FlexCenter>
                <Div className="flex flex-row pt-1">
                  <Flex className="text-grey-700 text-sm">{point.serieId}</Flex>
                  <Flex className="text-right text-sm font-medium">
                    {point.data.y.toLocaleString()}
                  </Flex>
                </Div>
              </Div>
            ))}
          </Div>
        )
      }
      xFormat="time:%m/%d/%Y"
      xScale={{
        type: 'time',
        format: '%m/%d/%Y',
      }}
      yFormat={(value) =>
        `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP' }).format(
          Number(value),
        )}`
      }
      yScale={{
        type: 'linear',
        stacked: true,
        reverse: false,
      }}
    />
  );
};


export const AffiliatesInfo: FC<ProductProps> = ({
  selectedPartner, setPartner, graphClicks, graphConversion, graphEarnings
}) => {

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const asset = query.get('asset');

  const [assetsView, setAssets] = React.useState(false);
  const [assetsOpen, setAssetsOpen] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const [loadedClicks, setLoadedClicks] = React.useState(false);
  const [loadedConversion, setLoadedConversion] = React.useState(false);
  const [loadedEarnings, setLoadedEarnings] = React.useState(false);

  const [prodgraphClicks, setProdGraphClicks] = React.useState<any>(graphClicks);
  const [prodgraphConversion, setProdGraphConversion] = React.useState<any>(graphConversion);
  const [prodgraphEarnings, setProdGraphEarnings] = React.useState<any>(graphEarnings);

  const [selectedChart, setSelectedChart] = React.useState<any>('');

  const [isToastOpen, setToastOpen] = React.useState(false);

  const date: any = new Date();
  const Year: any = date.getFullYear();
  const zero: any = '0';
  const month: any = (1 + date.getMonth()).toString();
  const Month: any = month.length > 1 ? month : zero.concat(month);
  const day: any = date.getDate().toString();
  const Day = day.length > 1 ? day : zero.concat(day);
  const DateToday: any = Year.toString().concat('-', Month, '-', Day);

  const [displayDate, setDisplayDate] = React.useState(DateToday);
  const [report, setReport] = React.useState({
    label: DateToday,
    value: '0',
  });

  React.useEffect(() => {
    if(asset === 'create'){
      setAssets(true)
      navigate('/affiliate/dashboard')
    }
  }, [asset])

  React.useEffect(() => {
    if(parseFloat(report.value) > 0){
      const result = new Date(DateToday);
      const days = parseFloat(report.value);
      const timestamp = result.setDate(result.getDate() - days)
      const fixedDate = new Date(timestamp)
      const newDay = fixedDate.getDate();
      const newMonth = 1+fixedDate.getMonth();
      const newYear = fixedDate.getFullYear();

      setDisplayDate(`${newYear}-${newMonth > 9 ? newMonth : `0${newMonth}`}-${newDay > 9 ? newDay : `0${newDay}`}`)
      setSelectedChart('Clicks')
    }else{
      const dateA: any = new Date();
      const YearA: any = dateA.getFullYear();
      const zeroA: any = '0';
      const monthA: any = (1 + dateA.getMonth()).toString();
      const MonthA: any = monthA.length > 1 ? monthA : zeroA.concat(monthA);
      const dayA: any = dateA.getDate().toString();
      const DayA = dayA.length > 1 ? dayA : zeroA.concat(dayA);
      const DateTodayA: any = YearA.toString().concat('-', MonthA, '-', DayA);

      setDisplayDate(DateTodayA)
      setSelectedChart('')
    }
  }, [report])

  React.useEffect(() => {
      const filterClicks: any = [];
      graphClicks.map((item:any) => {
        if(item.id === selectedPartner.name){
          filterClicks.push(item)
          setProdGraphClicks(filterClicks)
          if(item.data.length > 0){
            setLoadedClicks(true)  
          }else{
            setLoadedClicks(false)
          }
        }
        return 1;
      })
      const filterConversion: any = [];
      graphConversion.map((item:any) => {
        if(item.id === selectedPartner.name){
          filterConversion.push(item)
          setProdGraphConversion(filterConversion)
          if(item.data.length > 0){
            setLoadedConversion(true)  
          }else{
            setLoadedConversion(false)
          }
        }
        return 1;
      })
      const filterEarnings: any = [];
      graphEarnings.map((item:any) => {
        if(item.id === selectedPartner.name){
          filterEarnings.push(item)
          setProdGraphEarnings(filterEarnings)
          if(item.data.length > 0){
            setLoadedEarnings(true)  
          }else{
            setLoadedEarnings(false)
          }
        }
        return 1;
      })
  }, [selectedPartner])

  if (selectedPartner) {
    return (
      <>
      <ToastCustom
            isOpen={isToastOpen}
            onClose={() => setToastOpen(false)}
      >
        <Div className='w-full h-full'>
          <img
            alt="images"
            className="object-contain h-full w-full rounded-3xl"
            onError={() => setImageLoaded(true)}
            src={imageLoaded ? Icons.xffiliate2 : selectedPartner?.image}
          />
        </Div>
      </ToastCustom>
      <AssetModal
        isOpen={assetsOpen}
        onClose={() => setAssetsOpen(false)}
        selectedProd={selectedPartner}
      />
      {
        assetsView?
        <AffiliateMarketingAssets
          selectedProd={selectedPartner}
          setAssets={setAssets}
          setAssetsOpen={setAssetsOpen}
        />
        :
            <>
            <div className='py-14 surfaceDuo:px-5 tablet:px-10 w-full overflow-y-scroll'>
              <FlexRow className="w-full phone:flex-col laptop:px-20">
                 <button
                  className="text-grey-100 hover:text-grey-200 phone:hidden tabletWide:block mr-auto mb-8"
                  onClick={() => setPartner('')}
                  type="button"
                >
                  <Text className="items-center flex laptop:text-lg phone:base mr-3">
                    <i className="fas fa-angle-left laptop:text-4xl phone:xl mr-3" /> Back to overview
                  </Text>
                </button> 
                <Text className="font-poppins font-bold text-2xl text-primary-100 mr-auto">OVERVIEW</Text>
              </FlexRow>
              <FlexRow
                className="mt-5 laptop:flex-row phone:flex-col laptop:space-x-5 phone:space-y-5 
                  laptop:space-y-0 phone:space-x-0 laptop:px-20"
              >
                <Overview
                  icon="hand-holding-usd"
                  label="EARNINGS"
                  value={
                    selectedPartner?.earnings
                      ? <>&#8369; {selectedPartner?.earnings.toFixed(2).toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
                      : `0.00`
                  }
                />
                <Overview icon="mouse-pointer" label="CLICKS" value={selectedPartner?.total_cliked} />
                <Overview icon="repeat" label="CONVERSIONS" value={selectedPartner?.conversion || 0} />
              </FlexRow>

              <Text className="font-poppins font-bold text-2xl text-primary-100 mt-12 laptop:px-20">
                PRODUCT DETAILS
              </Text>
              <FlexRow className="mt-5 w-full tablet:flex-row phone:flex-col laptop:px-20">
                <div
                  className="tablet:w-400px h-400px phone:w-full rounded-3xl"
                  onClick={() => setToastOpen(true)}
                >
                  <img
                    alt="images"
                    className="h-full w-full object-contain cursor-pointer"
                    onError={() => setImageLoaded(true)}
                    src={imageLoaded ? Icons.xffiliate2 : selectedPartner?.image}
                    title='Click to view full image'
                  />
                </div>
                <Div
                  className="flex flex-col phone:mt-10 laptop:w-700px phone:w-full phone:ml-0 
        tablet:ml-10 tablet:mt-0"
                >
                  <FlexRow className='phone:flex-col tablet:flex-row'>
                    <Text
                      className="laptop:text-lg phone:text-base font-medium bg-blue-300 
                        rounded-lg text-center text-blue-100 px-4"
                    >
                      {selectedPartner?.category}
                    </Text>
                    <Text
                      className="phone:text-base font-medium 
                        rounded-lg text-center text-secondary-200 px-2 mt-2 tablet:mt-0"
                    >
                      {selectedPartner?.sub_category && selectedPartner?.sub_category !== "null" && selectedPartner?.category !== 'Others' ? selectedPartner?.sub_category : ''}
                    </Text>
                  </FlexRow>
                  <Text
                    className="laptop:text-3xl phone:text-xl font-semibold
                  rounded-lg text-primary-100 mt-3"
                  >
                    {selectedPartner?.name}
                  </Text>
                  <Text
                    className="laptop:text-base phone:text-sm font-normal
                  rounded-lg text-secondary-100 hidden"
                  >
                    SKU: {selectedPartner?.productCode}
                  </Text>
                  <div
                    className='font-robot font-regular text-secondary-200 laptop:text-base phone:text-sm hover:underline cursor-pointer hidden'
                    onClick={() => setToastOpen(true)}>
                    View full image size
                  </div>
                  <a
                    className="laptop:text-base phone:text-sm font-normal
                          rounded-lg text-secondary-100 hover:underline hidden"
                    href={`/full_image?action=${selectedPartner?.id}`}
                    rel="noopener noreferrer" 
                    target='_blank'
                  >
                    View full image
                  </a>
                  <Text className="text-orange-100 laptop:text-2xl phone:text-lg font-semibold mt-4">
                    PHP{' '}
                    {parseFloat(selectedPartner?.price)
                      ?.toFixed(2)
                      ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Text>
                  <Text
                    className="laptop:text-lg phone:text-base mt-5 mb-2 font-semibold
                  rounded-lg text-secondary-100"
                  >
                    Description
                  </Text>
                  <Text
                    className="laptop:text-base phone:text-sm
                  rounded-lg text-secondary-100"
                  >
                    {selectedPartner?.description}
                  </Text>
                  <Text
                    className="laptop:text-xl phone:text-base font-semibold
                  rounded-lg text-secondary-100 mt-4"
                  >
                    <span className="laptop:text-3xl phone:text-xl mx-1 text-orange-100 font-bold">
                      {selectedPartner?.category_commission === '0' ? selectedPartner?.commission : selectedPartner?.category_commission}%
                    </span>
                    commission
                  </Text>
                  <FlexRow className="mt-7 laptop:flex-row phone:flex-col">
                    <a
                      className="phone:w-full laptop:w-56 laptop:mr-2 phone:mr-auto my-1"
                      href={selectedPartner?.original_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <ButtonIcon
                        className="bg-grey-800 hover:bg-primary-300 phone:text-primary-500 pt-3 pb-3 phone:w-full laptop:w-56 justify-center my-1"
                        icon="fas fa-external-link"
                      >
                        Product website
                      </ButtonIcon>
                    </a>
                    <Link
                      className="phone:w-full tablet:w-56 laptop:mr-2 phone:mr-auto hidden"
                      to={`/affiliate/dashboard?action=qrcode&referral_link=${selectedPartner?.affiliate_link}&role=affiliate&product=${selectedPartner?.name}`}
                    >
                      <ButtonIcon
                        className="pt-3 pb-3 phone:w-full tablet:w-56 justify-center phone:ml-0 tabet:ml-2"
                        icon="fas fa-link"
                      >
                        Share My Link
                      </ButtonIcon>
                    </Link>
                    <ButtonIcon
                      className="pt-3 pb-3 phone:w-full laptop:w-56 laptop:mr-2 justify-center phone:mr-0 my-1"
                      icon="fas fa-link"
                      onClick={() => setAssetsOpen(true)}
                    >
                      Share My Link
                    </ButtonIcon>
                    <ButtonIcon
                      className="pt-3 pb-3 phone:w-full laptop:hidden desktop:w-56 desktop:block justify-center phone:mr-0 my-1"
                      icon="fas fa-file"
                      onClick={() => setAssets(true)}
                    >
                      Marketing Assets
                    </ButtonIcon>
                    <div className='text-white bg-primary-100 hover:bg-opacity-80 focus:ring-2 focus:ring-secondary-300 font-poppins font-regular rounded-lg text-lg py-3 hidden laptop:block laptop:px-3 desktop:hidden text-center laptop:text-lg cursor-pointer' onClick={() => setAssets(true)} title='Open Marketing Asset'>
                      <i className='fas fa-file mx-2' />
                    </div>
                  </FlexRow>

                </Div>
              </FlexRow>

              <Text className="font-poppins font-bold text-2xl text-primary-100 mt-12 mb-8 laptop:px-20">
                PRODUCT PERFORMANCE
              </Text>

              <FlexRow className='phone:flex-col phone:items-start tabletWide:flex-row tabletWide:items-center tabletWide:justify-between laptop:px-20'>
                <FlexRow className='mb-4'>
                  <Text className='text-primary-100 font-semibold mr-4 phone:ml-4 tabletWide:ml-0'>
                    Chart:
                  </Text>
                  <Select
                    className=''
                    label=""
                    name="selectedMethod"
                    onChange={setSelectedChart}
                    options={['Clicks', 'Conversions', 'Earnings', '-- SHOW ALL --']}
                    placeholder="Choose..."
                    value={selectedChart}
                  />
                </FlexRow>

                <FlexRow className='tabletWide:ml-auto mb-4 hidden'>
                 {report.value === '0' ? (
                   <FilterDropdown
                     classNameBody='border-4 border-primary-100 rounded-lg z-10'
                     color="white"
                     current={report?.label || ''}
                     items={FilterReport}
                     onSelect={setReport}
                     placeholder="Select a Range"
                   />
                 ) : (
                   <>
                     <div
                       className={`inline-flex items-center justify-center px-5 border-0
                py-3 font-poppins text-base font-regular text-primary-100
                hover:text-red-100 cursor-pointer `}
                       onClick={() => setReport({
                          label: DateToday,
                          value: '0',
                       })}
                     >
                       Reset
                       <i className="fas fa-filter text-2xl ml-1" />
                     </div>
                   </>
                 )}
                 <input className='mx-1 py-5 px-2 border border-primary-100 text-primary-100 rounded-lg cursor-pointer' readOnly type='date' value={displayDate} />
                 <input className='mx-1 py-5 px-2 border border-primary-100 text-primary-100 rounded-lg cursor-pointer' readOnly type='date' value={DateToday} />
                 </FlexRow>
              </FlexRow>

              <Text className='text-secondary-200 my-4 laptop:px-20'>
                {selectedChart === '-- SHOW ALL --' ? 'All Charts' : selectedChart}
              </Text>

              <Div className='w-full h-72 laptop:px-20'>
              {
                loadedClicks && (selectedChart === 'Clicks' || selectedChart === '') ?
                <AffLineGraph
                  data={prodgraphClicks}
                  titles={[]}
                />
                :
                <></>
              }
              {
                loadedConversion && selectedChart === 'Conversions' ?
                <AffLineGraph
                  data={prodgraphConversion}
                  titles={[]}
                />
                :
                <></>
              }
              {
                loadedEarnings && selectedChart === 'Earnings' ?
                <AffLineGraph
                  data={prodgraphEarnings}
                  titles={[]}
                />
                :
                <></>
              }
              {
                !loadedClicks && (selectedChart === 'Clicks' || selectedChart === '') ?
                <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
                  <Text className='text-center text-xs surfaceDuo:text-sm tabletWide:text-base'>
                    No data recorded at this time.
                  </Text>
                  <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                    <Text>10 -</Text>
                    <Text>8 -</Text>
                    <Text>6 -</Text>
                    <Text>4 -</Text>
                    <Text>2 -</Text>
                    <Text>0 -</Text>
                  </Flex>
                </FlexRow>
                :
                <></>
              }
              {
                !loadedConversion && selectedChart === 'Conversions' ?
                <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
                  <Text className='text-center text-xs surfaceDuo:text-sm tabletWide:text-base'>
                    No data recorded at this time.
                  </Text>
                  <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                    <Text>10 -</Text>
                    <Text>8 -</Text>
                    <Text>6 -</Text>
                    <Text>4 -</Text>
                    <Text>2 -</Text>
                    <Text>0 -</Text>
                  </Flex>
                </FlexRow>
                :
                <></>
              }
              {
                !loadedEarnings && selectedChart === 'Earnings' ?
                <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative'>
                  <Text className='text-center text-xs surfaceDuo:text-sm tabletWide:text-base'>
                    No data recorded at this time.
                  </Text>
                  <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                    <Text>10 -</Text>
                    <Text>8 -</Text>
                    <Text>6 -</Text>
                    <Text>4 -</Text>
                    <Text>2 -</Text>
                    <Text>0 -</Text>
                  </Flex>
                </FlexRow>
                :
                <></>
              }

              {
                selectedChart === '' ?
                <FlexRow className='h-72 justify-center items-center mx-8 my-4 rounded border-l-2 border-b-2 border-grey-400 bg-grey-500 relative hidden'>
                  <Text className='text-center text-xs surfaceDuo:text-sm tabletWide:text-base'>
                    No chart is selected.
                  </Text>
                  <Flex className='absolute -left-4 justify-around text-secondary-200 text-sm'>
                    <Text>10 -</Text>
                    <Text>8 -</Text>
                    <Text>6 -</Text>
                    <Text>4 -</Text>
                    <Text>2 -</Text>
                    <Text>0 -</Text>
                  </Flex>
                </FlexRow>
                :
                <></>
              }

              {
                selectedChart === '-- SHOW ALL --' ?
                <AffLineGraph
                  data={[
                      {
                        id: 'Clicks',
                        data: prodgraphClicks[0].data
                      },
                      {
                        id: 'Conversions',
                        data: prodgraphConversion[0].data
                      },
                      {
                        id: 'Earnings',
                        data: prodgraphEarnings[0].data
                      }
                    ]}
                  titles={[]}
                />
                :
                <></>
              }
              </Div>

            </div>
            </>
      }
      </>
    );
  }
  return <></>;
};