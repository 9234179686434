import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';

export const useSubscription = () => {
  const data: any = useSelector(selectUser);
  const merchant = data?.merchant_data;
  const getPercentage: any = (total: any, number: any) => Math.trunc((number / total) * 100);

  const [current, setCurrent] = React.useState({
    status: 'FREE TRIAL',
    product: {
      limit: 3,
      total: merchant?.total_products,
      remaining: getPercentage(3, merchant?.total_products) || 0,
    },
    affiliate: {
      limit: 15,
      total: merchant?.total_affiliate,
      remaining: getPercentage(15, merchant?.total_affiliate) || 0,
    },
    totalLinks: merchant?.total_links,
    expiration: 'You are not subscribed yet.',
  });

  React.useEffect(() => {
    if (data?.user_info?.subscription === 'pro') {
      const expiration = new Date(data?.user_info?.subscription_expiry);
      setCurrent({
        status: 'PRO PLAN',
        product: {
          limit: 1000,
          total: merchant?.total_products,
          remaining: 100,
        },
        affiliate: {
          limit: 1000,
          total: merchant?.total_affiliate,
          remaining: 100,
        },
        totalLinks: merchant?.total_links,
        expiration: expiration.toString().substring(0, 15),
      });
    } else if (data?.user_info?.subscription === 'plus') {
      const expiration = new Date(data?.user_info?.subscription_expiry);
      setCurrent({
        status: 'PLUS PLAN',
        product: {
          limit: 10,
          total: merchant?.total_products,
          remaining: getPercentage(10, merchant?.total_products) || 0,
        },
        affiliate: {
          limit: 1000,
          total: merchant?.total_affiliate,
          remaining: 100,
        },
        totalLinks: merchant?.total_links,
        expiration: expiration.toString().substring(0, 15),
      });
    }
     else {
      setCurrent({
        status: 'FREE PLAN',
        product: {
          limit: 3,
          total: merchant?.total_products,
          remaining: getPercentage(3, merchant?.total_products) || 0,
        },
        affiliate: {
          limit: 15,
          total: merchant?.total_affiliate,
          remaining: getPercentage(15, merchant?.total_affiliate) || 0,
        },
        totalLinks: merchant?.total_links,
        expiration: 'You are not subscribed yet.',
      });
    }
  }, []);
  return current;
};