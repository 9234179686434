import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { Tab } from '@headlessui/react';
import { Div, Flex, FlexRow, FlexCenter, Text, ToastCustom } from '@core';
import { getUserDetails } from '@services';
import { useWindowSize } from '@hooks';
import { formatDate2, formatTime, formatTime2, classNames } from '@helpers';

export const UserDetailsXffiliate: FC<any> = ({ user }) => {

	const [userData, setUserData] = React.useState<any>('');
	const [isShow, setIsShow] = React.useState(true);
	const [isToastOpen, setToastOpen] = React.useState(false);
	const [newDate, setNewDate] = React.useState('-- --');
	const [newDateRegister, setNewDateRegister] = React.useState<any>('-- --');

	const { search } = useLocation();
	const query = new URLSearchParams(search);
  const lastLogin = query.get('login');
  const type = query.get('type');

  const window = useWindowSize();
  const tabs = ['Personal Info'];

  function checkTabMobile(tab: string) {
    let icon: string = '';
    switch (tab) {
      case tabs[0]:
        icon = 'fas fa-user';
        break;
      case tabs[1]:
        icon = 'fas fa-users';
        break;
      default:
        break;
    }
    return <i className={classNames(icon, 'fa-2x')} />;
  }

  function checkTabDesktop(tab: string) {
    switch (tab) {
      case tabs[0]:
        return tabs[0];
      case tabs[1]:
        return `Merchant Partner`;
      default:
        return '';
    }
  }

	React.useEffect(() => {
	  batch(async() => {
	  	setToastOpen(true)
	  	setIsShow(false)
	    const data:any = await getUserDetails(user);
	    
	    if(data?.data?.status === 1){
	    	const newDateA = new Date(data?.data?.user_info?.created_at);

	      setUserData(data?.data)
	      setNewDateRegister(newDateA)
	    }

	    setTimeout(() => {
	      	setIsShow(true)
	      	setToastOpen(false)
	    }, 750)
	  })
	}, [user])

	React.useEffect(() => {
		if(lastLogin && lastLogin !== '-- --'){
			setNewDate(`${formatDate2(lastLogin.substring(0, 10))} at ${formatTime(lastLogin.substring(10, 18))}`)
		}
	}, [lastLogin])
	
return( 
	<>
	{
	userData === '' || !isShow ?
	<ToastCustom
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	>
		<Div className='w-full h-full flex items-center justify-center'>
			<Text className='text-xl text-white font-semibold items-center flex flex-row'>
			  Please wait...
			  <i className="fad fa-spinner-third animate-spin text-2xl mx-4" />
			</Text>
		</Div>
	</ToastCustom>
	:
	<>
	<Text className="font-poppins font-semibold text-2xl text-primary-100 mb-4">
        User Information
  </Text>
  <hr className="text-grey-400 mb-8" />

  <FlexRow className='phone:flex-col items-center tablet:flex-row'>
  	<Div className="h-40 w-40 border-primary-100 border-4 rounded-full overflow-hidden">
  	  <i className="fas fa-user h-full fa-8x text-grey-400 flex items-end justify-center" />
  	</Div>

  	<Div className='w-full tablet:w-4/5 px-4 tablet:px-8 laptop:px-20'>
  		<Text className='text-blue-100 font-semibold laptop:text-xl mt-4'>
  				{`${userData?.user_info?.firstname} ${userData?.user_info?.lastname}`}
  			</Text>
  		<Text className='text-secondary-200'>
  			{type}
  		</Text>
  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
	  		<Text className='font-semibold mr-auto tablet:mr-4'>
	  			Registered:
	  		</Text>
	  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
	  			{`${formatDate2(newDateRegister)} at ${formatTime2(newDateRegister)}`}
	  		</Text>
  		</FlexRow>
  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
	  		<Text className='font-semibold mr-auto tablet:mr-4'>
	  			Last Login:
	  		</Text>
	  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
	  			{newDate}
	  		</Text>
  		</FlexRow>
  		<FlexRow className='my-4'>
	  		<Text className='font-semibold mr-4'>
	  			Account:
	  		</Text>
	  		{
	  			userData?.email_confirmed?
	  			<Text className='bg-green-100 text-white font-semibold px-4 py-1 rounded-full'>
	  				VERIFIED
	  			</Text>
	  			:
	  			<Text className='bg-red-400 text-white font-semibold px-4 py-1 rounded-full'>
	  				NOT VERIFIED
	  			</Text>
	  		}
  		</FlexRow>
  	</Div>
  </FlexRow>

  <FlexRow className="mt-12">
    <Tab.Group>
      <Flex>
        <Tab.List className="tabletWide:space-x-8">
          {tabs.map((tab) => (
            <Tab
              className={({ selected }) =>
                classNames(
                  'py-3 font-semibold w-auto',
                  selected
                    ? 'text-primary-100 text-xl border-b-4 border-blue-400'
                    : 'text-grey-700 text-xl  hover:text-grey-200',
                )
              }
              key={tab}
            >
              {window.width > 0 ? checkTabDesktop(tab) : checkTabMobile(tab)}
            </Tab>
          ))}
        </Tab.List>
        <hr className="text-grey-1200" />
        <Tab.Panels className="pt-9">

        	{/* Personal Info */}
          <Tab.Panel>
            <FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			First Name:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.firstname}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Last Name:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.lastname}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Email:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.email}
					  		</Text>
				  		</FlexRow>
				  		<FlexRow className='my-4 w-full phone:flex-col tablet:flex-row'>
					  		<Text className='font-semibold mr-auto tablet:mr-4'>
					  			Mobile:
					  		</Text>
					  		<Text className='text-secondary-200 mr-auto tablet:mr-4'>
					  			{userData?.user_info?.contact}
					  		</Text>
				  		</FlexRow>
          </Tab.Panel>

        	{/* Other Info */}
          <Tab.Panel>
            	<FlexCenter className="col-span-2 py-4">
            	<Text>
            	  Loading data ...
            	  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
            	</Text>
            </FlexCenter>
          </Tab.Panel>

        </Tab.Panels>
      </Flex>
    </Tab.Group>
  </FlexRow>

  </>
	}
  </>
 )
}; 