import React, { FC } from 'react';
import { batch } from 'react-redux';
import { Div, Flex, FlexRow, Text, Button, Toast, ToggleButton } from '@core';
import { getEmailNotifs, updateEmailNotif } from '@services';

export const EmailNotif: FC<any> = ({ user }) => {

  const [all, setAll] = React.useState(false);
  const [newProduct, setNewProduct] = React.useState(true);
  const [updateProduct, setUpdateProduct] = React.useState(true);
  const [commission, setCommission] = React.useState(true);
  const [conversion, setConversion] = React.useState(true);
  const [category, setCategory] = React.useState(true);
  const [termsConditions, setTermsConditions] = React.useState(true);
  const [subscription, setSubscription] = React.useState(true);

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  React.useEffect(() => {
    if(all){
      setNewProduct(true)
      setConversion(true)
      setCategory(true)
      setTermsConditions(true)
      setUpdateProduct(true)
      setSubscription(true)
      setCommission(true)
    }
  }, [all])

  React.useEffect(() => {
    batch(async() => {
      const data:any = await getEmailNotifs();
      
      if(data?.data?.status === 1){
        setNewProduct(data?.data?.data?.merchant_new_product === 1)
        setConversion(data?.data?.data?.conversion_success === 1)
        setCategory(
          data?.data?.data?.affiliate_category_invite === 1 ||
          data?.data?.data?.affiliate_category_update === 1 ||
          data?.data?.data?.affiliate_category_removal === 1
        )
        setTermsConditions(data?.data?.data?.terms_and_privacy_update === 1)
        setUpdateProduct(
          data?.data?.data?.product_link_update === 1 ||
          data?.data?.data?.affiliate_product_removal === 1
        )
        setSubscription(data?.data?.data?.merchant_subscription === 1)
        setCommission(data?.data?.data?.commission === 1)
      }
    })
  }, [user])

  const onRefresh = async () => {
    batch(async() => {
      const data:any = await getEmailNotifs();
      
      if(data?.data?.status === 1){
        setNewProduct(data?.data?.data?.merchant_new_product === 1)
        setConversion(data?.data?.data?.conversion_success === 1)
        setCategory(
          data?.data?.data?.affiliate_category_invite === 1 ||
          data?.data?.data?.affiliate_category_update === 1 ||
          data?.data?.data?.affiliate_category_removal === 1        )
        setTermsConditions(data?.data?.data?.terms_and_privacy_update === 1)
        setUpdateProduct(
          data?.data?.data?.product_link_update === 1 ||
          data?.data?.data?.affiliate_product_removal === 1
        )
        setSubscription(data?.data?.data?.merchant_subscription === 1)
        setCommission(data?.data?.data?.commission === 1)
      }
    })
  }

  const onSave = async () => {
    setLoading(true);
    batch(async() => {
      const data: any = await updateEmailNotif({
        merchant_product_update: updateProduct || all? 1 : 0,
        affiliate_category_invite: category || all? 1 : 0,
        merchant_category_update: category || all? 1 : 0,
        merchant_category_removed: category || all? 1 : 0,
        affiliate_category_update: category || all? 1 : 0,
        affiliate_category_removal: category || all? 1 : 0,
        affiliate_product_removal: updateProduct || all? 1 : 0,
        merchant_product_removal: updateProduct || all? 1 : 0,
        terms_and_privacy_update: termsConditions || all? 1 : 0,
        conversion_success: conversion || all? 1 : 0,
        merchant_new_product: newProduct || all? 1 : 0,
        store_link_update: 1,
        product_link_update: updateProduct || all? 1 : 0,
        merchant_subscription: subscription || all? 1 : 0,
        commission: commission || all? 1 : 0
      })
      if(data?.data?.status === 1){
        onRefresh();
        setToastTitle('SUCCESS');
        setToastDesc('Email notifications successfully updated.');
        setToastKind('success');
        setTimeout(() => {
          setLoading(false)
          setToastOpen(true);
        }, 1500)
      }
      else{
        setToastTitle('ERROR');
        setToastDesc(data?.data?.message || 'Something went wrong');
        setToastKind('error');
        setTimeout(() => {
          setLoading(false)
          setToastOpen(true);
        }, 1500)
      }
    })
  }

return(
  <>
  <Toast
    description={toastDesc}
    isOpen={isToastOpen}
    onClose={() => setToastOpen(false)}
    title={toastTitle}
    toastKind={toastKind}
  />
  <Flex
    className="desktop:pl-16 phone:pt-0 laptop:pr-20 phone:px-5"
  >
    <Text className="font-poppins font-semibold text-xl phone:mt-4 tablet:mt-8 mb-3">
      Email Notifications
    </Text>
    <hr className="text-grey-400 mb-10" />

    <Text className='text-secondary-200'>
      Where we notify you via email about any updates concerning 
      your affiliated products and merchant.
    </Text>

    <Div className='mt-8 mb-4'>
      <ToggleButton
        checked={all}
        containerClass='w-full laptop:w-10/12 justify-between'
        onClick={() => setAll(!all)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            All Updates
          </Text>
          <Text className='text-secondary-200'>
            Enable all email notifications
          </Text>
        </div>
      </ToggleButton>

      <ToggleButton
        checked={updateProduct}
        containerClass='w-full laptop:w-10/12 justify-between'
        onClick={() => setUpdateProduct(!updateProduct)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            Affiliated Product Updates
          </Text>
          <Text className='text-secondary-200'>
            Get informed when merchant made some changes on your affiliated products
          </Text>
        </div>
      </ToggleButton>

      <ToggleButton
        checked={newProduct}
        containerClass='w-full laptop:w-10/12 justify-between'
        onClick={() => setNewProduct(!newProduct)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            New Product Updates
          </Text>
          <Text className='text-secondary-200'>
            Get informed of new products when created by your affiliated merchants
          </Text>
        </div>
      </ToggleButton>

      <ToggleButton
        checked={commission}
        containerClass='w-full laptop:w-10/12 justify-between hidden'
        onClick={() => setCommission(!commission)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            Commission Updates
          </Text>
          <Text className='text-secondary-200'>
            Get informed at any updates onn your commission payouts
          </Text>
        </div>
      </ToggleButton>

      <ToggleButton
        checked={conversion}
        containerClass='w-full laptop:w-10/12 justify-between'
        onClick={() => setConversion(!conversion)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            Conversion Updates
          </Text>
          <Text className='text-secondary-200'>
            Get informed when you had a conversion via your shared link
          </Text>
        </div>
      </ToggleButton>

      <ToggleButton
        checked={category}
        containerClass='w-full laptop:w-10/12 justify-between'
        onClick={() => setCategory(!category)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            Affiliate Category Updates
          </Text>
          <Text className='text-secondary-200'>
            Get informed when your category has been updated by your merchant
          </Text>
        </div>
      </ToggleButton>

      <ToggleButton
        checked={termsConditions}
        containerClass='w-full laptop:w-10/12 justify-between'
        onClick={() => setTermsConditions(!termsConditions)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            Terms and Conditions Updates
          </Text>
          <Text className='text-secondary-200'>
            Get informed when merchant terms and conditions has been updated
          </Text>
        </div>
      </ToggleButton>

      <ToggleButton
        checked={subscription}
        containerClass='w-full laptop:w-10/12 justify-between'
        onClick={() => setSubscription(!subscription)}
      >
        <div>
          <Text className='text-primary-100 font-semibold'>
            Merchant Subscription Updates
          </Text>
          <Text className='text-secondary-200'>
            Get informed when a merchant subscription has expired
          </Text>
        </div>
      </ToggleButton>

    </Div>

    <FlexRow className='w-full justify-end laptop:w-10/12'>
      <Button className='w-24 justify-center' isLoading={loading} onClick={onSave}>
        Save
      </Button>
    </FlexRow>
    
  </Flex>
  </>
);
}