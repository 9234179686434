import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../type';

const links = (state: RootState) => state.links;

export const selectlinksLoading = createSelector(links, (state) => state.loading);

export const selectlinks = createSelector(links, (state) => state.data ?? []);

export const selectAlllinksAndMetadata = createSelector(
  selectlinks,
  selectlinksLoading,
  (data = [], loading = false) => ({ data, loading }),
);
