import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { batch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { UserDetailsMerchant } from './UserDetailsMerchant';
import { UserDetailsAffiliate } from './UserDetailsAffiliate';
import { UserDetailsAdmin } from './UserDetailsAdmin';
import { UserDetailsXffiliate } from './UserDetailsXffiliate';
import { Text, FlexRow, Div, ToastInputMultipleV2 } from '@core';
import { PaginationV2, UserData } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';

export const UsersDashboard: FC<any> = ({ loading, readAccounts, writeAccounts }) => {

	const token = getCookie('token');
	const [isShow, setIsShow] = React.useState(true);
	const [isToastOpen5, setToastOpen5] = React.useState(false);
	const [toastKind5, setToastKind5] = React.useState('success');
	const [toastTitle5, setToastTitle5] = React.useState('');
	const [toastDesc5, setToastDesc5] = React.useState('');

	const [selected, setSelected] = React.useState('name');
	const [list, setList] = React.useState<any[]>([])
	const [mainList, setMainList] = React.useState<any[]>([])
	const resultList: any[] = [];
	const emptyList: any[] = [];

	const [searchKeyword, setSearchKeyword] = React.useState('');
	const [searchComp, setSearchComp] = React.useState('')
	const [searchEmail, setSearchEmail] = React.useState('')

	const [searchOpen, setSearchOpen] = React.useState(true);
	const [resultDesc, setResultDesc] = React.useState('');

	const [currentPage, setCurrentPage] = React.useState(1);
	const [articlesPerPage, setArticlesPerPage] = React.useState(5);
	const [pageItems, setPageItems] = React.useState(5);
	const indexOfLastArticle = currentPage * articlesPerPage;
	const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

	const [pageDesc, setPageDesc] = React.useState('');

	const currentAccounts = list.slice(indexOfFirstArticle, indexOfLastArticle);

	const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

	const { search } = useLocation();
	const query = new URLSearchParams(search);
  	const tabQuery = query.get('tab');
  	const userId = query.get('id');
  	const type = query.get('type');

	// CSV Variables
	const newDate = new Date();
	const fileName = newDate.getTime();
	const [csvData, setCsvData] = React.useState([]);

	const headers: any = [
		{ label: "User ID", key: "user_id" },
  	{ label: "Full Name", key: "name" },
  	{ label: "Email Address", key: "email" },
  	{ label: "User Type", key: "user_type" },
  	{ label: "Date Registered", key: "date_created" },
  	{ label: "Last Active", key: "last_login" },
	];

	React.useEffect(() => {
		if(pageItems < 0 || pageItems > list.length){
			setArticlesPerPage(5)
			setPageDesc('Items per page must not exceed total records count or less than zero.')
		}else if (pageItems > 0 && pageItems <= list.length){
			setArticlesPerPage(pageItems)
		}
	}, [pageItems])

	React.useEffect(() => {
		if(pageItems > 0 && pageItems <= list.length){
			setPageDesc('')
		}
	})

	React.useEffect(() => {
		setToastDesc5('Please enter a keyword to search.')
		setToastTitle5('ACTION')
		setTimeout(() => {
		  setToastKind5('info')
		}, 500)
	}, [searchKeyword, searchComp, searchEmail])

	React.useEffect(() => {
		setIsShow(false)
	  fetch(`${API}/get-all-users`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	  	if(data.data.length > 0){
	  		setCsvData(data?.data)
	  	  setList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
	  	  setTimeout(() => {
	  	    setIsShow(true)
	  	  }, 1500)	
	  		setMainList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}, [token])

	React.useEffect(() => {
	  setList(mainList)
	}, [search])

	const onClick = () => {
	    setSearchKeyword('')
	    fetch(`${API}/get-all-users`, {
	      method: 'GET',
	      headers: {
	        Authorization: `Bearer ${token}`,
	      },
	    })
	    .then(res => res.json())
	    .then(data => {
	      if(data?.data?.length > 0){
	      	setCsvData(data?.data)
		  	  setList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
		  	  setTimeout(() => {
		  	    setIsShow(true)
		  	  }, 1500)	
		  		setMainList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
	  			setIsShow(true)
	      }else{
	      	setIsShow(true)
	      }
		    })
		    setToastOpen5(true)
		    setToastTitle5('ACTION')
		    setToastKind5('info')
		    setToastDesc5('Please enter a keyword to search.')
	}

	const onSearch = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(searchKeyword)){
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.name.toLowerCase().includes(searchKeyword.toLowerCase())){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchKeyword}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchKeyword}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSearchComp = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.store_name.toLowerCase().includes(searchComp.toLowerCase())){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchComp}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchComp}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onSearchEmail = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.email.toLowerCase().includes(searchEmail.toLowerCase())){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onSubmit = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(searchKeyword)){
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.name.toLowerCase().includes(searchKeyword.toLowerCase())
	      ){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchKeyword}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchKeyword}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmitComp = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.store_name.toLowerCase().includes(searchComp.toLowerCase())
	      ){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchComp}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchComp}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onSubmitEmail = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await mainList.map((item:any) => {
	      if(item.email.toLowerCase().includes(searchEmail.toLowerCase())
	      ){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onRefresh = () => {
	  setSearchKeyword('')
	  setSearchOpen(true)
	  fetch(`${API}/get-all-users`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data?.data?.length > 0){
	    	setCsvData(data?.data)
	  	  setList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
	  	  setTimeout(() => {
	  	    setIsShow(true)
	  	  }, 1500)	
	  		setMainList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}

	const onSort = async (e:any) => {
	  if(e === 'name'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.name < b.name) { return -1; }
	    if(a.name > b.name) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if(e === 'reverseName'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(b.name < a.name) { return -1; }
	    if(b.name > a.name) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'email'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.email < b.email) { return -1; }
	    if(a.email > b.email) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateNew'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date_created);
	    const newDateB: any = new Date(b.date_created);
	    return newDateB - newDateA
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateOld'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date_created);
	    const newDateB: any = new Date(b.date_created);
	    return newDateA - newDateB
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'merchant'){
	    setIsShow(false)
	    resultList.splice(0, resultList.length)
	    batch(async() => {
	    	await mainList.map((item:any) => {
	    	  if(item.user_type.toLowerCase() === 'merchant'){
	    	    resultList.push(item)
	    	    setList(resultList)
	    	    setCurrentPage(1)
	    	  }
	    	  return 1;
	    	})
	    	if(resultList.length === 0){
	    	  setList(emptyList)
	    	}
	    	setTimeout(() => {
	    	  setIsShow(true)
	    	}, 1500)
	    })
	  } else if (e === 'affiliate'){
	    setIsShow(false)
	    resultList.splice(0, resultList.length)
	    batch(async() => {
	    	await mainList.map((item:any) => {
	    	  if(item.user_type.toLowerCase() === 'affiliate'){
	    	    resultList.push(item)
	    	    setList(resultList)
	    	    setCurrentPage(1)
	    	  }
	    	  return 1;
	    	})
	    	if(resultList.length === 0){
	    	  setList(emptyList)
	    	}
	    	setTimeout(() => {
	    	  setIsShow(true)
	    	}, 1500)
	    })
	  } else if (e === 'editor'){
	    setIsShow(false)
	    resultList.splice(0, resultList.length)
	    batch(async() => {
	    	await mainList.map((item:any) => {
	    	  if(item.user_type.toLowerCase() === 'editor'){
	    	    resultList.push(item)
	    	    setList(resultList)
	    	    setCurrentPage(1)
	    	  }
	    	  return 1;
	    	})
	    	if(resultList.length === 0){
	    	  setList(emptyList)
	    	}
	    	setTimeout(() => {
	    	  setIsShow(true)
	    	}, 1500)
	    })
	  } else if (e === 'manager'){
	    setIsShow(false)
	    resultList.splice(0, resultList.length)
	    batch(async() => {
	    	await mainList.map((item:any) => {
	    	  if(item.user_type.toLowerCase() === 'manager'){
	    	    resultList.push(item)
	    	    setList(resultList)
	    	    setCurrentPage(1)
	    	  }
	    	  return 1;
	    	})
	    	if(resultList.length === 0){
	    	  setList(emptyList)
	    	}
	    	setTimeout(() => {
	    	  setIsShow(true)
	    	}, 1500)
	    })
	  } else if (e === 'admin'){
	    setIsShow(false)
	    resultList.splice(0, resultList.length)
	    batch(async() => {
	    	await mainList.map((item:any) => {
	    	  if(item.user_type === 'Super-Admin'){
	    	    resultList.push(item)
	    	    setList(resultList)
	    	    setCurrentPage(1)
	    	  }
	    	  return 1;
	    	})
	    	if(resultList.length === 0){
	    	  setList(emptyList)
	    	}
	    	setTimeout(() => {
	    	  setIsShow(true)
	    	}, 1500)
	    })
	  } else if (e === ''){
	    setIsShow(false)
	    setList(mainList)
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  }
	}

	const onReload = () => {
	  setIsShow(false)
	  fetch(`${API}/get-all-users`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data?.data?.length > 0){
	    	setCsvData(data?.data)
	  	  setList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
	  	  setTimeout(() => {
	  	    setIsShow(true)
	  	  }, 1500)	
	  		setMainList(data?.data.sort((a:any , b: any) => a.user_id - b.user_id))
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}
	
return(
	<>
	{
		type === 'MERCHANT'?
		<div className='laptop:px-20'>
			<UserDetailsMerchant user={userId}/>
		</div>
		:
		<></>
	}
	{
		type === 'AFFILIATE'?
		<div className='laptop:px-20'>
			<UserDetailsAffiliate user={userId}/>
		</div>
		:
		<></>
	}
	{
		type === 'MANAGER' || type === 'EDITOR'?
		<div className='laptop:px-20'>
			<UserDetailsAdmin user={userId}/>
		</div>
		:
		<></>
	}
	{
		type === 'ADMIN'?
		<div className='laptop:px-20'>
			<UserDetailsXffiliate user={userId}/>
		</div>
		:
		<></>
	}
	{
	tabQuery === 'user'?
  <></>
	:
	<>
	<ToastInputMultipleV2
	      description={toastDesc5}
	      isOpen={isToastOpen5}
	      onChange={(e:any) => setSearchKeyword(e.target.value)}
	      onChangeComp={(e:any) => setSearchComp(e.target.value)}
	      onChangeEmail={(e:any) => setSearchEmail(e.target.value)}
	      onClickSubmit={onSearch}
	      onClickSubmitComp={onSearchComp}
	      onClickSubmitEmail={onSearchEmail}
	      onClose={() => setToastOpen5(false)}
	      onReset={onClick}
	      onSubmit={onSubmit}
	      onSubmitComp={onSubmitComp}
	      onSubmitEmail={onSubmitEmail}
	      title={toastTitle5}
	      toastKind={toastKind5}
	      value={searchKeyword}
	      valueComp={searchComp}
	      valueEmail={searchEmail}
	    />
	<Text className="font-poppins font-semibold text-2xl text-primary-100 my-4">
        Users List
    </Text>
    <hr className="text-grey-400 mb-10" />

    {
      searchOpen?
      <>
      </>
      :
      <div>
          <p className='phone:text-center surfaceDuo:text-left mb-2'>{resultDesc}</p>
          <span className='block text-center surfaceDuo:text-left hover:underline text-blue-100 cursor-pointer mb-4' onClick={onRefresh}>Clear Searches</span>
      </div>
    }

    <div className='flex tabletWide:hidden flex-row items-center phone:w-full surfaceDuo:w-auto my-4'>
    <label className='flex phone:ml-4 surfaceDuo:ml-0 mr-4' htmlFor='sort'>Search by:</label>
    <select className='w-56 border border-grey-400 text-secondary-200' name="sort" onChange={(e:any) => setSelected(e.target.value) }>
        <option value="name">Name</option>
        <option value='email'>Email</option>
    </select>
    </div>

    <FlexRow className="justify-between">
      <FlexRow className='gap-4 phone:items-start phone:mx-5 tabletWide:items-center tabletWide:mx-0 tabletWide:justify-between w-full flex phone:flex-col tabletWide:flex-row'>

            {
            	selected === 'name' ?
            	<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
            	  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
            	      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
            	  >
            	      <form onSubmit={onSubmit}>
            	      <input className="placeholder-grey-700 laptop:text-base
            	          tablet:py-3.5 w-full outline-none border-none block phone:text-sm phone:py-2"
            	          onChange={(e:any) => {
            	            setSearchKeyword(e.target.value)
            	          }}
            	          placeholder="Enter a keyword"
            	          value={searchKeyword}
            	      />
            	      <button className='hidden' type='submit'>Search</button>
            	      </form>
            	  </div>
            	  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearch}/>
            	</div>
            	:
            	<></>
            }

            {
            	selected === 'company' ?
            	<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
            	  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
            	      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
            	  >
            	      <form onSubmit={onSubmitComp}>
            	      <input className="placeholder-grey-700 laptop:text-base
            	          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
            	          onChange={(e:any) => {
            	            setSearchComp(e.target.value)
            	          }}
            	          placeholder="Enter a keyword"
            	          value={searchComp}
            	      />
            	      <button className='hidden' type='submit'>Search</button>
            	      </form>
            	  </div>
            	  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchComp}/>
            	</div>
            	:
            	<></>
            }

            {
            	selected === 'email' ?
            	<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
            	  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
            	      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
            	  >
            	      <form onSubmit={onSubmitEmail}>
            	      <input className="placeholder-grey-700 laptop:text-base
            	          tablet:py-3.5 w-full outline-none border-none block phone:text-sm phone:py-2"
            	          onChange={(e:any) => {
            	            setSearchEmail(e.target.value)
            	          }}
            	          placeholder="Enter a keyword"
            	          value={searchEmail}
            	      />
            	      <button className='hidden' type='submit'>Search</button>
            	      </form>
            	  </div>
            	  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchEmail}/>
            	</div>
            	:
            	<></>
            }

            <button className='hidden tabletWide:flex w-52 flex-row items-center justify-start text-primary-100 hover:text-white py-2 px-8 rounded-full border-2 border-primary-100 bg-white hover:bg-primary-100' onClick={onClick}>
              <i className="fas fa-search text-base mr-2" />Search 
            </button>
          
          <FlexRow className='phone:flex-col phone:w-full phone:items-start surfaceDuo:w-auto tablet:flex-row tablet:justify-start tablet:w-auto'>
	          <FlexRow className='phone:flex-col phone:items-start surfaceDuo:flex-row surfaceDuo:items-center phone:w-full surfaceDuo:w-auto surfaceDuo:my-2 tablet:my-0'>
	          <label className='mr-3 my-2 surfaceDuo:my-0 surfaceDuo:w-28' htmlFor='sort'>Sort by:</label>
	          <select className='rounded-lg phone:w-full surfaceDuo:w-56 border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
	              <option value="">Default</option>
	              <option value="name">A - Z (Name)</option>
	              <option value="reverseName">Z - A (Name)</option>

	              {/* <option value='email'>A - Z (Email)</option> */}

	              <option value="dateNew">Newest to Oldest</option>
	              <option value="dateOld">Oldest to Newest</option>
	          </select>
	          </FlexRow>

	          <FlexRow className='phone:flex-col phone:items-start surfaceDuo:flex-row surfaceDuo:items-center phone:w-full surfaceDuo:w-auto surfaceDuo:my-2 tablet:my-0'>
	          <label className='mr-3 my-2 surfaceDuo:my-0 surfaceDuo:w-28 tablet:w-32 tablet:ml-4' htmlFor='sort'>Filter by User:</label>
	          <select className='rounded-lg phone:w-full surfaceDuo:w-56 border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
	              <option value="">All User</option>
	              <option value="merchant">Merchant</option>
	              <option value="affiliate">Affiliate</option>
	              <option value='editor'>Editor</option>
	              <option value="manager">Manager</option>
	              <option value="admin">Admin</option>
	          </select>
	          </FlexRow>
          </FlexRow>
          
      </FlexRow>
      
    </FlexRow>

    <Text className='tabletWide:hidden mt-8 pb-2 text-primary-100 font-semibold border-b border-grey-400 text-center'>
    	List of Accounts
    </Text>

    <FlexRow className='w-full justify-around mt-4 pb-2 border-b border-grey-400 phone:hidden tabletWide:flex'>
    	<div className='text-primary-100 font-medium py-2 w-14'>
    		ID
    		<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/5'>
    		Name
    		<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/12 laptop:w-1/5'>
    		Email
    		<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/10'>
    		User Type
    		<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/10'>
    		Registered
    		<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/5'>
    		Last Login
    		<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
    	</div>
    	<div className='text-primary-100 font-medium py-2 w-1/12 text-center'>
    		Action
    		<i className="fas fa-chevron-down text-primary-100 mx-2 hidden" />
    	</div>
    </FlexRow>

    <Div className='w-full pt-4'>
    	<Div className={`w-full ${articlesPerPage > 10 ? 'h-96 overflow-y-scroll' : ''}`}>
    	{
    		currentAccounts.length > 0 && isShow && list.length > 0 ?
    		<>
    		{
    		  currentAccounts.map((listA: any, index: number) =>(
    		    <UserData
    		      accEmail={listA.email}
    		      accName={listA.name}
    		      date={listA.date_created}
    		      id={listA.user_id}
    		      key={index}
    		      lastLogin={listA.last_login || '-- --'}
    		      loading={loading}
    		      readAccounts={readAccounts}
    		      userType={listA.user_type}
    		      writeAccounts={writeAccounts}
    		    />
    		    ))
    		}
    		</>
    		:
    		<></>
    	}
	    {
	    	currentAccounts.length === 0 && isShow && list.length > 0 ?
	    	<Div className='bg-grey-400 py-4 my-2'>
	    	<Text className='text-center w-full'>
	    		Page is empty.
	    	</Text>
	    	<Text className='text-xs text-center w-full mt-4'>
	    		Go to Page 1
	    	</Text>
	    	</Div>
	    	:
	    	<></>
	    }
	    {
	    	currentAccounts.length === 0 && isShow && list.length === 0?
	    	<Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	    	  No data to be displayed.
	    	  <span className='block text-blue-100 hover:underline font-semibold cursor-pointer' onClick={onReload}>
	    	    Click to reload.
	    	    <i className="fas fa-repeat ml-2" />
	    	  </span>
	    	</Text>
	    	:
	    	<></>
	    }
	    {
	      !isShow?
	      <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	      	Loading data ...
	      	<i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	      </Text>
	      :
	      <></>
	    }
	    </Div>
    <Div className="w-full justify-center mt-14">

    	{
    		csvData.length > 0?
    		<FlexRow className='w-full justify-start'>
    		<CSVLink data={csvData} filename={`accounts-${fileName}.csv`} headers={headers}>
    			<div className='font-semibold text-primary-100 hover:underline'>
    				<i className="fas fa-download text-blue-100 mr-2"/>
    				Export to CSV
    			</div>
    		</CSVLink>
    		</FlexRow>
    		:
    		<FlexRow className='w-full justify-start'>
    		<div className='font-semibold text-primary-100 hover:underline cursor-not-allowed'>
    			<i className="fas fa-download text-blue-100 mr-2"/>
    			Export to CSV
    		</div>
    		</FlexRow>
    	}

    	<div
    	  className='font-robot font-regular text-blue-100 my-4 hover:underline cursor-pointer'
    	  onClick={onReload}>
    	  Refresh records
    	</div>

      <Text className='text-secondary-200 my-4'>
      	Total records found: <b className='ml-4 mr-2'>{list.length}</b> entries
      </Text>

      <Text className='flex flex-row items-center text-secondary-200 my-4'>
      	<Popover className="relative my-4">
      	      <Popover.Button>
      	            <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
      	      </Popover.Button>
      	      <Popover.Panel className="absolute z-10 left-0">
      	        <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
      	          <p className='font-semibold text-sm'>
      	            Items per Page
      	          </p>
      	          <hr className='text-primary-100 my-1'/>
      	          <p className='text-sm mt-4'>
      	            Input a number or press
      	            <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            to change number of entries per page.
      	          </p>
      	          <p className='mt-4 text-sm text-secondary-200'>
      	            ** Click anywhere on screen to close. **
      	          </p>
      	        </div>
      	      </Popover.Panel>
      	</Popover>
      	Items per Page:
      	<input className='mx-2 w-10 px-2' onChange={(e:any) => setPageItems(e.target.value)} type='number' value={pageItems} />
      	<button className='hidden flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white'>
      		Go
      	</button>
      </Text>

      {
      	list.length > 5 ?
      	<Text className='text-red-400'>
      		{pageDesc}
      	</Text>
      	:
      	<></>
      }

      <PaginationV2
        currentPage={currentPage}
        itemsPerPage={articlesPerPage}
        paginate={paginate}
        totalItems={list.length}
      />
    </Div>  
    </Div>
    </>
  	}
  	</>
 )
}; 