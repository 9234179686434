import React, { FC } from 'react';

interface FlexRowProps {
  className?: string;
  id?: string;
  title?: string;
}

export const FlexRow: FC<FlexRowProps> = ({ className, id, title, ...props }) => (
  <div className={`flex flex-row items-center ${className}`} id={id} title={title} {...props} />
);