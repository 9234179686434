import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { batch } from 'react-redux';
import { Div, Text, FlexRow, Flex, Toast, Icons } from '@core';
import { getCookie, useSubscription } from '@hooks';
import { API, SHARE } from '@config';
import { ColorsList, ColorsPairs } from '@constants';
import {
  getFilesData,
  getColorsData,
  uploadFile,
  updateFiles,
  updateTerms,
  updatePolicy,
} from '@services';

export const InviteLinks: FC<any> = ({ user }) => {
  
  const subscription = useSubscription();
  const token = getCookie('token');
  const [copied, setCopied] = React.useState<any>(false);
  const [referralLink, setReferralLink] = React.useState('');
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [customLink, setCustomLink] = React.useState(user?.merchant_data?.info?.merchant_link);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);

  const [isEdit, setEdit] = React.useState(false);
  const [moreColors, setMoreColors] = React.useState(false);
  const [colorsFont, setColorsFont] = React.useState('#CCE2E4');
  const [colorsButton, setColorsButton] = React.useState('#CCE2E4');

  const inputFile1 = React.useRef<any>(null);
  const inputFile2 = React.useRef<any>(null);
  const [file1, setFile1] = React.useState<any>([{ name: 'No Chosen file', size: 0 }]);
  const [file2, setFile2] = React.useState<any>([{ name: 'No Chosen file', size: 0 }]);
  const [terms, setTerms] = React.useState('');
  const [policy, setPolicy] = React.useState('');

  const [dataColors, setDataColors] = React.useState(false);
  const [dataFiles, setDataFiles] = React.useState(false);
  const [isLoadingFile, setLoadingFile] = React.useState(false);

  React.useEffect(() => {
    setLoadingFile(true);
    setTimeout(() => {
      setLoadingFile(false);
    }, 3000);
  }, [file1, file2]);

  React.useEffect(() => {
    setDataColors(false);
    batch(async () => {
      setReferralLink(`${SHARE}merchant/${user?.merchant_data?.info?.merchant_link}`);
      const colorData: any = await getColorsData();
      if (colorData.data.status === 1) {
        setColorsFont(colorData.data.data.font_colors);
        setColorsButton(colorData.data.data.button_colors);
        setDataColors(true);
      } else if (colorData.data.status === 0) {
        setDataColors(false);
      }
    });
  }, []);

  React.useEffect(() => {
    setDataFiles(false);
    batch(async () => {
      const filesData: any = await getFilesData();
      if (filesData.data.status === 1) {
        setTerms(filesData.data.data.TermsOfService);
        setPolicy(filesData.data.data.privacyPolicy);
        setDataFiles(true);
      } else if (filesData.data.status === 0) {
        setDataFiles(false);
      }
    });
  }, []);

  const onRefreshColor = async () => {
    setDataColors(false);
    batch(async () => {
      const colorData: any = await getColorsData();
      if (colorData.data.status === 1) {
        setColorsFont(colorData.data.data.font_colors);
        setColorsButton(colorData.data.data.button_colors);
        setDataColors(true);
      } else if (colorData.data.status === 0) {
        setDataColors(false);
      }
    });
  };

  const onRefreshFile = async () => {
    setDataFiles(false);
    batch(async () => {
      const filesData: any = await getFilesData();
      if (filesData.data.status === 1) {
        setTerms(filesData.data.data.TermsOfService);
        setPolicy(filesData.data.data.privacyPolicy);
        setDataFiles(true);
      } else if (filesData.data.status === 0) {
        setDataFiles(false);
      }
    });
  };

  const onUpdate = (e: any) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${API}/edit-merchant-link`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        merchant_link: customLink,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 1) {
          setToastTitle('SUCCESS');
          setToastDesc('Link has been updated.');
          setToastKind('success');
          setToastOpen(true);
          setEdit(false);
          setLoading(false);
          setReferralLink(`${SHARE}merchant/${data.data}`);
        } else {
          setToastTitle('ERROR');
          setToastDesc(data.message || 'Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
        }
      });
  };

  const onUploadFile = async () => {
    setLoadingFile(true);
    if (
      file1[0].type === 'application/pdf' &&
      file2[0].type === 'application/pdf' &&
      file1[0].size < 2000000 &&
      file2[0].size < 2000000
    ) {
      batch(async () => {
        const upload: any = await uploadFile({
          terms_service: file1[0],
          privacy_policy: file2[0],
        });
        if (upload.data.status === 1) {
          setTerms(upload.data.data.terms_service);
          setPolicy(upload.data.data.privacy_policy);
          setToastTitle('SUCCESS');
          setToastDesc('File has been uploaded successfully.');
          setToastKind('success');
          setToastOpen(true);
          setLoadingFile(false);
          onRefreshFile();
        } else {
          setToastTitle('ERROR');
          setToastDesc('Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoadingFile(false);
        }
      });
    } else {
      setToastTitle('ERROR');
      setToastDesc('File must be in PDF format.');
      setToastKind('error');
      setToastOpen(true);
      setLoadingFile(false);
    }
  };

  const onUpdateFile = async () => {
    setLoadingFile(true);
    if (
      file1[0].type === 'application/pdf' &&
      file1[0].size < 2000000 &&
      file2[0].name === 'No Chosen file'
    ) {
      batch(async () => {
        const upload: any = await updateTerms({
          terms_service: file1[0],
          privacy_policy: file1[0],
        });
        if (upload.data.status === 1) {
          setTerms(upload.data.data.terms_service);
          setPolicy(upload.data.data.privacy_policy);
          setToastTitle('SUCCESS');
          setToastDesc('File has been uploaded successfully.');
          setToastKind('success');
          setToastOpen(true);
          setLoadingFile(false);
          onRefreshFile();
        } else {
          setToastTitle('ERROR');
          setToastDesc('Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoadingFile(false);
        }
      });
    } else if (
      file2[0].type === 'application/pdf' &&
      file2[0].size < 2000000 &&
      file1[0].name === 'No Chosen file'
    ) {
      batch(async () => {
        const upload: any = await updatePolicy({
          terms_service: file2[0],
          privacy_policy: file2[0],
        });
        if (upload.data.status === 1) {
          setTerms(upload.data.data.terms_service);
          setPolicy(upload.data.data.privacy_policy);
          setToastTitle('SUCCESS');
          setToastDesc('File has been uploaded successfully.');
          setToastKind('success');
          setToastOpen(true);
          setLoadingFile(false);
          onRefreshFile();
        } else {
          setToastTitle('ERROR');
          setToastDesc('Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoadingFile(false);
        }
      });
    } else if (
      file1[0].type === 'application/pdf' &&
      file1[0].size < 2000000 &&
      file2[0].type === 'application/pdf' &&
      file2[0].size < 2000000
    ) {
      batch(async () => {
        const upload: any = await updateFiles({
          terms_service: file1[0],
          privacy_policy: file2[0],
        });
        if (upload.data.status === 1) {
          setTerms(upload.data.data.terms_service);
          setPolicy(upload.data.data.privacy_policy);
          setToastTitle('SUCCESS');
          setToastDesc('File has been uploaded successfully.');
          setToastKind('success');
          setToastOpen(true);
          setLoadingFile(false);
          onRefreshFile();
        } else {
          setToastTitle('ERROR');
          setToastDesc('Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
          setLoadingFile(false);
        }
      });
    } else {
      setToastTitle('ERROR');
      setToastDesc('File must be in PDF format and must be uploaded.');
      setToastKind('error');
      setToastOpen(true);
      setLoadingFile(false);
    }
  };

  const onCreateCustom = async () => {
    setLoading(true);
    if (colorsFont === '#ffffff' || colorsButton === '#ffffff') {
      setToastTitle('ERROR');
      setToastDesc('Please change selected color.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      fetch(`${API}/create-custom-page`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          font_colors: colorsFont,
          button_colors: colorsButton,
          background_colors: '#ffffff',
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 1) {
            setToastTitle('SUCCESS');
            setToastDesc('New colors has been customed successfully.');
            setToastKind('success');
            setToastOpen(true);
            setLoading(false);
            onRefreshColor();
          } else {
            setToastTitle('ERROR');
            setToastDesc(data.status || 'Something went wrong. Please try again.');
            setToastKind('error');
            setToastOpen(true);
            setLoading(false);
          }
        });
    }
  };

  const onUpdateCustom = async () => {
    setLoading(true);
    if (colorsFont === '#ffffff' || colorsButton === '#ffffff') {
      setToastTitle('ERROR');
      setToastDesc('Please change selected color.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      fetch(`${API}/update-custom-page`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          font_colors: colorsFont,
          button_colors: colorsButton,
          background_colors: '#ffffff',
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 1) {
            setToastTitle('SUCCESS');
            setToastDesc('New colors has been updated successfully.');
            setToastKind('success');
            setToastOpen(true);
            setLoading(false);
            onRefreshColor();
          } else {
            setToastTitle('ERROR');
            setToastDesc(data.status || 'Something went wrong. Please try again.');
            setToastKind('error');
            setToastOpen(true);
            setLoading(false);
          }
        });
    }
  };

  //   const onUpgrade = () => {
  //     navigate('/merchant/subscription');
  //   };

  //   const onClose = () => {
  //     setToastOpen2(false);
  //     setTimeout(() => {
  //       navigate('/merchant/settings?action=info');
  //     }, 1000);
  //   };

  //   React.useEffect(() => {
  //     if (subscription.status === 'FREE PLAN') {
  //       setToastOpen2(true);
  //     }
  //   }, [subscription]);

  return (
    <>
      <>
        <Toast
          description={toastDesc}
          isOpen={isToastOpen}
          onClose={() => setToastOpen(false)}
          title={toastTitle}
          toastKind={toastKind}
        />
        <Div className="flex flex-col phone:items-center phone:justify-start medium:items-start w-full px-5">
          <Text className="text-xl text-primary-100 font-semibold phone:mt-4 tablet:mt-8 phone:text-left w-full border-b-2 border-grey-400 pb-2 mb-2">
            Invite Affiliate
          </Text>

          <Text className="font-semibold w-auto text-left mt-4 mx-4 phone:hidden tablet:flex tabletWide:hidden">
            Your shareable link:
          </Text>

          <FlexRow className="phone:items-start medium:items-center phone:flex-col medium:flex-row mt-4 w-full">
            <Text className="font-semibold medium:w-56 mb-1 text-left tablet:hidden tabletWide:block tabletWide:ml-5">
              Your shareable link:
            </Text>

            <Flex className="phone:flex-col surfaceDuo:flex-row justify-start items-start medium:justify-start tablet:ml-4">
              <input
                className="flex px-2 py-1 mt-3 border-2 border-primary-100 w-64 rounded-lg"
                readOnly
                value={referralLink}
              />
              <button
                className={`surfaceDuo:mx-3 my-3 w-auto justify-center items-center rounded
			  		${copied ? 'bg-white' : 'bg-primary-100 text-white'}
			  		`}
                onClick={() => {
                  setCopied(true);
                  navigator.clipboard.writeText(`${referralLink}`);
                }}
              >
                {copied ? (
                  <p className="w-64 px-2 py-1 rounded-lg border-2 border-secondary-200 w-auto">
                    COPIED!
                  </p>
                ) : (
                  <>
                    <p className="w-64 px-2 py-1 rounded-lg border-2 border-secondary-200 w-auto">
                      COPY LINK
                    </p>
                  </>
                )}
              </button>
            </Flex>
          </FlexRow>

          {
			user?.user_info.role === 'merchant' && subscription.status === "FREE PLAN"
			? <></>
			: <Flex className="mt-8 tablet:ml-5 w-full">
				<Text className="text-secondary-200 mb-4">
				Create your customized link for inviting affiliates.
				</Text>
				{isEdit ? (
				<form className="mt-4" onSubmit={(e: any) => onUpdate(e)}>
					<label className="mr-4 text-secondary-200">Enter new link:</label>
					<input
					className="rounded-lg"
					onChange={(e: any) => setCustomLink(e.target.value)}
					placeholder="Your_Custom_Link"
					type="text"
					value={customLink}
					/>
					<Text className="text-secondary-200 my-4">
					Link Preview:
					<span className="text-primary-100 py-2 rounded-lg hover:underline font-robot font-regular phone:block surfaceDuo:inline surfaceDuo:ml-2 overflow-x-scroll scrollbar-hide">
						{SHARE}merchant/{customLink}
					</span>
					</Text>

					<FlexRow>
					{isLoading ? (
						<button className="mx-2 w-40 py-2 rounded-lg text-center block bg-primary-100 mt-4 text-white hover:bg-blue-100">
						<i className="fad fa-spinner-third animate-spin" />
						</button>
					) : (
						<button
						className="mx-2 w-40 py-2 rounded-lg text-center block bg-primary-100 mt-4 text-white hover:bg-blue-100"
						type="submit"
						>
						UPDATE
						</button>
					)}

					<button
						className="mx-2 w-40 py-2 rounded-lg text-center block bg-secondary-200 mt-4 text-white hover:bg-secondary-300"
						onClick={() => setEdit(false)}
					>
						CLOSE
					</button>
					</FlexRow>
				</form>
				) : (
				<>
					{user?.user_info.role === 'Editor' ? (
					<button
						className="w-40 py-2 rounded-lg text-center block bg-secondary-200 mt-2 text-white cursor-not-allowed"
						title="You are not eligible for this action"
					>
						CUSTOM LINK
					</button>
					) : (
					<button
						className="w-40 py-2 rounded-lg text-center block bg-primary-100 mt-2 text-white hover:bg-blue-100"
						onClick={() => setEdit(true)}
					>
						CUSTOM LINK
					</button>
					)}
				</>
				)}
			</Flex>
		  }

          <FlexRow className="w-full justify-center items-center">
            <div className="phone:w-full laptop:w-11/12 flex phone:justify-center tablet:justify-start">
              <div className="tablet:ml-5 mt-5 pt-5 pb-10 rounded-lg tablet:w-3/5">
                <p className="text-orange-100">AFFILIATE PARTNER</p>
                <p className="font-bold text-3xl text-secondary-100 mt-5 mobile:text-4xl">
                  Gain the freedom to partner with everyone
                </p>
                <p className="text-secondary-100 mt-5">
                  Create an effortless, integrated advocate sharing experience. A referral program
                  tailored to your brand will turn more of your customers into advocates and
                  increase brand awareness.
                </p>
              </div>
            </div>
            <div className="phone:hidden laptop:block laptop:w-1/2 flex justify-center items-center">
              <img
                alt="CompanyLogo"
                className="h-80 w-80 rounded-full object-cover shadow-xl"
                onError={() => setImageLoaded(true)}
                src={imageLoaded ? Icons.xffiliate2 : user?.merchant_data?.company[0]?.image}
              />
            </div>
          </FlexRow>

          <Text
            className={`text-xl text-primary-100 font-semibold mt-10 phone:text-left w-full border-b-2 border-grey-400 pb-2 mb-2
				${user?.user_info?.role === 'Editor' ? 'hidden' : ''}
		`}
          >
            Custom Sign Up Page
          </Text>

          <Div
            className={`tablet:px-5 w-full
			${user?.user_info?.role === 'Editor' ? 'hidden' : ''}
		`}
          >
            <Text className="text-secondary-200 mt-4 mb-8">
              Recreate your affiliate landing page with custom content.
            </Text>

            <Text className="font-semibold text-primary-100">My Color themes</Text>

            <FlexRow className="my-4 phone:w-72 surfaceDuo:w-80 justify-between items-center">
              <div className="font-poppins font-regular text-primary-100 font-semibold">
                Font Colors:
              </div>
              <Popover className="relative mb-4">
                <Popover.Button>
                  <div className="rounded-lg px-2 py-1 border border-grey-400">
                    <i className="fas fa-circle mx-2" style={{ color: `${colorsFont}` }} />
                    <i className="fas fa-chevron-down mx-2" />
                  </div>
                </Popover.Button>
                <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
                  <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 w-64 mobile:w-80">
                    <p className="font-semibold mb-2">Choose color:</p>

                    <Div className="grid grid-cols-5">
                      {ColorsList.map((color: any) => (
                        <div
                          className="w-6 h-6 m-2 rounded-full shadow-lg border-2 hover:border-secondary-200 border-white cursor-pointer"
                          key={color.hex}
                          onClick={() => setColorsFont(color.hex)}
                          style={{ backgroundColor: `${color.hex}` }}
                          title={color.name}
                        />
                      ))}
                    </Div>
                    <div
                      className="text-blue-100 hover:underline my-2 font-semibold cursor-pointer"
                      onClick={() => setMoreColors(!moreColors)}
                    >
                      More Colors
                      <i className="fas fa-angle-right ml-2" />
                      <i className="fas fa-angle-right" />
                    </div>

                    <FlexRow className={`mt-4 justify-start ${moreColors ? '' : 'hidden'}`}>
                      <label className="text-sm text-secondary-200" htmlFor="role">
                        Select:
                      </label>
                      <input
                        className="ml-4 h-7 border-2 border-primary-100 rounded-lg cursor-pointer"
                        name="role"
                        onChange={(e: any) => setColorsFont(e.target.value)}
                        required
                        type="color"
                        value={colorsFont}
                      />
                      <input
                        className="ml-4 pl-2 w-28 border-2 border-primary-100 rounded-lg"
                        onChange={(e: any) => setColorsFont(e.target.value)}
                        value={colorsFont}
                      />
                    </FlexRow>

                    <p className="mt-4 text-sm text-secondary-200">
                      ** Click anywhere on screen to close. **
                    </p>
                  </div>
                </Popover.Panel>
              </Popover>
            </FlexRow>

            <FlexRow className="my-4 phone:w-72 surfaceDuo:w-80 justify-between items-center">
              <div className="font-poppins font-regular text-primary-100 font-semibold">
                Button Colors:
              </div>
              <Popover className="relative mb-4">
                <Popover.Button>
                  <div className="rounded-lg px-2 py-1 border border-grey-400">
                    <i className="fas fa-circle mx-2" style={{ color: `${colorsButton}` }} />
                    <i className="fas fa-chevron-down mx-2" />
                  </div>
                </Popover.Button>
                <Popover.Panel className="absolute z-10 phone:right-0 medium:left-0">
                  <div className="bg-white shadow-xl p-3 rounded-lg border-2 border-primary-100 w-64 mobile:w-80">
                    <p className="font-semibold mb-2">Choose color:</p>

                    <Div className="grid grid-cols-5">
                      {ColorsList.map((color: any) => (
                        <div
                          className="w-6 h-6 m-2 rounded-full shadow-lg border-2 hover:border-secondary-200 border-white cursor-pointer"
                          key={color.hex}
                          onClick={() => setColorsButton(color.hex)}
                          style={{ backgroundColor: `${color.hex}` }}
                          title={color.name}
                        />
                      ))}
                    </Div>
                    <div
                      className="text-blue-100 hover:underline my-2 font-semibold cursor-pointer"
                      onClick={() => setMoreColors(!moreColors)}
                    >
                      More Colors
                      <i className="fas fa-angle-right ml-2" />
                      <i className="fas fa-angle-right" />
                    </div>

                    <FlexRow className={`mt-4 justify-start ${moreColors ? '' : 'hidden'}`}>
                      <label className="text-sm text-secondary-200" htmlFor="role">
                        Select:
                      </label>
                      <input
                        className="ml-4 h-7 border-2 border-primary-100 rounded-lg cursor-pointer"
                        name="role"
                        onChange={(e: any) => setColorsButton(e.target.value)}
                        required
                        type="color"
                        value={colorsButton}
                      />
                      <input
                        className="ml-4 pl-2 w-28 border-2 border-primary-100 rounded-lg"
                        onChange={(e: any) => setColorsButton(e.target.value)}
                        value={colorsButton}
                      />
                    </FlexRow>

                    <p className="mt-4 text-sm text-secondary-200">
                      ** Click anywhere on screen to close. **
                    </p>
                  </div>
                </Popover.Panel>
              </Popover>
            </FlexRow>

            <details className="my-4">
              <summary className="list-none">
                <Text className="text-blue-100 font-semibold hover:underline flex flex-row cursor-pointer">
                  FAQs | Complementary Colors
                  <i className="fas fa-question text-xs ml-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
                </Text>
              </summary>

              <div className="mt-4 mb-8 py-4">
                <Text className="text-secondary-200">
                  As a general rule, use between one and three colors from your logo design.
                </Text>

                <Text className="text-secondary-200 mt-4">
                  Since your brand identity contains your logo, be sure to pick at least one
                  dominant color to appear across all your branding assets.
                </Text>

                <Text className="text-secondary-200 mt-4">Sample color pairs:</Text>

                <div className="w-full overflow-x-scroll my-4">
                  <FlexRow className="w-max">
                    {ColorsPairs.map((pair: any, index: number) => (
                      <div
                        className="w-24 h-24 mr-2 font-semibold flex items-center justify-center cursor-pointer"
                        key={index}
                        onClick={() => {
                          setColorsFont(pair.font);
                          setColorsButton(pair.button);
                        }}
                        style={{ color: `${pair.font}`, backgroundColor: `${pair.button}` }}
                      >
                        Text
                      </div>
                    ))}
                  </FlexRow>
                </div>

                <div className="mr-auto phone:w-72 surfaceDuo:w-80 py-2 border border-grey-400">
                  <div
                    className="text-center font-semibold text-xl mb-2"
                    style={{ color: colorsFont }}
                  >
                    Sign Up
                  </div>
                  <div className="text-left ml-4" style={{ color: colorsFont }}>
                    Email:
                  </div>
                  <div className="w-72 mx-auto border py-4 px-2 rounded-lg text-secondary-200 mb-2">
                    Enter email
                  </div>
                  <div className="text-left ml-4" style={{ color: colorsFont }}>
                    Password:
                  </div>
                  <div className="w-72 mx-auto border py-4 px-2 rounded-lg text-secondary-200 mb-2">
                    Enter password
                  </div>
                  <div
                    className="w-72 mx-auto py-4 text-center font-semibold px-2 rounded-lg text-white mb-2"
                    style={{ backgroundColor: colorsButton }}
                  >
                    Sign Up Now
                  </div>
                </div>
              </div>
            </details>

            {isLoading ? (
              <button className="w-40 py-2 rounded-lg text-center block bg-primary-100 mt-2 text-white hover:bg-blue-100 mb-12">
                <i className="fad fa-spinner-third animate-spin" />
              </button>
            ) : (
              <>
                {dataColors ? (
                  <button
                    className="w-40 py-2 rounded-lg text-center block bg-primary-100 mt-8 text-white hover:bg-blue-100 mb-12"
                    onClick={onUpdateCustom}
                  >
                    UPDATE COLORS
                  </button>
                ) : (
                  <button
                    className="w-40 py-2 rounded-lg text-center block bg-primary-100 mt-8 text-white hover:bg-blue-100 mb-12"
                    onClick={onCreateCustom}
                  >
                    CUSTOM COLORS
                  </button>
                )}
              </>
            )}

            {dataFiles ? (
              <FlexRow className="flex-col w-full mb-8">
                <Text className="font-semibold text-primary-100 mb-4 mr-auto">My Files</Text>
                <Link
                  className="hover:underline text-blue-100 mb-2 mr-auto"
                  target="_blank"
                  to={`/files?path=${terms}&merchant=${user?.merchant_data?.info?.merchant_link}&merchantStore=${user?.merchant_data?.company[0]?.name}`}
                >
                  Click to view Terms of Service
                </Link>
                <Link
                  className="hover:underline text-blue-100 mb-2 mr-auto"
                  target="_blank"
                  to={`/files?path=${policy}&merchant=${user?.merchant_data?.info?.merchant_link}&merchantStore=${user?.merchant_data?.company[0]?.name}`}
                >
                  Click to view Privacy Policy
                </Link>
              </FlexRow>
            ) : (
              <></>
            )}

            <Text className="font-semibold text-primary-100">Upload Terms of Service</Text>
            <FlexRow className="border border-grey-400 pl-2 h-12 phone:w-72 surfaceDuo:w-80 rounded-lg my-2 justify-between items-center">
              <Text className="text-secondary-200 text-sm truncate">{file1[0].name}</Text>
              <button
                className="bg-grey-600 w-12 h-full"
                onClick={() => inputFile1?.current.click()}
                type="button"
              >
                <i className="fas fa-download text-secondary-200 mx-2" />
              </button>
              <input
                accept="application/pdf"
                className="hidden"
                onChange={(event) => setFile1(event.target.files)}
                ref={inputFile1}
                type="file"
              />
            </FlexRow>
            {file1[0].size > 2000000 ? (
              <Text className="text-sm text-red-100 italic mt-2 mb-4">
                * File exceeded the max. file size limit.
              </Text>
            ) : (
              <Text className="text-sm text-secondary-200 italic mt-2 mb-4">
                * Max. size 2MB for pdf
              </Text>
            )}

            <Text className="font-semibold text-primary-100 mt-4">Upload Privacy Policy</Text>
            <FlexRow className="border border-grey-400 pl-2 h-12 phone:w-72 surfaceDuo:w-80 rounded-lg my-2 justify-between items-center">
              <Text className="text-secondary-200 text-sm truncate">{file2[0].name}</Text>
              <button
                className="bg-grey-600 w-12 h-full"
                onClick={() => inputFile2?.current.click()}
                type="button"
              >
                <i className="fas fa-download text-secondary-200 mx-2" />
              </button>
              <input
                accept="application/pdf"
                className="hidden"
                onChange={(event) => setFile2(event.target.files)}
                ref={inputFile2}
                type="file"
              />
            </FlexRow>
            {file2[0].size > 2000000 ? (
              <Text className="text-sm text-red-100 italic mt-2 mb-4">
                * File exceeded the max. file size limit.
              </Text>
            ) : (
              <Text className="text-sm text-secondary-200 italic mt-2 mb-4">
                * Max. size 2MB for pdf
              </Text>
            )}

            {isLoading || isLoadingFile ? (
              <button className="w-40 py-2 rounded-lg text-center block bg-primary-100 mt-8 text-white hover:bg-blue-100 mb-12">
                <i className="fad fa-spinner-third animate-spin" />
              </button>
            ) : (
              <>
                {dataFiles ? (
                  <button
                    className="w-40 py-2 rounded-lg text-center block bg-primary-100 mt-2 text-white hover:bg-blue-100 mb-12"
                    onClick={onUpdateFile}
                  >
                    UPDATE FILES
                  </button>
                ) : (
                  <>
                    {file1[0].name === 'No Chosen file' || file2[0].name === 'No Chosen file' ? (
                      <button
                        className="w-40 py-2 rounded-lg text-center block bg-secondary-200 mt-2 text-white mb-12 cursor-not-allowed"
                        title="Upload file to proceed"
                      >
                        UPLOAD FILES
                      </button>
                    ) : (
                      <button
                        className="w-40 py-2 rounded-lg text-center block bg-primary-100 mt-2 text-white hover:bg-blue-100 mb-12"
                        onClick={onUploadFile}
                      >
                        UPLOAD FILES
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </Div>
        </Div>
      </>
      {/* {subscription.status === 'PRO PLAN' || subscription.status === 'FREE PLAN' ? (
        
      ) : (
        <div className="fixed z-10 w-screen h-screen bg-secondary-200">
          <div className="w-full">
            <ToastImage
              button="Upgrade"
              description="Upgrade Now to Use this feature"
              ImageAlt="Upgrade Xffiliate"
              ImageSource="upgrade"
              isOpen={isToastOpen2}
              onClose={onClose}
              submit={onUpgrade}
              title="You're on Free Plan"
              toastKind="info"
            />
          </div>
        </div>
      )} */}
    </>
  );
};
