import React, { FC } from 'react';

interface InputProps {
  className?: string;
  props?: any;
  placeholder?: string;
  label: string;
  containerClass?: string;
  onChange?: any;
  value?: string;
  name?: string;
  type?: string;
  maxlength?: number;
  pattern?: string;
}

export const InputWLength: FC<InputProps> = ({
  className,
  placeholder,
  label,
  props,
  containerClass,
  onChange,
  value,
  name,
  type,
  maxlength,
  pattern,
}) => {
  const onChangeInput = (event: any) => {
    if (type === 'file') {
      onChange(event.target.files);

    } else if (maxlength) {
      if (maxlength >= event.target.value?.length) {
        onChange(event.target.value);
      }
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <div
      {...props}
      className={`px-5 py-4 border border-secondary-100 rounded-lg mb-4 ${containerClass}`}
    >
      <details>
      <summary className="font-poppins font-bold laptop:text-sm phone:text-xs text-secondary-100 mb-1 list-none">
        {label}
        <i className="fas fa-question text-xs ml-2 px-1 rounded-full hover:bg-primary-100 hover:text-white border-2 border-primary-100" />
      </summary>

      <hr className='text-secondary-200 my-2' />
      {
        value?
        <p className={`text-sm my-2 ${value.length > 255 ? 'text-red-400' : 'text-green-100'}`}>
          {value?.length}/255 characters
        </p>
        :
        <p className='text-secondary-200 text-sm my-2'>
          0/255 characters
        </p>
      }
      <div className='text-secondary-200 text-sm italic mb-4'>
        Make a brief description of the product.
      </div>

      </details>
      <input
        accept='image/*'
        className={`outline-0 outline-none w-full border-0 font-poppins font-light 
      text-secondary-100 p-0 focus:outline-none border-transparent 
      focus:border-transparent focus:ring-0 laptop:text-sm phone:text-xs ${className}`}
        id={name}
        name={name}
        onChange={(event) => onChangeInput(event)}
        onWheel={(e: any) => e.target.blur()}
        pattern={pattern || ''}
        placeholder={placeholder}
        type={type || 'auto'}
        value={value}
      />
    </div>
  );
};