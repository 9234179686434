import React, { FC } from 'react';
import CurrencyInput from 'react-currency-input-field';

interface InputProps {
  className?: string;
  props?: any;
  placeholder?: string;
  label: string;
  containerClass?: string;
  onChange?: any;
  value: string;
  name?: string;
  currency: string;
}

export const InputPrice: FC<InputProps> = ({
  className,
  placeholder,
  label,
  props,
  containerClass,
  onChange,
  value,
  name,
  currency,
}) => {

  const [newValue, setNewValue] = React.useState(0);

  function getCurrencySign(sign: string) {
    switch (sign) {
      case 'peso':
        return <>&#8369;</>;
      case 'dollar':
        return <>&#36;</>;
      case 'pound':
        return <>&#163;</>;
      default:
        return <>&#8369;</>;
    }
  }

  React.useEffect(() => {
    if(parseFloat(value) > 0){
      setNewValue(parseFloat(value))
    }else{
      setNewValue(0)
    }
  }, [value])

  return (
    <div
      {...props}
      className={`px-5 py-4 border border-secondary-100 rounded-lg mb-4 ${containerClass}`}
    >
      <p className="font-poppins font-bold laptop:text-sm phone:text-xs text-secondary-100 mb-1">
        {label}
      </p>
      <div className='w-full flex flex-row justify-between items-center'>
        <div className='w-1/12 font-bold'>
          {getCurrencySign(currency)}
        </div>
        <CurrencyInput
          className={`outline-0 outline-none w-11/12 border-0 font-poppins font-light 
        text-secondary-100 p-0 mt-1 focus:outline-none border-transparent 
        focus:border-transparent focus:ring-0 laptop:text-sm phone:text-xs ${className}`}
          decimalsLimit={2}
          defaultValue={0}
          name={name}
          onValueChange={(event) => onChange(event)}
          placeholder={placeholder}
          value={newValue}
        />
      </div>
    </div>
  );
};