import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { API } from '@config';
import { getCookie } from '@hooks';

export const getPartners = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-all-partners`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getPartnersAdmin = async (id: number) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-all-admin-partners/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getApplicants = async (productId: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-applicants/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliatedProducts = async (userId: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliates-product-by-merchant/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getAffiliatedProductsAdmin = async (userId: string) => {
  const token = getCookie('token');
  const user = useSelector(selectUser);
  const merchantId: string = user.user_info.merchant_id
  return axios
    .get(`${API}/get-affiliate-products-by-merchant/${merchantId}/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getPartnerAffiliates = async () => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-affiliate-products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const getProductGraphData = async (productId: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/get-graph-data/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then((response) => response)
};

export const getAffiliateOfMerchantFromAdmin = async (id: string) => {
  const token = getCookie('token');
  return axios
    .get(`${API}/admin-get-affiliates-by-merchant-id/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};