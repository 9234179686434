import React, { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '@redux';
import { UserDropdown as MenuItems, AffiliateDrawers } from '@constants';
import { FlexRow } from '@core';
import { useSubscription } from '@hooks';

export const UserDropdown: FC<any> = ({ info, user, isWhite, noHidden }) => {

  const user1 = useSelector(selectUser);
  const subscription = useSubscription();

  return(
  <div className={`w-64 text-right ${noHidden ? '' : 'phone:hidden tabletWide:block'}`}>
    <Menu as="div" className="w-64 relative inline-block text-left">
      <div>
        <Menu.Button
          className={`flex flex-row items-center justify-center w-full px-5
             py-0.5 font-poppins text-base font-normal  rounded-md hover:bg-opacity-20 
            ${
              !isWhite
                ? 'text-white bg-primary-500 ring-primary-500 ring-4'
                : 'text-primary-100 bg-white ring-primary-500 ring-1'
            }`}
        >
          <i
            className={
              info.role === 'merchant' ? 'fas fa-store text-2xl mr-4' : 'fas fa-user text-2xl mr-4'
            }
          />
          <div className="flex flex-col">
            <p className="mr-auto truncate text-ellipsis text-left w-40">
              {info?.role === 'merchant'
                ? `${user?.merchant_data?.company[0]?.name}`
                : ''}
              {info?.role === 'affiliate' && user1?.affiliate_company_info !== null
                ? `${user1?.affiliate_company_info?.company_name}`
                : ''}
              {info?.role === 'affiliate' && user1?.affiliate_company_info === null
                ? `${info?.firstname} ${info?.lastname}`
                : ''}
            </p>
            <span className="text-sm font-light text-left">{info?.role.toUpperCase()}</span>
          </div>
          <i className="fas fa-chevron-down ml-2" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {info?.role === 'merchant' ? (
          <Menu.Items
            className={`absolute right-0 mt-1 origin-top-right font-poppins
            bg-white divide-y w-64 divide-secondary-300 
            rounded-md shadow-lg`}
          > 
            <div className='w-full'>
              <div className='p-1 group flex rounded-md items-center
                      w-full px-5 py-3 text-base font-semibold'>
                {subscription.status}
              </div>
              <FlexRow className='w-full justify-center px-4'>
                {
                  subscription.status === 'FREE PLAN'?
                  <Link className='px-5 py-3 mt-2 mb-4 text-white bg-orange-100 text-center w-full' to='/merchant/subscription'>
                    Upgrade My Plan
                  </Link>
                  :
                  <></>
                }
                {
                  subscription.status === 'PLUS PLAN'?
                  <Link className='px-5 py-3 mt-2 mb-4 text-white bg-green-100 text-center w-full' to='/merchant/subscription'>
                    Upgrade to PRO
                  </Link>
                  :
                  <></>
                }
                {
                  subscription.status === 'PRO PLAN'?
                  <button className='px-5 py-3 mt-2 mb-4 text-white bg-green-100 text-center w-full hidden'>
                    YOU ARE SUBSCRIBED
                  </button>
                  :
                  <></>
                }
              </FlexRow>
            </div>            
            {MenuItems.map((value) => (
              <div className="px-1 py-1 " key={value.name}>
                {value.links.map((link) => (
                  <Menu.Item key={link.name}>
                    {({ active }) => (
                      <Link
                        className={`${
                          active ? 'bg-primary-100 text-white' : 'text-secondary-100'
                        }  group flex rounded-md items-center
                    w-full px-4 py-3 text-base`}
                        to={link.url}
                      >
                        {link.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            ))}
          </Menu.Items>
        ) : (
          <Menu.Items
            className={`absolute right-0 mt-1 origin-top-right font-poppins
            bg-white divide-y w-64 divide-secondary-300 
            rounded-md shadow-lg`}
          >
            {AffiliateDrawers.map((value) => (
              <div className="px-1 py-1 " key={value.name}>
                {value.links.map((link) => (
                  <Menu.Item key={link.name}>
                    {({ active }) => (
                      <Link
                        className={`${
                          active ? 'bg-primary-100 text-white' : 'text-secondary-100'
                        }  group flex rounded-md items-center
                    w-full px-4 py-3 text-base`}
                        to={link.url}
                      >
                        {link.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            ))}
          </Menu.Items>
        )}
      </Transition>
    </Menu>
  </div>
)};