import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { batch } from 'react-redux';
import html2canvas from 'html2canvas';
import { partnersActions, useAppDispatch } from '@redux';
import { FlexRow, Button, Div, Text, Icons, FlexCenter, Toast } from '@core';
import { SHARE } from '@config';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  data: any;
  selectedPartner: any;
}

export const AffiliateInfoModal: FC<ModalProps> = ({ isOpen, onClose, data, selectedPartner }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');
  const [download, setDownload] = React.useState(false);
  const [copied, setCopied] = React.useState<any>(false);

  React.useEffect(() => {
    setCopied(false);
  }, [isOpen]);

  const handleDownloadImage = async () => {
    setDownload(true);
    const element: any = document.getElementById('qrcode');
    const canvas = await html2canvas(element);
    const imageURI = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    link.href = imageURI;
    link.download = 'QRCODE.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownload(false);
  };

  const onSubmit = () => {
    batch(async () => {
      setLoading(true);
      const res: any = await dispatch(partnersActions.removePartner(data?.affiliate_id));

      if (res?.payload?.data?.status === 1) {
        setToastTitle('Affiliate Removed');
        setToastDesc('Affiliate is successfully removed.');
        setToastKind('success');
        setToastOpen(true);
        setLoading(false);
        setTimeout(() => {
          setToastOpen(false);
          onClose();
          window.location.reload();
        }, 1000);
      } else {
        setToastTitle('ERROR');
        setToastDesc(res?.payload?.message || 'Something went wrong. Try again.');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200 font-poppins"
          onClose={() => {
            onClose();
            navigate('/merchant/dashboard');
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">DETAILS</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate('/merchant/dashboard');
                    }}
                  />
                </FlexRow>
                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide">
                  <Text className="font-bold text-lg text-grey-100 ml-2 mt-5">
                    Referral Overview
                  </Text>
                  <FlexRow className="px-5 py-6 bg-light-200 rounded-lg mt-5">
                    <i className="fas fa-hand-holding-usd mr-4 text-3xl text-orange-100" />
                    <Div>
                      <Text className="text-lg font-bold text-primary-100">
                        PHP{' '}
                        {data?.earnings
                          ? parseFloat(data?.earnings)
                              ?.toFixed(2)
                              ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')
                          : '0.00'}
                      </Text>
                      <Text className="text-lg font-normal text-grey-100">Earnings</Text>
                    </Div>
                  </FlexRow>
                  <FlexRow className="px-5 py-6 bg-light-200 rounded-lg mt-5">
                    <i className="fas fa-hand-pointer mr-4 text-3xl text-orange-100" />
                    <Div>
                      <Text className="text-lg font-bold text-primary-100">
                        {data?.total_cliked}
                      </Text>
                      <Text className="text-lg font-normal text-grey-100">Clicks</Text>
                    </Div>
                  </FlexRow>
                  <FlexRow className="px-5 py-6 bg-light-200 rounded-lg mt-5">
                    <i className="fas fa-repeat mr-4 text-3xl text-orange-100" />
                    <Div>
                      <Text className="text-lg font-bold text-primary-100">{data?.conversion}</Text>
                      <Text className="text-lg font-normal text-grey-100">Conversions</Text>
                    </Div>
                  </FlexRow>
                  {/* <Text className="font-bold text-lg text-grey-100 ml-2 mt-10">
                    Partner Details
                  </Text>
                  <Text className="font-bold text-xl text-primary-100 ml-2 mt-5">
                    {selectedPartner?.firstname} {selectedPartner?.lastname}
                  </Text>
                  <Text className="font-semibold text-lg text-secondary-100 mt-2 ml-2">
                    {selectedPartner?.email}
                  </Text>
                  <Text className="font-semibold text-lg text-secondary-100 ml-2">
                    {selectedPartner?.contact}{' '}
                  </Text> */}
                  <Text className="font-bold text-lg text-grey-100 ml-2 mt-10">
                    Referral Details
                  </Text>
                  <FlexCenter className="mt-5 mb-5">
                    <Text className="font-semibold text-lg mb-3 text-blue-100 select-none truncate text-ellipsis w-80">
                      {`${SHARE}${data?.affiliate_link}`}
                    </Text>

                    <QRCode
                      bgColor="#ffffff"
                      fgColor="#223141"
                      id="qrcode"
                      imageSettings={{
                        src: Icons.xffiliateQrcode,
                        height: 80,
                        width: 80,
                      }}
                      includeMargin={false}
                      level="H"
                      renderAs="canvas"
                      size={280}
                      value={`${SHARE}${data?.affiliate_link}`}
                    />
                    <Button
                      className={`w-full justify-center items-center mt-5 ${
                        copied ? 'bg-green-100' : 'bg-blue-100'
                      }`}
                      onClick={() => {
                        setCopied(true);
                        navigator.clipboard.writeText(`${SHARE}${data?.affiliate_link}`);
                      }}
                    >
                      {copied ? 'COPIED' : 'COPY LINK'}
                    </Button>
                    <Button
                      className="w-full justify-center items-center mt-2 bg-blue-100"
                      isLoading={download}
                      onClick={handleDownloadImage}
                    >
                      DOWNLOAD
                    </Button>
                  </FlexCenter>

                  <Text className="font-bold text-lg text-grey-100 ml-2 mt-5">Product Details</Text>
                  {/* <Div className="w-full mt-4 bg-grey-500 rounded-3xl">
                    <img
                      alt="images"
                      className="object-fill h-full w-full rounded-3xl"
                      src={product?.image}
                    />
                  </Div> */}
                  <Text className="font-bold text-xl text-primary-100 ml-2 mt-5">
                    {data?.name || ''}
                  </Text>
                  <Text className="font-normal text-base text-secondary-100 ml-2 mt-2">
                    {data?.description || ''}
                  </Text>
                  <Text className="font-semibold text-lg text-secondary-100 mt-2 ml-2">
                    {data?.price ? (
                      <>
                        PHP{' '}
                        {parseFloat(data?.price)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      </>
                    ) : (
                      ''
                    )}
                  </Text>
                  <Text className="font-semibold text-lg text-secondary-100 ml-2">
                    {data?.commission ? <>{data?.commission}% Commission</> : ''}
                  </Text>

                  <Text className="font-bold text-lg text-grey-100 ml-2 mt-10">
                    Remove Affiliated Product
                  </Text>
                  <FlexRow
                    className="px-5 py-6 bg-red-300 font-roboto
                  text-red-100 rounded-lg mt-5 mb-5"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      This will remove {data?.name || ''} from {selectedPartner?.firstname}.
                    </Text>
                  </FlexRow>
                  <Button
                    className="w-full justify-center items-center bg-red-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    REMOVE NOW
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
