import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Div, Text, Flex, FlexRow } from '@core';
import { PaymentData, Pagination} from '@components';
import { getCookie } from '@hooks';
import { API } from '@config';

export const TransactionsAdmin: FC<any> = ({ user }) => {

  const navigate = useNavigate();
  const token = getCookie('token');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const [SortList, setSortList] = React.useState<any>([]);
  const [transHistory, setTransHistory] = React.useState(0);
  const [loadingTrans, setLoadingTrans] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentHistory = SortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const randomID = new Date();
  const fileName = randomID.getTime();

  const [csvData, setCsvData] = React.useState([]);

  const headers: any = [
    { label: "Transaction Date", key: "date" },
    { label: "Reference No.", key: "reference_id" },
    { label: "Total Amount", key: "total_amount" },
    { label: "Bank Name", key: "payment_channel" },
    { label: "Payment Type", key: "payment_method" },
    { label: "Status", key: "status" }
  ];

  React.useEffect(() => {
    if(actionQuery === 'transaction'){
      navigate('/merchant/admin_dashboard/manager/settings')
    }
  })

  React.useEffect(() => {
    setLoadingTrans(true)
    fetch(`${API}/transaction-history`,{
      method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(data => {
        if(data.data.length > 0){
          setTransHistory(1)
          setSortList(data.data)
          setCsvData(data.data)
        }else{
          setTransHistory(0)
        }
        
        setTimeout(() => {
          setLoadingTrans(false)
        }, 1500)
      })
  }, [token])

  return(
  <>
  <Flex className='desktop:pl-16 phone:pt-0 laptop:pr-20 phone:px-5'>
      <FlexRow className='border-b border-grey-400 mb-10 pb-2 w-full justify-between mt-8'>
        <Text className="font-poppins font-semibold text-xl text-primary-100">
            Transaction History
        </Text>
        {
          csvData.length > 0?
          <CSVLink data={csvData} filename={`transactions-${fileName}.csv`} headers={headers}>
            <div className='font-semibold text-primary-100 hover:underline'>
              <i className="fas fa-download text-blue-100 mx-2 phone:hidden surfaceDuo:inline"/>
              <span className='hidden tablet:inline'>Export to CSV</span>
            </div>
          </CSVLink>
          :
          <div className='font-semibold text-primary-100 hover:underline cursor-not-allowed'>
            <i className="fas fa-download text-blue-100 mx-2"/>
            <span className='hidden tablet:inline'>Export to CSV</span>
          </div>
        }
      </FlexRow>

            <Div className='hidden medium:flex medium:flex-row tablet:hidden tabletWide:flex'>
      
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Type
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Date
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Reference No.
                </Text>
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Amount
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Status
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center "
                >
                  Action
                </Text>

            </Div>
        <hr className="hidden medium:block tablet:hidden tabletWide:block text-grey-400 my-4" />

        {
          transHistory > 0 && !loadingTrans ?
          <>
          <Div className='w-full'>
          {
            currentHistory.map((list: any) =>(
              <PaymentData
                amount={list.total_amount}
                id={list.id}
                key={list.id}
                paymentDate={list.date}
                paymentType={list.payment_channel}
                referenceNum={list.reference_id}
                status={list.status}
                user={user}
              />
              ))
          }
          </Div>
          <div className="flex justify-center mb-5">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={SortList.length}
            />
          </div>   
          </>
          :
          <>
          {
            loadingTrans?
            <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                <Text>
                  Loading data ...
                  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                </Text>
            </Div>
            :
            <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                <Text>
                  No data to be displayed for transaction history.
                </Text>
            </Div>
          }
          </>
        }

  </Flex>
  </>
  )
};