import React from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Div, Text } from '@src/core';
import { checkImageType, checkMessageType } from '@src/helpers/checkNotifType';
import { classNames } from "@src/helpers";

interface notifCard {
    category: string,
    affiliateName: string | null,
    affiliateCompany: string,
    merchantName: string,
    productName: string | null,
    createdAt: string,
    read: string | null,
    merchant: boolean,
    notifLink: string,
    notifLink2: string,
    role?: string | null,
}

export const NotificationCard = ({
    category, affiliateName, affiliateCompany, productName, merchantName,
    createdAt, read, merchant, notifLink, notifLink2, role
}:notifCard
) => {

    const [link, setLink] = React.useState('/');

    React.useEffect(() => {
        if(category === 'disbursement'){
            setLink(notifLink2)
        }else if(category === 'conversion created'){
            setLink(`${notifLink}?notification=${productName}&action=notif&type=conversion`)
        }else if(category === "affiliate categorized" || category === "affiliate update category" || category === "affiliate remove category" || category === "category removed" || category === "category update" || category === "affiliate removed"){
            setLink('/affiliate/settings')
        }else if(category === 'affiliate marketing asset' || category === 'new marketing asset' || category === 'marketing asset approved' || category === 'marketing asset denied' || category === 'marketing asset removed'){
            setLink(`${notifLink}?notification=${productName}&action=notif&type=asset`)
        }else{
            setLink(`${notifLink}?notification=${productName}&action=notif&type=apply`)
        }
    }, [category])

return(
    <>
    {
        merchantName === null?
        <></>
        :
        <Link className={classNames('flex flex-row hover:bg-blue-300 cursor-pointer w-full px-4 py-5 grid grid-cols-4 gap-x-3', read === "1" ? 'bg-white' : 'bg-grey-900')} to={link}>
            {
                category === "affiliate categorized" ||
                category === "affiliate update category" ||
                category === "category update" ?
                <div className='relative col-span-1 place-self-center'>
                <i className="fas fa-user text-primary-100 text-4xl"/>
                <i className="fas fa-check-circle text-green-100 absolute bottom-0 right-0 z-50"/>
                </div>
                :
                <></>
            }
            {
                category === "affiliate remove category" ||
                category === "category removed" ?
                <div className='relative col-span-1 place-self-center'>
                <i className="fas fa-user text-primary-100 text-4xl"/>
                <i className="fas fa-times text-red-400 absolute bottom-0 right-0 z-50"/>
                </div>
                :
                <></>
            }
            {
                category === 'approved' ||
                category === 'approve' ||
                category === 'denied' ||
                category === 'reject' ||
                category === 'applied' ||
                category === 'conversion created' ||
                category === 'disbursement' ||
                category === 'product update' ||
                category === 'product removed' ||
                category === "affiliate removed" ||
                category === 'affiliate marketing asset' ||
                category === 'marketing asset denied' ||
                category === 'marketing asset approved' ||
                category === 'marketing asset removed' ||
                category === 'new marketing asset'?
                <img alt='icon' className='col-span-1  place-self-center' src={checkImageType(category)} width={30}/>
                :
                <></>
            }
            
            <Div className='col-span-3 grid grid-rows-3 gap-y-3'>
                <Div className="row-span-2">
                    <p className="w-full">
                        {
                            category === 'conversion created' && !merchant ?
                            <b className="inline font-semibold">Congratulations!</b>
                            :
                            <b className="inline font-semibold">{merchant ? `${affiliateName === 'none, none' ? affiliateCompany : affiliateName}` : merchantName}</b>
                        }
                        {
                            category === 'disbursement'?
                            <p className='inline font-light'>&nbsp;{checkMessageType(category)}.</p>
                            :
                            <>
                                <p className='inline font-light'>&nbsp;{checkMessageType(category)}&nbsp;-&nbsp;</p>
                                <b className="inline font-semibold">
                                {
                                    category === "affiliate categorized" ||
                                    category === "affiliate update category" ||
                                    category === "affiliate remove category" ||
                                    category === "category removed" ||
                                    category === "category update"
                                    ? role
                                    : productName
                                }
                                .</b>
                            </>
                        }
                        {
                            category === 'conversion created' && !merchant ?
                            <span>
                                &nbsp;of&nbsp;<b className="inline font-semibold">{merchantName}.</b>
                            </span>
                            :
                            <></>
                        }
                        
                    </p>
                </Div>
                <Text className='row-span-1 text-sm text-grey-100 font-light'>{moment(createdAt).fromNow()}</Text>
            </Div>
        </Link>
    }
    </>
)
};