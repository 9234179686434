import React from 'react';
import { Link } from 'react-router-dom';

import { Flex, Div, Icons, Text, Button } from '@core';

export const EmailError = () => (
  <Flex className="phone:px-5 tablet:px-10 laptop:px-20 items-center">
    <Div className="mr-auto flex flex-row items-center py-8">
      <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
      <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">Xffiliate</Text>
    </Div>
    <hr className="text-secondary-300 w-full" />
    <Flex className="items-center laptop:pt-32 phone:pt-20 tablet:w-500px phone:w-full">
      <Text className="font-semibold text-secondary-100 text-2xl mb-5">Invalid Request!</Text>
      <Text
        className="font-poppins font-bold laptop:text-4xl phone:text-3xl text-primary-100
          text-center mb-10"
      >
        Email <span className="text-orange-100"> confimation</span> is not valid! Please try again!
      </Text>

      <Text className="mb-10 text-center">
        Your email confimation is either expire or not valid! If you encounter a error please try to
        resend the email confirmation. Thank you!
      </Text>
      <Link to="/">
        <Button className="px-20">Go Back</Button>
      </Link>
    </Flex>
  </Flex>
);
