import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { batch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FlexRow, Text, Div } from '@core';
import { useSubscription, getCookie } from '@hooks';
import { API } from '@config';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import { Days, MonthsFull } from '@constants';

interface ModalProps {
  isOpen: boolean;
  onClose: any;
}

export const AccountInformationModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const subscription = useSubscription();
  const token = getCookie('token');
  const [totalAff, setTotalAff] = React.useState<any>(0);

  const newDate: any = new Date(user.user_info?.created_at);

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profile());
    });
  }, [token]);

  React.useEffect(() => {
      fetch(`${API}/get-affiliate-table`, {
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`}
      })
      .then(res => res.json())
      .then(data => {
        setTotalAff(data.data.length)
      })
  }, [token])

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
        onClose={() => {
          onClose();
          if (user?.user_info?.role === 'merchant') {
            navigate('/merchant/dashboard');
          } else {
            navigate('/affiliate/catalogs');
          }
        }}
      >
        <div className="h-full text-center flex justify-end">
          {/* <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child> */}

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div
              className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
            >
              <FlexRow
                className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
              >
                <Dialog.Title as="h3">ACCOUNT</Dialog.Title>
                <i
                  className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                  onClick={() => {
                    onClose();
                    if (user?.user_info?.role === 'merchant') {
                      navigate('/merchant/dashboard');
                    } else {
                      navigate('/affiliate/dashboard');
                    }
                  }}
                />
              </FlexRow>
              <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide">
                <Text className="font-poppins font-medium text-xl text-primary-100 ">
                  Account Information
                </Text>
                <Text className="font-poppins font-semibold text-lg text-secondary-100 mt-5">
                  Hello {`${user.user_info.firstname} ${user.user_info.lastname}!`}
                </Text>
                <Text className="font-poppins font-normal text-base text-grey-100 mt-3">
                  Your workspace was created on
                </Text>
                <Text className="font-poppins font-bold text-base text-grey-100 mt-1 mb-5">
                  {Days[newDate.getDay()]}, 
                  {MonthsFull[newDate.getMonth()]} 
                  {new Date(user.user_info?.created_at).toString().substring(8, 11)}, 
                  {new Date(user.user_info?.created_at).toString().substring(11, 15)}
                </Text>
                {user?.user_info?.role === 'merchant' ? (
                  <>
                    <Div className="flex flex-col justify-center items-center mt-10">
                      <Text className="font-poppins font-normal text-lg text-grey-100 ">
                        You are currently on the
                      </Text>
                      <Text className="font-poppins font-semibold text-xl text-blue-100">
                        {subscription.status}
                      </Text>
                    </Div>
                    <Div className="mt-10">
                      <FlexRow>
                        <Text className="font-poppins font-semibold text-base text-primary-100 mr-auto">
                          Products
                        </Text>
                        {
                          subscription.product.total >= subscription.product.limit && subscription.status !== 'PRO PLAN' ?
                          <Text className="font-poppins font-semibold text-base text-red-400">
                            Limit Reached
                          </Text>
                          :
                          <Text className="font-poppins font-semibold text-base text-secondary-100">
                            {subscription?.product?.total}/
                            {subscription.status === 'PRO PLAN'? 'Unlimited' : subscription?.product?.limit}
                          </Text>
                        }
                      </FlexRow>
                      <div className="w-full bg-grey-400 overflow-hidden rounded-full h-3 mt-3">
                        <div
                          className='bg-orange-100 h-2.5 rounded-full'
                          style={{
                            width: `${subscription.product.remaining}%`,
                          }}
                        />
                      </div>
                    </Div>

                    <Div className="mt-5">
                      <FlexRow>
                        <Text className="font-poppins font-semibold text-base text-primary-100 mr-auto">
                          Affiliates
                        </Text>

                        {
                          subscription.status === 'FREE PLAN' && totalAff >= 15 ?
                          <Text className="font-poppins font-semibold text-base text-red-400">
                            Limit Reached
                          </Text>
                          :
                          <Text className="font-poppins font-semibold text-base text-secondary-100">
                            {totalAff}/
                            {subscription.status === 'PRO PLAN' || subscription.status === 'PLUS PLAN'? 'Unlimited' : ''}
                            {subscription.status === 'FREE PLAN'? '15' : ''}
                          </Text>
                        }

                      </FlexRow>
                      <div className="w-full bg-grey-400 overflow-hidden rounded-full h-3 mt-3">
                        <div
                          className='bg-orange-100 h-2.5 rounded-full'
                          style={{
                            width: `${
                              subscription.status === 'FREE PLAN' ? totalAff*6.66 : 100 
                            }%`,
                          }}
                        />
                      </div>
                  
                    </Div>

                    <Div className="mt-5">
                      <FlexRow>
                        <Text className="font-poppins font-semibold text-base text-primary-100 mr-auto">
                          Affiliates Links
                        </Text>
                        <Text className="font-poppins font-semibold text-base text-secondary-100">
                          {subscription.totalLinks}
                        </Text>
                      </FlexRow>
                      <div className="w-full bg-grey-400 overflow-hidden rounded-full h-3 mt-3">
                        <div className="bg-orange-100 h-3 rounded-full" style={{ width: `100%` }} />
                      </div>
                    </Div>

                    <Text className="font-poppins font-normal text-base text-grey-100 mt-10">
                      Your subscription will expire on:
                    </Text>
                    <Text className="font-poppins font-bold text-base text-grey-100 mt-1 mb-5">
                      {subscription.status !== 'FREE PLAN' ? user?.subscription?.date_of_expiration.toString().substring(0, 10) : 'Free Forever'}
                    </Text>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* {user?.user_info?.role === 'merchant' ? (
                <>
                  <hr />
                  <div className=" flex  mx-8 my-5">
                    <Button className="w-full justify-center items-center bg-blue-100">
                      EXTEND SUBSCRIPTION
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )} */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};