import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { batch } from 'react-redux';
import { Div, Text, FlexRow } from '@core';
import { NavBarAffiliate, ProductDetails } from '@components';
import { getCommissionDetails } from '@services';
import { formatDate2, formatTime } from '@helpers';

export const CommissionDetails: FC<any> = () => {

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const external = query.get('external') ?? 'N/A';
  const amount = query.get('value') ?? '0';

  const [transRef, setTransRef] = React.useState(' - -  - - ');
  const [transFees, setTransFees] = React.useState('0');
  const [grossAmt, setGrossAmt] = React.useState('0');
  const [netAmt, setNetAmt] = React.useState('0');

  const [bank, setBank] = React.useState('');
  const [dateReceived, setDateReceived] = React.useState('2022-01-01 00:00:00')

  const [transaction, setTransaction] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);

  React.useEffect(() => {
    batch(async () => {
      setLoading(true)
      const data: any = await getCommissionDetails(external || '0').catch(err => {
        console.log(err);
        setInvalid(true)
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      });

      if (data?.data?.status === 1) {
        const fees: number = data?.data?.type === 'MANUAL' ? 0 : 16;
        const newAmount: number = parseFloat(amount || '0') + fees;

        setInvalid(false)
        setDateReceived(data?.data?.date_received)
        setBank(data?.data?.deposited_to)
        setTransaction(data?.data?.data)
        setTransRef(data?.data?.transaction_id)
        setNetAmt(amount)
        setGrossAmt(newAmount.toString())
        setTransFees(fees.toString())
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }
    })
  }, [])

  return (
    <>
      <NavBarAffiliate />

      {
        loading ?
          <Div className='fixed top-0 left-0 right-0 bottom-0 z-60 h-screen w-screen flex justify-center items-center bg-secondary-200'>
            <Text className='text-xl text-white font-semibold items-center flex flex-row'>
              Please wait...
              <i className="fad fa-spinner-third animate-spin text-2xl mx-4" />
            </Text>
          </Div>
          :
          <Div className='flex pt-24 phone:flex-col tabletWide:flex-row w-full h-full'>

            <Div className='phone:w-full phone:px-2 surfaceDuo:px-4 tabletWide:w-64 flex justify-start tabletWide:justify-center mb-3 mt-3 tabletWide:mt-12'>
              <Link
                className='font-semibold text-primary-100'
                to='/affiliate/settings?action=commission'
              >
                <i className="fas fa-angle-left" />
                <i className="fas fa-angle-left mr-3" />
                Back to Settings
              </Link>
            </Div>

            <Div
              className="overflow-y-scroll phone:px-2 surfaceDuo:px-4 tabletWide:px-8 flex flex-col w-full"
            >
              <Text className="font-poppins font-semibold text-xl mb-3 text-primary-100 mt-8 tabletWide:mt-12">
                Commission Overview
              </Text>

              <Text className='text-secondary-200 mt-4'>
                Reference ID: <b>{transRef}</b>
              </Text>

              <Text className='text-secondary-200 my-4'>
                A summarized breakdown of your current commission received as of
                {` ${formatDate2(dateReceived.substring(0, 10))} at ${formatTime(dateReceived.substring(10, 18))}`}.
              </Text>

              <FlexRow className='phone:flex-col surfaceDuo:flex-row surfaceDuo:w-500px py-2 bg-grey-500 mt-4'>
                <Text className='text-secondary-200 text-base px-2 phone:w-full surfaceDuo:w-250px'>
                  Gross Total:
                </Text>
                <Text className='phone:w-full surfaceDuo:w-250px surfaceDuo:text-right text-primary-100 px-2'>
                  &#8369; {parseFloat(grossAmt).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </FlexRow>
              <FlexRow className='phone:flex-col surfaceDuo:flex-row surfaceDuo:w-500px py-2 bg-grey-500'>
                <Text className='text-secondary-200 text-base px-2 phone:w-full surfaceDuo:w-250px'>
                  Transaction fees:
                </Text>
                <Text className={`phone:w-full surfaceDuo:w-250px surfaceDuo:text-right px-2 ${parseFloat(transFees) > 0 ? 'text-red-100' : 'text-secondary-200'}`}>
                  - &#8369; {parseFloat(transFees).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </FlexRow>

              <div className='w-full bg-grey-500 surfaceDuo:w-500px'>
                <hr className='text-grey-400 my-4' />
              </div>

              <FlexRow className='phone:flex-col surfaceDuo:flex-row surfaceDuo:w-500px py-2 surfaceDuo:items-start bg-grey-500'>
                <Div className='mr-auto w-full'>
                  <Text className='text-secondary-200 text-lg laptop:text-xl font-semibold px-2 phone:w-full surfaceDuo:w-250px'>
                    Net Amount Received:
                  </Text>
                  <Text className='text-secondary-200 text-xs px-2 phone:w-full'>
                    (This is the amount credited to your account)
                  </Text>
                </Div>
                <Text className='text-lg laptop:text-xl font-bold phone:w-full surfaceDuo:w-250px surfaceDuo:text-right text-primary-100 px-2'>
                  &#8369; {parseFloat(netAmt).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </FlexRow>
              <FlexRow className='phone:flex-col surfaceDuo:flex-row surfaceDuo:w-500px py-2 bg-grey-500'>
                <Text className='text-secondary-200 text-base px-2 phone:w-full surfaceDuo:w-250px'>
                  Deposited To:
                </Text>
                <Text className='font-bold phone:w-full surfaceDuo:w-250px surfaceDuo:text-right text-primary-100 px-2'>
                  {bank}
                </Text>
              </FlexRow>

              {
                invalid && !loading ?
                  <Text className='text-red-100 my-4'>
                    <b className='mr-2'>ERROR:</b>
                    The transaction {transRef} does not exist.
                  </Text>
                  :
                  <></>
              }

              <FlexRow className='w-full items-center justify-between mb-4 mt-8 hidden'>
                <Text className="font-poppins font-semibold text-primary-100">
                  Amount Distribution
                </Text>
                <Div className='hidden'>
                  <i className="fas fa-download text-primary-100 mx-2" />
                  <Link className='hidden medium:inline text-primary-100 font-bold' download target='_blank' to='/report.pdf'>
                    Download
                  </Link>
                </Div>
              </FlexRow>
              <hr className="text-grey-400 mb-10 hidden" />

              <Text className="font-poppins font-semibold text-primary-100 text-lg mt-8 tabletWide:mt-14 mb-4 tabletWide:mb-0">
                Commission Breakdown Report
              </Text>

              <Div className='hidden tabletWide:flex tabletWide:flex-row mt-12'>
                <Text
                  className=" text-base text-secondary-200 font-semibold w-300px text-left"
                >
                  Product
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-semibold w-400px text-left"
                >
                  Product Link
                </Text>
                <Text
                  className=" text-base text-secondary-200 font-semibold w-300px text-left pl-10"
                >
                  Amount
                </Text>
              </Div>

              <Div className='w-full tabletWide:w-1000px'>
                <hr className="hidden tabletWide:block text-grey-400 my-4" />
              </Div>

              {
                transaction.length > 0 && !loading ?
                  <>
                    {
                      transaction.map((product: any, index: number) => (
                        <ProductDetails
                          commission={parseFloat(product?.earned)}
                          id={product?.product_id}
                          key={index}
                          link={product?.product_link}
                          name={product?.product_name}
                        />
                      ))
                    }
                  </>
                  :
                  <>{
                    loading ?
                      <FlexRow className="w-full justify-center py-4">
                        <Text>
                          Loading data ...
                          <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                        </Text>
                      </FlexRow>
                      :
                      <FlexRow className="w-full justify-center py-8 my-4 bg-grey-500">
                        <Text>
                          No product listed.
                        </Text>
                      </FlexRow>
                  }
                  </>
              }

            </Div>

          </Div>
      }
    </>
  )
};