import React, { FC } from 'react';
import { Popover } from '@headlessui/react';
import { Text, FlexRow, Div, ToastInputMultipleV2 } from '@core';
import { PaginationV2, AccountData } from '@components';
import { API } from '@config';
import { getCookie } from '@hooks';

export const AccountDashboard: FC<any> = ({ loading, readAccounts, writeAccounts }) => {

	const token = getCookie('token');
	const [isShow, setIsShow] = React.useState(true);
	const [isToastOpen5, setToastOpen5] = React.useState(false);
	const [toastKind5, setToastKind5] = React.useState('success');
	const [toastTitle5, setToastTitle5] = React.useState('');
	const [toastDesc5, setToastDesc5] = React.useState('');

	const [selected, setSelected] = React.useState('name');
	const [list, setList] = React.useState<any[]>([])
	const [mainList, setMainList] = React.useState<any[]>([])
	const resultList: any[] = [];
	const emptyList: any[] = [];

	const [search, setSearch] = React.useState('');
	const [searchComp, setSearchComp] = React.useState('')
	const [searchEmail, setSearchEmail] = React.useState('')

	const [searchOpen, setSearchOpen] = React.useState(true);
	const [resultDesc, setResultDesc] = React.useState('');

	const [currentPage, setCurrentPage] = React.useState(1);
	const [articlesPerPage, setArticlesPerPage] = React.useState(5);
	const [pageItems, setPageItems] = React.useState(5);
	const indexOfLastArticle = currentPage * articlesPerPage;
	const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

	const [pageDesc, setPageDesc] = React.useState('');

	const currentAccounts = list.slice(indexOfFirstArticle, indexOfLastArticle);

	const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

	React.useEffect(() => {
		if(pageItems < 0 || pageItems > list.length){
			setArticlesPerPage(5)
			setPageDesc('Page number must not exceed total records count or less than zero.')
		}else if (pageItems > 0 && pageItems <= list.length){
			setArticlesPerPage(pageItems)
		}
	}, [pageItems])

	React.useEffect(() => {
		if(pageItems > 0 && pageItems <= list.length){
			setPageDesc('')
		}
	})

	React.useEffect(() => {
		setIsShow(false)
	  fetch(`${API}/get-account-transaction`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	  	if(data.data.length > 0){
	  		setList(data?.data)
	  		setMainList(data?.data)
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}, [token])

	React.useEffect(() => {
	  setList(mainList)
	}, [search])

	const onClick = () => {
	    setSearch('')
	    fetch(`${API}/get-account-transaction`, {
	      method: 'GET',
	      headers: {
	        Authorization: `Bearer ${token}`,
	      },
	    })
	    .then(res => res.json())
	    .then(data => {
	      if(data.data.length > 0){
	      	setList(data?.data)
	      	setMainList(data?.data)
	      	setIsShow(true)
	      }else{
	      	setIsShow(true)
	      }
		    })
		    setToastOpen5(true)
		    setToastTitle5('ACTION')
		    setToastKind5('info')
		    setToastDesc5('')
	}

	const onSearch = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(search)){
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.merchant_name.toLowerCase().includes(search)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${search}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${search}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSearchComp = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.store_name.toLowerCase().includes(searchComp)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchComp}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchComp}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onSearchEmail = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.email.toLowerCase().includes(searchEmail)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onSubmit = async (e:any) => {
	  e.preventDefault()
	  if(/[a-zA-Z]/.test(search)){
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.merchant_name.toLowerCase().includes(e.target.value || search || e)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${search}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${search}" keyword. (${resultList.length}) result(s) found.`)
	    }
	  }
	}

	const onSubmitComp = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.store_name.toLowerCase().includes(e.target.value || searchComp || e)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchComp}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchComp}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onSubmitEmail = async (e:any) => {
	  e.preventDefault()
	    setList(mainList)
	    setSearchOpen(false)
	    await list.map((item:any) => {
	      if(item.email.toLowerCase().includes(e.target.value || searchEmail || e)){
	        resultList.push(item)
	        setList(resultList)
	        setToastOpen5(false)
	        setToastKind5('info')
	        setCurrentPage(1)
	      }else{
	        setToastDesc5('No matches found your search.')
	        setToastTitle5('ERROR')
	        setTimeout(() => {
	          setToastKind5('error')
	        }, 500)
	      }
	      return 1;
	    })
	    if(resultList.length === 0){
	      setList(emptyList)
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (0) result(s) found.`)
	    } else {
	      setResultDesc(`You've searched for "${searchEmail}" keyword. (${resultList.length}) result(s) found.`)
	    }
	}

	const onRefresh = () => {
	  setSearch('')
	  setSearchOpen(true)
	  fetch(`${API}/get-account-transaction`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data.data.length > 0){
	  		setList(data?.data)
	  		setMainList(data?.data)
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}

	const onSort = (e:any) => {
	  if(e === 'name'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.merchant_name < b.merchant_name) { return -1; }
	    if(a.merchant_name > b.merchant_name) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'company'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.store_name < b.store_name) { return -1; }
	    if(a.store_name > b.store_name) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'email'){
	    setIsShow(false)
	    setList(list.sort((a, b) => {
	    if(a.email < b.email) { return -1; }
	    if(a.email > b.email) { return 1; }
	    return 0;
	    }))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateNew'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date);
	    const newDateB: any = new Date(b.date);
	    return newDateB - newDateA
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  } else if (e === 'dateOld'){
	    setIsShow(false)
	    setList(list.sort((a:any , b: any) => {
	    const newDateA: any = new Date(a.date);
	    const newDateB: any = new Date(b.date);
	    return newDateA - newDateB
	  	}))
	    setTimeout(() => {
	      setIsShow(true)
	    }, 1500)
	  }
	}

	const onReload = () => {
	  setIsShow(false)
	  fetch(`${API}/get-account-transaction`, {
	    method: 'GET',
	    headers: {
	      Authorization: `Bearer ${token}`,
	    },
	  })
	  .then(res => res.json())
	  .then(data => {
	    if(data.data.length > 0){
	  		setList(data?.data)
	  		setMainList(data?.data)
	  		setIsShow(true)
	  	}else{
	  		setIsShow(true)
	  	}
	  })
	}
	
return( 
	<>
	<ToastInputMultipleV2
	      description={toastDesc5}
	      isOpen={isToastOpen5}
	      onChange={(e:any) => setSearch(e.target.value.toLowerCase())}
	      onChangeComp={(e:any) => setSearchComp(e.target.value.toLowerCase())}
	      onChangeEmail={(e:any) => setSearchEmail(e.target.value.toLowerCase())}
	      onClickSubmit={onSearch}
	      onClickSubmitComp={onSearchComp}
	      onClickSubmitEmail={onSearchEmail}
	      onClose={() => setToastOpen5(false)}
	      onReset={onClick}
	      onSubmit={onSubmit}
	      onSubmitComp={onSubmitComp}
	      onSubmitEmail={onSubmitEmail}
	      title={toastTitle5}
	      toastKind={toastKind5}
	      value={search}
	      valueComp={searchComp}
	      valueEmail={searchEmail}
	    />
	<Text className="font-poppins font-semibold text-2xl text-primary-100 my-4">
        Accounts
    </Text>
    <hr className="text-grey-400 mb-10" />

    {
      searchOpen?
      <>
      </>
      :
      <div>
          <p className='phone:text-center surfaceDuo:text-left mb-2'>{resultDesc}</p>
          <span className='block text-center surfaceDuo:text-left hover:underline text-blue-100 cursor-pointer mb-4' onClick={onRefresh}>Clear Searches</span>
      </div>
    }

    <div className='flex tabletWide:hidden flex-row items-center phone:w-full surfaceDuo:w-auto my-4'>
    <label className='flex phone:ml-4 surfaceDuo:ml-0 mr-4' htmlFor='sort'>Search by:</label>
    <select className='w-56 border border-grey-400 text-secondary-200' name="sort" onChange={(e:any) => setSelected(e.target.value) }>
        <option disabled selected value="">-- SELECT --</option>
        <option value="name">Merchant Name</option>
        <option value='company'>Company</option>
        <option value='email'>Email</option>
    </select>
    </div>

    <FlexRow className="justify-between">
      <FlexRow className='gap-4 phone:items-start phone:mx-5 surfaceDuo:items-center surfaceDuo:mx-0 surfaceDuo:justify-between w-full flex phone:flex-col surfaceDuo:flex-row'>

            {
            	selected === 'name' ?
            	<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
            	  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
            	      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
            	  >
            	      <form onSubmit={onSubmit}>
            	      <input className="placeholder-grey-700 laptop:text-base
            	          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
            	          onChange={(e:any) => {
            	            setSearch(e.target.value)
            	          }}
            	          placeholder="Search"
            	          value={search.toLowerCase()}
            	      />
            	      <button className='hidden' type='submit'>Search</button>
            	      </form>
            	  </div>
            	  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearch}/>
            	</div>
            	:
            	<></>
            }

            {
            	selected === 'company' ?
            	<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
            	  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
            	      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
            	  >
            	      <form onSubmit={onSubmitComp}>
            	      <input className="placeholder-grey-700 laptop:text-base
            	          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
            	          onChange={(e:any) => {
            	            setSearchComp(e.target.value)
            	          }}
            	          placeholder="Search"
            	          value={searchComp}
            	      />
            	      <button className='hidden' type='submit'>Search</button>
            	      </form>
            	  </div>
            	  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchComp}/>
            	</div>
            	:
            	<></>
            }

            {
            	selected === 'email' ?
            	<div className='tabletWide:hidden flex flex-row phone:w-full surfaceDuo:w-auto'>
            	  <div className="border border-primary-100 text-grey-700 font-poppins text-base phone:px-4
            	      rounded-full flex flex-row tablet:px-9 items-center overflow-hidden phone:w-full surfaceDuo:w-44 py-1 tablet:w-80 tablet:py-0"
            	  >
            	      <form onSubmit={onSubmitEmail}>
            	      <input className="placeholder-grey-700 laptop:text-base
            	          tablet:py-3.5 ml-3 w-full outline-none border-none block phone:text-sm phone:py-2"
            	          onChange={(e:any) => {
            	            setSearchEmail(e.target.value)
            	          }}
            	          placeholder="Search"
            	          value={searchEmail}
            	      />
            	      <button className='hidden' type='submit'>Search</button>
            	      </form>
            	  </div>
            	  <i className="fas fa-search text-white bg-primary-100 hover:bg-blue-100 text-xl ml-2 px-3 pt-2 rounded" onClick={onSearchEmail}/>
            	</div>
            	:
            	<></>
            }

            <button className='hidden tabletWide:flex w-52 flex-row items-center justify-start text-primary-100 hover:text-white py-2 px-8 rounded-full border-2 border-primary-100 bg-white hover:bg-primary-100' onClick={onClick}>
              <i className="fas fa-search text-base mr-2" />Search 
            </button>
          
          <div className='flex flex-row items-center phone:w-full surfaceDuo:w-auto'>
          <label className='hidden medium:flex mx-3' htmlFor='sort'>Sort by:</label>
          <select className='rounded-lg phone:w-full surfaceDuo:w-56 border-2 border-primary-100' name="sort" onChange={(e:any) => onSort(e.target.value)}>
              <option disabled selected value="">SORT</option>
              <option value="name">A - Z (by Name)</option>
              <option value="company">A - Z (by Company)</option>
              <option value='email'>A - Z (by Email)</option>
              <option value="dateNew">Newest to Oldest</option>
              <option value="dateOld">Oldest to Newest</option>
          </select>
          </div>
          
      </FlexRow>
      
    </FlexRow>

    <Text className='tabletWide:hidden mt-8 pb-2 text-primary-100 font-semibold border-b border-grey-400 text-center'>
    	List of Accounts
    </Text>

    <FlexRow className='w-full justify-around mt-4 pb-2 border-b border-grey-400 phone:hidden laptop:flex'>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('name')}>
    		Merchant Name
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer text-center' onClick={() => onSort('company')}>
    		Merchant Company
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-not-allowed w-52 text-center'>
    		Phone Number
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('email')}>
    		Email Address
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    	<div className='hover:bg-grey-400 bg-white text-primary-100 rounded-full px-3 py-2 cursor-pointer w-52 text-center' onClick={() => onSort('dateNew')}>
    		Date
    		<i className="fas fa-chevron-down text-primary-100 mx-2" />
    	</div>
    </FlexRow>

    <Div className='w-full pt-4'>
    	<Div className={`w-full ${articlesPerPage > 10 ? 'h-96 overflow-y-scroll' : ''}`}>
    	{
    		currentAccounts.length > 0 && isShow && list.length > 0 ?
    		<>
    		{
    		  currentAccounts.map((listA: any) =>(
    		    <AccountData
    		      accCompany={listA.store_name}
    		      accEmail={listA.email}
    		      accName={listA.merchant_name}
    		      contact={listA.contact}
    		      date={listA.date.toString().substring(0, 10)}
    		      key={listA.merchant_id}
    		      loading={loading}
    		      readAccounts={readAccounts}
    		      writeAccounts={writeAccounts}
    		    />
    		    ))
    		}
    		</>
    		:
    		<></>
    	}
	    {
	    	currentAccounts.length === 0 && isShow && list.length > 0 ?
	    	<Div className='bg-grey-400 py-4 my-2'>
	    	<Text className='text-center w-full'>
	    		Page is empty.
	    	</Text>
	    	<Text className='text-xs text-center w-full mt-4'>
	    		Go to Page 1
	    	</Text>
	    	</Div>
	    	:
	    	<></>
	    }
	    {
	    	currentAccounts.length === 0 && isShow && list.length === 0?
	    	<Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	    	  No data to be displayed.
	    	  <span className='block text-blue-100 hover:underline font-semibold cursor-pointer' onClick={onReload}>
	    	    Click to reload.
	    	    <i className="fas fa-repeat ml-2" />
	    	  </span>
	    	</Text>
	    	:
	    	<></>
	    }
	    {
	      !isShow?
	      <Text className='text-secondary-200 bg-grey-500 py-10 text-center w-full'>
	      	Loading data ...
	      	<i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
	      </Text>
	      :
	      <></>
	    }
	    </Div>
    <Div className="w-full justify-center mt-14">

    	<div
    	  className='font-robot font-regular text-blue-100 my-4 hover:underline cursor-pointer'
    	  onClick={onReload}>
    	  Reset records.
    	</div>

      <Text className='text-secondary-200 my-4'>
      	Total records found: <b className='ml-4 mr-2'>{list.length}</b> entries
      </Text>

      <Text className='flex flex-row items-center text-secondary-200 my-4'>
      	<Popover className="relative my-4">
      	      <Popover.Button>
      	            <i className="fas fa-question text-xs mr-2 px-1 rounded-full bg-primary-100 text-white border-2 border-primary-100" />
      	      </Popover.Button>
      	      <Popover.Panel className="absolute z-10 left-0">
      	        <div className="bg-white shadow-xl p-3 rounded-lg border border-primary-100 phone:w-48 tablet:w-80">
      	          <p className='font-semibold text-sm'>
      	            Items per Page
      	          </p>
      	          <hr className='text-primary-100 my-1'/>
      	          <p className='text-sm mt-4'>
      	            Input a number or press
      	            <i className="fas fa-chevron-down text-xs ml-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            <i className="fas fa-chevron-up text-xs mr-2 px-1 rounded bg-grey-500 border border-grey-400" />
      	            to change number of entries per page.
      	          </p>
      	          <p className='mt-4 text-sm text-secondary-200'>
      	            ** Click anywhere on screen to close. **
      	          </p>
      	        </div>
      	      </Popover.Panel>
      	</Popover>
      	Items per Page:
      	<input className='mx-2 w-10 px-2' onChange={(e:any) => setPageItems(e.target.value)} type='number' value={pageItems} />
      	<button className='hidden flex justify-center items-center p-2 rounded-lg bg-primary-100 hover:bg-blue-100 text-white'>
      		Go
      	</button>
      </Text>

      <Text className='text-red-400'>
      	{pageDesc}
      </Text>

      <PaginationV2
        currentPage={currentPage}
        itemsPerPage={articlesPerPage}
        paginate={paginate}
        totalItems={list.length}
      />
    </Div>  
    </Div>
 
    </>
 )
}; 