import { ResponsiveLine } from '@nivo/line';
import React, { FC } from 'react';
import { Div, Flex, FlexCenter } from '@src/core';

interface LineGraphInputs {
  titles: string[];
  data: any[];
  color: string;
  color2: string;
  cc?: string;
}

const theme = {
  textColor: '#9C9C9C',
  fontFamily: 'Poppins',
  fontSize: 14,
  legends: {
    title: {
      text: {
        fontSize: 14,
        fill: '#333333',
      },
    },
    text: {
      fontSize: 14,
      fill: '#333333',
    },
    ticks: {
      line: {},
      text: {
        fontSize: 14,
        fill: '#333333',
      },
    },
  },
};

export const LineGraph: FC<LineGraphInputs> = ({ titles, data, color, color2, cc }) => (

    <ResponsiveLine
      areaOpacity={0.1}
      axisBottom={{
        format: '%b %d',
        tickPadding: 5,
        tickRotation: 67,
      }}
      axisLeft={{
        tickValues: 5,
        tickPadding: 10,
        format: (text) =>
          cc
            ? `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'PHP',
                notation: 'compact',
              }).format(Number(text))}`
            : Number(text),
      }}
      colors={[color, color2]}
      curve="monotoneX"
      data={data}
      enableArea
      enableGridX={false}
      enableGridY={false}
      enableSlices="x"
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateY: -40,
          itemDirection: 'left-to-right',
          itemWidth: 140,
          itemHeight: 20,
          symbolSize: 12,
          symbolShape: 'square',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
          toggleSerie: false,
        },
      ]}
      margin={{
        top: 50,
        right: 10,
        bottom: 75,
        left: 50,
      }}
      sliceTooltip={
        // eslint-disable-next-line react/no-unstable-nested-components
        ({ slice }) => (
          <Div className="w-52 bg-white flex-row p-2 rounded drop-shadow-2xl bg-grey-500">
            {slice.points.map((point, i) => (
              <Div key={point.id}>
                <FlexCenter className="text-xs font-medium">
                  {i === 0 ? point.data.xFormatted : ''}
                </FlexCenter>
                <Div className="flex flex-row pt-1">
                  <Flex className="text-grey-700 text-sm">
                    {point.serieId === titles[0] ? titles[0] : titles[1]}
                  </Flex>
                  <Flex className="text-right text-sm font-medium">
                    {cc ? 'PHP ' : ''} {point.data.y.toLocaleString()}
                  </Flex>
                </Div>
              </Div>
            ))}
          </Div>
        )
      }
      theme={theme}
      useMesh
      xFormat="time:%m/%d/%Y"
      xScale={{
        type: 'time',
        format: '%m/%d/%Y',
      }}
      yFormat={(value) =>
        `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'PHP' }).format(
          Number(value),
        )}`
      }
      yScale={{
        type: 'linear',
        stacked: true,
        reverse: false,
      }}
    />
  );