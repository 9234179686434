import React, { FC } from 'react';
import { getDate } from '@helpers';
import { FlexRow, Div, Text, Flex } from '@core';

interface ProductItemProps {
  products?: any;
  selectedProd?: any;
  setSelected?: any;
  onSelect?: any;
  available?: boolean;
}

export const ProductItemV2: FC<ProductItemProps> = ({
  products,
  selectedProd,
  setSelected,
  onSelect,
  available,
}) => (
  <div
    className="group cursor-pointer hover:bg-white"
    key={products?.id}
    onClick={() => {
      if (available) {
        setSelected(products);
        onSelect();
      }
    }}
  >
    <li
      className="relative pr-4 hover:bg-white bg-grey-500 border-b-2 
                        border-grey-400 "
    >
      <FlexRow className={`group cursor-pointer ${available ? '' : 'opacity-60'}`}>
        <Div
          className={`h-28 w-2 mr-4 ${
            products?.id === selectedProd?.id ? 'bg-blue-200' : 'bg-white'
          } `}
        />
        <Flex className="py-5">
          <FlexRow className='justify-between items-center'>
            <Div className='w-7/12'>
              <Text className="font-poppins laptop:text-sm phone:text-xs text-grey-100">
                {getDate(products.created_at)}
              </Text>
              <h3
                className="font-poppins laptop:text-base phone:text-sm font-semibold leading-5 mb-1 
              text-secondary-100 "
              >
                {products.name}
              </h3>
              <Text className="font-poppins font-medium laptop:text-sm phone:text-xs text-blue-100 ">
                {products.commission}% commission
              </Text>
            </Div>

            <FlexRow className='w-5/12 phone:flex-col laptop:flex-row justify-end'>
              <Text
                className={`font-poppins rounded-full 
                text-primary-100 mx-2`}
              >
                <i className="fas fa-hand-pointer mr-2 text-orange-100" />
                {products.total_cliked}
              </Text>
              <Text
                className={`font-poppins rounded-full 
                text-primary-100 mx-2`}
              >
                <i className="fas fa-repeat mr-2 text-orange-100" />
                {products.conversion}
              </Text>
            </FlexRow>
          </FlexRow>
        </Flex>
      </FlexRow>
    </li>
  </div>
);