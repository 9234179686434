import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import { FlexRow, Button, Div, Text, InputV2, Toast } from '@core';
import { getSingleAdmin, changeInfoSuperAdminA } from '@services';

interface ModalProps {
  id: any;
  isOpen: boolean;
  onClose: any;
}

export const UpdateAdminModal: FC<ModalProps> = ({ isOpen, onClose, id }) => {

  const navigate = useNavigate();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  React.useEffect(() => {
    setFirstName('');
    setLastName('');
    setContactNumber('');
  }, [navigate, isOpen]);

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if(parseInt(contactNumber) < 0 || regExp.test(contactNumber)){
        setContactNumber('')
      }
  }, [contactNumber])

  React.useEffect(() => {
    batch(async () => {
      const useradmin = await getSingleAdmin(id)
      if(useradmin.data.status === 1){
        const userdata = useradmin?.data?.user_info
        setFirstName(userdata.firstname)
        setLastName(userdata.lastname)
        setContactNumber(userdata.contact)
      }
    })
  }, [isOpen])

  const onSubmit = async () => {
    if (
      firstName === '' &&
      lastName === '' &&
      contactNumber === ''
    ) {
      setToastTitle('Invalid Input');
      setToastDesc('Please complete all the fields.');
      setToastKind('error');
      setToastOpen(true);
      setLoading(false);
    } else {
      batch(async () => {
        setLoading(true);
        const data: any = await changeInfoSuperAdminA({
            id,
            firstname: firstName,
            lastname: lastName,
            contact: contactNumber
          })
        console.log(data)
        if(data?.data?.status === 1){
          setToastTitle('SUCCESS');
          setToastDesc('Admin info updated.');
          setToastKind('success');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            navigate(`/admin/manage_users?action=updated`)
          }, 2000)
        } else {
          setToastTitle('Invalid Input');
          setToastDesc(data?.payload?.message || 'Something went wrong. Try again!');
          setToastKind('error');
          setToastOpen(true);
          setLoading(false);
          setTimeout(() => {
            setToastOpen(false);
          }, 2000)
        }
      });
    }
  };

  return (
    <>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hide bg-secondary-200"
          onClose={() => {
            onClose();
            navigate('/admin/manage_users')
          }}
        >
          <div className="h-full text-center flex justify-end">
            <Transition.Child
              as={Fragment}
              enter="transition ease duration-700 transform"
              enterFrom="opacity-0 translate-x-full"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease duration-1000 transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-full"
            >
              <div
                className="h-full w-full max-w-sm overflow-hidden text-left z-20 
              align-middle transition-all transform bg-white shadow-xl flex flex-col"
              >
                <FlexRow
                  className="px-8 py-7 text-2xl font-poppins font-normal leading-6 
                text-white bg-primary-100"
                >
                  <Dialog.Title as="h3">UPDATE ADMIN USER</Dialog.Title>
                  <i
                    className="fas fa-times ml-auto hover:text-blue-100
                cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate('/admin/manage_users')
                    }}
                  />
                </FlexRow>
                <div className="px-8 h-full py-8 flex flex-col overflow-y-scroll scrollbar-hide">
                  <Text className="font-roboto font-bold text-lg text-grey-100 ml-2">
                    Admin User Details
                  </Text>
                  <Text className="font-roboto font-normal text-base text-grey-100 ml-2 mt-3 mb-5">
                    Enter the information below
                  </Text>
                  
                  <InputV2
                    label="FIRST NAME"
                    name="first-name"
                    onChange={setFirstName}
                    placeholder="Enter First Name"
                    value={firstName}
                  />
                  <InputV2
                    label="LAST NAME"
                    name="last-name"
                    onChange={setLastName}
                    placeholder="Enter Last Name"
                    value={lastName}
                  />
                  <InputV2
                    label="CONTACT NUMBER"
                    maxlength={11}
                    name="contact-number"
                    onChange={setContactNumber}
                    pattern="[0-9]*"
                    placeholder="Enter Contact Number"
                    type="text"
                    value={contactNumber}
                  />
                  
                  <FlexRow
                    className="px-5 py-6 bg-blue-300 font-roboto
                  text-blue-100 rounded-lg"
                  >
                    <i className="fas fa-exclamation-circle mr-4 text-2xl" />
                    <Text className="text-lg font-medium">
                      Be sure to complete all fields to create a partner.
                    </Text>
                  </FlexRow>
                  <Div className="h-20 w-2" />
                </div>
                <hr />
                <div className=" flex  mx-8 my-5">
                  <Button
                    className="w-full justify-center items-center bg-blue-100"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    UPDATE
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};