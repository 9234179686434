import React, { FC } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectUser } from '@redux'
import {
  Icons,
  Div,
  Text,
  FlexRow,
  Button,
  UserDropdownV2,
} from '@core';
import { Stage } from '@components';

export const AddMethodComplete: FC<any> = () => {

  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const back = async () => {
    navigate(user?.user_info?.role === 'affiliate'? '/affiliate/settings' : '/merchant/settings')
  }

  return(
  <>
  <Div className='overflow-y-scroll'>
  <FlexRow className="phone:px-5 tablet:px-10 laptop:px-20 w-full">

        <Div className="mr-auto flex flex-row items-center py-8">
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
        </Div>
        
        <UserDropdownV2 info={user.user_info} user={user} />
        
  </FlexRow>
  <hr className="text-secondary-300 laptop:mb-20 phone:mb-10 phone:px-5 tablet:px-10 laptop:px-20" />

  <Link className='ml-10 font-semibold text-primary-100 hidden' to={user?.user_info?.role === 'affiliate'? '/affiliate/settings' : '/merchant/settings'}>
      <i className="fas fa-angle-left"/>
      <i className="fas fa-angle-left mr-3"/>
      Back to Settings
  </Link>

  <FlexRow className='mt-5 phone:flex medium:hidden justify-center'>
        <Stage
            isCurrent
            label="Complete"
            stageNumber="3"
        />
  </FlexRow>

  <FlexRow
      className="items-center laptop:mb-7 phone:mb-2 phone:flex-col phone:items-start phone:space-y-4
      medium:flex-row medium:items-center medium:space-y-0 justify-center mt-5"
      >
        <Stage
            className='phone:hidden medium:flex'
            isCurrent
            label="Add Information"
            stageNumber="1"
        />
          <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden medium:block" />
            <Stage
                className="phone:hidden medium:flex"
                isCurrent
                label="Authenticate"
                stageNumber="2"
            />

          <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden medium:flex" />
            <Stage
                className="phone:hidden medium:flex"
                isCurrent
                label="Complete"
                stageNumber="3"
             />
  </FlexRow>

  <Div className="phone:px-4 phone:w-full medium:w-3/4 tabletWide:w-1/2 mx-auto">

    <Text className='text-center my-10'>
        You have successfully added a new payment method.
    </Text>
  
      <FlexRow className="phone:justify-center">
            <Button
              className=" mt-10 mb-20 phone:w-full phone:justify-center surfaceDuo:w-48"
              onClick={back}
            >
              Back to Settings
            </Button>
      </FlexRow>
   </Div>
  </Div>
  </>
  )
};