import React, {FC} from 'react';
import { Div, Text, FlexRow } from '@core';

interface DataProps {
	id: number;
	name: string;
	link: string;
	commission: number;
}

export const ProductDetails: FC<DataProps> = ({
	id,
	name,
	link,
	commission,
}) => (
<>

<div className='w-full tabletWide:w-1000px hover:bg-grey-500 border-b border-grey-500 my-2 py-2'>
	<Div className='phone:flex phone:flex-row tabletWide:hidden' key={id}>
	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Product:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Amount:
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        Product Link:
	      </Text>
	  </Div>

	  <Div className='phone:flex phone:flex-col phone:w-1/2'>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {name}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        &#8369; {commission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
	      </Text>
	      <Text
	        className="text-base text-secondary-100 font-light w-full text-left"
	      >
	        {link}
	      </Text>
	  </Div>
	</Div>

	<FlexRow className='w-full justify-start items-start phone:hidden tabletWide:flex'>
		<Text
		  className="text-base text-secondary-200 font-light w-300px"
		>
		  {name}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-400px break-words"
		>
		  {link}
		</Text>
		<Text
		  className="text-base text-secondary-200 font-light w-300px pl-10"
		>
		  &#8369; {commission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
		</Text>
	</FlexRow>
	
</div>

</>
)