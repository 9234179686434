import React, {FC} from 'react';
import { useNavigate } from 'react-router-dom'
import { Popover } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { Div, Text, Toast, FlexRow } from '@core';
import { getCookie } from '@hooks';
import { selectUser } from '@redux';
import { API } from '@config';

interface AffiliateProps {
	id: any;
	affiliateEmail: string;
	affiliateName: string;
	affiliateCategory: string;
	affiliateLink: string;
	categoryColor: string;
	commission: number;
	Select: any;
	selection: boolean;
	selectedData: any;
	selectedAll: boolean;
	partner: any;
	currentPage: any;
}

export const AffiliateTagList: FC<AffiliateProps> = ({
	id,
	affiliateName,
	affiliateEmail,
	affiliateCategory,
	affiliateLink,
	categoryColor,
	commission,
	Select,
	selection,
	selectedData,
	selectedAll,
	partner,
	currentPage,
}) => {

	const user = useSelector(selectUser);
	const token = getCookie('token');
	const navigate = useNavigate();
	const [isEdit, setEdit] = React.useState(false);
	const [newCategory, setNewCategory] = React.useState('');
	const [categoryCommission, setCategoryCommission] = React.useState('0');
	const [categoryId, setCategoryId] = React.useState(0);

	const [isLoading, setLoading] = React.useState(false);

	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('');
	const [toastDesc, setToastDesc] = React.useState('');

	const [options, setOptions] = React.useState<any>([]);
	const [isChecked, setIsChecked] = React.useState<any>();

	React.useEffect(() => {
	  if(selectedData.includes(partner)){
	    setIsChecked(true)
	  }else{
	    setIsChecked(null)
	  }
	}, [selectedData, currentPage, partner])

	React.useEffect(() => {
		setNewCategory(affiliateCategory)
	}, [affiliateCategory])

	React.useEffect(() => {
	      fetch(`${API}/get-all-role-commission`,{
	        method: 'GET',
	        headers: {Authorization: `Bearer ${token}`}
	      })
	      .then(res => res.json())
	      .then(data => {
	      	if(data.status === 1){
	      		setOptions(data.data)
	      	}
	      })
	}, [isEdit]);

	React.useEffect(() => {
		options.map((category:any) => {
			if(category.role_name === newCategory){
				setCategoryId(category.id)
				setCategoryCommission(category.commission)
			}
			return 1;
		})
	}, [newCategory])

	const onUpdate = () => {
		setLoading(true)
		fetch(`${API}/update-affiliate-category/${id}`,{
		  method: 'PUT',
		  headers: {
		  	Authorization: `Bearer ${token}`,
		  	'Content-Type': 'application/json'
		  },
		  body: JSON.stringify({
		  		category_id: categoryId,
		  		commission: categoryCommission
        	})
		})
		.then(res => res.json())
		.then(data => {
			if(data.status === 1){
				setLoading(false)
				setEdit(false)
				setToastDesc('Affiliate category has been updated successfully')
				setToastOpen(true)
				setToastTitle('SUCCESS')
				setToastKind('success')
				if(user?.user_info?.role === 'merchant'){
					navigate('/merchant/settings?action=updated')
				}else{
					navigate(`/merchant/admin_dashboard/${user?.user_info.role.toLowerCase()}/settings?action=updated`)
				}
			}else{
				setLoading(false)
				setToastDesc(data.message || 'Something went wrong. Please try again.')
				setToastOpen(true)
				setToastTitle('ERROR')
				setToastKind('error')
			}
		})
	}

return(
	<>
	<Toast
	      description={toastDesc}
	      isOpen={isToastOpen}
	      onClose={() => setToastOpen(false)}
	      title={toastTitle}
	      toastKind={toastKind}
	    />
	<div className={`py-2 px-1 rounded ${isEdit? 'bg-grey-500' : 'hover:bg-grey-500 '}`}>
	<Div className='phone:flex phone:flex-row medium:items-center tablet:items-start' key={affiliateEmail}>

		{
			selection && !isEdit?
			<div className='laptop:my-auto'>
			<input
			  checked={isChecked}
			  className={`mr-2 w-5 h-5 rounded border border-secondary-100 
			        focus:ring-3 focus:ring-primary-200 text-primary-100 phone:mb-auto phone:mt-2 tabletWide:mt-auto cursor-pointer
			        ${selection ? '' : 'hidden'}
			        `}
			  id={id}
			  key={id}
			  onClick={Select}
			  readOnly
			  title={selectedAll? 'Unselect all to choose items manually.' : 'Click to select/unselect this item'}
			  type='checkbox'
			/>
			{
				isEdit?
				<i
				  className="flex items-center justify-center fas fa-times text-primary-100 border-2 border-primary-100 rounded mr-2 cursor-pointer h-5 w-5 tabletWide:hidden mt-1"
				  onClick={() => setEdit(false)}
				  title='Close field.'
				/>
				:
				<i
				  className="fas fa-edit text-primary-100 mr-2 cursor-pointer tabletWide:hidden"
				  onClick={() => setEdit(true)}
				  title='Edit this field.'
				/>
			}
			</div>
			:
			<div className='laptop:my-auto'>
			<input
			  className='mr-2 w-5 h-5 rounded border border-secondary-100 
			        focus:ring-3 focus:ring-primary-200 text-primary-100 phone:mb-auto phone:mt-2 tabletWide:mt-auto cursor-not-allowed'
			  disabled
			  title='Close edit field to enable selection'
			  type='checkbox'
			/>
			{
				isEdit?
				<i
				  className="flex items-center justify-center fas fa-times text-primary-100 border-2 border-primary-100 rounded mr-2 cursor-pointer h-5 w-5 tabletWide:hidden mt-1"
				  onClick={() => setEdit(false)}
				  title='Close field.'
				/>
				:
				<i
				  className="fas fa-edit text-primary-100 mr-2 cursor-pointer tabletWide:hidden"
				  onClick={() => setEdit(true)}
				  title='Edit this field.'
				/>
			}
			</div>
		}

		<Div className='phone:flex phone:flex-col phone:w-1/2 medium:hidden tablet:flex tabletWide:hidden'>
		    <Text
		      className=" text-base text-secondary-200 font-light w-full text-left"
		    >
		      Name:
		    </Text>
		    <Text
		      className=" text-base text-secondary-200 font-light  w-full text-left"
		    >
		      Category:
		    </Text>
		    <Text
		      className=" text-base text-secondary-200 font-light  w-full text-left"
		    >
		      Commission:
		    </Text>
		    <Text
		      className=" text-base text-secondary-200 font-light  w-full text-left"
		    >
		      Social Link:
		    </Text>
		</Div>

		<Div className='phone:flex phone:flex-col phone:w-1/2 medium:flex-row medium:w-full medium:items-center tablet:flex-col tablet:items-start tablet:w-1/2 tabletWide:flex-row tabletWide:items-center tabletWide:w-full' >
			<FlexRow
		      className="w-full medium:w-1/4 tablet:w-full tabletWide:w-1/4 text-left"
		    >
		      <div className='phone:hidden laptop:flex items-center justify-center w-10 h-10 rounded-full bg-grey-400 mr-2'>
		      	{affiliateName.substring(0, 1).toUpperCase()}
		      </div>
		      <div className='flex flex-col laptop:w-10/12'>
			      <span className='block text-base text-secondary-200 font-light w-full'>{affiliateName}</span>
			      <span className='phone:hidden laptop:block truncate text-base text-secondary-200 font-light w-full'>{affiliateEmail}</span>
		      </div>
		    </FlexRow>
		    <Text
		      className="hidden text-base text-secondary-200 font-light w-full medium:w-1/4 tablet:w-full tabletWide:w-1/4 text-left"
		    >
		      {affiliateName}
		    </Text>
		    {
		    	isEdit?
		    	<div className='w-full flex flex-row phone:justify-start medium:w-1/4 medium:justify-center tablet:justify-start tablet:w-full tabletWide:w-1/4 tabletWide:justify-center'>
		    	<select
		    		className='rounded-full phone:w-32 surfaceDuo:w-44 border-2 border-grey-400 text-primary-100 cursor-pointer bg-grey-500 py-0 shadow-lg'
		    		name="sort"
		    		onChange={(e:any) => setNewCategory(e.target.value)}
		    		value={newCategory}>
		    	    <option disabled selected value="">Choose</option>
		    	    <option value="">-- SELECT --</option>
		    	    {options.map((option:any) => (
		    	      <option
		    	      	key={option.role_name}
		    	      	style={{backgroundColor: option.color}}
		    	      	value={option.role_name}
		    	      >
		    	        {option.role_name.toUpperCase()}
		    	      </option>
		    	    ))}
		    	    <option value="0">NONE</option>
		    	</select>
		    	</div>
		    	:
		    	<div
		    	   className='w-full flex flex-row phone:justify-start medium:w-1/4 medium:justify-center tablet:justify-start tablet:w-full tabletWide:w-1/4 tabletWide:justify-center'
		    	>
		    	<div
		    		className={`text-base border-2 border-grey-400 rounded-full tabletWide:w-44 text-left medium:text-center tablet:text-left tabletWide:text-center cursor-pointer px-4 ${affiliateCategory === 'NONE' ? 'text-secondary-200' : 'text-white font-semibold'}`}
		    		style={{backgroundColor: categoryColor}}>
		    	  {affiliateCategory.toUpperCase()}
		    	</div>
		    	</div>
		    }
		    <Text
		      className=" text-base text-secondary-200 font-light  w-full medium:w-1/4 tablet:w-full tabletWide:w-1/4 text-left medium:text-center tablet:text-left tabletWide:text-center"
		    >
		      {commission.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
		    </Text>
		    <Popover className="relative w-full medium:w-1/4 tablet:w-full tabletWide:w-1/4 flex flex-row medium:justify-center tablet:justify-start tabletWide:justify-center">
		          <Popover.Button className=''>
		              <Text className='text-blue-100'>
		                View Link
		              </Text>
		          </Popover.Button>
		          <Popover.Panel className="absolute z-10 right-0 top-full mt-2 text-secondary-200 border-2 border-primary-100 bg-white px-2 rounded w-64 py-4">
		          	<Text className='text-sm text-secondary-200'>
		          		Social Link:
		          	</Text>
		          	<hr className='text-grey-400' />
		          	<Text className='text-sm text-secondary-200 break-words mt-4'>
		          		{affiliateLink}
		          	</Text>
		          </Popover.Panel>
		    </Popover>
		    <input
		      className="hidden text-base text-secondary-200 font-light border-none outline-none w-full medium:w-1/4 tablet:w-full tabletWide:w-1/4 text-left medium:text-right  tablet:text-left tabletWide:text-right bg-transparent"
		      readOnly
		      value={affiliateLink}
		    />
		    {
		    	isEdit?
		    	<i
		    	  className="flex items-center justify-center fas fa-times text-primary-100 border-2 border-primary-100 rounded mr-2 cursor-pointer h-5 w-5 hidden tabletWide:flex"
		    	  onClick={() => setEdit(false)}
		    	  title='Close field.'
		    	/>
		    	:
		    	<i
		    	  className="fas fa-edit text-primary-100 mr-2 cursor-pointer hidden tabletWide:flex"
		    	  onClick={() => setEdit(true)}
		    	  title='Edit this field.'
		    	/>
		    }
		</Div>

	</Div>
	{
		isEdit && !isLoading ?
		<Text className='mt-4 ml-6 text-secondary-200 text-base font-light'>
			Select Action:
			<span className='ml-3 text-primary-100 hover:underline cursor-pointer font-semibold' onClick={onUpdate}>Update</span>
			<span className='ml-3 text-primary-100 hover:underline cursor-pointer font-semibold' onClick={() => setEdit(false)}>Cancel</span>
		</Text>
		:
		<></>
	}
	{
		isLoading?
		<Text className='flex flex-row items-center mt-4 ml-6 text-secondary-200 text-base font-light'>
			Loading please wait.
			<i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
		</Text>
		:
		<></>
	}
	</div>
	<hr className="text-grey-400 mt-2 mb-4" />
	</>
)
}