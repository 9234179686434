import React, { FC } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import { selectUser, useAppDispatch, usersActions } from '@redux';
import {
  Icons,
  Text,
  Div,
  Flex,
  FlexRow,
  Button,
  Input,
  UserDropdownV2,
  Toast,
  ToastV5,
  InputV3,
  ButtonV2,
  Checkbox,
} from '@core';
import { Stage } from '@components';
import { getCookie, useCookie } from '@hooks';
import { API } from '@config';

export const MethodSetup: FC<any> = () => {

  const token = getCookie('token');
  const navigate = useNavigate();
  const { search } = useLocation();
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const [cookie, updateToken] = useCookie('token', '0');

  const [accountName, setAccountName] = React.useState('');
  const [accountNum, setAccountNum] = React.useState<any>('');
  const [channelCode, setChannelCode] = React.useState<any>('');
  const [bankName, setBankName] = React.useState<any>('');

  const [tab, setTab] = React.useState('information');
  const [verified, setVerified] = React.useState(false);
  const [codeSent, setCodeSent] = React.useState(false);
  const [agreement, setAgreement] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState('');
  const [oneTimeCode, setOneTimeCode] = React.useState('');
  const OTP: any[] = [];

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const [isToastOpen5, setToastOpen5] = React.useState(false);
  const [toastKind5, setToastKind5] = React.useState('success');
  const [toastTitle5, setToastTitle5] = React.useState('');
  const [toastDesc5, setToastDesc5] = React.useState('');

  const query = new URLSearchParams(search);
  const newBankName = query.get('bank_name');
  const newBankCode = query.get('channel_code');
  const newBankCode2: any = newBankCode;
  const newBankName2: any = newBankName;
  const xffiliateToken = query.get('t');

  React.useEffect(() => {
      if(xffiliateToken){
        updateToken(xffiliateToken, 1);
        console.log(cookie)
        batch(async() => {
          const data = await dispatch(usersActions.profile());
          if(data.payload){
            navigate('/payment_method_setup')
          }
        })
      }
  }, [xffiliateToken])

  React.useEffect(() => {
      if(newBankName !== 'undefined' && newBankName !== null){
      setBankName(newBankName2)
      setChannelCode(newBankCode2)
      }
  }, [query])

  const onSetBank = (e:any) => {
    console.log(e)
  }

  const sendCode = async () => {
    OTP.shift()
    setLoading(true)
    if(user?.user_info?.email !== null){
      await OTP.push(Math.random().toString().substring(3, 9))
      setVerificationCode('')
      fetch(`${API}/payment-method-verification`,{
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
              bank_code: channelCode,
              account_name: accountName,
              action: 'add',
              code: OTP[0]
          })
      })
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setLoading(false)
          setCodeSent(true);
          setToastTitle('Info');
          setToastDesc('Please check the code that has been sent to your registered email.');
          setToastKind('warning');
          setToastOpen(true);
          setOneTimeCode(OTP[0])
        }else{
          setLoading(false)
          setToastTitle('ERROR');
          setToastDesc('Something went wrong. Try again.');
          setToastKind('error');
          setToastOpen(true);
        }
      })
    }
  }

  const ToAuth = async () => {
    if(accountNum !== '' && accountName !== '' && agreement !== false && channelCode !== '' && bankName !== ''){
      fetch(`${API}/get-payment-method`,{
        method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => res.json())
        .then(data => {
          if(data.data.default.length !== 0){
            if(data.data.default[0].channel_code === channelCode){
              setToastTitle('ERROR');
              setToastDesc('You already have this bank as default method.');
              setToastKind('error');
              setToastOpen(true);
              setTimeout(() => {
                setToastOpen(false);
              }, 3000)
            }else{
              setTab('authenticate')
            }
          }else{
            setTab('authenticate')
          }
        })
    }else{
      setToastTitle('ERROR');
      setToastDesc('Please complete all fields.');
      setToastKind('error');
      setToastOpen(true);
    } 
  }

  const btnVerify = () => {
    setLoading(true)
    if(verificationCode === oneTimeCode){
      setTimeout(() => {
        setLoading(false)
        setVerified(true)
      }, 2000)
    }else{
      setLoading(false)
      setToastTitle('ERROR');
      setToastDesc('Verification code is incorrect.');
      setToastKind('error');
      setToastOpen(true);
    }
  }

  const Verify = () => {
    setLoading(true)
    if(verificationCode === oneTimeCode && channelCode !== '' && accountName !== '' && accountNum !== ''){
      console.log('here')
      fetch(`${API}/set-payment-method`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            bank_code: channelCode,
            account_name: accountName,
            account_number: accountNum
        })
      })
      .then(res => res.json())
      .then(data => {
        if(data.status === 1 && data?.message === 'Payment method added successfully' ){
          setLoading(false)
          setVerified(true);
          setToastTitle('Success');
          setToastDesc('Verification code correct. Redirecting.');
          setToastKind('success');
          setToastOpen(true);
          setTimeout(() => {
            navigate('/payment_method_setup/complete');
          }, 2000)
        } else{
          setLoading(false)
          setVerified(false);
          setToastTitle('Error');
          setToastDesc( data?.message || 'Something went wrong. Try again.');
          setToastKind('error');
          setToastOpen(true);
          setTimeout(() => {
            navigate(user?.user_info?.role === 'merchant' ?
                '/merchant/settings'
                : '/affiliate/settings'
              );
          }, 4000)
        }
      })
    }else {
      setLoading(false)
      setVerified(false);
      setToastTitle('Error');
      setToastDesc('Verification code is incorrect');
      setToastKind('error');
      setToastOpen(true);
    }
  }

  const back = async () => {
    setTab('information')
  }

  const View = () => {
    setToastOpen5(true)
    setToastKind5('info')
    setToastTitle5('INFO')
    setToastDesc5('Banks and E-Wallets available at Xendit:')
  }

  return(
  <>
    <Toast
          description={toastDesc}
          isOpen={isToastOpen}
          onClose={() => setToastOpen(false)}
          title={toastTitle}
          toastKind={toastKind}
        />
    <ToastV5
          description={toastDesc5}
          isOpen={isToastOpen5}
          onClickSubmit={onSetBank}
          onClose={() => setToastOpen5(false)}
          title={toastTitle5}
          toastKind={toastKind5}
        />

  
  <Div className='overflow-y-scroll scrollbar-hide'>

  <FlexRow className="phone:px-5 tablet:px-10 laptop:px-20 w-full justify-between">

        <Div>
          <Link className="mr-auto flex flex-row items-center py-8" to='/'>
          <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
          <Text className="phone:hidden surfaceDuo:flex text-primary-100 mr-auto text-3xl font-semibold select-none">
            Xffiliate
          </Text>
          </Link>
        </Div>
        
        <UserDropdownV2 info={user.user_info} user={user} />
        
  </FlexRow>
  <hr className="text-secondary-300 laptop:mb-20 phone:mb-10 phone:mx-5 tablet:mx-10 laptop:mx-20" />

  <Link className='phone:ml-5 tablet:ml-10 laptop:ml-20 font-semibold text-primary-100' to={user?.user_info?.role === 'affiliate'? '/affiliate/settings' : '/merchant/settings'}>
      <i className="fas fa-angle-left"/>
      <i className="fas fa-angle-left mr-3"/>
      Back to Settings
  </Link>

  {
    (tab === 'information')?
    <>
     <FlexRow className=' mt-5 phone:flex medium:hidden justify-center'>
          <Stage
              isCurrent
              label="Add Information"
              stageNumber="1"
           />
     </FlexRow>

      <FlexRow
         className="items-center laptop:mb-7 phone:mb-2 phone:flex-col phone:items-start phone:space-y-4
         medium:flex-row medium:items-center medium:space-y-0 justify-center mt-5"
         >
           <Stage
              className='phone:hidden medium:flex'
              isCurrent
              label="Add Information"
              stageNumber="1"
           />
             <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden medium:block" />
               <Stage
                   className="phone:hidden medium:flex"
                   isCurrent={false}
                   label="Authenticate"
                   stageNumber="2"
               />

             <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden medium:flex" />
               <Stage
                   className="phone:hidden medium:flex"
                   isCurrent={false}
                   label="Complete"
                   stageNumber="3"
                />
     </FlexRow>

      <Div className="phone:px-4 phone:w-full medium:w-3/4 tabletWide:w-1/2 mx-auto pb-32">
        <FlexRow className=" phone:flex-col laptop:flex-row">
          <Flex className="phone:mt-10">
            <form>
              <FlexRow className='phone:flex-col surfaceDuo:flex-row w-full'>
              <div className='phone:w-full surfaceDuo:w-3/4 surfaceDuo:pr-2'>
              <Input
                label="Bank/E-Wallet *"
                name="bankName"
                onChange={setBankName}
                placeholder="Enter Bank Name / E-Wallet"
                type="text"
                value={bankName}
              />
              </div>
              <div className='phone:w-full surfaceDuo:w-1/4 surfaceDuo:pl-2'>
              <Input
                label="Bank Code *"
                name="accountName"
                onChange={setChannelCode}
                placeholder="PH_BANK"
                type="text"
                value={channelCode}
              />
              </div>
              </FlexRow>
              <Input
                label="Account Name *"
                name="accountName"
                onChange={setAccountName}
                placeholder="Enter Account Name"
                type="text"
                value={accountName}
              />
              <Input
                label="Account Number *"
                name="accountNum"
                onChange={setAccountNum}
                placeholder="Enter Account Number"
                type="text"
                value={accountNum}
              />
              <div className="font-semibold text-blue-200 hover:underline cursor-pointer"
                 onClick={View}
              >
                Click Here to View Bank Codes <i className='rounded mx-2 bg-primary-100 text-white px-2 py-1 fas fa-exclamation'/>
              </div>
              <hr className='text-white mt-3'/>

              <Div className='flex flex-row items-start mt-6 mb-4'>
              <Checkbox
                checked={agreement}
                className='mt-2'
                containerClass=""
                id="agreements"
                label=""
                onClick={() => setAgreement(!agreement)}
              />
              <Text>
              I have read and agree to Xendit's
              <a className='text-blue-100 mx-1 font-semibold hover:underline' href='https://www.xendit.co/en-ph/terms-and-conditions/' rel="noopener noreferrer" target='_blank'>Terms of Service,</a>
              <a className='text-blue-100 mr-1 font-semibold hover:underline' href={`https://www.xendit.co/en-ph/privacy-policy/#:~:text=The%20Personal%20Data%20we%20collect,Xendit's%20official%20social%20media%20platforms.`} rel="noopener noreferrer" target='_blank'>Privacy Policy.</a>
              </Text>
              </Div>
              
              <FlexRow className="mt-3 justify-center tabletWide:justify-end">
                <Button
                  className="mt-10 mb-20 phone:w-full phone:justify-center surfaceDuo:w-1/4"
                  onClick={ToAuth}
                >
                  Continue
                </Button>
              </FlexRow>

            </form>
          </Flex>
        </FlexRow>
      </Div>
    </>
    :
    <>
    </>
  }
  {
    (tab === 'authenticate')?
    <>
     <FlexRow className='mt-5 phone:flex medium:hidden justify-center'>
          <Stage
              isCurrent
              label="Authenticate"
              stageNumber="2"
           />
     </FlexRow>

      <FlexRow
         className="items-center laptop:mb-7 phone:mb-2 phone:flex-col phone:items-start phone:space-y-4
         medium:flex-row medium:items-center medium:space-y-0 justify-center mt-5"
         >
           <Stage
              className='phone:hidden medium:flex'
              isCurrent
              label="Add Information"
              stageNumber="1"
           />
             <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden medium:block" />
               <Stage
                   className="phone:hidden medium:flex"
                   isCurrent
                   label="Authenticate"
                   stageNumber="2"
               />

             <Div className="border-t-2 border-grey-300 w-10 mx-4 phone:hidden medium:flex" />
               <Stage
                   className="phone:hidden medium:flex"
                   isCurrent={false}
                   label="Complete"
                   stageNumber="3"
                />
     </FlexRow>

     <Div className="phone:px-4 phone:w-full medium:w-3/4 tabletWide:w-1/2 mx-auto">
     {
      codeSent?
      <>
        <Div className='my-10'>
          <Text>
            To confirm your identity, 
            we will send a 6-digit verification code to your registered email address.
          </Text>

          <Text className='my-7'>
            Registered email: <b>{user?.user_info?.email}</b>
          </Text>

          <Text>
            Enter Verification Code
          </Text>

          
          {
            verified?
            <FlexRow className='items-start phone:justify-center medium:justify-start'>
            <div className='rounded-lg px-3 py-4 border-2 border-primary-100 flex flex-row items-center justify-center'>
            <input
              value={verificationCode}
            />
            <i className="fas fa-check-circle text-green-100 text-xl mr-2" />
            </div>
            <Button
              className='mb-3 mx-3 mt-3 bg-green-100'
              >
                  Verified
            </Button>
            </FlexRow>
            :
            <>
            <FlexRow className='items-start phone:justify-center medium:justify-start'>
            <InputV3
                className='relative'
                maxlength={6}
                name="VerificationCode"
                onChange={setVerificationCode}
                placeholder="Enter code"
                type="number"
                value={verificationCode}
            />

            <Button
              className={`mb-3 mx-3 ${verificationCode === oneTimeCode? '' : 'bg-grey-100'}`}
              isLoading={loading}
              onClick={btnVerify}>
                  Verify
            </Button>
            </FlexRow>
            <span className='text-blue-100 hover:underline cursor-pointer' onClick={sendCode}>Resend Code</span>
            </>
          }

          {
            (verificationCode === oneTimeCode || verificationCode === '')?
            <></>
            :
            <>
              <Text className='text-red-400'>
                The code you entered is wrong.
              </Text>
            </>


          }
          
        </Div>
      </>
      :
      <>
        <Div className='my-10'>
          <Text>
            To confirm your identity, 
            we will send a 6-digit verification code to your registered email address.
          </Text>

          <Text className='my-7'>
            Registered email: <b>{user?.user_info?.email}</b>
          </Text>

          <Button className='phone:px-4 phone:py-4 w-60 flex-row flex justify-center' isLoading={loading} onClick={sendCode} >
            Send Verification Code
          </Button>
        </Div>
      </>
     }

     <hr className='text-grey-400 surfaceDuo:hidden' />    
           <FlexRow className='phone:justify-center medium:justify-end'> 
              <ButtonV2
                className="bg-grey-500 hover:bg-grey-100 mt-10 mb-20 phone:w-full phone:justify-center surfaceDuo:w-1/4"
                 onClick={back}
              >
                Back
              </ButtonV2>
               {

               (verified)?
               <Button
                 className=" mt-10 mb-20 phone:w-full phone:justify-center surfaceDuo:w-1/4"
                 isLoading={loading}
                 onClick={Verify}
               >
                 Continue
               </Button>
               :
               <Button
                 className="bg-grey-100 mt-10 mb-20 phone:w-full phone:justify-center surfaceDuo:w-1/4"
                 disabled
               >
                 Continue
               </Button>

               }
           </FlexRow>    
            
      </Div>
    </>
    :
    <>
    </>
  }
  </Div>
  </>
  )
}