import React, { FC } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { batch, useSelector } from 'react-redux';
import validator from 'validator';
import { useCookie } from '@hooks';
import { usersActions, useAppDispatch, selectUser } from '@redux';
import {
  Icons,
  H2,
  Info,
  Div,
  Flex,
  FlexRow,
  ButtonIcon,
  Input,
  PasswordInput,
  Button,
  Text,
  Toast,
} from '@core';
import { API } from '@config';

export const AdminSignup: FC<any> = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const user = useSelector(selectUser);

  const newDate: any = new Date();
  const userNum: number = newDate.getTime();

  const [cookie, updateCookie] = useCookie('token', '0');
  const [userId, updateUserId] = useCookie('userId', '0');
  const [email, setEmail] = React.useState('');
  const username: any = `user${userNum}`;
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [contact, setContact] = React.useState('');

  const [validEmail, setValidEmail] = React.useState('');
  const [validPassword, setValiPassword] = React.useState('');
  const [validConfirmPassword, setValisetConfirmPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('Account Created');
  const [toastDesc, setToastDesc] = React.useState('Redirecting to onboarding.');

  React.useEffect(() => {
    batch(async () => {
      await dispatch(usersActions.profileAdmin());
      if(user?.user_info?.role === 'Manager' || user?.user_info?.role === 'Editor'){
        navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`)
      }
    })
  }, [cookie, user])

  React.useEffect(() => {
    if(email === ''){
      fetch(`${API}/invite-details/${id}`)
      .then(res => res.json())
      .then(data => {
        if(data.status === 1){
          setEmail(data.data.email)
        }else{
          navigate('/')
        }
      })
    }
  })

  React.useEffect(() => {
    const regExp = /[a-zA-Z]/g;
    if(parseInt(contact) < 0 || regExp.test(contact)){
        setContact('')
    }
  }, [contact])

  const onSubmit = async () => {
    const isEmail = validator.isEmail(email);
    const isPassword = validator.isStrongPassword(password, {
      minLength: 6,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });
    const isPasswordSame = password === confirmPassword;
    setValidEmail(email === '' ? 'Email is required' : `${!isEmail ? 'Invalid email' : ''}`);
    setValiPassword(
      password === '' ? 'Password is required' : `${!isPassword ? 'Invalid password' : ''}`,
    );
    setValisetConfirmPassword(
      confirmPassword === ''
        ? 'Password is required'
        : `${password !== confirmPassword ? `Password don't match` : ''}`,
    );

    if (email !== '' && password !== '' && confirmPassword !== '' && lastname !== '' && firstname !== '' && contact !== '') {
      if (isEmail && isPassword && isPasswordSame) {
        setLoading(true)
        fetch(`${API}/register-admin/${id}`,{
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
              email,
              username,
              password,
              lastname,
              firstname,
              contact
            })
          })
          .then(res => res.json())
          .then(data => {
            if (data?.status === 1) {
              setToastTitle('Account Created');
              setToastDesc('Redirecting to dashboard.');
              setToastKind('success');
              setToastOpen(true);
              setLoading(false);
              batch(async () => {
                const login: any = await dispatch(usersActions.adminLogin({ email, password }));
                updateCookie(`${login?.payload?.access_token}`, 24);
                updateUserId(`${login?.payload?.userInfo?.id}`, 24);
                await dispatch(usersActions.profileAdmin());
                setTimeout(() => {
                  if(user?.user_info?.role === 'Manager' || user?.user_info?.role === 'Editor'){
                    navigate(`/merchant/admin_dashboard/${user.user_info.role.toLowerCase()}`)
                  }
                }, 2000)
              })              
            } else {
              setToastTitle('Invalid Input');
              setToastDesc(data?.message || 'Something went wrong. Try again!');
              setToastKind('error');
              setToastOpen(true);
              setLoading(false);
            }
          })
      }else{
        setToastTitle('Invalid Input');
        setToastDesc('Please check input fields.');
        setToastKind('error');
        setToastOpen(true);
        setLoading(false);
      }
    }
  };

  return (
    <Flex className="phone:px-5 tablet:px-10 laptop:px-20 overflow-y-auto h-full">
      <span className='hidden'>Unused Variables {cookie}{userId}</span>
      <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <Div className="mr-auto flex flex-row items-center py-8">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <hr className="text-secondary-300" />
      <Flex
        className="flex-col items-center w-full mx-auto"
      >
        <div className='phone:w-full medium:w-3/4 tabletWide:w-3/5 laptop:w-2/5 text-center'>
        <H2 className="text-primary-100 mt-10">Admin Sign Up</H2>
        <Info className="text-secondary-100 mt-2">
          Already have an account?{' '}
          <Link className="underline text-primary-100" to="/">
            Login
          </Link>
        </Info>
        <Div className="w-full mt-10">
          <ButtonIcon
            className="w-full justify-center mb-4 hidden"
            icon="fab fa-google font-bold"
            // onClick={() => setToastOpen(true)}
          >
            Sign up with Google
          </ButtonIcon>

          <FlexRow className='hidden'>
            <Div className="border-t-2 border-secondary-200 w-full" />
            <Info className="text-secondary-100 font-semibold mx-4 ">OR</Info>
            <Div className="border-t-2 border-secondary-200 w-full" />
          </FlexRow>
          <form className="mt-0">
            <FlexRow className='w-full phone:flex-col surfaceDuo:flex-row'>
              <Input
                containerClass='w-full surfaceDuo:mr-2'
                label="First Name *"
                name="firstname"
                onChange={setFirstname}
                placeholder="Enter your first name"
                type="text"
                value={firstname}
              />
              <Input
                containerClass='w-full surfaceDuo:ml-2'
                label="Last Name *"
                name="lastname"
                onChange={setLastname}
                placeholder="Enter your last name"
                type="text"
                value={lastname}
              />
            </FlexRow>
            <FlexRow className='w-full phone:flex-col surfaceDuo:flex-row'>
            <Input
              containerClass='w-full surfaceDuo:mr-2'
              disabled={email !== ''}
              errorLabel={validEmail}
              label="Email address *"
              name="email"
              onChange={setEmail}
              type="email"
              validation={validEmail !== ''}
              value={email}
            />
            <Input
              containerClass='w-full surfaceDuo:ml-2'
              inputmode='numeric'
              label="Contact Number *"
              name="contact"
              onChange={setContact}
              placeholder="Enter your contact number"
              type="text"
              value={contact}
            />
            </FlexRow>
            <FlexRow className='w-full phone:flex-col surfaceDuo:flex-row'>
            <PasswordInput
              containerClass='w-full surfaceDuo:mr-2'
              label="Password *"
              name="password"
              onChange={setPassword}
              placeholder="Enter your Password"
              validation={validPassword !== ''}
              value={password}
            />
            <PasswordInput
              containerClass='w-full surfaceDuo:ml-2'
              label="Confirm Password *"
              name="confirm-password"
              onChange={setConfirmPassword}
              placeholder="Enter your Password"
              validation={validConfirmPassword !== ''}
              value={confirmPassword}
            />
            </FlexRow>

            <Div
              className="flex tablet:flex-row tablet:items-center mb-5 font-poppins 
            text-base phone:text-sm tablet:text-base phone:flex-col phone:items-start w-full"
            >
              <Div className='phone:w-full tablet:w-1/2 flex flex-col items-start'>
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className="mb-1 mr-auto">&#8226; At least 6 characters</Text>
                </Div>
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className="mb-1 mr-auto">&#8226; At least 1 capital letter</Text>
                </Div>
              </Div>
              <Div className='phone:w-full tablet:w-1/2 flex flex-col items-start'>
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className="mb-1 mr-auto">&#8226; At least 1 number</Text>
                </Div>
                <Div
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${
                          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                            ? 'text-green-100'
                            : 'text-red-100'
                        }`
                  }
                >
                  <Text className="mb-1 mr-auto">&#8226; At least 1 special character</Text>
                </Div>
              </Div>

              <ul className="list-disc hidden">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${password.length >= 6 ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className="text-left mb-1">At least 6 characters</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/[A-Z]/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className="text-left mb-1">At least 1 capital letter</Text>
                </li>
              </ul>
              <ul className="list-disc tablet:ml-20 phone:ml-0 hidden">
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${/\d/.test(password) ? 'text-green-100' : 'text-red-100'}`
                  }
                >
                  <Text className="text-left mb-1">At least 1 number</Text>
                </li>
                <li
                  className={
                    password.length === 0
                      ? 'text-grey-100'
                      : `${
                          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                            ? 'text-green-100'
                            : 'text-red-100'
                        }`
                  }
                >
                  <Text className="text-left mb-1">At least 1 special character</Text>
                </li>
              </ul>
            </Div>

            <Button className="w-full py-5 justify-center" isLoading={loading} onClick={onSubmit}>
              Sign Up Now
            </Button>

            <Text className="text-base text-center mt-14 mb-10">
              By creating an account, you agree to Xffiliate’s{' '}
              <a className="underline text-primary-100" href='https://xffiliate.com/terms-of-service/' rel="noopener noreferrer" target='_blank'>
                Terms of Service
              </a>{' '}
              Xffiliate’s{' '}
              <a className="underline text-primary-100" href='https://xffiliate.com/privacy-policy/' rel="noopener noreferrer" target='_blank'>
                Privacy Policy
              </a>
            </Text>
          </form>
        </Div>
        </div>

      </Flex>
    </Flex>
  );
};