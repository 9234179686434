import React from 'react';
import { batch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useSound from 'use-sound';
import { useAppDispatch, usersActions } from '@redux';
import { useCookie } from '@hooks';
import { Flex, Div, Text, Icons, SoundMp3 } from '@core';

export const Logout = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [token, updateToken] = useCookie('token', '0');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('session');
  const [playSound1] = useSound(SoundMp3.Sound1);

  React.useEffect(() => {
    batch(async () => {
      if(!actionQuery){
        playSound1()
      }
      updateToken('', 1);
      localStorage.removeItem('message')
      await dispatch(usersActions.logout());
      setTimeout(() => {
        if(actionQuery === 'timeout'){
          navigate(`/?session=timeout`);
        }else{
          navigate(`/`);
        }
      }, 2000);
    });
  });

  return (
    <Flex className="phone:px-5 justify-center items-center">
      <Div className='hidden'>Unused {token}</Div>
      <Div className="flex flex-row items-center">
        <img alt="Xffiliate" className="w-12 h-12 mr-3 select-none" src={Icons.xffiliate} />
        <Text className="text-primary-100 mr-auto text-3xl font-semibold select-none">
          Xffiliate
        </Text>
      </Div>
      <Text className='text-center mt-4'>
        Logging out...
        <i className="fad fa-spinner-third animate-spin ml-3" />
      </Text>
    </Flex>
  );
};