import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FlexRow } from '@core'

interface ButtonProps {
  className?: string;
  title: string;
  onClose: any;
  description: string;
  isOpen: boolean;
  toastKind: string;
  onClickSubmit?: any;
  onClickSubmitComp?: any;
  onClickSubmitEmail?: any;
  onChange?: any;
  onChangeComp?: any;
  onChangeEmail?: any;
  onReset?: any;
  onSubmit?: any;
  onSubmitComp?: any;
  onSubmitEmail?: any;
  value?: string;
  valueComp?: string;
  valueEmail?: string;
}

export const ToastInputMultipleV2: FC<ButtonProps> = ({
  title, description, onClose, onChange, onChangeComp, onChangeEmail,
  onReset, onSubmit,onSubmitComp, onSubmitEmail, onClickSubmit,
  onClickSubmitComp, onClickSubmitEmail, isOpen, toastKind,
  value, valueComp, valueEmail
}) => {

  const [selected, setSelected] = React.useState('name');

  let color = 'green-100';

  let icon = 'fa-check';
  if (toastKind === 'success') {
    color = 'green-100';
    icon = 'fa-check';
  } else if (toastKind === 'error') {
    color = 'red-100';
    icon = 'fa-times';
  } else if (toastKind === 'warning') {
    color = 'yellow-100';
    icon = 'fa-exclamation';
  } else if (toastKind === 'info') {
    color = 'primary-100';
    icon = 'fa-exclamation';
  }

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed top-0 left-0 right-0 bottom-0 z-40 h-screen w-screen flex justify-center items-center bg-secondary-200"
        onClose={onClose}
      >
        <div className="phone:w-full medium:w-3/4 tabletWide:w-1/2 phone:w-full h-screen text-center">

          <Transition.Child
            as={Fragment}
            enter="transition ease duration-300 transform"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div
              className="h-full w-full overflow-hidden text-left z-50 surfaceDuo:px-5 pt-5
      align-middle transition-all transform flex flex-col justify-center items-center"
            >
              <div
                className={`flex flex-col justify-around p-4 mb-4 text-gray-500 w-full
            bg-white border border-primary-100 rounded-lg shadow-xl dark:text-gray-400 font-poppins bg-white`}
                id="toast-success"
                role="alert"
              >
              <FlexRow className='w-full'>
                <div
                  className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 
                  text-white bg-${color} rounded-lg mr-1`}
                >
                  <i className={`fas ${icon}`} />
                </div>
                <div>
                  <div className={`ml-3 font-semibold text-base text-${color}`}>
                    {title.toUpperCase()}
                  </div>
                  <div className="ml-3 text-sm font-normal text-primary-100">{description}</div>
                  {
                    toastKind === 'error' ?
                    <div className='hidden hover:underline text-blue-100 font-semibold ml-3 mt-2 cursor-pointer' onClick={onReset}>
                      Search again.
                    </div>
                    :
                    <></>
                  }
                  {
                    selected === 'name' && toastKind !== 'error' ?
                    <div className="ml-3 text-sm font-normal text-primary-100 hidden">Please enter a keyword to search.</div>:<></>
                  }
                  {
                    selected === 'company' && toastKind !== 'error' ?
                    <div className="ml-3 text-sm font-normal text-primary-100 hidden">Please enter a keyword to search.</div>:<></>
                  }
                  {
                    selected === 'email' && toastKind !== 'error' ?
                    <div className="ml-3 text-sm font-normal text-primary-100 hidden">Please enter a keyword to search.</div>:<></>
                  }

                </div>
                <button
                  aria-label="Close"
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-grey-200 hover:text-grey-100 
              rounded-lg inline-flex h-8 w-8 justify-center items-center"
                  data-collapse-toggle="toast-success"
                  onClick={onClose}
                  type="button"
                >
                  <i className="fas fa-times text-lg" />
                </button>
                </FlexRow>

              <div className='flex flex-row items-center justify-start phone:w-full surfaceDuo:w-auto my-4 surfaceDuo:ml-4'>
              <label className='hidden surfaceDuo:flex surfaceDuo:mx-4' htmlFor='sort'>Search by:</label>
              <select className='phone:w-full surfaceDuo:w-56 border border-grey-400 text-secondary-200' name="sort" onChange={(e:any) => setSelected(e.target.value) }>
                  <option value="name">Name</option>
                  <option value='email'>Email</option>
              </select>
              </div>

              {
                selected === 'name' ?
                <div>
                  <form  className='flex flex-row phone:justify-between surfaceDuo:justify-start w-full' onSubmit={onSubmit}>
                    <input className='border-2 border-primary-100 w-3/4 rounded-lg surfaceDuo:ml-8' name='search' onChange={onChange} placeholder='Enter a keyword' required type='search' value={value}/>
                    <button className='bg-primary-100 rounded-lg p-2 ml-2 text-white font-semibold' onClick={onClickSubmit} type='submit'>
                      Search
                    </button>
                  </form>
                </div>
                :
                <></>
              }
              {
                selected === 'company' ?
                <div>
                  <form  className='flex flex-row phone:justify-between surfaceDuo:justify-start w-full' onSubmit={onSubmitComp}>
                    <input className='border-2 border-primary-100 w-3/4 rounded-lg surfaceDuo:ml-8' name='search' onChange={onChangeComp} placeholder='Enter a keyword' required type='search' value={valueComp}/>
                    <button className='bg-primary-100 rounded-lg p-2 ml-2 text-white font-semibold' onClick={onClickSubmitComp} type='submit'>
                      Search
                    </button>
                  </form>
                </div>
                :
                <></>
              }
              {
                selected === 'email' ?
                <div>
                  <form  className='flex flex-row phone:justify-between surfaceDuo:justify-start w-full' onSubmit={onSubmitEmail}>
                    <input className='border-2 border-primary-100 w-3/4 rounded-lg surfaceDuo:ml-8' name='search' onChange={onChangeEmail} placeholder='Enter a keyword' required type='search' value={valueEmail}/>
                    <button className='bg-primary-100 rounded-lg p-2 ml-2 text-white font-semibold' onClick={onClickSubmitEmail} type='submit'>
                      Search
                    </button>
                  </form>
                </div>
                :
                <></>
              }

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};