import React, { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, batch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { selectUser } from '@redux';
import { Div, FlexRow, Text, Button, ButtonV2, Radio, Toast } from '@core';
import { Pagination, PaymentData } from '@components';
import { getCookie } from '@hooks';
import { API } from '@config';
import { FrequencyOptions, FrequencyDataDefault, Days } from '@constants';
// import { FrequencyOptions, FrequencyDataDefault, Days, Months } from '@constants';
import { getFrequency } from '@services';

export const Transactions: FC<any> = () => {

  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const token = getCookie('token');
  const newDate = new Date();
  const resultDate = new Date(newDate);
  // const [date, setDate] = React.useState<any>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get('action');

  const [SortList, setSortList] = React.useState<any>([]);
  const [frequencyData, setFrequencyData] = React.useState<any>(FrequencyDataDefault);
  const [noData, setData] = React.useState(true);

  const [frequency, setFrequency] = React.useState('');
  const [dateDisburse, setDateDisburse] = React.useState('');
  const [timeDisburse, setTimeDisburse] = React.useState('');
  const [dateCutoff, setDateCutoff] = React.useState('');
  const [timeCutoff, setTimeCutoff] = React.useState('');
  // const [cutoff, setCutoff] = React.useState('-- --');

  const [edit, setEdit] = React.useState(false);
  const [transHistory, setTransHistory] = React.useState(0);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentHistory = SortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const [loading, setLoading] = React.useState(false);
  const [loadingTrans, setLoadingTrans] = React.useState(false);
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState('success');
  const [toastTitle, setToastTitle] = React.useState('');
  const [toastDesc, setToastDesc] = React.useState('');

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  const randomID = new Date();
  const fileName = randomID.getTime();

  const [csvData, setCsvData] = React.useState([]);

  const headers: any = [
    { label: "Transaction Date", key: "date" },
    { label: "Reference No.", key: "reference_id" },
    { label: "Total Amount", key: "total_amount" },
    { label: "Bank Name", key: "payment_channel" },
    { label: "Payment Type", key: "payment_method" },
    { label: "Status", key: "status" }
  ];

  React.useEffect(() => {
    if(actionQuery === 'transaction'){
      navigate('/merchant/settings')
    }
  })

  React.useEffect(() => {
    if(noData){
      batch(async() => {
        const data: any = await getFrequency();

        if(data.data.status === 1){
          setFrequencyData(data.data.data)
            // const result = new Date(newDate);
            // const days = (data.data.data.date_cutoff-result.getDay())+7
            // const timestamp = result.setDate(result.getDate() + days)
            // const fixedDate = new Date(timestamp)
            // setDate(fixedDate.toString().substring(4, 15))
            setData(false)
            if(data.data.data.frequency === 'Weekly'){
              // setCutoff(`${Days[data.data.data.date_cutoff]}`)
            }else if(data.data.data.frequency === 'Monthly'){
              if(resultDate.getDate() > 3 && resultDate.getFullYear() !== 11){
                // setCutoff(`${Months[resultDate.getMonth()+1]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()}`)
              }else if(resultDate.getDate() > 3 && resultDate.getFullYear() === 11){
                // setCutoff(`${Months[resultDate.getMonth()+1]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()+1}`)
              }else if(resultDate.getDate() <= 3){
                // setCutoff(`${Months[resultDate.getMonth()]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()}`)
              }
            }else if(data.data.data.frequency === 'Bi-Monthly'){
              if(resultDate.getDate() > 16 && resultDate.getMonth() !== 11){
                // setCutoff(`${Months[resultDate.getMonth()+1]} 1, ${resultDate.getFullYear()}`)
              }else if(resultDate.getDate() > 16 && resultDate.getMonth() === 11){
                // setCutoff(`${Months[resultDate.getMonth()+1]} 1, ${resultDate.getFullYear()+1}`)
              }else if(resultDate.getDate() <= 16){
                // setCutoff(`${Months[resultDate.getMonth()]} 16, ${resultDate.getFullYear()}`)
              }
            }
        }else{
            setFrequencyData(FrequencyDataDefault)
            setData(true)
        }
      })
    }
  }, [token])

  React.useEffect(() => {
    setLoadingTrans(true)
    fetch(`${API}/transaction-history`,{
      method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.json())
      .then(data => {
        if(data.data.length > 0){
          setTransHistory(1)
          setSortList(data.data)
          setCsvData(data.data)
        }else{
          setTransHistory(0)
        }

        setTimeout(() => {
          setLoadingTrans(false)
        }, 1500)
      })
  }, [token])

  const Change = async () => {
      setEdit(true);
  }

  const Save = async () => {
    setLoading(true)
    setEdit(false)
    fetch(`${API}/create-disbursement-frequency`,{
      method: 'POST',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      body: JSON.stringify({
            frequency,
            date_disburse: dateDisburse,
            time_disburse: timeDisburse,
            date_cutoff: dateCutoff,
            time_cutoff: timeCutoff
        })
      })
      .then(res => res.json())
      .then(dataA => {
        if(dataA.status === 1){
          batch(async() => {
            const data: any = await getFrequency();

            if(data.data.status === 1){
              setFrequencyData(data.data.data)
                // const result = new Date(newDate);
                // const days = (data.data.data.date_cutoff-result.getDay())+7
                // const timestamp = result.setDate(result.getDate() + days)
                // const fixedDate = new Date(timestamp)
                // setDate(fixedDate.toString().substring(4, 15))
                setData(false)
                if(data.data.data.frequency === 'Weekly'){
                  // setCutoff(`${Days[data.data.data.date_cutoff]}`)
                }else if(data.data.data.frequency === 'Monthly'){
                  if(resultDate.getDate() > 3 && resultDate.getFullYear() !== 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()}`)
                  }else if(resultDate.getDate() > 3 && resultDate.getFullYear() === 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()+1}`)
                  }else if(resultDate.getDate() <= 3){
                    // setCutoff(`${Months[resultDate.getMonth()]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()}`)
                  }
                }else if(data.data.data.frequency === 'Bi-Monthly'){
                  if(resultDate.getDate() > 16 && resultDate.getMonth() !== 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} 1, ${resultDate.getFullYear()}`)
                  }else if(resultDate.getDate() > 16 && resultDate.getMonth() === 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} 1, ${resultDate.getFullYear()+1}`)
                  }else if(resultDate.getDate() <= 16){
                    // setCutoff(`${Months[resultDate.getMonth()]} 16, ${resultDate.getFullYear()}`)
                  }
                }
            }else{
                setFrequencyData(FrequencyDataDefault)
                setData(true)
            }
          })
          setLoading(false)
          setFrequencyData(dataA.data)
          setToastTitle('SUCCESS');
          setToastDesc('Frequency details has been updated.');
          setToastKind('success');
          setToastOpen(true);
        }else{
          setLoading(false)
          setToastTitle('ERROR');
          setToastDesc(dataA.message || 'Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
        }
      })
  }

  const Update = async () => {
    setLoading(true)
    setEdit(false)
    fetch(`${API}/update-disbursement-frequency`,{
      method: 'PUT',
      headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      body: JSON.stringify({
            frequency,
            date_disburse: dateDisburse,
            time_disburse: timeDisburse,
            date_cutoff: dateCutoff,
            time_cutoff: timeCutoff

        })
      })
      .then(res => res.json())
      .then(dataA => {
        if(dataA.status === 1){
          batch(async() => {
            const data: any = await getFrequency();

            if(data.data.status === 1){
              setFrequencyData(data.data.data)
                // const result = new Date(newDate);
                // const days = (data.data.data.date_cutoff-result.getDay())+7
                // const timestamp = result.setDate(result.getDate() + days)
                // const fixedDate = new Date(timestamp)
                // setDate(fixedDate.toString().substring(4, 15))
                setData(false)
                if(data.data.data.frequency === 'Weekly'){
                  // setCutoff(`${Days[data.data.data.date_cutoff]}`)
                }else if(data.data.data.frequency === 'Monthly'){
                  if(resultDate.getDate() > 3 && resultDate.getFullYear() !== 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()}`)
                  }else if(resultDate.getDate() > 3 && resultDate.getFullYear() === 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()+1}`)
                  }else if(resultDate.getDate() <= 3){
                    // setCutoff(`${Months[resultDate.getMonth()]} ${data.data.data.date_cutoff}, ${resultDate.getFullYear()}`)
                  }
                }else if(data.data.data.frequency === 'Bi-Monthly'){
                  if(resultDate.getDate() > 16 && resultDate.getMonth() !== 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} 1, ${resultDate.getFullYear()}`)
                  }else if(resultDate.getDate() > 16 && resultDate.getMonth() === 11){
                    // setCutoff(`${Months[resultDate.getMonth()+1]} 1, ${resultDate.getFullYear()+1}`)
                  }else if(resultDate.getDate() <= 16){
                    // setCutoff(`${Months[resultDate.getMonth()]} 16, ${resultDate.getFullYear()}`)
                  }
                }
            }else{
                setFrequencyData(FrequencyDataDefault)
                setData(true)
            }
          })
          setLoading(false)
          setFrequencyData(dataA.data)
          setToastTitle('SUCCESS');
          setToastDesc('Frequency details has been updated.');
          setToastKind('success');
          setToastOpen(true);
        }else{
          setLoading(false)
          setToastTitle('ERROR');
          setToastDesc(dataA.message || 'Something went wrong. Please try again.');
          setToastKind('error');
          setToastOpen(true);
        }
      })
  }

  const Cancel = async () => {
    setEdit(false)
  }

  return(
  <>
  <Toast
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

  <Div
    className="phone:px-2 surfaceDuo:px-4 tabletWide:px-8 flex flex-col w-full h-full pb-72"
  >
        <Text className="font-poppins font-semibold text-xl mt-8 mb-1 text-primary-100">
            Frequency
        </Text>
        <hr className="text-grey-400 mb-10 mt-1" />

        <FlexRow className='phone:items-start phone:flex-col surfaceDuo:flex-row'>
        <Div className='w-full surfaceDuo:w-1/2 medium:w-1/3'>
          <Text className='mb-5 font-semibold text-secondary-200'>Disbursement Notifications</Text>  
        </Div>
        {
          edit?
          <>
            <Div className='flex phone:flex-col surfaceDuo:flex-row hover:bg-grey-500 w-full mb-8'>
              <Text className='font-semibold mx-2 mb-3'>
                Frequency
              </Text>

              <Div className='flex flex-col mx-5'>

                {
                  FrequencyOptions.map((option:any) => (
                    <Div key={option.label}>
                      <Radio
                        isChecked={frequency === option.label}
                        label={option.label}
                        name={option.name}
                        onClick={() => {
                          setFrequency(option.label)
                          setDateDisburse(option.disburseDay)
                          setTimeDisburse(option.disburseTime)
                          setDateCutoff(option.cutoffDay)
                          setTimeCutoff(option.cutoffTime)
                        }}
                        optionId="frequency"
                      />
                      <Text className='ml-5 mb-2 mt-2 text-secondary-200'>
                          {option.cutOff}
                      </Text>
                      {/* <Text className='ml-5 mb-4 mt-2 text-secondary-200'>
                          {option.details}
                      </Text> */}
                    </Div>
                  ))
                }

                <FlexRow>
                    <Button
                        className='w-24 justify-center phone:py-2'
                        isLoading={loading}
                        onClick={noData? Save : Update}
                    >
                      Save
                    </Button>
                    <ButtonV2
                        className='bg-grey-500 hover:bg-grey-100 w-24 justify-center phone:py-2'
                        onClick={Cancel}
                    >
                      Cancel
                    </ButtonV2>
                </FlexRow>

              </Div>
            </Div>
          </>
          :
          <>
          {
            noData ?
            <>
            <FlexRow className='phone:flex-col surfaceDuo:flex-row hover:bg-grey-500 w-full'>
              <Div className='phone:w-full surfaceDuo:w-3/4'>
                <Text className='font-semibold text-blue-100 mb-4 text-lg flex flex-row items-center'>
                  Frequency
                  <span className="flex text-base text-blue-100 font-medium phone:mx-0 surfaceDuo:mx-3 cursor-pointer font-semibold"  onClick={Change} title='Click to create frequency'>
                    <i className="phone:hidden surfaceDuo:block fas fa-plus text-blue-100 text-xl mr-2 ml-auto cursor-pointer"/>
                  </span>
                </Text>
                <Text className='text-secondary-200'>
                  No records found.
                </Text>
                <Text className='text-secondary-200 my-5'>
                  This feature will send email notifications of
                  scheduled disbursement and cutoff dates.
                </Text>
              </Div>

              <Div className='h-full phone:w-full surfaceDuo:hidden flex items-start phone:justify-start surfaceDuo:justify-end mb-auto'>
                <span className="flex text-base text-blue-100 font-medium phone:mx-0 surfaceDuo:mx-3 cursor-pointer border-b-2 border-white hover:border-b-2 hover:border-blue-100 font-semibold"  onClick={Change}>
                    Create
                </span>
              </Div>
            </FlexRow>
            </>
            :
            <>
            <FlexRow className='phone:flex-col surfaceDuo:flex-row hover:bg-grey-500 w-full'>
              <Div className='phone:w-full surfaceDuo:w-3/4'>
                <Text className='font-semibold text-blue-100 mb-4 text-lg flex flex-row items-center'>
                  {frequencyData.frequency}
                  <span className="flex text-base text-blue-100 font-medium phone:mx-0 surfaceDuo:mx-3 cursor-pointer font-semibold"  onClick={Change} title='Click to change frequency'>
                    <i className="phone:hidden surfaceDuo:block fas fa-edit text-blue-100 text-xl mr-2 ml-auto cursor-pointer"/>
                  </span>
                </Text>
                {
                  frequencyData.frequency === 'Weekly'?
                  <>
                  <Text className='text-secondary-200'>
                    Reminders will be sent every {Days[frequencyData.date_cutoff]} at 8:00 PM PHT.
                  </Text>
                  {/* <Text className='text-secondary-200'>
                    Disbursement is every {Days[frequencyData.date_disburse]} at 8:00 PM PHT
                  </Text>
                  <Text className='text-secondary-200 my-5'>
                    Next cut-off is on
                    <span className='font-bold text-blue-100 mx-2'>
                      {date} {Days[frequencyData.date_cutoff]} at 8:00 PM PHT
                    </span>
                  </Text> */}
                  </>
                  :
                  <></>
                }
                {
                  frequencyData.frequency === 'Bi-Monthly'?
                  <>
                  <Text className='text-secondary-200'>
                    Reminders will be sent every 1st and 16th day of the month at 8:00 PM PHT.
                  </Text>
                  {/* <Text className='text-secondary-200'>
                    Disbursement is every 3rd and 18th day of the month at 8:00 PM PHT
                  </Text>
                  <Text className='text-secondary-200 my-5'>
                    Next cut-off is on
                    <span className='font-bold text-blue-100 mx-2'>
                      {cutoff} at 8:00 PM PHT
                    </span>
                  </Text> */}
                  </>
                  :
                  <></>
                }
                {
                  frequencyData.frequency === 'Monthly'?
                  <>
                  <Text className='text-secondary-200'>
                    Reminders will be sent every 1st day of the month at 8:00 PM PHT.
                  </Text>
                  {/* <Text className='text-secondary-200'>
                    Disbursement is every 3rd day of the month at 8:00 PM PHT
                  </Text>
                  <Text className='text-secondary-200 my-5'>
                    Next cut-off is on
                    <span className='font-bold text-blue-100 mx-2'>
                      {cutoff} at 8:00 PM PHT
                    </span>
                  </Text> */}
                  </>
                  :
                  <></>
                }
              </Div>

              <Div className='h-full phone:w-full surfaceDuo:hidden flex items-start phone:justify-start surfaceDuo:justify-end mb-auto'>
                <span className="flex text-base text-blue-100 font-medium phone:mx-0 surfaceDuo:mx-3 cursor-pointer border-b-2 border-white hover:border-b-2 hover:border-blue-100 font-semibold"  onClick={Change}>
                    Change
                </span>
              </Div>
            </FlexRow>
            </>
          }
          </>
        }
          
        </FlexRow>

      <FlexRow className='border-b border-grey-400 mt-4 mb-10 pb-2 w-full justify-between'>
        <Text className="font-poppins font-semibold text-xl text-primary-100">
            Transaction History
        </Text>
        {
          csvData.length > 0?
          <CSVLink data={csvData} filename={`transactions-${fileName}.csv`} headers={headers}>
            <div className='font-semibold text-primary-100 hover:underline'>
              <i className="fas fa-download text-blue-100 mx-2 phone:hidden surfaceDuo:inline"/>
              <span className='hidden tablet:inline'>Export to CSV</span>
            </div>
          </CSVLink>
          :
          <div className='font-semibold text-primary-100 hover:underline cursor-not-allowed'>
            <i className="fas fa-download text-blue-100 mx-2"/>
            <span className='hidden tablet:inline'>Export to CSV</span>
          </div>
        }
      </FlexRow>

            <Div className='hidden medium:flex medium:flex-row tablet:hidden tabletWide:flex'>
      
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Type
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Date
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Reference No.
                </Text>
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Amount
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center"
                >
                  Status
                </Text> 
                <Text
                  className="text-base text-secondary-200 font-medium w-1/6 text-center "
                >
                  Action
                </Text>

            </Div>
        <hr className="hidden medium:block tablet:hidden tabletWide:block text-grey-400 my-4" />

        {
          transHistory > 0 && !loadingTrans ?
          <>
          <Div className='w-full'>
          {
            currentHistory.map((list: any) =>(
              <PaymentData
                amount={list.total_amount}
                id={list.id}
                key={list.id}
                paymentDate={list.date}
                paymentType={list.payment_channel}
                referenceNum={list.reference_id}
                status={list.status}
                user={user}
              />
              ))
          }
          </Div>
          <div className="flex justify-center mb-5">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={SortList.length}
            />
          </div>   
          </>
          :
          <>
          {
            loadingTrans?
            <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                <Text>
                  Loading data ...
                  <i className="fad fa-spinner-third animate-spin text-2xl mx-3" />
                </Text>
            </Div>
            :
            <Div className='bg-grey-500 text-center py-8 mt-3 mb-10 '>
                <Text>
                  No data to be displayed for transaction history.
                </Text>
            </Div>
          }
          </>
        }
  
  </Div>
  </>
  )
};