import { Months } from '@constants';

export function formatDate(value:any) {

    const date = new Date(value);
    const zero: any = '0';

    const year = date.getFullYear();
    const month = (date.getMonth() + 1);
    const newMonth = month > 9 ? month.toString() : zero.concat(month.toString());
    const day = date.getDate();
    const newDay = day > 9 ? day.toString() : zero.concat(day.toString());

    return newMonth.concat('/', newDay, '/', year.toString());
};

export function formatDate2(value:any) {

    const date = new Date(value);
    const zero: any = '0';

    const year = date.getFullYear();
    const month = (date.getMonth() + 1);
    const newMonth = month > 9 ? month.toString() : zero.concat(month.toString());
    const day = date.getDate();
    const newDay = day > 9 ? day.toString() : zero.concat(day.toString());

    return Months[newMonth-1].concat(' ', newDay, ', ', year.toString());
};

export function formatTime(value:any) {
    const hour = parseFloat(value.substring(0, 3));
    const newHour = () => 12-hour > 0 ? hour : Math.abs(12-hour);
    const minutes = value.substring(4, 6);
    const meridian = () => 12-hour > 0 ? 'AM' : 'PM' ;

    switch (hour) {
        case 0 :
            return `12:${minutes < 10 ? `${minutes}` : minutes} AM`;
        case 12 :
            return `12:${minutes} PM`;
        default :
            return `${newHour()}:${minutes < 10 ? `${minutes}` : minutes} ${meridian()}`;
    }

};

export function formatTime2(value:any) {
    const hour = value.getHours();
    const newHour = () => 12-hour > 0 ? hour : Math.abs(12-hour);
    const minutes = value.getMinutes();
    const meridian = () => 12-hour > 0 ? 'AM' : 'PM' ;

    switch (hour) {
        case 0 :
            return `12:${minutes < 10 ? `0${minutes}` : minutes} AM`;
        case 12 :
            return `12:${minutes < 10 ? `0${minutes}` : minutes} PM`;
        default :
            return `${newHour()}:${minutes < 10 ? `0${minutes}` : minutes} ${meridian()}`;
    }

};