import axios from 'axios';
import { API } from '@config';
import { getCookie } from '@hooks';

interface PARTNER {
  user_id: string;
  product_id: string;
  status: string;
  role: string;
}

interface EMAIL {
  email: string;
  password: string;
  id: number;
}

export const addPartner = async (body: PARTNER) => {
  const token = getCookie('token');
  return axios
    .post(`${API}/add-applicant`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const addPartnerAdmin = async (body: PARTNER) => {
  const token = getCookie('token');
  return axios
    .post(`${API}/add-admin-applicant`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};

export const sendPartnerEmail = async (body: EMAIL) => {
  const token = getCookie('token');
  return axios
    .post(`${API}/create-partner-email`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response);
};