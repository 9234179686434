import React, { FC } from 'react';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import ReactTooltip from 'react-tooltip';
import { partnersActions, useAppDispatch } from '@redux';
import { Div, FlexRow, Text, Toast, ToastV4 } from '@src/core';
import { formatCount } from '@src/helpers/formatNumber';

interface PartnerProps {
	firstName: string;
	lastName: string;
	socialLink: string;
	companyName: string;
	companyLink: string;
	dateApproved: string;
	earnings: number;
	clicks: number;
	conversion: number;
	product: string;
	affiliateId: number;
	user: any;
	Select: any;
	selectedAll: boolean;
	selectedData: any;
	selection: boolean;
	data: any;
	currentPage: number;
}

export const PartnerList: FC<PartnerProps> = ({
	firstName, lastName, socialLink, companyName, companyLink,
	dateApproved, earnings, clicks, conversion, product, affiliateId, user,
	Select, selectedAll, selectedData, selection, data, currentPage
}) => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isChecked, setIsChecked] = React.useState<any>();

	const [isToastOpen, setToastOpen] = React.useState(false);
	const [toastKind, setToastKind] = React.useState('success');
	const [toastTitle, setToastTitle] = React.useState('');
	const [toastDesc, setToastDesc] = React.useState('');
	
	const [isToastOpen2, setToastOpen2] = React.useState(false);
	const [toastKind2, setToastKind2] = React.useState('success');
	const [toastTitle2, setToastTitle2] = React.useState('');
	const [toastDesc2, setToastDesc2] = React.useState('');

	React.useEffect(() => {
	  if(selectedData.includes(data)){
	    setIsChecked(true)
	  }else{
	    setIsChecked(null)
	  }
	}, [selectedData, currentPage, data])

	const Remove = async () => {
	  setToastTitle2('Action');
	  setToastDesc2(`Are you sure you want to remove ${firstName} ${lastName} as an affiliate of ${product}?`);
	  setToastKind2('info');
	  setToastOpen2(true);
	}

	const onRemove = () => {
	  setToastOpen2(false)
	  batch(async () => {
	    const res: any = await dispatch(partnersActions.removePartner(affiliateId));

	    if (res?.payload?.data?.status === 1) {
	      setToastTitle('Affiliate Removed');
	      setToastDesc('Affiliate is successfully removed.');
	      setToastKind('success');
	      setToastOpen(true);
	      setTimeout(() => {
	      	if(user?.user_info?.role === 'merchant'){
	      		navigate('/merchant/dashboard?action=affiliate')
	      	}else{
	      		navigate(`/merchant/admin_dashboard/${user?.user_info?.role.toLowerCase()}?action=affiliate`)
	      	}
	      }, 2000)
	    } else {
	      setToastTitle('ERROR');
	      setToastDesc(res?.payload?.message || 'Something went wrong. Try again.');
	      setToastKind('error');
	      setToastOpen(true);
	    }
	  });
	};

return(
	<>
	<Toast
	    description={toastDesc}
	    isOpen={isToastOpen}
	    onClose={() => setToastOpen(false)}
	    title={toastTitle}
	    toastKind={toastKind}
	  />
	<ToastV4
	    btnText='Cancel'    
	      description={toastDesc2}
	      isOpen={isToastOpen2}
	      link=''
	      onClick={onRemove}
	      onClick2={() => setToastOpen2(false)}
	      onClose={() => setToastOpen2(false)}
	      submitBtnText='Remove'
	      title={toastTitle2}
	      toastKind={toastKind2}
	    />
	<FlexRow className='my-2 py-2 w-full border-b border-grey-400'>

		<input
		  checked={isChecked}
		  className={`mr-2 phone:w-3 phone:h-3 laptop:w-4 laptop:h-4 rounded border border-secondary-100 
		        focus:ring-3 focus:ring-primary-200 text-primary-100 phone:mb-auto phone:mt-2 tabletWide:mt-auto cursor-pointer
		        ${selection ? '' : 'hidden'}
		        `}
		  id={data.id}
		  key={data.id}
		  onClick={Select}
		  title={selectedAll? 'Unselect all to choose items manually.' : 'Click to select/unselect this item'}
		  type='checkbox'
		/>

		<Div className='w-1/3 tabletWide:hidden mt-1'>
			<Text className='text-left text-secondary-200'>
				Name:
			</Text>
			<Text className='text-left text-secondary-200'>
				Social:
			</Text>
			<Text className='text-left text-secondary-200'>
				Statistics:
			</Text>
			<Text className='text-left text-secondary-200'>
				Status:
			</Text>
			<Text className='text-left text-secondary-200 mt-4'>
				Action:
			</Text>
		</Div>

		<FlexRow className="phone:w-2/3 phone:flex-col tabletWide:flex-row tabletWide:w-full" key={affiliateId}>
		    {
		      firstName === 'none' ?
		      <>
		        <FlexRow className='phone:w-full tabletWide:w-4/12 laptop:w-3/12'>
		          <Div className='items-center justify-center w-10 h-10 rounded-full bg-grey-400 mr-2 phone:hidden laptop:flex'>
		            {companyName.substring(0, 1).toUpperCase()}
		          </Div>
		          <Text className="text-left text-secondary-200 flex flex-col">
		            <span>{companyName}</span>
		            <span className='text-sm hidden'>{dateApproved}</span>
		          </Text>
		        </FlexRow>
		        <Popover className="relative phone:w-full tabletWide:w-2/12">
					      <Popover.Button className='flex flex-row w-full justify-start tabletWide:justify-center'>
					          <Text className='text-blue-100 tabletWide:hidden'>
					            <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
					            Social
					          </Text>
					          <a className='hidden text-blue-100 tabletWide:inline text-sm' href={companyLink} rel="noopener noreferrer" target="_blank" title={companyLink}>
					            <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
					            Social
					          </a>
					      </Popover.Button>
					      <Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
					      	<Text className='text-sm text-secondary-200'>
					      		Social Link:
					      	</Text>
					      	<hr className='text-grey-400' />
					      	<Text className='text-sm text-secondary-200 hover:text-blue-100 hover:underline break-words mt-4'>
					      		<a href={companyLink} rel="noopener noreferrer" target="_blank">	          	
		          					{companyLink}
		          				</a>
					      	</Text>
					      </Popover.Panel>
				</Popover>	
		      </>
		      :
		      <>
		        <FlexRow className='phone:w-full tabletWide:w-4/12 laptop:w-3/12'>
		          <Div className='items-center justify-center w-10 h-10 rounded-full bg-grey-400 mr-2 phone:hidden laptop:flex'>
		            {firstName.substring(0, 1).toUpperCase()}
		          </Div>
		          <Text className="text-left text-secondary-200 flex flex-col">
		            <span>{firstName} {lastName}</span>
		            <span className='text-sm hidden'>{dateApproved}</span>
		          </Text>
		        </FlexRow>
		        <Text className="text-sm font-medium text-grey-700 truncate phone:w-full tabletWide:w-2/12 text-left phone:hidden tabletWide:text-center laptop:text-left laptop:text-base">
		          	<a href={socialLink} title={socialLink}>
		          		{socialLink}
		          	</a>
		        </Text>
		        <Popover className="relative phone:w-full tabletWide:w-2/12">
		              <Popover.Button className='flex flex-row w-full justify-start tabletWide:justify-center'>
		                  <Text className='text-blue-100 tabletWide:hidden'>
		                    <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
		                    Social
		                  </Text>
		                  <a className='hidden text-blue-100 tabletWide:inline text-sm' href={socialLink} rel="noopener noreferrer" target="_blank" title={socialLink}>
		                    <i className='fas fa-external-link text-blue-100 text-xs mr-2' />
		                    Social
		                  </a>
		              </Popover.Button>
		              <Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
		              	<Text className='text-sm text-secondary-200'>
		              		Social Link:
		              	</Text>
		              	<hr className='text-grey-400' />
		              	<Text className='text-sm text-secondary-200 hover:text-blue-100 hover:underline break-words mt-4'>
		              		<a href={socialLink} rel="noopener noreferrer" target="_blank">
		              			{socialLink}
		              		</a>
		              	</Text>
		              </Popover.Panel>
		        </Popover>
		      </>
		    }
		    <Popover className="relative phone:w-full tabletWide:w-2/12 phone:inline laptop:hidden mx-auto text-left tabletWide:text-center">
		          <Popover.Button className='mx-auto text-center'>
		              <Text className='text-blue-100 tabletWide:text-center tabletWide:text-sm'>
		                <i className='fas fa-chart-pie text-blue-100 text-xs mr-2' />
		                Data
		              </Text>
		          </Popover.Button>
		          <Popover.Panel className="absolute z-10 phone:-left-1/2 tabletWide:left-1/2 top-full mt-2 text-secondary-200 border border-secondary-200 bg-white px-2 rounded w-64 py-4">
		          	<FlexRow>
		          		<FlexRow className='w-1/2 h-24 justify-between flex-col'>
		          			<Text className='mr-auto text-sm'>
		          				Earnings:
		          			</Text>
		          			<Text className='mr-auto text-sm'>
		          				Clicks:
		          			</Text>
		          			<Text className='mr-auto text-sm'>
		          				Conversion:
		          			</Text>
		          		</FlexRow>
		          		<Div className="w-1/2 text-primary-100 font-medium text-sm phone:flex-col tablet:flex-row justify-center">
		          		  <span data-for="revenue" data-tip>
		          		    <FlexRow className="bg-light-200 rounded-full p-2 m-1">
		          		      <i className="fas fa-hand-holding-usd text-orange-400 pr-2" />
		          		      <Text>{`${formatCount(earnings)}.00`}</Text>
		          		    </FlexRow>
		          		  </span>
		          		  <ReactTooltip
		          		    backgroundColor="#FFFFFF"
		          		    delayHide={150}
		          		    effect="solid"
		          		    id="revenue"
		          		    textColor="#073763"
		          		  >
		          		    <Text>Commissions</Text>
		          		  </ReactTooltip>
		          		  <span data-for="clicks" data-tip>
		          		    <FlexRow className="bg-light-200 rounded-full p-2 m-1">
		          		      <i className="fas fa-mouse-pointer text-orange-400 pr-2" />
		          		      <Text>{formatCount(clicks)}</Text>
		          		    </FlexRow>
		          		  </span>
		          		  <ReactTooltip
		          		    backgroundColor="#FFFFFF"
		          		    delayHide={150}
		          		    effect="solid"
		          		    id="clicks"
		          		    textColor="#073763"
		          		  >
		          		    <Text>Clicks</Text>
		          		  </ReactTooltip>
		          		  <span data-for="conversions" data-tip>
		          		    <FlexRow className="bg-light-200 rounded-full p-2 m-1">
		          		      <i className="fas fa-repeat text-orange-400 pr-2" />
		          		      <Text>{formatCount(conversion)}</Text>
		          		    </FlexRow>
		          		  </span>
		          		  <ReactTooltip
		          		    backgroundColor="#FFFFFF"
		          		    delayHide={150}
		          		    effect="solid"
		          		    id="conversions"
		          		    textColor="#073763"
		          		  >
		          		    <Text>Conversions</Text>
		          		  </ReactTooltip>
		          		</Div>
		          	</FlexRow>
		          </Popover.Panel>
		    </Popover>
		    <Div className="phone:hidden laptop:flex phone:w-full tabletWide:w-3/12 text-primary-100 font-medium text-sm phone:flex-col tablet:flex-row justify-center">
		      <span data-for="revenue" data-tip>
		        <FlexRow className="bg-light-200 rounded-full p-2 mx-1">
		          <i className="fas fa-hand-holding-usd text-orange-400 pr-2" />
		          <Text>{`${formatCount(earnings)}.00`}</Text>
		        </FlexRow>
		      </span>
		      <ReactTooltip
		        backgroundColor="#FFFFFF"
		        delayHide={150}
		        effect="solid"
		        id="revenue"
		        textColor="#073763"
		      >
		        <Text>Commissions</Text>
		      </ReactTooltip>
		      <span data-for="clicks" data-tip>
		        <FlexRow className="bg-light-200 rounded-full p-2 mx-1">
		          <i className="fas fa-mouse-pointer text-orange-400 pr-2" />
		          <Text>{formatCount(clicks)}</Text>
		        </FlexRow>
		      </span>
		      <ReactTooltip
		        backgroundColor="#FFFFFF"
		        delayHide={150}
		        effect="solid"
		        id="clicks"
		        textColor="#073763"
		      >
		        <Text>Clicks</Text>
		      </ReactTooltip>
		      <span data-for="conversions" data-tip>
		        <FlexRow className="bg-light-200 rounded-full p-2 mx-1">
		          <i className="fas fa-repeat text-orange-400 pr-2" />
		          <Text>{formatCount(conversion)}</Text>
		        </FlexRow>
		      </span>
		      <ReactTooltip
		        backgroundColor="#FFFFFF"
		        delayHide={150}
		        effect="solid"
		        id="conversions"
		        textColor="#073763"
		      >
		        <Text>Conversions</Text>
		      </ReactTooltip>
		    </Div>
		    <Text className='text-secondary-200 phone:w-full tabletWide:w-2/12 text-left tabletWide:text-center tabletWide:text-sm'>
		      APPROVED
		    </Text>
		    <Div className='phone:w-full tabletWide:w-2/12 flex flex-row justify-start tabletWide:justify-center mt-4 tabletWide:mt-0'>
		      <button className='text-sm rounded-full bg-red-100 hover:bg-red-400 text-white font-semibold text-center px-3' onClick={Remove}>
		      	REMOVE
		      </button>
		    </Div>
		</FlexRow>
	</FlexRow>
	</>
)
};