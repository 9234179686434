export const AllLinks = [
  {
    name: 'Routes',
    links: [
      {
        name: 'Dashboard',
        icon: 'fas fa-chart-line',
        url: '/merchant/dashboard',
      },
      {
        name: 'Affiliate Partners',
        icon: 'fas fa-users',
        url: '/merchant/affiliates',
      },
      {
        name: 'Affiliates Links',
        icon: 'fas fa-link',
        url: '/merchant/links',
      },
    ],
  },
];

export const AffiliateLinks = [
  {
    name: 'Affiliate Routes',
    links: [
      {
        name: 'Dashboard',
        icon: 'fas fa-chart-line',
        url: '/affiliate/dashboard',
      },
      {
        name: 'Catalogs',
        icon: 'fas fa-boxes',
        url: '/affiliate/catalogs',
      },
    ],
  },
];

export const SuperAdminLinks = [
  {
    name: 'Admin Routes',
    links: [
      {
        name: 'Dashboard',
        icon: 'fas fa-chart-line',
        url: '/admin/dashboard',
      },
      {
        name: 'Manage Users',
        icon: 'fas fa-users',
        url: '/admin/manage_users',
      },
      {
        name: 'Account Settings',
        icon: 'fas fa-user',
        url: '/admin/settings',
      },
    ],
  },
];

export const Domains = [
  {
    name: 'FREE',
    links: [
      {
        name: 'X.fly',
        icon: 'fas fa-link',
        url: '/',
      },
      {
        name: 'X.com',
        icon: 'fas fa-link',
        url: '/',
      },
      {
        name: 'X.xffiliate',
        icon: 'fas fa-link',
        url: '/',
      },
    ],
  },
];

export const Create = [
  {
    name: 'CREATE',
    links: [
      {
        name: 'PRODUCT',
        icon: 'fas fa-box',
        url: '/merchant/dashboard?action=product',
      },
      // {
      //   name: 'LINK',
      //   icon: 'fas fa-link',
      //   url: '/merchant/dashboard?action=createlink',
      // },
      {
        name: 'AFFILIATE',
        icon: 'fas fa-users',
        url: '/merchant/dashboard?action=partner',
      },
      // {
      //   name: 'CAMPAIGN',
      //   icon: 'fas fa-poll',
      //   url: '/',
      // },
    ],
  },
];

export const CreateManager = [
  {
    name: 'CREATE',
    links: [
      {
        name: 'PRODUCT',
        icon: 'fas fa-box',
        url: '/merchant/admin_dashboard/manager?action=product',
      },
      // {
      //   name: 'LINK',
      //   icon: 'fas fa-link',
      //   url: '/merchant/dashboard?action=createlink',
      // },
      {
        name: 'PARTNER',
        icon: 'fas fa-users',
        url: '/merchant/admin_dashboard/manager?action=partner',
      },
      // {
      //   name: 'CAMPAIGN',
      //   icon: 'fas fa-poll',
      //   url: '/',
      // },
    ],
  },
];

export const CreateEditor = [
  {
    name: 'CREATE',
    links: [
      {
        name: 'PRODUCT',
        icon: 'fas fa-box',
        url: '/merchant/admin_dashboard/editor?action=product',
      },
      // {
      //   name: 'LINK',
      //   icon: 'fas fa-link',
      //   url: '/merchant/dashboard?action=createlink',
      // },
      {
        name: 'PARTNER',
        icon: 'fas fa-users',
        url: '/merchant/admin_dashboard/editor?action=partner',
      },
      // {
      //   name: 'CAMPAIGN',
      //   icon: 'fas fa-poll',
      //   url: '/',
      // },
    ],
  },
];

export const UserDropdown = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Billing',
        url: '/merchant/settings?action=billing',
      },
      {
        name: 'Settings',
        url: '/merchant/settings',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
      },
    ],
  },
];

export const UserDropdownManager = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Settings',
        url: '/merchant/admin_dashboard/manager/settings',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
      },
    ],
  },
];

export const UserDropdownEditor = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Settings',
        url: '/merchant/admin_dashboard/editor/settings',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
      },
    ],
  },
];

export const UserDropdownAdmin = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Account',
        url: '/admin/dashboard?action=account',
      },
    ],
  },
  {
    name: 'USER 3',
    links: [
      {
        name: 'Settings',
        url: '/admin/settings',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
      },
    ],
  },
];

export const UserDrawers = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Billing',
        url: '/merchant/settings?action=billing',
        icon: 'fas fa-envelope-open-dollar',
      },
      {
        name: 'Settings',
        url: '/merchant/settings',
        icon: 'fas fa-cog',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
        icon: 'fas fa-sign-out',
      },
    ],
  },
];

export const UserDrawersManager = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Settings',
        url: '/merchant/admin_dashboard/manager/settings',
        icon: 'fas fa-cog',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
        icon: 'fas fa-sign-out',
      },
    ],
  },
];

export const UserDrawersEditor = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Settings',
        url: '/merchant/admin_dashboard/editor/settings',
        icon: 'fas fa-cog',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
        icon: 'fas fa-sign-out',
      },
    ],
  },
];

export const AffiliateDrawers = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Account',
        url: '/affiliate/dashboard?action=account',
        icon: 'fas fa-user',
      },
      {
        name: 'Settings',
        url: '/affiliate/settings',
        icon: 'fas fa-cog',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
        icon: 'fas fa-sign-out',
      },
    ],
  },
];

export const SuperAdminDrawers = [
  {
    name: 'USER 1',
    links: [
      {
        name: 'Account',
        url: '/admin/dashboard?action=account',
        icon: 'fas fa-user',
      },
      {
        name: 'Settings',
        url: '/admin/settings',
        icon: 'fas fa-cog',
      },
      {
        name: 'Log Out',
        url: '/user/logout',
        icon: 'fas fa-sign-out',
      },
    ],
  },
];

export const MerchantTabs = [
  {
    name: 'Settings Tabs',
    links: [
      {
        name: 'Account',
        icon: 'fas fa-user',
        tab: 'details',
      },
      {
        name: 'Security',
        icon: 'fas fa-key',
        tab: 'security',
      },
      {
        name: 'Payment',
        icon: 'fas fa-coins',
        tab: 'payment',
      },
      {
        name: 'Links',
        icon: 'fas fa-user-plus',
        tab: 'links',
      },
      {
        name: 'Transactions',
        icon: 'fas fa-money-check',
        tab: 'paymentSettings',
      },
      {
        name: 'Pending',
        icon: 'fas fa-envelope-open-dollar',
        tab: 'pending',
      },
      {
        name: 'Manage Admins',
        icon: 'fas fa-user-lock',
        tab: 'adminRole',
      },
      {
        name: 'Manage Affiliates',
        icon: 'fas fa-users',
        tab: 'tagging',
      },
      {
        name: 'Manage Category',
        icon: 'fas fa-user-tag',
        tab: 'manageRole',
      },
    ],
  },
];

export const AffiliateTabs = [
  {
    name: 'Settings Tabs',
    links: [
      {
        name: 'Account',
        icon: 'fas fa-user',
        tab: 'details',
      },
      {
        name: 'Security',
        icon: 'fas fa-key',
        tab: 'security',
      },
      {
        name: 'Commissions',
        icon: 'fas fa-envelope-open-dollar',
        tab: 'commissions',
      },
    ],
  },
];

export const ManagerTabs = [
  {
    name: 'Settings Tabs',
    links: [
      {
        name: 'Account',
        icon: 'fas fa-user',
        tab: 'details',
      },
      {
        name: 'Security',
        icon: 'fas fa-key',
        tab: 'security',
      },
      {
        name: 'Links',
        icon: 'fas fa-user-plus',
        tab: 'links',
      },
      {
        name: 'Payment',
        icon: 'fas fa-coins',
        tab: 'payment',
      },
      {
        name: 'Transactions',
        icon: 'fas fa-money-check',
        tab: 'paymentSettings',
      },
      {
        name: 'Manage Affiliates',
        icon: 'fas fa-users',
        tab: 'tagging',
      },
      {
        name: 'Manage Category',
        icon: 'fas fa-user-tag',
        tab: 'manageRole',
      },
    ],
  },
];

export const EditorTabs = [
  {
    name: 'Settings Tabs',
    links: [
      {
        name: 'Account',
        icon: 'fas fa-user',
        tab: 'details',
      },
      {
        name: 'Security',
        icon: 'fas fa-key',
        tab: 'security',
      },
      {
        name: 'Links',
        icon: 'fas fa-user-plus',
        tab: 'links',
      },
      {
        name: 'Manage Affiliates',
        icon: 'fas fa-users',
        tab: 'tagging',
      },
      {
        name: 'Manage Category',
        icon: 'fas fa-user-tag',
        tab: 'manageRole',
      },
    ],
  },
];

export const MenuTabs = [
  {
    name: 'Menu Tabs',
    links: [
      {
        name: 'Overview',
        icon: 'fas fa-chart-line',
        tab: 'Overview',
      },
      {
        name: 'Products',
        icon: 'fas fa-store',
        tab: 'Products',
      },
      {
        name: 'Partners',
        icon: 'fas fa-users',
        tab: 'Partners',
      },
    ],
  },
];

export const SuperAdminTabs = [
  {
    name: 'Menu Tabs',
    links: [
      {
        name: 'Account Info',
        icon: 'fas fa-user-line',
        tab: 'details',
      },
      {
        name: 'Securiy',
        icon: 'fas fa-key',
        tab: 'security',
      },
    ],
  },
];
